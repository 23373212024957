<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{place?.currentPlace?.longName}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper resp_row">
    <div class="resp_l-50 resp_scroll-wrapper_l" *ngIf="api.hasPerm(api.perm.ALLOW_ADMIN)">
      <div class="f_col gap_10 mb_20">
        <app-slide-element *ngIf="canEdit" leftIcon="xmark" rightIcon="none" (btnClicked)="deletePlace()">{{'global.delete' | translate}}</app-slide-element>
        <app-slide-element *ngIf="canEdit" leftIcon="pen-to-square" [rightIcon]="disabled ? 'none' : 'times'" (btnClicked)="toggleChange()">{{(disabled ? 'modifyData' : 'stopModifyData') | translate}}</app-slide-element>
        <app-slide-element leftIcon="cash-register" (btnClicked)="nav.to('admin-request', undefined, {queryParams: {createPlaceId: placeId}})">{{'global.toPayments.DYN.'+api.env.type | translate}}</app-slide-element>
        <form *ngIf="form" [formGroup]="form">
          <app-checkbox-element  *ngIf="this.api.userInfo.role === UserPlatformRole.ADMIN && environment.type === DocumentType.PASS" [formGrp]="form" formCtrlName="dailyOtp" [checked]="place?.userPlace?.allowAdmin" leftIcon="user" [icons]="['circle', 'circle-check']" text="{{'dailyOtp.txt' | translate}}"></app-checkbox-element>
        </form>
      </div>
      <app-divider class="mb_20 resp_maxSize_l"></app-divider>
      <app-divider class="mb_10 resp_minSize_l"></app-divider>
      <app-button color="transparent" border="true" formId="form" size="xsm" class="fullWidth resp_minSize_l" (btnClicked)="nav.goBack()">{{'global.back' | translate}}</app-button>
      <app-button *ngIf="!disabled" size="xsm" formId="form" class="fullWidth mt_4 resp_minSize_l" (btnClicked)="updatePlace()">{{'payments.validate' | translate}}</app-button>
    </div>
    <div class="resp_r-50 resp_scroll-wrapper_l">
      <form *ngIf="form" class="mt_10" id="form" [formGroup]="form" >
        <app-input class="mb_24" icon="user" type="text" formCtrlName="name" [formGrp]="form" label="{{'form.name' | translate}}"></app-input>
        <app-input class="mb_24" icon="phone" type="text" formCtrlName="phoneNumbersCsv" [formGrp]="form" label="{{'form.phone' | translate}}"></app-input>
        <app-input class="mb_24" icon="code" type="text" formCtrlName="connectUAPattern" [formGrp]="form" label="{{'form.connectUAPattern' | translate}}"></app-input>
        <app-input class="mb_24" icon="network-wired" type="text" formCtrlName="connectIPRange" [formGrp]="form" label="{{'form.connectIPRange' | translate}}"></app-input>
        <app-input class="mb_24" icon="hashtag" type="text" formCtrlName="id" [formGrp]="form" label="{{'form.id' | translate}}"></app-input>
        <app-input class="mb_24" icon="hashtag" type="text" formCtrlName="externalCode" [formGrp]="form" label="{{'form.externalCode' | translate}}"></app-input>
        <app-input *ngIf="place.allPlaces" class="mb_20" icon="user" type="select" [selectOptions]="placesSO" formCtrlName="idParent" [formGrp]="form" label="{{'form.parentId' | translate}}"></app-input>
        <div class="mb_20">
          <app-collapse [leftIcon]="'location-dot'" [title]="'place.countriesSelected' | translate">
            <div class="f_col gap_5">
              <app-checkbox-element *ngFor="let country of request.getNationalities(false)"
                                    leftIcon="location-dot"
                                    [icons]="['circle', 'circle-check']"
                                    text="{{country.name}}"
                                    [checked]="isSelected(country.value)"
                                    (onChange)="toggleCountry(country.value, $event)"
                                    [id]="country.value"
                                    [disableClick]="disabled"
                                    [size]="'sm'"></app-checkbox-element>
            </div>
          </app-collapse>
        </div>
      </form>
      <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button *ngIf="disabled" color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.goBack()">{{'global.back' | translate}}</app-button>
      <app-button *ngIf="!disabled" formId="form" class="fullWidth" (btnClicked)="updatePlace()">{{'payments.validate' | translate}}</app-button>
    </div>
      <app-back-btn *ngIf="!disabled" class="ml_10" icon="xmark" (btnClicked)="toggleChange()"></app-back-btn>
  </div>
</div>

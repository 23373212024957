<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'place-add.add' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper resp_row" (scroll)="onScroll($event)">
    <div class="resp_l-50 resp_scroll-wrapper_l">
      <app-snackbar  Icon="user" class="f_center mb_10">{{actualUser?.name}}</app-snackbar>
      <div class="input_wrapper mb_20">
        <app-input icon="magnifying-glass" type="text" formCtrlName="text" [formGrp]="form" label="{{'form.name' | translate}}, #id" class="fullWidth" (input)="applyFilter($event)"></app-input>
      </div>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth mt_4 resp_minSize_l" (btnClicked)="nav.to('user/' + userId +'/places-choice')">{{'global.back' | translate}}</app-button>
    </div>
    <div class="resp_r-50 resp_scroll-wrapper_l">
      <div *ngFor="let place of filteredData | slice:0:scrollNumber">
        <app-desc-card2 class="mb_10" LDesc1="{{place?.longName}}" LDesc2="{{place?.id}}" (btnClicked)="selectPlace(place?.id)"></app-desc-card2>
      </div>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.to('user/' + userId +'/places-choice')">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>

<div id="layout" class="sign-in f_col">
  <app-view-title fromRoute="sign-in" toRoute="choose-lang" [chooseLangBtn]="api.env.supportedLanguages.length > 1">{{'signIn.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image imageUrl="assets-projects/images/image_access.svg"></app-view-deco-image>
      <form *ngIf="form" id="form" [formGroup]="form" (ngSubmit)="submitForm()">
        <app-input [type]="connectionAd ? 'text' : 'email'" formCtrlName="email" [formGrp]="form" [label]="connectionAd ? 'form.ad' : 'form.email'" [icon]="connectionAd ? 'user' : 'at'"></app-input>
        <app-input type="password" formCtrlName="password" [formGrp]="form" label="form.password" class="mt_30"></app-input>
        <app-input *ngIf="secondStep" class="mt_30" icon="location-dot" type="select" formCtrlName="place" [formGrp]="form" label="{{'form.place' | translate}}"  [selectOptions]="placesSO" (change)="selectChange($event)"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
      <div class="f_end mt_20" *ngIf="!secondStep">
        <a (click)="nav.to('forgot-password')">{{'signIn.forgotPassword' | translate}}</a>
      </div>
      <div class="mt_20" *ngIf="internal && !secondStep">
        <app-checkbox-element text="{{'adChoice' | translate }}" [checked]="connectionAd" (onChange)="changeConnection($event.checked)"></app-checkbox-element>
      </div>
      <div class="resp_minSize_l mt_20">
        <app-button [disabled]="secondStep && !this.selectedPlace" size="sm" type="submit" formId="form" class="fullWidth mb_5">{{'login.sign_in' | translate}}</app-button>
        <app-button *ngIf="!secondStep" size="sm" color="transparent" border="true" class="fullWidth" (btnClicked)="nav.to('sign-up')">{{'global.notAccount' | translate}}</app-button>
        <app-button *ngIf="secondStep" color="transparent" border="true" size="sm" type="button" formId="form" class="fullWidth mb_5" (btnClicked)="cancel()">{{'global.cancel' | translate}}</app-button>
      </div>
    </div>
    <div class="f_align_end f_col mt_10 mb_20 resp_maxSize_l">
        <app-button [disabled]="secondStep && !this.selectedPlace" type="submit" formId="form" class="fullWidth mb_5">{{'login.sign_in' | translate}}</app-button>
        <app-button color="transparent" border="true" class="fullWidth" (btnClicked)="nav.to('sign-up')">{{'global.notAccount' | translate}}</app-button>
    </div>
  </div>
</div>

import {Component, EventEmitter, OnDestroy, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeConfig,
  ScannerQRCodeDevice,
  ScannerQRCodeResult
} from 'ngx-scanner-qrcode';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScanQRComponent implements OnDestroy {
  @Output() scan: EventEmitter<string> = new EventEmitter<string>();
  @Output() hasStarted: EventEmitter<string> = new EventEmitter<string>();

  permissions: any;
  started = false;
  hasPermission = false;
  cameraFound = false;
  availableDevices: ScannerQRCodeDevice[] = [];
  currentDevice: ScannerQRCodeDevice = null;
  currentCamIndex = 0;
  qrResultString: string;

  declare window: any;

  @ViewChild('scanner') scanner!: NgxScannerQrcodeComponent;

  public config: ScannerQRCodeConfig = {
    constraints: {video: {width: 850, height: 850}}
  };

  private subscriptions: Subscription[] = [];

  constructor(public nav: NavigateService,
    private loader: LoaderService,
    private lang: LangService,
    private device: DevicesService) {}

  ngAfterViewInit(): void {
    this.loader.loading(true);
    this.subscriptions.push(this.scanner.isReady.subscribe(() => {
      this.handle(this.scanner, 'start');
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.handle(this.scanner, 'stop');
  }

  public handle(scanner: NgxScannerQrcodeComponent, fn: 'start' | 'stop'): void {
    const playDeviceFacingBack = (devices: ScannerQRCodeDevice[]) => {
      this.availableDevices = devices;
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      scanner.playDevice(device ? device.deviceId : devices[0].deviceId);
    };

    if(fn === 'start') {
      this.subscriptions.push(scanner[fn](playDeviceFacingBack).subscribe({
        next: () => {
          this.loader.loading(false);
          this.started = true;
        },
        error: () => {
          this.loader.loading(false);
          this.handleNoPermission();
        }
      }));
    } else {
      this.subscriptions.push(scanner[fn]().subscribe({
        next: () => {
          this.loader.loading(false);
        },
        error: () => {
          window.location.reload();
        }
      }));
    }
  }

  handleNoPermission() {
    if(this.device.isDevices('desktop')) {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('no.activecamera')}).then(() => {
        this.nav.to('dashboard');
      });
    } else if(this.device.isDevices('cordova')) {
      this.nav.to('page-permission');
    }
  }

  public onCodeResult(result: ScannerQRCodeResult[], scanner?: any): void {
    result && scanner?.pause();

    if(Array.isArray(result) && result.length > 0) {
      this.qrResultString = result[0].value;
      this.scan.emit(this.qrResultString);
      const extractedResult = this.qrResultString.slice(12, 48);
      this.nav.to('admin-request-select/' + extractedResult, undefined, {queryParams: {from: 'scanQr'}});
    }
  }

  nextCamera() {
    if(this.availableDevices.length > 0) {
      this.currentCamIndex++;
      if(this.currentCamIndex >= this.availableDevices.length) {
        this.currentCamIndex = 0;
      }
      this.currentDevice = this.availableDevices[this.currentCamIndex];
      this.scanner.playDevice(this.currentDevice.deviceId);
    }
  }

  scanError(err: any) {
    this.loader.loading(true, {type: 'error', message: `ScanError: ${err}`});
  }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {CartService} from '../../../shared/services/cart.service';
import {notInArrayValidator} from '../../../shared/utils/form';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  template: ''
})
export class RedirectTravelNameComponent {
  constructor(private nav: NavigateService) {
    this.nav.to('travel-name', undefined, {queryParams: {clean: true}});
  }
}

@Component({
  selector: 'app-1.travel-name',
  templateUrl: './travel-name.component.html',
  styleUrl: './travel-name.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TravelNameComponent {

  form: FormGroup;
  submitted: boolean;
  subscription: any;
  existingTravelNames: string[];

  constructor(public forms: FormService,
              public nav: NavigateService,
              public api: ApiService,
              public cart: CartService,
              public request: RequestService,
              private loader: LoaderService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
    this.existingTravelNames = this.request.allMyPayments?.map(p => p.batchId) || [];
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(this.cart.currentApplication?.isRevision) {
        this.nav.to('dashboard');
      } else if(this.api.userRole.isKiosk) {
        this.nav.to('standby-kiosk');
      }
      this.setPlannedEntries();
      this.setForm();
    });
    this.route.queryParams.subscribe(params => {
      if(params.clean) {
        this.cart.deleteSavedApplication();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setForm() {
    this.form = this.formBuilder.group({
      travelName: ['', [Validators.required, notInArrayValidator(this.existingTravelNames)]],
      plannedEntry: ['', Validators.required]
    });
    this.form.controls.travelName.patchValue(this.cart.currentApplication.travelName || this.cart.getUniqueReference(this.existingTravelNames));
    this.form.controls.travelName.disable();
    this.form.controls.plannedEntry.patchValue(this.cart.currentApplication.plannedEntry || '');
    if(this.cart.currentApplication.submitted) this.form.controls.travelName.disable();
  }

  submit() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.cart.currentApplication.travelName = this.form.controls.travelName.value;
      this.cart.currentApplication.plannedEntry = this.form.controls.plannedEntry.value;
      this.cart.saveCurrentApplication(false);
      this.nav.to('participants');
    }
  }

  setPlannedEntries() {
    if(this.request.entryPoints?.length > 0) return;
    this.loader.loading(true);
    this.api.schema('zweentry').then((res: any) => {
      this.request.entryPoints = res?.properties?.ProvisionnalEntryPort?.enum || [];
      if(this.request.entryPoints.length) {
        this.loader.loading(false);
      } else {
        this.loader.loading(true, {type: 'error', message: 'err.entryPoints'});
      }
    }).catch(() => this.loader.loading(true, {type: 'error', message: 'err.entryPoints'}));
  }
}

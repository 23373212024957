import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';

@Component({
  selector: 'app-welcome-offline',
  templateUrl: './welcome-offline.component.html',
  styleUrls: ['./welcome-offline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeOfflineComponent{
  constructor(public nav: NavigateService, public api: ApiService) {}
}

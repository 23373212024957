import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {NavigateService, ShortcutAction} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {Document, DocumentType, UserPermission} from '../../../shared/models/user';
import {CartService} from '../../../shared/services/cart.service';
import {PassportType} from '../../../shared/models/request';
import {RequestService} from '../../../shared/services/request.service';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {canMultipleCountries} from '../../../shared/models/VISA-ZIM/local-form';
import {Direction, VisaFN} from '../../../shared/models/forms';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-4.visa-type',
  templateUrl: './visa-type.component.html',
  styleUrl: './visa-type.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class VisaTypeComponent implements OnDestroy {

  selected: Document = this.cart.currentRequest?.visaType || undefined;
  visaTypes: Document[];
  keepScanned = false;
  subscription;
  paramDirection: Direction;
  shortcuts = [
    ShortcutAction.ONE,
    ShortcutAction.TWO,
    ShortcutAction.THREE,
    ShortcutAction.FOUR
  ];

  constructor(public api: ApiService,
    public cart: CartService,
    public request: RequestService,
    public nav: NavigateService,
    private inactivity: InactivityService,
    private route: ActivatedRoute,
    private scanDoc : ScanDocumentService) {
    this.route.queryParams.subscribe(params => {
      if(params && params.direction) this.paramDirection = params.direction;
    });
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(!this.cart.currentRequest) {
        if(this.cart.currentApplication?.isRevision || (this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CREATE) && this.api.hasPerm(UserPermission.ALLOW_CONSUME))) {
          this.nav.to('dashboard');
        } else if(this.api.userRole.isKiosk) {
          this.nav.to('standby-kiosk');
        } else {
          this.nav.to('travel-name');
        }
        return;
      } else if(!this.cart.currentRequest.nationality || !this.cart.currentRequest.passportType) {
        this.nav.to('passport-type');
        return;
      }
      this.cart.currentRequest = {...this.cart.currentRequest, latestRouteVisited: 'visa-type'};
      this.cart.saveCurrentApplication(false);
      this.selected = this.cart.currentRequest.visaType || undefined;
      this.getVisaTypes();
    });
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
  }

  ngOnDestroy() {
    if(!this.keepScanned) {
      this.scanDoc.scanned = false;
    }
    this.subscription.unsubscribe();
  }

  getVisaTypes() {
    const all = this.api.userInfo.server.paymentRequests;
    if((this.api.userRole.isWorker || this.api.userRole.isAdmin) && ((this.request.CatC.some(n => n.value === this.cart.currentRequest.nationality) && (this.cart.currentRequest.passportType === PassportType.ORDINARY)) || this.cart.currentRequest.passportType === PassportType.REFUGEE)) {
      this.visaTypes = all.filter(v => v.id === DocumentType.ZWEENTRY);
      return;
    }
    switch(this.cart.currentRequest.passportType) {
    case PassportType.DIPLOMATIC:
    case PassportType.MARINE:
    case PassportType.LAISSEZ_PASSER:
    case PassportType.SERVICE:
    case PassportType.IDENTITY_CARD:
    case PassportType.RESIDENT_PERMIT:
    case PassportType.PERMANENT_RESIDENT_PERMIT:
      this.visaTypes = all.filter(v => v.id === DocumentType.ZWEENTRY);
      break;
    case PassportType.ORDINARY:
    case PassportType.REFUGEE:
      if(this.request.CatA.some(n => n.value === this.cart.currentRequest.nationality)) {
        this.visaTypes = all.filter(v => v.id === DocumentType.ZWEENTRY);
      }
      if(this.request.CatB.some(n => n.value === this.cart.currentRequest.nationality)) {
        this.visaTypes = all.filter(v => v.id === DocumentType.ZWEVISAARR ||
          v.id === DocumentType.ZWEVISAARR_DOUBLE ||
          v.id === DocumentType.ZWEVISAARR_MULTIPLE && this.canMultiple() ||
          v.id === DocumentType.ZWEVISAARR_KAZA);
      }
      if(this.request.CatC.some(n => n.value === this.cart.currentRequest.nationality)) {
        this.visaTypes = all.filter(v => v.id === DocumentType.ZWEVISA ||
          v.id === DocumentType.ZWEVISA_DOUBLE ||
          v.id === DocumentType.ZWEVISA_MULTIPLE && this.canMultiple() ||
          v.id === DocumentType.ZWEVISA_KAZA);
      }
    }

    if(this.selected && !this.visaTypes.some(v => v.id === this.selected.id)) {
      this.selectVisaType(undefined);
    }
    if(!this.selected && this.visaTypes.length === 1) {
      this.selectVisaType(this.visaTypes[0]);
    }

    if(this.cart.currentApplication.isRevision && this.cart.currentApplication.skipValidRevision && this.cart.isFnInRevision(VisaFN.VISA_TYPE, true)) {
      this.paramDirection === Direction.NEXT ? this.next() : this.previous();
    }

  }

  selectVisaType(visaType: Document) {
    if(this.cart.isFnInRevision(VisaFN.VISA_TYPE, true)) return;
    this.selected = visaType;
    this.cart.currentRequest = {
      ...this.cart.currentRequest,
      visaType: visaType,
      formConfig: undefined
    };
    this.cart.saveCurrentApplication();
    if(!this.selected || !this.request.isPaidOnline(this.selected?.id)) {
      this.cart.currentRequest.urgent = undefined;
    }
    this.next();
  }

  next() {
    if(this.request.isPaidOnline(this.selected.id) && !this.api.userRole.isWorker && !this.api.userRole.isAdmin) {
      this.nav.to('urgent', undefined, {queryParams: {direction: Direction.NEXT}});
    } else {
      if(this.scanDoc?.scanned) {
        this.keepScanned = true;
      }
      this.nav.to('form-visa', undefined, {queryParams: {direction: Direction.NEXT}});
    }
  }

  canMultiple() {
    return canMultipleCountries.includes(this.cart.currentRequest.nationality);
  }
  previous() {
    if(this.scanDoc?.scanned) {
      this.keepScanned = true;
    }
    this.nav.to('passport-type', undefined, {queryParams: {direction: Direction.PREVIOUS}});
  }

  toBeSupervised(): boolean {
    return (this.api.userRole.isAdmin || this.api.userRole.isWorker) && this.request.isCountryCat(this.cart.currentRequest.nationality, 'C') && this.cart.currentRequest.passportType === PassportType.ORDINARY;
  }

  protected readonly ShortcutAction = ShortcutAction;
}

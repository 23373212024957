export const isPDF = (document: string | File | Blob | null): boolean => {
  if(!document) return false;
  if(typeof document === 'string') {
    return document.startsWith('data:application/pdf;base64,');
  } else if(document instanceof File || document instanceof Blob) {
    return document.type === 'application/pdf';
  }
  return false;
};

export const isBase64 = (document: string | null): boolean => {
  if(!document) return false;
  return document.startsWith('data:image/') && document.includes('base64');
};
<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background" fromRoute="sign-up" toRoute="choose-lang" [chooseLangBtn]="api.env.supportedLanguages.length > 1">{{'signUp.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image imageUrl="assets-projects/images/image_signin.svg"></app-view-deco-image>
      <form id="form" [formGroup]="form" (ngSubmit)="submit()">
        <app-input type="text" formCtrlName="firstName" [formGrp]="form" label="form.firstName"></app-input>
        <app-input type="text" formCtrlName="lastName" [formGrp]="form" label="form.lastName" class="mt_24"></app-input>
        <app-input type="email" formCtrlName="email" [formGrp]="form" label="form.email" class="mt_24"></app-input>
        <app-input type="password" formCtrlName="password" (output)="checkPswdStrength($event)"
                   [formGrp]="form" label="form.password" class="mt_24"></app-input>
        <div class="pswd_strength mt_4">
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 20"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 40"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 70"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 90"></span>
        </div>
        <app-input type="password" formCtrlName="password2"
                   [formGrp]="form" label="form.password2" class="mt_30"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted"
                         [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
      </form>
      <div class="resp_minSize_l mt_20">
        <app-button size="sm" type="submit" formId="form" class="fullWidth">{{'global.confirm' | translate}}</app-button>
        <app-button size="sm" color="transparent" border="true" class="fullWidth mt_10" (btnClicked)="nav.to('home')">{{'global.back' | translate}}</app-button>
      </div>
    </div>
  </div>
  <div class="f_align_end mb_20 resp_maxSize_l">
    <app-back-btn [navTo]="{route: 'home'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button type="submit" formId="form" class="fullWidth">{{'global.confirm' | translate}}</app-button>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {
  ActionState,
  OperationType,
  PassportType,
  Request,
  RequestState,
  RequestSubState,
  RequestTheme,
  RequestWithDuplicata,
  PrintReceiptData,
  Currency,
  CurrencySign
} from '../models/request';
import {ApiService} from './api.service';
import {Document, DocumentType, UserPermission} from '../models/user';
import {CartService, FormApplication} from './cart.service';
import {FormAction, FormField, VisaFN, VisaFormConfig, VisaFormName} from '../models/forms';
import {Visa} from '../models/VISA-ZIM/local-form';
import {isJsonParsable, parseIndexedData} from '../utils/parseMetadata';
import {ExtendVisa} from '../models/VISA-ZIM/extend-form';
import {isAfter, endOfDay, isValid, format} from 'date-fns';
import {environment} from 'src/environments/environment';
import {Person} from '../models/person';
import {Subscription} from 'rxjs';
import {flattenObject} from '../utils/flattenObject';
import {QrService} from './qr.service';
import {PlatformEvent, PlatformEventType} from '../models/information';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  CatA: {value: string, name: string}[] = [];
  CatB: {value: string, name: string}[] = [];
  CatC: {value: string, name: string}[] = [];
  entryPoints: string[] = [];

  allMyPayments: Request[];
  allExtensions: Request[];
  allBatchIds: string[];
  allMyApplications: {batchId: string, requests: Request[] | RequestWithDuplicata[]}[];
  invalidatePersons: Person[] = [];
  private signOutSubscription: Subscription;
  priceOverstay = 10000;

  constructor(private lang: LangService,
    private api: ApiService,
    private loader: LoaderService,
    private nav: NavigateService,
    private cart: CartService,
    private qr: QrService) {
    this.signOutSubscription = this.api.signOutObservable.subscribe({
      next: () => {
        this.allMyPayments = undefined;
        this.allExtensions = undefined;
        this.allBatchIds = undefined;
        this.allMyApplications = undefined;
        this.invalidatePersons = undefined;
      }
    });
  }

  ngOnDestroy() {
    this.signOutSubscription.unsubscribe();
  }

  pay(_action?: FormAction, isPayOnline = false, application?: FormApplication) {
    return this.payApplication(_action, isPayOnline, application).then((res: any) => {
      if(this.api.userRole.isWorker) {
        if(res) {
          this.nav.to('admin-request-select/' + res, undefined, {queryParams: {registerSession: true}});
          setTimeout(() => {
            this.cart.deleteSavedApplication(false);
          });
        } else {
          this.loader.loading(true, {type: 'error', message: 'global.error'});
          return Promise.reject('global.error');
        }
      } else if(res) {
        if(this.api.userRole.isKiosk) {
          this.nav.to('success-kiosk');
        } else {
          this.nav.to('payment-success');
        }
        setTimeout(() => {
          this.cart.deleteSavedApplication(false);
        });
      } else if(!application) {
        this.loader.loading(true, {type: 'valid', message: 'revision.success'});
        this.nav.to('dashboard');
      }
    }).finally(() => {
      this.invalidatePersons = this.cart.currentApplication.requests.map(request => request.person);
    }).catch((err: any) => {
      this.loader.loading(true, {type: 'error', message: err});
      return Promise.reject(err);
    });
  }

  payApplication(_action?: FormAction, isPayOnline = false, application?: FormApplication): Promise<any> {
    return new Promise((resolve, reject) => {
      const payments: { request_id: string, person_id: number, serialized: string }[] = [];
      (application || this.cart.currentApplication).requests.forEach((request: Request) => {
        const data = {
          AddressZimbabwe: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ADDRESS_ZIMBABWE, request.formConfig) || undefined,
          ArrivalDate: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.ARRIVAL_DATE, request.formConfig) ? new Date(this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.ARRIVAL_DATE, request.formConfig)).getTime() : undefined,
          BirthDay: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.BIRTH_DAY, request.formConfig) ? new Date(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.BIRTH_DAY, request.formConfig)).getTime() : !this.cart.currentApplication.isExtend ? new Date('1900-01-01').getTime() : undefined,
          DateOfExpiry: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_EXPIRY, request.formConfig) ? new Date(this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_EXPIRY, request.formConfig)).getTime() : undefined,
          DateOfIssue: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_ISSUE, request.formConfig) ? new Date(this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_ISSUE, request.formConfig)).getTime() : undefined,
          DepartureDate: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.DEPARTURE_DATE, request.formConfig) ? new Date(this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.DEPARTURE_DATE, request.formConfig)).getTime() : undefined,
          Documents: {
            PurposeOfVisit: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, request.formConfig) || undefined,
            PassportPhoto: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.PASSPORT_PHOTO, request.formConfig) || undefined,
            PassportScan: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.PASSPORT_SCAN, request.formConfig) || undefined,
            ProofOfResidenceHost: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.PROOF_OF_RESIDENCE_HOST, request.formConfig) || undefined,
            AddressAtDestination: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.ADDRESS_AT_DESTINATION, request.formConfig) || undefined,
            PreviousZimbabweanVisa: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.PREVIOUS_ZIMBABWEAN_VISA, request.formConfig) || undefined,
            HostInvitationLetter: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.HOST_INVITATION_LETTER, request.formConfig) || undefined,
            ProofOfResidence: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.PROOF_OF_RESIDENCE, request.formConfig) || undefined,
            ApplicationLetter: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.APPLICATION_LETTER, request.formConfig) || undefined,
            ResidentialStatusOfHost: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.RESIDENTIAL_STATUS_OF_HOST, request.formConfig) || undefined,
            ReasonOfVisitProof: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.REASON_OF_VISIT_PROOF, request.formConfig) || undefined,
            BusinessLetter: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.BUSINESS_LETTER, request.formConfig) || undefined,
            BusinessProfile: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.BUSINESS_PROFILE, request.formConfig) || undefined,
            InvitationLetterAndBusinessProfile: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.INVITATION_LETTER_AND_BUSINESS_PROFILE, request.formConfig) || undefined,
            LetterOfAcceptanceFromSchool: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.LETTER_OF_ACCEPTANCE_FROM_SCHOOL, request.formConfig) || undefined,
            InvitationLetter: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.INVITATION_LETTER, request.formConfig) || undefined,
            AttestantDocument: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.ATTESTANT_DOCUMENT, request.formConfig) || undefined,
            AffidavitCustody: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.AFFIDAVIT_CUSTODY, request.formConfig) || undefined,
            CopyOfEntry: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.COPY_OF_ENTRY, request.formConfig) || undefined,
            DepartureTicket: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.DEPARTURE_TICKET, request.formConfig) || undefined,
            ExtraDocument1: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.EXTRA_DOCUMENT_1, request.formConfig) || undefined,
            ExtraDocument2: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.EXTRA_DOCUMENT_2, request.formConfig) || undefined,
            ExtraDocument3: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.EXTRA_DOCUMENT_3, request.formConfig) || undefined,
            ExtraDocument4: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.EXTRA_DOCUMENT_4, request.formConfig) || undefined,
            ExtraDocument5: this.getFieldValue(VisaFormName.DOCUMENT, VisaFN.EXTRA_DOCUMENT_5, request.formConfig) || undefined
          },
          Email: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.EMAIL, request.formConfig)?.toLowerCase() || undefined,
          FirstName: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, request.formConfig) ? this.normalizeName(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, request.formConfig)) : undefined,
          OtherName: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.OTHER_NAME, request.formConfig) ? this.normalizeName(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.OTHER_NAME, request.formConfig)) : undefined,
          Gender: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.GENDER, request.formConfig) || undefined,
          HomeAddress: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.HOME_ADDRESS, request.formConfig) || undefined,
          HostName: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.HOST_NAME, request.formConfig) || undefined,
          LastName: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, request.formConfig) ? this.normalizeName(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, request.formConfig)) : undefined,
          MaritalStatus: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, request.formConfig) || undefined,
          Nationality: !(application || this.cart.currentApplication)?.isExtend && request.nationality || undefined,
          Occupation: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.OCCUPATION, request.formConfig) || undefined,
          CompanionsSize: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.COMPANIONS_SIZE, request.formConfig) ?  +this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.COMPANIONS_SIZE, request.formConfig) : this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.COMPANIONS_SIZE, request.formConfig) ? +this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.COMPANIONS_SIZE, request.formConfig) : undefined,
          PassportNumber: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, request.formConfig)?.toUpperCase() || this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, request.formConfig)?.toUpperCase() || undefined,
          PassportCountry: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_COUNTRY, request.formConfig) || this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, request.formConfig) || undefined,
          Phone: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PHONE, request.formConfig) || undefined,
          PlaceOfBirth: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_COUNTRY, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_COUNTRY, request.formConfig) + ' - ' + this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_CITY, request.formConfig) : undefined,
          PlaceofIssue: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PLACE_OF_ISSUE, request.formConfig) || undefined,
          PreviousConvictionsZim: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, request.formConfig) === 'true' ? this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM, request.formConfig) : this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, request.formConfig) === 'true' ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, request.formConfig) : undefined,
          PreviousConvictionsHome: this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, request.formConfig) === 'true' ? this.getFieldValue(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME, request.formConfig) : this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, request.formConfig) === 'true' ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, request.formConfig) : undefined,
          Spouse: this.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? 'Yes' : 'No' : undefined,
          SpouseBirthDay: this.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? new Date(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE_DATE_OF_BIRTH, request.formConfig)).getTime() : undefined : undefined,
          SpouseFullName: this.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.normalizeName(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE_NAME, request.formConfig)) : undefined,
          SpousePassportNumber: this.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE_PASSPORT_NUMBER, request.formConfig)?.toUpperCase() : undefined : undefined,
          SpousePlaceOfBirth: this.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE, request.formConfig) ? this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.SPOUSE_PLACE_OF_BIRTH, request.formConfig) : undefined : undefined,
          NextOfKin: !(application || this.cart.currentApplication)?.isExtend && JSON.stringify({
            firstname: this.getFieldValue(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_FIRST_NAME, request.formConfig) ? this.normalizeName(this.getFieldValue(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_FIRST_NAME, request.formConfig)) : undefined,
            lastname: this.getFieldValue(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_LAST_NAME, request.formConfig) ? this.normalizeName(this.getFieldValue(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_LAST_NAME, request.formConfig)) : undefined,
            phone: this.getFieldValue(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_PHONE, request.formConfig) || undefined,
            email: this.getFieldValue(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_EMAIL, request.formConfig)?.toLowerCase() || undefined
          }) || undefined,
          FundsAvailable: !(application || this.cart.currentApplication)?.isExtend ? Number(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE, request.formConfig) || 0) : undefined,
          FundsAvailableCurrency: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE_CURRENCY, request.formConfig) || undefined,
          Urgent: !(application || this.cart.currentApplication)?.isExtend ? (this.isPaidOnline(request.visaType.id) ? request.urgent : undefined) : undefined,
          PassportType: !(application || this.cart.currentApplication)?.isExtend && request.passportType || undefined,
          ProvisionnalEntryPort: !(application || this.cart.currentApplication)?.isExtend && (application || this.cart.currentApplication).plannedEntry || undefined,
          NonOrdinaryPassMission: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.NON_ORDINARY_PASS_MISSION, request.formConfig) || undefined,
          VisaReference: (application || this.cart.currentApplication)?.isExtend ? (application || this.cart.currentApplication)?.requestId : undefined,
          DaysRequested: Number(this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.DAYS_REQUESTED, request.formConfig)) || undefined,
          ReasonForExtension: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.REASON_FOR_EXTENSION, request.formConfig) || undefined,
          AttestantID: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_ID, request.formConfig) || undefined,
          AttestantName: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_NAME, request.formConfig) || undefined,
          AttestantPhone: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_PHONE, request.formConfig) || undefined,
          AttestantEmail: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_EMAIL, request.formConfig) || undefined,
          AttestantAddress: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_ADDRESS, request.formConfig) || undefined,
          AttestantRelation: this.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_RELATION, request.formConfig) || undefined
        } as Visa | ExtendVisa;

        Object.keys(data).forEach((key: keyof Visa) => {
          if(data[key] === undefined) {
            delete data[key];
          }
        });
        payments.push({
          request_id: request.visaType.id,
          person_id: request.person.id ? Number(request.person.id) : undefined,
          serialized: JSON.stringify(data)
        });
      });

      let previousBatchId = undefined;
      if(this.api.userRole.isCustomer && !(application || this.cart.currentApplication)?.isExtend) {
        previousBatchId = (application || this.cart.currentApplication).isRevision ? (application || this.cart.currentApplication).travelName : (this.allBatchIds?.find(b => b === (application || this.cart.currentApplication).travelName) || undefined);
      }
      this.loader.loading(true);
      if(this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CREATE) && this.api.hasPerm(UserPermission.ALLOW_CONSUME)) {
        this.api.registerMNTAPayment(payments, (application || this.cart.currentApplication).travelName, previousBatchId, false).then(res => {
          resolve(this.processPaymentResponse(res));
          this.loader.loading(false);
        }).catch(err => {
          this.loader.loading(false);
          reject(err);
        });
      } else if((application || this.cart.currentApplication).isRevision) {
        this.api.customTimeout = 300000;
        this.api.updatePayment((application || this.cart.currentApplication).requestId,
          undefined,
          undefined,
          payments[0].serialized,
          undefined,
          undefined).then(() => {
          this.loader.loading(false);
          this.api.customTimeout = undefined;
          this.invalidatePersons = (application || this.cart.currentApplication).requests.map(request => request.person);
          resolve(undefined);
        }).catch((err) => {
          this.loader.loading(false);
          this.api.customTimeout = undefined;
          reject(err);
        });
      } else {
        if(this.api.userRole.isCustomer || this.api.userRole.isKiosk) this.api.customTimeout = 300000;
        this.api.registerMNTAPayment(payments, (application || this.cart.currentApplication).travelName, previousBatchId, isPayOnline).then(res => {
          this.api.customTimeout = undefined;
          if(this.api.userRole.isCustomer) {
            this.allBatchIds.push((application || this.cart.currentApplication).travelName);
          }
          resolve(this.processPaymentResponse(res));
          this.loader.loading(false);
        }).catch(err => {
          this.loader.loading(false);
          this.api.customTimeout = undefined;
          reject(err);
        });
      }
    });
  }

  processPaymentResponse(res: {ids: string[], body: any}, application?: FormApplication): string {
    this.cart.currentApplication.submitted = true;
    if(res.body) {
      this.cart.saveCurrentApplication(false);
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://' + this.api.userInfo.server.mnta.host + '/Lite/Authorise.aspx';
      Object.keys(res.body).forEach(key => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = res.body[key];
        form.appendChild(input);
      });
      document.body.appendChild(form);
      form.submit();
    } else {
      this.invalidatePersons = (application || this.cart.currentApplication).requests.map(request => request.person);
      return res.ids[0];
    }
  }

  getStatusTheme(request: RequestWithDuplicata): RequestTheme {
    const isUsage = this.isUsage(request);
    const isBlacklist = this.isBlacklisted(request);
    const document = this.api.userInfo.server.paymentRequests.find(d => d.id === request.operationId);
    const indexedDatas = this.api.env.type === DocumentType.PASS ? '' : parseIndexedData(request.internalIndexedData, request.operationId, this.api.userInfo.server);
    const isUrgent =  this.api.env.type === DocumentType.ZWEVISA  && indexedDatas &&  indexedDatas?.urgent === 'Yes';
    switch(request.state) {
    case RequestState.PROPOSED:
      return {
        severity: 3,
        color: '#C9C9C9',
        iconColor: '#747474',
        icon: 'hourglass-start',
        state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.PROPOSED),
        descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.PROPOSED)
      };
    case RequestState.DUPLICATED:
      return {
        severity: 2,
        color: '#FFA14A',
        iconColor: '#A85100',
        icon: 'copy',
        state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.DUPLICATED),
        descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.DUPLICATED)
      };
    case RequestState.REFUNDED:
      return {
        severity: 2,
        color: '#FF823C',
        iconColor: '#9D3900',
        icon: 'undo',
        state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.REFUNDED),
        descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.REFUNDED)
      };
    case RequestState.DELAY_PAY:
      return {
        severity: 2,
        color: isUrgent ? '#FF4136' : '#C9F88D',
        iconColor: isUrgent ? '#A11A12' : '#6ABD00',
        icon: (request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? 'circle-check' : 'circle-check',
        actionState: ActionState.READY_CHECK,
        state: (request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? this.lang.transform('zwevisaExtValid') : this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.DELAY_PAY + (request.operationId === DocumentType.ZWEENTRY ? '.entry' : request.operationId === DocumentType.ZWEEXIT ? '.exit' : '')),
        descState: (request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? this.lang.transform('zwevisaExtValid.desc') : this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.DELAY_PAY + (request.operationId === DocumentType.ZWEENTRY ? '.entry' : request.operationId === DocumentType.ZWEEXIT ? '.exit' : ''))
      };
    case RequestState.EXPIRED:
      return {
        severity: 3,
        color: '#F88D8D',
        iconColor: '#B50000',
        icon: isBlacklist ? 'ban' : 'xmark-circle',
        actionState: ActionState.REFUSED,
        state: this.lang.transform(((request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? 'payment_status.extension.DYN.' :'payment_status.DYN.') + this.api.env.type + '.' + RequestState.EXPIRED + (isBlacklist ? '.blacklisted' : '')),
        descState: this.lang.transform(((request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? 'payment_status.desc.extension.DYN.' :'payment_status.desc.DYN.') + this.api.env.type + '.' + RequestState.EXPIRED + (isBlacklist ? '.blacklisted' : ''))
      };
    case RequestState.PENDING:
      return {
        severity: 4,
        color: '#FFE455',
        iconColor: '#B39600',
        icon: 'clock',
        state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.PENDING),
        descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.PENDING)
      };
    case RequestState.PAID:
      if(this.api.env.type === DocumentType.PASS) {
        return {
          severity: 2,
          color: '#8FF88D',
          iconColor: '#03B000',
          icon: 'clock',
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type  + '.' + RequestState.PAID),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type  + '.' + RequestState.PAID)
        };
      }
    case RequestState.USED:
    case RequestState.PAID:
      if(request.subState === RequestSubState.REC_ASKED) {
        return {
          severity: 3,
          color: '#B2C3FF',
          iconColor: '#4A66CC',
          icon: 'question-circle',
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + RequestSubState.REC_ASKED),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + RequestSubState.REC_ASKED)
        };
      }
      if(request.subState === RequestSubState.REC_RECEIVED) {
        return {
          severity: 3,
          color: '#B2C3FF',
          iconColor: '#4A66CC',
          icon: 'question-circle',
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + RequestSubState.REC_RECEIVED),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + RequestSubState.REC_RECEIVED)
        };
      }
      if(request.subState === RequestSubState.REFUSED_IN || request.subState === RequestSubState.REFUSED_OUT) {
        return {
          severity: 3,
          color: '#F88D8D',
          iconColor: '#B50000',
          icon: 'xmark-circle',
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.EXPIRED + (request.subState === RequestSubState.REFUSED_IN ? '.checkIn' : '.checkOut')),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.EXPIRED + (request.subState === RequestSubState.REFUSED_IN ? '.checkIn' : '.checkOut'))
        };
      }
      if(request.subState === RequestSubState.CLOSED) {
        return {
          severity: 1,
          color: '#B2C3FF',
          iconColor: '#4A66CC',
          icon: 'right-from-bracket',
          actionState: ActionState.CLOSED,
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + 'closed'),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + 'closed')
        };
      }
      if(request.subState === RequestSubState.CHECKED_IN && document.multiUsage) {
        return {
          severity: 2,
          color: isUrgent ? '#FF4136' : '#8DE5F8',
          iconColor: isUrgent ? '#A11A12' : '#0094B4',
          icon: 'right-from-bracket',
          actionState: ActionState.READY_CHECKOUT,
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + 'out'),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.USED + '.' + 'out')
        };
      } else {
        if(isUsage && request?.usageCount < document.usageCount) {
          return {
            severity: 2,
            color: isUrgent ? '#FF4136' : '#8FF88D',
            iconColor: isUrgent ? '#A11A12' : '#03B000',
            icon: 'right-from-bracket',
            actionState: request.operationId === DocumentType.ZWEEXIT ? ActionState.READY_ZWEEXIT : ActionState.READY_CHECKIN,
            state: (request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? this.lang.transform('zwevisaExtValid') : this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.USED + (this.api.env.type === DocumentType.PASS ? '' :  ('.' + (request.operationId === DocumentType.ZWEEXIT ? 'out' : 'in')))),
            descState: (request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) ? this.lang.transform('zwevisaExtValid.desc') : this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.USED + (this.api.env.type === DocumentType.PASS ? ''  : ('.' + (request.operationId === DocumentType.ZWEEXIT ? 'zweexit' : 'in'))))
          };
        } else {
          return {
            severity: 1,
            color: '#B2C3FF',
            iconColor: '#4A66CC',
            icon: 'right-from-bracket',
            actionState: request.operationId === DocumentType.ZWEENTRY ? (request.subState === RequestSubState.CHECKED_OUT ? ActionState.OUT_FINISH : ActionState.ENTRY_FINISH) : ActionState.OUT_FINISH,
            state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.USED + (this.api.env.type === DocumentType.PASS ? '' : ('.' + (request.operationId === DocumentType.ZWEENTRY ? (request.subState === RequestSubState.CHECKED_OUT ? 'outFinish' : 'entryFinish') : 'outFinish')))),
            descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.USED + (this.api.env.type === DocumentType.PASS ? '' : ('.' + (request.operationId === DocumentType.ZWEENTRY ? (request.subState === RequestSubState.CHECKED_OUT ? 'outFinish' : 'entryFinish') : 'outFinish'))))
          };
        }
      }
    case RequestState.PAID_NOT_CONFIRMED:
    case RequestState.DELAY_PAY_NOT_CONFIRMED:
      if(this.api.env.type === DocumentType.PASS) {
        return {
          severity: 3,
          color: '#FF823C',
          iconColor: '#9D3900',
          icon: 'clock',
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type  + '.' + RequestState.PAID_NOT_CONFIRMED),
          descState: this.lang.transform('payment_status.desc.DYN.' + this.api.env.type  + '.' + RequestState.PAID_NOT_CONFIRMED)
        };
      }
      const isRevisionSinceOneWeek = new Date().getTime() - new Date(request.createdAt).getTime() > 604800000;
      if((request.subState === RequestSubState.REVISIONS_0 && request.operationId !== DocumentType.ZWEVISAEXTAB) || request.subState === RequestSubState.REVISIONS_1) {
        return {
          severity: 3,
          color: (isRevisionSinceOneWeek || isUrgent) ? '#FF4136' : '#FFA14A',
          iconColor: (isRevisionSinceOneWeek || isUrgent) ? '#A11A12' : '#A85100',
          icon: 'clock',
          state: request.operationId === DocumentType.ZWEVISAEXTC ?  request.subState === RequestSubState.REVISIONS_1 ? this.lang.transform('zwevisaExtC.finalReview.state') : this.lang.transform('zwevisaExtC.review.state') : this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.PAID_NOT_CONFIRMED + (this.api.userRole.isCustomer ? '' : ('.' + 'review')), {reviewCount: request.subState}),
          descState: request.operationId === DocumentType.ZWEVISAEXTC ? request.subState === RequestSubState.REVISIONS_1 ? this.lang.transform('zwevisaExtC.finalReview.desc') :  this.lang.transform('zwevisaExtC.review.desc') : this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.PAID_NOT_CONFIRMED + (this.api.userRole.isCustomer ? '' : ('.' + 'review')), {reviewCount: request.subState}) + (isRevisionSinceOneWeek ? ' ' + this.lang.transform('sinceOneWeek') : '')
        };
      } else if(request.subState === RequestSubState.REVISIONS_2 || request.operationId === DocumentType.ZWEVISAEXTAB) {
        return {
          severity: 3,
          color: (isRevisionSinceOneWeek || isUrgent) ? '#FF4136' : '#FF823C',
          iconColor: (isRevisionSinceOneWeek || isUrgent) ? '#A11A12' : '#9D3900',
          icon: 'clock',
          state: this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + RequestState.PAID_NOT_CONFIRMED + (this.api.env.type === DocumentType.ZWEVISA ? (this.api.userRole.isCustomer ? '' : '.finalReview') : '')),
          descState: request.operationId === DocumentType.ZWEVISAEXTAB ? this.lang.transform('final.ZWEVISAEXTAB') : this.lang.transform('payment_status.desc.DYN.' + this.api.env.type + '.' + RequestState.PAID_NOT_CONFIRMED + (this.api.env.type === DocumentType.ZWEVISA ? (this.api.userRole.isCustomer ? '' : '.finalReview') : '')) + (isRevisionSinceOneWeek ? ' ' + this.lang.transform('sinceOneWeek') : '')
        };
      }
    default:
      return {
        severity: 2,
        color: '#C9C9C9',
        iconColor: '#747474',
        icon: 'question',
        state: request.state + '.state',
        descState: request.state + '.desc'
      };
    }
  }

  getHighestSeverityTheme = (app: {batchId: string; requests: Request[]}, request?: Request | RequestWithDuplicata): RequestTheme => {
    let highestSeverity: RequestTheme;
    if(request) {
      const requestTheme = this.getStatusTheme(request as RequestWithDuplicata);
      const extensionsThemes = request.extensions?.map(ext => this.getStatusTheme(ext as RequestWithDuplicata))[0];
      highestSeverity = extensionsThemes && extensionsThemes.severity > requestTheme.severity ? extensionsThemes : requestTheme;

    } else {
      const requestThemes = app.requests.map(req => this.getStatusTheme(req as RequestWithDuplicata));
      const requestsHighestSeverity = requestThemes.length && requestThemes.reduce((prev, curr) => prev.severity > curr.severity ? prev : curr);
      const extensionsThemes = app.requests.map(req => req.extensions?.map(ext => this.getStatusTheme(ext as RequestWithDuplicata))[0])?.filter(ext => ext !== undefined);
      const extensionsHighestSeverity = extensionsThemes.length && extensionsThemes.reduce((prev, curr) => prev.severity > curr.severity ? prev : curr);
      highestSeverity = extensionsHighestSeverity && extensionsHighestSeverity.severity > requestsHighestSeverity.severity ? extensionsHighestSeverity : requestsHighestSeverity;
    }
    return highestSeverity;
  };

  getLatestModificationDate(app: { batchId: string; requests: Request[] }): string {
    return app.requests.reduce((prev, curr) => prev.updatedOn > curr.updatedOn ? prev : curr).updatedOn;
  }

  getBatch(batchId: string) {
    return this.allMyApplications.find(app => app.batchId === batchId);
  }

  isDocumentType(document: DocumentType): boolean {
    const documentType = this.api.userInfo.server.paymentRequests.find(d => d.id === document);
    return documentType.customer;
  }

  isPaidOnline(document: DocumentType | string): boolean {
    const documentType = this.api.userInfo.server.paymentRequests.find(d => d.id === document);
    return documentType?.paidOnline;
  }

  isNextCheckout(request: RequestWithDuplicata): boolean {
    return request?.events?.filter(e => {
      const data = e.internalIndexedData || '';
      return data.indexOf(`|${OperationType.IN}|`) > -1
             || data.endsWith(`|${OperationType.IN}`)
             || data.indexOf(`|${OperationType.INRETURNED}|`) > -1
             || data.endsWith(`|${OperationType.INRETURNED}`);
    }).length > request?.events?.filter(e => {
      const data = e.internalIndexedData || '';
      return data.indexOf(`|${OperationType.OUT}|`) > -1
             || data.endsWith(`|${OperationType.OUT}`);
    }).length;
  }

  detectCheckRefusal(request: RequestWithDuplicata): boolean | OperationType {
    if(request?.events?.length > 0) {
      const lastEvent = request.events[request.events.length - 1];
      const firstEvent = request.events[0];
      if((firstEvent.internalIndexedData || '').includes(`|${OperationType.REFUSEIN}`) || (lastEvent.internalIndexedData || '').includes(`|${OperationType.REFUSEIN}`)) {
        return OperationType.REFUSEIN;
      } else if((firstEvent.internalIndexedData || '').includes(`|${OperationType.REFUSEOUT}`) || (lastEvent.internalIndexedData || '').includes(`|${OperationType.REFUSEOUT}`)) {
        return OperationType.REFUSEOUT;
      }
    }
    return false;
  }

  getDeportedStatus(request: RequestWithDuplicata): PlatformEvent | null {
    if(request?.events?.length > 0) {
      const inReturnedEvent = request.events.find(event => (event.internalIndexedData || '').includes(`|${OperationType.INRETURNED}`));
      if(inReturnedEvent) {
        return inReturnedEvent;
      }
    }
    return null;
  }

  isUsage(request: RequestWithDuplicata): boolean {
    const document = this.api.userInfo.server.paymentRequests.find(d => d.id === request.operationId);
    if(document?.isUsage) {
      return eval(document.isUsage)({Operation: this.isNextCheckout(request) ? OperationType.OUT : OperationType.IN});
    } else {
      return false;
    }
  }

  getFieldValue(formName: VisaFormName, fieldName: VisaFN, config: VisaFormConfig): any {
    const c = config;
    if(!c[formName]?.fields) return undefined;
    return (typeof c[formName].fields?.find((field: FormField) => field.name === fieldName)?.value === 'string' ? c[formName].fields?.find((field: FormField) => field.name === fieldName)?.value : c[formName].fields?.find((field: FormField) => field.name === fieldName)?.value) || undefined;
  }

  getField(formName: VisaFormName, fieldName: string, config: VisaFormConfig): FormField {
    const c = config;
    return c[formName]?.fields?.find((field: FormField) => field.name === fieldName);
  }

  setCountries(): Promise<boolean> {
    if(this.api.isZwevisa) {
      return this.api.schema('zweentry').then((res: any) => {
        const nationalities = res?.properties?.Nationality;
        this.CatA = nationalities?.enumA.map((n: string) => ({value: n, name: this.lang.transform('DYN.' + n)}));
        this.CatB = nationalities?.enumB.map((n: string) => ({value: n, name: this.lang.transform('DYN.' + n)}));
        this.CatC = nationalities?.enumC.map((n: string) => ({value: n, name: this.lang.transform('DYN.' + n)}));
        return true;
      });
    }
    return Promise.resolve(true);
  }

  getNationalities(withRefugees: boolean): {value: string, name: string}[] {
    const noDeclarations: {value: string, name: string}[] = [];
    const sorted = [...this.CatA, ...this.CatB, ...this.CatC].filter(n => withRefugees? true : !n.value.startsWith('XX')).sort((a, b) => {
      if(environment.nationalityNoDeclaration.includes(a.value)) noDeclarations.push(a);
      if(environment.nationalityNoDeclaration.includes(b.value)) noDeclarations.push(b);
      return a.name.localeCompare(b.name);
    });
    if(noDeclarations.length) {
      noDeclarations.sort((a, b) => a.name.localeCompare(b.name));
      noDeclarations.forEach((nd) => {
        const index = sorted.findIndex((n) => n.value === nd.value);
        if(index > -1) {
          sorted.splice(index, 1);
          sorted.unshift(nd);
        }
      });
    }
    return sorted;
  }

  isCountryCat(country: string, cat: 'A' | 'B' | 'C'): boolean {
    const categoryKey: keyof RequestService = `Cat${cat}` as keyof RequestService;
    const category = this[categoryKey];
    if(Array.isArray(category)) {
      return category.findIndex((c: any) => typeof c !== 'string' ? c.value === country : c === country) > -1;
    }
    return false;
  }


  convertCurrency(sourceCurrency: string, amount: number, targetCurrency: string) {
    const sourceRate = this.api.userInfo.rates.rates[sourceCurrency];
    const targetRate = this.api.userInfo.rates.rates[targetCurrency];
    if(!sourceRate || !targetRate) {
      return null;
    }
    const convertedAmount = (amount / sourceRate * targetRate).toFixed(2);
    return {
      currency: targetCurrency,
      amount: convertedAmount
    };
  }

  isUsageDateValid(date: string, type: 'usageAfter' | 'usageUntil') {
    if(type === 'usageAfter') {
      return new Date() >= new Date(date);
    } else {
      return new Date() <= new Date(date);
    }
  }

  isLocalUsageDateValid(date: any): boolean {
    if(!date) {
      return true;
    }
    const parsedDate = new Date(date);
    if(!isValid(parsedDate)) {
      return false;
    }
    return isAfter(endOfDay(parsedDate), endOfDay(new Date()));
  }

  isBlacklisted(request: RequestWithDuplicata): boolean {
    return ['blacklist', 'black list', 'black-list', 'blacklisted', 'black-listed'].some(blacklist => request?.refusalReason?.toLowerCase().includes(blacklist));
  }

  isExtension(request: Request): boolean {
    return request && (request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC);
  }

  checkIfPendingWaiting() {
    if(this.api.userRole.isCustomer) {
      const openDialog = (req: Request) => {
        this.loader.loading(true, {type: 'warn', message: 'ask.pendingRequest', btnLabel: 'yes', custom: {icon: 'clock', closeBtnLabel: 'no'}}).then((done) => {
          if(done) this.nav.to('request-account/' + req.id);
        });
      };
      this.allMyApplications.forEach((app) => {
        app.requests.forEach((req) => {
          if(req.state === RequestState.PENDING) {
            openDialog(req);
            return;
          }
          if(req.extensions.length) {
            req.extensions.forEach((ext) => {
              if(ext.state === RequestState.PENDING) {
                openDialog(req);
                return;
              }
            });
          }
        });
      });
    }
  }

  calculateLocalUntil(documentId: DocumentType, passportType: PassportType | string, nationality: string) {
    if(passportType === undefined) {
      return undefined;
    }
    try {
      const localUntilFromServerString = this.api.userInfo.server.paymentRequests.find(doc => doc.id === documentId)?.localUsageUntilFromNowMillis;
      if(localUntilFromServerString === undefined) {
        return undefined;
      }
      const localUntilFromServer = eval(localUntilFromServerString);
      const unserialized = {PassportType: passportType, Nationality: nationality};
      return localUntilFromServer(unserialized);
    } catch (e) {
      return undefined;
    }
  }

  getRequestData(specificPerson?: Person[]): Promise<void> {
    const relevantPersons = specificPerson?.length ? specificPerson : this.api.listPersons;
    return Promise.all(relevantPersons.map((person: Person) => this.api.personRequests(person.id))).then((payments: Request[][]) => {
      const paymentData = payments.reduce((acc, curVal) => acc.concat(curVal), []);
      paymentData.forEach((payment) => {
        if(payment.internalIndexedData) {
          payment.metadata = parseIndexedData(payment.internalIndexedData, payment.operationId, this.api.userInfo.server);
        } else {
          payment.metadata = isJsonParsable(payment?.metadata);
        }
      });
      if(this.allMyPayments?.length) {
        paymentData.forEach((newPayment) => {
          const existingPaymentIndex = this.allMyPayments.findIndex(payment => payment.id === newPayment.id);
          if(existingPaymentIndex >= 0) {
            this.allMyPayments[existingPaymentIndex] = newPayment;
          } else {
            this.allMyPayments.push(newPayment);
          }
        });
      } else {
        this.allMyPayments = paymentData;
      }
      this.allMyPayments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      // get all batchIds for each person
      this.api.listPersons.forEach((person: Person) => {
        person.batchIds = person.batchIds || [];
        this.allMyPayments.forEach((payment: Request) => {
          if(payment.person_id === person.id && payment.batchId !== null) {
            person.batchIds.push(payment.batchId);
          }
        });
      });
      this.allMyApplications = undefined;
      // get all batchIds for all users
      this.allBatchIds = (specificPerson?.length ? this.allMyPayments : paymentData)?.reduce((acc: string[], request: Request) => {
        if(!acc.includes(request.batchId) && request.batchId && request.operationId !== DocumentType.ZWEVISAEXTAB && request.operationId !== DocumentType.ZWEVISAEXTC) {
          acc.push(request.batchId);
        }
        return acc;
      }, []) || [];
      // get all extensions for all users
      this.allExtensions = paymentData?.filter((request: Request) => request.operationId === DocumentType.ZWEVISAEXTAB || request.operationId === DocumentType.ZWEVISAEXTC) || [];
      //get all applications for all users sorted by batchIds, with each their assigned requests and their extensions within those requests
      this.allMyApplications = this.allBatchIds.map((batchId: string) => ({
        batchId,
        requests: this.allMyPayments.filter((request: Request) => request.batchId === batchId).map((request: Request) => {
          request.extensions = this.allExtensions.filter((extension: Request) => {
            const extensionIndexedData = parseIndexedData(extension.internalIndexedData, extension.operationId, this.api.userInfo.server);
            return extensionIndexedData.visaReference === request.id;
          });
          return request;
        })
      }));
      this.invalidatePersons = [];
    });
  }

  getNameRequest(request: Request): string {
    const {operationId, id, metadata} = request;
    if(operationId === DocumentType.ZWEVISAEXTAB || operationId === DocumentType.ZWEVISAEXTC) {
      const linkedRequest = this.allMyPayments.find(payment => {
        if(payment.extensions && payment.extensions.length) {
          return payment.extensions.find(extension => extension.id === id);
        } else {
          return false;
        }
      });
      if(linkedRequest) {
        const {firstName, lastName} = linkedRequest.metadata;
        return `${firstName} ${lastName}`;
      }
    } else if(metadata?.firstName && metadata?.lastName) {
      return `${metadata.firstName} ${metadata.lastName}`;
    }
    return '';
  }

  isSubjectToUntil(request: Request): boolean {
    const dataActualDocument = this.api.userInfo.server.paymentRequests.find(document => document.id === request?.operationId);
    if(request.operationId === DocumentType.ZWEVISAEXTC || request.operationId === DocumentType.ZWEVISAEXTAB) return false;
    return eval(dataActualDocument.usageUntilFromNowMillis)({
      Nationality: request.serialized.Nationality,
      PassportType: request.serialized.PassportType
    });
  }

  hasNoAwaitingExtension(request: Request): boolean {
    if(!request?.extensions) return true;
    return request?.extensions?.every((request: Request) => request.state !== RequestState.PENDING && request.state !== RequestState.PAID_NOT_CONFIRMED && request.state !== RequestState.DELAY_PAY_NOT_CONFIRMED);
  }

  startExtend(request: Request, metadata?: any) {
    this.loader.loading(true);
    const extendVisaFormConfig: VisaFormConfig = JSON.parse(JSON.stringify(this.cart.getOriginalForm(DocumentType.ZWEVISAEXTC)));

    const meta = metadata || flattenObject(request.serialized as Visa);
    const extendApplication: FormApplication = {
      travelName: undefined,
      plannedEntry: meta.ProvisionnalEntryPort,
      requests: [{
        formConfig: extendVisaFormConfig,
        nationality: meta.Nationality,
        passportType: meta.PassportType,
        visaType: this.api.userInfo.server.paymentRequests.find((doc: Document) => this.isCountryCat(meta.Nationality, 'C') ? doc.id === DocumentType.ZWEVISAEXTC : doc.id === DocumentType.ZWEVISAEXTAB),
        urgent: meta.Urgent,
        person: {
          firstName: meta.FirstName || this.api.listPersons.find((person: Person) => person.id === request.person_id)?.firstName || '',
          lastName: meta.LastName || this.api.listPersons.find((person: Person) => person.id === request.person_id)?.lastName || '',
          nationalNumber: '',
          batchIds: [],
          passportNumber: '',
          id: metadata ? undefined : Number(request.person_id),
          linkType: null,
          otherLinkType: ''
        }
      }],
      visaFeesAndTerms: false,
      isExtend: true,
      requestId: request.id
    };

    this.loader.loading(true, {type: 'info', message: 'extend.start', btnLabel: 'yes', custom: {closeBtnLabel: 'no'}}).then((done ) => {
      if(done) {
        this.cart.startApplication(false, extendApplication, extendVisaFormConfig);
      }
    });
  }

  printReceipt(data: PrintReceiptData) {
    const {
      referencePrint,
      datePrint,
      nameClient,
      amount,
      currency,
      agentId,
      typePayment,
      placeId,
      buyDocument,
      idRequest,
      nationality,
      gender,
      HQReference,
      vendorName,
      vendorAddress,
      vendorEmail
    } = data;
    this.qr.qrOfflineGenerate(undefined, idRequest, undefined);
    const content = [
      {
        type: 'image',
        path: 'assets-projects/logo-print.png',
        position: 'center',
        width: '120px',
        height: '120px',
        style: {
          marginBottom: '10px'
        }
      },
      {
        type: 'text',
        value: this.lang.transform('electronic.receipt'),
        style: {
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14px',
          margin: '0 0 10px 0',
          fontFamily: 'Arial'
        }
      },
      {
        type: 'table',
        style: {border: '1px solid #ddd', width: '90%', marginBottom: '10px', tableLayout: 'fixed', margin: '0 auto', padding: '0'},
        tableHeader: [
          {type: 'text', value: this.lang.transform('desc'), style: {textAlign: 'left', backgroundColor: '#000', color: 'white', fontFamily: 'Arial'}},
          {type: 'text', value: this.lang.transform('details'), style: {textAlign: 'right', backgroundColor: '#000', color: 'white', fontFamily: 'Arial'}}
        ],
        tableBody: [
          [
            {type: 'text', value: this.lang.transform('receiptFor') + '', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: this.lang.transform(buyDocument + '.title'), style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('date') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: format(datePrint, 'dd/MM/yyyy HH:mm'), style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('datePrinted') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: format(new Date(), 'dd/MM/yyyy HH:mm'), style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('client') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: nameClient, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('nationality') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: this.lang.transform('DYN.' + nationality), style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('gender') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: this.lang.transform(gender), style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('HQReference') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: HQReference, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('vendorName') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: vendorName, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('vendorAddress') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: vendorAddress, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('vendorEmail') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: vendorEmail, style: {textAlign: 'right', wordWrap: 'break-word', wordBreak: 'break-all', whitespace: 'normal', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('reference') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: referencePrint, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('agentID') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: agentId, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('form.createPlaceId') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: placeId, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('paymentType') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: this.lang.transform('form.type.' + typePayment), style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],
          [
            {type: 'text', value: this.lang.transform('totalAmount') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
            {type: 'text', value: `${(+amount / 100)} $`, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
          ],

          ...(currency !== Currency.USD ? [
            [
              {type: 'text', value: this.lang.transform('totalAmountCurrency') + ':', style: {textAlign: 'left', fontSize: '10px', fontFamily: 'Arial'}},
              {type: 'text', value: `${this.convertCurrency('USD', +amount / 100, currency).amount} ${CurrencySign[currency]}`, style: {textAlign: 'right', fontSize: '10px', fontFamily: 'Arial'}}
            ]
          ] : [])
        ],
        tableHeaderStyle: {backgroundColor: '#000', color: 'white'},
        tableBodyStyle: {border: '0.5px solid #ddd'},
        tableFooterStyle: {backgroundColor: '#000', color: 'white'}
      },
      {
        type: 'qrCode',
        value: this.qr.qrCode,
        height: 150,
        width: 150,
        position: 'center',
        style: {marginBottom: '10px'}
      }
    ];

    const paperSize = localStorage.getItem('printPaperSize') ? localStorage.getItem('printPaperSize')+'mm' : '80mm';
    return electron.printReceipt({paperSize, content});
  }

  requestHasBeenCorrected(payment: RequestWithDuplicata) {
    const events = payment.events?.sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime()).filter(event => event?.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE || (event?.type === PlatformEventType.PAYMENT_UPDATED && !event?.place_id));
    if(payment.events?.length < 2) return false;
    return events[1]?.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE && events[0].type === PlatformEventType.PAYMENT_UPDATED;
  }

  private normalizeName(name: string): string {
    if(!name) return undefined;
    return name
      .normalize('NFD') // Décompose les caractères accentués en lettres de base + accents
      .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
      .toUpperCase() // Convertit en majuscules
      .replace(/[^A-Z' -]/g, ''); // Conserve uniquement les lettres, apostrophes, tirets et espaces
  }
}

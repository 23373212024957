<div id="form_reset_btn" class="resp_minSize_l mt_10 flex f_center" *ngIf="cart.currentRequest && api.userRole.isKiosk">
  <app-button color="green" size="xsm" (btnClicked)="cart.resetKioskRequest()"> {{ 'onArrival.reset' | translate }}</app-button>
</div>
<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background">
    <div class="flex gap_10 f_align_c">
      {{'summary.title' | translate}} <span *ngIf="api.userRole.isCustomer">- {{currentApplication.travelName}}</span>
    </div>
  </app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <ng-container *ngIf="!currentApplication.isExtend">
        <div class="summary_block mt_10" *ngIf="api.userRole.isCustomer">
          <div class="summary_line flex f_between gap_5">
            <b>{{'travelName' | translate}}: </b>
            {{currentApplication.travelName}}
          </div>
          <div class="summary_line flex f_between gap_5">
            <b>{{'plannedEntry' | translate}}: </b>
            {{currentApplication.plannedEntry}}
          </div>
        </div>
        <app-divider [classList]="'mt_10 mb_10'" *ngIf="api.userRole.isCustomer"></app-divider>
      </ng-container>
      <div class="resp_maxSize_l mt_10"></div>
      <form id="form" [formGroup]="form" (ngSubmit)="submit()">
        <ng-container *ngFor="let req of currentApplication.requests; let i = index" [class.mt_10]="i > 0">
          <div class="summary_block" [class.mt_10]="i > 0">
            <app-collapse title="{{api.userRole.isCustomer ? (req.person.firstName+ ('summary.meta' | translate)) : 'summary.your_meta' | translate}}">
            <div>
              <div class="summary_line flex f_between gap_5" *ngIf="api.userRole.isCustomer">
                <b>{{'person.name' | translate}}: </b>
                {{req.person.firstName}} {{req.person.lastName}}
              </div>
              <div class="summary_line flex f_between gap_5" *ngIf="req.nationality">
                <b>{{'form.nationality' | translate}}: </b>
                {{('DYN.'+req.nationality) | translate}}
              </div>
              <div class="summary_line flex f_between gap_5" *ngIf="req.passportType">
                <b>{{'passportType' | translate}}: </b>
                {{('DYN.'+req.passportType) | translate}}
              </div>
              <div class="summary_line flex f_between gap_5">
                <b>{{'visaType' | translate}}: </b>
                {{req.visaType.id | translate}}
              </div>
              <div class="summary_line flex f_between gap_5" *ngIf="req.urgent">
                <b>{{'urgent' | translate}}: </b>
                {{req.urgent}}
              </div>
              <ng-container *ngFor="let field of getConfigFields(req.formConfig)">
                <div *ngIf="field.showInResume && field.value" class="summary_line flex f_between gap_5">
                  @if(field.type === 'file' && field.fileName) {
                    <b>{{field.name | translate}}:</b>
                    <span>{{field.fileName | translate}}</span>
                  } @else if(field.type === 'date' && field.value) {
                    <b>{{field.name | translate}}:</b>
                    <span>{{field.value | date: 'dd/MM/yyyy'}}</span>
                  } @else if(field.type !== 'file' && field.type !== 'date' && field.value) {
                    <b>{{field.name | translate}}:</b>
                    <span [style.text-align]="'right'">{{(field.name === VisaFN.PURPOSE_OF_VISIT ? 'DYN.'+field.value : field.value) | translate}}</span>
                  }
                </div>
              </ng-container>
            </div>
            </app-collapse>
            <app-paragraph class="pl_20 pr_20" innerhtml="{{('DYN.payment_text.' + req.visaType.id) | translate}}"></app-paragraph>
            <app-desc-card leftIcon="tag"
                           [classList]="'no-pointer'"
                           descL="{{getPrice(req, false, true) > 0 ? (('amountDue' | translate) + '<b>' + (getPrice(req) + ' ' + CurrencySign[req.visaType?.currency])) + '</b>' : (cart.currentApplication.isRevision ? 'revision.free' : 'free.charge') | translate}}"
                           descR1="{{api.userRole.isKiosk ? (request.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, req.formConfig) + ' ' + request.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, req.formConfig)) : (req.person.firstName + ' ' + req.person.lastName)}}"
                           descR2="{{req.visaType.id + '.title' | translate}}"></app-desc-card>
            <div class="mt_10" *ngIf="(request.isCountryCat(req.nationality, 'A') || request.isCountryCat(req.nationality, 'B')) && !cart.currentApplication?.isExtend">
              <div class="warning-container">
                <div class="flex">
                  <fa-icon class="mr_20" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
                  <app-paragraph class="refusal-desc" [innerhtml]="'summary.warning_validity' | translate"></app-paragraph>
                </div>
              </div>
            </div>
            <div class="mt_10">
              <app-checkbox-element text="{{(cart.currentApplication?.isExtend ? VisaFN.CHECKBOX_VISA_EXTEND_FEES : VisaFN.CHECKBOX_VISA_FEES) | translate}}" formCtrlName="visaFeesAndTerms_{{i}}" [formGrp]="form"></app-checkbox-element>
            </div>
            <app-errors-list [dark]="true" *ngIf="submitted"
                             [formErrors]="getError('visaFeesAndTerms_'+i)"></app-errors-list>
          </div>
        </ng-container>
        <div *ngIf="!api.userRole.isKiosk && !api.userRole.isWorker && showPayOnline" class="mt_10">
          <app-divider [classList]="'mt_10'"></app-divider>
          <div class="mt_10 summary_choice">
            <div class="mb_10">
              <app-paragraph>{{'summary.payOnline.desc' | translate}}</app-paragraph>
            </div>
            <lib-choice-button [formGrp]="form"
                               formCtrlName="payOnline"
                               [buttons]="choiceButtons" size="xsm"
                               (output)="checkPayOnline($event)"
                               [text]="('summary.payOnline' | translate)"></lib-choice-button>
          </div>
        </div>
        <div *ngIf="currentApplication.requests.length > 1 && (getTotalPriceNr(true) > 0 || getTotalPriceNr(false) > 0)" class="mt_10">
          <app-divider [classList]="'mt_10'"></app-divider>
          <div class="shoppingTotal mt_10" *ngIf="getTotalPrice(true, false) > 0 && cart.currentApplication.payOnline === 'false'">
            <app-desc-card leftIcon="cash-register"
                           [classList]="'no-pointer'"
                           descL="{{getTotalPrice(true, false) > 0 && ('amountDue.total.later' | translate) + '<b>' + (getTotalPrice(false, false) + ' ' + CurrencySign[currentApplication.requests[0].visaType?.currency]) + '</b>'}}"
                           descR1=""
                           descR2=""></app-desc-card>
          </div>
          <div class="shoppingTotal mt_10" *ngIf="getTotalPrice(true, true) > 0">
            <app-desc-card leftIcon="credit-card"
                           [classList]="'no-pointer'"
                           descL="{{getTotalPrice(true, true) > 0 && ('amountDue.total.now' | translate) + '<b>' + (getTotalPrice(false, true) + ' ' + CurrencySign[currentApplication.requests[0].visaType?.currency]) + '</b>'}}"
                           descR1=""
                           descR2=""></app-desc-card>
          </div>
        </div>
        <div *ngIf="currentApplication.requests.length > 1 && !getTotalPriceNr(true) && !getTotalPriceNr(false)" class="mt_10">
          <app-divider [classList]="'mt_10'"></app-divider>
          <div class="shoppingTotal mt_10">
            <app-desc-card leftIcon="credit-card"
                           [classList]="'no-pointer'"
                           descL="{{'free.charge.total' | translate}}"
                           descR1=""
                           descR2=""></app-desc-card>
          </div>
        </div>
      </form>
      <div class="resp_minSize_l mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (btnClicked)="previous()">
          {{'global.back' | translate}}
        </app-button>
        <app-button [disabled]="disabledConfirm" size="xsm" type="submit" formId="form" class="fullWidth" [debounceTimeInMilliseconds]="2000">
          {{(cart.currentApplication?.isRevision ? 'confirm.revision' : 'global.confirm') | translate}}
        </app-button>
      </div>
    </div>
  </div>
  <div class="mb_20 resp_maxSize_l">
    <div class="f_align_end gap_10">
      <app-back-btn *ngIf="!api.userRole.isKiosk" class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-back-btn (btnClicked)="cart.resetKioskRequest()" [color]="'#F54321'" icon="arrow-rotate-left" *ngIf="cart.currentRequest?.nationality && cart.currentRequest?.passportType && api.userRole.isKiosk"></app-back-btn>
      <app-back-btn [navTo]="{route: 'form-visa'}"></app-back-btn>
      <div class="fullWidth">
        <app-button [disabled]="disabledConfirm" type="submit" formId="form" class="fullWidth" [debounceTimeInMilliseconds]="2000">
          {{(cart.currentApplication?.isRevision ? 'confirm.revision' : 'global.confirm') | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>

<div id="form_reset_btn" class="resp_minSize_l mt_10 flex f_center" *ngIf="cart.currentRequest?.nationality && cart.currentRequest?.passportType && api.userRole.isKiosk">
  <app-button color="green" size="xsm" (btnClicked)="cart.resetKioskRequest()"> {{ 'onArrival.reset' | translate }}</app-button>
</div>
<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background">
    <div class="flex gap_10 f_align_c">
      {{ 'passportType.title' | translate }}
      <app-snackbar [classList]="'cursor-pointer'" (click)="cart.openParticipants()" *ngIf="cart.currentRequest && api.userRole.isCustomer && !cart.currentApplication.isRevision">
        <fa-icon icon="bars" class="mr_10"></fa-icon>
        {{ cart.currentRequest.person.firstName }} {{ cart.currentRequest.person.lastName[0] }}.
      </app-snackbar>
    </div>
  </app-view-title>
  <div class="scroll_wrapper">
    <div class="f_center mt_20" *ngIf="cart?.currentApplication?.isRevision">
      <app-checkbox-element size="sm" [text]="'skip.valid' | translate" [checked]="cart.currentApplication.skipValidRevision" (onChange)="cart.changeSkip($event.checked)" class="check-validStep"></app-checkbox-element>
    </div>
    <div class="resp_wrapper">
      <div class="resp_c-50 mt_20 nationality_section">
        <div class="flex gap_10 f_align_c">
          <img src="./assets-projects/images/nationality.png" width="80" alt="">
          <div>
            <app-paragraph>{{ 'nationality.desc' | translate }}</app-paragraph>
            <form id="form" class="mt_24" [formGroup]="form" (ngSubmit)="submit()">
              <app-input type="select" [selectOptions]="nationalities" formCtrlName="nationality"
                         [formGrp]="form" label="form.nationality" (output)="setDocumentTypes(true)"></app-input>
              <app-errors-list [dark]="true" *ngIf="submitted"
                               [formErrors]="forms.getFormErrors(form)"></app-errors-list>
            </form>
          </div>
        </div>
      </div>
    </div>
      <app-paragraph textAlign="center" class="mt_10" *ngIf="form.controls.nationality.value">{{ 'passportType.desc' | translate }}</app-paragraph>
      <div class="parent-container parent-container_md mt_20 mb_40">
        <div class="flex flex_col f_center gap_10 f_wrap" *ngIf="form.controls.nationality.value">
          <ng-container *ngFor="let passportType of documentTypes; let i = index">
            <div class="big_btn big_btn-resp" (click)="selectPassportType(passportType)" [shortcut]="shortcuts[i]"
                 [class.big_btn-selected]="passportType === selected">
              <img src="assets-projects/images/passport_{{passportType}}.png" alt="">
              <span>{{ 'DYN.' + passportType | translate }}</span>
              <p>{{ 'DYN.' + passportType + '.desc' | translate }}</p>
            </div>
          </ng-container>
        </div>
        <div class="resp_wrapper">
          <div class="resp_minSize_l mt_40 mb_20 flex gap_10 resp_c-50">
            <app-button size="xsm" color="transparent" border="true" class="fullWidth"
                        (click)="previous()" [shortcut]="ShortcutAction.ESCAPE" [shortcutStyle]="{bottom: '-25px'}">
              {{ 'global.back' | translate }}
            </app-button>
            <app-button size="xsm" type="submit" (click)="submit()" [shortcut]="ShortcutAction.ENTER" [shortcutStyle]="{bottom: '-25px'}" formId="form" class="fullWidth" [disabled]="!selected || lock">
              {{ 'global.continue' | translate }}
            </app-button>
          </div>
        </div>
      </div>
  </div>
  <div class="mb_20 resp_maxSize_l">
    <div class="f_align_end gap_10">
      <app-back-btn *ngIf="!api.userRole.isKiosk" class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-back-btn (btnClicked)="cart.resetKioskRequest()" [color]="'#F54321'" icon="arrow-rotate-left" *ngIf="cart.currentRequest?.nationality && cart.currentRequest?.passportType && api.userRole.isKiosk"></app-back-btn>
      <app-back-btn (btnClicked)="previous()"></app-back-btn>
      <div class="fullWidth">
        <app-button type="submit" formId="form" class="fullWidth" [disabled]="!selected || lock">
          {{ 'global.continue' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</div>

<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="email-send" [chooseLangBtn]="true">{{'emailSend.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <app-view-deco-image imageUrl="assets-projects/images/image_mail_sent.svg"></app-view-deco-image>
      <p>{{'emailSend.description' | translate}}</p>
      <app-button size="sm" color="transparent" border="true" type="button" (click)="nav.to('sign-in')" class="fullWidth mt_20 resp_minSize_l">{{'close' | translate}}</app-button>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <div class="fullWidth">
      <app-button color="transparent" border="true" type="button" (click)="nav.to('sign-in')" class="fullWidth">{{'close' | translate}}</app-button>
    </div>
  </div>
</div>

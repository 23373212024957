<mat-dialog-content class="dialog_content">
  <p class="mb-4">{{(loader.BTdevices?.length > 0 ? 'bt.available_devices' : 'bt.no_available_devices') | translate}}</p>
  <ul>
    <li *ngFor="let device of loader.BTdevices" [class.active]="loader.BTConnectedDevice === device" class="flex justify-between w-full mb-2">
      {{device}}
      <button class="btn" [class.btn_warning]="loader.BTConnectedDevice === device" (click)="loader.BTConnectedDevice === device ? disconnectBTDevice(device) : connectBTDevice(device)">{{(loader.BTConnectedDevice === device ? 'global.disconnect' : 'global.connect') | translate}}</button>
    </li>
  </ul>
  <div class="flex justify-between mt-4">
    <button class="btn w-1/2 mr-1" type="button" (click)="closeDialog()">{{'global.close' | translate}}</button>
    <button class="btn w-1/2 ml-1" type="button" (click)="getBTDevices()">{{'global.refresh' | translate}}</button>
  </div>
  <div class="flex justify-between mt-2" *ngIf="loader.BTConnectedDevice">
    <button class="btn w-full" type="button" (click)="print()">{{'global.print' | translate}}</button>
  </div>
</mat-dialog-content>

<div [hidden]="true" *ngIf="loader.qrCode">
  <qrcode [qrdata]="loader.qrCode" [width]="300" [errorCorrectionLevel]="'M'" [elementType]="'url'"></qrcode>
</div>

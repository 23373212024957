<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_20" fromRoute="pre-enroll" [chooseLangBtn]="false">{{data?.name}}</app-view-title>
  <app-snackbar Icon="ban" type="error" class="firstLetter f_center" *ngIf="data?.lockedAt">{{'global.blocked' | translate}}</app-snackbar>
  <div class="scroll_wrapper resp_wrapper resp_row">
    <div class="resp_l-50 resp_scroll-wrapper_l">
      <div class="mb_20">
        <app-slide-element leftIcon="user" rightIcon="ban" class="mt_10" (btnClicked)="lockUser(!data?.lockedAt)">{{(data?.lockedAt ? 'global.unblock' : 'global.block') | translate}}</app-slide-element>
        <app-slide-element leftIcon="location-dot" class="mt_10" (btnClicked)="nav.to(actualRoute + '/places-choice')">{{'menu.placesPerm' | translate}}</app-slide-element>
        <app-slide-element leftIcon="cash-register" class="mt_10" (btnClicked)="goToUserPayment()">{{('global.toPayments.DYN.' + api.env.type) | translate}}</app-slide-element>
        <app-slide-element leftIcon="pen-to-square" [rightIcon]="disabled ? 'none' : 'xmark'" class="mt_10" (btnClicked)="toggleChange()">{{(disabled ? 'modifyData' : 'stopModifyData') | translate}}</app-slide-element>

      </div>
      <app-divider class="mb_10 mt_4"></app-divider>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="goToPreviousUrl()">{{'global.back' | translate}}</app-button>
      <app-button size="sm" *ngIf="!disabled" formId="form" class="fullWidth resp_minSize_l mt_4" (btnClicked)="updateUser()">{{'payments.validate' | translate}}</app-button>
    </div>
    <div class="resp_r-50 resp_scroll-wrapper_l">
      <form *ngIf="data" id="form" [formGroup]="form" class="mt_20">
        <app-input class="mb_30" icon="at" type="email" formCtrlName="email" [formGrp]="form" label="{{'form.email' | translate}}"></app-input>
        <app-input class="mb_30" icon="user" type="text" formCtrlName="name" [formGrp]="form" label="{{'form.name' | translate}}"></app-input>
        <app-input class="mb_30" icon="briefcase" type="select" formCtrlName="role" [selectOptions]="roles" [formGrp]="form" label="{{'form.role' | translate}}"></app-input>
        <app-input class="mb_30" icon="hashtag" type="email" formCtrlName="userId" [formGrp]="form" label="{{'form.userId' | translate}}"></app-input>
        <app-input icon="hashtag" formCtrlName="id" [formGrp]="form" label="{{'label.numberAccount' | translate}}"></app-input>
      </form>
      <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button *ngIf="disabled" color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="goToPreviousUrl()">{{'global.back' | translate}}</app-button>
      <app-button *ngIf="!disabled" formId="form" class="fullWidth" (btnClicked)="updateUser()">{{'payments.validate' | translate}}</app-button>
    </div>
      <app-back-btn *ngIf="!disabled" class="ml_10" icon="xmark" (btnClicked)="toggleChange()"></app-back-btn>
  </div>
</div>


<div id="layout" class="sign_in f_col">
    <app-view-title class="mb_20" fromRoute="pre-enroll" [chooseLangBtn]="false">{{place?.currentPlace?.longName}}</app-view-title>
    <div class="scroll_wrapper resp_wrapper resp_row">
      <div class="resp_l-50 resp_scroll-wrapper_l">
        <app-snackbar Icon="user" class="f_center">{{place?.user?.name}}</app-snackbar>
        <app-slide-element leftIcon="location-dot" class="mt_10 mb_5" (btnClicked)="nav.to('place/'+ placeId)">{{'place.sheet.open' | translate}}</app-slide-element>
        <app-slide-element leftIcon="location-dot" rightIcon="link-slash" class="mb_10" (btnClicked)="removePlace()" >{{'place.delete.link' | translate}}</app-slide-element>
        <app-divider class="mb_10"></app-divider>
        <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="nav.to('user/'+userId+'/places-choice')">{{'global.back' | translate}}</app-button>
      </div>
      <div class="resp_r-50 resp_scroll-wrapper_l">
        <form *ngIf="form" class="f_col gap_10" [formGroup]="form" (ngSubmit)="updatePermissions()" id="form">
          <app-checkbox-element *ngIf="environment.type === DocumentType.PASS" [formGrp]="form" formCtrlName="perm1" [checked]="place?.userPlace?.allowCreate" leftIcon="user" [icons]="['circle', 'circle-check']" text="{{'form.perm1' | translate}}"></app-checkbox-element>
          <app-checkbox-element [formGrp]="form" formCtrlName="perm2" [checked]="place?.userPlace?.allowConsume" leftIcon="user" [icons]="['circle', 'circle-check']" text="{{('form.perm2.DYN.' + environment.type) | translate}}"></app-checkbox-element>
          <app-checkbox-element [formGrp]="form" formCtrlName="perm3" [checked]="place?.userPlace?.allowControl" leftIcon="user" [icons]="['circle', 'circle-check']" text="{{('form.perm3.DYN.' + environment.type) | translate}}"></app-checkbox-element>
          <app-checkbox-element  [formGrp]="form" formCtrlName="perm4" [checked]="place?.userPlace?.allowAdmin" leftIcon="user" [icons]="['circle', 'circle-check']" text="{{('form.perm4.DYN.' + environment.type) | translate}}"></app-checkbox-element>
          <app-input class="mt_10" *ngIf="showSeniority && api.env.type === DocumentType.ZWEVISA" type="number" [formGrp]="form" formCtrlName="seniority" label="{{'form.seniority' | translate}}"></app-input>
          <div class="perm-btn">
            <app-button color="green" size="xsm" type="submit" formId="form" class="fullWidth child-btn resp_minSize_l">{{'global.update' | translate}}</app-button>
            <app-button color="green" type="submit" formId="form" class="fullWidth child-btn resp_maxSize_l">{{'global.update' | translate}}</app-button>
            <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
          </div>
        </form>
      </div>
    </div>
    <div class="f_align_end f_grow mb_20 resp_maxSize_l">
      <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <div class="ml_10 fullWidth">
        <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.to('user/'+userId+'/places-choice')">{{'global.back' | translate}}</app-button>
      </div>
    </div>
  </div>

export function flattenObject(obj: any): any {
  const result: any = {};

  for(const key in obj) {
    if(Array.isArray(obj[key])) {
      // Si la propriété est un tableau, fusionnez son contenu avec l'objet parent
      obj[key].forEach((item: any, index: number) => {
        result[`${key}_${index + 1}`] = item;
      });
    } else if(typeof obj[key] === 'object' && obj[key] !== null) {
      // Si la propriété est un objet, fusionnez ses clés/valeurs avec l'objet parent
      const flattenedObject = flattenObject(obj[key]);
      for(const subKey in flattenedObject) {
        result[`${subKey}`] = flattenedObject[subKey];
      }
    } else {
      // Sinon, copiez simplement la propriété dans l'objet résultant
      result[key] = obj[key];
    }
  }
  return result;
}

<div id="print">
  <div class="invoice-container">
    <div class="logo">
      <img src="assets-projects/logo-print-color.png" width="140" height="140">
    </div>
    <p class="header">
      {{'electronic.receipt' | translate}}
    </p>
    <div class="print_content">
      <table class="details-table">
        <thead>
          <tr>
            <th>{{'desc' | translate}}</th>
            <th class="right-align">{{'details' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{'receiptFor' | translate}}:</td>
            <td class="right-align">{{receiptData.buyDocument + '.title' | translate}}</td>
          </tr>
          <tr>
            <td>{{'date' | translate}}:</td>
            <td class="right-align">{{format(receiptData.datePrint, 'dd/MM/yyyy HH:mm')}}</td>
          </tr>
          <tr>
            <td>{{'client' | translate}}:</td>
            <td class="right-align">{{receiptData.nameClient}}</td>
          </tr>

          <tr>
            <td>{{'nationality' | translate}}:</td>
            <td class="right-align">{{receiptData.nationality}}</td>
          </tr>
          <tr>
            <td>{{'gender' | translate}}:</td>
            <td class="right-align">{{receiptData.gender}}</td>
          </tr>
          <tr>
            <td>{{'HQReference' | translate}}:</td>
            <td class="right-align">{{receiptData.HQReference}}</td>
          </tr>
          <tr>
            <td>{{'vendorName' | translate}}:</td>
            <td class="right-align">{{receiptData.vendorName}}</td>
          </tr>
          <tr>
            <td>{{'vendorAddress' | translate}}:</td>
            <td class="right-align">{{receiptData.vendorAddress}}</td>
          </tr>
          <tr>
            <td>{{'vendorEmail' | translate}}:</td>
            <td class="right-align">{{receiptData.vendorEmail}}</td>
          </tr>
          <tr>
            <td>{{'idRequest' | translate}}:</td>
            <td class="right-align">{{receiptData.idRequest}}</td>
          </tr>
          <tr>
            <td>{{'serialNumber' | translate}}:</td>
            <td class="right-align">{{receiptData.serialNumber}}</td>
          </tr>
          <tr>
            <td>{{'reference' | translate}}:</td>
            <td class="right-align">{{receiptData.referencePrint}}</td>
          </tr>
          <tr>
            <td>{{'agentID' | translate}}:</td>
            <td class="right-align">{{receiptData.agentId}}</td>
          </tr>
          <tr>
            <td>{{'form.createPlaceId' | translate}}:</td>
            <td class="right-align">{{receiptData.placeId}}</td>
          </tr>
          <tr>
            <td>{{'paymentType' | translate}}:</td>
            <td class="right-align">{{('form.type.' + receiptData.typePayment) | translate}}</td>
          </tr>
          <tr>
            <td>{{'totalAmount' | translate}}:</td>
            <td class="right-align">{{(receiptData.amount / 100).toFixed(2)}} $</td>
          </tr>
          <ng-container *ngIf="receiptData.currency !== 'USD'">
            <tr>
              <td>{{'totalAmountCurrency' | translate}}:</td>
              <td class="right-align">{{request.convertCurrency('USD', receiptData.amount / 100, receiptData.currency).amount}} {{receiptData.currency}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="qr-code mt_20">
      <qrcode class="qr-print mt-4" [qrdata]="qr.qrCode" [width]="220" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
    </div>
  </div>
</div>

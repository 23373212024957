<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="email-valid" [chooseLangBtn]="true">{{'emailValid.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <app-view-deco-image imageUrl="assets-projects/images/image_email_done.svg"></app-view-deco-image>
      <p class="text-center">{{'emailValid.description' | translate : {appName: env.name} }}</p>
      <app-button size="sm" type="button" (click)="nav.to('sign-in')" class="fullWidth mt_20 resp_minSize_l">{{'global.connect' | translate}}</app-button>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <div class="fullWidth">
      <app-button type="button" (click)="nav.to('sign-in')" class="fullWidth">{{'global.connect' | translate}}</app-button>
    </div>
  </div>
</div>

<div id="layout" class="qr_code f_col">
    <div class="scroll_wrapper resp_wrapper">
      <div class="resp_c-50 resp_scroll-wrapper_l">
        <div class="mt_40">
          <h1 class="text_center">{{'qr.title' | translate}}</h1>
          <span class="text_center mb_30">{{('qr.desc.DYN.' + api.env.type) | translate}}</span>
        </div>
        <div class="f_center mb_40">
          <div class="qrcode mt_40">
              <qrcode *ngIf="qr.qrCode" class="f_center" [qrdata]="qr.qrCode" [width]="300" [errorCorrectionLevel]="'M'" allowEmptyString="true"></qrcode>
          </div>
        </div>
        <div class="f_col gap_10">
          <form id="form" [formGroup]="form" class="mt_20">
            <app-input *ngIf="BTdevices" icon="print" type="select" formCtrlName="devices" [formGrp]="form" label="{{'print.name' | translate}}"  [selectOptions]="printSO" (change)="changeSelect($event)"></app-input>
          </form>
          <app-button *ngIf="device.isDevices('cordova')" formId="form" class="fullWidth" (btnClicked)="print()">{{'global.print_c7' | translate}}</app-button>
          <app-button formId="form" class="fullWidth resp_maxSize_l" (btnClicked)="printA4()">{{'global.print.a4' | translate}}</app-button>
          <app-button size="sm" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="printA4()">{{'global.print.a4' | translate}}</app-button>
          <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth backbtn resp_minSize_l" (btnClicked)="nav.to('admin-request-select/' + paymentId,undefined, {queryParams: {from: this.from}})">{{'global.close' | translate}}</app-button>
        </div>
      </div>
  </div>
    <div class="f_align_end f_grow mb_20 fullWidth resp_maxSize_l">
      <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <div class="ml_10 fullWidth">
        <app-button  color="transparent" border="true" formId="form" class="fullWidth backbtn" (btnClicked)="nav.to('admin-request', undefined, {queryParams: {from: this.from}})">{{'global.close' | translate}}</app-button>
      </div>
    </div>
</div>
<app-print *ngIf="qr.currentQrData"></app-print>

import {Validators} from '@angular/forms';
import {VisaFN, VisaFormConfig, VisaFormName} from 'src/app/shared/models/forms';
import {endOfDay, format, subYears} from 'date-fns';

export interface Visa {
  AddressZimbabwe: string;
  BirthDay: number;
  Documents: {
    PurposeOfVisit: string;
  };
  FirstName: string;
  Gender: 'male'|'female'|'other';
  HostName: string;
  LastName: string;
  MaritalStatus: 'single'|'married'|'separated'|'divorced'|'widowed';
  Nationality: string;
  PreviousConvictionsZim: string;
  PreviousConvictionsHome: string;
  NextOfKin?: string;
  FundsAvailableCurrency?: string,
  FundsAvailable?: number,
  PassportType?: string,
}

//Model to represent the form config
export const VISA_ARRIVAL_FORM_CONFIG: VisaFormConfig = {
  [VisaFormName.PERSONAL_DETAILS]: {
    version: 0.1,
    classNames: 'gap_25',
    fields: [
      {name: VisaFN.PASSPORT_NUMBER, type: 'text', icon: 'passport', validators: [Validators.required, Validators.pattern('^[A-Za-z0-9 \\-]+$')], showInResume: true},
      {name: VisaFN.PASSPORT_COUNTRY, type: 'select', icon: 'location-dot', showInResume: true, validators: [Validators.required]},
      {name: VisaFN.PURPOSE_OF_VISIT, type: 'select', icon: 'question', validators: [Validators.required], showInResume: true},
      {name: VisaFN.LAST_NAME, type: 'text', icon: 'person', validators: [Validators.required, Validators.pattern('^[A-Za-z \\-]+$')], showInResume: true},
      {name: VisaFN.FIRST_NAME, type: 'text', icon: 'person', validators: [Validators.required, Validators.pattern('^[A-Za-z \\-]+$')], showInResume: true},
      {name: VisaFN.OTHER_NAME, type: 'text', icon: 'person', validators: [Validators.pattern('^[A-Za-z \\-]+$')],  showInResume: true, tooltip: 'tooltip.otherName'},
      {name: VisaFN.COMPANIONS_SIZE, type: 'number', icon: 'users', showInResume: true},
      {name: VisaFN.BIRTH_DAY, type: 'date', icon: 'cake-candles', validators: [Validators.required], dateValidators: {maxDate: format(endOfDay(new Date()), 'yyyy-MM-dd'), minDate: format(subYears(new Date(), 150), 'yyyy-MM-dd')}, showInResume: true},
      {name: VisaFN.GENDER, type: 'select', icon: 'venus-mars', enum: ['male', 'female'], validators: [Validators.required], showInResume: true},
      {name: VisaFN.MARITAL_STATUS, type: 'select', icon: 'people-arrows', enum: ['single', 'married', 'separated', 'divorced', 'widowed'], validators: [Validators.required], showInResume: true},
      {name: VisaFN.HOME_ADDRESS, type: 'text', icon: 'house', showInResume: true, validators: []},
      {name: VisaFN.HOST_NAME, type: 'text', icon: 'person', validators: [], showInResume: true, tooltip: 'tooltip.hostName'},
      {name: VisaFN.OCCUPATION, type: 'text', icon: 'briefcase', validators: [Validators.maxLength(50)], showInResume: true},
      {name: VisaFN.ADDRESS_ZIMBABWE, type: 'text', icon: 'location-dot', showInResume: true, tooltip: 'tooltip.addressZimbabwe', validators: []},
      {name: VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, type: 'choice', choiceButtons: [
        {text: 'Yes', value: 'true', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
        {text: 'No', value: 'false', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
      ], validators: [Validators.required]},
      {name: VisaFN.PREVIOUS_CONVICTIONS_ZIM, type: 'text', icon: 'handcuffs', requiredIfFormControl: true, ifFormControl: VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, showInResume: true, validators: []},
      {name: VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, type: 'choice', choiceButtons: [
        {text: 'Yes', value: 'true', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
        {text: 'No', value: 'false', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
      ], validators: [Validators.required]},
      {name: VisaFN.PREVIOUS_CONVICTIONS_HOME, type: 'text', icon: 'handcuffs', requiredIfFormControl: true, ifFormControl: VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, showInResume: true, validators: []},
      {name: VisaFN.FUNDS_AVAILABLE, type: 'number', icon: 'piggy-bank', validators: [Validators.required], showInResume: true, sameRow: 'currency'},
      {name: VisaFN.FUNDS_AVAILABLE_CURRENCY, type: 'select', icon: 'dollar-sign', validators: [Validators.required], showInResume: true, sameRow: 'currency'},
      {name: VisaFN.NON_ORDINARY_PASS_MISSION, type: 'text', icon: 'briefcase', showInResume: true, hidden: true}
    ]
  },
  [VisaFormName.EMERGENCY_CONTACT]: {
    version: 0,
    classNames: 'gap_25',
    textBeforeForm: {
      text: 'emergency_contact_text'
    },
    fields: [
      {name: VisaFN.EMERGENCY_LAST_NAME, type: 'text', icon: 'person', validators: [Validators.maxLength(50)], showInResume: true},
      {name: VisaFN.EMERGENCY_FIRST_NAME, type: 'text', icon: 'person', validators: [Validators.maxLength(50)], showInResume: true},
      {name: VisaFN.EMERGENCY_EMAIL, type: 'text', icon: 'at', validators: [Validators.email, Validators.maxLength(100)], showInResume: true},
      {name: VisaFN.EMERGENCY_PHONE, type: 'text', icon: 'phone', validators: [Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'), Validators.maxLength(20)], customExplain: 'phone.pattern', showInResume: true}
    ]
  }
};

<div id="print-receipt-a5">
  <img class="print-receipt_bg hide_info" src="./assets-projects/print_bg.jpg" alt="">
  <div class="invoice-container">
    <table><tbody><tr><td style="width: 50%;">
      <div class="print_content hide_info2" style="margin-top: 100px;">
        <table class="receipt-table">
          <thead>
          <tr class="hide_info2">
            <th>{{'desc' | translate}}</th>
            <th class="right-align">{{'details' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td innerHtml="{{'receiptFor.alt' | translate}}:"></td>
            <td class="right-align">{{receiptData.buyDocument + '.title' | translate}}</td>
          </tr>
          <tr>
            <td>{{'date' | translate}}:</td>
            <td class="right-align">{{format(receiptData.datePrint, 'dd/MM/yyyy HH:mm')}}</td>
          </tr>
          <tr>
            <td>{{'client' | translate}}:</td>
            <td class="right-align">{{receiptData.nameClient}}</td>
          </tr>

          <tr>
            <td>{{'nationality' | translate}}:</td>
            <td class="right-align">{{('DYN.' + receiptData.nationality) | translate}}</td>
          </tr>
          <tr>
            <td>{{'gender' | translate}}:</td>
            <td class="right-align">{{receiptData.gender}}</td>
          </tr>
          <tr>
            <td>{{'HQReference' | translate}}:</td>
            <td class="right-align">{{receiptData.HQReference}}</td>
          </tr>
          <tr>
            <td>{{'trip.name' | translate}}:</td>
            <td class="right-align">{{receiptData.idRequest}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'transactionReference.alt' | translate}}:"></td>
            <td class="right-align">{{receiptData.referencePrint}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'issuingOfficer.alt' | translate}}:"></td>
            <td class="right-align">{{sync.userDisplay(receiptData.agentId)}} #{{receiptData.agentId}}</td>
          </tr>
          <tr>
            <td>{{'placeOfIssue' | translate}}:</td>
            <td class="right-align">{{receiptData.placeId}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'paymentType.alt' | translate}}:"></td>
            <td class="right-align">{{('form.type.' + receiptData.typePayment) | translate}} {{(receiptData.onlinePayment === 'online' ? '|' + ('online' | translate) : '')}}</td>
          </tr>
          <tr>
            <td innerHtml="{{'totalAmount.alt' | translate}};"></td>
            <td class="right-align">{{(receiptData.amount / 100).toFixed(2)}} {{receiptData.currency}}</td>
          </tr>
          <ng-container *ngIf="receiptData.currency !== 'USD'">
            <tr>
              <td>{{'totalAmountCurrency' | translate}}:</td>
              <td
                class="right-align">{{request.convertCurrency('USD', receiptData.amount / 100, receiptData.currency).amount}} {{receiptData.currency}}
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      </td><td style="width: 50%;">
      <div class="qr-code mt_20 hide_info2" style="margin-top: 200px;">
        <qrcode class="qr-print" [qrdata]="qr.qrCode" [width]="140" errorCorrectionLevel="M" elementType="svg"></qrcode>
      </div>
    </td></tr></tbody></table>
  </div>
</div>

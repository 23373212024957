<div class="webcam-container">
  <div id="webcam">
    <div class="broken-button" *ngIf="brokenButton">
      <app-button size="xsm" color="red" (click)="problemManualCamera()" [matTooltip]="api.isZwevisa ? ('tooltip.webcamProblems' | translate) : ''">{{'camera.problem.button' | translate}}</app-button>
    </div>
    <div id="scanner_placeholder" *ngIf="showWebcam && deviceReady">
      <div class="scanner_square"></div>
      <div class="scanner_bottom">
        <app-back-btn color="none" [shortcut]="ShortcutAction.ESCAPE" (click)="backUrlRedirect(backUrl)"></app-back-btn>
        <span id="takeScreenshot" [shortcut]="ShortcutAction.ENTER" (click)="triggerSnapshot()"><span></span></span>
        <div class="flex">
          <fa-icon *ngIf="multipleWebcamsAvailable" class="scanner_camera" icon="camera-rotate" (click)="showNextWebcam(true)"></fa-icon>
          <fa-icon *ngIf="canRotateCamera" class="scanner_camera" icon="rotate-right" (click)="rotateCamera()"></fa-icon>
        </div>
      </div>
    </div>
    <webcam id="webcam_element" *ngIf="showWebcam" [hidden]="!deviceReady"
            [trigger]="triggerObservable"
            [allowCameraSwitch]="false"
            [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="imageQuality"
            [videoInitialized]="videoInitialized"
            mirrorImage="never"
            (imageCapture)="handleImage($event)"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
            [ngClass]="rotationClass"
    ></webcam>
  </div>
</div>

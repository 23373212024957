import {Injectable} from '@angular/core';
import * as Connection from 'cordova-plugin-network-information/www/Connection';
import {LangService, LoaderService} from 'ngx-satoris';
import {environment} from 'src/environments/environment';
import {ApiService} from './api.service';

declare const window: any;
declare const navigator: any;

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  private smsSending = false;
  private smsReceive = false;
  // Grows, but not expected to be a burden
  private ackedMessages: {[id: number]: string[]} = {};

  constructor(private api: ApiService, private loader: LoaderService, private lang: LangService) {
    this.smsSending = true;
    window.SMSReceive?.startWatch(() => {
      this.smsReceive = true;
      document.addEventListener('onSMSArrive', (e: any) => {
        const incomingSMS: {address: string, body: string} = e.data;
        if(this.api.userInfo.server.serverPhones.indexOf(incomingSMS.address) > -1) {
          const parts = incomingSMS.body.split('|');
          this.ackedMessages[parseInt(parts[0], 10)] = (parts[1] === 'ACK') ? parts : [];
        }
      });
    }, (error: any) => {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('err.noSmsReceive') + ': ' + error.toString()});
    });
  }

  private sendSms(number: string, message: string) {
    return new Promise((resolve, reject) => {
      window.sms.send(number, message, {
        replaceLineBreaks: false,
        android: {
          intent: environment.smsIntent
        }
      }, resolve, reject);
    });
  }

  private checkAckSms(reqId: number): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const verifyIntervalHandle = setInterval(() => {
        if(this.ackedMessages[reqId] && this.ackedMessages[reqId].length) {
          clearTimeout(timeoutHandle);
          clearInterval(verifyIntervalHandle);
          resolve(this.ackedMessages[reqId]);
        } else if(this.ackedMessages[reqId]) {
          clearTimeout(timeoutHandle);
          clearInterval(verifyIntervalHandle);
          reject();
        }
      }, 100);
      const timeoutHandle = setTimeout(() => {
        clearInterval(verifyIntervalHandle);
        reject();
      }, 20000);
    });
  }

  sendCreateSms(requestId: string, metadata: string): Promise<{id: string, signature: string}> {
    if(navigator.connection.type === Connection.UNKNOWN || navigator.connection.type === Connection.NONE) return Promise.reject('err.noCarrier');
    if(!this.smsSending) return Promise.reject('err.noSmsPerm');
    if(!this.smsReceive) return Promise.reject('err.noSmsReceive');

    const reqId = Math.floor(Math.random() * 100000);
    const index = Math.floor(Math.random() * this.api.userInfo.server.serverPhones.length);
    return this.sendSms(this.api.userInfo.server.serverPhones[index], reqId + '|CREATE|' + requestId + '|' + metadata)
      .then(() => this.checkAckSms(reqId).then(parts => ({id: parts[2], signature: parts[3]})));
  }

  sendConsumeSms(paymentId: string): Promise<boolean> {
    if(navigator.connection.type === Connection.UNKNOWN || navigator.connection.type === Connection.NONE) return Promise.reject('err.noCarrier');
    if(!this.smsSending) return Promise.reject('err.noSmsPerm');
    if(!this.smsReceive) return Promise.reject('err.noSmsReceive');

    const reqId = Math.floor(Math.random() * 100000);
    const index = Math.floor(Math.random() * this.api.userInfo.server.serverPhones.length);
    return this.sendSms(this.api.userInfo.server.serverPhones[index], reqId + '|CONSUME|' + paymentId)
      .then(() => this.checkAckSms(reqId).then(() => true));
  }
}

import {isWithinInterval, sub, parse} from 'date-fns';

export const convertToEpoch = (YYMMDD: string, asDate = false, isAfterNowYearCheck = false): Date | number => {
  try {
    // Extract year, month, and day from the YYMMDD string
    const year = parseInt(YYMMDD.substring(0, 2), 10);
    const month = parseInt(YYMMDD.substring(2, 4), 10) - 1; // Months are zero-based in JavaScript
    const day = parseInt(YYMMDD.substring(4, 6), 10);

    // Determine the century according to ICAO rules
    const currentYear = new Date().getFullYear();
    let fullYear = year >= 50 ? 1900 + year : 2000 + year;

    // If the calculated year is in the future, adjust it to the previous century
    if(fullYear > currentYear && isAfterNowYearCheck) {
      fullYear = 1900 + year;
    }

    // Create the Date object
    const date = new Date(fullYear, month, day);

    // Return either the Date object or the epoch time
    if(asDate) {
      return date;
    } else {
      return date.getTime();
    }
  } catch (e) {
    console.log('Error converting date', e);
    return 0;
  }
};

export const checkValidity = (YYMMDD: string): boolean => {
  try {
    const epoch = convertToEpoch(YYMMDD);
    if(epoch === 0) {
      return false;
    }
    const currentEpoch = new Date().getTime();
    if((epoch as number) < currentEpoch) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const isDateWithinPeriod = (dateInput: string, period: string): boolean => {
  try {
    let inputDate: Date;
    if(typeof dateInput === 'string') {
      inputDate = parse(dateInput, 'dd/MM/yyyy', new Date());
    } else {
      inputDate = new Date(dateInput);
    }
    if(isNaN(inputDate.getTime())) {
      return false;
    }
    let periodDate: Date;
    switch(period) {
    case '1 year':
      periodDate = sub(new Date(), {years: 1});
      break;
    case '1 month':
      periodDate = sub(new Date(), {months: 1});
      break;
    case '1 week':
      periodDate = sub(new Date(), {weeks: 1});
      break;
    case '1 day':
      periodDate = sub(new Date(), {days: 1});
      break;
    default:
      return false;
    }
    return isWithinInterval(inputDate, {start: periodDate, end: new Date()});
  } catch (e) {
    return false;
  }
};

export const cleanDate = (dateString: string): Date => {
  const normalizedDateString = dateString.replace(' +0000', 'Z');
  const parsedDate = Date.parse(normalizedDateString);
  if(!isNaN(parsedDate)) {
    return new Date(parsedDate);
  } else {
    return null;
  }
};

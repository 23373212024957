import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailSentComponent  {

  constructor(public nav: NavigateService) {}

}

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-place-created',
  templateUrl: './place-created.component.html',
  styleUrls: ['./place-created.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceCreatedComponent {

  placeId: string;

  constructor(public nav: NavigateService, private route: ActivatedRoute) {
    this.placeId = this.route.snapshot.paramMap.get('placeId');
  }

}

import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {Document} from '../../../shared/models/user';
import {CartService} from '../../../shared/services/cart.service';
import {RequestService} from '../../../shared/services/request.service';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Direction, VisaFN, VisaFormName} from '../../../shared/models/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-visa-extend',
  templateUrl: './visa-extend.component.html',
  styleUrl: './visa-extend.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class VisaExtendComponent implements OnDestroy {

  selected: Document = this.cart.currentRequest?.visaType || undefined;
  visaTypes: Document[];
  keepScanned = false;
  subscription;
  form: FormGroup;
  paramDirection: Direction;

  constructor(public api: ApiService,
    public cart: CartService,
    public request: RequestService,
    public nav: NavigateService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private inactivity: InactivityService) {
    this.route.queryParams.subscribe(params => {
      if(params && params.direction) this.paramDirection = params.direction;
    });
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(!this.cart.currentRequest) {
        if(!this.cart.currentApplication?.isExtend) {
          this.nav.to('dashboard');
        }
        return;
      }
      this.cart.currentRequest = {...this.cart.currentRequest, latestRouteVisited: 'visa-extend'};
      this.cart.saveCurrentApplication(false);
    });

    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
    this.setForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setForm() {
    this.form = this.formBuilder.group({
      num: [this.request.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.DAYS_REQUESTED, this.cart.currentApplication.requests[0].formConfig) || 1, [Validators.required, Validators.min(1), Validators.max(9999)]]
    });
    if(this.cart.isFnInRevision(VisaFN.DAYS_REQUESTED, true)) {
      this.form.controls.num.disable();
      if(this.cart.currentApplication.skipValidRevision) this.paramDirection === Direction.NEXT ? this.next() : this.previous();
    }
  }

  next() {
    if(this.form.value.num) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.DAYS_REQUESTED, this.cart.currentApplication.requests[0].formConfig).value = this.form.value.num;
      this.cart.saveCurrentApplication();
      this.nav.to('form-visa', undefined, {queryParams: {direction: Direction.NEXT}});
    }
  }

  previous() {
    if(!this.api.userRole.isCustomer) {
      this.nav.to('admin-request-select/'+this.cart.currentApplication?.requestId);
    } else {
      this.nav.to('request-account/'+this.cart.currentApplication?.requestId);
    }
  }

  mod(additive: boolean) {
    if(this.cart.isFnInRevision(VisaFN.DAYS_REQUESTED, true)) return;
    if(!additive && this.form.value.num <= 1) return;
    if(additive && this.form.value.num >= 9999) return;
    this.form.setValue({num: this.form.value.num + (additive ? 1 : -1)});
  }

  checkNum() {
    if(this.form.value.num && this.form.value.num < 1) this.form.setValue({num: 1});
    if(this.form.value.num && this.form.value.num > 9999) this.form.setValue({num: 9999});
  }

  mouseDown(additive: boolean) {
    const interval = setInterval(() => {
      this.mod(additive);
    }, 200);
    const listener = () => {
      clearInterval(interval);
      window.removeEventListener('mouseup', listener);
    };
    window.addEventListener('mouseup', listener);
  }

  protected readonly VisaFN = VisaFN;
}

<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'place.new' | translate}}</app-view-title>
  <app-view-deco-image class="mb_20" imageUrl="assets-projects/images/image_done.svg"></app-view-deco-image>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <span class="text_center mb_30">{{'place_created.desc' | translate}} </span>
      <app-slide-element leftIcon="location-dot" (btnClicked)="nav.to('place/'+ placeId)">{{'place_created.open' | translate}}</app-slide-element>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth mt_10 resp_minSize_l" (btnClicked)="nav.to('places')">{{'global.close' | translate}}</app-button>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.to('places')">{{'global.close' | translate}}</app-button>
    </div>
  </div>
</div>

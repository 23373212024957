import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {NavigateService} from 'ngx-satoris';
import {CartService} from '../../../shared/services/cart.service';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {UserPermission} from '../../../shared/models/user';
import {Direction, VisaFN} from '../../../shared/models/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-5.urgent',
  templateUrl: './urgent.component.html',
  styleUrl: './urgent.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UrgentComponent {

  subscription;
  defaultValue: 'Yes' | 'No';
  show = true;
  paramDirection: Direction;
  disabled: boolean;
  choiceButtons = [
    {text: 'Yes', value: 'Yes', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
    {text: 'No', value: 'No', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
  ];

  constructor(public api: ApiService,
    public nav: NavigateService,
    public cart: CartService,
    private route: ActivatedRoute,
    private inactivity: InactivityService) {
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(!this.cart.currentRequest) {
        if(this.cart.currentApplication?.isRevision || (this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CREATE) && this.api.hasPerm(UserPermission.ALLOW_CONSUME))) {
          this.nav.to('dashboard');
        } else if(this.api.userRole.isKiosk) {
          this.nav.to('standby-kiosk');
        } else {
          this.nav.to('travel-name');
        }
        return;
      } else if(!this.cart.currentRequest.nationality || !this.cart.currentRequest.passportType) {
        this.nav.to('passport-type');
        return;
      } else if(!this.cart.currentRequest.visaType) {
        this.nav.to('visa-type');
        return;
      }
      this.cart.currentRequest = {...this.cart.currentRequest, latestRouteVisited: 'urgent'};
      this.cart.saveCurrentApplication(false);
      this.show = false;
      this.defaultValue = this.cart.currentRequest.urgent;
      setTimeout(() => {
        this.disabled = this.cart.isFnInRevision(VisaFN.VISA_TYPE, true);
        this.show = true;
      });
    });
    this.route.queryParams.subscribe(params => {
      if(params && params.direction) this.paramDirection = params.direction;
      if(this.cart.currentApplication.isRevision && this.cart.currentApplication.skipValidRevision && this.cart.isFnInRevision(VisaFN.URGENT, true)) {
        this.paramDirection === Direction.NEXT ? this.next() : this.previous();
      }
    });
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
  }

  change(val: 'Yes' | 'No') {
    if(this.cart.isFnInRevision(VisaFN.URGENT, true)) return;
    this.defaultValue = val;
    this.cart.currentRequest = {
      ...this.cart.currentRequest,
      urgent: val
    };
    this.cart.saveCurrentApplication(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  next() {
    if(!this.defaultValue) return;
    this.nav.to('form-visa', undefined, {queryParams: {direction: Direction.NEXT}});
  }

  previous() {
    this.nav.to('visa-type', undefined, {queryParams: {direction: Direction.PREVIOUS}});
  }

  protected readonly VisaFN = VisaFN;
}

<div id="layout" class="f_col">
  <app-view-title fromRoute="welcome3" [chooseLangBtn]="true">{{'welcome.title_3.DYN.' + api.env.type | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="parent-container">
      <app-view-deco-image class="mt_20 mb_20" imageUrl="assets-projects/images/image_synchronize.svg"></app-view-deco-image>
      <div class="welcome-text">
        <p>{{'welcome.text_4.DYN.'+api.env.type | translate}}</p>
        <p>{{'welcome.text_4.1.DYN.'+api.env.type | translate}}</p>
      </div>
    </div>
    <div class="btns-container">
      <div class="fullWidth flex f_center">
        <div class="menuButton-container flex f_center">
          <div class="flex gap_10">
            <app-back-btn class="resp_maxSize_l" icon="arrow-left" (btnClicked)="nav.to('welcome-employee')"></app-back-btn>
            <app-button color="transparent" border="true" size="xsm" type="submit" formId="form" class="fullWidth child-btn resp_minSize_l" (btnClicked)="nav.to('welcome-employee')">{{'global.previous' | translate}}</app-button>
          </div>
          <div class="ml_10 fullWidth">
            <app-button type="submit" formId="form" class="fullWidth child-btn resp_maxSize_l" (btnClicked)="nav.to('welcome-thanks')">{{'global.next' | translate}}</app-button>
            <app-button size="xsm" type="submit" formId="form" class="fullWidth child-btn resp_minSize_l" (btnClicked)="nav.to('welcome-thanks')">{{'global.next' | translate}}</app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormService} from 'ngx-satoris';

@Component({
  selector: 'app-dialog-reject-validate-payment',
  templateUrl: './dialog-reject-validate-payment.component.html',
  styleUrls: ['./dialog-reject-validate-payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogRejectValidatePaymentComponent {

  color: string;
  form: FormGroup;
  submitted: boolean;

  constructor(public dialogRef: MatDialogRef<DialogRejectValidatePaymentComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: { type: 'valid' | 'warn', message?: string, btnLabel?: string, refuse?: boolean},
              private formBuilder: FormBuilder,
              private forms: FormService) {
    this.color = data.type === 'valid' ? '#57AC4C' : data.type === 'warn' ? '#CB6129' : '#BC232C';
    if(this.data?.refuse) {
      this.setForm();
    }
  }

  setForm() {
    this.form = this.formBuilder.group({
      reason: ['', [
        Validators.required
      ]]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.closeDialog(true);
    }
  }

  closeDialog(action?: boolean) {
    const data = {
      done: action,
      reason: this.data?.refuse ? this.form.get('reason').value : undefined
    };
    this.dialogRef?.close(data);
  }
}

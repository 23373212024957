<div class="printFrames">

  <div class="printFrame frame_tl">
    <span class="frame_number">1</span>
    <img class="w-64 my-16 mx-auto" [src]="environment.theme.logo" alt="logo" width="200" height="200">
    <img *ngIf="api.userInfo?.server?.country && environment.type === DocumentType.PASS" class="w-20 mx-auto" [ngSrc]="'assets/images/flags/'+api.userInfo.server.country+'.png'" alt="flag" width="200" height="200">
  </div>

  <div class="printFrame frame_tr">
    <span class="frame_number">4</span>
    <div class="flex f_align_c mb_20">
      <img class="mr_20" ngSrc="assets/images/icons_clr/error.png" alt="" width="80" height="80">
      <span class="frame_title">{{'print.notice.title' | translate}}</span>
    </div>
    <p>{{'print.notice.desc1' | translate}}</p>
    <p>{{'print.notice.desc2' | translate}}</p>
    <div class="flex justify-between flex-grow">
      <span *ngIf="api.userInfo.server.emailConfig.phone.trim()">TEL: {{api.userInfo.server.emailConfig.phone}}</span>
      <span>EMAIL: {{api.userInfo.server.emailConfig.email}}</span>
    </div>
    <div class="flex f_align_c mt_20 mb_20">
      <img class="mr_20" ngSrc="assets/images/icons_clr/origami.png" width="80" height="80">
      <span class="frame_title">{{'print.notice.folding' | translate}}</span>
    </div>
    <img class="mb-14" ngSrc="assets/images/folding.png" alt="folding" width="320" height="100">
  </div>

  <div class="printFrame frame_bl">
    <span class="frame_number">2</span>
    <div class="flex f_align_c mb_20">
      <img class="mr_20" ngSrc="assets/images/icons_clr/about.png" width="80" height="80">
      <span class="frame_title">{{'print.informations' | translate}}</span>
    </div>
    <ng-container *ngIf="api.env.type === DocumentType.PASS">
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.firstName">
        <p><span class="info">{{'form.name' | translate}}:</span> {{qr.currentQrData?.metadata.firstName}} {{qr.currentQrData?.metadata.lastName}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.passportNumber">
        <p><span class="info">{{'form.passportNumber' | translate}}:</span> {{qr.currentQrData?.metadata?.passportNumber}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.phone">
        <p><span class="info">{{'form.phone' | translate}}:</span> {{qr.currentQrData?.metadata.phone}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.idCard">
        <p><span class="info">{{'form.idCard' | translate}}:</span> {{qr.currentQrData?.metadata?.idCard}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.amountCents">
        <p><span class="info">{{'form.amount' | translate}}:</span> {{qr.currentQrData?.amountCents / 100}} {{qr.currentQrData?.currency}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.buyingOption">
        <p><span class="info">{{'form.buyingOption' | translate}}: </span> {{qr.currentQrData?.metadata?.buyingOption?.type ? qr.currentQrData?.metadata?.buyingOption?.type : qr.currentQrData?.metadata?.buyingOption}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.type && !api.userRole.isCustomer">
        <p><span class="info">{{'form.type' | translate}}:</span> {{qr.currentQrData?.type}}</p>
      </div>
      <div class="input_wrapper" *ngIf="api.userRole.isCustomer">
        <p><span class="info">{{'form.type' | translate}}: </span> {{qr.currentQrData?.paymentType}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.created_place_id">
        <p><span class="info">{{'form.place' | translate}}:</span> {{qr.currentQrData?.created_place_id}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.createdAt">
        <p><span class="info">{{'form.created_date' | translate}}:</span> {{qr.currentQrData?.createdAt | date}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.id">
        <p><span class="info">{{'form.id' | translate}}:</span> {{qr.currentQrData?.id}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.worker">
        <p><span class="info">{{'form.worker' | translate}}:</span> {{qr.currentQrData?.metadata?.worker}}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="api.env.type !== DocumentType.PASS">
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.firstName || qr.currentQrData?.serialized?.FirstName">
        <p><span class="info">{{'form.name' | translate}}:</span> {{qr.currentQrData?.metadata.firstName ||  qr.currentQrData?.serialized?.FirstName}} {{qr.currentQrData?.metadata.lastName ||  qr.currentQrData?.serialized?.LastName}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.serialized?.BirthDay">
        <p><span class="info">{{'form.birthDate' | translate}}:</span> {{qr.currentQrData?.serialized?.BirthDay | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.serialized?.Nationality">
        <p><span class="info">{{'form.nationality' | translate}}:</span> {{'DYN.' + qr.currentQrData?.serialized?.Nationality | translate}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.metadata?.passportNumber">
        <p><span class="info">{{'form.passportNumber' | translate}}:</span> {{qr.currentQrData?.metadata?.passportNumber}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.operationId">
        <p><span class="info">{{'visaIntro.title' | translate}}: </span> {{qr.currentQrData?.operationId | translate}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.usageAfter">
        <p><span class="info">{{'visaIntro.validFrom' | translate}}: </span> {{qr.currentQrData?.usageAfter | date}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.usageUntil">
        <p><span class="info">{{'visaIntro.validUntil' | translate}}: </span> {{qr.currentQrData?.usageUntil | date}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.operationId">
        <p><span class="info">{{'visaIntro.usageCount' | translate}}: </span> {{getUsageCount(qr.currentQrData?.operationId)}}</p>
      </div>
      <div class="input_wrapper" *ngIf="qr.currentQrData?.id">
        <p><span class="info">{{'form.id' | translate}}:</span> {{qr.currentQrData?.id}}</p>
      </div>
    </ng-container>
  </div>

  <div class="printFrame frame_br">
    <span class="frame_number">3</span>
    <div class="flex f_align_c mb_20">
      <img class="mr_20" ngSrc="assets/images/icons_clr/qr.png" width="80" height="80">
      <span class="frame_title">{{'print.qr.title' | translate}}</span>
    </div>
    <div *ngIf="api.env.type === DocumentType.ZWEVISA">
      <div class="flex f_col gap_10">
        <app-paragraph>{{('print.desc.part1' + (qr.currentQrData.operationId === DocumentType.ZWEENTRY ?  '.' + DocumentType.ZWEENTRY : '')) | translate}}</app-paragraph>
        <app-paragraph>{{'print.desc.part3' | translate}}</app-paragraph>
      </div>
    </div>
    <div *ngIf="api.env.type === DocumentType.PASS">
      <p>{{'print.qr.desc' | translate}}</p>
      <p class="small" *ngIf="environment.type !== DocumentType.PASS">{{'qr.request_desc' | translate}}</p>
      <p *ngIf="environment.type !== DocumentType.PASS" class="fw_bold">{{(isValid ? 'qrDesc.valid' : 'qrDesc.invalid') | translate}}</p>
    </div>
    <qrcode *ngIf="isValid || api.env.type === DocumentType.PASS" class="qr-print mt-4" [qrdata]="qr.qrCode" [width]="220" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
  </div>
</div>

<div id="print">
  <div class="print_content">
    <div class="flex f_between mt_30">
      <span><b>Station / HQ Ref</b>: <b>{{form.controls.hqReference.value}}</b> / <b>{{form.controls.stationReference.value}}</b></span>
      <span><b>Regulations Section 9</b></span>
    </div>
    <div class="flex f_between mt_30">
      <span>Purpose: </span>
      <div class="flex gap_30">
        <span class="f_align_c">(a) Conditional entry <span class="print_bubble ml_5" [ngStyle]="{'border': form.controls.purpose.value === 'conditionalEntry' ? '10px solid black' : ''}"></span></span>
        <span class="f_align_c">(b) Departure <span class="print_bubble ml_5" [ngStyle]="{'border': form.controls.purpose.value === 'departure' ? '10px solid black' : ''}"></span></span>
        <span class="f_align_c">(c) Transit <span class="print_bubble ml_5" [ngStyle]="{'border': form.controls.purpose.value === 'transit' ? '10px solid black' : ''}"></span></span>
      </div>
    </div>
    <div class="text_center mt_30">
      <h3>Zimbabwe<br>Department of Immigration</h3>
      <h2><b>NOTICE TO VISITOR</b></h2>
    </div>
    <div>
      <p>To: <b>{{form.controls.fullNames.value}}</b></p>
      <p class="f_between"><span>Host: <b>{{form.controls.host.value}}</b></span><span>Mobile Number: <b>{{form.controls.hostPhone.value}}</b></span></p>
      <p>Address in Zimbabwe: <b>{{form.controls.addressZimbabwe.value}}</b></p>
      <p class="f_between"><span>Mobile Number: <b>{{form.controls.phone.value}}</b></span><span>Email Address: <b>{{form.controls.email.value}}</b></span></p>
      <p>Address in country of origin / domicile: <b>{{form.controls.address.value}}</b></p>
      <p>Period of visit: From <b>{{form.controls.periodFrom.value | date: 'dd/MM/yyyy'}}</b> to <b>{{form.controls.periodTo.value | date: 'dd/MM/yyyy'}}</b></p>
    </div>
    <div>
      <span><b>TAKE NOTICE:</b> that in terms of section 9 of the Immigration Regulations 195 / 98, you are required / directed:</span>
    </div>
    <div class="ml_30 mt_20">
      <p>(1) To report to an Immigration Officer at <b>{{form.controls.reportAt.value}}</b> on <b>{{form.controls.reportOn.value | date: 'dd/MM/yyyy'}}</b>.</p>
      <p>(2) To report to an Immigration Officer at <b>{{form.controls.reportAt.value}}</b> at <b>{{form.controls.reportInterval.value}}</b> intervals of from the date of issue of this notice.</p>
      <p>(3) To surrender this notice to an Immigration Officer at the place of your departure from Zimbabwe.</p>
    </div>
    <div class="mt_20">
      <span><b>WARNING</b></span>
    </div>
    <div class="ml_30 mt_20">
      <p>(1) It is an offence for any person entering Zimbabwe as a VISITOR to take up employment unless he/ she has been granted a residence permit or temporary employment permit prior to entry.</p>
      <p>(2) Any person issued a <b>Notice to Visitor for Departure</b> shall not be readmitted into Zimbabwe until aftera period of ninety (90) days following prior written authorization from the Chief Immigration Officer.</p>
      <p>(3) Failure to comply with any of the conditions of this Notice may result in holder being treated as a prohibited person.</p>
    </div>
    <div class="mt_30 flex f_between">
      <span><b>OFFICE DATE STAMP</b></span>
      <div class="text_end">
        <p>Signature of Holder: </p>
        <p>Immigration Officer: <b>{{sync.userDisplay(form.controls.officer.value)}} #{{form.controls.officer.value}}</b></p>
      </div>
    </div>
    <div class="mt_30">
      <p><b>NOTICE SURRENDERED TO ME</b></p>
      <div class="flex f_between">
        <p><b>OFFICE DATE STAMP</b></p>
        <p>Immigration Officer: <b>{{sync.userDisplay(form.controls.officer.value)}} #{{form.controls.officer.value}}</b></p>
      </div>
    </div>
    <div class="flex f_end">
      <div class="f_col">
        <span class="mb_20">GARSU PASAULIS</span>
        <qrcode *ngIf="qrCode" [qrdata]="qrCode" [margin]="0"></qrcode>
      </div>
    </div>
  </div>
</div>

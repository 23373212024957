import {Injectable} from '@angular/core';
import {generateQrOffline, generateQrOnline} from '../utils/generateQr';
import {ApiService} from './api.service';
import {format} from 'date-fns';
import {FormGroup} from '@angular/forms';
import {DialogBluetoothComponent} from '../../components/dialogs/dialog-bluetooth/dialog-bluetooth.component';
import {DialogsService} from './dialog.service';
import {Request} from '../models/request';
import {LoaderService as LoadService} from 'ngx-satoris';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  active: boolean;
  done: boolean;
  doneMessage: string;
  error: boolean;
  errorMessage: string;
  qrCode: string;
  currentQrData: Request | any;
  printType: 'c7' | 'a4';
  BTdevices: any[];
  BTConnectedDevice: string;
  hasBackground: boolean;

  constructor(private api: ApiService,
    private dialog: DialogsService,
    private loader: LoadService) {}


  qrOnlineLoader(placeId: string, id: string, externalId: string, signature64: string) {
    setTimeout(() => {
      this.qrCode = generateQrOnline(id, signature64, placeId, externalId);
    }, 1000);
  }

  qrOfflineLoader(placeId: string, id: string, externalId: string) {
    setTimeout(() => {
      this.qrCode = generateQrOffline(id, placeId, externalId);
    }, 1000);
  }


  print(type: 'c7' | 'a4') {
    this.printType = type;

    setTimeout(() => {
      if(this.api.isCordovaApp) {
        this.loader.loading(true, {type: 'warn', message: 'noPrint.mobile'});
      } else {
        window.print();
      }
    }, 1000);
  }

  setCurrentQrData(form?: FormGroup, paymentData?: Request) {
    let data: Request = null;
    if(form) {
      data = {
        amountCents: form.get('buyingOption') ? (form.get('buyingOption').value)?.amountCents : '',
        currency: form.get('buyingOption') ? (form.get('buyingOption').value)?.currency : '',
        firstName: form.get('firstName') ? form.get('firstName').value : '',
        lastName: form.get('lastName') ? form.get('lastName').value : '',
        phone: form.get('phone') ? form.get('phone').value : '',
        idCard: form.get('idCard') ? form.get('idCard').value : '',
        buyingOption: form.get('buyingOption') ? form.get('buyingOption').value : '',
        type: form.get('type') ? form.get('type').value : '',
        created_place_id: form.get('place') ? form.get('place').value : '',
        createdAt: format(Date.now(), 'dd-MM-yyyy @ HH:mm:ss'),
        id: form.get('id') ? form.get('id').value : '',
        signature: form.get('signature') ? form.get('signature').value : '',
        metadata: {'worker': this.api.userInfo.id}
      };
    }
    this.currentQrData = paymentData ? paymentData : data;
  }

  deleteCurrentQrData() {
    this.currentQrData = undefined;
  }

  openBT() {
    this.dialog.openDialog(DialogBluetoothComponent, 'md').then(() => {

    });
  }
}

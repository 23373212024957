import {Component, Input} from '@angular/core';
import {PrintBorderpassData} from 'src/app/shared/models/request';
import {QrService} from 'src/app/shared/services/qr.service';

@Component({
  selector: 'app-print-notice-border-pass',
  templateUrl: './print-notice-border-pass.component.html',
  styleUrl: './print-notice-border-pass.component.scss'
})
export class PrintNoticeBorderPassComponent {
  @Input() printBorderPassData: PrintBorderpassData;
  constructor(public qr: QrService) {}
}

<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_20" fromRoute="pre-enroll" [chooseLangBtn]="false">{{places?.user?.name}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper resp_row">
    <div class="resp_l-50 resp_scroll-wrapper_l">
      <app-slide-element leftIcon="location-dot" rightIcon="plus" class="mb_10" (btnClicked)="nav.to('user/'+ userId + '/places-add')">{{'menu.addPlace' | translate}}</app-slide-element>
      <app-divider class="mb_10"></app-divider>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="nav.to('user/'+userId)">{{'global.back' | translate}}</app-button>
    </div>
    <div class="resp_r-50 resp_scroll-wrapper_l">
      <div *ngFor="let place of places?.allPlacesByPerson">
        <app-slide-element leftIcon="globe" class="mb_10 fadeIn" (btnClicked)="nav.to('user/'+ userId + '/place/'+ place?.id)">{{place?.longName}}</app-slide-element>
      </div>
      <span *ngIf="places?.allPlacesByPerson?.length === 0" class="mt_40 text_center opacity_50">{{'no.place' | translate}}</span>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.to('user/'+userId)">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>

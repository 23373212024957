<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'modifiedPassword.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <app-view-deco-image imageUrl="assets-projects/images/image_done.svg"></app-view-deco-image>
      <p class="text_center mb_40">{{'modifiedPassword.description' | translate}}</p>
      <div class="mt_20 resp_minSize_l">
        <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="api.signOut('/sign-in')" >{{'global.close' | translate}}</app-button>
      </div>
      <div class="f_align_end f_grow resp_maxSize_l">
        <div class="ml_10 fullWidth">
          <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="api.signOut('/sign-in')" >{{'global.close' | translate}}</app-button>
        </div>
      </div>
    </div>
  </div>
</div>

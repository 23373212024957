import {Injectable, OnDestroy} from '@angular/core';
import {ActionState, ConsumeMode, PassportType, PurposeOfVisit, RequestCashType, RequestWithDuplicata} from '../models/request';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from './api.service';
import {DocumentType, UserPermission} from '../models/user';
import {RequestService} from './request.service';
import parse from 'mrz/lib/parse/parse';
import {checkValidity, convertToEpoch, isDateWithinPeriod} from '../utils/date';
import {CartService} from './cart.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {parseIndexedData} from '../utils/parseMetadata';
import {environment} from 'src/environments/environment';
import {QueueService} from './queue.service';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class ScanDocumentService implements OnDestroy {

  scanDatas: (ReturnType<typeof parse> & { id?: string });
  visaDatas: RequestWithDuplicata[];
  visaDatas$: BehaviorSubject<RequestWithDuplicata[]> = new BehaviorSubject([]);
  documentError: string;
  documentWarning: string;
  documentPhoto: string;
  consumeMode: ConsumeMode | null = null;
  consumeMode$: BehaviorSubject<ConsumeMode> = new BehaviorSubject(undefined);
  scanned = false;
  private signOutSubscription: Subscription;

  constructor(private loader: LoaderService,
    private lang: LangService,
    private api: ApiService,
    private nav: NavigateService,
    private cart: CartService,
    private request: RequestService,
    private queue: QueueService) {
    if(this.isRegistered()) {
      const scanDatas = sessionStorage.getItem('scanDatas');
      const visaDatas = sessionStorage.getItem('visaDatas');
      this.scanDatas = scanDatas ? JSON.parse(scanDatas) : null;
      this.documentError = sessionStorage.getItem('documentError') || '';
      this.documentWarning = sessionStorage.getItem('documentWarning') || '';
      this.documentPhoto = sessionStorage.getItem('documentPhoto') || '';
      this.visaDatas = visaDatas ? JSON.parse(visaDatas) : [];
    }

    this.signOutSubscription = this.api.signOutObservable.subscribe({
      next: () => {
        this.resetScanDatas();
      }
    });
    this.consumeMode = localStorage.getItem('consumeMode') as ConsumeMode;
  }

  ngOnDestroy() {
    this.signOutSubscription.unsubscribe();
  }

  scanPassport() {
    if(!this.consumeMode) {
      return this.loader.loading(true, {type: 'warn', message: this.lang.transform('scan.noConsumeMode')});
    } else {
      this.resetScanDatas();
      this.loader.loading(true);
      electron.step('scanning', false, []);
      return this.scanDocument().then(() => this.api.searchPayment(undefined, this.scanDatas.details.find((field: any) => field.field === 'documentNumber').value).then(resCall => {
        this.loader.loading(false);
        const nat = this.scanDatas.details.find((field: any) => field.field === 'nationality').value;
        return this.scanPassportAction(resCall.result.filter(p => p.internalIndexedData.indexOf(nat) > -1) as any, 'dashboard');
      })).finally(() => this.loader.loading(false));
    }
  }

  scanDocument() {
    let timeoutScan : NodeJS.Timeout;
    const timeoutPromise = new Promise((_, reject) => {
      timeoutScan = setTimeout(() => reject(), 10000);
    });
    return Promise.race([
      electronLocal.testDocument(false, true, false),
      timeoutPromise
    ]).then(result => {
      const [err, photo, _, infos, certBytes] = result as [string, Uint8Array, Uint8Array, ReturnType<typeof parse>, Uint8Array];
      this.scanDatas = infos;

      const cleanName = (name: string) => name.trim().replace(/_/g, ' ').replace(/\s+/g, ' ');
      if(this.scanDatas?.fields?.firstName) this.scanDatas.fields.firstName = cleanName(this.scanDatas.fields.firstName);
      if(this.scanDatas?.fields?.lastName) this.scanDatas.fields.lastName = cleanName(this.scanDatas.fields.lastName);

      return electron.convertJp2Jpg(photo).catch(() => undefined).then(photoUrl => {
        switch(err) {
        case 'err.noPassiveAuthenticationCSCA':
          this.documentWarning = err;
          break;
        default:
          this.documentError = err;
          break;
        }
        const isSoonIssued = this.scanDatas?.fields?.issueDate && isDateWithinPeriod(this.scanDatas.fields.issueDate, '1 month');
        if(isSoonIssued) {
          this.documentWarning = 'err.passport.issuedSoon';
        }
        const isValid = checkValidity(this.scanDatas.fields.expirationDate);
        if(!isValid) {
          this.documentWarning = 'err.passport.expired';
        }
        this.documentPhoto = photoUrl;
        if(this.documentPhoto && !this.documentError) {
          this.documentError = 'passport.valid';
        }

        if(!environment.production) {
          if((this.documentWarning && this.documentWarning === 'err.noPassiveAuthenticationCSCA') || (this.documentError && this.documentError !== 'passport.valid' && this.documentError !== 'err.noCard')) {
            const encodedCertBytes = certBytes ? btoa(String.fromCharCode.apply(null, certBytes)) : undefined;
            window.sendTicket(`[ERROR Scan passport] - Agent ID : ${this.api.userInfo.id}`, this.lang.transform('glpi.' + (this.documentWarning === 'err.noPassiveAuthenticationCSCA' ? this.documentWarning : this.documentError)), `${this.api.userInfo.name}`, `${this.api.userInfo.accountName}`, this.lang.transform('error.passportScan', {certBytes: encodedCertBytes}));
          }
        }

        return {documentPhoto: photoUrl, infos: infos, documentError: this.documentError};
      });
    }).catch(err => {
      const message = err === 'err.noReader' ? err : 'err.scanPassport';
      return this.loader.loading(true, {type: 'warn', message: message, btnLabel: message === 'err.scanPassport' ? 'global.reload' : ''}).then((reload: boolean) => {
        if(reload) {
          return window.location.reload();
        }
        return Promise.reject(err);
      });
    }).finally(() => {
      clearTimeout(timeoutScan);
    });
  }

  resetScanDatas() {
    this.scanDatas = undefined;
    this.documentError = undefined;
    this.documentWarning = undefined;
    this.documentPhoto = undefined;
    this.visaDatas = undefined;
    this.visaDatas$.next(this.visaDatas);
    sessionStorage.removeItem('scanDatas');
    sessionStorage.removeItem('documentError');
    sessionStorage.removeItem('documentWarning');
    sessionStorage.removeItem('documentPhoto');
    sessionStorage.removeItem('visaDatas');
  }

  changeConsume(mode: ConsumeMode, clean = true) {
    if(localStorage.getItem('consumeMode') === mode && clean) {
      localStorage.removeItem('consumeMode');
      this.consumeMode = null;
    } else {
      this.consumeMode = mode;
      this.consumeMode$.next(mode);
      localStorage.setItem('consumeMode', mode);
    }
  }

  scanPassportAction(datas: RequestWithDuplicata[], from: 'dashboard' | 'admin-request', params?: any) {
    const nationality = this.scanDatas.details.find((field: any) => field.field === 'nationality').value;
    const isNoDeclaration = this.api.env.nationalityNoDeclaration.includes(this.scanDatas.fields.nationality);
    const documentNumber = this.scanDatas.details.find((field: any) => field.field === 'documentNumber').value;
    const isVisaExist = datas.find(request => {
      const parsedData = parseIndexedData(request.internalIndexedData, request.operationId, this.api.userInfo.server);
      return parsedData.passportNumber === documentNumber && parsedData.nationality === nationality;
    });
    const zweRefugee = this.isZweRefugee(this.scanDatas.fields.nationality, this.scanDatas.fields.issuingState);
    const register = (type: DocumentType) => this.registerPayment(this.scanDatas, type, zweRefugee).then((createdVisa) => {
      this.scanDatas.id = createdVisa.id;
      this.registerScanDatasToSession();
      // on admin select
      return this.nav.to('admin-request-select/' + createdVisa.id, undefined, {queryParams: {from, foundByScan: true, ...params}});
    }).catch(() => this.loader.loading(true, {type: 'warn', message: this.lang.transform('scan.error')}));
    if(!isVisaExist) {
      if(this.consumeMode === ConsumeMode.ENTRY || this.consumeMode === ConsumeMode.TRANSIT) {
        if(isNoDeclaration || zweRefugee) {
          return register(DocumentType.ZWEENTRY);
        } else {
          return this.loader.loading(true, {type: 'info', message: 'scan.noEntryFound', btnLabel: 'yes', custom: {icon: 'file-circle-question', closeBtnLabel: 'no'}}).then((done) => {
            if(done) {
              this.scanned = true;
              this.cart.startApplication(true);
            } else {
              this.registerScanDatasToSession(false);
              this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
            }
          });
        }
      } else {
        if(this.api.hasPerm(UserPermission.ALLOW_CONSUME) && !this.api.hasPerm(UserPermission.ALLOW_CREATE)) {
          return this.loader.loading(true, {type: 'warn', message: 'allowConsume.noCreate'});
        }
        if(isNoDeclaration) {
          return register(DocumentType.ZWEEXIT);
        } else {
          return this.loader.loading(true, {type: 'info', message: this.lang.transform('scan.noVisaFound'), btnLabel: 'global.confirm'}).then((done) => {
            if(done) {
              return register(DocumentType.ZWEEXIT);
            } else {
              this.registerScanDatasToSession(false);
              this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
            }
          });
        }
      }
    } else {
      if(this.consumeMode === ConsumeMode.ENTRY || this.consumeMode === ConsumeMode.TRANSIT) {
        const isCheckin = datas.filter(request => {
          const parsedData = parseIndexedData(request.internalIndexedData, request.operationId, this.api.userInfo.server);
          const isSameDocumentNumberAndNationality = parsedData.passportNumber === documentNumber && parsedData.nationality === nationality;
          const isSyncing = this.cart.syncQueue.find(sync => sync.paymentId === request.id) || this.queue.requestInQueue(request.id);
          const isValidState = [ActionState.READY_CHECKIN, ActionState.READY_CHECK, ActionState.REFUSED].includes(this.request.getStatusTheme(request).actionState);
          const isUsageAfterValid = this.request.isUsageDateValid(request.usageAfter, 'usageAfter');
          const isUsageUntilValid = !request.usageUntil || this.request.isUsageDateValid(request.usageUntil, 'usageUntil');
          return isValidState && isUsageAfterValid && isUsageUntilValid && !isSyncing && isSameDocumentNumberAndNationality;
        });
        if(isCheckin.length === 1) {
          this.visaDatas = [];
          this.visaDatas$.next(this.visaDatas);
          sessionStorage.removeItem('visaDatas');
          this.scanDatas.id = isCheckin[0].id;
          this.registerScanDatasToSession();
          return this.nav.to('admin-request-select/' + isCheckin[0].id, undefined,  {queryParams: {from: from, foundByScan: true, ...params}});
        } else if(isCheckin.length > 1) {
          const isMultiple = isCheckin.filter(request => request.operationId === DocumentType.ZWEVISAARR_MULTIPLE || request.operationId === DocumentType.ZWEVISA_MULTIPLE);
          if(isMultiple.length === 1) {
            this.visaDatas = [];
            this.visaDatas$.next(this.visaDatas);
            sessionStorage.removeItem('visaDatas');
            this.scanDatas.id = isMultiple[0].id;
            this.registerScanDatasToSession();
            return this.nav.to('admin-request-select/' + isMultiple[0].id, undefined,  {queryParams: {from, foundByScan: true, ...params}});
          }
          this.visaDatas = isCheckin;
          this.visaDatas$.next(this.visaDatas);
          this.registerScanDatasToSession();
          return this.nav.to('admin-scanned-list', undefined, {queryParams: {fromRoute: from, ...params}});
        } else {
          if(isNoDeclaration || zweRefugee) {
            return register(DocumentType.ZWEENTRY);
          } else {
            return this.loader.loading(true, {type: 'info', message: 'scan.noEntryFound', btnLabel: 'yes', custom: {icon: 'file-circle-question', closeBtnLabel: 'no'}}).then((done) => {
              if(done) {
                this.scanned = true;
                this.cart.startApplication(true);
              } else {
                this.registerScanDatasToSession(false);
                this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
              }
            });
          }
        }
      } else {
        //TODO Change check-in to check-out and remove operationId check (when good substate)
        const isCheckout = datas.filter(request => {
          const parsedData = parseIndexedData(request.internalIndexedData, request.operationId, this.api.userInfo.server);
          const isSameDocumentNumberAndNationality = parsedData.passportNumber === documentNumber && parsedData.nationality === nationality;
          const isValidState = [ActionState.READY_CHECKOUT, ActionState.READY_ZWEEXIT, ActionState.REFUSED].includes(this.request.getStatusTheme(request).actionState);
          const isSyncing = this.cart.syncQueue.find(sync => sync.paymentId === request.id) || this.queue.requestInQueue(request.id);
          const isUsageAfterValid = this.request.isUsageDateValid(request.usageAfter, 'usageAfter');
          const isUsageUntilValid = !request.usageUntil || this.request.isUsageDateValid(request.usageUntil, 'usageUntil');
          return isValidState && isUsageAfterValid && isUsageUntilValid && !isSyncing && isSameDocumentNumberAndNationality;
        });
        if(isCheckout.length === 1) {
          this.visaDatas = [];
          this.visaDatas$.next(this.visaDatas);
          sessionStorage.setItem('visaDatas', JSON.stringify(this.visaDatas));
          this.scanDatas.id = isCheckout[0].id;
          this.registerScanDatasToSession();
          return this.nav.to('admin-request-select/' + isCheckout[0].id, undefined,  {queryParams: {from, foundByScan: true, ...params}});
        } else if(isCheckout.length > 1) {
          const isMultiple = isCheckout.filter(request => request.operationId === DocumentType.ZWEVISAARR_MULTIPLE || request.operationId === DocumentType.ZWEVISA_MULTIPLE);
          if(isMultiple.length === 1) {
            this.visaDatas = [];
            this.visaDatas$.next(this.visaDatas);
            sessionStorage.setItem('visaDatas', JSON.stringify(this.visaDatas));
            this.scanDatas.id = isMultiple[0].id;
            this.registerScanDatasToSession();
            return this.nav.to('admin-request-select/' + isMultiple[0].id, undefined,  {queryParams: {from, foundByScan: true, ...params}});
          }
          this.visaDatas = isCheckout;
          this.visaDatas$.next(this.visaDatas);
          this.registerScanDatasToSession();
          return this.nav.to('admin-scanned-list', undefined, {queryParams: {fromRoute: from, ...params}});
        } else {
          if(this.api.hasPerm(UserPermission.ALLOW_CONSUME) && !this.api.hasPerm(UserPermission.ALLOW_CREATE)) {
            return this.loader.loading(true, {type: 'warn', message: 'allowConsume.noCreate'});
          }
          if(isNoDeclaration) {
            return register(DocumentType.ZWEEXIT);
          } else {
            return this.loader.loading(true, {type: 'info', message: this.lang.transform('scan.noVisaFound'), btnLabel: 'global.confirm', custom: {icon: 'file-circle-question'}}).then((done) => {
              if(done) {
                return register(DocumentType.ZWEEXIT);
              } else {
                this.registerScanDatasToSession(false);
                this.scanDatas = sessionStorage.getItem('scanDatas') ? JSON.parse(sessionStorage.getItem('scanDatas')) : null;
              }
            });
          }
        }
      }
    }
  }

  registerPayment(scanDetails: ReturnType<typeof parse>, type: DocumentType, zweRefugee = false) {
    let passportType;
    if(this.scanDatas.fields.documentCode === 'PR' || zweRefugee) {
      passportType = PassportType.REFUGEE;
    } else if(this.scanDatas.fields.documentCode === 'PD') {
      passportType = PassportType.DIPLOMATIC;
    } else if(this.scanDatas.fields.documentCode === 'PS') {
      passportType = PassportType.SERVICE;
    } else {
      passportType = PassportType.ORDINARY;
    }
    if(this.api.isOnline) {
      return this.api.registerCashPayment(undefined,
        type,
        '',
        RequestCashType.CASH,
        this.api.userPlaceId,
        undefined,
        undefined,
        undefined,
        undefined,
        JSON.stringify({
          FirstName: scanDetails.details.find((data: any) => data.field === 'firstName').value,
          PassportType: type === DocumentType.ZWEENTRY ? passportType : undefined,
          LastName: scanDetails.details.find((data: any) => data.field === 'lastName').value,
          BirthDay: convertToEpoch(scanDetails.details.find((data: any) => data.field === 'birthDate').value, false, true),
          Gender: scanDetails.details.find((data: any) => data.field === 'sex').value,
          Nationality: scanDetails.details.find((data: any) => data.field === 'nationality').value,
          PassportNumber: scanDetails.details.find((data: any) => data.field === 'documentNumber').value,
          BorderPass: type === DocumentType.ZWEEXIT ? 'No' : undefined,
          FundsAvailable: 0,
          FundsAvailableCurrency: 'USD',
          Documents: this.consumeMode === ConsumeMode.TRANSIT ? {PurposeOfVisit: PurposeOfVisit.TRANSIT_VISA} : (this.consumeMode === ConsumeMode.ENTRY && zweRefugee) ? {PurposeOfVisit: PurposeOfVisit.LIVE_HERE} : undefined,
          PassportCountry: (this.consumeMode === ConsumeMode.ENTRY && zweRefugee) ? 'ZWE' : undefined
        })).then((res: any) => res);
    }
  }

  registerScanDatasToSession(visa = true) {
    if(this.scanDatas) {
      sessionStorage.setItem('scanDatas', JSON.stringify(this.scanDatas));
    }
    if(this.documentError) {
      sessionStorage.setItem('documentError', this.documentError);
    }
    if(this.documentWarning) {
      sessionStorage.setItem('documentWarning', this.documentWarning);
    }
    if(this.documentPhoto) {
      sessionStorage.setItem('documentPhoto', this.documentPhoto);
    }
    if(!visa){
      sessionStorage.removeItem('visaDatas');
    } else {
      if(this.visaDatas) {
        sessionStorage.setItem('visaDatas', JSON.stringify(this.visaDatas));
      }
    }
    if(this.documentPhoto && !this.documentError) {
      this.documentError = 'passport.valid';
      sessionStorage.setItem('documentError', this.documentError);
    }
  }

  isRegistered() {
    const scanDatas = sessionStorage.getItem('scanDatas');
    const visaDatas = sessionStorage.getItem('visaDatas');
    if(visaDatas) {
      const parsedVisaDatas = JSON.parse(visaDatas);
      return parsedVisaDatas ? true : false;
    }
    if(scanDatas) {
      const parsedScanDatas = JSON.parse(scanDatas);
      return parsedScanDatas && parsedScanDatas.id ? true : false;
    }
    return false;
  }

  isZweRefugee(nationality: string, issuingState: string) {
    const refugeeNat = ['XXA', 'XXB', 'XXC'];
    return (refugeeNat.includes(nationality) && issuingState === 'ZWE');
  }
}

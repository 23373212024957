import {AbstractControl, ValidatorFn} from '@angular/forms';

export function notInArrayValidator(values: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value.toLowerCase();
    if(values.map(v => v.toLowerCase()).includes(value)) {
      return {'notInArray': {value: value}};
    }
    return null;
  };
}

<div id="layout" class="sign_in f_col">
  <app-view-title *ngIf="this.actualPerson" fromRoute="pre-enroll" [chooseLangBtn]="false">{{this.actualPerson?.firstName + ' ' + this.actualPerson?.lastName}}</app-view-title>

  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <div>
          <app-view-deco-image imageUrl="assets-projects/images/image_male.svg"></app-view-deco-image>
          <app-snackbar class="f_center mb_20" type="valid" Icon="check">{{'global.ready' | translate}}</app-snackbar>
        </div>
        <div>
          <form *ngIf="form" class="f_col gap_20" id="form" [formGroup]="form" (ngSubmit)="submit()">
            <app-input class="mb_5" formCtrlName="firstName" [formGrp]="form" label="{{'form.firstName' | translate}}" icon="user"></app-input>
            <app-input class="mb_5" formCtrlName="lastName" [formGrp]="form" label="{{'form.lastName' | translate}}"  icon="user"></app-input>
            <app-input *ngIf="environment.type === DocumentType.PASS" class="mb_5" formCtrlName="personalNr" [formGrp]="form" label="{{'form.personalNr' | translate}}"  icon="hashtag"></app-input>
            <app-input class="mb_5" formCtrlName="passportNr" [formGrp]="form" label="{{'form.passportNr' | translate}}"  icon="hashtag"></app-input>
            <app-input  [formGrp]="form"  type="select" formCtrlName="linkType" label="{{'form.linkType' | translate}}" icon="list" [selectOptions]="linkTypeOptions"></app-input>
            <app-input *ngIf="otherOptions" formCtrlName="otherLinkType" [formGrp]="form" class="mb_5" disabled="true" label="form.otherLinkType" icon="link"></app-input>
            <app-button type="submit" formId="form" class="modification-save resp_maxSize_l">{{'global.save' | translate}}</app-button>
            <div class="flex gap_10">
              <app-button size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="nav.goBack()">{{'global.back' | translate}}</app-button>
              <app-button size="xsm" type="submit" formId="form" class="modification-save resp_minSize_l">{{'global.save' | translate}}</app-button>
            </div>
            <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
          </form>
        </div>
    </div>
    <div class="btns-container">
      <div class="fullWidth flex f_center">
        <div class="menuButton-container flex f_center">
        </div>
      </div>
    </div>
  </div>
  <div class="flex gap_10 mb_20">
    <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class=" resp_maxSize_l fullWidth" (btnClicked)="nav.goBack()">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {DocumentType} from 'src/app/shared/models/user';

@Component({
  selector: 'app-welcome-employee',
  templateUrl: './welcome-employee.component.html',
  styleUrls: ['./welcome-employee.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeEmployeeComponent {

  constructor(public nav: NavigateService, public api: ApiService) {}
  protected readonly DocumentType = DocumentType;
}

<div id="layout" class="qrList f_col">
  <app-view-title class="" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'payment.qrList.title' | translate}} ({{data?.length}})</app-view-title>
  <div class="scroll_wrapper">
    <div class="qrList_item mt_20" *ngFor="let payment of data">
      <qrcode class="f_center"  [qrdata]="payment?.qrCode" [width]="300" [errorCorrectionLevel]="'M'" allowEmptyString="true"></qrcode>
      <div class="qrList_item_content">
        <div class="input_wrapper" *ngIf="payment?.state">
          <p><span class="info">{{'form.state' | translate}}:</span> {{('payment_status.DYN.' + api.env.type  + '.' + status[payment.state]) | translate}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.metadata?.firstName">
          <p><span class="info">{{'form.name' | translate}}:</span> {{payment?.metadata.firstName}} {{payment?.metadata.lastName}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.metadata?.phone">
          <p><span class="info">{{'form.phone' | translate}}:</span> {{payment?.metadata.phone}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.metadata?.idCard">
          <p><span class="info">{{'form.idCard' | translate}}:</span> {{payment?.metadata?.idCard}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.amountCents">
          <p><span class="info">{{'form.amount' | translate}}:</span> {{payment?.amountCents / 100}} {{payment?.currency}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.metadata?.buyingOption">
          <p><span class="info">{{'form.buyingOption' | translate}}: </span> {{payment?.metadata?.buyingOption?.type ? payment?.metadata?.buyingOption?.type : payment?.metadata?.buyingOption}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.created_place_id">
          <p><span class="info">{{'form.place' | translate}}:</span> {{payment?.created_place_id}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.createdAt">
          <p><span class="info">{{'form.created_date' | translate}}:</span> {{payment?.createdAt}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.id">
          <p><span class="info">{{'form.id' | translate}}:</span> {{payment?.id}}</p>
        </div>
        <div class="input_wrapper" *ngIf="payment?.metadata?.worker">
          <p><span class="info">{{'form.worker' | translate}}:</span> {{payment?.metadata?.worker}}</p>
        </div>
      </div>
    </div>
    <div class="qrList_empty"></div>
    <div id="qrList_backBtn" class="mb_30">
      <app-back-btn (btnClicked)="goToPreviousUrl()"></app-back-btn>
      <div class="ml_10 fullWidth">
        <app-button formId="form" class="fullWidth" (btnClicked)="loader.print('a4')">{{'global.print' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';

@Component({
  selector: 'app-welcome-hello',
  templateUrl: './welcome-hello.component.html',
  styleUrls: ['./welcome-hello.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeHelloComponent{

  constructor(public nav: NavigateService, public api: ApiService) {}
}

import {format} from 'date-fns';
/**
 * Capitalize the first letter of a string and the rest of the string in lowercase
 * @param string
 */
const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const convertUpperCase = (string: string) => string.toUpperCase();

const formatDate = (date: Date, hours = false, onlyHours = false) => hours ? format(new Date(date), 'HH:mm dd/MM/yyyy') : onlyHours ? format(new Date(date), 'HH:mm') : format(new Date(date), 'dd/MM/yyyy');

export {capitalizeFirstLetter, formatDate, convertUpperCase};

export const toCamelCase = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

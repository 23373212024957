import {Component, ViewEncapsulation} from '@angular/core';
import {FormService, LoaderService, NavigateService, ShortcutAction} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {PassportType} from '../../../shared/models/request';
import {CartService} from '../../../shared/services/cart.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RequestService} from '../../../shared/services/request.service';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {ScanDocumentService} from '../../../shared/services/scan-document.service';
import {UserPermission} from '../../../shared/models/user';
import {Direction, VisaFN} from '../../../shared/models/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-3.passport-type',
  templateUrl: './passport-type.component.html',
  styleUrl: './passport-type.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PassportTypeComponent {
  selected: PassportType = this.cart.currentRequest?.passportType || undefined;
  subscription;
  nationalities = this.request.getNationalities(true);
  form: FormGroup;
  keepScanned = false;
  submitted: boolean;
  documentTypes: PassportType[];
  lock = false;
  paramDirection: Direction;
  shortcuts = [
    ShortcutAction.ONE,
    ShortcutAction.TWO,
    ShortcutAction.THREE,
    ShortcutAction.FOUR,
    ShortcutAction.FIVE,
    ShortcutAction.SIX,
    ShortcutAction.SEVEN,
    ShortcutAction.EIGHT,
    ShortcutAction.NINE
  ];

  constructor(public forms: FormService,
    public nav: NavigateService,
    public api: ApiService,
    public cart: CartService,
    private request: RequestService,
    private inactivity: InactivityService,
    private loader: LoaderService,
    private scanDoc: ScanDocumentService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(params => {
      if(params && params.direction) this.paramDirection = params.direction;
    });
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(!this.cart.currentRequest) {
        if(this.cart.currentApplication?.isRevision || (this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CREATE) && this.api.hasPerm(UserPermission.ALLOW_CONSUME))) {
          this.nav.to('dashboard');
        } else if(this.api.userRole.isKiosk) {
          this.nav.to('standby-kiosk');
        } else {
          this.nav.to('travel-name');
        }
        return;
      }
      this.setForm();
      this.cart.currentRequest = {...this.cart.currentRequest, latestRouteVisited: 'passport-type'};
      this.cart.saveCurrentApplication(false);
      this.selected = this.cart.currentRequest.passportType || undefined;
    });
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if(!this.keepScanned) {
      this.scanDoc.scanned = false;
    }
  }

  setForm() {
    this.form = this.formBuilder.group({
      nationality: ['', Validators.required]
    });
    const nationalityFromScan = (this.scanDoc.scanned && this.nationalities?.find(nat => nat.value === this.scanDoc.scanDatas?.fields?.nationality)) ? this.scanDoc.scanDatas?.fields?.nationality : '';
    this.form.controls.nationality.patchValue(this.cart.currentRequest.nationality || nationalityFromScan || '');
    if(nationalityFromScan || this.cart.isFnInRevision(VisaFN.NATIONALITY, true)) this.form.controls.nationality.disable();
    this.setDocumentTypes();
  }

  setDocumentTypes(clear = false) {
    let docs = Object.keys(PassportType).map(key => PassportType[key as keyof typeof PassportType]);
    if(this.form.controls.nationality.value.startsWith('XX')) {
      docs = [PassportType.REFUGEE];
    } else if(this.form.controls.nationality.value !== this.api.userInfo.server.country) {
      docs = docs.filter(doc => doc !== PassportType.IDENTITY_CARD && doc !== PassportType.REFUGEE);
    } else {
      docs = docs.filter(doc => doc !== PassportType.RESIDENT_PERMIT && doc !== PassportType.PERMANENT_RESIDENT_PERMIT && doc !== PassportType.REFUGEE);
    }
    this.documentTypes = docs;

    if(this.cart.currentApplication.isRevision && this.cart.isFnInRevision(VisaFN.PASSPORT_TYPE, true) && this.cart.isFnInRevision(VisaFN.NATIONALITY, true)) {
      if(this.cart.currentApplication.skipValidRevision) this.paramDirection === Direction.NEXT ? this.next() : this.previous();
    }

    if(clear) {
      this.selectPassportType(undefined);
    }
  }

  selectPassportType(passportType: PassportType) {
    if(this.cart.isFnInRevision(VisaFN.PASSPORT_TYPE, true)) return;
    this.selected = passportType;
    this.cart.currentRequest = {
      ...this.cart.currentRequest,
      passportType: passportType
    };
    this.cart.saveCurrentApplication(false);

    if(this.selected && this.selected !== PassportType.ORDINARY && this.selected !== PassportType.IDENTITY_CARD && this.selected !== PassportType.RESIDENT_PERMIT) {
      this.loader.loading(true, {type: 'info', message: 'passportType.acknowledge', custom: {closeBtnLabel: 'understood'}, shortcut: {closeBtn: {action: ShortcutAction.ENTER}}}).then(() => {
        if(this.form.controls.nationality.value) this.submit();
      });
    } else if(this.selected && this.form.controls.nationality.value) {
      this.submit();
    }
  }

  submit() {
    if(this.lock) return;
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      if((this.api.userRole.isKiosk) && ((this.request.isCountryCat(this.form.controls.nationality.value, 'C') && this.cart.currentRequest.passportType === PassportType.ORDINARY) || this.cart.currentRequest.passportType === PassportType.REFUGEE)) {
        this.lock = true;
        this.loader.loading(true, {type: 'warn', message: 'passportType.kioskWarning'}).then(() => {
          this.cart.currentRequest.nationality = undefined;
          this.setForm();
          this.lock = false;
        });
      } else {
        this.lock = false;
        this.cart.currentRequest = {
          ...this.cart.currentRequest,
          nationality: this.form.controls.nationality.value
        };
        this.cart.saveCurrentApplication(false);
        if(this.scanDoc?.scanned) {
          this.keepScanned = true;
        }
        this.next();
      }
    }
  }

  next() {
    this.nav.to('visa-type', undefined, {queryParams: {direction: Direction.NEXT}});
  }

  previous() {
    if(this.api.userRole.isCustomer && !this.cart.currentApplication?.isRevision) {
      this.nav.to('participants');
    } else if(this.cart.currentApplication?.isRevision && this.cart.currentApplication?.requestId) {
      this.nav.to('request-account/'+this.cart.currentApplication?.requestId);
    } else if(this.api.userRole.isKiosk) {
      this.nav.to('standby-desc-kiosk');
    } else {
      this.nav.to('dashboard');
    }
  }

  protected readonly ShortcutAction = ShortcutAction;
}

import {Component, ViewEncapsulation} from '@angular/core';
import {DialogFormInput, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {UserPermission, DocumentType} from '../../../shared/models/user';
import {FingerprintService} from '../../../shared/services/fingerprint.service';
import {QueueService} from '../../../shared/services/queue.service';
import {PaperSize} from 'electron-pos-printer';
import {isJsonParsable} from 'src/app/shared/utils/parseMetadata';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyAccountComponent {

  seniorityLevel: null | number;
  form: FormGroup;

  constructor(public nav: NavigateService,
    public api: ApiService,
    public lang: LangService,
    public queue: QueueService,
    public fingerprint: FingerprintService,
    private loader: LoaderService,
    private formBuilder: FormBuilder) {
    this.seniorityLevel = this.api.checkSeniority();
  }


  ngOnInit() {
    this.setForm();
  }

  setForm() {
    if(this.api.isElectronApp) {
      const printThermal = isJsonParsable(localStorage.getItem('printThermal'));
      const printThermalValue = (printThermal !== null) ? printThermal : this.api.isElectronApp ? true : false;
      this.form = this.formBuilder.group({
        printThermal: [printThermalValue, []]
      });
    }
  }

  changePassword() {
    if(!this.api.isOnline) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('no.connection')});
    } else if(this.api.userInfo && this.api.userInfo.accountName.toLowerCase().includes(this.api.userInfo.server.domain)) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('change.password.ad')});
    } else {
      this.nav.to('change-password');
    }
  }


  history() {
    if(this.api.isOnline) {
      this.nav.to('payment-commandHistory');
    } else {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('no.connection')});
    }
  }

  wip() {
    this.loader.loading(true, {type: 'warn', message: 'WIP'}).then(() => {
      this.nav.to('my-account');
    });
  }

  disconnect() {
    this.loader.loading(true, {type: 'warn', message: 'ask.disconnect', btnLabel: 'global.yes'}).then((done) => {
      if(done) {
        this.api.signOut();
      }
    });
  }

  openChangePrinterSize() {
    const dialogInput = [
      {
        formCtrlName: 'paperSize',
        type: 'select',
        label: 'paperSize',
        selectOptions: [
          {name: '80mm' as PaperSize, value: 80},
          {name: '78mm' as PaperSize, value: 78},
          {name: '76mm' as PaperSize, value: 76},
          {name: '57mm' as PaperSize, value: 57},
          {name: '58mm' as PaperSize, value: 58},
          {name: '44mm' as PaperSize, value: 44}
        ],
        value: localStorage.getItem('printPaperSize') || 80
      }] as DialogFormInput[];
    this.loader.loading(true, {type: 'info', message: 'printPaperSize.desc', btnLabel: 'global.save', dialogInput, custom: {icon: 'arrows-left-right-to-line'}}).then((res: any) => {
      if(res?.paperSize) {
        localStorage.setItem('printPaperSize', res.paperSize);
      }
    });
  }

  togglePrintSetting(event: boolean) {
    this.localStorage.setItem('printThermal', JSON.stringify(event));
  }

  protected readonly UserPermission = UserPermission;
  protected readonly DocumentType = DocumentType;
  protected readonly localStorage = localStorage;
}



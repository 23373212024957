<div id="layout" class="history-payment f_col">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'payment.commandHistory' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-view-deco-image imageUrl="assets-projects/images/image_receipt.svg"></app-view-deco-image>
        <div class="flex f_col gap_4">
          <div *ngIf="request.allMyPayments.length > 0" class="flex f_col gap_10">
            <app-desc-card2 *ngFor="let payment of request.allMyPayments"
                            [dot]="true"
                            type="2"
                            LDesc1="{{request.getNameRequest(payment)}}"
                            LDesc2="{{payment.operationId | translate}}"
                            RDesc1="{{payment.amountCents / 100 + ' ' + CurrencySign[payment.currency]}}"
                            RDesc2="{{formatDate(payment.createdAt, true)}}"
                            [hoverEffect]="true"
                            (btnClicked)="nav.to('receipt/' + payment.id)"></app-desc-card2>
          </div>
          <div class="f_center">
            <span class="mt_40" *ngIf="request.allMyPayments.length === 0"> {{'noPayment' | translate}}</span>
          </div>
        </div>
        <div class="btns-container">
          <div class="fullWidth flex f_center">
            <div class="menuButton-container flex f_center">
              <div class="ml_10 fullWidth">
                <app-button size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="nav.to('my-account')">{{'global.back' | translate}}</app-button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="mb_20 flex gap_10">
    <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-button color="transparent" border="true" formId="form" class=" resp_maxSize_l fullWidth" (btnClicked)="nav.to('my-account')">{{'global.back' | translate}}</app-button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LangService, LoaderService} from 'ngx-satoris';
import {UserPermission, UserPlatformRole} from '../models/user';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {Person} from '../models/person';
import {Request, RequestState} from '../models/request';
import {CartService} from './cart.service';
import {RequestService} from './request.service';
import {QueueService} from './queue.service';

@Injectable()
export class UserValidGuardService implements CanActivate {
  constructor(public api: ApiService, private router: Router, private lang: LangService, private request: RequestService, private loader: LoaderService, private cart: CartService, private queue: QueueService) {
  }

  private draftCheckInterval?: number;
  private isCheckingDraft = false;

  canActivate(r: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Try to auto log in

    this.api.jwt = this.api.getStoredItem('jwt');
    if(this.api.jwt) {
      this.checkJwt();
      if(this.api.userInfo) {
        return this.checkAll(r).then((res: boolean) => res);
      } else {
        try {
          if(this.api.jwt && JSON.parse(atob(this.api.jwt.split('.')[1])).exp > Date.now() / 1000) {
            this.loader.loading(true);
            return this.api.info().then(() => {
              this.loader.loading(false);
              if(this.api.userInfo) {
                if(!this.api.userRole.isCustomer && !this.api.userRole.isKiosk && !this.api.userPlaceId) {
                  this.loader.loading(true, {type: 'error', message: this.lang.transform('dashboard.place.verif')});
                  this.api.signOut('/sign-in');
                  return false;
                } else {
                  return this.checkAll(r).then((res: boolean) => res);
                }
              }
            }, err => {
              this.loader.loading(false);
              this.loader.loading(true, {type: 'error', message: this.lang.transform(err)});
              return false;
            }) as Observable<boolean> | Promise<boolean> | boolean;
          }
        } catch (_) {
          this.api.jwt = undefined;
          this.api.removeStoredItem('jwt');
          return false;
        }
      }
    } else {
      if(!('return_url' in sessionStorage)) {
        const uri = state.url.replace(/;.*$/, '').replace(/^\//, '');
        sessionStorage.setItem('return_url', JSON.stringify(uri.split('/').map(decodeURIComponent)));
      }
      this.router.navigate(['/sign-in']);
      return false;
    }

  }

  checkAll(r: ActivatedRouteSnapshot): Promise<boolean>{
    return this.request.setCountries().then((countries: boolean) => {
      if(this.api.userRole.isAdmin) {
        return this.checkStorage().then((res: boolean) => countries && res && this.checkPlace());
      }
      if(this.api.userRole.isCustomer) {
        return this.checkStorage().then((res: boolean) => this.checkPayments(r).then(() => countries && res && this.checkRole(r) && this.checkPerm(r)));
      } else if(this.api.userRole.isKiosk) {
        return this.checkStorage().then((res: boolean) => countries && res && this.checkRole(r) && this.checkPerm(r));
      } else {
        return this.checkStorage().then((res: boolean) => countries && res && this.checkRole(r) && this.checkPerm(r) && this.checkPlace());
      }
    });
  }

  checkRole(r: ActivatedRouteSnapshot) {
    if(r.data.role !== undefined) {
      if(Array.isArray(r.data.role)) {
        return r.data.role.some((v: UserPlatformRole) => v ===  this.api.userInfo.role);
      } else {
        return this.api.hasPerm(r.data.permission?.toString());
      }
    }
    return true;
  }

  checkPerm(r: ActivatedRouteSnapshot) {
    if(r.data.permission !== undefined) {
      if(Array.isArray(r.data.permission)) {
        return r.data.permission.some((v: UserPermission) => this.api.hasPerm(v));
      } else {
        return this.api.hasPerm(r.data.permission);
      }
    }
    return true;
  }


  checkPaymentsDraft() {
    if(!this.draftCheckInterval && this.request.allMyPayments && this.request.allMyPayments.some((payment) => payment.state === RequestState.PROPOSED)) {
      this.draftCheckInterval = window.setInterval(() => {
        if(!this.isCheckingDraft) {
          this.isCheckingDraft = true;
          const draftPayments = this.request.allMyPayments.filter((payment: Request) => payment.state === RequestState.PROPOSED);
          if(draftPayments.length === 0) {
            clearInterval(this.draftCheckInterval);
            this.draftCheckInterval = undefined;
            return;
          }
          const personsFromDrafts = draftPayments.map((payment: Request) => this.api.listPersons.filter((person: Person) => person.id === payment.person_id)).flat();
          this.request.getRequestData(personsFromDrafts).then(() => {
            this.isCheckingDraft = false;
            if(!this.request.allMyPayments.some((payment) => payment.state === RequestState.PROPOSED)) {
              clearInterval(this.draftCheckInterval);
              this.draftCheckInterval = undefined;
            }
          }).catch(() => {
            this.isCheckingDraft = false;
          });
        }
      }, 15000);
    }
  }

  checkPayments(r: ActivatedRouteSnapshot): Promise<boolean | void> {
    if(r.data.payment !== undefined) {
      if(this.api.userRole.isCustomer && (r.data.payment === true && (!this.request.allMyPayments || !this.request.allMyApplications || !this.request.allExtensions || !this.request.allBatchIds || this.request.invalidatePersons.length))) {
        this.loader.loading(true);
        return this.request.getRequestData(this.request.invalidatePersons).then(() => {
          this.loader.loading(false);
          this.checkPaymentsDraft();
          return true;
        });
      } else {
        this.checkPaymentsDraft();
        return Promise.resolve(true);
      }
    } else {
      return Promise.resolve(true);
    }
  }

  checkPlace() {
    if(!this.api.userRole.isCustomer && !this.api.userPlaceId && !this.api.userRole.isKiosk) {
      this.api.signOut('/sign-in');
      return false;
    }
    return true;
  }

  checkJwt() {
    if(this.api.jwt && JSON.parse(atob(this.api.jwt.split('.')[1])).exp > Date.now() / 1000) {
      return true;
    } else {
      this.api.signOut();
      this.loader.loading(true, {type: 'error', message: this.lang.transform('session.expired')});
      return false;
    }
  }

  checkStorage(): Promise<boolean>{
    return new Promise((resolve) => {
      if(!this.cart.idbstorage) {
        const dbreq = window.indexedDB.open('lu.satoris.paycode', 3);
        dbreq.onsuccess = (e: any) => {
          this.cart.idbstorage = e.target.result;
          const lastApplicationType = localStorage.getItem('lastApplicationType_'+this.api.userInfo?.id) || undefined;
          this.cart.loadCurrentApplication(lastApplicationType).then(() => resolve(true));
          if(this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CONSUME)) {
            this.cart.callSyncQueue();
            this.queue.start();
          }
        };
        dbreq.onupgradeneeded = (e: any) => {
          const db = e.target.result;
          const storeNames = [this.cart.CART_STORE, this.cart.QUEUE_STORE, this.cart.SYNC_STORE, this.cart.REVISION_STORE, this.cart.EXTEND_STORE];
          for(const store of storeNames) {
            if(!db.objectStoreNames.contains(store)) {
              db.createObjectStore(store, {keyPath: 'id'});
            }
          }
        };
      } else {
        if(this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CONSUME)) {
          this.cart.callSyncQueue();
          this.queue.start();
        }
        resolve(true);
      }
    });
  }
}

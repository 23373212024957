import {Component, Inject} from '@angular/core';
import {isBase64, isPDF} from 'src/app/shared/utils/file';
import {ShortcutAction} from 'ngx-satoris';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-doc-viewer',
  templateUrl: './dialog-doc-viewer.component.html',
  styleUrl: './dialog-doc-viewer.component.scss'
})
export class DialogDocViewerComponent {

  currentDocumentIndex = 0;
  isPDF = isPDF;
  originalWindowPrint: () => void;
  zoomValue = 100;

  constructor(public dialogRef: MatDialogRef<DialogDocViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentsInput: { nameDocument: string, document: any, title?: string}[],
      beginIndex?: string
    }) {
    this.originalWindowPrint = window.print;
  }

  ngOnInit() {
    this.data.documentsInput = this.data.documentsInput.filter(doc => isBase64(doc.document) || isPDF(doc.document));
    this.currentDocumentIndex = this.data.beginIndex ? this.data.documentsInput.findIndex(doc => doc.nameDocument === this.data.beginIndex) : 0;
  }

  download(b64: string) {
    try {
      const link = document.createElement('a');
      link.href = b64;
      link.download = this.data.documentsInput[this.currentDocumentIndex].title.toLowerCase().replace(/ /g, '_') + '.png';
      link.click();
    } catch (e) {
      console.log(e);
    }
  }

  zoom(zoomPlus: boolean) {
    if(zoomPlus) {
      this.zoomValue += 25;
    } else {
      this.zoomValue -= 25;
    }
  }

  previousDocument() {
    if(this.currentDocumentIndex > 0) {
      this.zoomValue = 100;
      this.currentDocumentIndex--;
    }
  }

  nextDocument() {
    if(this.currentDocumentIndex < this.data.documentsInput.length - 1) {
      this.zoomValue = 100;
      this.currentDocumentIndex++;
    }
  }

  closeDialog(action?: boolean) {
    this.dialogRef?.close(action);
  }

  protected readonly ShortcutAction = ShortcutAction;
}

import {Component, ViewEncapsulation} from '@angular/core';
import {CartService} from '../../../shared/services/cart.service';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {ApiService} from '../../../shared/services/api.service';

@Component({
  selector: 'app-standby-desc-kiosk',
  templateUrl: './standby-desc-kiosk.component.html',
  styleUrl: './standby-desc-kiosk.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class StandbyDescKioskComponent {
  constructor(public cart: CartService, private inactivity: InactivityService, private api: ApiService) {
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
  }
}

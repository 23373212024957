import {Component, Input, ViewEncapsulation} from '@angular/core';
import {format} from 'date-fns';
import {PrintStickerData} from 'src/app/shared/models/request';
import {QrService} from 'src/app/shared/services/qr.service';

@Component({
  selector: 'app-print-sticker',
  templateUrl: './print-sticker.component.html',
  styleUrl: './print-sticker.component.scss',
  encapsulation: ViewEncapsulation.None

})
export class PrintStickerComponent {
  format = format;
  @Input() data : PrintStickerData;
  @Input() qrCode: string;
  constructor(public qr: QrService) {}
}

<div id="form_reset_btn" class="resp_minSize_l mt_10 flex f_center" *ngIf="cart.currentRequest && api.userRole.isKiosk">
  <app-button color="green" size="xsm" (btnClicked)="cart.resetKioskRequest()"> {{ 'onArrival.reset' | translate }}</app-button>
</div>
<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background">
    <div class="flex gap_10 f_align_c">
      {{ 'visaExtend.title' | translate }}
    </div>
  </app-view-title>
  <div class="scroll_wrapper">
    <div class="f_center mt_20" *ngIf="cart?.currentApplication?.isRevision">
      <app-checkbox-element size="sm" [text]="'skip.valid' | translate" [checked]="cart.currentApplication.skipValidRevision" (onChange)="cart.changeSkip($event.checked)" class="check-validStep"></app-checkbox-element>
    </div>
    <div class="resp_wrapper">
      <div class="resp_c-50 mt_20">
        <app-paragraph textAlign="center" class="mt_10">{{ 'visaExtend.desc' | translate }}</app-paragraph>
      </div>
    </div>
    <div class="parent-container mt_20 mb_40">
      <div class="flex f_col f_align_c f_center" [formGroup]="form" id="extendInput">
        <div class="flex f_align_c f_center gap_10">
          <button *ngIf="!cart.currentApplication?.isRevision || (cart.currentApplication?.isRevision && cart.isFnInRevision(VisaFN.DAYS_REQUESTED, false))"
                  (click)="mod(false)"
                  (mousedown)="mouseDown(false)">-</button>
          <input #numInput type="number" [min]="1" [max]="9999" formControlName="num" (input)="checkNum()">
          <button *ngIf="!cart.currentApplication?.isRevision || (cart.currentApplication?.isRevision && cart.isFnInRevision(VisaFN.DAYS_REQUESTED, false))"
                  (click)="mod(true)"
                  (mousedown)="mouseDown(true)">+</button>
        </div>
        <h2>{{(numInput.value > 1 ? 'days' : 'day') | translate}}</h2>
      </div>
      <div class="resp_wrapper">
        <div class="resp_minSize_l mt_40 mb_40 flex gap_10 resp_c-50">
          <app-button size="xsm" color="transparent" border="true" class="fullWidth"
                      (btnClicked)="previous()">
            {{ 'global.back' | translate }}
          </app-button>
          <app-button size="xsm" type="button" class="fullWidth" (btnClicked)="next()">
            {{ 'global.continue' | translate }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <div class=" mb_20 resp_maxSize_l">
    <div class="f_align_end gap_10">
      <app-back-btn *ngIf="!api.userRole.isKiosk" class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-back-btn (btnClicked)="previous()"></app-back-btn>
      <div class="fullWidth">
        <app-button type="button" class="fullWidth" (btnClicked)="next()">
          {{ 'global.continue' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</div>

<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'global.receipt' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-view-deco-image imageUrl="assets-projects/images/image_payment_history.svg"></app-view-deco-image>
        <div class="flex f_col f_align_c">
          <app-paragraph class="fw_bold mb_10" *ngIf="api.env.type === DocumentType.ZWEVISA">{{'global.receipt.id' | translate: {paymentId: paymentId} }}</app-paragraph>
          <app-desc-card2 *ngIf="actualPayment" class="mb_10 fullWidth card-receipt" type="2" LDesc1="{{request.getNameRequest(actualPayment)}}" LDesc2="{{actualPayment.operationId | translate}}" LDesc3="{{actualPayment.operationId === DocumentType.ZWEENTRY ? ('free.charge' | translate) : capitalize(actualPayment?.paymentType)}}" LDesc4="{{(environment.type === DocumentType.ZWEVISA ? 'payment_status.DYN.' + api.env.type + '.' + actualPayment.state : RequestRemoteState[actualPayment.remoteState]) | translate}}" RDesc1="{{actualPayment?.amountCents / 100 + ' ' +  CurrencySign[actualPayment.currency]}}" RDesc2="{{formatDate(actualPayment?.createdAt, true)}}"></app-desc-card2>
        </div>
        <div class="btns-container">
          <div class="fullWidth flex f_center">
            <div class="menuButton-container flex f_center">
              <div class="ml_10 fullWidth">
                <app-button size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="nav.to('payment-commandHistory')">{{'global.back' | translate}}</app-button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="flex gap_10 mb_20">
    <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-button color="transparent" border="true" formId="form" class=" resp_maxSize_l fullWidth" (btnClicked)="nav.to('payment-commandHistory')">{{'global.back' | translate}}</app-button>
  </div>
</div>

import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserRoles} from 'src/app/shared/models/user';
import {FormService} from 'src/app/shared/services/form.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import * as userPlatformRole from '../../../shared/models/user';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  roles = UserRoles;
  platformRole = (userPlatformRole as any).UserPlatformRole;
  reloadOnClose: boolean;
  userId: number;
  data: any;
  disabled=true;
  actualRoute: string;

  constructor(public nav: NavigateService,
    public api: ApiService,
    public forms: FormService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private lang: LangService,
    public router: Router) {
    this.userId = +this.route.snapshot.paramMap.get('userId');
    this.actualRoute = router.url;
  }

  ngOnInit() {
    this.loader.loading(true);
    this.getUser(this.userId).then(() => {
      this.setForm();
      this.changeDetectorRef.detectChanges();
      this.loader.loading(false);
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      email: [{value: this.data?.accountName, disabled: this.disabled}, [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]],
      name: [{value: this.data?.name, disabled: this.disabled}, [
        Validators.required,
        Validators.maxLength(50)
      ]],
      role: [{value: this.data?.role, disabled: this.disabled}, [
        Validators.required
      ]],
      userId: [{value: this.data?.userId, disabled: this.disabled}, [
        Validators.required,
        Validators.maxLength(50)
      ]],
      id: [{value: this.data?.id, disabled: true}, [
        Validators.required
      ]]
    });
  }

  updateUser() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.api.updateUser(this.form.get('id').value, this.form.get('userId').value, this.form.get('email').value, this.form.get('name').value).then(() => {
        this.loader.loading(false);
        this.loader.loading(true, {type: 'info', message: 'Your account has been updated.'}).then(() =>{
          this.getUser(this.userId);
          this.toggleChange();
        });
        this.submitted = false;
        this.reloadOnClose = true;
      }).catch((err) => {
        if(err.includes('Cannot insert duplicate key row in object')) {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('email.error')});
        } else {
          this.loader.loading(true, {type: 'error', message: err});
        }
      });
    }
  }

  lockUser(lock: boolean) {
    if(this.api.userInfo.id === this.data.id) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('block.yourself')});
    } else {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('block.message'), btnLabel: this.lang.transform('global.confirm')}).then((done: boolean) => {
        if(done) {
          this.api.lock(this.data?.id, lock).then(() => {
            this.loader.loading(true, {type: 'info', message: this.data?.lockedAt ? 'block.unblock' : 'block.done'}).then(() =>{
              this.getUser(this.userId);
            });
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    }
  }

  getUser(id: number) {
    return this.api.user(id).then((response) => {
      this.data = response;
    });
  }

  toggleChange() {
    for(const key in this.form.controls) {
      if( key !== 'id' && key!== 'role') { // check if we lock it or not
        this.disabled ? this.form.controls[key].enable() : this.form.controls[key].disable();
      }
    }
    this.disabled = !this.disabled;
  }
  goToUserPayment() {
    this.nav.to('admin-request', undefined, {state: this.data?.id, queryParams: {userId: this.data?.id}});
  }
  goToPreviousUrl() {
    this.router.navigateByUrl(localStorage.getItem('previous_url'));
  }
}

<div id="form_reset_btn" class="resp_minSize_l mt_10 flex f_center" *ngIf="cart.currentRequest && api.userRole.isKiosk">
  <app-button color="green" size="xsm" (btnClicked)="cart.resetKioskRequest()"> {{ 'onArrival.reset' | translate }}</app-button>
</div>
<div id="layout" class="f_col urgent">
  <app-view-title [bgImage]="api.env.theme.header_background">
    <div class="flex gap_10 f_align_c">
      {{'urgent.title' | translate}}
      <app-snackbar [classList]="'cursor-pointer'" (click)="cart.openParticipants()" *ngIf="cart.currentRequest && api.userRole.isCustomer && !cart.currentApplication.isRevision">
        <fa-icon icon="bars" class="mr_10"></fa-icon>
        {{cart.currentRequest.person.firstName}} {{cart.currentRequest.person.lastName[0]}}.
      </app-snackbar>
    </div>
  </app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <div class="f_center" *ngIf="cart?.currentApplication?.isRevision">
        <app-checkbox-element size="sm" [text]="'skip.valid' | translate" [checked]="cart.currentApplication.skipValidRevision" (onChange)="cart.changeSkip($event.checked)" class="check-validStep"></app-checkbox-element>
      </div>
      <app-view-deco-image imageUrl="assets-projects/images/image_no_time.svg"></app-view-deco-image>
      <app-paragraph innerhtml="{{'urgent.desc' | translate}}"></app-paragraph>
      <div *ngIf="show" class="mt_20">
        <lib-choice-button [disableClick]="disabled"
                           [buttons]="choiceButtons"
                           size="xsm"
                           [value]="defaultValue"
                           (output)="change($event)"
                           [text]="'urgent.checkbox' | translate"></lib-choice-button>
      </div>
      <div class="resp_minSize_l mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (btnClicked)="previous()">
          {{'global.back' | translate}}
        </app-button>
        <app-button size="xsm" type="button" class="fullWidth" (btnClicked)="next()" [disabled]="!defaultValue">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
  <div class="mb_20 resp_maxSize_l">
    <div class="f_align_end gap_10">
      <app-back-btn *ngIf="!api.userRole.isKiosk" class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-back-btn (btnClicked)="cart.resetKioskRequest()" [color]="'#F54321'" icon="arrow-rotate-left" *ngIf="cart.currentRequest?.nationality && cart.currentRequest?.passportType && api.userRole.isKiosk"></app-back-btn>
      <app-back-btn (btnClicked)="previous()"></app-back-btn>
      <div class="fullWidth">
        <app-button type="button" class="fullWidth" (btnClicked)="next()" [disabled]="!defaultValue">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-welcome-thanks',
  templateUrl: './welcome-thanks.component.html',
  styleUrls: ['./welcome-thanks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeThanksComponent {
  env = environment;

  constructor(public nav: NavigateService, public api: ApiService) {}

  close() {
    this.api.storeItem('welcome', true);
    this.nav.to('dashboard');
  }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {CartService} from '../../shared/services/cart.service';
import {ApiService} from '../../shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from 'src/app/shared/services/request.service';
import {Request, RequestWithDuplicata} from '../../shared/models/request';

@Component({
  selector: 'app-tracking-control',
  templateUrl: './tracking-control.component.html',
  styleUrls: ['./tracking-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrackingControlComponent {

  batchId: string;

  constructor(public nav: NavigateService,
    public cart: CartService,
    public api: ApiService,
    private route: ActivatedRoute,
    public request: RequestService) {
    this.route.queryParams.subscribe(params => {
      this.batchId = params.batchId;
    });
  }

  getSortedRequests(): Request[] | RequestWithDuplicata[] {
    return this.request.getBatch(this.batchId).requests.sort((a, b) =>
      //sort by severity
      this.request.getHighestSeverityTheme(undefined, b as RequestWithDuplicata).severity - this.request.getHighestSeverityTheme(undefined, a as RequestWithDuplicata).severity);
  }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {InactivityService} from '../../shared/services/inactivity.service';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'app-standby-kiosk',
  templateUrl: './standby-kiosk.component.html',
  styleUrl: './standby-kiosk.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class StandbyKioskComponent {
  constructor(public nav: NavigateService, public api: ApiService, private inactivity: InactivityService) {
    this.inactivity.stopWatching();
  }
}

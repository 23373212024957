import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '../../../shared/services/loader.service';
import {generateQrOnline} from '../../../shared/utils/generateQr';
import {RequestStatus, RequestStep} from 'src/app/shared/models/request';
import {ApiService} from 'src/app/shared/services/api.service';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-admin-request-qr-list',
  templateUrl: './admin-request-qr-list.component.html',
  styleUrls: ['./admin-request-qr-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestQrListComponent implements OnInit, OnDestroy {

  data: RequestStep[] | any;
  status = RequestStatus;

  constructor(public loader: LoaderService,
    public api: ApiService,
    private router: Router,
    private nav: NavigateService) {
    this.data = this.router.getCurrentNavigation().extras.state;
    if(!this.data) this.nav.goBack();
  }

  ngOnInit(): void {
    if(Array.isArray(this.data)) {
      this.data?.forEach((payment: RequestStep, i: number) => {
        this.data[i].qrCode = generateQrOnline(payment.id, payment.signature, payment.created_place_id, payment.externalId);
      });
    } else {
      const obj = this.data;
      this.data = [];
      Object.keys(obj).forEach((key: string) => {
        this.data.push(obj[key]);
        this.data[this.data.length - 1].qrCode = generateQrOnline(obj[key].id, obj[key].signature, obj[key].created_place_id, obj[key].externalId);
      });
    }

    document.querySelector('html').classList.add('unlockScroll');
  }

  ngOnDestroy() {
    document.querySelector('html').classList.remove('unlockScroll');
  }

  goToPreviousUrl() {
    this.router.navigateByUrl(localStorage.getItem('previous_url'));
  }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {CartService} from '../../../shared/services/cart.service';
import {ApiService} from 'src/app/shared/services/api.service';
import {QrService} from 'src/app/shared/services/qr.service';
import {InactivityService} from 'src/app/shared/services/inactivity.service';
import {RequestService} from 'src/app/shared/services/request.service';

declare const window: Window & {initialQueryString: string[][]};

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuccessPaymentComponent {

  isPaymentError: string;

  constructor(public nav: NavigateService,
              private cart: CartService,
              public api: ApiService,
              public loader: LoaderService,
              public qr: QrService,
              private inactivity: InactivityService,
              public request: RequestService) {
    const errorHolder = window.initialQueryString.find((q: string[]) => q[0] === 'error');
    if(errorHolder) {
      this.isPaymentError = errorHolder[1];
    }
    if(this.inactivity.isWatching) this.inactivity.stopWatching();
  }

  ngOnDestroy() {
    if(!this.isPaymentError) {
      this.cart.deleteSavedApplication();
    }
    this.inactivity.stopWatching();
  }

  navClose() {
    if(this.isPaymentError) {
      this.nav.to('summary');
    } else {
      this.nav.to('trips');
    }
  }
}

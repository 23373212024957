<div id="layout" class="f_col">
  <app-view-title fromRoute="password-send" [chooseLangBtn]="false">{{'global.changePassword' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
        <app-view-deco-image imageUrl="assets-projects/images/image_new_password.svg"></app-view-deco-image>
        <div>
          <form id="form" [formGroup]="form" (ngSubmit)="submitForm()">
            <app-input class="fullWidth" type="password" formCtrlName="old" (output)="checkPswdStrength($event)"
                       [formGrp]="form" label="form.password_old" class="mt_24"></app-input>
            <app-input type="password" formCtrlName="password"
                       [formGrp]="form" label="form.password_new" class="mt_24 mb_20"></app-input>
            <div class="pswd_strength mt_4">
              <span class="pswd_strength_bar" *ngIf="pswdStrength >= 20"></span>
              <span class="pswd_strength_bar" *ngIf="pswdStrength >= 40"></span>
              <span class="pswd_strength_bar" *ngIf="pswdStrength >= 70"></span>
              <span class="pswd_strength_bar" *ngIf="pswdStrength >= 90"></span>
            </div>
            <app-input type="password" formCtrlName="password2"
                       [formGrp]="form" label="form.password2"></app-input>
            <app-errors-list [dark]="true" *ngIf="submitted"
                             [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
          </form>
          <div class="flex f_col mb_20 btn-changepwd">
            <div class="flex gap_10 f_align_c">
              <app-button size="xsm" color="transparent" border="true" type="button" (click)="nav.to('my-account')" class="fullWidth resp_minSize_l">{{'global.back' | translate}}</app-button>
              <app-button size="xsm" type="submit" formId="form" class="fullWidth resp_minSize_l">{{'global.confirm' | translate}}</app-button>
            </div>
          </div>
    </div>
    </div>
  </div>
  <div class="resp_maxSize_l flex gap_5 mb_20">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-button border="true" class="resp_maxSize_l" (btnClicked)="nav.to('my-account')">
      <fa-icon icon="arrow-left"></fa-icon>
    </app-button>
    <app-button type="button" (click)="submitForm()" class="fullWidth  resp_maxSize_l">{{'global.confirm' | translate}}</app-button>
  </div>
</div>

<div id="webcamImage" class="loader_webcam" *ngIf="picture">
  <div class="faceCheckImgs">
    <img class="face-picture" [src]="picture" alt="facePicture" [class.face-picture-sm]="showFaceCheck">
    <span id="faceCheckScore" *ngIf="showFaceCheck" [style.background-color]="faceCheckScoreColor">{{faceCheckScore}}<span>/100</span></span>
    <img *ngIf="showFaceCheck && scanDocument.documentPhoto" class="face-picture" [src]="scanDocument.documentPhoto" alt="PassportPicture" [class.face-picture-sm]="showFaceCheck">
  </div>
  <div class="webcam_btns f_center">
    <app-button size="xsm" color="red" [shortcut]="ShortcutAction.ESCAPE" (click)="goBack()">{{'global.close' | translate}}</app-button>
    <app-button size="xsm" color="red" [shortcut]="ShortcutAction.BACKSPACE" (click)="close()">{{'retake_picture' | translate}}</app-button>
    <app-button *ngIf="scanDocument.documentPhoto && !showFaceCheck" size="xsm" color="green" (btnClicked)="faceCheck()">{{'faceCheck.see_score' | translate}}</app-button>
    <app-button size="xsm" color="green" [shortcut]="ShortcutAction.ENTER" (click)="consumePayment()">{{'global.validate' | translate}}</app-button>
  </div>
</div>
<app-webcam [fullHeight]="true"
            *ngIf="!picture && showCamera"
            [backUrl]="query.fromNoticePrint  === 'true' ? 'admin-request-notice/' + query.paymentId : query.fromScanFingerprint === 'true' ? 'admin-scan-fingerprint' : (query.from && query.fromScanPassport) ? query.from : 'admin-request-select/' + query.paymentId"
            [backUrlParams]="backParams"
            (output)="setPicture($event)"
            [imageQuality]="imageQuality"
            [videoOptions]="videoOptions"
            [brokenButton]="api.env.type === DocumentType.ZWEVISA ? true : false"
            (emitManualProblem)="consumePayment(true)"
            [canRotateCamera]="true"
            (cameraProblem)="consumePayment(false, true)"></app-webcam>
<app-print-receipt-a5 *ngIf="currentPrintReceiptDatas" [receiptData]="currentPrintReceiptDatas"></app-print-receipt-a5>

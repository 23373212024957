import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Place} from '../../../shared/models/place';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {User} from '../../../shared/models/user';
import {ActivatedRoute} from '@angular/router';
import {PlacesService} from '../../../shared/services/places.service';

@Component({
  selector: 'app-place-add',
  templateUrl: './place-add.component.html',
  styleUrls: ['./place-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceAddComponent implements OnInit {

  data: Place[];
  filteredData: Place[];
  form: FormGroup;
  actualUser: User;
  scrollNumber = 30;
  userId: number;
  constructor(public nav: NavigateService,
              private api: ApiService,
              private formBuilder: FormBuilder,
              private loader: LoaderService,
              private route: ActivatedRoute,
              private lang: LangService,
              public place: PlacesService) {
    this.userId = +this.route.snapshot.paramMap.get('userId');
  }
  ngOnInit(): void {
    this.setForm();
    this.getUser(this.userId).then(() => {
      this.place.getAllPlaces().then(() => {
        this.data = this.place.allPlaces;
        this.filteredData = this.data;
      });
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      text: ['', []]
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const regexFilter = filterValue.startsWith('#') ? new RegExp('^' + filterValue.trim().toLowerCase().replace('#', '') + '.*$', 'g') : new RegExp('^' + filterValue.trim().toLowerCase() + '.*$', 'g')  ;
    const filterArray = this.data.filter((data: any) => {
      if(filterValue.startsWith('#')) {
        return data.id.trim().toLowerCase().match(regexFilter);
      } else {
        return data.longName.trim().toLowerCase().match(regexFilter) || data.longName.trim().toLowerCase().match(regexFilter);
      }
    });
    this.filteredData = filterArray;
  }

  onScroll(event: any) {
    if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if(this.scrollNumber < this.filteredData.length) {
        this.loader.loading(true);
        setTimeout(() =>{
          this.scrollNumber += 30;
          this.loader.loading(false);
        }, 1000);
      }
    }
  }

  selectPlace(placeId: string) {
    this.loader.loading(true, {type: 'info', message: this.lang.transform('place.add.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done: boolean) => {
      if(done) {
        const createPlaceIds: string[] = [];
        const consumePlaceIds: string[] = [];
        const controlPlaceIds: string[] = [];
        const adminPlaceIds: string[] = [];
        this.actualUser.links?.forEach(item => {
          item.allowCreate ? createPlaceIds.push(item.place_id) : undefined;
          item.allowConsume ? consumePlaceIds.push(item.place_id) : undefined;
          item.allowControl ? controlPlaceIds.push(item.place_id) : undefined;
          item.allowAdmin ? adminPlaceIds.push(item.place_id) : undefined;
        });

        createPlaceIds.push(placeId);
        this.api.places(this.actualUser.id, createPlaceIds, consumePlaceIds, controlPlaceIds, adminPlaceIds, Math.max(...this.actualUser.links.map(l => l.seniority))).then(() => {
          this.loader.loading(true, {type: 'info', message: this.lang.transform('place.add.done')}).then(() => {
            this.nav.to('user/'+ this.userId + '/places-choice');
          });
        }).catch(err => {
          this.loader.loading(true, {type: 'error', message: err});
        });
      }
    });
  }

  getUser(id: number) {
    return this.api.user(id).then(response => {
      this.actualUser = response;
    });
  }
}

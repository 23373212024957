<div id="layout" class="f_col">
  <app-view-title fromRoute="person" [chooseLangBtn]="false">{{'people.title.DYN.' + api.env.type | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image imageUrl="assets-projects/images/image_people.svg"></app-view-deco-image>
      <div class="flex f_col slide-container">
        <div *ngIf="api.listPersons">
          <div *ngFor="let person of api.listPersons;">
            <app-slide-element [noWrap]="true"  leftIcon="user" (btnClicked)="onClick(person.id)" class="mb_10">
              {{person?.firstName + ' ' + person?.lastName}}
            </app-slide-element>
          </div>
          <app-slide-element [noWrap]="true" leftIcon="address-book" (btnClicked)="openDialogNewPerson()" [matTooltip]="api.isZwevisa ? ('tooltip.addPerson' | translate) : ''">
            {{'people.addPerson.DYN.' + api.env.type | translate}}
          </app-slide-element>
        </div>
      </div>
      <div class="resp_minSize_l mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (btnClicked)="nav.to('dashboard')">
          {{'global.back' | translate}}
        </app-button>
      </div>
    </div>
  </div>
  <div class="f_align_end mb_20 resp_maxSize_l">
    <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="resp_maxSize_l fullWidth" (btnClicked)="nav.to('dashboard')">{{'global.back' | translate}}</app-button>
    </div>
  </div>
</div>

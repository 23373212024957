import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DialogFormInput,
  DialogsService,
  faSpellCheck,
  LangService,
  LoaderService,
  NavigateService,
  ShortcutAction
} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {addDays, addMilliseconds, endOfDay, format, isBefore, parse, parseISO} from 'date-fns';
import {Place} from '../../../shared/models/place';
import {isJsonParsable, parseIndexedData, parseMetadata} from 'src/app/shared/utils/parseMetadata';
import {Document, DocumentType, Gender, UserPermission} from 'src/app/shared/models/user';
import {
  ActionState,
  ComsumePaymentData,
  ConsumePaymentNoticeType,
  ConsumeRequestData,
  CurrencySign,
  DataGroup,
  OperationType,
  PassportType,
  PrintApprovalLetterData,
  PrintBorderpassData,
  PrintReceiptData,
  PrintStickerData,
  PurposeOfVisit,
  RequestCashType,
  RequestState,
  RequestSubState,
  RequestWithDuplicata
} from 'src/app/shared/models/request';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from 'src/environments/environment';
import {VisaFN} from 'src/app/shared/models/forms';
import {PlatformEvent, PlatformEventType} from 'src/app/shared/models/information';
import {QrService} from 'src/app/shared/services/qr.service';
import {CartService} from 'src/app/shared/services/cart.service';
import {RequestService} from 'src/app/shared/services/request.service';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';
import {cleanDate, convertToEpoch} from 'src/app/shared/utils/date';
import {Subscription} from 'rxjs';
import * as uuid from 'uuid';
import {isBase64, isPDF, mergeDocuments} from 'src/app/shared/utils/file';
import {FingerprintService} from '../../../shared/services/fingerprint.service';
import {DialogDocViewerComponent} from '../../../components/dialogs/dialog-doc-viewer/dialog-doc-viewer.component';
import {QueueService} from '../../../shared/services/queue.service';
import {SyncService} from '../../../shared/services/sync.service';
import {enGB} from 'date-fns/locale';
declare const window: any;

@Component({
  selector: 'app-admin-request-select',
  templateUrl: './admin-request-select.component.html',
  styleUrls: ['./admin-request-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestSelectComponent implements OnDestroy {
  document: Document;
  paymentId: string;
  currentPayment: RequestWithDuplicata;
  metadata: any;
  placeActual: Place;
  fromRoute: string;
  placeConsumed: Place;
  PlatformEventType = PlatformEventType;
  syncQueueSubscription: Subscription;
  searchParams = {};
  actualManualConfirmRounds: number;
  localUsageUntilfromServer: string;
  referencePaymentOnarrival: string;
  printBorderPassData: PrintBorderpassData;
  eventCheckout: ConsumeRequestData;
  form: FormGroup;
  checkDraftInterval: NodeJS.Timeout;
  finishCallPayment = false;
  isCheckDraft = false;
  seniorityLevel: null | number;
  currentPrintReceiptDatas: PrintReceiptData;
  blacklist = false;
  isSyncing = false;
  cleanDate = cleanDate;
  isJsonParsable = isJsonParsable;
  isExtension = false;
  isNoDeclaration = false;
  canBeDeported = false;
  usageAfterValid = false;
  usageUntilValid = false;
  localUsageUntilValid = false;
  printReceiptData: PrintReceiptData[] = [];
  isRevisionSinceOneWeek = false;
  OperationType = OperationType;
  ConsumePaymentNoticeType = ConsumePaymentNoticeType;
  selectedOperation = 0;
  printVisaApplication: DataGroup[] = [];
  isNextCheckOut: boolean;
  checkRefusal: boolean | OperationType;
  RequestSubState = RequestSubState;
  status = RequestState;
  UserPermission = UserPermission;
  selectMode: boolean;
  selectedFields: VisaFN[] = [];
  isUsage: boolean;
  printCurrent: 'receipt' | 'receipt-a5' | 'application' | 'borderPass' | `notice-${ConsumePaymentNoticeType}` | 'approvalLetter' | 'sticker' | '';
  printDocument: {
    title: string;
    img: string;
    id: string;
    date: string;
  } = {
      title: '',
      img: '',
      id: '',
      date: ''
    };
  approvalLetterData: PrintApprovalLetterData;
  referenceToPrint = '';
  showDocuments: { nameDocument: string, document: any }[] = [];
  visualizerIndex = '';
  metadataDocuments: { nameDocument: string, document: any, title?: string, icon?: string, customClass?: string, previewDirect?: string, checkComparison?: boolean}[] = [];
  isDiplomatic = false;
  isCatAB = false;
  indexedData: any;
  isPDF = isPDF;
  existingNotices: PlatformEvent[];
  formNotice: FormGroup;
  docFormNotice: string[] = [];
  showComparison = false;
  foundByScan = false;
  deportedMode = false;
  cancelEvent: PlatformEvent[] = [];
  stickerData: PrintStickerData;
  currentPlace: Place;

  constructor(public nav: NavigateService,
              public api: ApiService,
              public qr: QrService,
              public cart: CartService,
              public request: RequestService,
              public queue: QueueService,
              public scanDocument: ScanDocumentService,
              public sync: SyncService,
              private fingerprint: FingerprintService,
              private route: ActivatedRoute,
              private loader: LoaderService,
              private formBuilder: FormBuilder,
              private dialogs: DialogsService,
              private lang: LangService,
              private router: Router) {
    this.currentPlace = this.api.userPlaces?.find(place => place.id === this.api.userPlaceId);
    this.seniorityLevel = this.api.checkSeniority();
    let registerScanSession = false;
    this.route.queryParams.subscribe(params => {
      const {from, registerSession, foundByScan, ...search} = params;
      registerScanSession = registerSession;
      this.searchParams = search;
      this.fromRoute = from;
      this.foundByScan = foundByScan === 'true';
    });
    this.route.paramMap.subscribe(params => {
      this.paymentId = params.get('paymentId');
      window.sessionStorage.setItem('previousIdApplication', this.paymentId);
      if(registerScanSession && this.scanDocument.scanned) {
        this.scanDocument.scanDatas.id = this.paymentId;
        this.scanDocument.registerScanDatasToSession();
        this.scanDocument.scanned = false;
      }
      this.payment();
    });
    if(this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CONSUME)) {
      this.syncQueueSubscription = this.cart.syncQueue$.subscribe((data) => {
        if(this.currentPayment?.id === data?.paymentId && data) {
          this.payment();
        }
      });
    }
  }

  ngOnInit() {
    this.setForm();
  }

  ngOnDestroy() {
    if(this.checkDraftInterval) {
      clearInterval(this.checkDraftInterval);
    }
    if(this.syncQueueSubscription) {
      this.syncQueueSubscription.unsubscribe();
    }
  }

  updateField(field: VisaFN, inputType: 'text' | 'number' | 'password' | 'email' | 'select' | 'tel' | 'date' | 'pin' | 'datetime-local' | 'textarea' | 'file', value: any) {
    if(!this.canUpdateField()) return;
    const dialogInput: DialogFormInput[] = [{
      formCtrlName: field,
      type: inputType,
      label: field,
      value: inputType === 'date' ? format(new Date(value), 'yyyy-MM-dd') : value,
      validator: [Validators.required]
    }];
    this.loader.loading(true, {type: 'info', btnLabel: 'global.update', custom: {icon: 'pen-to-square', innerHtml: this.lang.transform('updateField.desc', {field: this.lang.transform(field)})}, dialogInput}).then((res: any) => {
      if(res?.[field] && res?.[field] !== value) {
        let newValue = res[field];
        if(inputType === 'date') {
          newValue = new Date(newValue);
        }
        const serializedDatas = JSON.stringify({[field]: newValue});
        this.loader.loading(true);
        this.api.updatePayment(this.currentPayment.id,
          undefined, undefined,
          field !== VisaFN.USAGE_AFTER && field !== VisaFN.USAGE_UNTIL ? serializedDatas : undefined,
          undefined, undefined, undefined, undefined,
          field === VisaFN.USAGE_AFTER ? newValue : undefined,
          field === VisaFN.USAGE_UNTIL ? newValue : undefined).then(() => {
          this.payment().then(() => {
            this.loader.loading(false);
          });
        }).catch((err) => {
          this.loader.loading(true, {type: 'error', message: err});
        });
      }
    });
  }

  canUpdateField(): boolean {
    return this.api.userRole.isWorker &&
      this.api.hasPerm(UserPermission.ALLOW_CONTROL) &&
      this.seniorityLevel && this.seniorityLevel >= 2 && this.currentPayment.state !== RequestState.USED;
  }

  isZweentryOrdinaryCatC() {
    return this.metadata && this.currentPayment && this.metadata.PassportCountry !== 'ZWE' && this.currentPayment.state === RequestState.PAID && this.request.CatC.some(n => n.value === this.metadata.Nationality) && this.currentPayment.operationId === DocumentType.ZWEENTRY && this.metadata.PassportType === PassportType.ORDINARY;
  }

  checkIfSpecifiedNationals(): boolean {
    const currentPlace = this.api.userPlaces?.find(place => place.id === this.api.userPlaceId);
    return currentPlace && currentPlace.specifiedNationals.includes(this.scanDocument.scanDatas?.fields?.nationality || this.metadata?.Nationality);
  }

  payment(errorBack = false) {
    const FULLFILED = 'fulfilled';
    this.loader.loading(true);
    this.finishCallPayment = false;
    return this.api.payment(this.paymentId, true, true, true, true, true).then(payment => {
      this.isSyncing = this.cart.syncQueue.some(item => item.paymentId === this.paymentId) || this.queue.requestInQueue(this.paymentId);
      this.currentPayment = payment;
      this.indexedData = parseIndexedData(this.currentPayment.internalIndexedData, this.currentPayment.operationId, this.api.userInfo.server);
      this.isDiplomatic = this.indexedData.passportType === PassportType.DIPLOMATIC;
      this.isCatAB = this.request.isCountryCat(this.indexedData.nationality, 'A') || this.request.isCountryCat(this.indexedData.nationality, 'B');
      if(this.currentPayment.state === RequestState.PROPOSED) {
        this.paymentCheckDraft();
      }
      this.isExtension = this.currentPayment.operationId === DocumentType.ZWEVISAEXTAB || this.currentPayment.operationId === DocumentType.ZWEVISAEXTC;
      this.blacklist = this.request.isBlacklisted(this.currentPayment) && this.currentPayment.state === RequestState.EXPIRED;
      const today = endOfDay(new Date());
      const usageAfter = endOfDay(new Date(this.currentPayment.usageAfter));
      const usageUntil = endOfDay(new Date(this.currentPayment.usageUntil));
      const localUsageUntil = endOfDay(new Date(this.currentPayment.localUsageUntil));
      this.usageAfterValid = today >= usageAfter;
      this.usageUntilValid = today <= usageUntil;
      this.localUsageUntilValid = this.currentPayment.localUsageUntil ? today <= localUsageUntil : true;
      this.isRevisionSinceOneWeek = new Date().getTime() - new Date(this.currentPayment.createdAt).getTime() > 604800000;
      this.actualManualConfirmRounds = this.api.userInfo.server.paymentRequests.find(document => document.id === this.currentPayment?.operationId)?.manualConfirmRounds;
      const promiseBatchIds = this.currentPayment.fetchedAt ? [] : this.currentPayment.batch.views.filter(b => b !== this.paymentId).map(batchId => this.api.payment(batchId.id).then((batch: RequestWithDuplicata) => {
        const indexData = parseIndexedData(batch.internalIndexedData, batch.operationId, this.api.userInfo.server);
        this.currentPayment.batch.views[this.currentPayment.batch.views.findIndex(b => b.id === batchId.id)] = {...batchId, ...indexData};
        for(let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if(key.startsWith('payment') && key.endsWith(this.currentPayment.id)) {
            const paymentStorage = localStorage.getItem(key);
            if(paymentStorage) {
              const paymentToUpdate = isJsonParsable(paymentStorage);
              if(paymentToUpdate) {
                paymentToUpdate.batch.views = this.currentPayment.batch.views;
                localStorage.setItem(key, JSON.stringify(paymentToUpdate));
              }
            }
            break;
          }
        }
      }));
      return Promise.allSettled(promiseBatchIds).then(() => {
        this.document = this.api.userInfo.server.paymentRequests.find(d => d.id === payment.operationId);
        this.isNextCheckOut = this.request.isNextCheckout(this.currentPayment);
        this.checkRefusal = this.request.detectCheckRefusal(this.currentPayment);
        this.isUsage = this.request.isUsage(this.currentPayment);
        // Get all metadatas
        const moveEvents = this.moveEvents();
        this.cancelEvent = this.currentPayment.events
          .filter(event => event.type === PlatformEventType.PAYMENT_VOIDED)
          .map(voidEvent => {
            const voidedEventId = JSON.parse(voidEvent.context).platform_event_id;
            const usageEvent = this.currentPayment.events.find(e => e.id === voidedEventId && e.type === PlatformEventType.PAYMENT_USAGE_VOIDED);
            if(usageEvent) {
              return {
                ...usageEvent,
                cancellationReason: JSON.parse(voidEvent.context).reason
              };
            }
            return null;
          }).filter(event => event !== null);
        return Promise.allSettled([
          payment.serialized ? this.api.getMetadataPayment(payment.serialized) : Promise.resolve(),
          payment.consumeData ? this.api.getMetadataPayment(payment.consumeData as string) : Promise.resolve(),
          ...moveEvents.map(e => {
            const consumeDataUrl = JSON.parse(e.context as string).consumeData;
            if(consumeDataUrl === payment.consumeData) {
              return Promise.resolve();
            }
            return this.api.getMetadataPayment(consumeDataUrl);
          })
        ]).then(results => {
          this.metadata = results[0].status === FULLFILED && results[0].value ? results[0].value : parseMetadata(payment.metadata).metadata || parseMetadata(payment.metadata);
          this.localUsageUntilfromServer = this.request.calculateLocalUntil(this.currentPayment.operationId, this.metadata.PassportType, this.metadata.Nationality);
          this.showComparison = this.foundByScan ? true : (this.scanDocument?.scanDatas?.fields?.documentNumber === this.metadata?.PassportNumber) && (this.scanDocument?.scanDatas?.fields?.nationality === this.metadata?.Nationality);
          if(!this.localUsageUntilfromServer) {
            this.isNoDeclaration = true;
          }
          if(this.metadata) {
            this.currentPayment.serialized = this.metadata;
            this.canBeDeported = this.api.env.nationalityNoDeclaration.includes(this.metadata.Nationality) ||
              this.metadata.PassportType === PassportType.REFUGEE;
          } else if(this.scanDocument && this.scanDocument.scanDatas) {
            const {nationality, issuingState} = this.scanDocument.scanDatas.fields || {};
            this.canBeDeported = this.scanDocument.isZweRefugee(nationality, issuingState);
          }

          const previousConsumeDataUrl = this.currentPayment.consumeData;
          this.currentPayment.consumeData = results[1].status === FULLFILED? results[1].value as ConsumeRequestData : this.currentPayment.consumeData;
          if(moveEvents.length) {
            moveEvents.forEach((event, index) => {
              const context = isJsonParsable(event.context);
              if(context && context.consumeData) {
                if(context.consumeData === previousConsumeDataUrl) {
                  event.consumeData = this.currentPayment.consumeData as ConsumeRequestData;
                } else {
                  event.consumeData = results[index + 2].status === FULLFILED && (results[index + 2] as any).value;
                  if(event.consumeData) {
                    if(event.consumeData.PaymentReference) {
                      this.referencePaymentOnarrival = event.consumeData.PaymentReference;
                    }
                  } else {
                    const internalData = event.internalIndexedData;
                    let operation = null;
                    if(internalData.includes('|IN|')) {
                      operation = OperationType.IN;
                    } else if(internalData.includes('|OUT|')) {
                      operation = OperationType.OUT;
                    }
                    event.consumeData = {
                      Operation: operation
                    };
                  }
                }
              }
            });
          }
          const getPlacePromise = (placeId: string | undefined, cache: {[key: string]: Promise<Place>}) => {
            if(!placeId) return Promise.resolve();
            if(!cache[placeId]) {
              cache[placeId] = this.api.place(placeId);
            }
            return cache[placeId];
          };
          const placeCache: {[key: string]: Promise<Place>} = {};

          return Promise.allSettled([
            getPlacePromise(this.currentPayment.created_place_id, placeCache),
            getPlacePromise(this.currentPayment.consumed_place_id, placeCache),
            ...moveEvents.map(e => getPlacePromise(JSON.parse(e.context as string).place_id, placeCache))
          ]).then(results => {
            const res = results.filter((r: any) => !!r.value);
            if(res.length) {
              this.placeActual = res[0].status === FULLFILED && res[0].value as Place;
              this.placeConsumed = res[1]?.status === FULLFILED && res[1].value as Place;
              moveEvents.forEach((event, index) => {
                event.place = res[index + 2]?.status === FULLFILED && (res[index + 2] as any).value;
              });
            }
          });
        }).catch((e:any) => {
          console.log('error meta', e);
        });
      });
    }).catch(err => this.loader.loading(true, {type: 'error', message: this.lang.transform(err)}).then(() => {
      this.nav.to('admin-request');
    })).finally(() => {
      this.qr.qrOfflineGenerate(this.api.userPlaceId, this.currentPayment.id, this.currentPayment.externalId);
      this.qr.setCurrentQrData(undefined, this.currentPayment);

      this.finishCallPayment = true;
      this.loader.loading(false);
      if(this.printVisaApplication.length === 0) {
        this.preparePrintVisa();
        this.preparePrintPersonalData();
        this.prepareContactDataPrint();
        this.preparePrintDiversVisa();
        this.preparePrintNextOfSkin();
        this.preparePrintExtension();
        this.preparePrintSpouse();
        this.preparePrintReceipt();
        this.preparePrintBorderPass();
        this.prepareVisualDocuments();
        this.preparePrintNotices();
      }
      if(!this.currentPayment.fetchedAt) {
        let paymentToUpdate = {};
        const existingPayment = localStorage.getItem('payment' + true + this.paymentId);
        if(existingPayment) {
          paymentToUpdate = JSON.parse(existingPayment);
          const updatedPayment = {...paymentToUpdate, ...this.currentPayment};
          localStorage.setItem('payment' + true + this.paymentId, JSON.stringify(updatedPayment));
        }
      }
      const warningMessage = this.getWarningMessage();
      if(warningMessage) {
        this.loader.loading(true, {type: 'warn', hideCloseBtn: true, btnLabel: 'payAttention', custom: {innerHtml: warningMessage}}).then(() => {
          this.checkScannedGender();
        });
      } else if(this.blacklist) {
        this.loader.loading(true, {type: 'warn', message: 'visa.blacklist'}).then(() => {
          this.checkScannedGender();
        });
      } else if(this.isZweentryOrdinaryCatC()) {
        this.loader.loading(true, {type: 'warn', custom: {innerHtml: this.lang.transform('visa.catC.ordinary')}}).then(() => {
          this.checkScannedGender();
        });
      } else {
        this.checkScannedGender();
      }
    }).catch(() => {
      if(errorBack) {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('getPayment.error')}).then(() => {
          this.nav.to('admin-request');
        });
      }
    });
  }

  paymentCheckDraft() {
    if(!this.checkDraftInterval) {
      this.checkDraftInterval = setInterval(() => {
        if(!this.isCheckDraft) {
          this.isCheckDraft = true;
          this.api.payment(this.paymentId, false, false, false, false, false).then(payment => {
            this.isCheckDraft = false;
            if(payment.state !== RequestState.PROPOSED) {
              clearInterval(this.checkDraftInterval);
              this.checkDraftInterval = null;
              this.payment();
            }
          }).catch(() => {
            this.isCheckDraft = false;
          });
        }
      }, 1000);
    }
  }

  moveEvents() {
    return this.currentPayment.events.filter(e => (e.type === PlatformEventType.PAYMENT_USED || e.type === PlatformEventType.PAYMENT_USAGE_VOIDED) && e.consumeData?.Operation !== OperationType.REFUSEIN && e.consumeData?.Operation !== OperationType.REFUSEOUT).sort((a, b) => a.updatedOn.localeCompare(b.updatedOn));
  }

  rejectOrValidatePayment(refuse: boolean) {
    const dialogReasonInput = [{
      formCtrlName: 'reason',
      type: 'textarea',
      label: 'payment.reject_reason',
      maxLength: 200,
      validator: refuse ? [Validators.required] : []
    }] as DialogFormInput[];

    if(refuse) {
      this.loader.loading(true, {type: 'warn', message: 'payment.reject_desc', dialogInput: dialogReasonInput}).then((res: any) => {
        if(res?.reason) {
          this.loader.loading(true);
          this.api.validatePayment(this.currentPayment.id, this.api.userPlaceId, refuse, res.reason).then(() => {
            this.nav.to('admin-request');
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    } else {
      this.loader.loading(true, {type: 'info', message: 'payment.validate_desc', btnLabel: 'global.confirm'}).then((done) => {
        if(done) {
          this.loader.loading(true);
          this.api.validatePayment(this.currentPayment.id, this.api.userPlaceId, refuse, '').then(() => {
            this.nav.to('admin-request');
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    }
  }

  private roundPrice(price: number): number {
    if(price < 100) {
      return Math.ceil(price / 5) * 5;
    } else {
      return Math.ceil(price / 10) * 10;
    }
  }

  private getRatesText(customAmount?: number) {
    const serverRates = this.api.userInfo.rates.rates;
    const authorizedCurrencies = this.api.env.supportedCurrencies;
    const availableRates: {currency: string, price: number}[] = Object.keys(serverRates).filter(currency => authorizedCurrencies.includes(currency)).map(currency => (
      {currency, price: this.roundPrice(((customAmount || this.currentPayment.amountCents) / 100) * serverRates[currency])}
    ));
    return '<b></br></br>'+availableRates.map(rate => `<h2>${rate.currency} ${rate.price}</h2>`).join('')+'</b>';
  }

  consumePayment(refuse = false, useFingerprint = false, useNoticePrint = false, supervisor?: string, passSupervision = false, skipCamera = false, skipCameraDialogDone = false, deportedComment?: string) {
    if(skipCamera && !skipCameraDialogDone) {
      const dialogInput = this.deportedMode ? [{
        formCtrlName: 'deportedComment',
        type: 'textarea',
        label: 'deportedComment',
        maxLength: 1000
      }] as DialogFormInput[] : [];
      this.loader.loading(true, {type: 'info', message: 'payment.consume.ask.DYN.' + this.api.env.type, btnLabel: 'global.confirm', dialogInput}).then((done: any) => {
        if(done) {
          this.consumePayment(refuse, useFingerprint, useNoticePrint, supervisor, passSupervision, skipCamera, true, done?.deportedComment);
        }
      });
      return;
    }

    if(!passSupervision && (this.blacklist || (this.checkIfSpecifiedNationals() && this.metadata.PassportType === PassportType.ORDINARY))) {
      let supervisionAction = '';
      if(this.blacklist) supervisionAction = 'supervision.blacklist';
      if(this.checkIfSpecifiedNationals()) supervisionAction = 'supervision.specifiedNationals';
      this.fingerprint.supervision(supervisionAction).then((supervisorAccountName: string) => {
        this.consumePayment(refuse, useFingerprint, useNoticePrint, supervisorAccountName, true);
      });
      return;
    }

    const cleanupSession = () => {
      window.sessionStorage.removeItem('noticePrint');
      window.sessionStorage.removeItem('fingerprint');
    };
    const consumeAction = (fingerprint = false) => {
      const navToFaceCheck = (paymentReference? : string, cashType? : RequestCashType, currency?: string) => {
        cleanupSession();
        this.nav.to(fingerprint ? 'admin-scan-fingerprint' : 'admin-request-face-check', undefined, {queryParams: {
          from: this.fromRoute,
          paymentId: this.currentPayment.id,
          amount: (this.isNextCheckOut && this.isLocalUsageUntilError()) ? this.request.priceOverstay : this.currentPayment.amountCents,
          nameClient: (this.metadata?.FirstName && this.metadata?.LastName) ? (this.metadata?.FirstName + ' ' + this.metadata?.LastName) : '',
          buyDocument: this.currentPayment.operationId,
          userPlaceID: this.api.userPlaceId,
          fromNoticePrint: false,
          placeID: this.currentPayment.created_place_id,
          occupationMedia: this.metadata?.Occupation?.toLowerCase()?.includes('media'),
          provisionalPermit: this.currentPayment.operationId === DocumentType.ZWEPROVRESPERMIT,
          createdAt: this.currentPayment.createdAt,
          operation: this.deportedMode ? OperationType.INRETURNED : (this.isNextCheckOut || this.currentPayment.operationId === DocumentType.ZWEEXIT) ? OperationType.OUT : OperationType.IN,
          paymentReference,
          paymentIsNotDocument: (this.isNextCheckOut && this.isLocalUsageUntilError()) ? 'Yes' : 'No',
          fromScanFingerprint: fingerprint,
          currency,
          cashType,
          skipCamera: skipCamera,
          nationality: this.metadata.Nationality,
          gender: this.metadata.Gender,
          HQReference: this.currentPayment.internalReference,
          vendorName: this.api.userInfo.server.emailConfig.copyrightName,
          vendorAddress: this.api.userInfo.server.emailConfig.address,
          vendorEmail: this.api.userInfo.server.emailConfig.email,
          purposeOfVisit: (this.metadata.Documents && this.metadata.Documents.PurposeOfVisit) ? this.metadata.Documents.PurposeOfVisit : undefined,
          deportedComment: this.deportedMode ? (deportedComment ? deportedComment : skipCamera ? '' : undefined) : undefined,
          foundByScan: this.foundByScan
        }});
      };
      if(environment.type === DocumentType.ZWEVISA) {
        const consume = () => {
          if(refuse) {
            this.loader.loading(true, {type: 'warn', message: 'checkinOut.refuse', btnLabel: 'global.confirm'}).then((done) =>{
              if(done) {
                if((this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKIN ||
                  this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKOUT ||
                  this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECK) && this.currentPayment.operationId !== DocumentType.ZWEEXIT) {
                  this.nav.to('admin-request-notice/' + this.currentPayment.id, undefined, {queryParams: {notice: ConsumePaymentNoticeType.REFUSAL, from: 'admin-request-select/' + this.currentPayment.id,  operation: this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKOUT ? OperationType.REFUSEOUT : OperationType.REFUSEIN, fromRoute: this.fromRoute, ...this.searchParams}});
                } else {
                  return this.cart.editSyncQueue({
                    agentId: this.api.userInfo.id,
                    paymentId: this.paymentId,
                    consumedPlaceId: this.api.userPlaceId,
                    createdPlaceId: this.currentPayment.created_place_id,
                    createdAt: new Date(this.currentPayment.createdAt),
                    consumeMessage: '',
                    consumeData: {Operation: OperationType.REFUSEOUT, PaymentIsNotDocument: 'No', SupervisorAccountName: supervisor || undefined}
                  }).then(() => {
                    this.cart.callSyncQueue();
                    this.isSyncing = true;
                    this.nav.to('dashboard');
                  }).catch(() => {
                    this.loader.loading(true, {type: 'error', message: 'payment.consume.error.DYN.' + this.api.env.type});
                  });
                }
              }
            });
          } else {
            const navToFaceCheck = (paymentReference? : string, cashType?: RequestCashType, currency?: string, localUsageUntil?: Date) => {
              cleanupSession();
              this.nav.to(fingerprint ? 'admin-scan-fingerprint' : 'admin-request-face-check', undefined, {queryParams: {
                from: this.fromRoute,
                paymentId: this.currentPayment.id,
                userPlaceID: this.api.userPlaceId,
                amount: (this.isNextCheckOut && this.isLocalUsageUntilError()) ? this.request.priceOverstay : this.currentPayment.amountCents,
                nameClient: (this.metadata.FirstName && this.metadata.LastName) ? (this.metadata.FirstName + ' ' + this.metadata.LastName) : '',
                placeID: this.currentPayment.created_place_id,
                buyDocument: this.currentPayment.operationId,
                createdAt: this.currentPayment.createdAt,
                occupationMedia: this.metadata?.Occupation?.toLowerCase()?.includes('media'),
                provisionalPermit: this.currentPayment.operationId === DocumentType.ZWEPROVRESPERMIT,
                fromNoticePrint: false,
                borderPass: this.metadata.BorderPass ? this.metadata.BorderPass : undefined,
                fromScanFingerprint: fingerprint,
                operation: this.deportedMode ? OperationType.INRETURNED : (this.isNextCheckOut || this.currentPayment.operationId === DocumentType.ZWEEXIT) ? OperationType.OUT : OperationType.IN,
                paymentReference,
                paymentIsNotDocument: (this.isNextCheckOut && this.isLocalUsageUntilError()) ? 'Yes' : 'No',
                cashType,
                currency,
                localUsageUntil,
                purposeOfVisit: (this.metadata.Documents && this.metadata.Documents.PurposeOfVisit) ? this.metadata.Documents.PurposeOfVisit : undefined,
                skipCamera: skipCamera,
                nationality: this.metadata.Nationality,
                gender: this.metadata.Gender,
                HQReference: this.currentPayment.internalReference,
                vendorName: this.api.userInfo.server.emailConfig.copyrightName,
                vendorAddress: this.api.userInfo.server.emailConfig.address,
                vendorEmail: this.api.userInfo.server.emailConfig.email,
                foundByScan: this.foundByScan,
                deportedComment: this.deportedMode ? (deportedComment ? deportedComment : skipCamera ? '' : undefined) : undefined,
                ...this.searchParams
              }});
            };
            const navToNoticePrint = (paymentReference?: string, cashType?: RequestCashType, currency?: string, notice?: ConsumePaymentNoticeType, localUsageUntil?: Date) => {
              cleanupSession();
              this.nav.to('admin-request-notice/' + this.currentPayment.id, undefined, {queryParams: {
                from: 'admin-request-select/' + this.currentPayment.id,
                fromRoute: this.fromRoute,
                paymentId: this.currentPayment.id,
                userPlaceID: this.api.userPlaceId,
                placeID: this.currentPayment.created_place_id,
                buyDocument: this.currentPayment.operationId,
                createdAt: this.currentPayment.createdAt,
                notice: notice,
                amount: (this.isNextCheckOut && this.isLocalUsageUntilError()) ? this.request.priceOverstay : this.currentPayment.amountCents,
                nameClient: (this.metadata.FirstName && this.metadata?.LastName) ? (this.metadata.FirstName + ' ' + this.metadata.LastName) : '',
                fromScanFingerprint: fingerprint,
                fromNoticePrint: true,
                borderPass: this.metadata.BorderPass ? this.metadata.BorderPass : undefined,
                operation: this.deportedMode ? OperationType.INRETURNED : (this.isNextCheckOut || this.currentPayment.operationId === DocumentType.ZWEEXIT) ? OperationType.OUT : OperationType.IN,
                paymentReference,
                paymentIsNotDocument: (this.isNextCheckOut && this.isLocalUsageUntilError()) ? 'Yes' : 'No',
                cashType,
                currency,
                localUsageUntil,
                purposeOfVisit: (this.metadata.Documents && this.metadata.Documents.PurposeOfVisit) ? this.metadata.Documents.PurposeOfVisit : undefined,
                nationality: this.metadata.Nationality,
                gender: this.metadata.Gender,
                HQReference: this.currentPayment.internalReference,
                vendorName: this.api.userInfo.server.emailConfig.copyrightName,
                vendorAddress: this.api.userInfo.server.emailConfig.address,
                vendorEmail: this.api.userInfo.server.emailConfig.email,
                foundByScan: this.foundByScan,
                ...this.searchParams
              }});
            };
            if(this.currentPayment.state === RequestState.DELAY_PAY) {
              if(this.currentPayment.operationId === DocumentType.ZWEENTRY && !(this.metadata.Documents && this.metadata.Documents.PurposeOfVisit === PurposeOfVisit.TRANSIT_VISA)) {
                if(useFingerprint || (this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECK)) {
                  navToFaceCheck();
                } else {
                  if(this.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                    navToNoticePrint(undefined, undefined, undefined, ConsumePaymentNoticeType.REPORTING);
                  } else {
                    if(useNoticePrint) {
                      navToNoticePrint();
                    } else {
                      navToFaceCheck();
                    }
                  }
                }
              } else if(this.currentPayment.operationId === DocumentType.ZWEPROVRESPERMIT) {
                navToNoticePrint(undefined, undefined, undefined, ConsumePaymentNoticeType.RESTRICTION);
              } else {
                const dialogInput = [
                  {
                    formCtrlName: 'paymentReference',
                    type: 'text',
                    label: 'paymentReference.ask',
                    maxLength: 60,
                    value: uuid.v1()
                  },
                  {
                    formCtrlName: 'currency',
                    type: 'select',
                    label: 'form.currency',
                    selectOptions: this.api.env.supportedCurrencies
                  }
                ] as DialogFormInput[];

                if(this.currentPayment.operationId !== DocumentType.ZWEEXIT) {
                  const cashTypeInput = {
                    formCtrlName: 'cashType',
                    type: 'select',
                    label: 'method.payment',
                    selectOptions: [
                      {name: 'form.type.CASH', value: RequestCashType.CASH},
                      {name: 'form.type.CARD', value: RequestCashType.CARD}
                    ]
                  };

                  const localUsageUntilInput = {
                    formCtrlName: 'localUsageUntil',
                    type: 'date',
                    label: 'usageUntil.ask',
                    value: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.localUsageUntilfromServer), 'yyyy-MM-dd'),
                    date: {
                      dateShowPickerOnClick: true,
                      minDate: format(new Date() > addDays(new Date(this.currentPayment.usageAfter), 1) ? new Date() : addDays(new Date(this.currentPayment.usageAfter), 1), 'yyyy-MM-dd'),
                      maxDate: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.localUsageUntilfromServer), 'yyyy-MM-dd'),
                      autoMinMaxDate: true
                    }
                  };
                  dialogInput.push(cashTypeInput as DialogFormInput);
                  dialogInput.push(localUsageUntilInput as DialogFormInput);
                }
                this.loader.loading(true, {type: 'valid', btnLabel: 'global.confirm', custom: {innerHtml: (this.currentPayment.operationId === DocumentType.ZWEEXIT ? this.lang.transform('ask.isArrivalPaid.exit') : this.lang.transform('ask.isArrivalPaid')) + this.getRatesText(), icon: 'money-bill-wave'}}).then(done => {
                  if(done) {
                    this.loader.loading(true, {type: 'question', message: (this.currentPayment.operationId === DocumentType.ZWEEXIT ? 'ask.isArrivalPaid.confirm.exit' : 'ask.isArrivalPaid.confirm'), btnLabel: 'yes', custom: {closeBtnLabel: 'no'}, dialogInput}).then((res: any) =>{
                      if(res && res.paymentReference && (res.cashType || this.currentPayment.operationId === DocumentType.ZWEEXIT) && res.currency) {
                        if(res.localUsageUntil && (new Date(res.localUsageUntil) < new Date() || new Date(res.localUsageUntil) > addMilliseconds(new Date(), +this.localUsageUntilfromServer))) {
                          return this.loader.loading(true, {type: 'error', message: 'error.bad.localUsageUntil'});
                        }
                        if(useFingerprint || (this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECK && (this.metadata.BorderPass && this.metadata.BorderPass === 'No'))) {
                          navToFaceCheck(res.paymentReference, res.cashType, res.currency, res.localUsageUntil);
                        } else {
                          if(this.currentPayment.operationId === DocumentType.ZWEPROVRESPERMIT) {
                            navToNoticePrint(undefined, undefined, undefined, ConsumePaymentNoticeType.RESTRICTION, res.localUsageUntil);
                          } else if(this.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                            navToNoticePrint(res.paymentReference, res.cashType, res.currency, ConsumePaymentNoticeType.REPORTING, res.localUsageUntil);
                          } else if(this.currentPayment.operationId === DocumentType.ZWEEXIT) {
                            const printBorderPass = () => this.printBorderPass().then(() => {
                              this.loader.loading(true, {type: 'question', message: 'print.borderPass.ask', btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no.retry'}}).then((done: boolean) => {
                                if(done){
                                  navToFaceCheck(res.paymentReference, RequestCashType.CASH, res.currency, res.localUsageUntil);
                                } else {
                                  return printBorderPass();
                                }
                              });
                            });
                            return printBorderPass();
                          } else {
                            if(useNoticePrint) {
                              navToNoticePrint(res.paymentReference, res.cashType, res.currency, undefined, res.localUsageUntil);
                            } else {
                              navToFaceCheck(res.paymentReference, res.cashType, res.currency, res.localUsageUntil);
                            }
                          }
                        }
                      } else {
                        this.loader.loading(true, {type: 'error', message: 'error.payment.noPaymentReference'});
                      }
                    });
                  }
                });
              }
            } else {
              if(this.currentPayment?.chargedBackAt) {
                if(this.isNextCheckOut) {
                  this.loader.loading(true, {type: 'warn', message: 'client.extended.chargedBack'}).then(() => {
                    const dialogInput = [{
                      formCtrlName: 'paymentReferenceChargedBack',
                      type: 'text',
                      label: 'paymentReference.ask',
                      maxLength: 60,
                      value: uuid.v1()
                    }, {
                      formCtrlName: 'currency',
                      type: 'select',
                      label: 'form.currency',
                      selectOptions: this.api.env.supportedCurrencies
                    }] as DialogFormInput[];
                    this.loader.loading(true, {type: 'info', message: 'ask.chargedBack.confirm', btnLabel: 'global.confirm', custom: {closeBtnLabel: 'no'}, dialogInput}).then((res: any) => {
                      if(res?.paymentReferenceChargedBack && res?.currency) {
                        if(useFingerprint || (this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECK)) {
                          navToFaceCheck(res.paymentReferenceChargedBack, RequestCashType.CASH, res.currency);
                        } else {
                          if(this.currentPayment.operationId === DocumentType.ZWEPROVRESPERMIT) {
                            navToNoticePrint(undefined, undefined, undefined, ConsumePaymentNoticeType.RESTRICTION);
                          } else if(this.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                            navToNoticePrint(res.paymentReference, res.cashType, res.currency, ConsumePaymentNoticeType.REPORTING);
                          } else {
                            if(useNoticePrint) {
                              navToNoticePrint(res.paymentReferenceChargedBack, RequestCashType.CASH, res.currency);
                            } else {
                              navToFaceCheck(res.paymentReferenceChargedBack, RequestCashType.CASH, res.currency);
                            }
                          }
                        }
                      } else {
                        this.loader.loading(true, {type: 'error', message: 'error.payment.noReferenceChargedBack'});
                      }
                    });
                  });
                } else {
                  this.loader.loading(true, {type: 'warn', message: 'client.extended.entryChargedBack'});
                }
              } else {
                const leave = (navToNotice = false) => {
                  if(this.isNextCheckOut || this.isNoDeclaration) {
                    navToFaceCheck();
                  } else {
                    const dialogInput = [{
                      formCtrlName: 'localUsageUntil',
                      type: 'date',
                      label: 'usageUntil.ask',
                      value: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.localUsageUntilfromServer), 'yyyy-MM-dd'),
                      date: {
                        dateShowPickerOnClick: true,
                        minDate: format(new Date() > addDays(new Date(this.currentPayment.usageAfter), 1) ? new Date() : addDays(new Date(this.currentPayment.usageAfter), 1), 'yyyy-MM-dd'),
                        maxDate: this.scanDocument.scanDatas?.fields?.expirationDate && isBefore(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), addMilliseconds(new Date(), +this.localUsageUntilfromServer)) ? format(parse(this.scanDocument.scanDatas.fields.expirationDate, 'yyMMdd', new Date()), 'yyyy-MM-dd') : format(addMilliseconds(new Date(), +this.localUsageUntilfromServer), 'yyyy-MM-dd'),
                        autoMinMaxDate: true
                      }
                    }
                    ] as DialogFormInput[];
                    this.loader.loading(true, {type: 'info', message: 'ask.usageUntil.confirm', btnLabel: 'global.confirm', dialogInput}).then((res: any) => {
                      if(res?.localUsageUntil) {
                        if(new Date(res?.localUsageUntil) < new Date() || new Date(res?.localUsageUntil) > addMilliseconds(new Date(), +this.localUsageUntilfromServer)) {
                          return this.loader.loading(true, {type: 'error', message: 'error.bad.localUsageUntil'});
                        } else {
                          navToNotice ? navToNoticePrint(undefined, undefined, undefined, undefined, res.localUsageUntil) :  navToFaceCheck(undefined, undefined, undefined, res.localUsageUntil);
                        }
                      }
                    });
                  }
                };
                if(useFingerprint || (this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECKIN && this.request.getStatusTheme(this.currentPayment).actionState !== ActionState.READY_CHECK)) {
                  leave();
                } else {
                  if(this.currentPayment.operationId === DocumentType.ZWEPROVRESPERMIT) {
                    navToNoticePrint(undefined, undefined, undefined, ConsumePaymentNoticeType.RESTRICTION);
                  } else if(this.metadata?.Occupation?.toLowerCase()?.includes('media')) {
                    navToNoticePrint(undefined, undefined, undefined, ConsumePaymentNoticeType.REPORTING);
                  } else {
                    if(useNoticePrint){
                      if(this.isNoDeclaration) {
                        navToNoticePrint();
                      } else {
                        leave(true);
                      }
                    } else {
                      leave();
                    }
                  }
                }
              }
            }
          }
        };
        if(!this.usageUntilValid) {
          if(this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKIN || this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECK) {
            return this.loader.loading(true, {type: 'warn', custom: {innerHtml: this.lang.transform(this.isCatAB ? 'outUsage.AB' : 'outUsage.C')}});
          }
        }
        if(!this.localUsageUntilValid && (!(this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKIN) && !(this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECK)) && !refuse) {
          const dialogInput = [{
            formCtrlName: 'paymentReference',
            type: 'text',
            label: 'paymentReference.askExpired',
            maxLength: 60,
            value: uuid.v1()
          },
          {
            formCtrlName: 'currency',
            type: 'select',
            label: 'form.currency',
            selectOptions: this.api.env.supportedCurrencies
          }] as DialogFormInput[];
          this.loader.loading(true, {type: 'warn', btnLabel: 'global.confirm', custom: {innerHtml: this.lang.transform('error.paymentUntil.expired') + this.getRatesText(this.request.priceOverstay)}}).then((done) => {
            if(done) {
              this.loader.loading(true, {type: 'info', message: 'ask.checkoutExpired.confirm', btnLabel: 'global.confirm', custom: {closeBtnLabel: 'no'}, dialogInput}).then((res: any) =>{
                if(res?.paymentReference && res?.currency) {
                  navToFaceCheck(res.paymentReference, RequestCashType.CASH, res.currency);
                } else {
                  this.loader.loading(true, {type: 'error', message: 'error.payment.noPaymentReference'});
                }
              });
            }
          });
        } else {
          if(!this.usageAfterValid) {
            this.loader.loading(true, {type: 'warn', message: this.lang.transform('error.paymentUntil.before', {dateAfter: format(this.currentPayment.usageAfter, 'dd/MM/yyyy HH:mm')} )});
          } else {
            consume();
          }
        }
      } else {
        this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.consume.ask.DYN.' + this.api.env.type), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
          if(done) {
            this.loader.loading(true);
            const consumeData: ComsumePaymentData = this.currentPayment.operationId === DocumentType.PREENROLL ? {SupervisorAccountName: supervisor} : undefined;
            this.api.consumePayment(this.currentPayment.id, this.api.userPlaceId, this.currentPayment.created_place_id, new Date(this.currentPayment.createdAt), undefined, consumeData).then(() => {
              this.loader.loading(false);
              this.loader.loading(true, {type: 'valid', message: this.lang.transform('payment.consume.done.DYN.' + this.api.env.type)}).then(() =>{
                this.payment();
              });
            }).catch(err => {
              this.loader.loading(true, {type: 'error', message: this.lang.translateError(err, 'client.extended.uuidUsed', 'err.client.extended.uuidUsed')});
            });
          }
        });
      }
    };

    if(this.api.isElectronApp && useFingerprint) {
      if(electron.addon.hasSystemFingerprint()) {
        consumeAction(true);
      } else {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('fingerprint.notAvailable')}).then(() => {
          consumeAction();
        });
      }
    } else {
      consumeAction();
    }
  }

  goToPreviousUrl() {
    if(this.fromRoute === 'scanQr') {
      this.nav.to('scan-qr');
    } else {
      this.router.navigateByUrl(localStorage.getItem('previous_url'));
    }
  }

  formatDate(date: string) {
    return format(parseISO(date), 'HH:mm dd/MM/yyyy');
  }

  toggleSelectMode(setBool?: boolean) {
    if(setBool !== undefined) {
      this.selectMode = setBool;
    } else {
      this.selectMode = !this.selectMode;
    }
    if(this.selectMode) {
      this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.selectMode')});
    } else {
      this.selectedFields = [];
    }
  }

  suspendPayment() {
    if(!this.selectedFields.length) {
      this.toggleSelectMode(true);
      return;
    }
    const dialogInput = [{
      formCtrlName: 'refusalReason',
      type: 'textarea',
      label: 'refusal.desc.DYN.' + this.api.env.type,
      maxLength: 200
    }] as DialogFormInput[];

    this.loader.loading(true, {type: 'warn', message: 'suspendReason.desc', dialogInput: dialogInput}).then((res: any) => {
      if(res?.refusalReason) {
        return this.api.suspendPayment(this.currentPayment?.id, true, res.refusalReason, this.selectedFields, this.api.userPlaceId).then(() => {
          this.toggleSelectMode(false);
          this.nav.to('admin-request');
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: ('error.payment.suspend.error.DYN.' + this.api.env.type)});
        });
      } else {
        this.loader.loading(true, {type: 'error', message: 'error.payment.noSuspendReason'});
      }
    });
  }

  validateOrRefusePayment(refuse = false, review = false) {
    if(this.api.env.type === DocumentType.PASS) {
      return this.rejectOrValidatePayment(refuse);
    }
    this.toggleSelectMode(false);
    const dialogInput: DialogFormInput[] = [
      {
        formCtrlName: 'refusalReason',
        type: 'textarea',
        label: (review ?
          (refuse ?
            (this.isExtension ? 'review.refusal.desc.extension.DYN.' : 'review.refusal.desc.DYN.')
            : (this.isExtension ? 'review.approval.desc.extension.DYN.' : 'review.approval.desc.DYN.'))
          :  refuse ?
            (this.isExtension ? 'refusal.desc.extension.DYN.' : 'refusal.desc.DYN.')
            : (this.isExtension ? 'approval.desc.extension.DYN.' : 'approval.desc.DYN.')) + this.api.env.type,
        maxLength: 200,
        validator: refuse ? [Validators.required] : []
      }
    ];

    if(!this.isExtension && !review && !refuse) {
      dialogInput.push({
        formCtrlName: 'usageUntil',
        type: 'date',
        label: 'approval.usageUntil.DYN.' + this.api.env.type,
        value: format(new Date(this.currentPayment.usageUntil), 'yyyy-MM-dd'),
        date: {
          dateShowPickerOnClick: true,
          minDate: format(addDays(new Date(this.currentPayment.usageAfter), 1), 'yyyy-MM-dd'),
          maxDate: format(new Date(this.currentPayment.usageUntil), 'yyyy-MM-dd'),
          autoMinMaxDate: true
        }
      });
    }
    const loadingMessage = review ? (refuse ? 'refusalReason.review.desc.DYN.' : 'approveReason.review.desc.DYN.') : refuse ? (this.isExtension ? 'refusalReason.desc.extension.DYN.' : 'refusalReason.desc.DYN.') : (this.isExtension ? 'payment.validate.extension.ask.DYN.' : 'payment.validate.ask.DYN.');
    this.loader.loading(true, {type: 'info', dialogInput: dialogInput, custom: {innerHtml: this.lang.transform(loadingMessage + this.api.env.type)}}).then((res: any) => {
      if(new Date(res?.usageUntil) < new Date(addDays(this.currentPayment.usageAfter, 1))) {
        return this.loader.loading(true, {type: 'error', message: 'client.extended.minUsageNotMet'});
      }
      if((refuse && res?.refusalReason) || !refuse) {
        const confirmationMessage = refuse
          ? (this.isExtension ? 'payment.review.refuse.ask.extension.DYN.' : 'payment.review.refuse.ask.DYN.')
          : (this.isExtension ? 'payment.review.approve.extension.ask.DYN.' : 'payment.review.approve.ask.DYN.');

        if(review) {
          this.loader.loading(true, {
            type: 'info',
            custom: {innerHtml: this.lang.transform(confirmationMessage + this.api.env.type)},
            btnLabel: this.lang.transform('global.confirm')
          }).then((done) => {
            if(done) {
              this.validatePaymentAndNavigate(this.currentPayment?.id, this.api.userPlaceId, refuse, res.refusalReason, res?.usageUntil);
            }
          });
        } else {
          this.validatePaymentAndNavigate(this.currentPayment?.id, this.api.userPlaceId, refuse, res.refusalReason, res?.usageUntil);
        }
      } else {
        this.loader.loading(true, {type: 'error', custom: {innerHtml: this.lang.transform('error.payment.noRefuseReason')}});
      }
    });
  }

  validatePaymentAndNavigate(paymentId: string, userPlaceId: string, refuse: boolean, refusalReason: string, usageUntil?: Date) {
    this.loader.loading(true);
    this.api.validatePayment(paymentId,
      userPlaceId,
      refuse,
      refusalReason,
      usageUntil ? new Date(usageUntil) : undefined).then(() => {
      this.nav.to('admin-request');
    }).catch((err) => {
      this.loader.loading(true, {
        type: 'error',
        custom: {innerHtml: this.lang.transform(err || ('error.approveRefuse.DYN.' + this.api.env.type))}
      });
    });
  }

  visualFile(file?:string, name?: string) {
    this.showDocuments = file ? [{nameDocument: name, document: file, title: name}] : this.metadataDocuments;
    this.visualizerIndex = name;
    if(this.showDocuments.length) this.openDialogDocViewer();
  }

  openDialogDocViewer() {
    this.dialogs.openDialog(DialogDocViewerComponent, 'full', {
      documentsInput: this.showDocuments,
      beginIndex: this.visualizerIndex
    }, false, undefined, true).then(() => {
      this.showDocuments = [];
      this.visualizerIndex = '';
    });
  }

  duplicatePayment() {
    if(this.currentPayment?.state === 1) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('payment.duplicate.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
        if(done) {
          this.loader.loading(true);
          this.api.duplicatePayment(this.currentPayment.id, this.api.userPlaceId).then((res: any) => {
            this.loader.loading(false);
            this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.duplicate.done'), btnLabel: this.lang.transform('payment.duplicate.open')}).then((done) => {
              if(done) {
                this.nav.to('admin-request-select/'+res.result);
              } else {
                this.payment();
              }
            });
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    } else {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('payment.paid.error')});
    }
  }

  refundPayment() {
    if(this.currentPayment?.state === 1) {
      if(!this.currentPayment.refunded_place_id) {
        if(this.api.userRole.isCustomer) {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('payment.refund.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
            if(done) {
              this.loader.loading(true);
              this.api.selfRefundPayment(this.currentPayment.id).then(() => {
                this.loader.loading(false);
                this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.refund.done')}).then(() =>{
                  this.payment();
                });
              }).catch((err) => {
                this.loader.loading(true, {type: 'error', message: err});
              });
            }
          });
        } else {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('payment.refund.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done) =>{
            if(done) {
              this.loader.loading(true);
              this.api.refundPayment(this.currentPayment.id, this.api.userPlaceId).then(() => {
                this.loader.loading(false);
                this.loader.loading(true, {type: 'info', message: this.lang.transform('payment.refund.done')}).then(() =>{
                  this.nav.to('admin-request');
                });
              }).catch((err) => {
                this.loader.loading(true, {type: 'error', message: err});
              });
            }
          });
        }
      } else {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('payment.refund.exist')});
      }
    } else {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('payment.paid.error')});
    }
  }

  goToDuplicata() {
    this.nav.to('admin-request-select/' + this.currentPayment?.duplicatas[0].id);
    setTimeout(() => {
      this.payment();
    }, 300);
  }

  getAmount(currency: keyof typeof CurrencySign, ammount: number, millis = true) : string{
    return CurrencySign[currency] + (millis ? ammount / 100 : ammount);
  }

  getPaymentType(currency: keyof typeof RequestCashType) : string{
    return RequestCashType[currency];
  }

  setForm() {
    this.form = this.formBuilder.group({
      deportedMode: ['', []]
    });
  }
  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
  protected readonly VisaFN = VisaFN;

  checkSelect($event: Event, control: VisaFN, noPointer?: boolean) {
    noPointer && $event.preventDefault();
    $event.stopPropagation();
    this.selectedFields = ($event.target as any).checked ? [...this.selectedFields, control] : this.selectedFields.filter(c => c !== control);
  }

  canBeValidated(approveRefuseButtons= false) {
    const eventOfPayment = this.currentPayment.events.length;
    if(approveRefuseButtons) {
      if(this.api.hasPerm(UserPermission.ALLOW_ADMIN)) {
        if(this.currentPayment.state === RequestState.DELAY_PAY || this.currentPayment.state === RequestState.PAID) {
          return true;
        }
        return eventOfPayment > 2;
      } else {
        return false;
      }
    } else {
      if(this.api.hasPerm(UserPermission.ALLOW_ADMIN)) {
        return this.actualManualConfirmRounds > eventOfPayment && !approveRefuseButtons;
      } else {
        if(this.api.hasPerm(UserPermission.ALLOW_CONTROL)) {
          if(this.currentPayment.events.find(payment => payment.actor_id === this.api.userInfo.id)) {
            return false;
          } else {
            return this.actualManualConfirmRounds > eventOfPayment;
          }
        } else {
          return false;
        }
      }
    }
  }

  reviewPayment() {
    if(!this.currentPayment?.internalReference) {
      this.loader.loading(true, {type: 'warn', message: 'error.payment.noInternalReference'});
      return;
    }

    const dialogInput = [{
      formCtrlName: 'approveRefuse',
      type: 'select',
      label: 'recommendOrNot',
      selectOptions: [{
        name: 'recommended',
        value: 0
      },
      {
        name: 'not_recommended',
        value: 1
      }]
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'question', custom: {innerHtml: this.lang.transform((this.isExtension ? 'ask.review.extension.' : 'ask.review.') + this.currentPayment.subState)}, buttonType: 'button', dialogInput: dialogInput}).then((res: any) => {
      if(res) {
        if(!res?.approveRefuse) {
          this.loader.loading(true, {type: 'error', message: 'error.review.noChoice'});
        } else {
          if(+res?.approveRefuse === 0) {
            this.validateOrRefusePayment(undefined, true);
          } else if(+res?.approveRefuse === 1) {
            this.validateOrRefusePayment(true, true);
          }
        }
      }
    });
  }

  JSONparse(data: string) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return false;
    }
  }

  isEvent() {
    return this.currentPayment?.events.some(event => event.type === PlatformEventType.PAYMENT_VALIDATED ||
      event.type === PlatformEventType.PAYMENT_REFUSED ||
      (event.type === PlatformEventType.PAYMENT_UPDATED && isJsonParsable(event.context)?.newOperation) ||
      (event.type === PlatformEventType.PAYMENT_UPDATED && !event.place_id) ||
      event.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE);
  }

  isSupervisionEvent() {
    return this.currentPayment?.events.some(event => event.type === PlatformEventType.PAYMENT_USED && event.consumeData?.Operation);
  }

  printA4() {
    const printSummary = () => {
      this.loader.loading(true);
      this.printCurrent = 'application';
      setTimeout(() => {
        this.loader.loading(false);
        setTimeout(() => {
          window.print();
          this.printCurrent = '';
        }, 1000);
      }, 1000);
    };

    this.loader.loading(true, {
      type: 'info',
      message: 'print.askMultiple',
      btnLabel: 'yes',
      custom: {icon: 'print', closeBtnLabel: 'no'}
    }).then((done: boolean) => {
      if(done) {
        this.loader.loading(true);
        mergeDocuments(this.metadataDocuments)
          .then((mergedPdfBytes) => {
            const blob = new Blob([mergedPdfBytes], {type: 'application/pdf'});
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
            printSummary();
            this.loader.loading(false);
          })
          .catch(() => {
            this.loader.loading(false);
          });
      } else {
        printSummary();
      }
    });
  }

  printApprovalLetter() {
    this.approvalLetterData = {
      name: this.metadata?.FirstName + ' ' + this.metadata?.LastName,
      documentType: this.lang.transform(this.currentPayment.operationId).toUpperCase(),
      address: this.metadata?.AddressZimbabwe,
      hqRef: this.currentPayment.internalReference,
      date: format(new Date(), 'dd-MM-yyyy'),
      applicationTrackingCode: this.currentPayment.batchId,
      paymentRef: this.currentPayment.externalId?.toUpperCase(),
      vendorName: this.api.userInfo.server.emailConfig.copyrightName,
      vendorAddress: this.api.userInfo.server.emailConfig.address,
      vendorEmail: this.api.userInfo.server.emailConfig.email,
      placePhone: this.currentPlace?.phoneNumbersCsv || ''
    };
    this.loader.loading(true);
    this.printCurrent = 'approvalLetter';
    setTimeout(() => {
      this.loader.loading(false);
      setTimeout(() => {
        this.loader.loading(false);
        window.print();
        this.printCurrent = '';
      }, 1000);
    }, 1000);
  }

  isObjectEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  getReviewNr(event: PlatformEvent) {
    let events = this.currentPayment?.events.filter(e => e.type === PlatformEventType.PAYMENT_VALIDATED || e.type === PlatformEventType.PAYMENT_REFUSED);
    if(events && event.type === PlatformEventType.PAYMENT_VALIDATED || event.type === PlatformEventType.PAYMENT_REFUSED) {
      events.sort((a, b) => a.updatedOn.localeCompare(b.updatedOn));
      if(events.length > 2) {
        if(event.id === events[events.length - 1].id) {
          return this.lang.transform('review.nr.final') + ': ';
        } else {
          events = events.slice(0, 2);
          return events.indexOf(event) > -1 ? this.lang.transform('review.nr') + (events.indexOf(event) + 1)+ ': ' : '';
        }
      } else {
        return this.lang.transform('review.nr') + (events.indexOf(event) + 1)+ ': ';
      }
    }
  }
  goBack() {
    if(this.fromRoute !== 'admin-request' && !this.fromRoute?.includes('admin-request-select')) {
      this.fromRoute ? this.nav.to(this.fromRoute) : this.nav.to('admin-request');
    } else {
      this.nav.to('admin-request', undefined, {queryParams: this.searchParams});
    }

  }

  canBeReviewed() {
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL) && (this.currentPayment.state === RequestState.PAID_NOT_CONFIRMED || this.currentPayment.state === RequestState.DELAY_PAY_NOT_CONFIRMED)) {
      if(this.currentPayment.operationId === DocumentType.ZWEVISAEXTAB) {
        return false;
      }
      if(this.currentPayment.events.find(payment => payment.actor_id === this.api.userInfo.id && (payment.type === PlatformEventType.PAYMENT_VALIDATED || payment.type === PlatformEventType.PAYMENT_REFUSED))) {
        return false;
      } else {
        const subState = this.currentPayment.subState;
        if(subState === RequestSubState.REVISIONS_0 && (this.seniorityLevel === 1 || this.seniorityLevel === 3)) {
          return true;
        } else if((subState === RequestSubState.REVISIONS_1 && this.currentPayment.operationId !== DocumentType.ZWEVISAEXTC) && (this.seniorityLevel === 2 || this.seniorityLevel === 3)) {
          return true;
        }
        return false;
      }
    }
  }

  canBeAcceptedRefused(refuse = false) {
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL) && this.seniorityLevel === 3 && !this.selectMode) {
      const hasAlreadyDoneAction = this.currentPayment.events.find(payment => payment.actor_id === this.api.userInfo.id && (payment.type === PlatformEventType.PAYMENT_VALIDATED || payment.type === PlatformEventType.PAYMENT_REFUSED || payment.type === PlatformEventType.PAYMENT_CREATED));
      if(this.currentPayment.state === RequestState.PAID && this.currentPayment.operationId !== DocumentType.ZWEEXIT) {
        return refuse ? !hasAlreadyDoneAction : false;
      } else if(this.currentPayment.state === RequestState.EXPIRED) {
        return refuse ? false : !hasAlreadyDoneAction;
      } else {
        if(this.currentPayment.state === RequestState.PAID_NOT_CONFIRMED || this.currentPayment.state === RequestState.DELAY_PAY_NOT_CONFIRMED) {
          if(this.currentPayment.operationId === DocumentType.ZWEVISAEXTAB || (this.currentPayment.operationId === DocumentType.ZWEVISAEXTC && this.currentPayment.subState === RequestSubState.REVISIONS_1)) {
            return true;
          }
          if(hasAlreadyDoneAction) {
            return false;
          }
          const subState = this.currentPayment.subState;
          return subState === RequestSubState.REVISIONS_2;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  canBeSuspended() {
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL)) {
      if(this.currentPayment.state === RequestState.PAID_NOT_CONFIRMED || this.currentPayment.state === RequestState.DELAY_PAY_NOT_CONFIRMED) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canBeConsumed(refused = false) {
    if(this.currentPayment.subState === RequestSubState.CLOSED){
      return false;
    }
    if(this.isSyncing) {
      return false;
    }
    if(this.currentPayment.operationId === DocumentType.ZWEVISAEXTAB  || this.currentPayment.operationId === DocumentType.ZWEVISAEXTC ) {
      return false;
    }
    const hasPermissionAndValidState = this.api.hasPerm(UserPermission.ALLOW_CONSUME) &&
      (this.currentPayment.state === RequestState.DELAY_PAY || this.currentPayment.state === RequestState.PAID ||
        this.currentPayment?.state === RequestState.USED);
    if(this.currentPayment.subState === RequestSubState.REFUSED_IN || (this.currentPayment.subState === RequestSubState.REFUSED_OUT && refused)) {
      return false;
    }
    const isValidUsageCount = this.currentPayment?.usageCount <= this.document?.usageCount;
    const isNotExceedUsage = !(this.currentPayment?.usageCount >= this.document?.usageCount && this.isUsage);
    if(hasPermissionAndValidState && isValidUsageCount && isNotExceedUsage) {
      if(this.currentPayment.subState === RequestSubState.CHECKED_IN  && !this.document.multiUsage) {
        return false;
      }
      const eventOfPayment = this.currentPayment.events.length;
      return this.actualManualConfirmRounds <= eventOfPayment;
    }
    return false;
  }

  actionPrint(title: string, image: string) {
    this.printDocument.title = this.lang.transform(title);
    this.printDocument.date = format(new Date(), 'dd/MM/yyyy HH:mm');
    this.printDocument.id = this.currentPayment.id;
    this.printDocument.img = image;
    setTimeout(() => {
      window.print();
    }, 300);
    window.onafterprint = () => {
      this.printDocument.title = '';
      this.printDocument.img = '';
      this.printDocument.date = '';
      this.printDocument.id = '';
    };
  }

  canInternalReference() {
    return this.api.userRole.isAdmin || (this.api.userRole.isWorker && this.seniorityLevel === 3 && !this.selectMode && (this.currentPayment.state === RequestState.PAID_NOT_CONFIRMED || this.currentPayment.state === RequestState.DELAY_PAY_NOT_CONFIRMED || this.currentPayment.state === RequestState.PAID || this.currentPayment.state === RequestState.PENDING || this.currentPayment.state === RequestState.DELAY_PAY)) && this.currentPayment.state !== RequestState.PENDING;
  }

  preparePrintVisa() {
    const data: any = [];
    if(this.currentPayment?.operationId) {
      data.push([this.lang.transform('document.type.DYN.zwevisa'), this.lang.transform(this.currentPayment.operationId)]);
    }
    if(this.currentPayment?.usageAfter) {
      data.push([this.lang.transform('form.usageAfter'), format(this.currentPayment.usageAfter, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.currentPayment?.localUsageUntil) {
      data.push([this.lang.transform('form.localUsageUntil'), format(this.currentPayment.localUsageUntil, 'dd/MM/yyyy')]);
    }
    if(this.currentPayment?.amountCents) {
      data.push([this.lang.transform('form.amount'), this.getAmount(this.currentPayment.currency as any, this.currentPayment.amountCents)]);
    }
    if(this.currentPayment?.amountCents) {
      data.push([this.lang.transform('form.paymentType'), this.lang.transform(this.currentPayment.externalId ? 'payment.type.online' : 'payment.type.offline')]);
    }
    if(this.currentPayment?.state || this.currentPayment?.state === 0) {
      data.push([this.lang.transform('request.state.DYN.' + this.api.env.type), this.request.getStatusTheme(this.currentPayment).state]);
    }
    if(this.currentPayment?.batchId) {
      data.push([this.lang.transform('BatchId'), this.currentPayment.batchId]);
    }
    if(this.referencePaymentOnarrival) {
      data.push([this.lang.transform('paymentReference.ask'), this.referencePaymentOnarrival]);
    }

    if(this.metadata?.Urgent) {
      data.push([this.lang.transform('global.urgent'), this.metadata?.Urgent]);
    }
    if(this.metadata?.buyingOption && this.metadata?.buyingOption !== '{}' && this.metadata?.buyingOption !== 'Embassy fee') {
      data.push(['Buying Option', this.lang.transform('document.' + this.metadata.buyingOption)]);
    }
    if(this.metadata?.Documents?.PurposeOfVisit) {
      data.push([this.lang.transform('form.purposeOfVisit'), this.lang.transform('DYN.' + this.metadata.Documents.PurposeOfVisit)]);
    }
    const generalDataGroup: DataGroup = {
      subtitle: this.lang.transform('application.data'),
      data: data
    };
    if(data.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, generalDataGroup];
    }
  }

  preparePrintPersonalData() {
    const personalDataPrint: any = [];
    if(this.metadata?.FirstName || this.metadata?.firstName) {
      personalDataPrint.push([this.lang.transform('form.firstName'), this.metadata.FirstName || this.metadata.firstName]);
    }
    if(this.metadata?.LastName || this.metadata.lastName) {
      personalDataPrint.push([this.lang.transform('form.lastName'), this.metadata.LastName || this.metadata.lastName]);
    }
    if(this.metadata?.BirthDay) {
      personalDataPrint.push([this.lang.transform('form.birthDate'), format(this.metadata.BirthDay, 'dd/MM/yyyy')]);
    }
    if(this.metadata?.PassportNumber) {
      personalDataPrint.push([this.lang.transform('form.passportNumber'), this.metadata.PassportNumber]);
    }
    if(this.metadata?.DateOfIssue) {
      personalDataPrint.push([this.lang.transform('form.passportNumber.dateOfIssue'), format(this.metadata.DateOfIssue, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.metadata?.DateOfExpiry) {
      personalDataPrint.push([this.lang.transform('form.passportNumber.dateOfExpiry'), format(this.metadata.DateOfExpiry, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.metadata?.PlaceofIssue) {
      personalDataPrint.push([this.lang.transform('form.passportNumber.placeOfIssue'), this.metadata.PlaceofIssue]);
    }
    if(this.metadata?.PlaceOfBirth) {
      personalDataPrint.push([this.lang.transform('form.placeOfBirth'), this.metadata.PlaceOfBirth]);
    }
    if(this.metadata?.Gender) {
      personalDataPrint.push([this.lang.transform('form.gender'), this.lang.transform('applicant.gender.' + this.metadata.Gender)]);
    }
    if(this.metadata?.MaritalStatus) {
      personalDataPrint.push([this.lang.transform('form.marialStatus'), this.lang.transform('marital.status.' + this.metadata.MaritalStatus)]);
    }
    if(this.metadata?.Occupation) {
      personalDataPrint.push([this.lang.transform('form.profession'), this.metadata.Occupation]);
    }
    if(this.metadata?.Nationality) {
      personalDataPrint.push([this.lang.transform('form.nationality'), this.lang.transform('DYN.' + this.metadata.Nationality)]);
    }
    if(this.metadata?.idCard) {
      personalDataPrint.push([this.lang.transform('idcard.number'), this.metadata.idCard]);
    }

    const personalDataGroup: DataGroup = {
      subtitle: this.lang.transform('personal.data'),
      data: personalDataPrint
    };

    if(personalDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, personalDataGroup];
    }
  }


  prepareContactDataPrint() {
    const contactDataPrint: any = [];
    if(this.metadata?.Email || this.metadata?.email) {
      contactDataPrint.push([this.lang.transform('form.email'), this.metadata.Email || this.metadata.email]);
    }
    if(this.metadata?.Phone || this.metadata?.phone) {
      contactDataPrint.push([this.lang.transform('form.phone'), this.metadata.Phone || this.metadata.phone]);
    }
    if(this.metadata?.AddressZimbabwe) {
      contactDataPrint.push([this.lang.transform('form.addressZimbabwe'), this.metadata.AddressZimbabwe]);
    }
    if(this.metadata?.HomeAddress) {
      contactDataPrint.push([this.lang.transform('form.residenceAdress'), this.metadata.HomeAddress]);
    }
    if(this.metadata?.HostName) {
      contactDataPrint.push([this.lang.transform('form.hostName'), this.metadata.HostName]);
    }
    const contactDataGroup: DataGroup = {
      subtitle: this.lang.transform('contact.data'),
      data: contactDataPrint
    };
    if(contactDataPrint.length > 0) {
      this.printVisaApplication= [...this.printVisaApplication, contactDataGroup];
    }

  }

  preparePrintDiversVisa() {
    const diverseDataPrint: any = [];
    if(this.metadata?.ArrivalDate) {
      diverseDataPrint.push([this.lang.transform('form.arrivalDate'), format(this.metadata.ArrivalDate, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.metadata?.DepartureDate) {
      diverseDataPrint.push([this.lang.transform('form.departureDate'), format(this.metadata.DepartureDate, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.metadata?.ReferencePreviousVisa) {
      diverseDataPrint.push([this.lang.transform('form.referencePreviousVisa'), this.metadata.ReferencePreviousVisa]);
    }
    if(this.metadata?.PreviousConvictionsZim) {
      diverseDataPrint.push([this.lang.transform('form.previousConvictionsZim'), this.metadata.PreviousConvictionsZim]);
    }
    if(this.metadata?.PreviousConvictionsHome) {
      diverseDataPrint.push([this.lang.transform('form.previousConvictionsHome'), this.metadata.PreviousConvictionsHome]);
    }
    if(this.metadata?.PurposeOfVisit) {
      diverseDataPrint.push([this.lang.transform('form.purposeOfVisit'), this.metadata.PurposeOfVisit]);
    }
    if(this.currentPayment?.createdAt) {
      diverseDataPrint.push([this.lang.transform('date.creation'), format(this.currentPayment.createdAt, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.currentPayment?.externalId) {
      diverseDataPrint.push([this.lang.transform('form.externalId'), this.currentPayment.externalId]);
    }
    if(this.placeActual) {
      diverseDataPrint.push([this.lang.transform('form.created_place'), this.placeActual.longName + ' [' + this.placeActual.id + ']']);
    }
    if(this.placeConsumed) {
      diverseDataPrint.push([this.lang.transform('form.consumed_place'), this.placeConsumed.longName]);
    }
    if(this.metadata?.worker) {
      diverseDataPrint.push([this.lang.transform('form.userCreator'), this.sync.userDisplay(this.metadata.worker) +' #'+this.metadata.worker]);
    }
    if(this.currentPayment?.refunded_by_user__id) {
      diverseDataPrint.push([this.lang.transform('form.userRefund'), this.sync.userDisplay(this.currentPayment.refunded_by_user__id) +' #'+this.currentPayment.refunded_by_user__id]);
    }
    if(this.currentPayment?.used_by_user__id) {
      diverseDataPrint.push([this.lang.transform('form.userConsumed'), this.sync.userDisplay(this.currentPayment.used_by_user__id) +' #'+this.currentPayment.used_by_user__id]);
    }
    if(this.metadata?.FundsAvailable && this.metadata?.FundsAvailableCurrency) {
      const funds = this.metadata.FundsAvailable + ' ' + this.metadata.FundsAvailableCurrency + (this.metadata.FundsAvailableCurrency !== 'USD' ? (' ' + '(' + this.request.convertCurrency(this.metadata?.FundsAvailableCurrency, this.metadata?.FundsAvailable, 'USD').amount + ' ' + this.request.convertCurrency(this.metadata.FundsAvailableCurrency, this.metadata.FundsAvailable, 'USD').currency +')') : '');
      diverseDataPrint.push([this.lang.transform('FundsAvailable'), funds]);
    }
    const diverseDataGroup = {
      subtitle: this.lang.transform('divers.data.DYN.' + this.api.env.type),
      data: diverseDataPrint
    };
    if(diverseDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, diverseDataGroup];
    }
  }

  preparePrintNextOfSkin() {
    if(this.metadata.nextOfKin) {
      const nextOfKinData: any = [];
      const nextOfKin = this.JSONparse(this.metadata.NextOfKin);
      if(nextOfKin?.firstname) {
        nextOfKinData.push([this.lang.transform('form.firstName'), nextOfKin.firstname]);
      }
      if(nextOfKin?.lastname) {
        nextOfKinData.push([this.lang.transform('form.lastName'), nextOfKin.lastname]);
      }
      if(nextOfKin?.phone) {
        nextOfKinData.push([this.lang.transform('form.phone'), nextOfKin.phone]);
      }
      if(nextOfKin?.email) {
        nextOfKinData.push([this.lang.transform('form.email'), nextOfKin.email]);
      }
      const nextOfKinGroup = {
        subtitle: this.lang.transform('nextOfKin.data'),
        data: nextOfKinData
      };
      if(nextOfKinData.length > 0) {
        this.printVisaApplication = [...this.printVisaApplication, nextOfKinGroup];
      }
    }
  }

  preparePrintSpouse() {
    const spouseDataPrint: any = [];
    if(this.metadata?.SpouseFullName) {
      spouseDataPrint.push([this.lang.transform('form.spouseFullName'), this.metadata.SpouseFullName]);
    }
    if(this.metadata?.SpousePassportNumber) {
      spouseDataPrint.push([this.lang.transform('form.spousePassportNumber'), this.metadata.SpousePassportNumber]);
    }
    if(this.metadata?.SpouseBirthDay) {
      spouseDataPrint.push([this.lang.transform('form.spouse.birthDay'), format(this.metadata.SpouseBirthDay, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.metadata?.SpousePlaceOfBirth) {
      spouseDataPrint.push([this.lang.transform('form.spousePlaceOfBirth'), this.metadata.SpousePlaceOfBirth]);
    }
    const spouseDataGroup = {
      subtitle: this.lang.transform('spouse.data'),
      data: spouseDataPrint
    };
    if(spouseDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, spouseDataGroup];
    }
  }

  preparePrintExtension() {
    const extensionDataPrint: any = [];
    if(this.metadata?.ExtensionReason) {
      extensionDataPrint.push([this.lang.transform('form.extensionReason'), this.metadata.ExtensionReason]);
    }
    if(this.metadata?.ExtensionDate) {
      extensionDataPrint.push([this.lang.transform('form.extensionDate'), format(this.metadata.ExtensionDate, 'dd/MM/yyyy HH:mm')]);
    }
    if(this.metadata?.AttestantAddress) {
      extensionDataPrint.push([this.lang.transform('attestantAddress'), this.metadata.AttestantAddress]);
    }
    if(this.metadata?.ReasonForExtension) {
      extensionDataPrint.push([this.lang.transform('reasonForExtention'), this.metadata.ReasonForExtension]);
    }
    if(this.metadata?.AttestantEmail) {
      extensionDataPrint.push([this.lang.transform('attestantEmail'), this.metadata.AttestantEmail]);
    }
    if(this.metadata?.AttestantID) {
      extensionDataPrint.push([this.lang.transform('attestantID'), this.metadata.AttestantID]);
    }
    if(this.metadata?.AttestantName) {
      extensionDataPrint.push([this.lang.transform('attestantName'), this.metadata.AttestantName]);
    }
    if(this.metadata?.AttestantPhone) {
      extensionDataPrint.push([this.lang.transform('attestantPhone'), this.metadata.AttestantPhone]);
    }
    if(this.metadata?.AttestantRelation) {
      extensionDataPrint.push([this.lang.transform('attestantRelation'), this.metadata.AttestantRelation]);
    }
    if(this.metadata?.DaysRequested) {
      extensionDataPrint.push([this.lang.transform('daysRequested'), this.metadata.DaysRequested]);
    }

    const extensionDataGroup = {
      subtitle: this.lang.transform('extension.data'),
      data: extensionDataPrint
    };
    if(extensionDataPrint.length > 0) {
      this.printVisaApplication = [...this.printVisaApplication, extensionDataGroup];
    }
  }

  printSticker() {
    this.stickerData = {
      name: (this.metadata?.FirstName + ' ' + this.metadata?.LastName).toUpperCase(),
      passportNumber: this.metadata?.PassportNumber?.toUpperCase(),
      dateOfEntry: this.currentPayment.usageAfter ? format(new Date(this.currentPayment.usageAfter), 'dd MMM yyyy', {locale: enGB}).toUpperCase() : '',
      status: this.lang.transform('request.state.DYN.' + this.api.env.type).toUpperCase(),
      until: this.currentPayment.localUsageUntil ? format(new Date(this.currentPayment.localUsageUntil), 'dd MMM yyyy', {locale: enGB}).toUpperCase() : '',
      place: this.placeActual.longName.toUpperCase()
    };

    this.loader.loading(true);
    this.printCurrent = 'sticker';
    setTimeout(() => {
      this.loader.loading(false);
      setTimeout(() => {
        this.loader.loading(false);
        window.print();
        this.printCurrent = '';
      }, 200);
    }, 1000);
  }

  isSameData(scannedData: string, visaData: string): boolean {
    const scannedDate = new Date(scannedData);
    const visaDate = new Date(visaData);
    if(!isNaN(scannedDate.getTime()) && !isNaN(visaDate.getTime())) {
      return scannedDate.getTime() === visaDate.getTime();
    } else {
      return scannedData.toLowerCase() === visaData.toLowerCase();
    }
  }

  hasDifferences() {
    if(!this.scanDocument?.scanDatas || !this.metadata) {
      return false;
    }
    const scanFields = this.scanDocument.scanDatas.fields;
    const meta = this.metadata;
    const fieldsToCompare = [
      {scan: scanFields.firstName, meta: meta.FirstName || meta.firstName},
      {scan: scanFields.lastName, meta: meta.LastName || meta.lastName},
      {scan: scanFields.sex, meta: meta.Gender},
      {scan: format(convertToEpoch(scanFields.birthDate, false, true), 'dd/MM/yyyy'), meta: meta.BirthDay && format(meta.BirthDay, 'dd/MM/yyyy')},
      {scan: format(convertToEpoch(scanFields.expirationDate), 'dd/MM/yyyy'), meta: meta.DateOfExpiry && format(meta.DateOfExpiry, 'dd/MM/yyyy')},
      {scan: scanFields.nationality, meta: meta.Nationality}
    ];
    return fieldsToCompare.some(field => field.scan !== field.meta);
  }


  updateScannedPassport() {
    const datas = this.scanDocument?.scanDatas.fields;
    const {birthDate: BirthDay, firstName: FirstName, lastName: LastName, nationality: Nationality, documentNumber: PassportNumber, sex: Gender, expirationDate: DateOfExpiry} = datas;
    const serializedDatas = JSON.stringify({BirthDay: convertToEpoch(BirthDay, false, true), FirstName, LastName, Nationality, PassportNumber, Gender, DateOfExpiry: convertToEpoch(DateOfExpiry)});
    this.loader.loading(true, {type: 'info', message: 'ask.updateScannedPassport', btnLabel: 'global.yes'}).then((res: any) => {
      if(res) {
        this.loader.loading(true);
        this.api.updatePayment(this.currentPayment.id, undefined, undefined, serializedDatas, undefined, undefined, undefined).then(() => {
          this.loader.loading(true, {type: 'valid', message: 'done.updateScannedPassport'}).then(() => {
            this.payment();
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.updateScannedPassport'});
        });
      }
    });
  }

  getFormattedExternalIndexedData(): string {
    return this.currentPayment?.externalIndexedData.replace(/\|/g, ', ');
  }

  captureFingerprint() {
    if(electron.addon.hasSystemFingerprint()) if(electron.addon.scanFingerprint()) electron.beep();
  }

  openGate() {
    electron.step('go', true, [
      this.scanDocument?.scanDatas ? this.scanDocument.scanDatas.fields.firstName + ' ' + this.scanDocument.scanDatas.fields.lastName : 'person',
      this.scanDocument?.documentPhoto ? this.scanDocument.documentPhoto : '::0'
    ]);
  }

  closeGate() {
    electron.step('scan', true, []);
  }

  navToNotice(queryParams?: any) {
    if(!this.usageAfterValid) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('error.paymentUntil.before', {dateAfter: format(this.currentPayment.usageAfter, 'dd/MM/yyyy HH:mm')} )});
    } else {
      this.nav.to('admin-request-notice/' + this.paymentId, undefined, {queryParams});
    }
  }

  getCategoriesElement(): string {
    if(!this.currentPayment?.watchlistsResponse?.matches.length) {
      return '';
    }
    return this.currentPayment?.watchlistsResponse?.matches
      .filter(match => match.watchlist.category)
      .map(match => match.watchlist.category)
      .join(', ');
  }

  handleClickLink(event: MouseEvent, url: string) {
    event.stopPropagation();
    window.open(url, '_blank');
  }

  preparePrintReceipt() {
    if(this.currentPayment && this.currentPayment.events) {
      const adaptEvents = [...this.currentPayment.events];
      const receiptEvents = adaptEvents.filter(event => {
        if(event && event.consumeData) {
          return event.consumeData.PaymentReference !== undefined;
        }
        return false;
      }).map(event => {
        const newEvent = {...event};
        return {
          referencePrint: newEvent.consumeData.PaymentReference,
          datePrint: new Date(newEvent.updatedOn),
          nameClient: (this.metadata ? (this.metadata.FirstName + ' ' + this.metadata.LastName) : ''),
          amount: (newEvent.consumeData.PaymentIsNotDocument  === 'Yes' && newEvent.consumeData.PaymentAmountCents) ? newEvent.consumeData.PaymentAmountCents : this.currentPayment.amountCents,
          currency: newEvent.consumeData.PaymentCurrency,
          agentId: newEvent.actor_id,
          typePayment: newEvent.consumeData.PaymentMode,
          onlinePayment: this.currentPayment.externalId ? 'online' : 'offline',
          placeId: newEvent.place_id,
          buyDocument: this.metadata.BorderPass === 'Yes' ? DocumentType.BORDER_PASS : this.currentPayment.operationId as DocumentType,
          operation: newEvent.consumeData.Operation,
          idRequest: this.currentPayment.id,
          nationality: this.metadata.Nationality,
          gender: this.metadata.Gender,
          HQReference: this.currentPayment.internalReference,
          vendorName: this.api.userInfo.server.emailConfig.copyrightName,
          vendorAddress: this.api.userInfo.server.emailConfig.address,
          vendorEmail: this.api.userInfo.server.emailConfig.email,
          serialNumber: newEvent.consumeData.PlaceSerial,
          placePhone: this.currentPlace?.phoneNumbersCsv || ''
        } as PrintReceiptData;
      });
      this.printReceiptData = receiptEvents;
    }
  }

  preparePrintBorderPass() {
    const eventPayBorderPass = this.currentPayment.events.find(event => event.consumeData && event.consumeData.Operation === OperationType.OUT && this.metadata.BorderPass === 'Yes');
    this.printBorderPassData = {
      borderPassNumber: this.metadata.BorderPassNumber,
      fullNames: this.metadata.FirstName + ' ' + this.metadata.LastName,
      dateOfBirth: this.metadata.BirthDay,
      residentialAddress: this.metadata.ResidentialAddress,
      issueDate: this.metadata.IssueDate,
      expiryDate: this.metadata.ExpiryDate,
      reason: this.metadata.Reason,
      authorizedCrossingPoints: this.metadata.AuthorizedCrossingPoints,
      place: eventPayBorderPass ? eventPayBorderPass.place.longName : this.api.userPlaces.find(place => place.id === this.api.userPlaceId).longName,
      officer: eventPayBorderPass ? eventPayBorderPass.actor_id: this.api.userInfo.id
    };
  }

  prepareVisualDocuments() {
    const arrayDocuments: {nameDocument: string, document: any, title?: string, icon?: string, customClass?: string, previewDirect?: string, checkComparison?: boolean}[] =
      (this.metadata.Documents && !this.isObjectEmpty(this.metadata.Documents))
        ? Object.entries(this.metadata.Documents)
          .map(([nameDocument, document]) => ({nameDocument, document}))
          .filter(doc => isBase64(doc.document as string) || isPDF(doc.document as string))
        : [];

    if(arrayDocuments && arrayDocuments.length) {
      arrayDocuments.forEach(doc => {
        switch(doc.nameDocument) {
        case VisaFN.PASSPORT_PHOTO:
          doc.customClass = 'doc doc-dk doc-previewDirect';
          doc.previewDirect = 'photo.current';
          doc.title = 'photo.current';
          break;
        case VisaFN.PASSPORT_SCAN:
          doc.icon = 'passport';
          doc.title = 'data.biographic';
          break;
        case VisaFN.PROOF_OF_RESIDENCE:
          doc.icon = 'house';
          doc.title = 'residence.proof';
          break;
        case VisaFN.COPY_OF_ENTRY:
          doc.icon = 'passport';
          doc.title = 'data.copyOfEntry';
          break;
        case VisaFN.DEPARTURE_TICKET:
          doc.icon = 'passport';
          doc.title = 'data.departureTicket';
          break;
        case VisaFN.ATTESTANT_DOCUMENT:
          doc.icon = 'passport';
          doc.title = 'data.attestantDocument';
          break;
        case VisaFN.AFFIDAVIT_CUSTODY:
          doc.icon = 'passport';
          doc.title = 'data.affidavitCustody';
          break;
        case VisaFN.REASON_OF_VISIT_PROOF:
          doc.icon = 'file-line';
          doc.title = 'reasonOfVisit.proof';
          break;
        case VisaFN.ADDRESS_AT_DESTINATION:
          doc.icon = 'location-dot';
          doc.title = 'address.destination';
          break;
        case VisaFN.PREVIOUS_ZIMBABWEAN_VISA:
          doc.icon = 'file-lines';
          doc.title = 'previous.visa';
          break;
        case VisaFN.INVITATION_LETTER_AND_BUSINESS_PROFILE:
          doc.icon = 'envelope-open-text';
          doc.title = 'inviteBusiness.letter';
          break;
        case VisaFN.BUSINESS_PROFILE:
          doc.icon = 'user';
          doc.title = 'business.profile';
          break;
        case VisaFN.APPLICATION_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'application.letter';
          break;
        case VisaFN.LETTER_OF_ACCEPTANCE_FROM_SCHOOL:
          doc.icon = 'envelope-open-text';
          doc.title = 'acceptanceFromScool.letter';
          break;
        case VisaFN.BUSINESS_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'business.letter';
          break;
        case VisaFN.HOST_INVITATION_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'hostInvitation.letter';
          break;
        case VisaFN.PROOF_OF_RESIDENCE_HOST:
          doc.icon = 'home';
          doc.title = 'proof.residenceHost';
          break;
        case VisaFN.RESIDENTIAL_STATUS_OF_HOST:
          doc.icon = 'id-card';
          doc.title = 'hostResidential.status';
          break;
        case VisaFN.INVITATION_LETTER:
          doc.icon = 'envelope-open-text';
          doc.title = 'invite.letter';
          break;
        case VisaFN.EXTRA_DOCUMENT_1:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument1';
          break;
        case VisaFN.EXTRA_DOCUMENT_2:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument2';
          break;
        case VisaFN.EXTRA_DOCUMENT_3:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument3';
          break;
        case VisaFN.EXTRA_DOCUMENT_4:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument4';
          break;
        case VisaFN.EXTRA_DOCUMENT_5:
          doc.icon = 'file-image';
          doc.title = 'ExtraDocument5';
          break;
        default:
          doc.icon = 'file';
          doc.title = 'document';
          break;
        }
      });
    }

    if(this.scanDocument?.documentPhoto && this.showComparison) {
      arrayDocuments.unshift({
        nameDocument: VisaFN.PASSPORT_SCAN,
        document: this.scanDocument.documentPhoto,
        title: 'photo.scanned',
        customClass: 'doc doc-dk doc-previewDirect',
        previewDirect: 'photo.scanned',
        checkComparison: true
      });
    }
    this.metadataDocuments = arrayDocuments;
  }


  printReceipt(referencePrint?: string, a5 = false) {
    const printSelectedReceipt = (receipt: PrintReceiptData) => {
      this.loader.loading(true);
      this.currentPrintReceiptDatas = receipt;
      this.printCurrent = a5 ? 'receipt-a5' : 'receipt';

      setTimeout(() => {
        const printReceiptNoThermal = (): void => setTimeout(() => {
          window.print();
          this.loader.loading(false);
          this.printCurrent = '';
        }, 500) as any;

        const printReceiptThermal = () => this.request.printReceipt(receipt).then(() => {
          this.loader.loading(false);
        }).catch(([error]: [string]) => {
          this.loader.loading(true, {type: 'warn', message: error, btnLabel: 'global.print'}).then((done) => done && printReceiptNoThermal());
        });
        const storedValue = isJsonParsable(localStorage.getItem('printThermal'));
        const printThermal = storedValue !== null ? storedValue : true;
        if(this.api.isElectronApp && printThermal) {
          return printReceiptThermal();
        } else {
          return printReceiptNoThermal();
        }
      }, 100);
    };
    if(referencePrint) {
      const receipt = this.printReceiptData.find(receipt => receipt.referencePrint === referencePrint);
      if(receipt) {
        printSelectedReceipt(receipt);
      }
    } else {
      if(this.printReceiptData.length > 0) {
        if(this.printReceiptData.length === 1) {
          const receipt = this.printReceiptData[0];
          this.loader.loading(true, {
            type: 'info',
            btnLabel: 'global.yes',
            custom: {
              innerHtml: this.lang.transform('ask.printReceipt', {date: format(receipt.datePrint, 'dd/MM/yyyy HH:mm')}),
              icon: 'print'
            }
          }).then((res: boolean) => {
            if(res) {
              return printSelectedReceipt(receipt);
            }
          });
        } else {
          const dialogInput = [
            {
              formCtrlName: 'selectedReceipt',
              type: 'select',
              label: 'select.receipt',
              selectOptions: this.printReceiptData.map((receipt, index) => ({
                name: `${this.lang.transform('operation.' + receipt.operation)} - ${format(receipt.datePrint, 'dd/MM/yyyy HH:mm')}`,
                value: index
              }))
            }
          ] as DialogFormInput[];
          this.loader.loading(true, {
            type: 'question',
            message: 'ask.selectReceipt',
            btnLabel: 'global.confirm',
            custom: {
              closeBtnLabel: 'global.cancel',
              icon: 'print'
            },
            dialogInput
          }).then((res: any) => {
            if(res?.selectedReceipt) {
              const selectedReceipt = this.printReceiptData[res.selectedReceipt];
              printSelectedReceipt(selectedReceipt);
            } else if(res === undefined) {
              this.loader.loading(true, {type: 'error', message: 'noChoice.selectedReceipt'});
            }
          });
        }
      }
    }
  }

  printBorderPass() : Promise<void> {
    return new Promise(resolve => {
      this.loader.loading(true);
      this.printCurrent = 'borderPass';
      setTimeout(() => {
        window.print();
        this.loader.loading(false);
        this.printCurrent = '';
        return resolve();
      }, 500);
    });
  }

  isLocalUsageUntilError() {
    return this.currentPayment?.localUsageUntil && !this.localUsageUntilValid;
  }

  downgrade() {
    this.loader.loading(true, {type: 'warn', message: 'ask.downgrade', btnLabel: 'global.yes', custom: {icon: 'circle-down'}}).then((done: boolean) => {
      if(done) {
        this.loader.loading(true);
        this.api.updatePayment(this.currentPayment.id, undefined, undefined, undefined, undefined, undefined, undefined, DocumentType.ZWEVISA).then(() => {
          this.loader.loading(true, {type: 'valid', message: 'done.downgrade'}).then(() => {
            this.payment();
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.downgrade'});
        });
      }
    });
  }

  canPrintBorderPass(){
    return this.api.isZwevisa && this.printBorderPassData && this.currentPayment.operationId === DocumentType.ZWEEXIT && this.currentPayment.subState === RequestSubState.CLOSED && this.currentPayment.events.find(event => event.consumeData && event.consumeData.Operation !== OperationType.REFUSEOUT  && event.consumeData.Operation !== OperationType.REFUSEIN) && (this.metadata.BorderPass && this.metadata.BorderPass === 'Yes');
  }

  getWarningMessage(): string {
    const messages = [];
    const readyApplication = this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKIN ||
      this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKOUT ||
      this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECK;
    if(!this.usageUntilValid && readyApplication) {
      messages.push(this.lang.transform(this.isCatAB ? 'outUsage.AB' : 'outUsage.C'));
    }
    if(!this.localUsageUntilValid && this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKOUT) {
      messages.push(this.lang.transform('application.overStay'));
    }
    if(this.currentPayment?.watchlistsResponse?.matches?.length){
      messages.push(this.lang.transform('watchlistFound'));
    }
    if(this.currentPayment?.apipnrResponse?.matches?.length){
      messages.push(this.lang.transform('apipnrFound'));
    }
    return (this.currentPayment.state !== RequestState.PROPOSED && this.api.env.type === DocumentType.ZWEVISA) ? messages.join(' & ') : '';
  }

  changeDeportedMode(event: boolean) {
    this.deportedMode = event;
  }

  toggleShowComparison() {
    this.showComparison = !this.showComparison;
    if(this.showComparison) {
      const hasComparison = this.metadataDocuments.some(doc => doc.checkComparison);
      if(!hasComparison && this.scanDocument?.documentPhoto) {
        this.metadataDocuments.unshift({
          nameDocument: VisaFN.PASSPORT_SCAN,
          document: this.scanDocument.documentPhoto,
          title: 'photo.scanned',
          customClass: 'doc doc-dk doc-previewDirect',
          previewDirect: 'photo.scanned',
          checkComparison: true
        });
      }
    } else {
      this.metadataDocuments = this.metadataDocuments.filter(doc => !doc.checkComparison);
    }
  }

  hasDocumentsToShow(): boolean {
    const metadataDocs = this.metadata?.Documents;
    return (metadataDocs && Object.keys(metadataDocs).some(key => key !== 'PurposeOfVisit' && metadataDocs[key])) ||
      !!this.currentPayment?.consumeData ||
      !!this.scanDocument?.documentPhoto;
  }

  preparePrintNotices() {
    const notices = this.currentPayment?.events?.filter(event => event.consumeData?.Documents?.some((document: any) => document.Type === ConsumePaymentNoticeType.RESTRICTION ||
      document.Type === ConsumePaymentNoticeType.REPORTING ||
      document.Type === ConsumePaymentNoticeType.REFUSAL)) || [];
    if(!notices.length) {
      return;
    }
    this.existingNotices = notices;
  }

  printNotices() {
    const longName = this.metadata?.FirstName || this.metadata?.LastName ? (this.metadata?.FirstName || '') + (this.metadata?.LastName ? ' ' + this.metadata?.LastName : '') : '';

    const noticePrintAction = (event: PlatformEvent) => {
      const documentsConsumeData = event.consumeData.Documents[0];
      const restrictionFields = documentsConsumeData?.RestrictionFields;
      const reportingFields = documentsConsumeData?.ReportingFields;
      const refusalFields = documentsConsumeData?.RefusalFields;
      switch(documentsConsumeData.Type) {
      case ConsumePaymentNoticeType.REPORTING:
        this.formNotice = this.formBuilder.group({
          hqReference: [this.currentPayment.internalReference || ''],
          stationReference: [event.place.longName +' ['+ event.place.id +']' || ''],
          purpose: [reportingFields?.Purpose || ''],
          fullNames: [longName],
          host: [reportingFields?.Host || ''],
          phone: [reportingFields?.Phone || ''],
          hostPhone: [reportingFields?.HostPhone || ''],
          addressZimbabwe: [reportingFields?.AddressZimbabwe || ''],
          email: [reportingFields?.Email],
          address: [reportingFields?.Address || ''],
          periodFrom: [reportingFields?.PeriodFrom || ''],
          periodTo: [reportingFields?.PeriodTo || ''],
          officer: [event.actor_id],
          reportAt: [reportingFields?.ReportingPost || ''],
          reportOn: [reportingFields?.ReportOn ? reportingFields?.ReportOn : ''],
          reportInterval: [reportingFields?.ReportAtIntervalDays || '']
        });
        break;
      case ConsumePaymentNoticeType.RESTRICTION:
        this.formNotice = this.formBuilder.group({
          hqReference: [this.currentPayment.internalReference || ''],
          stationReference: [event.place.longName +' ['+ event.place.id +']' || ''],
          fullNames: [longName],
          reportLocation: [restrictionFields?.ReportingPost || ''],
          daysUntilReport: [restrictionFields?.GracePeriodDays || ''],
          otherMissingDocument: [restrictionFields?.DocumentToProduceOther || ''],
          residentialAddress: [restrictionFields?.ResidentialAddress || ''],
          businessAddress: [restrictionFields?.BusinessAddress || ''],
          phone: [restrictionFields?.Phone || ''],
          email: [restrictionFields?.Email || ''],
          officer: [event.actor_id]
        });
        this.docFormNotice = documentsConsumeData.RestrictionFields.DocumentToProduce;
        break;
      case ConsumePaymentNoticeType.REFUSAL:
        this.formNotice = this.formBuilder.group({
          fullNames: [longName],
          reportLocation: [event.place.longName +' ['+ event.place.id +']' || ''],
          paragraph: [refusalFields?.Paragraph || ''],
          reason: [refusalFields?.Reason || ''],
          dateOfRefusal: [format(event.updatedOn, 'yyyy-MM-dd')],
          place: [event.place.longName +' ['+ event.place.id +']' || ''],
          officer: [event.actor_id],
          operationComments: [event?.consumeData?.OperationComments || '']
        });
      }

      this.printCurrent = `notice-${documentsConsumeData.Type}` as `notice-${ConsumePaymentNoticeType}`;
      setTimeout(() => {
        window.print();
        this.printCurrent = '';
        this.formNotice = this.formBuilder.group({});
        this.docFormNotice = [];
      }, 1000);
    };

    if(this.existingNotices?.length === 1) {
      this.loader.loading(true, {
        type: 'question',
        message: this.lang.transform('ask.noticePrint', {date: format(this.existingNotices[0].updatedOn, 'dd/MM/yyyy')}),
        btnLabel: 'global.yes',
        custom: {icon: 'print'}
      }).then((res: boolean) => {
        if(res) {
          noticePrintAction(this.existingNotices[0]);
        }
      });
    } else if(this.existingNotices?.length > 1) {
      const dialogInput = [
        {
          formCtrlName: 'selectedNotice',
          type: 'select',
          label: 'select.notice',
          selectOptions: this.existingNotices.map((notice: PlatformEvent, index: number) => ({
            name: `${format(notice.updatedOn, 'dd/MM/yyyy')} - ${this.lang.transform(`notice.${notice.consumeData.Documents[0].Type}`)}`,
            value: index
          }))
        }
      ] as DialogFormInput[];
      this.loader.loading(true, {
        type: 'question',
        message: 'ask.selectNotice',
        btnLabel: 'global.confirm',
        custom: {
          closeBtnLabel: 'global.cancel',
          icon: 'print'
        },
        dialogInput
      }).then((res: any) => {
        if(res?.selectedNotice) {
          const selectedNotice = this.existingNotices[res.selectedNotice];
          noticePrintAction(selectedNotice);
        }
      });
    }
  }

  closeApplication() {
    this.fingerprint.supervision('closeApplication').then(() => {
      this.loader.loading(true);
      this.api.changePayment(this.currentPayment.id, RequestState.USED, RequestSubState.CLOSED).then(() => {
        this.loader.loading(false);
        this.payment();
      }).catch(() => {
        this.loader.loading(true, {type: 'error', message: 'error.closeApplication'});
      });
    });
  }

  revertConsume(event: PlatformEvent) {
    const dialogReasonInput = [{
      formCtrlName: 'revertReason',
      type: 'textarea',
      label: 'revert.consume',
      maxLength: 200
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'warn', message: 'ask.revertConsume', btnLabel: 'global.yes', dialogInput: dialogReasonInput, custom: {icon: 'undo'}}).then((done: any) => {
      if(done){
        this.loader.loading(true);
        return this.api.voidConsumePayment(this.currentPayment.id, event.id, done.revertReason).then(() => this.loader.loading(true, {type: 'valid', message: 'done.revertConsume'}).then(() => {
          this.payment();
        })).catch(() => this.loader.loading(true, {type: 'error', message: 'error.revertConsume'}));
      }
    });
  }

  getCancelledEvent(eventId: string): any {
    return this.cancelEvent.find(e => +e.id === +eventId) || null;
  }

  isLatestUpdatedEvent(event: PlatformEvent): boolean {
    const listOfUpdates = this.currentPayment.events.filter(e => e.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE || (e.type === PlatformEventType.PAYMENT_UPDATED && !e.place_id));
    const sorted = listOfUpdates.sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime());
    return sorted[0].id === event.id && this.currentPayment.state !== RequestState.PENDING;
  }

  internalReference(edit = false) {
    const HQ_REFERENCE_PATTERN = /^\d+_\d{4}$/;
    const dialogInput = [{
      formCtrlName: 'internalReference',
      type: 'textarea',
      value: this.currentPayment.internalReference || '',
      label: 'internalReference.desc',
      maxLength: 40
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'info', message: this.currentPayment.internalReference ? 'internalReference.ask.edit' : 'internalReference.ask.create', dialogInput}).then((res: any) => {
      if(res?.internalReference) {
        if(!HQ_REFERENCE_PATTERN.test(res.internalReference)) {
          this.loader.loading(true, {type: 'warn', message: 'internalReference.badPattern'});
          return;
        }
        return this.api.updatePayment(this.currentPayment.id, undefined, undefined, undefined, undefined, undefined, res.internalReference).then(() => {
          this.payment().then(() => {
            this.loader.loading(true, {type: 'valid', message: edit ? 'internalReference.done.edit' : 'internalReference.done.create'});
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: 'error.internalReference'});
        });
      }
    });
  }

  private checkScannedGender() {
    if(this.currentPayment.state !== RequestState.USED && this.scanDocument?.scanDatas && this.metadata.Gender && this.metadata.Gender === Gender.OTHER) {
      const dialogInput = [{
        formCtrlName: 'gender',
        type: 'select',
        label: 'form.gender',
        selectOptions: [
          {name: 'male', value: Gender.MALE},
          {name: 'female', value: Gender.FEMALE}
        ]
      }] as DialogFormInput[];
      setTimeout(() => {
        this.loader.loading(true, {type: 'info', message: 'changeGender.ask', dialogInput, btnLabel: 'global.confirm', custom: {icon: 'venus-mars'}}).then((res: any) => {
          if(res?.gender) {
            const serializedDatas = JSON.stringify({[VisaFN.GENDER]: res.gender});
            this.loader.loading(true);
            this.api.updatePayment(this.currentPayment.id,
              undefined, undefined, serializedDatas, undefined,
              undefined, undefined, undefined, undefined, undefined).then(() => {
              this.payment().then(() => {
                this.loader.loading(false);
              });
            }).catch(() => {
              this.loader.loading(true, {type: 'error', message: 'global.error'});
            });
          }
        });
      }, 500);
    }
  }

  protected readonly RequestState = RequestState;
  protected readonly convertToEpoch = convertToEpoch;
  protected readonly ShortcutAction = ShortcutAction;
  protected readonly ActionState = ActionState;
  protected readonly faSpellCheck = faSpellCheck;
}

/* tslint:disable */
export const fr_strings = {
  'dashboard.kiosk.start': 'Démarrer une demande',
  'form.role.kiosk': 'Kiosque',
  'global.cancel': 'Annuler',
  'FundsAvailable': 'Fonds disponibles',
  'FundsAvailableCurrency': 'Devise des fonds disponibles',
  'err.FundsAvailable.required': 'Les fonds disponibles sont requis',
  'err.FundsAvailableCurrency.required': 'La devise des fonds disponibles est requis',
  'USD': '$ USD',
  'EUR': '€ EUR',
  'GBP': '£ GBP',
  'emergency_contact_text': 'Veuillez saisir les données de la personne à contacter en cas d\'urgence',
  'travel_information_title': 'Attention',
  'emergency_contact': 'Contact d\'urgence',
  'zwevisaarr': 'Visa à l\'arrivée',
  'err.BirthDay.mustBeEqualOrBefore': 'Date de naissance doit être égale ou avant la date de délivrance du passeport',
  'err.DateOfIssue.mustBeEqualOrAfter': 'Date de délivrance doit être égale ou après la date de naissance',
  'internet.lost': 'Connexion internet perdue.</br> Veuillez vérifier votre connexion.',
  'zwevisa_visitor_details_title': 'Votre nationalité relève-t-elle du régime de la Catégorie A?',
  'zwevisa_visitor_details_text': '<div class="mb_40">Si vous êtes un ressortissant d\'un pays figurant dans la <a href="https://evisa-zimbabwe.webflow.io/visa-regime"><b>Catégorie A (cliquez pour ouvrir)</b></a>, vous n\'avez pas besoin d\'obtenir un visa pour voyager au Zimbabwe.</div>',
  'err.DateOfExpiry.maxDateMilliFromToday': 'La date d\'expiration de votre passeport est inférieure à 6 mois à compter d\'aujourd\'hui. Veuillez renouveler votre passeport avant de demander un visa.',
  'err.DepartureDate.maxDateMilliFrom': 'La date de départ est trop éloignée de la date d\'arrivée. Veuillez vous référer à la date limite du visa.',
  'requirements': 'Prérequis',
  'err.file.size': 'Fichier trop volumineux. Taille maximale: ',
  'suspendReason.desc': 'Veuillez rédiger une brève description de la demande de correction. Cette description sera envoyée au demandeur avec les champs sélectionnés.',
  'global.suspend': 'Demander une révision',
  'revision.success': 'Votre mise à jour de demande a été soumise avec succès. Vous recevrez un email contenant le code QR dès qu\'elle sera approuvée.',
  'PAYMENT_UPDATED': 'Demande mise à jour',
  'revision.free': 'Correction gratuite',
  'revision.start': 'Nous avons pris soin de remplir les champs avec les informations que vous avez fournies dans la demande initiale, à l\'exception des champs qui posent problème. Veuillez vérifier les informations et les corriger. Voulez-vous continuer ?',
  'file_uploaded': 'Fichier déja téléchargé et sauvegardé',
  'revision.person_not_found': 'La personne liée à cette demande n\'a pas été trouvée',
  'refusal.desc2': 'Les champs suivants posent problème:',
  'start_correction': 'Commencer la correction',
  'PAYMENT_SUSPEND_CHANGE': 'Demande suspendue, changements demandés.',
  'payment_status.PENDING': 'En attente de correction',
  'payment_status.7': 'En attente',
  'payment.selectMode': 'Veuillez sélectionner les champs qui pose problème. Vous pouvez sélectionner plusieurs champs. Ensuite, cliquez à nouveau sur "Demander une révision".',
  'refusalReason.desc': 'Veuillez fournir une brève description du refus. Cette description sera envoyée au demandeur.',
  'refusalReason.incomplete_or_missing': 'Informations incomplètes ou manquantes dans le formulaire.',
  'refusalReason.inconsistencies_or_contradictions': 'Incohérences ou contradictions dans les informations fournies.',
  'refusalReason.incorrect_misleading_info': 'Informations incorrectes, trompeuses ou frauduleuses.',
  'refusalReason.missing_or_illegible_documents': 'Documents requis non fournis, incomplets ou illisibles.',
  'refusalReason.documents_not_compliant': 'Documents non conformes aux exigences (qualité, validité).',
  'refusalReason.non_compliance_visa_requirements': 'Non-conformité avec les exigences spécifiques du visa demandé.',
  'refusalReason.terms_conditions_not_accepted': 'Non-acceptation des termes et conditions du visa.',
  'refusalReason.payment_issues': 'Non-paiement des frais de visa ou échec du processus de paiement.',
  'refusalReason.problems_with_personal_details': 'Problèmes liés à l\'âge, la nationalité ou le statut marital du demandeur.',
  'refusalReason.criminal_history_travel_issues': 'Antécédents judiciaires ou problèmes d\'historique de voyage.',
  'refusalReason.inadequate_purpose_of_visit': 'But du voyage inadéquat ou non conforme au type de visa.',
  'refusalReason.security_background_concerns': 'Préoccupations lors des vérifications de sécurité ou d\'antécédents.',
  'refusalReason.doubts_about_authenticity': 'Doutes sur l\'authenticité des informations ou documents fournis.',
  'refusalReason.legitimacy_concerns': 'Problèmes identifiés concernant la légitimité du séjour ou de l\'invitation.',
  'refusalReason.form_entry_or_technical_issues': 'Erreurs de saisie ou problèmes techniques dans le formulaire de demande.',
  'refusalReason.electronic_submission_difficulties': 'Difficultés liées à la soumission électronique des documents.',
  'refusalReason.host_country_capacity_or_restrictions': 'Capacité d\'accueil du pays hôte dépassée ou restrictions en vigueur.',
  'refusalReason.exceptional_diplomatic_political_considerations': 'Considérations diplomatiques ou politiques exceptionnelles.',
  'refusalReason.exceptional_circumstances_humanitarian_reasons': 'Circonstances exceptionnelles ou raisons humanitaires justifiant le refus.',
  'refusalReason.other': 'Autre, veuillez préciser.',
  'err.BirthDay.maxDate': 'Date de naissance doit être avant la date d\'aujourd\'hui',
  'err.DepartureDate.minDate': 'Date de départ doit être après la date d\'aujourd\'hui',
  'err.ArrivalDate.minDate': 'Date d\'arrivée doit être après la date d\'aujourd\'hui',
  'err.DateOfExpiry.minDate': 'Date d\'expiration doit être après la date d\'aujourd\'hui',
  'err.DateOfIssue.maxDate': 'Date de délivrance doit être avant la date d\'aujourd\'hui',
  'err.ArrivalDate.mustBeEqualOrBefore': 'Date d\'arrivée doit être égale ou avant la date de départ',
  'err.DepartureDate.mustBeEqualOrAfter': 'Date de départ doit être égale ou après la date d\'arrivée',
  'qr.request_desc': 'Ce QR Code sert de laissez-passer numérique pour le visa. Le gouvernement se réserve le droit d\'accepter ou de refuser l\'entrée lors du contrôle.',
  'PAYMENT_REFUSED': 'Paiement refusé',
  'request_state.PENDING': 'En attente',
  'document.Screening': 'Vérification',
  'deletePerson.error.DYN.pass': 'Erreur lors de la suppression de la personne. Vous ne pouvez pas supprimer une personne qui a déjà acheté un document.',
  'miscellaneousTrip': 'Voyage',
  'miscellaneous_text': 'Veuillez indiquer les informations suivantes de manière véridique',
  'err.SpouseBirthDay.max': 'Date de naissance du conjoint invalide',
  'err.ArrivalDate.min': 'Date d\'arrivée invalide',
  'err.DepartureDate.min': 'Date de départ invalide',
  'err.DateOfIssue.max': 'Date de délivrance invalide',
  'err.BirthDay.max': 'Date de naissance invalide',
  'err.dependants_date_of_birth.max': 'Date de naissance invalide',
  'err.DateOfExpiry.min': 'Date d\'expiration invalide',
  'dependants': 'Personnes à charge',
  'InvitationLetter': 'Lettre d\'invitation',
  'err.InvitationLetter.required': 'Lettre d\'invitation est requise',
  'err.LetterOfAcceptanceFromSchool.required': 'Lettre d\'acceptation de l\'école est requise',
  'err.ReasonOfVisitProof.required': 'Preuve de raison de la visite est requise',
  'err.AddressAtDestination.required': 'Adresse à la destination est requise',
  'err.ApplicationLetter.required': 'Lettre de demande est requise',
  'err.ResidentialStatusOfHost.required': 'Statut de résidence de l\'hôte est requis',
  'err.PassportPhoto.required': 'Photo de passeport est requise',
  'err.PassportScan.required': 'Scan de passeport est requis',
  'err.ProofOfResidenceHost.required': 'Preuve de résidence de l\'hôte est requise',
  'err.BusinessLetter.required': 'Lettre d\'affaires est requise',
  'err.BusinessProfile.required': 'Profil d\'entreprise est requis',
  'err.InvitationLetterAndBusinessProfile.required': 'Lettre d\'invitation et profil d\'entreprise sont requis',
  'err.SpouseName.required': 'Nom du conjoint est requis',
  'err.SpousePassportNumber.required': 'Numéro de passeport du conjoint est requis',
  'err.SpouseBirthDay.required': 'Date de naissance du conjoint est requise',
  'err.SpousePlaceOfBirth.required': 'Lieu de naissance du conjoint est requis',
  'err.ArrivalDate.required': 'Date d\'arrivée est requise',
  'err.DepartureDate.required': 'Date de départ est requise',
  'err.DateOfIssue.required': 'Date de délivrance est requise',
  'err.DateOfExpiry.required': 'Date d\'expiration est requise',
  'err.Email.required': 'Email est requis',
  'err.Phone.required': 'Num. de téléphone est requis',
  'err.HostName.required': 'Nom de l\'hôte est requis',
  'err.LastName.required': 'Nom de famille est requis',
  'err.FirstName.required': 'Prénom est requis',
  'err.BirthDay.required': 'Date de naissance est requise',
  'err.PlaceOfBirth.required': 'Lieu de naissance est requis',
  'err.Gender.required': 'Genre est requis',
  'err.MaritalStatus.required': 'Statut marital est requis',
  'err.CheckboxVisaFees.required': 'Veuillez accepter les frais de visa',
  'err.CheckboxTermsAndConditions.required': 'Veuillez accepter les conditions d\'utilisation et la politique de confidentialité',
  'err.Nationality.required': 'Nationalité est requise',
  'err.PurposeOfVisit.required': 'Raison de la visite est requise',
  'male': 'Homme',
  'female': 'Femme',
  'other': 'Autre',
  'single': 'Célibataire',
  'married': 'Marié(e)',
  'divorced': 'Divorcé(e)',
  'widowed': 'Veuf/Veuve',
  'select_reason_first': 'Veuillez sélectionner la raison de la visite sur la page précédente afin de voir la liste des documents requis.',
  'visaIntro.title': 'Demande de visa',
  'askDocument': 'Commencer la demande',
  'cart.load': 'Une demande sauvegardée a été trouvée, voulez-vous charger ses données ? (Si vous cliquez sur "non", la demande sera supprimée)',
  'cart.keep': 'Voulez-vous sauvegarder votre demande en cours pour la reprendre plus tard ?',
  'zwevisa.description': 'Le visa simple entrée est valable pour une entrée dans le pays. Il est valable pour une période de 90 jours à compter de la date d\'émission.',
  'visa_double.description': 'Le visa double entrée est valable pour deux entrées dans le pays. Il est valable pour une période de 90 jours à compter de la date d\'émission.',
  'visa_kaza.description': 'Le visa KAZA Univisa est valable pour les entrées multiples au Zimbabwe et en Zambie. Il est valable pour une période de 30 jours à compter de la date d\'émission.',
  'zwevisa': 'Visa simple',
  'zwevisa_double': 'Visa double',
  'zwevisa_kaza': 'KAZA Univisa',
  'zwevisa_multiple': 'Visa multiple',
  'zwevisa_double.title': 'Visa double',
  'zwevisa_kaza.title': 'KAZA Univisa',
  'zwevisa_multiple.title': 'Visa multiple',
  'err.email.pattern': 'L\'email doit avoir un format correct',
  'err.phone.pattern': 'Le numéro de téléphone doit avoir un format correct par exemple +263 712 345 678',
  'err.Email.pattern': 'L\'email doit avoir un format correct',
  'err.Phone.pattern': 'Le numéro de téléphone doit avoir un format correct par exemple +263 712 345 678',
  'remove': 'Supprimer',
  'dependant_add': 'Ajouter une personne à charge',
  'dependant': 'Personne à charge',
  'err.dependants_name.required': 'Le nom de la personne à charge est requis',
  'err.dependants_passport_number.required': 'Le numéro de passeport de la personne à charge est requis',
  'err.dependants_date_of_birth.required': 'La date de naissance du bénéficiaire est requise',
  'err.dependants_place_of_birth.required': 'Le lieu de naissance du bénéficiaire est requis',
  'upload_file': 'Télécharger un fichier (< 500Ko)',
  'PassportPhoto': 'Photo de passeport',
  'PassportScan': 'Scan de passeport',
  'ProofOfResidenceHost': 'Preuve de résidence de l\'hôte',
  'AddressAtDestination': 'Adresse à la destination',
  'PreviousZimbabweanVisa': 'Visa zimbabwéen précédent',
  'HostInvitationLetter': 'Lettre d\'invitation de l\'hôte',
  'ProofOfResidence': 'Preuve de résidence',
  'ApplicationLetter': 'Lettre de demande',
  'ResidentialStatusOfHost': 'Statut de résidence de l\'hôte',
  'ReasonOfVisitProof': 'Preuve de raison de la visite',
  'BusinessLetter': 'Lettre d\'affaires',
  'BusinessProfile': 'Profil d\'entreprise',
  'InvitationLetterAndBusinessProfile': 'Lettre d\'invitation et profil d\'entreprise',
  'LetterOfAcceptanceFromSchool': 'Lettre d\'acceptation de l\'école',
  'DYN.male': 'Homme',
  'DYN.female': 'Femme',
  'DYN.other': 'Autre',
  'DYN.single': 'Célibataire',
  'DYN.married': 'Marié(e)',
  'DYN.divorced': 'Divorcé(e)',
  'DYN.widowed': 'Veuf/Veuve',
  'visa_application': 'Prérequis du visa',
  'visa_application_title': 'Avant de poursuivre, assurez-vous que',
  'visa_application_text': '<ol><li><b>1. </b>Vos informations sont exactes et véridiques</li><li><b>2. </b>Vous disposez d\'une carte MasterCard ou Visa  3D Secure et de fonds suffisants pour payer les frais de visa.</li><li><b>3. </b>En effectuant votre paiement et en soumettant votre demande, vous attestez également que toutes les informations saisies sont correctes et exactes. La falsification d\'informations en vue de l\'obtention d\'un visa est illégale et passible d\'une sanction en cas de condamnation.</li></ol>',
  'visaPrice.desc': 'Il vous sera demandé d\'effectuer ce paiement par Visa ou MasterCard à la fin de l\'application. Ce paiement est non remboursable.',
  'visaPrice': 'Prix du visa',
  'required': 'Requis',
  'DYN.visa': 'Entrée unique',
  'DYN.visa_double': 'Entrée double',
  'DYN.visa_kaza': 'KAZA Univisa',
  'DYN.holiday_visa': 'Visa de vacances',
  'DYN.business_visa': 'Visa d\'affaires',
  'DYN.conferencing_visa': 'Visa de conférence',
  'DYN.transit_visa': 'Visa de transit',
  'DYN.student_visa': 'Visa d\'étudiant',
  'paypal': 'PayPal',
  'document': 'Document',
  'document_text': 'Veuillez indiquer les informations suivantes de manière véridique et telles qu\'elles figurent sur votre passeport ou sur d\'autres documents légaux. Le département de l\'immigration (Zimbabwe) ne sera pas tenu responsable de toute information falsifiée ou de tout désagrément qui en résulterait.',
  'Dependants': 'Personnes à charge',
  'dependants_name': 'Nom',
  'dependants_passport_number': 'N° de passeport',
  'dependants_date_of_birth': 'Date de naissance',
  'dependants_place_of_birth': 'Lieu de naissance',
  'Spouse': 'Conjoint',
  'SpouseName': 'Nom du conjoint',
  'SpousePassportNumber': 'Numéro de passeport du conjoint',
  'SpouseBirthDay': 'Date de naissance du conjoint',
  'SpousePlaceOfBirth': 'Lieu de naissance du conjoint',
  'ArrivalDate': 'Date d\'arrivée',
  'DepartureDate': 'Date de départ',
  'ReferencePreviousVisa': 'Référence du visa précédent',
  'PreviousConvictionsZim': 'Condamnations précédentes au Zimbabwe',
  'PreviousConvictionsHome': 'Condamnations précédentes au pays d\'origine',
  'travel_information': 'Informations de passeport',
  'travel_information_text': 'Veuillez indiquer les informations suivantes de manière véridique et telles qu\'elles figurent sur votre passeport ou sur d\'autres documents légaux. Le département de l\'immigration (Zimbabwe) ne sera pas tenu responsable de toute information falsifiée ou de tout désagrément qui en résulterait.',
  'DateOfIssue': 'Date de délivrance',
  'DateOfExpiry': 'Date d\'expiration',
  'PlaceofIssue': 'Lieu de délivrance',
  'Email': 'Email',
  'Phone': 'Num. de téléphone',
  'Occupation': 'Occupation',
  'HomeAddress': 'Adresse',
  'HostName': 'Nom de l\'hôte',
  'AddressZimbabwe': 'Adresse au Zimbabwe',
  'LastName': 'Nom de famille',
  'FirstName': 'Prénom',
  'BirthDay': 'Date de naissance',
  'PlaceOfBirth': 'Lieu de naissance',
  'Gender': 'Genre',
  'MaritalStatus': 'Statut marital',
  'CheckboxVisaFees': 'J\'accepte les frais de visa',
  'CheckboxTermsAndConditions': 'J\'accepte les conditions d\'utilisation et la politique de confidentialité',
  'PassportNumber': 'N° de passeport',
  'Nationality': 'Nationalité',
  'PurposeOfVisit': 'Raison de la visite',
  'VisaType': 'Type de visa',
  'residence_proof': 'Preuve de résidence',
  'destination_address': 'Adresse de destination',
  'visitor_details': 'Détails du visiteur',
  'personal_details': 'Détails personnels',
  'contact_details': 'Détails de contact',
  'miscellaneous_details': 'Détails de voyage',
  'spouse_details': 'Détails du conjoint',
  'payment': 'Paiement',
  'global.toPayments.DYN.pass': 'Voir les paiements',
  'global.toPayments.DYN.zwevisa': 'Voir les demandes de visa',
  'form.createPlaceId': 'ID du lieu',
  'payment.seeDuplicata': 'Ouvrir le duplicata',
  'payment.duplicate.open': 'Ouvrir le nouveau paiement ?',
  'ONE_DAY': '1 jour',
  'THREE_DAYS': '3 jours',
  'ONE_WEEK': '1 semaine',
  'TWO_WEEKS': '2 semaines',
  'ONE_MONTH': '1 mois',
  'TWO_MONTHS': '2 mois',
  'SIX_MONTHS': '6 mois',
  'ONE_YEAR': '1 an',
  'dropdown.since': 'Depuis',
  'payments.hasFilter': 'Filtre est actif',
  'payments.clear': 'Réinitialiser',
  'payments.clearFilters': 'Effacer tous les filtres',
  'place_created.open': 'Ouvrir la fiche du lieu',
  'place_created.desc': 'Le nouveau lieu a été créé et est désormais utilisable.',
  'payment_status.NONE': '-- Aucun filtre --',
  'dropdown.state': 'Statut',
  'payment.create_noExternalId': 'Votre paiement est bien enregistré, cependant il nécessite la validation d\'un superviseur avant de pouvoir être consommé. Un email vous sera envoyé dès que le paiement sera accepté ou refusé.',
  'payment.qrList.title': 'Liste des paiements QR',
  'see.qr_list': 'Voir la liste des codes QR',
  'form.role.partner_registrer': 'Registre des partenaires',
  'form.reason': 'Raison',
  'err.reason.required': 'La raison est requise',
  'global.reject': 'Rejeter',
  'payment.validate_desc': 'Êtes-vous sûr de vouloir valider ce paiement ?',
  'payment.reject_desc': 'Voulez-vous vraiment rejeter ce paiement ? N\'oubliez pas d\'y ajouter une raison.',
  'qr.title': 'Code QR',
  'payments.count.DYN.pass': 'Nombre de paiements: ',
  'dashboard': 'Tableau de bord',
  'dashboard.documents.DYN.pass': 'Demande de document',
  'dashboard.follow_up': 'Suivi des demandes',
  'dashboard.people.DYN.pass': 'Personnes',
  'dashboard.my_account': 'Mon compte',
  'form.refunded_date': 'Date de remboursement',
  'form.enter_message': 'Saisir un message',
  'form.end_date': 'Date de fin',
  'form.start_date': 'Date de début',
  'form.invalid_end_date': 'Date de fin invalide',
  'form.invalid_start_date': 'Date de début invalide',
  'form.id': 'ID',
  'form.enter_date_range': 'Saisir une plage de dates',
  'payments.scan': 'Scanner QR',
  'print.qr.desc': 'Votre code QR unique et sécurisé, ne peut être utilisé que le nombre de fois indiqué. Ne le rayez pas pour qu\'il reste utilisable.',
  'print.qr.title': 'Code QR',
  'print.informations': 'Informations',
  'print.notice.folding': 'Instructions de pliage',
  'print.notice.desc2': 'Pour toute question relative à ce document, veuillez contacter le support par téléphone ou par e-mail.',
  'print.notice.desc1': 'Conservez ce document à l\'abri de l\'eau et des flammes.',
  'print.notice.title': 'Notice',
  'form.search_anything': 'Rechercher tout',
  'form.search': 'Recherche',
  'client.extended.openSuchPayment': 'Un paiement est déjà actif sous cet utilisateur',
  'form.choose_role': 'Choisir le rôle',
  'form.idCard': 'Nr. carte ID',
  'create_card_payment.fail_title': 'Paiement en cours',
  'create_card_payment.fail_text': 'Cela peut prendre un moment. Vous recevrez une notification par email une fois l\'opération terminée. Votre paiement et code QR seront disponibles dans vos Paiements.',
  'err.externalId.required': 'ID externe requis',
  'form.externalId': 'ID externe',
  'global.confirm': 'Confirmer',
  'global.submit': 'Envoyer',
  'global.create': 'Créer',
  'global.back': 'Retour',
  'global.close': 'Fermer',
  'global.contact_support': 'Contacter le support',
  'global.previous': 'Précédent',
  'global.next': 'Suivant',
  'global.accept': 'Accepter',
  'global.under_dev': 'En développement',
  'global.logout': 'Déconnecter',
  'global.support': 'Support',
  'payment_status.undefined': 'Etat actuel - pas une opération',
  'global.consume': 'Consommer',
  'global.validate': 'Valider',
  'global.refund': 'Rembourser',
  'global.duplicate': 'Dupliquer',
  'global.delete': 'Supprimer',
  'global.see_more': 'Voir plus',
  'global.update': 'Mettre à jour',
  'global.modify': 'Modifier',
  'global.download': 'Télécharger',
  'global.personal': 'Personnel',
  'global.personalNum': 'N° personnel',
  'global.passportNum': 'N° de passeport',
  'global.print': 'Imprimer',
  'global.print.a4': 'Imprimer le document A4',
  'global.print_c7': 'Imprimer le Ticket',
  'global.print_a4': 'Imprimer Certificat',
  'global.all': 'Tout',
  'global.not_available': 'Indisponible',
  'global.show_qr': 'QR code',
  'global.show_qr_signature': 'QR code (signé)',
  'global.no_results_for': 'Aucun résultat trouvé pour',
  'global.delete_sure': 'Cette action est irréversible, êtes vous sûr de vouloir supprimer ceci ?',
  'global.add_new': 'Ajouter nouveau',
  'global.add': 'Ajouter',
  'global.permissions': 'Permissions',
  'global.send': 'Envoyer',
  'global.block': 'Bloquer',
  'global.blocked': 'Bloqué',
  'global.unblock': 'Débloquer',
  'global.daily_otp': 'Code journalier',
  'global.refresh': 'Rafraîchir',
  'global.connect': 'Connecter',
  'global.disconnect': 'Déconnecter',
  'form.email': 'Email',
  'form.name': 'Nom',
  'form.firstName': 'Prénom',
  'form.lastName': 'Nom de famille',
  'form.password': 'Mot de passe',
  'form.password2': 'Retaper votre mot de passe',
  'form.userId': 'ID Utilisateur',
  'form.parentId': 'ID parent',
  'form.amount': 'Montant',
  'form.currency': 'Devise',
  'form.metadata': 'Métadonnées',
  'form.buyingOption': 'Document acheté',
  'form.place': 'Lieu',
  'form.terms': 'J\'accepte les conditions d\'utilisation et la politique de confidentialité',
  'form.role': 'Rôle',
  'form.no_data': 'Aucune donnée correspondant au filtre',
  'form.filter': 'Filtres',
  'form.phoneNumbers': 'Numéros de téléphone',
  'form.connectUAPattern': 'Modèle User Agent',
  'form.connectIPRange': 'Gamme d\'IP',
  'form.state': 'Statut',
  'form.type': 'Type',
  'form.operation': 'Opération',
  'form.created_date': 'Date de création',
  'form.created_place': 'Lieu de création',
  'form.consumed_place': 'Lieu de consommation',
  'form.refunded_place': 'Lieu du remboursement',
  'form.email_code': 'Code dans l\'email',
  'form.addToPlace': 'ID de lieu',
  'form.placeToAdd': 'Lieu à ajouter',
  'form.message': 'Message',
  'form.phone': 'Num. de téléphone',
  'form.worker': 'ID d\'employé',
  'form.camera': 'Camera',
  'form.comment': 'Commentaire optionnel',
  'form.password_new2': 'Nouveau mot de passe (encore)',
  'form.type.CASH': 'Espèces',
  'form.type.CARD': 'Carte',
  'form.currency.EUR': 'Euro',
  'form.currency.USD': 'US Dollar',
  'role.customer': 'Client',
  'form.currency.ZAR': 'Rand',
  'form.role.customer': 'Client',
  'form.confirmed': 'Confirmé?',
  'yes': 'Oui',
  'no': 'Non',
  'form.role.worker': 'Employé',
  'form.role.orbis_consumer': 'Consommateur Orbis',
  'form.role.admin': 'Admin',
  'role.partner_registrer': 'Enregistreur de partenaires',
  'form.choose_currency': 'Sélectionner une devise',
  'form.choose_place': 'Sélectionner un lieu',
  'form.choose_type': 'Sélectionner un type',
  'form.choose_buyingOption': 'Sélectionner un document',
  'form.choose_camera': 'Choose camera',
  'form.perm1': 'Créer code QR',
  'form.perm2.DYN.pass': 'Consommer code QR',
  'form.perm2.DYN.zwevisa': 'Consommer une demande de visa',
  'form.perm3.DYN.pass': 'Dupliquer / Rembourser code QR & Z des sous lieux',
  'form.perm3.DYN.zwevisa': 'Approuver ou refuser une demande de visa',
  'form.perm4.DYN.pass': 'Créer / Gérer utilisateurs & Créer sous lieux',
  'form.perm4.DYN.zwevisa': 'Créer/gérer des places, utilisateurs et des demandes de visa',
  'done.password_changed': 'Mot de passe modifié',
  'done.user_created': 'L\'utilisateur a bien été crée',
  'done.place_created': 'Le lieu a bien été créé.',
  'done.account_created': 'Le compte a été crée, vérifiez vos emails pour le valider',
  'client.extended.clientNotAllowed': 'Votre compte ne peut actuellement se connecter à ce lieu. Vérifiez vos paramètres de connection.',
  'err.submit': 'Erreur à l\'envoi du formulaire',
  'err.email.required': 'Email est requis',
  'err.email.email': 'L\'email doit avoir un format correct : email@mail.com',
  'err.password.required': 'Mot de passe est requis',
  'err.password.minlength': 'Le mot de passe doit avoir entre 6 et 20 caractères',
  'err.password.maxlength': 'Le mot de passe doit avoir entre 6 et 20 caractères',
  'err.password2.required': 'Mot de passe (encore) est requis',
  'err.password2.mustMatch': 'Les mots de passe ne correspondent pas',
  'dropdown.pass': 'Scanner un document',
  'err.role.required': 'Rôle est requis',
  'err.name.required': 'Nom est requis',
  'err.firstName.required': 'Prénom est requis',
  'err.lastName.required': 'Nom de famille est requis',
  'err.terms.required': 'Les conditions doivent être acceptées',
  'err.amount.required': 'Montant est requis',
  'err.currency.required': 'Devise est requis',
  'err.metadata.required': 'Métadonnées est requis',
  'err.place.required': 'Lieu est requis',
  'err.noSmsPerm': 'Permission SMS n\'a pas été autorisée',
  'err.noSmsReceive': 'Impossible de se lier à la lecture d\'SMS',
  'err.code.required': 'Code dans l\'email est requis',
  'err.buyingOption.required': 'Option d\'achat est requis',
  'err.addToPlace.required': 'ID de lieu est requis',
  'err.phone.required': 'Num. de téléphone est requis',
  'err.message.required': 'Message est requis',
  'err.text.maxlength': 'Texte trop long',
  'err.old.required': 'Mot de passe actuel est requis',
  'client.extended.missingResourceLogin': 'Impossible de se connecter, mauvais identifiants',
  'client.extended.linkedAccountExists': 'Un compte avec cette adresse email existe déjà',
  'client.extended.badPayload': 'Mauvaise information dans la requête',
  'client.extended.placeNotAllowed': 'Ce lieu n\'est pas autorisé',
  'client.extended.locked': 'Compte bloqué',
  'client.extended.clientBadOtp': 'Mauvais code journalier',
  'time': 'Heure',
  'place': 'Lieu',
  'home.first': 'Votre compte est maintenant validé et vous pouvez vous connecter.',
  'payOnline': 'Payer en ligne',
  'login.sign_in': 'Se connecter',
  'login.sign_up': 'S\'enregistrer',
  'login.forgot_password': 'Mot de passe oublié ?',
  'photo.entryCountry': 'Photo au passage',
  'global.entry': 'Entrée',
  'global.exit': 'Sortie',
  'menu.create_user': 'Utilisateur +',
  'menu.create_place': 'Lieu +',
  'menu.create_payment': 'Paiement +',
  'menu.users': 'Utilisateurs',
  'menu.places': 'Lieux',
  'menu.payments': 'Paiements',
  'menu.about': 'A propos',
  'menu.contact': 'Contact',
  'create_user.title': 'Créer un utilisateur',
  'create_payment.title': 'Créer un paiement',
  'create_place.title': 'Créer un lieu',
  'users.title': 'Tableau des utilisateurs',
  'payments.title': 'Tableau des paiements',
  'payments.employee_graph': 'Graph paiements par employé',
  'payments.open_scan': 'Scanner code QR',
  'payments.download_csv': 'Télécharger CSV',
  'create_card_payment.title': 'Paiement en ligne',
  'create_card_payment.success_title': 'Paiement reçu !',
  'choose-lang.title': 'Préférence de langue',
  'choose-lang.desc': 'Veuillez choisir la langue qui sera utilisée pour cette application.',
  'create_card_payment.success_text': 'Votre paiement a bien été reçu. Votre QR code vous à été envoyé à votre adresse email et est également accessible via le bouton ci-dessous.',
  'create_card_payment.no_data': 'Ce paiement n\'est plus disponible ici, vérifier vos emails pour retrouver le code ou cliquez sur Paiements depuis le menu.',
  'places.title': 'Liste des lieux',
  'forgot_password.title': 'Réinitialiser le mot de passe',
  'forgot_password.title_new': 'Définir un nouveau mot de passe',
  'forgot_password.sent': 'Confirmation envoyée !',
  'forgot_password.sent_text': ' Un email de confirmation vous à été envoyé à votre adresse email avec un lien pour réinitialiser votre mot de passe.',
  'forgot_password.done': 'Le mot de passe à été réinitialisé',
  'forgot_password.done_text': 'Votre mot de passe à bien été réinitialisé. Vous pouvez désormais vous connecter.',
  'welcome.first': 'Votre compte est validé!',
  'welcome.title_1.DYN.pass': 'Bonjour !',
  'welcome.text_1.DYN.pass': 'Merci d\'avoir rejoint {appName}. Nous sommes fiers de fournir le meilleur moyen de convertir votre paiement en un code QR sécurisé et unique.',
  'welcome.text_1.1.DYN.pass': 'Apprenons-en plus sur nous, voulez-vous ?',
  'welcome.title_2.DYN.pass': 'Qu\'est-ce que {appName} ?',
  'welcome.text_2.DYN.pass': '{appName} vous permet de soumettre un paiement en ligne en échange d\'un code QR sécurisé et unique.',
  'welcome.text_2.1.DYN.pass': 'Ce code est ensuite utilisable pour payer un document officiel tel qu\'un passeport, une carte d\'identité, etc.. auprès de votre administration locale.',
  'welcome.title_3.DYN.pass': 'En tant qu\'employé',
  'welcome.text_3.DYN.pass': 'Avec {appName}, un client peut venir vers vous pour payer un document officiel. Vous pouvez enregistrer le paiement par carte ou en espèces.',
  'welcome.text_3.1.DYN.pass': 'Un code QR sécurisé et unique est ensuite généré et prêt à être imprimé pour votre client.',
  'welcome.title_4.DYN.pass': 'Encore mieux',
  'welcome.text_4.DYN.pass': 'Vous pouvez même enregistrer des paiements hors ligne. Nous sommes confiants dans notre manière sûre et fiable de générer nos codes QR.',
  'welcome.text_4.1.DYN.pass': 'Dès que vous serez de nouveau en ligne, tout sera à nouveau synchronisé.',
  'welcome.title_5.DYN.pass': 'Profitez-bien !',
  'welcome.text_5.DYN.pass': 'Merci d\'utiliser {appName} et n\'hésitez pas à nous envoyer vos commentaires en utilisant le bouton Contact sur la page d\'accueil.',
  'payments.make_bundle': 'Créer un paquet',
  'payments.validate': 'Confirmer',
  'payment_status.PROPOSED': 'Proposé',
  'payment_status.PAID': 'Payé',
  'payment_status.USED': 'Consommé',
  'payment_status.DUPLICATED': 'Dupliqué',
  'payment_status.REFUNDED': 'Remboursé',
  'payment_status.EXPIRED': 'Refusé',
  'payment_status.PAID_NOT_CONFIRMED': 'Payé - Non Confirmé',
  'request_state.PROPOSED': 'Proposé',
  'request_state.PAID': 'Payé',
  'request_state.USED': 'Consommé',
  'request_state.DUPLICATED': 'Dupliqué',
  'request_state.REFUNDED': 'Remboursé',
  'request_state.EXPIRED': 'Refusé',
  'request_state.PAID_NOT_CONFIRMED': 'Payé - Non Confirmé',
  'request_state.DELAY_PAY_NOT_CONFIRMED': 'A payer - Non Confirmé',
  'request_state.DELAY_PAY': 'A payer - Confirmé',
  'payment_status.0': 'Proposé',
  'payment_status.1': 'Payé',
  'payment_status.6': 'Payé - Non confirmé',
  'payment_status.8': 'A payer - Non Confirmé',
  'payment_status.9': 'A payer - Confirmé',
  'payment_status.2': 'Consommé',
  'payment_status.3': 'Dupliqué',
  'payment_status.4': 'Remboursé',
  'payment_status.5': 'Refusé',
  'payment.history': 'Historique des paiements',
  'documentContinue.title': 'Continuer votre achat',
  'documentContinue.stop': 'Recommencer votre achat',
  'documentContinue.desc1': 'Vous avez déjà commencé une demande de document.',
  'documentContinue.desc2': 'Souhaitez-vous continuer votre achat en cours ?',
  'permission.no_place_found': 'Vous n\'avez pas de nouveau lieu disponible pour cet utilisateur',
  'bt.available_devices': 'Appareils bluetooth disponibles',
  'bt.no_available_devices': 'Aucun appareil bluetooth détecté',
  'emailSend.title': 'Vérification email',
  'emailSend.description': 'Un email de validation à été envoyé à votre adresse email. Vous avez 24h pour cliquer sur le lien contenu dedans afin de valider votre compte.',
  'emailValid.title': 'Compte validé',
  'emailValid.description': 'Votre compte {appName} a bien été validé. Vous pouvez dès à présent vous connecter à l’aide de vos identifiants pour accéder à votre espace personnel.',
  'documentManagement.title': 'Demande de document',
  'passportPage.select': 'Choisissez la personne qui souhaite faire la demande de passeport.',
  'passportPage.choosePerson': 'Choisir une personne',
  'preenroll.title': 'Pré-enrôlement',
  'passportPage.askPassport': 'Demande de passeport',
  'preenroll.description': 'Le pré-enrôlement vous permet de commencer la première étape avant de finaliser votre document de passeport. Il vous suffira ensuite de vous rendre dans un centre agréé afin de le valider.',
  'passportEmbassy.title': 'Passeport d\'embassade',
  'passportEmbassy.description': 'Un Passeport d’embassade est un passeport délivré par un État souverain à ses diplomates pour leurs déplacements professionnels, ainsi qu\'à des personnes les accompagnant.',
  'zwevisa.title': 'Visa',
  'passwordSend.title': 'Confirmation envoyée',
  'passwordSend.description': 'Un email de confirmation à été envoyé à votre adresse email. Vous avez 24h pour cliquer sur le lien contenu dedans afin de réinitialiser votre mot de passe.',
  'forgotPassword.title': 'Mot de passe oublié',
  'forgotPassword.description': 'Veuillez renseigner l’email du compte ci-dessous afin de commencer la procédure pour réinitialiser votre mot de passe.',
  'passwordNew.title': 'Nouveau mot de passe',
  'modifiedPassword.title': 'Mot de passe modifié',
  'modifiedPassword.description': 'Le mot de passe a été modifié. Veuillez vous reconnecter.',
  'people.title.DYN.pass': 'Personnes',
  'people.addPerson.DYN.pass': 'Ajouter une personne',
  'meansPayment.title': 'Moyen de paiement',
  'onlinePayment.title': 'Paiement via PayPal',
  'onlinePayment.description': 'Payer en ligne avec votre carte bancaire afin de finaliser votre paiement.',
  'onlinePayment.pay': 'Payer',
  'qrPayment.title': 'Paiement par QR code',
  'qrPayment.description': 'Scanner un QR code de paiement valide afin de finaliser votre paiement.',
  'qrPayment.scan': 'Scanner le QR code',
  'processPayment.description': 'Entrez vos données de cartes bancaires afin de réaliser le paiement.',
  'form.cardNumber': 'N° de carte',
  'form.expireDate': 'Date d’expiration',
  'successPayment.title': 'Paiement effectué',
  'successPayment.description1': 'Votre paiement a bien été effectué. Le traitement pour votre document est en cours, vous recevrez une notification lorsque celui-ci sera prêt ou en cas de besoin de révision de la demande.',
  'successPayment.description2': 'Si vous ne recevez pas de notification, veuillez vous vous rendre sur le Support depuis la page de votre compte.',
  'trackingControl.title': 'Suivi des demandes',
  'trackingControl.enroll': 'Prêt pour l\'enrôlement',
  'trackingControl.treatment': 'En cours de traitement',
  'trackingControl.revision': 'Demande de révision',
  'askRevisionProcess': 'Traitement en cours',
  'askRevision': 'Révision demandée',
  'askOrdinaryPassport': 'Demande de passeport ordinaire',
  'askPassport': 'Demande de passeport',
  'askVisa': 'Demande de visa',
  'modifyData': 'Modifier les données',
  'stopModifyData': 'Arrêter de modifier les données',
  'treatmentDone': 'Traitement terminé. Révision demandée',
  'sendRevision': 'Révision envoyée',
  'seeRevision': 'Voir la révision',
  'enrolReady': 'Demande acceptée. Prêt pour l\'enrôlement',
  'actualRevision': 'Révision de votre demande',
  'askRevision.description': 'Afin de répondre à votre demande de Passeport Ordinaire, veuillez nous envoyer un certificat de résidence à jour.',
  'peopleNew.title.DYN.pass': 'Nouvelle personne',
  'peopleFound.title.DYN.pass': 'Personne créée',
  'peopleFound.description.DYN.pass': 'La personne correspondant aux informations que vous avez renseignées a été ajoutée à votre liste de personnes disponibles.',
  'payment.commandHistory': 'Historique des paiements',
  'menu.placesPerm': 'Lieux et permissions',
  'block.message': 'Voulez-vous bloquer cet utilisateur ?',
  'menu.addPlace': 'Ajouter un lieu',
  'label.numberAccount': 'N° de compte',
  'place.sheet.open': 'Ouvrir la fiche du lieu',
  'place.delete.link': 'Supprimer le lien avec ce lieu',
  'place.delete.link.ask': 'Êtes-vous sûr de vouloir supprimer le lien avec ce lieu?',
  'place.delete.link.done': 'Vous avez bien supprimé le lien avec ce lieu',
  'place.update.success': 'Le lieu a bien été mis à jour.',
  'place.delete': 'Êtes-vous sûr de vouloir supprimer ce lieu? Cette action est irréversible.',
  'place.delete.success': 'Ce lieu a bien été supprimé.',
  'place.new': 'Nouveau lieu',
  'place.create': 'Créer le lieu',
  'place.add.ask': 'Voulez-vous lier ce lieu à cette personne ?',
  'place.add.done': 'Vous avez bien ajouté ce lieu à cette personne.',
  'user.new': 'Nouvel utilisateur',
  'user.create': 'Créer l\'utilisateur',
  'user.created': 'Le nouvel utilisateur a été crée avec succès. Ce compte est désormais utilisable.',
  'user.sheet.open': 'Ouvrir la fiche de l\'utilisateur',
  'payment.sheet.open': 'Ouvrir la fiche du paiement',
  'err.createplace': 'Impossible de créer. Id déjà existant.',
  'place.parent': 'Lieu parent',
  'dropdown.sorting': 'Tri',
  'dropdown.qr': 'Scanner un code QR',
  'dropdown.user': 'Utilisateur',
  'dropdown.meansPayment': 'Type de paiement',
  'payment.new.DYN.pass': 'Nouveau paiement',
  'payment.create': 'Créer un paiement',
  'payment.created': 'Votre paiement a bien été enregistré et est désormais visible dans la liste des paiements.',
  'payment.created.register': 'Paiement enregistré',
  'payment.created.qr': 'Un email contenant le code QR généré a été envoyé à l’adresse mail renseignée.',
  'text.status': 'Statut',
  'phone.number': 'N° de téléphone',
  'idcard.number': 'N° de carte d\'identité',
  'form.userCreator': 'Créé par l\'utilisateur',
  'form.userRefund': 'Créé par l\'utilisateur',
  'form.userConsumed': 'Consommé par l\'utilisateur',
  'PRICEUP': 'Prix 0-9',
  'PRICEDOWN': 'Prix 9-0',
  'RECENT': 'Plus récent',
  'LATEST': 'Plus ancien',
  'STATUSUP': 'Statut a-z',
  'STATUSDOWN': 'Statut z-a',
  'payment.success': 'Votre paiement a bien été enregistré',
  'err.idCard.required': 'Num. de carte d\'identité requis',
  'err.meansPayment.required': 'Moyen de paiement requis',
  'payment.consume.ask': 'Voulez-vous vraiment consommer ce paiement ?',
  'payment.consume.done': 'Votre paiement a été consommé avec succès',
  'payment.duplicate.ask': 'Souhaitez-vous vraiment dupliquer ce paiement ?',
  'payment.duplicate.done': 'Votre paiement a été dupliqué avec succès',
  'payment.refund.ask': 'Voulez-vous vraiment rembourser ce paiement ?',
  'payment.refund.done': 'Votre paiement a été remboursé avec succès',
  'payment.refund.exist': 'Ce paiement a déjà été remboursé',
  'payment.paid.error': 'Votre paiement doit avoir le statut \'Payé\'',
  'unlink.place.error': 'Votre compte doit avoir au moins un lieu actif. Veuillez d\'abord rajouter un autre lieu afin de délier celui-ci',
  'block.done': 'Le compte a bien été bloqué',
  'block.unblock': 'Le compte a bien été débloqué',
  'dailyOtp.txt': 'Code de sécurité journalier ',
  'place-add.add': 'Ajout de lieu',
  'permission.title': 'Permission requise: Caméra',
  'permission.desc': 'Le service que vous souhaitez utiliser requiert l\'autorisation d\'utiliser la caméra de votre appareil',
  'qr.see': 'Voir le code QR',
  'qr.desc.DYN.pass': 'Voici le code QR du paiement. Il sera présent lors d\'une impression et a été envoyé à l\'email renseigné lors de son enregistrement.',
  'dashboard.place.verif': 'Vous n\'avez entrer aucun lieu, s\'il vous plais sélectionnez-en un',
  'uptodate': 'Vous êtes à jour',
  'block.yourself': 'Vous ne pouvez-pas vous bloquer vous-même',
  'lastPlace.delete': 'Vous êtes sur le point de supprimer le dernier lieu lié au compte. Cela aura pour effet de rendre ce compte inutilisable. Continuer ?',
  'notallow.payment': 'Vous n\'avez pas les droits de créer un payment',
  'no.connection': 'Vous avez besoin d\'internet pour pouvoir accéder à cette page. Veuillez vous reconnecter',
  'wrong.current': 'Vous avez entrer un mauvais mot de passe actuel',
  'no.place': 'Aucun lieu pour ce compte.',
  'no.payment': 'Aucun paiement ne correspond au filtre actuel',
  'email.error': 'Un email similaire existe déjà',
  'email.notexist': 'L\'email n\'existe pas',
  'one.payment': 'Vous devez avoir au moins un paiement créé et/ou filtré.',
  'err.client.extended.uuidUsed': 'Ce paiement a déjà été consummé',
  'no.activecamera': 'Aucune caméra trouvée',
  'no.accessPermission': 'Ce compte n\'a pas d\'autorisation d\'accès',
  'download.success': 'Votre fichier a bien été téléchargé dans le dossier Téléchargement',
  'print.name': 'Imprimante',
  'print.success': 'Votre ticket a bien été imprimé',
  'print.need': 'Vous devez d\'abord vous connecter à une imprimante pour pouvoir imprimer',
  'printer.connect': 'Vous êtes maintenant connecté à l\'imprimante: ',
  'err.personalNr.required': 'Numéro personnel requis',
  'err.passportNr.required': 'Numéro de passeport requis',
  'createPeople.ask.DYN.pass': 'Voulez-vous vraiment créer cette personne ?',
  'createPeople.success.DYN.pass': 'La personne a bien été créée',
  'deletePerson.DYN.pass': 'Supprimer cette personne',
  'deletePerson.ask.DYN.pass': 'Voulez-vous vraiment supprimer cette personne ?',
  'deletePerson.valid.DYN.pass': 'La personne a bien été supprimée',
  'personModification.ask.DYN.pass': 'Voulez-vous vraiment modifier les données de cette personne ?',
  'personModification.valid.DYN.pass': 'Les données de la personne ont bien été modifiées',
  'noPayment': 'Aucun paiement',
  'selfie.desc': 'Une photo est requise pour la demande du document, veuillez vous assurer de vous trouver dans un bon éclairage et de tenir votre téléphone stable pour éviter une image floue. Assurez-vous également que tous les détails de votre visage sont clairement visibles sur la photo.',
  'selfie.take': 'Prendre la photo',
  'ZWEVISA.title': 'Visa',
  'EMBASSYPASS.title': 'Passeport d\'embassade',
  'PREENROLL.title': 'Pré-enrôlement',
  'pay.temp': 'Paiement actuellement indisponible, veuillez choisir pré-enrôlement',
  'request.0': 'Demande acceptée. Prêt pour l\'enrôlement',
  'request.1': 'Demande acceptée. Traitement en cours.',
  'request.2': 'Demande refusée',
  'request.3': 'Demande refusée. Action nécessaire',
  'request.4': 'En cours de traitement',
  'form.merchantReference': 'Référence du marchand',
  'form.pan': 'Numéro de carte',
  'err.MerchantReference.required': 'Référence du marchand requis',
  'err.PAN.required': 'Numéro de carte requis',
  'err.ExpiryDate.required': 'Date d\'expiration requise',
  'WIP': 'Work in progress',
  'PAYMENT_CREATED': 'Paiement créé',
  'PAYMENT_VALIDATED': 'Paiement validé',
  'menu.requests.DYN.zwevisa': 'Demandes de visa',
  'menu.requests.DYN.pass': 'Requêtes',
  'menu.createRequests.DYN.zwevisa': 'Nouvelle demande de visa',
  'menu.requests.pass': 'Demandes',
  'menu.createRequests.DYN.pass': 'Nouvelle demande',
  'no.data': 'Aucune données ne correspond au filtre actuel',
  'dropdown.requestState': 'Statut de la demande',
  'dropdown.paymentState': 'Statut du paiement',
  'one.request': 'Vous devez avoir au moins une demande visible.',
  'request_state.SUBMITTED': 'Soumis',
  'request_state.CONFIRMED': 'Confirmé',
  'request_state.TREATED': 'Traité',
  'request_state.TRANSFERRED': 'Transféré',
  'request_state.CANCELLED': 'Annulé',
  'request_state.REFUSED': 'Refusé',
  'request_state.UNUSABLE': 'Inutilisable',
  'request_state.NULL': 'Pas payé',
  'request_state.NONE': '-- Aucun filtre --',
  'getPayment.error': 'Une erreur est survenue lors de la récupération du paiement.',
  'adminRequestResult.title.DYN.pass': 'Demande sélectionnée - {paymentID}',
  'form.requestState': 'Statut de la demande',
  'form.paymentType': 'Type de paiement',
  'form.paymentState': 'Statut du paiement',
  'payment.type.PHYSICAL': 'Physique',
  'payment.type.PAYPAL': 'PayPal',
  'payment.type.WU': 'Western Union',
  'payment.type.MNTA': 'CBZ',
  'request.state.DYN.pass': 'Statut de la demande',
  'payment.continue': 'Continuer le paiement',
  'form.birthDate': 'Date de naissance',
  'form.birthCountry': 'Pays de naissance',
  'form.placeOfBirth': 'Ville de naissance',
  'form.residenceAdress': 'Adresse de résidence',
  'form.marialStatus': 'Etat civil',
  'form.dateOfIssue': 'Date de délivrance',
  'form.dateOfExpiry': 'Date d\'expiration',
  'form.placeOfIssue': 'Lieu de délivrance',
  'form.departureDate': 'Date de départ',
  'form.arrivalDate': 'Date d\'arrivée',
  'form.referencePreviousVisa': 'Référence du visa précédent',
  'form.passportNumber': 'Numéro de passeport',
  'form.hostName': 'Nom de l\'hôte',
  'form.purposeOfVisit': 'Raison de la visite',
  'form.addressZimbabwe': 'Adresse au Zimbabwe',
  'form.previousConvictionsZim': 'Condamnations précédentes au Zimbabwe',
  'form.previousConvictionsHome': 'Condamnations précédentes dans le pays de résidence',
  'form.spouse': 'Conjoint',
  'form.SpousePlaceOfBirth': 'Lieu de naissance du conjoint',
  'form.spouse.birthDay': 'Date de naissance du conjoint',
  'form.spousePassportNumber': 'Numéro de passeport du conjoint',
  'form.spousePlaceOfBirth': 'Lieu de naissance du conjoint',
  'form.dependants': 'Personnes à charge',
  'form.dependantFullName': 'Nom complet de la personne à charge',
  'form.dependantPassportNumber': 'Numéro de passeport de la personne à charge',
  'form.dependantPlaceOfBirth': 'Lieu de naissance de la personne à charge',
  'form.dependantBirthDay': 'Date de naissance de la personne à charge',
  'global.approve': 'Approuver',
  'global.refuse': 'Refuser',
  'global.unprocess': 'Détraiter',
  'personal.data': 'Données personnelles',
  'contact.data': 'Données de contact',
  'divers.data.DYN.zwevisa': 'Détails du voyage',
  'divers.data.DYN.pass': 'Détails divers',
  'dependant.data': 'Personnes à charge',
  'spouse.data': 'Données du conjoint',
  'paymentState.0': 'Proposé',
  'paymentState.1': 'Payé',
  'paymentState.2': 'Consommé',
  'paymentState.3': 'Dupliqué',
  'paymentState.4': 'Remboursé',
  'paymentState.5': 'Refusé',
  'paymentState.6': 'Payé - Non confirmé',
  'payment.refuse.ask': 'Voulez-vous vraiment refuser ce paiement ?',
  'payment.validate.ask': 'Voulez-vous vraiment approuver ce paiement ?',
  'payment.refuse.success': 'Le paiement a bien été refusé.',
  'payment.suspend.success': 'Le paiement a bien été suspendu. Une demande de révision a été envoyée au demandeur.',
  'payment.validate.success': 'Le paiement a bien été approuvé.',
  'error.payment.refuse.error': 'Une erreur est survenue lors du refus du paiement.',
  'error.payment.suspend.error': 'Une erreur est survenue lors de la suspension du paiement.',
  'error.payment.validate.error': 'Une erreur est survenue lors de l\'approbation du paiement.',
  'ask.disconnect': 'Êtes-vous sûr de vouloir vous déconnecter ?',
  'global.yes': 'Oui',
  'global.no': 'Non',
  'global.Yes': 'Oui',
  'global.No': 'Non',
  'visa.type.holiday_visa': 'Visa pour vacances',
  'visa.type.business': 'Visa d\'affaires',
  'visa.type.conferencing': 'Visa pour conférences',
  'visa.type.transit': 'Visa de transit',
  'visa.type.student': 'Visa étudiant',
  'visa.entry.single': 'Entrée unique',
  'visa.entry.double': 'Double entrée',
  'visa.entry.kaza': 'Kaza Univisa',
  'applicant.gender.male': 'Homme',
  'applicant.gender.female': 'Femme',
  'applicant.gender.other': 'Autre',
  'marital.status.single': 'Célibataire',
  'marital.status.married': 'Marié(e)',
  'marital.status.divorced': 'Divorcé(e)',
  'marital.status.widowed': 'Veuf(ve)',
  'photo.entryContry': 'Photo d\'entrée dans le pays',
  'documents': 'Documents',
  'photo.passport': 'Photo du passeport',
  'data.biographic': 'Page biographiques du passeport',
  'residence.proof': 'Preuve de résidence',
  'address.destination': 'Adresse de destination',
  'previous.visa': 'Visa précédent',
  'invite.letter': 'Lettre d\'invitation',
  'proof.residence.zim': 'Preuve de résidence au Zimbabwe',
  'residential.status': 'Statut résidentiel',
  'biometric.data': 'Données biométriques',
  'hostInvitation.letter': 'Lettre d\'invitation de l\'hôte',
  'proof.residenceHost': 'Preuve de résidence de l\'hôte',
  'hostResidential.status': 'Statut résidentiel de l\'hôte',
  'reasonOfVisit.proof': 'Raison de la visite du pays de destination',
  'inviteBusiness.letter': 'Lettre commerciale de la société d\'envoi',
  'business.letter': 'Lettre commerciale',
  'business.profile': 'Profil professionnel du voyageur',
  'application.letter': 'Lettre de demande de visa',
  'acceptanceFromScool.letter': 'Lettre d\'acceptation de l\'école',
  'date.creation': 'Date de création',
  'document.Passport': 'Passeport',
  'completeName': 'Nom complet',
  'price': 'Prix',
  'global.retry': 'Réessayer',
  'err.loginWeak': 'Problème de connection, veuillez réessayer',
  'URGENTPASS.title': 'Passeport urgent',
  'refusalReason': 'Raison du refus',
  'error.payment.noRefuseReason': 'Veuillez renseigner une raison de refus. Cette raison sera envoyée au demandeur.',
  'error.payment.noSuspendReason': 'Veuillez renseigner une raison de suspension. Cette raison sera envoyée au demandeur.',
  'refusal.desc': 'Message...',
  'err.getUsers: client.extended.notActiveToken': 'Votre session a expiré. Veuillez vous reconnecter',
  'forgetPassword.success': 'La réinitialisation de votre mot de passe a bien été effectuée. Vous pouvez désormais vous connecter.',
  'VISA_DOUBLE.title': 'Visa double',
  'VISA_KAZA.title': 'Visa Kaza',
  'history': 'Historique',
  'noPaymentFilter': 'Aucune demande en cours',
  'menu.request': 'Demandes',
  'menu.tracking': 'Suivi des demandes',
  'menu.people.DYN.pass': 'Personnes',
  'DYN.AGO': 'Angola',
  'DYN.ABW': 'Aruba',
  'DYN.ATG': 'Antigua & Barbuda',
  'DYN.BHS': 'Bahamas',
  'DYN.BRB': 'Barbade',
  'DYN.BLZ': 'Belize',
  'DYN.BWA': 'Botswana',
  'DYN.CYM': 'Îles Caïmans',
  'DYN.CYP': 'Chypre',
  'DYN.FJI': 'Fidji',
  'DYN.COD': 'République démocratique du Congo',
  'DYN.GHA': 'Ghana',
  'DYN.GRD': 'Grenade',
  'DYN.HKG': 'Région administrative spéciale de Hong Kong (Chine)',
  'DYN.KEN': 'Kenya',
  'DYN.KIR': 'Kiribati',
  'DYN.JAM': 'Jamaïque',
  'DYN.LDW': 'Îles Sous-le-Vent',
  'DYN.LSO': 'Lesotho',
  'DYN.MDG': 'Madagascar',
  'DYN.MWI': 'Malawi',
  'DYN.MYS': 'Malaisie',
  'DYN.MDV': 'Maldives',
  'DYN.MLT': 'Malte',
  'DYN.MUS': 'Île Maurice',
  'DYN.MSR': 'Montserrat',
  'DYN.MOZ': 'Mozambique',
  'DYN.NAM': 'Namibie',
  'DYN.NRU': 'Nauru',
  'DYN.LCA': 'Sainte-Lucie',
  'DYN.VCT': 'Saint-Vincent-et-les-Grenadines',
  'DYN.WSM': 'Samoa occidental',
  'DYN.SYC': 'Seychelles',
  'DYN.SGP': 'Singapour',
  'DYN.SLB': 'Îles Salomon',
  'DYN.ZAF': 'Afrique du Sud',
  'DYN.KNA': 'Saint-Christophe-et-Niévès',
  'DYN.SWZ': 'Swaziland',
  'DYN.TON': 'Tonga',
  'DYN.TTO': 'Trinité-et-Tobago',
  'DYN.TCA': 'Îles Turques-et-Caïques',
  'DYN.TUV': 'Tuvalu',
  'DYN.UGA': 'Ouganda',
  'DYN.TZA': 'République unie de Tanzanie',
  'DYN.VUT': 'Vanuatu',
  'DYN.VAT': 'Vatican',
  'DYN.VEN': 'Venezuela (République bolivarienne du)',
  'DYN.USA': 'États-Unis d\'Amérique',
  'DYN.URY': 'Uruguay',
  'DYN.UZB': 'Ouzbékistan',
  'DYN.UKR': 'Ukraine',
  'DYN.ARE': 'Émirats arabes unis',
  'DYN.GBR': 'Royaume-Uni de Grande-Bretagne et d\'Irlande du Nord',
  'DYN.TUR': 'Turquie',
  'DYN.TKM': 'Turkménistan',
  'DYN.SWE': 'Suède',
  'DYN.CHE': 'Suisse',
  'DYN.TJK': 'Tadjikistan',
  'DYN.KAZ': 'Kazakhstan',
  'DYN.SUR': 'Surinam',
  'DYN.ESP': 'Espagne',
  'DYN.SVK': 'Slovaquie',
  'DYN.SVN': 'Slovénie',
  'DYN.SEN': 'Sénégal',
  'DYN.SRB': 'Serbie',
  'DYN.SMR': 'Saint-Marin',
  'DYN.STP': 'Sao Tomé et Principe',
  'DYN.NLD': 'Pays-Bas',
  'DYN.NCL': 'Nouvelle-Calédonie',
  'DYN.NZL': 'Nouvelle-Zélande',
  'DYN.NIC': 'Nicaragua',
  'DYN.MEX': 'Mexique',
  'DYN.FSM': 'Micronésie, États fédérés de',
  'DYN.MDA': 'Moldavie',
  'DYN.MCO': 'Monaco',
  'DYN.MHL': 'Îles Marshall',
  'DYN.LVA': 'Lettonie',
  'DYN.NFK': 'Îles Norfolk',
  'DYN.MNP': 'Îles Mariannes du Nord',
  'DYN.NOR': 'Norvège',
  'DYN.PLW': 'Île Palau',
  'DYN.PSE': 'Palestine',
  'DYN.PAN': 'Panama',
  'DYN.PNG': 'Papouasie-Nouvelle-Guinée',
  'DYN.PRY': 'Paraguay',
  'DYN.PER': 'Pérou',
  'DYN.POL': 'Pologne',
  'DYN.PRT': 'Portugal',
  'DYN.PRI': 'Porto Rico',
  'DYN.REU': 'Réunion',
  'DYN.ROU': 'Roumanie',
  'DYN.RUS': 'Fédération de Russie',
  'DYN.RWA': 'Rwanda',
  'DYN.LIE': 'Liechtenstein',
  'DYN.LTU': 'Lituanie',
  'DYN.LUX': 'Luxembourg',
  'DYN.MAC': 'Région administrative spéciale de Macao (Chine)',
  'DYN.JPN': 'Japon',
  'DYN.KWT': 'Koweït',
  'DYN.KGZ': 'Kirghizistan',
  'DYN.KOR': 'Corée du Sud',
  'DYN.GUY': 'Guyana',
  'DYN.HTI': 'Haïti',
  'DYN.HND': 'Honduras',
  'DYN.IRL': 'Irlande',
  'DYN.ISR': 'Israël',
  'DYN.ITA': 'Italie',
  'DYN.HUN': 'Hongrie',
  'DYN.ISL': 'Islande',
  'DYN.IND': 'Inde',
  'DYN.IDN': 'Indonésie',
  'DYN.IRN': 'Iran',
  'DYN.GUM': 'Guam',
  'DYN.GTM': 'Guatemala',
  'DYN.GIB': 'Gibraltar',
  'DYN.GRC': 'Grèce',
  'DYN.GEO': 'Géorgie',
  'DYN.DEU': 'Allemagne',
  'DYN.DNK': 'Danemark',
  'DYN.EST': 'Estonie',
  'DYN.ETH': 'Éthiopie',
  'DYN.FIN': 'Finlande',
  'DYN.FRA': 'France',
  'DYN.GUF': 'Guyane française',
  'DYN.PYF': 'Polynésie française',
  'DYN.ATF': 'Antilles françaises',
  'DYN.CZE': 'République tchèque',
  'DYN.COM': 'Comores',
  'DYN.DOM': 'République dominicaine',
  'DYN.ECU': 'Équateur',
  'DYN.EGY': 'Égypte',
  'DYN.SLV': 'Salvador',
  'DYN.GNQ': 'Guinée équatoriale',
  'DYN.CHL': 'Chili',
  'DYN.CHN': 'Chine',
  'DYN.CHN_NT': 'Chine (non touristique)',
  'DYN.COK': 'Îles Cook',
  'DYN.CRI': 'Costa Rica',
  'DYN.HRV': 'Croatie',
  'DYN.CUB': 'Cuba',
  'DYN.BDI': 'Burundi',
  'DYN.CAN': 'Canada',
  'DYN.CPV': 'Cap-Vert',
  'DYN.BRA': 'Brésil',
  'DYN.VGB': 'Îles Vierges britanniques',
  'DYN.BRN': 'Brunéi Darussalam',
  'DYN.BGR': 'Bulgarie',
  'DYN.BMU': 'Bermudes',
  'DYN.BTN': 'Bhoutan',
  'DYN.BIH': 'Bosnie-Herzégovine',
  'DYN.BLR': 'Biélorussie',
  'DYN.BEL': 'Belgique',
  'DYN.BHR': 'Bahreïn',
  'DYN.ARG': 'Argentine',
  'DYN.ARM': 'Arménie',
  'DYN.AUS': 'Australie',
  'DYN.AUT': 'Autriche',
  'DYN.AZE': 'Azerbaïdjan',
  'DYN.ALB': 'Albanie',
  'DYN.DZA': 'Algérie',
  'DYN.AIA': 'Anguilla',
  'DYN.BGD': 'Bangladesh',
  'DYN.BEN': 'Bénin',
  'DYN.AFG': 'Afghanistan',
  'DYN.BOL': 'Bolivie',
  'DYN.BFA': 'Burkina Faso',
  'DYN.KHM': 'Cambodge',
  'DYN.CMR': 'Cameroun',
  'DYN.CIV': "Côte d'Ivoire",
  'DYN.CAF': 'République centrafricaine',
  'DYN.TCD': 'Tchad',
  'DYN.COL': 'Colombie',
  'DYN.ERI': 'Érythrée',
  'DYN.COG': 'Congo-Brazzaville',
  'DYN.PRK': 'République populaire démocratique de Corée',
  'DYN.GAB': 'Gabon',
  'DYN.GMB': 'Gambie',
  'DYN.DJI': 'République de Djibouti',
  'DYN.GIN': 'Guinée-Conakry',
  'DYN.GNB': 'Guinée-Bissau',
  'DYN.IRQ': 'Irak',
  'DYN.KOS': 'Kosovo',
  'DYN.LAO': 'République démocratique populaire lao',
  'DYN.JOR': 'Jordanie',
  'DYN.LBR': 'Libéria',
  'DYN.LBY': 'Jamahiriya arabe libyenne',
  'DYN.MLI': 'Mali',
  'DYN.QAT': 'Qatar',
  'DYN.REF': 'Réfugié',
  'DYN.PHL': 'Philippines',
  'DYN.OMN': 'Oman',
  'DYN.PAK': 'Pakistan',
  'DYN.LBN': 'Liban',
  'DYN.MRT': 'Mauritanie',
  'DYN.MNG': 'Mongolie',
  'DYN.MNE': 'Monténégro',
  'DYN.NER': 'Niger',
  'DYN.NGA': 'Nigéria',
  'DYN.MMR': 'Myanmar',
  'DYN.MAR': 'Maroc',
  'DYN.NPL': 'Népal',
  'DYN.SAU': 'Arabie saoudite',
  'DYN.SLE': 'Sierra Leone',
  'DYN.SOM': 'Somalie',
  'DYN.LKA': 'Sri Lanka',
  'DYN.SSD': 'Soudan du Sud',
  'DYN.SDN': 'Soudan',
  'DYN.THA': 'Thaïlande',
  'DYN.MKD': 'Ancienne République yougoslave de Macédoine',
  'DYN.TGO': 'Togo',
  'DYN.SYR': 'République arabe syrienne',
  'DYN.TWN': 'Taïwan',
  'DYN.TUN': 'Tunisie',
  'DYN.VNM': 'Vietnam',
  'DYN.YEM': 'Yémen',
  'DYN.TLS': 'Timor oriental',
  'DYN.AND': 'Andorre',
  'DYN.VIR': 'Îles Vierges des États-Unis',
  'DYN.XKX': 'Kosovo',
  'DYN.XXA': 'Apatride',
  'DYN.XXB': 'Réfugié (Convention de Genève)',
  'DYN.XXC': 'Réfugié (Autre)',
  'DYN.UNA': 'Agence des Nations Unies',
  'DYN.UNO': 'Organisation des Nations Unies',
  'err.Occupation.pattern': 'Occupation doit avoir un format correc',
  'err.PreviousConvictions.pattern': 'Condamnations précédentes doit avoir un format correct',
  'err.PlaceOfBirth.pattern': 'Lieu de naissance doit avoir un format correct',
  'PAYMENT_USED': 'Paiement consommé',
  'document.type.DYN.pass': 'Type de document',
  'filterPayment.error': 'Une erreur est survenue lors du filtre des demandes. Voulez-vous réessayer ?',
  'filterPaymentNoFirst.error': 'Une erreur est survenue lors de la récupération des demandes. Voulez-vous réessayer ?',
  'dependantIndex': 'Personne à charge {index}',
  'form.spouseFullName': 'Nom complet du conjoint',
  'err.pay': 'Une erreur est survenue lors du paiement. Veuillez réessayer.',
  'payment_status.DYN.zwevisa.0': 'Brouillon',
  'payment_status.DYN.zwevisa.1': 'Complet',
  'payment_status.DYN.zwevisa.6': 'Payé - Non Confirmé',
  'payment_status.DYN.zwevisa.7': 'En Attente',
  'payment_status.DYN.zwevisa.8': 'Payer Plus Tard - Non Confirmé',
  'payment_status.DYN.zwevisa.9': 'Payer Plus Tard - Confirmé',
  'payment_status.DYN.pass.9': 'Payer Plus Tard - Confirmé',
  'payment_status.DYN.zwevisa.2': 'Clôturé',
  'payment_status.DYN.zwevisa.3': 'Dupliqué',
  'payment_status.DYN.zwevisa.4': 'Remboursé',
  'payment_status.DYN.zwevisa.5': 'Refusé',
  'payment_status.DYN.pass.0': 'Proposé',
  'payment_status.DYN.pass.1': 'Payé',
  'payment_status.DYN.pass.6': 'Non confirmé',
  'payment_status.DYN.pass.2': 'Utilisé',
  'payment_status.DYN.pass.3': 'Dupliqué',
  'payment_status.DYN.pass.4': 'Remboursé',
  'payment_status.DYN.pass.5': 'Expiré',
  'payment_status.DYN.pass.PROPOSED': 'Proposé',
  'payment_status.DYN.pass.PAID': 'Payé',
  'payment_status.DYN.pass.PAID_NOT_CONFIRMED': 'Non confirmé',
  'payment_status.DYN.pass.USED': 'Utilisé',
  'payment_status.DYN.pass.DUPLICATED': 'Dupliqué',
  'payment_status.DYN.pass.REFUNDED': 'Remboursé',
  'payment_status.DYN.pass.EXPIRED': 'Expiré',
  'session.expired': 'Votre session a expiré. Veuillez vous reconnecter.',
  'createPeople.error.DYN.pass': 'Une erreur est survenue lors de la création de la personne.',
  'createPeople.done.DYN.pass': 'La personne a bien été créée.',
  'ask.place.updatePermissions': 'Voulez-vous vraiment mettre à jour les permissions de ce lieu ?',
  'place.updatePermissions.success': 'Les permissions du lieu ont bien été mises à jour.',
  'err.place.updatePermissions': 'Une erreur est survenue lors de la mise à jour des permissions du lieu.',
  'err.email.maxlength': 'L\'email ne doit pas dépasser 50 caractères',
  'err.password2.maxlength': 'Le mot de passe doit avoir entre 6 et 20 caractères',
  'err.name.maxlength': 'Le nom ne doit pas dépasser 50 caractères',
  'err.id.maxlength': 'L\'ID ne doit pas dépasser 50 caractères',
  'err.userId.maxlength': 'L\'ID utilisateur ne doit pas dépasser 50 caractères',
  'err.phoneNumbersCsv.maxlength': 'Le numéro de téléphone ne doit pas dépasser 20 caractères',
  'err.phoneNumbersCsv.required': 'Le numéro de téléphone est requis',
  'err.idParent.required': 'L\'ID parent est requis',
  'global.noFilter': '-- Aucun filtre --',
  'err.externalId.maxlength': 'L\'ID externe ne doit pas dépasser 60 caractères',
  'payment.consume.ask.DYN.pass': 'Voulez-vous vraiment consommer ce paiement ?',
  'payment.consume.done.DYN.pass': 'Votre paiement a été consommé avec succès',
  'application.id': 'ID de la demande',
  'payment.reject_reason': 'Raison du refus',
  'payment.reject.success': 'Le paiement a bien été refusé.',
  'err.fileSize': 'La taille du fichier est trop grande. La taille maximale est : {size}',
  'err.image.required ': 'Une image est requise',
  'scan.birthCertificate': 'Upload du scan de l\'acte de naissance',
  'err.scanBirthCertificate.required': 'Le scan de l\'acte de naissance est requis',
  'document.zafembpass': 'Passeport d\'ambassade ZAF',
  'document.screen': 'Vérification',
  'document.pass': 'Passeport',
  'preenroll': 'Pré-enrôlement',
  'screen': 'Screening',
  'pass': 'Passeport',
  'zafembpass': 'Passeport d\'ambassade ZAF',
  'form.externalCode': 'Code externe'
};

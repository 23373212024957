<div id="print">
  <div class="print_content">
    <div class="flex f_between mt_40 mb_30">
      <div class="flex f_align_start gap_10">
        <div class="f_col f_align_c">
          <img src="https://st-public.s3.eu-central-1.amazonaws.com/public+images/PayCode/zim_immigration_logo.png" width="80px" alt="">
        </div>
        <div class="mt_10">
          <img src="https://st-public.s3.eu-central-1.amazonaws.com/public+images/PayCode/eVisa_logo_text_dk.png" width="56px" alt="">
        </div>
        <div class="f_col f_align_c">
          <img src="https://st-public.s3.eu-central-1.amazonaws.com/public+images/PayCode/zimbabwe_logo.png" width="80px" alt="">
        </div>
      </div>
      <div class="f_col f_align_end print_header">
        <span>{{data.vendorName}}</span>
        <span>{{data.vendorAddress}}</span>
        <span>{{data.vendorEmail}}</span>
        <span *ngIf="data.placePhone">{{data.placePhone}}</span>
      </div>
    </div>
  </div>
  <div class="mt_40">
    <h3 class="text_center"><strong>The Zimbabwe Immigration Act [Chapter 4: 02]<br>
      The Immigration Regulations SI 195/98</strong></h3>
  </div>
  <div [style.border]="'1px solid black'" class="mb_10"></div>
  <div class="flex f_end">
    <strong>{{data.hqRef}}</strong>
  </div>
  <div class="f_col f_align_c">
    <h2><strong>APPROVAL</strong></h2>
    <h4>ADDRESS: {{data.address}}</h4>
    <h4>{{data.date}}</h4>
    <h4>NAME: {{data.name}}</h4>
    <h4>APPLICATION REFERENCE: {{data.documentType}}</h4>
    <h4 *ngIf="data.paymentRef">PAYMENT REFERENCE: {{data.paymentRef}}</h4>
  </div>
  <div class="flex f_center">
    <div [ngStyle]="{'border': '1px solid black', 'border-radius': '10px', 'padding': '14px 20px'}" class="text_center">
      <span [ngStyle]="{'font-family': 'monospace, sans-serif', 'font-size': '1.2rem'}">APPLICATION TRACKING CODE:<br>
        <strong>{{data.applicationTrackingCode}}</strong></span>
    </div>
  </div>
  <div class="f_col f_align_c mt_10">
    <h4>Your visa application has been approved.</h4>
    <h4>Kindly present the QR code attached below upon your arrival at a Port of Entry (PoE).</h4>
    <h3><strong><u>CHIEF DIRECTOR OF IMMIGRATION</u></strong></h3>
  </div>
  <div class="flex f_center mt_40">
    <qrcode *ngIf="qrCode" [qrdata]="qrCode" [margin]="0" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</div>

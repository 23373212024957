import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-user-created',
  templateUrl: './user-created.component.html',
  styleUrls: ['./user-created.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserCreatedComponent{

  userId: number;
  constructor(public nav: NavigateService, private route: ActivatedRoute) {
    this.userId = +this.route.snapshot.paramMap.get('userId');
  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../shared/models/user';
import {ApiService} from '../../../shared/services/api.service';
import {PlacesService} from '../../../shared/services/places.service';

@Component({
  selector: 'app-places-choice',
  templateUrl: './places-choice.component.html',
  styleUrls: ['./places-choice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlacesChoiceComponent implements OnInit {

  userId: number;

  constructor(public nav: NavigateService,
              public router: Router,
              public places: PlacesService,
              private api: ApiService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.userId = +this.route.snapshot.paramMap.get('userId');
    this.api.user(this.userId).then((res: User) => {
      this.places.user = res;
      this.places.getAllPlacesByPerson(res).catch(err => console.log(err));
    }).catch(err => console.log(err));
  }

}

import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEventType
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiService} from '../services/api.service';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {

  constructor(private api: ApiService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Seulement intercepter si la requête contient un corps (POST, PUT, etc.)
    const isUpload = req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH';
    const isDownload = req.method === 'GET';
    if(isUpload || isDownload) {
      // Cloner la requête pour ajouter l'option `reportProgress`
      const clonedReq = req.clone({reportProgress: isUpload});

      return next.handle(clonedReq).pipe(tap((event: HttpEvent<any>) => {
        // Vérifier les différents types d'événements
        switch(event.type) {
        case HttpEventType.UploadProgress:
          // Calculer le pourcentage de l'upload
          if(event.total) {
            const progress = Math.round((100 * event.loaded) / event.total);
            this.api.updateProgress(req.url, progress, isUpload);
          }
          break;
        case HttpEventType.Response:
          // Réinitialiser la progression à 100% une fois que la réponse est reçue
          this.api.updateProgress(req.url, 100, isUpload);
          setTimeout(() => {
            this.api.resetProgress(req.url, isUpload);
          }, 700);
          break;
        case HttpEventType.Sent:
          // Initialisation de la progression
          this.api.updateProgress(req.url, 0, isUpload);

          // Réinitialiser la progression après 10 secondes si elle existe encore
          setTimeout(() => {
            if(this.api.hasProgress(req.url, isUpload)) {
              this.api.resetProgress(req.url, isUpload);
            }
          }, 5000);
          break;
        case HttpEventType.DownloadProgress:
          // Calculer le pourcentage du download
          if(event.total) {
            const progress = Math.round((100 * event.loaded) / event.total);
            this.api.updateProgress(req.url, progress, isDownload);
          }
          break;
        }
      }));
    } else {
      // Si ce n'est pas une requête avec un corps, on laisse passer sans changement
      return next.handle(req);
    }
  }
}

import {ServerInfo} from '../models/user';
import {toCamelCase} from './string';

enum BuyingOption {
  SCREENING = 'PASSPORT APPLICATION USD20',
  PASSPORT = 'PASSPORT USD100',
  URGENT = 'PASSPORT USD200'
}

export function parseMetadata(m: string): any {
  try {
    const md = JSON.parse(m);
    try {
      md.buyingOption = JSON.parse(md.buyingOption);
    } catch {}
    return md;
  } catch {
    try {
      let doc: BuyingOption;
      switch(true) {
        case m.includes(BuyingOption.SCREENING): doc = BuyingOption.SCREENING; break;
        case m.includes(BuyingOption.PASSPORT): doc = BuyingOption.PASSPORT; break;
        case m.includes(BuyingOption.URGENT): doc = BuyingOption.URGENT; break;
      }

      const split = m.split(' '+ doc +' ');
      const split1 = split[0].split(' ');
      const split2 = split[1].split(' ');
      const arr = split1.concat(split2);
      arr[0] = doc;

      const data = {
        buyingOption: arr[0],
        worker: arr[1],
        place_id: arr[2],
        email: arr[3].toLowerCase(),
        ref: arr[4],
        longName: arr.slice(5, arr.length + 1).join(' ')
      };
      return data;
    } catch {
      return {metadata: m, buyingOption: '{}'};
    }
  }
}


export const parseJson = (jsonString: string) => JSON.parse(jsonString);

export const isJsonParsable = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

export const parseIndexedData = (data: string, documentType: string, serverInfo: ServerInfo) => {
  const indexedFields = serverInfo.paymentRequests.find((request: any) => request.id === documentType).indexedFields;
  const values = data.split('|');
  const result: Record<string, string> = {};
  for(let i = 0; i < indexedFields.length; i++) {
    const fieldNameInCamelCase = toCamelCase(indexedFields[i]);
    result[fieldNameInCamelCase] = values[i];
  }
  return result;
};
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from 'src/app/shared/services/api.service';
import {Router} from '@angular/router';
import {
  I18nLang,
  LangService,
  NavigateService
} from 'ngx-satoris';
import * as langs from 'ngx-satoris';
import {environment} from 'src/environments/environment';

declare const window: any;

@Component({
  selector: 'app-choose-lang',
  templateUrl: './choose-lang.component.html',
  styleUrls: ['./choose-lang.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseLangComponent implements OnInit {
  systemLangs: I18nLang[] = (langs as any).I18nLang;
  languages: {value: any, label: any}[] = [];
  data : { fromRoute: string };

  constructor(public api: ApiService,
              public nav: NavigateService,
              private  router: Router,
              private lang: LangService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    const supportedLanguages = this.api.env.supportedLanguages;
    this.languages = Object.keys(this.systemLangs)
      .filter(v => isNaN(Number(v)))
      .map(l => ({
        label: 'form.lang.' + l,
        value: this.systemLangs[l as keyof typeof this.systemLangs]
      }));
    this.languages = this.languages.filter(l => supportedLanguages.includes(l.value));
  }

  changeLang(l: I18nLang) {
    this.lang.setLang(l);
    try {
      window.updateGlpi(this.lang.langType(l).toLowerCase(), undefined, environment.faqSource, environment.appVersion);
    } catch { /* empty */ }

    this.nav.to(this.data.fromRoute);
  }
}

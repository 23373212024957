
<div id="layout" class="f_col">
  <app-view-title fromRoute="password-send" [chooseLangBtn]="true">{{'forgotPassword.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <div *ngIf="!email">
        <app-view-deco-image imageUrl="assets-projects/images/image_forgot_password.svg"></app-view-deco-image>
        <p class="text_center" [innerHTML]="(internal ? 'forgotPassword.description.electron' :  'forgotPassword.description') | translate"></p>
        <app-input class="mt_10" type="email" formCtrlName="email" [formGrp]="form" label="form.email"></app-input>
          <app-errors-list [dark]="true" *ngIf="submitted"
                           [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
      </div>
      <div *ngIf="email">
        <app-view-deco-image imageUrl="assets-projects/images/image_new_password.svg"></app-view-deco-image>
        <form id="form" [formGroup]="formRecover" autocomplete="off">
          <app-input type="password" formCtrlName="password"
                     [formGrp]="formRecover" label="form.password" class="mt_24" (output)="checkPswdStrength($event)" [autocomplete]="false"></app-input>
          <div class="pswd_strength mt_4">
            <span class="pswd_strength_bar" *ngIf="pswdStrength >= 20"></span>
            <span class="pswd_strength_bar" *ngIf="pswdStrength >= 40"></span>
            <span class="pswd_strength_bar" *ngIf="pswdStrength >= 70"></span>
            <span class="pswd_strength_bar" *ngIf="pswdStrength >= 90"></span>
          </div>
          <app-input class="mb_20 mt_24" type="password" formCtrlName="password2"
                     [formGrp]="formRecover" label="form.password2" [autocomplete]="false"></app-input>
          <app-input class="mt_24" type="text" formCtrlName="code"
                     [formGrp]="formRecover" label="form.email_code" [autocomplete]="false"></app-input>
          <app-errors-list [dark]="true" *ngIf="submitted"
                           [formErrors]="this.forms.getFormErrors(this.formRecover)"></app-errors-list>
        </form>
      </div>
      <div class="resp_minSize_l mt_20">
        <app-button size="sm" type="button" (click)="email ? changePassword() : sendCodeEmail()" class="fullWidth">{{'global.confirm' | translate}}</app-button>
        <app-button size="sm" color="transparent" border="true" class="fullWidth mt_10" (btnClicked)="nav.to('sign-in')">{{'global.back' | translate}}</app-button>
      </div>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="arrow-left" [navTo]="{route: 'sign-in'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button type="button" (click)="email ? changePassword() : sendCodeEmail()" class="fullWidth">{{'global.confirm' | translate}}</app-button>
    </div>
  </div>
</div>

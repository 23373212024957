<div id="print">
  <div class="print_content">
    <img class="print-logo" width="100" src="assets-projects/logo-full.png" alt="Logo">
    <app-paragraph textAlign="center" [innerhtml]="title" class="text_center print-title"></app-paragraph>
    <div *ngFor="let group of dataList">
      <h3 class="text_center group-subtitle">{{ group.subtitle }}</h3>
      <table class="fullWidth">
        <tbody>
          <tr *ngFor="let item of group.data">
            <td>
              <b>{{ item[0] }}</b>
            </td>
            <td>{{ item[1] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'payment.new.DYN.' + api.env.type | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper" [class.resp_row]="api.env.type === DocType.PASS">
    <div class="resp_scroll-wrapper_l" *ngIf="api.env.type === DocType.PASS">
      <app-view-deco-image imageUrl="assets-projects/images/image_payment.svg"></app-view-deco-image>
    </div>
    <div class="resp_scroll-wrapper_l" [class.resp_c-50]="api.env.type === DocType.ZWEVISA">
      <form id="form" [formGroup]="form" class="pt_20">
        <app-input *ngIf="api.env.type === DocType.PASS" class="mb_30" icon="user" type="select" formCtrlName="buyingOption" [formGrp]="form" label="{{'form.buyingOption' | translate}}" [selectOptions]="documentType" (change)="changeSelect($event)"></app-input>
        <div *ngIf="api.env.type === DocType.ZWEVISA">
          <app-input class="mb_30" icon="user" type="text" formCtrlName="lastName" [formGrp]="form" label="{{'form.lastName' | translate}}"></app-input>
          <app-input class="mb_30" icon="user" type="text" formCtrlName="firstName" [formGrp]="form" label="{{'form.firstName' | translate}}"></app-input>
          <app-input class="mb_30" icon="hashtag" type="text" formCtrlName="passportNr" [formGrp]="form" label="{{'form.passportNumber' | translate}}"></app-input>
          <app-input class="mb_30" icon="birthday-cake" type="date" formCtrlName="BirthDay" [formGrp]="form" label="{{'form.birthDate' | translate}}" [minDate]="birthDayValidator.minDate" [maxDate]="birthDayValidator.maxDate" [autoMinMaxDate]="true"></app-input>
          <app-input class="mb_30" icon="venus-mars" type="select" formCtrlName="gender" [formGrp]="form" label="{{'Gender' | translate}}" [selectOptions]="genderOptions"></app-input>
          <app-input class="mb_30" icon="user" type="select" formCtrlName="nationality" [formGrp]="form" label="{{'nationality.title' | translate}}" [selectOptions]="allNationalities" (output)="nationalityChange($event)"></app-input>
          <ng-container *ngIf="form.contains('borderPass')">
            <div class="mb_30">
              <lib-choice-button [formGrp]="form"
              formCtrlName="borderPass"
              [buttons]="choiceButtons" size="xsm"
              (output)="borderPassCheck($event)"
              [text]="('form.borderPass' | translate)"></lib-choice-button>
            </div>
          </ng-container>
          <ng-container *ngIf="form.contains('borderPassNumber')">
            <app-input class="mb_30" icon="hashtag" type="text" formCtrlName="borderPassNumber" [formGrp]="form" label="{{'form.borderPassNumber' | translate}}"></app-input>
          </ng-container>

          <ng-container *ngIf="form.contains('residentialAddress')">
            <app-input class="mb_30" icon="location-dot" type="text" formCtrlName="residentialAddress" [formGrp]="form" label="{{'form.residentialAddress' | translate}}"></app-input>
          </ng-container>

          <ng-container *ngIf="form.contains('issueDate')">
            <app-input class="mb_30" icon="calendar-days" type="date" formCtrlName="issueDate" [formGrp]="form" label="{{'form.issueDate' | translate}}"></app-input>
          </ng-container>

          <ng-container *ngIf="form.contains('expiryDate')">
            <app-input class="mb_30" icon="calendar-days" type="date" formCtrlName="expiryDate" [formGrp]="form" label="{{'form.expiryDate' | translate}}"></app-input>
          </ng-container>

          <ng-container *ngIf="form.contains('reason')">
            <app-input class="mb_30" icon="question-circle" type="text" formCtrlName="reason" [formGrp]="form" label="{{'form.reason' | translate}}"></app-input>
          </ng-container>

          <ng-container *ngIf="form.contains('authorizedCrossingPoints')">
            <app-input class="mb_30" icon="road-barrier" type="text" formCtrlName="authorizedCrossingPoints" [formGrp]="form" label="{{'form.authorizedCrossingPoints' | translate}}"></app-input>
          </ng-container>
        </div>
        <app-input *ngIf="api.env.type === DocType.PASS"  class="mb_30" icon="user" type="text" formCtrlName="lastName" [formGrp]="form" label="{{'form.lastName' | translate}}"></app-input>
        <app-input *ngIf="api.env.type === DocType.PASS"  class="mb_30" icon="user" type="text" formCtrlName="firstName" [formGrp]="form" label="{{'form.firstName' | translate}}"></app-input>
        <app-input *ngIf="api.env.type === DocType.PASS" class="mb_30" icon="user" type="email" formCtrlName="email" [formGrp]="form" label="{{'form.email' | translate}}"></app-input>
        <app-input *ngIf="api.env.type === DocType.PASS && !isPreenroll" class="mb_30" icon="user" type="number" formCtrlName="phone" [formGrp]="form" label="{{'form.phone' | translate}}"></app-input>
        <app-input *ngIf="api.env.type === DocType.PASS && !isPreenroll" class="mb_30" icon="user" type="text" formCtrlName="idCard" [formGrp]="form" label="{{'form.idCard' | translate}}"></app-input>
        <app-input *ngIf="api.env.type === DocType.PASS" class="mb_30" icon="user" type="select" formCtrlName="meansPayment" [formGrp]="form" label="{{'dropdown.meansPayment' | translate}}" [selectOptions]="paymentCashType"></app-input>
        <app-input *ngIf="api.env.type === DocType.PASS" icon="user" type="text" formCtrlName="externalId" [formGrp]="form" label="{{'form.externalId' | translate}}"></app-input>
        <app-input class="mb_0 mt_20 fullWidth" *ngIf="api.env.type === DocType.PASS && isPreenroll" type='file'
        [formGrp]="form"
        formCtrlName="scanBirthCertificate"
        acceptType=".png,.jpg,.jpeg,.pdf"
        [sizeLimit]="1000 * 2000"
        [label]="'scan.birthCertificate' | translate"
        (output)="outputInput($event)"
        (reset)="resetFile()"
        (visualFile)="visualFile($event)"></app-input>
      </form>
      <div class="flex gap_10 f_align_c" [class.mt_20]="api.env.type === DocType.PASS">
        <app-button size="xsm" color="transparent" border="true" type="button" [shortcut]="ShortcutAction.ESCAPE" [shortcutStyle]="{bottom: '-25px'}" (click)="nav.to(params.fromRoute || 'dashboard')" class="fullWidth resp_minSize_l">{{'global.back' | translate}}</app-button>
        <app-button size="xsm" formId="form" class="fullWidth resp_minSize_l" [shortcut]="ShortcutAction.ENTER" [shortcutStyle]="{bottom: '-25px'}" (click)="submitForm()">{{(api.env.type === DocType.PASS ? 'payment.create' : 'exit.create') | translate}}</app-button>
      </div>
      <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l flex gap_5 mb_20">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div>
      <app-button border="true" formId="form" class="fullWidth" (btnClicked)="nav.to(params.fromRoute || 'dashboard')"> <fa-icon icon="arrow-left"></fa-icon> </app-button>
    </div>
    <app-button type="button" (click)="submitForm()" class="fullWidth  resp_maxSize_l">{{(api.env.type === DocType.PASS ? 'payment.create' : 'exit.create') | translate}}</app-button>
  </div>
</div>

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {
  ActionState,
  CurrencySign, PassportType,
  Request,
  RequestRemoteState,
  RequestState, RequestSubState,
  RequestWithDuplicata
} from '../../../shared/models/request';
import {ApiService} from '../../../shared/services/api.service';
import {Person} from '../../../shared/models/person';
import {formatDate} from 'src/app/shared/utils/string';
import {PlatformEvent, PlatformEventType} from '../../../shared/models/information';
import {environment} from 'src/environments/environment';
import {Document, DocumentType} from 'src/app/shared/models/user';
import {QrService} from 'src/app/shared/services/qr.service';
import {CartService, FormApplication} from '../../../shared/services/cart.service';
import {FormField, FormStep, VisaFN, VisaFormConfig, VisaFormName} from '../../../shared/models/forms';
import {format} from 'date-fns';
import {Visa} from '../../../shared/models/local-form';
import {flattenObject} from '../../../shared/utils/flattenObject';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  selector: 'app-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestAccountComponent implements OnInit {
  RequestRemoteState = RequestRemoteState;
  currentRequest: RequestWithDuplicata;
  RequestState = RequestState;
  dataActualDocument: any;
  actualPerson: Person;
  PlatformEventType = PlatformEventType;
  paymentId: string;
  formatDate = formatDate;
  events: PlatformEvent[] = [];
  isPrintingApprovalLetter: boolean;

  constructor(public nav: NavigateService,
    public qr : QrService,
    public cart: CartService,
    public api: ApiService,
    public request: RequestService,
    private route: ActivatedRoute,
    private loader: LoaderService) {
    this.paymentId = this.route.snapshot.paramMap.get('paymentId');
  }

  ngOnInit() {
    const action = () => {
      if(this.currentRequest) {
        if(typeof this.currentRequest.serialized === 'string' && this.currentRequest.serialized && this.currentRequest.id) {
          this.loader.loading(true);
          this.currentRequest.serialized = this.api.getMetadataPayment(this.currentRequest.serialized, this.currentRequest.id).then((res: any) => {
            this.currentRequest.serialized = res;
            this.getExtensionsMetadata();
            this.loader.loading(false);
            return res;
          }).catch(() => {
            this.loader.loading(false);
            this.getExtensionsMetadata();
            return this.currentRequest.serialized;
          });
        }
      } else {
        this.nav.to('trips');
      }
      this.qr.qrOfflineGenerate(this.api.userPlaceId, this.currentRequest.id, this.currentRequest.externalId);
      this.qr.setCurrentQrData(undefined, this.currentRequest);
    };

    const application = this.request.allMyApplications?.find((app: {batchId: string, requests: Request[] | RequestWithDuplicata[]}) => app.requests.find((request: Request | RequestWithDuplicata) => request.id === this.paymentId));
    if(!application) return this.nav.to('trips');
    this.currentRequest = application.requests?.find((request: Request | RequestWithDuplicata) => request.id === this.paymentId) as RequestWithDuplicata;
    this.loader.loading(true);
    this.api.payment(this.currentRequest.id, true).then((res: RequestWithDuplicata) => {
      this.loader.loading(false);
      if(res.events) {
        const events: PlatformEvent[] = [];
        res.events.forEach((event: PlatformEvent) => {
          const context = JSON.parse(event.context);
          if(context.consumeData) {
            this.api.getMetadataPayment(context.consumeData, event.payment_id).then((res: any) => {
              event.consumeData = res;
              event.consumeData.Operation && events.push(event);
              if(events?.length === this.currentRequest.events?.length) {
                this.events = events.sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime()).filter((event: PlatformEvent) => event.consumeData?.Operation);
                action();
              }
            });
          } else {
            events.push(event);
            if(events.length === this.currentRequest.events.length) {
              this.events = events.sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime()).filter((event: PlatformEvent) => event.consumeData?.Operation);
              action();
            }
          }
        });
      } else {
        action();
      }
    }).catch(() => {
      this.loader.loading(false);
      action();
    });
  }

  startProcess(isResume: boolean, request: Request = this.currentRequest) {
    const visaFormConfig: VisaFormConfig = JSON.parse(JSON.stringify(this.cart.getOriginalForm(request.operationId as DocumentType)));
    const meta = flattenObject(request.serialized as Visa);

    if(meta.NextOfKin) {
      meta.NextOfKin = JSON.parse(meta.NextOfKin);
      meta[VisaFN.EMERGENCY_LAST_NAME] = meta.NextOfKin.lastname;
      meta[VisaFN.EMERGENCY_FIRST_NAME] = meta.NextOfKin.firstname;
      meta[VisaFN.EMERGENCY_EMAIL] = meta.NextOfKin.email;
      meta[VisaFN.EMERGENCY_PHONE] = meta.NextOfKin.phone;
    }

    Object.keys(meta).forEach((key: any) => {
      Object.keys(visaFormConfig).forEach((step: string) => {
        const formStep: FormStep = visaFormConfig[step];
        formStep.errors = formStep.errors || [];
        const formField: FormField = formStep.fields?.find((field: FormField) => field.name === key);

        if(formField) {
          const val: any = meta[key as keyof Visa];

          if(isResume || !request.refusalRefusedFields?.includes(key)) {
            switch(formField.type) {
            case 'date':
              formField.value = format(new Date(val), 'yyyy-MM-dd');
              break;
            case 'checkbox':
              formField.value = (val.toLowerCase() === 'true' || val.toLowerCase() === 'yes') ? val : '';
              break;
            default:
              formField.value = val;
              break;
            }
            if(!isResume) formField.disabled = true;
          } else if(!isResume && request.refusalRefusedFields?.includes(key)) {
            formStep.errors.push({key: key, keyError: 'refused'});
            formStep.hasRevisionErrors = true;
          }
        }

        if(!isResume) {
          formStep.visited = true;
          formStep.submitted = true;
        }

        if(step === VisaFormName.MISCELLANEOUS_DETAILS) {
          formStep.fields.find((field: FormField) => field.name === VisaFN.BATCH_ID).value = request.batchId;
        }
      });
    });

    if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_COUNTRY, visaFormConfig)) {
      const placeOfBirth = meta.PlaceOfBirth ? meta.PlaceOfBirth.split(' - ') : ['', ''];
      const countryField = this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_COUNTRY, visaFormConfig);
      const cityField = this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_CITY, visaFormConfig);

      countryField.value = placeOfBirth[0].trim();
      cityField.value = placeOfBirth[1].trim();

      if(!isResume) {
        countryField.disabled = !request.refusalRefusedFields.includes(VisaFN.PLACE_OF_BIRTH_COUNTRY);
        cityField.disabled = !request.refusalRefusedFields.includes(VisaFN.PLACE_OF_BIRTH_CITY);
      }
    }

    if(this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, visaFormConfig)) {
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, visaFormConfig).value = meta.PreviousConvictionsZim ? 'true' : 'false';
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM, visaFormConfig).value = isResume || !request.refusalRefusedFields?.includes(VisaFN.PREVIOUS_CONVICTIONS_ZIM) ? meta.PreviousConvictionsZim : '';
      if(!isResume) {
        this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, visaFormConfig).disabled = !request.refusalRefusedFields.includes(VisaFN.PREVIOUS_CONVICTIONS_ZIM);
        this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_ZIM, visaFormConfig).disabled = !request.refusalRefusedFields.includes(VisaFN.PREVIOUS_CONVICTIONS_ZIM);
      }
    }

    if(this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, visaFormConfig)) {
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, visaFormConfig).value = meta.PreviousConvictionsHome ? 'true' : 'false';
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME, visaFormConfig).value = isResume || !request.refusalRefusedFields?.includes(VisaFN.PREVIOUS_CONVICTIONS_HOME) ? meta.PreviousConvictionsHome : '';
      if(!isResume) {
        this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, visaFormConfig).disabled = !request.refusalRefusedFields.includes(VisaFN.PREVIOUS_CONVICTIONS_HOME);
        this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PREVIOUS_CONVICTIONS_HOME, visaFormConfig).disabled = !request.refusalRefusedFields.includes(VisaFN.PREVIOUS_CONVICTIONS_HOME);
      }
    }

    if(!isResume && request.refusalRefusedFields.includes(VisaFN.PURPOSE_OF_VISIT)) {
      visaFormConfig[VisaFormName.DOCUMENT].hasRevisionErrors = true;
      visaFormConfig[VisaFormName.DOCUMENT].fields.forEach((field: FormField) => {
        field.value = undefined;
        field.disabled = false;
        field.hidden = true;
      });
    }

    const application: FormApplication = {
      travelName: request.batchId,
      plannedEntry: meta.ProvisionnalEntryPort,
      requests: [
        {
          formConfig: visaFormConfig,
          nationality: isResume || !(request.refusalRefusedFields as VisaFN[]).includes(VisaFN.NATIONALITY) ? meta.Nationality : undefined,
          passportType: isResume || !(request.refusalRefusedFields as VisaFN[]).includes(VisaFN.PASSPORT_TYPE) ? meta.PassportType : undefined,
          visaType: isResume || !(request.refusalRefusedFields as VisaFN[]).includes(VisaFN.VISA_TYPE) ? this.api.userInfo.server.paymentRequests.find((doc: Document) => doc.id === request.operationId) : undefined,
          urgent: meta.Urgent,
          person: {
            firstName: this.api.listPersons.find((person: Person) => person.id === request.person_id)?.firstName || '',
            lastName: this.api.listPersons.find((person: Person) => person.id === request.person_id)?.lastName || '',
            nationalNumber: '',
            batchIds: [],
            passportNumber: '',
            id: Number(request.person_id),
            linkType: null,
            otherLinkType: ''
          }
        }
      ],
      visaFeesAndTerms: false,
      requestId: request.id,
      isRevision: !isResume,
      isExtend: request.operationId === DocumentType.ZWEVISAEXTC || request.operationId === DocumentType.ZWEVISAEXTAB,
      revisionFields: request.refusalRefusedFields as VisaFN[]

    };

    if(isResume) {
      this.request.pay(undefined, true, application);
    } else {
      this.loader.loading(true, {type: 'info', message: 'revision.start', btnLabel: 'yes', custom: {closeBtnLabel: 'no'}}).then((done) => {
        if(done) {
          this.cart.startApplication(false, application, visaFormConfig);
        }
      });
    }
  }

  printApprovalLetter() {
    this.loader.loading(true);
    this.isPrintingApprovalLetter = true;
    setTimeout(() => {
      this.loader.loading(false);
      window.print();
      this.isPrintingApprovalLetter = false;
    }, 1000);
  }

  calculateDeadlineDate(usageAfter: string, millis: number): Date {
    const usageAfterDate = new Date(usageAfter);
    const deadlineDate = new Date(usageAfterDate.getTime() + millis);
    return deadlineDate;
  }

  getAmount(currency: keyof typeof CurrencySign, ammount: number, millis = true, showCurrency: boolean = true) : string{
    return (showCurrency && CurrencySign[currency]) + (millis ? ammount / 100 : ammount);
  }

  getExtensionsMetadata() {
    if(!this.currentRequest?.extensions?.length) {
      return;
    } else {
      this.currentRequest.extensions.map((request: Request) => this.api.getMetadataPayment(request?.serialized, request?.id).then((res: any) => {
        request.serialized = res;
        return request;
      }).catch(() => request));
    }
  }

  isExtend() {
    return this.currentRequest && (this.currentRequest.operationId === DocumentType.ZWEVISAEXTC || this.currentRequest.operationId === DocumentType.ZWEVISAEXTAB);
  }

  showWarning(req: Request) {
    return (this.request.isCountryCat(req.nationality, 'A') || this.request.isCountryCat(req.nationality, 'B')) &&
      !this.cart.currentApplication?.isExtend &&
      req.passportType !== PassportType.PERMANENT_RESIDENT_PERMIT &&
      req.passportType !== PassportType.RESIDENT_PERMIT;
  }

  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
  protected readonly RequestSubState = RequestSubState;
  protected readonly ActionState = ActionState;
}

<div id="print">
  <div class="print_sticker f_col">
    <img class="print_sticker_bg hide_info" src="./assets/images/sticker_bg.png" width="200px" alt="">
    <div class="flex">
      <div class="flex f_align_c">
        <img class="hide_info" src="./assets-projects/emblem.png" width="19px" alt="">
      </div>
      <div class="f_col f_align_c hide_info">
        <span class="print_sticker_sm_text">REPUBLIC OF ZIMBABWE</span>
        <span class="print_sticker_md_text"><b>ZIMBABWE IMMIGRATION SERVICE</b></span>
        <span class="print_sticker_md_text"><b>[ ENTRY CONFIRMATION ]</b></span>
      </div>
    </div>
    <div class="print_sticker_border hide_info"></div>
    <div class="f_grow">
      <div class="f_col print_sticker_data print_sticker_data_name">
        <span class="print_sticker_sm_text hide_info">NAME</span><br>
        <span><b>{{data.name}}</b></span>
      </div>
      <div class="flex gap_2">
        <div class="f_col print_sticker_col">
          <div class="f_col print_sticker_data">
            <span class="print_sticker_sm_text hide_info">PASSPORT NO.</span><br>
            <span><b>{{data.passportNumber}}</b></span>
          </div>
          <div class="f_col print_sticker_data">
            <span class="print_sticker_sm_text hide_info">DATE OF ENTRY</span><br>
            <span><b>{{data.dateOfEntry}}</b></span>
          </div>
          <div class="f_col print_sticker_data">
            <span class="print_sticker_sm_text hide_info">STATUS</span><br>
            <span><b>{{data.status}}</b></span>
          </div>
          <div class="f_col print_sticker_data">
            <span class="print_sticker_sm_text hide_info">UNTIL</span><br>
            <span><b>{{data.until}}</b></span>
          </div>
          <span class="print_sticker_sm_text print_sticker_place"><b>{{data.place}}</b></span>
        </div>
        <div class="print_sticker_col2">
          <qrcode elementType="svg" width="200" [version]="4" [qrdata]="qrCode" errorCorrectionLevel="L"></qrcode>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content class="dialog_content">
  <p class="py-4">{{'global.daily_otp' | translate}}</p>

  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="flex justify-evenly">
      <input id="char1" formControlName="char1" type="text" maxlength="1" (keyup)="onKeyUpEvent(1, $event)" (focus)="onFocusEvent(1)"/>
      <input id="char2" formControlName="char2" type="text" maxlength="1" (keyup)="onKeyUpEvent(2, $event)" (focus)="onFocusEvent(2)"/>
      <input id="char3" formControlName="char3" type="text" maxlength="1" (keyup)="onKeyUpEvent(3, $event)" (focus)="onFocusEvent(3)"/>
      <input id="char4" formControlName="char4" type="text" maxlength="1" (keyup)="onKeyUpEvent(4, $event)" (focus)="onFocusEvent(4)"/>
      <input id="char5" formControlName="char5" type="text" maxlength="1" (keyup)="onKeyUpEvent(5, $event)" (focus)="onFocusEvent(5)"/>
      <input id="char6" formControlName="char6" type="text" maxlength="1" (keyup)="onKeyUpEvent(6, $event)" (focus)="onFocusEvent(6)"/>
    </div>

    <div class="flex justify-between mt-6">
      <button class="btn w-1/2" (click)="closeDialog()">{{'global.close' | translate}}</button>
      <button class="btn w-1/2 ml-1" type="submit">{{'global.submit' | translate}}</button>
    </div>

    <app-errors-list *ngIf="submitted" [formErrors]="forms.getFormErrors(form)"></app-errors-list>
  </form>
</mat-dialog-content>



<div id="layout" class="f_col">
  <app-view-title fromRoute="password-send" [chooseLangBtn]="true">{{'passwordSend.title' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <app-view-deco-image imageUrl="assets-projects/images/image_mail_sent.svg"></app-view-deco-image>
      <p class="text_center">{{'passwordSend.description' | translate}}</p>
      <div class="resp_minSize_l mt_20">
        <app-button size="sm" color="transparent" border="true" type="button" (click)="nav.to('sign-in')" class="fullWidth">{{'global.close' | translate}}</app-button>
      </div>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <div class="fullWidth">
      <app-button color="transparent" border="true" type="button" (click)="nav.to('sign-in')" class="fullWidth">{{'global.close' | translate}}</app-button>
    </div>
  </div>
</div>

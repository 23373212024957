import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LoaderService} from 'ngx-satoris';
import {RequestCashType, Request, RequestState} from '../models/request';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(private api: ApiService, private loader: LoaderService) {}

  syncAll() {
    this.syncPayments();
  }

  addOfflineCashPayment(id: string, requestId: string, metadata: string, type: RequestCashType, createPlaceId: string,
    external_id?: string, confirmed = true) {
    const payment: Request = {
      id: id,
      requestId: requestId,
      metadata: metadata,
      type: type,
      created_place_id: createPlaceId,
      externalId: external_id,
      state: confirmed ? RequestState.PAID : RequestState.PAID_NOT_CONFIRMED
    };
    const payments = this.api.getStoredItem('payments_old') || [];
    payments.push(payment);
    this.api.storeItem('payments_old', payments);
  }

  syncPayments() {
    const payments: Request[] = this.api.getStoredItem('payments_old') || [];
    if(payments.length > 0) {
      this.loader.loading(true);
      payments.forEach((p: Request, index) => {
        this.api.registerCashPayment(p.id, p.requestId, p.metadata, p.type, p.created_place_id, undefined, p.externalId, undefined, p.state === RequestState.PAID)
          .catch(err =>  this.loader.loading(true, {type: 'error', message: err}));

        if(payments.length === index + 1) {
          this.api.removeStoredItem('payments_old');
          this.loader.loading(false);
        }
      });
    }
  }
}

import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {CartService} from '../../../shared/services/cart.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DocumentType} from '../../../shared/models/user';

@Component({
  selector: 'app-dialog-new-person',
  templateUrl: './dialog-pdf-viewer.component.html',
  styleUrl: './dialog-pdf-viewer.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DialogPdfViewerComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {pdf: string},
    public cart: CartService,
    public dialogRef: MatDialogRef<DialogPdfViewerComponent>) {
  }

  closeDialog(done?: boolean) {
    this.dialogRef?.close(done);
  }

  protected readonly DocumentType = DocumentType;
}

import {ModelInterface} from './entity';
import {DocumentType, Gender, MaritalStatus, Document} from './user';
import {PlatformEvent} from './information';
import {Person} from './person';
import {VisaFormConfig, VisaFormName} from './forms';
import {IconProp} from 'ngx-satoris';

export enum RequestType {
  PHYSICAL = 'PHYSICAL',
  PAYPAL = 'PAYPAL',
  WU = 'WU',
  MNTA = 'MNTA'
}

export interface RequestStep extends Request {
  step: {status:string, value: any}[];
  qrCode?: string;
}

export enum PassportType {
  ORDINARY = 'ordinary',
  DIPLOMATIC = 'diplomatic',
  MARINE = 'marine',
  SERVICE = 'service',
  LAISSEZ_PASSER = 'laissez-passer',
  IDENTITY_CARD = 'identity-card',
  RESIDENT_PERMIT = 'resident-permit',
  REFUGEE = 'refugee',
  PERMANENT_RESIDENT_PERMIT = 'permanent-resident-permit',
}

export const enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  ZWL = 'ZWL',
  ZAR = 'ZAR',
  GBP = 'GBP'
}

export enum CurrencySign {
  EUR = '€',
  USD = 'US$',
  ZWL = 'Z$',
  ZAR = '(SA)R',
  GBP = '£',
  BWP = 'P'
}

export enum RequestRemoteState {
  OK = 0,
  OK_MODIFIED = 1,
  NOK = 2,
  NOK_TO_MODIFY = 3,
  PROCESSING = 4
}

export enum RequestState {
  PROPOSED = 0,
  PAID = 1,
  USED = 2,
  DUPLICATED = 3,
  REFUNDED = 4,
  EXPIRED = 5,
  PAID_NOT_CONFIRMED = 6,
  PENDING = 7,
  DELAY_PAY_NOT_CONFIRMED = 8,
  DELAY_PAY = 9
}

export enum RequestSubState {
  NONE = 0,
  REVISIONS_0 = 0,
  REVISIONS_1 = 1,
  REVISIONS_2 = 2,
  REVISIONS_3_MORE = 3,
  CHECKED_IN = 4,
  CHECKED_OUT = 5,
  REFUSED_IN = 6,
  REFUSED_OUT = 7,
  CLOSED = 8,
  REC_ASKED = 9,
  REC_RECEIVED = 10
}

export enum ActionState {
  READY_ZWEEXIT = 'READY_ZWEEXIT',
  READY_CHECKIN = 'READY_CHECKIN',
  REFUSED = 'REFUSED',
  READY_CHECK = 'READY_CHECK',
  CLOSED = 'CLOSED',
  READY_CHECKOUT = 'READY_CHECKOUT',
  OUT_FINISH = 'OUT_FINISH',
  ENTRY_FINISH = 'ENTRY_FINISH',
}

export enum RequestFilter {
  PRICEUP = 'PRICEUP',
  PRICEDOWN = 'PRICEDOWN',
  RECENT = 'RECENT',
  LATEST = 'LATEST',
  STATUSUP = 'STATUSUP',
  STATUSDOWN = 'STATUSDOWN'
}

export const RequestStatus = {
  0: 'PROPOSED',
  1: 'PAID',
  2: 'USED',
  3: 'DUPLICATED',
  4: 'REFUNDED',
  5: 'EXPIRED',
  6: 'PAID_NOT_CONFIRMED',
  7: 'PENDING',
  8: 'DELAY_PAY_NOT_CONFIRMED',
  9: 'DELAY_PAY'
};

export interface Request extends ModelInterface<string | number> {
  amountCents?: number;
  currency?: string;
  paymentType?: RequestType;
  usedAt?: string;
  closedAt?: string;
  state?: RequestState;
  metadata?: any;
  type?: RequestCashType;
  createdAt?: string;
  offline?: boolean;
  refundedAt?: string;
  consumeMessage?: string;
  created_place_id?: string;
  consumed_place_id?: string;
  refunded_place_id?: string;
  refunded_by_user__id?: string;
  parent_id?: string;
  user__id?: number;
  used_by_user__id?: number;
  validatedAt?: string;
  validated_by_user__id?: number;
  person_id?: number;
  serialized?: any;
  operationId?: DocumentType;
  refusalReason?: string;
  externalId?: string;
  remoteState?: RequestRemoteState;
  remoteStateReason?: string;
  remoteStateRefusedFields?: string[];
  consumeData?: string | ConsumeRequestData;
  internalIndexedData?: string;
  assignedAt?: string;
  assigned_user__id?: number;
  usageCount?: number;
  passportNumber?: string;
  usageAfter?: string;
  usageUntil?: string;
  refusalRefusedFields?: string[];
  batchId?: string;
  internalReference?: string;
  subState?: RequestSubState;
  externalIndexedData?: string;
  chargedBackAt?: string;
  localUsageUntil?: string;
  batch?: {
    count: number;
    views: ({id: string}|Request)[];
  };
  // Added computed fields
  buyingOption?: any;
  firstName?: string;
  lastName?: string;
  phone?: number;
  signature?: string;
  id?: string;
  idCard?: string;
  requestId?: string;
  paymentPublicData?: string;
  paymentId?: string;
  controlCode?: string;
  email?: string;
  approvedAt?: Date;
  approver_user__id?: number;
  approverResponse?: string;
  document?: string;
  remoteAttestationId?: string;
  meetingId?: number;
  documentSigned?: string;
  treatmentOk?: string;
  stateReason?: string;
  step?: any[];
  place_id?: string;
  person?: Person;
  nationality?: string;
  passportType?: PassportType;
  visaType?: Document;
  latestRouteVisited?: string;
  urgent?: 'Yes' | 'No';
  formConfig?: VisaFormConfig;
  latestFormStepVisited?: {key: VisaFormName, value: VisaFormName};
  extendDays?: number;
  extensions?: Request[] | RequestWithDuplicata[];
  completeName?: string;
  events?: PlatformEvent[];
  creationDate?: string;
  lock?: boolean;
  assignedUserId?: number;
  price?: number;
  realState?: RequestState;
  iconColor?: string;
  isUrgent?: boolean;
}

export interface RequestWithDuplicata extends Request {
  duplicatas: Request[];
  watchlistsResponse: {
    matches: {
      type: string;
      score: number;
      level: number;
      watchlist: {
        uuid: string;
        name: string;
        alertLevel: number;
        category: string;
        visibility: string[];
      };
      item: {
        uuid: string;
        name: string;
        aliases: string[];
        datesOfBirth: string[];
        placeOfBirth: string;
        hair: string;
        eyes: string;
        height: string;
        weight: string;
        gender: string;
        race: string;
        occupation: string;
        marks: string;
        crime: string;
        link: string;
        source: {
          watchlist: string;
          uuid: string;
          status: string;
        };
        documents: {
          number: string;
          type: string;
          issuer: string;
        }[];
        nationalities: string[];
        images: {
          url: string;
          mimeType: string;
          matched: boolean;
        }[];
      }
    }[];
  };
  apipnrResponse: {
    matches: {
      name: {
        name: string;
        score: number;
      };
      flight: {
        carrier: string;
        flightNumber: string;
        origin?: {
          domestic?: boolean;
          icao?: string;
          lat?: number;
          lng?: number;
          iata?: string;
          city?: string;
          state?: string | null;
          country?: string;
          continent?: string;
          name?: string;
          timezone?: string;
        };
        destination?: {
          domestic?: boolean;
          icao?: string;
          lat?: number;
          lng?: number;
          iata?: string;
          city?: string;
          state?: string | null;
          country?: string;
          continent?: string;
          name?: string;
          timezone?: string;
        };
        departure?: {
          utcstr?: string;
          local?: {
            date?: string;
            time?: string;
            totalMinutes?: number;
            holiday?: boolean;
            observedHoliday?: boolean;
            typicalHoliday?: boolean;
            day?: string;
            zone?: string;
          };
          airport?: {
            date?: string;
            time?: string;
            totalMinutes?: number;
            holiday?: boolean;
            observedHoliday?: boolean;
            typicalHoliday?: boolean;
            day?: string;
            zone?: string;
          };
          utc?: {
            date?: string;
            time?: string;
            totalMinutes?: number;
            holiday?: boolean;
            observedHoliday?: boolean;
            typicalHoliday?: boolean;
            day?: string;
            zone?: string;
          };
          original?: string;
        };
        arrival?: {
          utcstr?: string;
          local?: {
            date?: string;
            time?: string;
            totalMinutes?: number;
            holiday?: boolean;
            observedHoliday?: boolean;
            typicalHoliday?: boolean;
            day?: string;
            zone?: string;
          };
          airport?: {
            date?: string;
            time?: string;
            totalMinutes?: number;
            holiday?: boolean;
            observedHoliday?: boolean;
            typicalHoliday?: boolean;
            day?: string;
            zone?: string;
          };
          utc?: {
            date?: string;
            time?: string;
            totalMinutes?: number;
            holiday?: boolean;
            observedHoliday?: boolean;
            typicalHoliday?: boolean;
            day?: string;
            zone?: string;
          };
          original?: string;
        };
        bound?: string;
      };
      document: {
        type: string;
        number: string;
        issuer: string;
        issued?: string;
        expiration?: string;
        score?: number;
      };
      time: {
        type: string;
        date: string;
        scope: number;
      };
      nationality: string;
      gender: Gender;
      uuid: string;
      travellerUUID: string;
    }[];
  };
  fetchedAt: string;
}

export enum OperationType {
  IN = 'IN',
  OUT = 'OUT',
  REFUSEIN = 'REFUSEIN',
  REFUSEOUT = 'REFUSEOUT',
  RECPOSITIVE = 'RECPOSITIVE',
  RECNEGATIVE = 'RECNEGATIVE',
  INRETURNED = 'INRETURNED',
}

export interface ConsumeRequestData {
  ProfilePicture?: string;
  FingerPrint?: string;
  Operation: OperationType;
  PaymentReference?: string;
  PaymentCurrency?: Currency;
  PaymentMode?: RequestCashType;
  PaymentIsNotDocument?: 'Yes' | 'No';
  PaymentAmountCents?: number;
  OperationComments?: string;
  ReturnedPassengerReason?: string; 
  PlaceSerial?: number;
  Documents?: any;
}

export enum RequestCashType {
  CASH = 'CASH',
  CARD = 'CARD'
}

export interface RequestTable {
  id: string,
  completeName: string;
  price: string;
  birthDay: number;
  passportNumber: string;
  email: string;
  state: RequestState;
  creationDate: string;
}

export enum SinceTime {
  ONE_DAY = 'ONE_DAY',
  THREE_DAYS = 'THREE_DAYS',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR'
}

export interface VisaApplication {
  PassportNumber: string;
  Nationality: string;
  LastName: string;
  OtherName: string;
  FirstName: string;
  BirthDay: number;
  PlaceOfBirth: string;
  Gender: Gender;
  MaritalStatus: MaritalStatus;
  Email: string;
  Phone: string
  Occupation?: string;
  HomeAddress?: string;
  HostName: string;
  AddressZimbabwe?: string;
  DateOfIssue: number;
  DateOfExpiry: number;
  PlaceofIssue?: string;
  ArrivalDate: number;
  DepartureDate: number;
  ReferencePreviousVisa?: number;
  PreviousConvictionsZim?: string;
  PreviousConvictionsHome?: string;
  Spouse: 'Yes' | 'No';
  Dependants: 'Yes' | 'No';
  Documents: {
    PurposeOfVisit: PurposeOfVisit;
    PassportPhoto: string;
    PassportScan: string;
    ProofOfResidenceHost: string;
    AddressAtDestination?: string;
    ApplicationLetter?: string;
    ResidentialStatusOfHost?: string;
    ReasonOfVisitProof?: string;
    BusinessLetter?: string;
    BusinessProfile?: string;
    InvitationLetterAndBusinessProfile?: string;
    LetterOfAcceptanceFromSchool?: string;
    PreviousZimbabweanVisa?: string;
    HostInvitationLetter?: string;
    ProofOfResidence?: string;
  };
  SpouseDetails?: {
    SpouseFullName?: string;
    SpousePassportNumber?: number;
    SpouseBirthDay?: number;
    SpousePlaceOfBirth?: string;
  };
  DependantsDetails?: {
    DependantFullName: string;
    DependantPassportNumber: number;
    DependantBirthDay: number;
    DependantPlaceOfBirth: string;
  }[];
}

export enum ParagraphType {
  PARX_SUB3_SEC12 = 'PARX_SUB3_SEC12',
  PARB_SEC17 = 'PARB_SEC17',
  SUB1_SEC18 = 'SUB1_SEC18',
  PARX_SUB1_SEC14 = 'PARX_SUB1_SEC14',
  SUBPARIII_PARF_SUB1_SEC14 = 'SUBPARIII_PARF_SUB1_SEC14',
}

export enum ConsumePaymentNoticeType {
  RESTRICTION = 'RESTRICTION',
  REPORTING = 'REPORTING',
  REFUSAL = 'REFUSAL'
}

export interface ComsumePaymentData {
  ProfilePicture?: string;
  PaymentIsNotDocument?: 'Yes' | 'No';
  PaymentAmountCents?: number;
  Fingerprint?: number[];
  Operation?: OperationType
  PaymentReference?: string;
  PaymentMode?: RequestCashType;
  PaymentCurrency?: Currency;
  localUsageUntil?: Date;
  OperationComments?: string;
  FingerPrint?: string;
  ReturnedPassengerReason?: string; 
  Documents?: {
    Type: ConsumePaymentNoticeType;
    RefusalFields?: {
      Paragraph: string;
      Reason: string;
    };
    RestrictionFields?: {
      ReportingPost: string;
      GracePeriodDays: number;
      ResidentialAddress: string;
      BusinessAddress: string;
      Phone: string;
      Email: string;
      DocumentToProduce: string[];
      DocumentToProduceOther: string;
    };
    ReportingFields?: {
      Purpose: string;
      Host: string;
      HostPhone: string;
      AddressZimbabwe: string;
      Phone: string;
      Email: string;
      Address: string;
      ReportingPost: string;
      ExpectedDeparture: number;
      ReportOn: number;
      ReportAtIntervalDays: number;
      PeriodFrom: number;
      PeriodTo: number;
    };
  }[];
  SupervisorAccountName?: string;
  PlaceSerial?: number;
}

export interface DataGroup {
  subtitle: string;
  data: string[][];
}


export interface consumeDataCall {
  agentId: number;
  paymentId: string;
  consumedPlaceId: string;
  createdPlaceId?: string;
  createdAt?: Date;
  consumeMessage?: string;
  consumeData?: ComsumePaymentData;
  localUsageUntil?: Date;
}

export interface CartSyncRow {
  id?: number;
  data?: consumeDataCall[];
}

export enum PaymentReason {
  SUCCESS = 'SUCCESS',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}
export interface RequestTheme {
  color: string,
  iconColor: string,
  state: string,
  descState: string,
  icon: IconProp,
  severity: 1 | 2 | 3 | 4,
  actionState?: ActionState
}

export interface PrintReceiptData {
  referencePrint: string;
  datePrint: Date;
  nameClient: string;
  amount: number;
  currency: Currency,
  agentId: string;
  typePayment: RequestCashType;
  placeId: string;
  buyDocument: DocumentType;
  operation?: OperationType;
  idRequest: string;
  nationality?: string;
  gender?: string;
  HQReference?: string;
  vendorName?: string;
  vendorAddress?: string;
  vendorEmail?: string;
  serialNumber?: number;
}
export interface PrintBorderpassData {
  borderPassNumber: string;
  fullNames: string;
  dateOfBirth: string;
  residentialAddress: string;
  issueDate: string;
  expiryDate: string;
  reason: string;
  authorizedCrossingPoints: string;
  place: string;
  officer: string;
}

export enum PurposeOfVisit{
  HOLIDAY_VISA = 'holiday_visa',
  BUSINESS_VISA = 'business_visa',
  CONFERENCING_VISA = 'conferencing_visa',
  TRANSIT_VISA = 'transit_visa',
  STUDENT_VISA = 'student_visa',
  LIVE_HERE = 'live_here',
}

export enum ConsumeMode {
  ENTRY = 'entry',
  EXIT = 'exit',
  TRANSIT = 'transit'
}

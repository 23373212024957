import {Validators} from '@angular/forms';
import {FormAction, VisaFN, VisaFormConfig, VisaFormName} from 'src/app/shared/models/forms';
import {Visa} from './local-form';

export interface ExtendVisa extends Visa {
  VisaReference: string,
  DaysRequested: number,
  ReasonForExtension: string,
  AttestantID: string,
  AttestantName: string,
  AttestantPhone: string,
  AttestantEmail: string,
  AttestantAddress: string,
  AttestantRelation: string,
}

//Model to represent the form config
export const EXTEND_VISA_FORM_CONFIG: VisaFormConfig = {
  [VisaFormName.PERSONAL_DETAILS]: {
    version: 0.1,
    classNames: 'gap_25',
    fields: [
      {name: VisaFN.DAYS_REQUESTED, type: 'number', icon: 'question', hidden: true, showInResume: true},
      {name: VisaFN.PURPOSE_OF_VISIT, type: 'select', icon: 'question', validators: [Validators.required], showInResume: true},
      {name: VisaFN.REASON_FOR_EXTENSION, type: 'textarea', icon: 'question', validators: [Validators.required], showInResume: true},
      {name: VisaFN.ATTESTANT_RELATION, type: 'select', icon: 'people-arrows', validators: [Validators.required], showInResume: true},
      {name: VisaFN.ATTESTANT_ID, type: 'text', ifFormControlHasNotValue: {control: VisaFN.ATTESTANT_RELATION, value: 'Self'}, requiredIfFormControl: true, icon: 'id-card', showInResume: true},
      {name: VisaFN.ATTESTANT_NAME, type: 'text', ifFormControlHasNotValue: {control: VisaFN.ATTESTANT_RELATION, value: 'Self'}, requiredIfFormControl: true, icon: 'person', showInResume: true},
      {name: VisaFN.ATTESTANT_PHONE, type: 'text', ifFormControlHasNotValue: {control: VisaFN.ATTESTANT_RELATION, value: 'Self'}, requiredIfFormControl: true, icon: 'phone', validators: [Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')], customExplain: 'phone.pattern', showInResume: true},
      {name: VisaFN.ATTESTANT_EMAIL, type: 'text', ifFormControlHasNotValue: {control: VisaFN.ATTESTANT_RELATION, value: 'Self'}, requiredIfFormControl: true, icon: 'at', validators: [Validators.email], showInResume: true},
      {name: VisaFN.ATTESTANT_ADDRESS, type: 'text', ifFormControlHasNotValue: {control: VisaFN.ATTESTANT_RELATION, value: 'Self'}, requiredIfFormControl: true, icon: 'house', showInResume: true}
    ]
  },
  [VisaFormName.DOCUMENT]: {
    version: 0,
    classNames: 'gap_8',
    textBeforeForm: {
      title: 'document',
      text: 'document_text'
    },
    fields: [
      {name: VisaFN.ATTESTANT_DOCUMENT, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.AFFIDAVIT_CUSTODY, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.PROOF_OF_RESIDENCE_HOST, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.COPY_OF_ENTRY, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.DEPARTURE_TICKET, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE}
    ]
  }
};

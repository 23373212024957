<div id="print">
  <div class="print_content">
    <div class="flex f_between mt_30">
      <div>
        <span><b>Section 8 (3) (a) of the Act<br>
        Section 48 (1) of the Regulations</b></span>
      </div>
      <span><b>Form L.F.20</b></span>
    </div>
    <div class="text_center">
      <h3>GOVERNMENT OF ZIMBABWE<br>
        IMMIGRATION ACT (CHAPTER 4:02)</h3>
      <h2>*1. NOTICE TO PERSON REFUSED LEAVE TO ENTER ZIMBABWE<br>
        *2. NOTICE TO PROHIBITED PERSON</h2>
    </div>
    <div class="mt_10">
      <p>To: <b>{{form.controls.fullNames.value}}</b></p>
      <p>At: <b>{{form.controls.reportLocation.value}}</b></p>
    </div>
    <div class="mt_10">
      <p><b>TAKE NOTICE</b></p>
      <div>
        <span>*1 That leave to enter Zimbabwe is refused in terms of:</span>
        <ul class="ml_20 mt_10">
          <li [ngStyle]="{'font-weight': form.value.reason === ParagraphType.PARX_SUB3_SEC12 ? 'bold' : 'unset'}">*(a) paragraph (<span *ngIf="form.value.reason === ParagraphType.PARX_SUB3_SEC12">{{form.controls.paragraph.value}}</span>) of subsection (3) of section 12 of the Act.</li>
          <li [ngStyle]="{'font-weight': form.value.reason === ParagraphType.PARB_SEC17 ? 'bold' : 'unset'}">*(b) paragraph (b) of section 17 of the Act.</li>
          <li [ngStyle]="{'font-weight': form.value.reason === ParagraphType.SUB1_SEC18 ? 'bold' : 'unset'}">*(c) subsection (1) of section 18 of the Act.</li>
        </ul>
      </div>
      <div class="mt_10">
        <span>*2 That you are a prohibited person in terms of:</span>
        <ul class="ml_20 mt_10">
          <li [ngStyle]="{'font-weight': form.value.reason === ParagraphType.PARX_SUB1_SEC14 ? 'bold' : 'unset'}">*(a) paragraph (<span *ngIf="form.value.reason === ParagraphType.PARX_SUB1_SEC14">{{form.controls.paragraph.value}}</span>) of subsection (1) of section 14 of the Act.</li>
          <li [ngStyle]="{'font-weight': form.value.reason === ParagraphType.SUBPARIII_PARF_SUB1_SEC14 ? 'bold' : 'unset'}">*(b) subparagraph (iii) of paragraph (f) of subsection (1) of section 14 of the Act.</li>
        </ul>
      </div>
      <div class="mt_10">
        <span>* Immigration officer to delete or complete as appropriate.</span>
      </div>
      <div>
        <p>You are notified that, in terms of subsection (1) of section 21, as read with subsection (1) of section 22 of the Act, you may appeal to the nearest Magistrate's Court:</p>
        <ul class="ml_20">
          <li class="flex gap_10"><span>(a)</span><span>If you have been notified that leave to enter Zimbabwe has been refused on any grounds relating to the question of whether you were properly refused leave to enter; or</span></li>
          <li class="flex gap_10"><span>(b)</span><span>If you have been notified that you are a prohibited person in terms of paragraph (a), (h) or (k)of subsection (1) of section 14 of the Act, on the grounds of identity only; or</span></li>
          <li class="flex gap_10"><span>(c)</span><span>If you have been notified that you are a prohibited person in terms of subparagraph (iii) of paragraph (f) of subsection (1) of section 14 of the Act, on the grounds of identity or on the question of whether you have been convicted of an offense specified in Part II of the First Schedule to the Act; or</span></li>
          <li class="flex gap_10"><span>(d)</span><span>If you have been notified that you are a prohibited person in terms of any provision of subsection (1) of section 14 of the Act, on any grounds relating to the question of whether you are a prohibited person.<br></span></li>
          <li class="mt_10">If you have been refused leave to enter Zimbabwe, you are not entitled to enter or remain in Zimbabwe for the purpose of noting or prosecuting your appeal, making representations to the Minister or being present at the hearing hereof, but may be represented at your appeal by a legal practioner.</li>
          <li class="mt_10">Your appeal may be noted:</li>
          <li class="flex gap_10"><span>(a)</span><span>If you have been refused leave to enter or are outside Zimbabwe when you receive this notice not later than ten days, Saturdays, Sundays and Public Holidays excluded, after receiving this notice.</span></li>
          <li class="flex gap_10"><span>(b)</span><span>If you are in Zimbabwe when you receive this notice not later than ten days, Saturdays, Sundays and Public Holidays excluded, after receiving this notice.</span></li>
          <li class="mt_10">and must be noted in Form IF. 21 which may be obtained from any immigration officer.</li>
          <li class="mt_10">The provisions of sections 48 to 58 of the Immigration Regulations, 1979, are to be adhered to in the event of the submission of an appeal.</li>
        </ul>
      </div>
      <div class="mt_20">
        <p>Date: <b>{{form.controls.dateOfRefusal.value | date: 'dd/MM/yyyy'}}</b></p>
      </div>
      <div class="flex f_between mt_10">
        <p>Place: <b>{{form.controls.place.value}}</b></p>
        <div class="text_end">
          <p><i>Immigration Officer</i></p>
          <p><b>{{sync.userDisplay(form.controls.officer.value)}} #{{form.controls.officer.value}}</b></p>
        </div>
        <qrcode *ngIf="qrCode" [qrdata]="qrCode" [margin]="0"></qrcode>
      </div>
      <div class="mt_10">
        <p>I hereby acknowledge receipt of this notice.</p>
      </div>
    </div>
  </div>
</div>

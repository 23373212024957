<div id="form_reset_btn" class="resp_minSize_l mt_10 flex f_center" *ngIf="cart.currentRequest && api.userRole.isKiosk">
  <app-button color="green" size="xsm" (btnClicked)="cart.resetKioskRequest()"> {{ 'onArrival.reset' | translate }}</app-button>
</div>
<div id="layout" class="f_col" *ngIf="cart.currentRequest && config">
  <app-view-title fromRoute="pre-enroll">
    <div class="flex gap_10 f_align_c">
      {{ currentStep?.value | translate }}
      <app-snackbar [classList]="'cursor-pointer'" (click)="cart.openParticipants()" *ngIf="cart.currentRequest && api.userRole.isCustomer && !cart.currentApplication.isRevision && !cart.currentApplication.isExtend">
        <fa-icon icon="bars" class="mr_10"></fa-icon>
        {{ cart.currentRequest.person.firstName }} {{ cart.currentRequest.person.lastName[0] }}.
      </app-snackbar>
    </div>
  </app-view-title>
  <div id="stepper" *ngIf="steps?.length > 1">
    <div class="stepper_list">
      <div *ngFor="let step of steps; let i = index"
           class="stepper_list_item"
           [class.stepper_list_item_active]="step.value === currentStep.value"
           [class.stepper_list_item_error]="config[steps[i].value]?.errors?.length > 0"
           [class.stepper_list_item_done]="config[steps[i].value]?.visited && !config[steps[i].value]?.errors?.length > 0"
           (click)="setStep(i)">{{ i + 1 }}
      </div>
    </div>
  </div>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-checkbox-element size="sm" [text]="'skip.valid' | translate" *ngIf="cart?.currentApplication?.isRevision" [checked]="cart.currentApplication.skipValidRevision" (onChange)="cart.changeSkip($event.checked)" class="check-validStep"></app-checkbox-element>
      <ng-container *ngFor="let step of steps">
        <ng-container *ngIf="currentStep.key === step.key">
          <ng-container *ngIf="!!config[step.value].requiredList">
            <div *ngIf="this.config[VisaFormName.DOCUMENT]?.fields">
              <p class="fs-h6 mb_10 bold">{{ 'required' | translate }}</p>
              <p *ngIf="config[step.value].requiredList.length < 1">{{ 'select_reason_first' | translate }}</p>
              <div class="flex flex_col gap_20" *ngFor="let item of config[step.value].requiredList">
                <div class="flex gap_10">
                  <fa-icon [icon]="item.icon"></fa-icon>
                  <app-paragraph class="bold">{{ item.text | translate }}</app-paragraph>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="mt_20" *ngIf="config[step.value].visaPrice && document.amountCents > 0">
            <p class="fs-h6 mb_20 bold">{{ 'visaPrice' | translate }}</p>
            <div>
              <app-snackbar>{{getPrice()}}</app-snackbar>
              <div class="mt_10 mb_20">
                <app-paragraph>{{config[step.value].visaPriceText | translate}}</app-paragraph>
              </div>
            </div>
          </div>

          <div class="mb_20" *ngIf="!!config[step.value].textBeforeForm">
            <p class="fs-h6 mb_10 bold"
               *ngIf="config[step.value].textBeforeForm.title">{{config[step.value].textBeforeForm.title | translate}}</p>
            <div class="flex f_col gap_4" *ngIf="config[step.value].textBeforeForm.text"
                 [innerHTML]="config[step.value].textBeforeForm.text | translate"></div>
          </div>

          <div class="mt_20">
            <app-form *ngIf="cart.currentRequest && config && schemaLoaded"
                      [config]="config"
                      [personId]="personId"
                      [classNames]="config[step.value].classNames"
                      [formName]="VisaFormName[step.key]"
                      [submitOnStart]="scanDoc.scanDatas && scanDoc.scanned"
                      (submit)="submitStep($event)"></app-form>
          </div>
          <div class="flex f_col gap_10">
            <app-button *ngFor="let btn of config[step.value].buttons"
                        [disabled]="btn.needAllFields ? !allStepsValid() : false"
                        [size]="btn.size"
                        [ngClass]="btn?.customClass"
                        [icon]="btn.icon"
                        [class]="config[step.value].classNames">{{ (cart.revisionPayment ? 'global.submit' : btn.text) | translate }}
            </app-button>
          </div>
        </ng-container>
      </ng-container>

      <div class="resp_minSize_l mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (click)="previousStep()" [shortcut]="ShortcutAction.ESCAPE" [shortcutStyle]="{bottom: '-25px'}">
          {{'global.previous' | translate}}
        </app-button>
        <app-button size="xsm" type="submit" formId="form" class="fullWidth" (click)="submit()" [shortcut]="ShortcutAction.ENTER" [shortcutStyle]="{bottom: '-25px'}">
          {{(isLastStep() && api.userRole.isWorker ? 'global.submit' : 'global.next') | translate}}
        </app-button>
      </div>
    </div>
  </div>
  <div class="mb_20 resp_maxSize_l">
    <div class="f_align_end gap_10">
      <app-back-btn *ngIf="!api.userRole.isKiosk" class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-back-btn (btnClicked)="cart.resetKioskRequest()" [color]="'#F54321'" icon="arrow-rotate-left" *ngIf="cart.currentRequest?.nationality && cart.currentRequest?.passportType && api.userRole.isKiosk"></app-back-btn>
      <app-back-btn (btnClicked)="previousStep()"></app-back-btn>
      <div class="fullWidth">
        <app-button type="submit" formId="form" class="fullWidth">
          {{(isLastStep() && api.userRole.isWorker ? 'global.submit' : 'global.next') | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AdminRequestNoticeComponent} from './views/admin-request/admin-request-notice/admin-request-notice.component';
import {PrintNoticeReportingComponent} from './components/print-notice-reporting/print-notice-reporting.component';
import {PrintNoticeRestrictionComponent} from './components/print-notice-restriction/print-notice-restriction.component';
import {PrintNoticeRefusalComponent} from './components/print-notice-refusal/print-notice-refusal.component';
import {FaIconComponent, NgxSatorisModule} from 'ngx-satoris';
import {StandbyKioskComponent} from './views/standby-kiosk/standby-kiosk.component';
import {QRCodeModule} from 'angularx-qrcode';
import {StandbyDescKioskComponent} from './views/standby-kiosk/standby-desc-kiosk/standby-desc-kiosk.component';
import {SuccessKioskComponent} from './views/payment/success-kiosk/success-kiosk.component';
import {VisaExtendComponent} from './views/form-visa/8.visa-extend/visa-extend.component';


@NgModule({
  declarations: [
    AdminRequestNoticeComponent,
    PrintNoticeReportingComponent,
    PrintNoticeRestrictionComponent,
    PrintNoticeRefusalComponent,
    StandbyKioskComponent,
    StandbyDescKioskComponent,
    SuccessKioskComponent,
    VisaExtendComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSatorisModule,
    QRCodeModule,
    FaIconComponent
  ],
  providers: [
    AdminRequestNoticeComponent,
    PrintNoticeReportingComponent,
    PrintNoticeRestrictionComponent,
    PrintNoticeRefusalComponent,
    StandbyKioskComponent,
    StandbyDescKioskComponent,
    SuccessKioskComponent,
    VisaExtendComponent
  ],
  exports: [
    PrintNoticeReportingComponent,
    PrintNoticeRestrictionComponent,
    PrintNoticeRefusalComponent
  ]
})
export class ZwevisaModule { }

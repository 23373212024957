<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'peopleFound.title.DYN.' + api.env.type | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="parent-container">
      <app-view-deco-image class="mt_30" imageUrl="assets-projects/images/image_done.svg"></app-view-deco-image>
      <span class="text-center ml_10 mb_40 f_grow">{{'peopleFound.description.DYN.' + api.env.type | translate}}</span>
    </div>
    <div class="btns-container">
      <div class="fullWidth flex f_center">
        <div class="menuButton-container flex f_center">
          <div class="flex gap_10">
            <app-back-btn class="resp_maxSize_l" icon="arrow-left" (btnClicked)="nav.to('dashboard')"></app-back-btn>
            <app-button color="transparent" border="true" size="xsm" type="submit" formId="form" class="fullWidth child-btn resp_minSize_l" (btnClicked)="nav.to('dashboard')">{{'global.previous' | translate}}</app-button>
          </div>
          <div class="ml_10 fullWidth">
            <app-button type="submit" formId="form" class="fullWidth child-btn resp_maxSize_l" (btnClicked)="mockRedirect()">{{'global.next' | translate}}</app-button>
            <app-button size="xsm" type="submit" formId="form" class="fullWidth child-btn resp_minSize_l" (btnClicked)="mockRedirect()">{{'global.next' | translate}}</app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="layout" class="sign_in f_col users-page">
  <div class="scroll_wrapper">
    <app-view-title fromRoute="pre-enroll" [chooseLangBtn]="false">{{'menu.users' | translate}}</app-view-title>
    <div class="user-container" [style.visibility]="!firstCall? 'hidden' : 'visible'">
      <div class="leftSide-container">
        <p class="title-leftSide">{{'form.search' | translate}}</p>
        <div class="input_wrapper mb_20">
          <app-input icon="magnifying-glass" type="text" formCtrlName="text" [formGrp]="form" label="{{'form.name' | translate}}, #id, email@mail.com" class="fullWidth" (input)="query.inputQuery('search',$event)"></app-input>
        </div>
        <app-button size="sm" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="nav.to('user-create')">{{'create_user.title' | translate}}</app-button>
      </div>
      <div class="table-container">
        <div class="rightSide-container">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'form.userId' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'form.name' | translate}} </th>
                <td mat-cell *matCellDef="let element" > {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="role">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> {{'form.role' | translate}} </th>
                <td mat-cell *matCellDef="let element">{{('form.role.' + role[element.role] | lowercase) | translate}}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Email</th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>

              <ng-container matColumnDef="creationDate">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'date.creation' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.creationDate | date}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="nav.to('user/' + row.id)"></tr>
            </table>

          </div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                        [pageSize]="defaultPaginatorSize"
                       (page)="onPageChange($event)"
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="btns-container resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button formId="form" class="fullWidth" (btnClicked)="nav.to('user-create')">{{'create_user.title' | translate}}</app-button>
    </div>
  </div>
</div>

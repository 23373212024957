import {Component, ViewEncapsulation} from '@angular/core';
import {DialogsService, NavigateService} from 'ngx-satoris';
import {CartService} from '../../shared/services/cart.service';
import {ApiService} from '../../shared/services/api.service';
import {DialogNewPersonComponent} from '../../components/dialogs/dialog-new-person/dialog-new-person.component';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PeopleComponent {

  constructor(private dialog: DialogsService,
    public nav: NavigateService,
    public cart: CartService,
    public api: ApiService) {}

  onClick(personId: number) {
    this.nav.to('people/' + personId);
  }

  openDialogNewPerson() {
    this.dialog.openDialog(DialogNewPersonComponent, 'md');
  }
}

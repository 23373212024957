import {Component} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {QrService} from '../../shared/services/qr.service';
import {environment} from 'src/environments/environment';
import {Document, DocumentType} from 'src/app/shared/models/user';
import {RequestState} from 'src/app/shared/models/request';
import {parseIndexedData} from 'src/app/shared/utils/parseMetadata';
import {RequestService} from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent{

  isValid = this.api.userRole.isKiosk || this.qr.currentQrData?.state === RequestState.PAID || this.qr.currentQrData?.state === RequestState.USED || this.qr.currentQrData?.state === RequestState.DELAY_PAY;
  document: Document;
  parseIndexedData = parseIndexedData;

  constructor(public api: ApiService,
    public qr: QrService,
    public request: RequestService) {
    this.document = this.api.userInfo.server.paymentRequests.find(p => p.id === this.qr.currentQrData.operationId);
  }

  ngOnInit() {
    if(this.qr.currentQrData?.metadata?.metadata) {
      this.qr.currentQrData.metadata = {...this.qr.currentQrData.metadata, ...this.qr.currentQrData.metadata.metadata};
    }
  }

  getUsageCount(operationId: string) {
    return this.api.userInfo.server.paymentRequests.find(r => r.id === operationId).usageCount;
  }

  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
}

import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, take, takeUntil, timeout} from 'rxjs/operators';
import {DevicesService, LangService, LoaderService, ShortcutService} from 'ngx-satoris';
import {environment} from '../../../environments/environment';
import {BackendMessage, Results} from '../models/backend-message';
import {Place} from '../models/place';
import * as userPerm from '../models/user';
import {DocumentType, ServerInfo, User, UserPermission, UserPlatformRole} from '../models/user';
import hashPassword from '../utils/verifyPassword';
import {Application} from './application.service';
import {Bundle} from '../models/bundle';
import {Person, PersonLinkType} from '../models/person';
import {
  ComsumePaymentData,
  ConsumeRequestData,
  Request,
  RequestCashType,
  RequestRemoteState,
  RequestState,
  RequestSubState,
  RequestType,
  RequestWithDuplicata,
  VisaApplication
} from '../models/request';
import {BehaviorSubject, Subject, throwError, TimeoutError} from 'rxjs';
import {isEmptyObject} from '../utils/object';
import {S3} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';
declare const window: any;

@Injectable()
export class ApiService extends Application {
  get jwt() {
    return this.jwtToken;
  }

  set jwt(token: string) {
    this.jwtToken = token;
    this.storeItem('jwt', this.jwtToken);
  }

  // Used for authentication towards the API server
  private get httpOptions() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }),
      reportProgress: true
    };
    if(this.jwtToken) {
      options.headers = options.headers.append('Authorization', this.jwtToken);
    }
    return options;
  }

  private signOutSubject = new Subject<void>();
  private jwtToken: string;
  private cancelHttpRequests = new Subject<void>();
  private s3 = new S3({region: 'eu-central-1', credentials: {accessKeyId: 'AKIAVW5IKIIBH7DOMXV3', secretAccessKey: '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'}});
  public signOutObservable = this.signOutSubject.asObservable();
  public perm = (userPerm as any).UserPermission;
  public progressUploadMap$: BehaviorSubject<{ [key: string]: number }> = new BehaviorSubject<{ [key: string]: number }>({});
  public progressDownloadMap$: BehaviorSubject<{ [key: string]: number }> = new BehaviorSubject<{ [key: string]: number }>({});

  configuration: ServerInfo;
  userInfo: User;
  listPersons: Person[];
  userPlaceId: string;
  userPlaces: Place[];
  queueIsSyncing = false;
  pingInterval: NodeJS.Timeout;
  connectDailyOtp: string;
  isOnline = true;
  isCordovaApp = !!window.cordova;
  isElectronApp = !!window.electron;
  userRole: {
    isOrbis: boolean;
    isWorker: boolean;
    isCustomer: boolean;
    isAdmin: boolean;
    isPartnerRegister: boolean;
    isKiosk: boolean;
  } = undefined;
  flexibleUri = environment.baseUri;
  env = environment;
  isZwevisa = this.env.type === DocumentType.ZWEVISA;
  directReturnError: boolean = false;
  customTimeout: number = undefined;

  constructor(private http: HttpClient,
    protected router: Router,
    private lang: LangService,
    private deviceService : DevicesService,
    private shortcuts: ShortcutService,
    private loader: LoaderService) {
    super(router);
    // Recover place
    this.userPlaceId = this.getStoredItem('placeId');
    // No backup?
    this.env.backupUri = this.env.backupUri || this.flexibleUri;
  }

  // Mettre à jour la progression pour une URL spécifique
  updateProgress(url: string, progress: number, isUpload: boolean): void {
    if(isUpload) {
      const currentProgress = this.progressUploadMap$.value;
      currentProgress[url] = progress;
      this.progressUploadMap$.next({...currentProgress});
    } else {
      const currentProgress = this.progressDownloadMap$.value;
      currentProgress[url] = progress;
      this.progressDownloadMap$.next({...currentProgress});
    }
  }

  // Obtenir une liste des progressions
  getProgressValues(isUpload: boolean, limit: number): number[] {
    if(isUpload) {
      return Object.values(this.progressUploadMap$.value).slice(0, limit);
    } else {
      return Object.values(this.progressDownloadMap$.value).slice(0, limit);
    }
  }

  //Vérifier si une URL a une progression
  hasProgress(url: string, isUpload: boolean): boolean {
    if(isUpload) {
      return this.progressUploadMap$.value[url] !== undefined;
    } else {
      return this.progressDownloadMap$.value[url] !== undefined;
    }
  }

  // Réinitialiser la progression pour une URL spécifique
  resetProgress(url: string, isUpload: boolean): void {
    if(isUpload) {
      const currentProgress = this.progressUploadMap$.value;
      delete currentProgress[url];
      this.progressUploadMap$.next({...currentProgress});
    } else {
      const currentProgress = this.progressDownloadMap$.value;
      delete currentProgress[url];
      this.progressDownloadMap$.next({...currentProgress});
    }
  }

  signOut(navigate?: string) {
    this.jwt = undefined;
    this.userInfo = undefined;
    this.connectDailyOtp = undefined;
    this.userPlaceId = '';
    this.removeStoredItem('placeId');
    clearInterval(this.pingInterval);
    this.pingInterval = undefined;
    this.flexibleUri = environment.baseUri;
    this.removeStoredItem('currentQrData');
    this.removeStoredItem('cache.infos');
    this.removeStoredItem('previous_url');
    this.removeStoredItem('consumeMode');
    this.removeStoredItem('cameraRotate');
    this.removeStoredItem('printThermal');
    this.clearLocalStorageItemsWithPrefix('places');
    this.clearLocalStorageItemsWithPrefix('payment');
    this.removeStoredItem('cameras');
    this.cancelRequests();
    this.queueIsSyncing = false;
    environment.type === DocumentType.PASS && this.removeStoredItem('lang');
    this.removeStoredItem('jwt');
    window.initialQueryString = [];
    this.signOutSubject.next();
    return navigate ? this.router.navigate([navigate]) : this.router.navigate(['/']);
  }

  schema(schemaId: string, storage = true): Promise<any> {
    const cachedData = storage ? JSON.parse(sessionStorage.getItem('schema_' + schemaId) || 'null') : null;
    if(cachedData) {
      return Promise.resolve(cachedData);
    }
    return new Promise((resolve, reject) => {
      const schemaCall = (url: string) => this.http.get<Results<any>>(url + 'schema/' + schemaId, this.httpOptions).pipe(timeout(20000), take(1));
      schemaCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return schemaCall(this.env.backupUri);
        }
        return throwError(() => error);
      }))
        .subscribe((res) => {
          if(storage) {
            sessionStorage.setItem('schema_' + schemaId, JSON.stringify(res.result));
          }
          resolve(res.result);
        },
        (err) => {
          this.makeReject(reject)(err);
        });
    });
  }

  contact(message: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/contact', {
        message: message
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  users(links: boolean, orderColumn?: 'id' | 'name' | 'role' | 'accountName', orderAsc?: 'ASC' | 'DESC', offset?: number, limit?: number): Promise<Results<User[]>> {
    return new Promise((resolve, reject) => {
      const usersCall = (base: string) => {
        return this.http.get<Results<User[]>>(`${base}users?1=1` + (links ? '&links=true' : '') + (orderColumn ? `&orderColumn=${orderColumn}` : '')
          + (orderAsc ? `&orderAsc=${orderAsc}` : '') + (offset ? `&offset=${offset}` : '') + (limit ? `&limit=${limit}` : ''), this.httpOptions).pipe(timeout(20000), take(1));
      };
      usersCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return usersCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe((res: Results<User[]>) => {
        this.processMessages(res);
        resolve(res);
      }, this.makeReject(reject, false));
    });
  }

  user(userId: number): Promise<User> {
    return new Promise((resolve, reject) => {
      const userCall = (url: string) => this.http.get<Results<User>>(url + 'user/' + userId, this.httpOptions).pipe(timeout(20000), take(1));
      userCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return userCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe((res: Results<User>) => {
        this.processMessages(res);
        resolve(res.result);
      }, this.makeReject(reject, false));
    });
  }

  userFingerprint(userAccountName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get<undefined>(environment.baseUri + 'user/' + encodeURIComponent(userAccountName.toLowerCase()) + '/fingerprint', this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  info(): Promise<User> {
    const infoCall = (url: string) => this.http.get<Results<User>>(url + 'users/info', this.httpOptions).pipe(timeout(20000), take(1));
    if(!this.isOnline) {
      const cached = this.getStoredItem('cache.infos');
      if(cached) return Promise.resolve(cached[this.userInfo.accountName]);
    }
    return new Promise((resolve, reject) => {
      infoCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return infoCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe((res: Results<User>) => {
        this.processMessages(res);
        // General set up thanks to having the user finally!
        this.userInfo = res.result;
        this.userRole =  {
          isOrbis: this.userInfo.role === UserPlatformRole.ORBIS_CONSUMER,
          isWorker: this.userInfo.role === UserPlatformRole.WORKER,
          isCustomer: this.userInfo.role === UserPlatformRole.CUSTOMER,
          isAdmin: this.userInfo.role === UserPlatformRole.ADMIN,
          isPartnerRegister: this.userInfo.role === UserPlatformRole.PARTNER_REGISTRER,
          isKiosk: this.userInfo.role === UserPlatformRole.KIOSK
        };

        // Set up shortcuts for the user role
        this.shortcuts.enabled = !(this.userRole.isCustomer || this.userRole.isKiosk) && this.deviceService.isDevices('desktop');

        if(this.userRole.isKiosk) {
          document.body.classList.add('kiosk');
        } else {
          document.body.classList.remove('kiosk');
        }

        if(this.userPlaceId && (this.userRole.isWorker || this.userRole.isAdmin)) {
          this.listPlaces([this.userPlaceId]).then((places: Place[]) => {
            this.userPlaces = places;
          });
        }

        const cached = this.getStoredItem('cache.infos') || {};
        cached[this.userInfo.accountName] = this.userInfo;
        this.storeItem('isBMS', this.userRole.isWorker || this.userRole.isAdmin);
        this.storeItem('cache.infos', cached);
        if(this.userRole.isKiosk) {
          const glpiWidget = document.getElementById('glpi_widget');
          if(glpiWidget) glpiWidget.remove();
        }
        if(this.userRole.isCustomer) {
          this.persons().then((persons: Person[]) => {
            this.listPersons = persons;
            resolve(res.result);
          });
        } else {
          resolve(res.result);
        }
      }, this.makeReject(reject));
    });
  }

  consumeLoginWeak(accountName: string, password: string, placeId?: string, place_otp?: string, adConnection = false): Promise<[boolean, number | string[]]> {
    const loginCall = (url: string) => this.http.post<{access_token: string}>(url + 'login', {
      username: accountName.toLowerCase().split('@')[0],
      password: password,
      office: placeId
    }, this.httpOptions).pipe(timeout(3000), take(1));
    const personOfficeCall = (url: string) => this.http.post<{offices: string[]}>(url + 'person-offices', {
      username: accountName.toLowerCase().split('@')[0],
      password: password
    }, this.httpOptions).pipe(timeout(3000), take(1));
    const loginWeakCall = (url: string) => this.http.post<Results<{jwt: string}>>(url + 'users/login_weak', {
      account_name: accountName.toLowerCase() + (accountName.indexOf('@') > -1 ? '' : ('@' + environment.domain)),
      password: password,
      place_id: placeId || undefined,
      place_otp: place_otp || undefined
    }, {...this.httpOptions, observe: 'response'}).pipe(timeout(adConnection ? 3000 : 30000), take(1));
    if(!this.isOnline) {
      const cached = this.getStoredItem('cache.infos') || {};
      if(cached && cached[accountName]) {
        return hashPassword(password, cached[accountName].salt).then(hash => {
          if(hash === cached[accountName].passwordHash) {
            this.jwt = 'dummy';
            this.userPlaceId = this.getStoredItem('placeId');
            this.userInfo = cached[accountName];
            return Promise.resolve([true, 200]);
          }
          return Promise.reject('err.consumeLoginWeak');
        });
      }
      return Promise.reject('err.consumeLoginWeak');
    } else {
      return new Promise<[boolean, number | string[]]>((resolve, reject) => {
        if(environment.authUri && adConnection) {
          if(placeId) {
            loginCall(environment.authUri).subscribe(res => {
              this.exchange(res.access_token, password).then(s => resolve([false, s]), reject);
            }, reject);
          } else {
            personOfficeCall(environment.authUri).subscribe(res => resolve([false, res.offices]), reject);
          }
        } else {
          reject('No AD connection');
        }
      }).catch(() => new Promise<[boolean, number | string[]]>((resolve, reject) => {
        loginWeakCall(this.flexibleUri).pipe(catchError(error => {
          if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
            this.handleBackup();
            return loginWeakCall(this.env.backupUri);
          }
          return throwError(() => error);
        })).subscribe(res => {
          this.processMessages(res.body);
          this.jwt = res.body.result.jwt;
          if(placeId) {
            this.userPlaceId = placeId;
            this.storeItem('placeId', placeId);
          }
          resolve([true, res.status]);
        }, reject);
      })).catch((err: any) => new Promise((_, reject) => this.makeReject(reject, false)(err)));
    }
  }

  exchange(jwt: string, password: string): Promise<number> {
    const body = JSON.parse(atob(jwt.split('.')[1]));
    return new Promise((resolve, reject) => {
      this.jwtToken = jwt;
      const exchangeCall = (url: string) => this.http.post<Results<{jwt: string}>>(url + 'users/exchange', {
        password: password
      }, {...this.httpOptions, observe: 'response'}).pipe(timeout(1500), take(1));
      exchangeCall(environment.baseUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          return exchangeCall(environment.baseUri).pipe(catchError(error => {
            if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
              this.handleBackup();
              return exchangeCall(environment.backupUri);
            }
            return throwError(() => error);
          }));
        }
        return throwError(() => error);
      })).subscribe(res => {
        this.processMessages(res.body);
        this.jwt = res.body.result.jwt;
        if(body.office) {
          this.userPlaceId = body.office;
          this.storeItem('placeId', body.office);
        }
        resolve(res.status);
      }, this.makeReject(reject, false));
    });
  }

  register(accountName: string, password: string, userId: string, name: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/register', {
        account_name: accountName.toLowerCase(),
        password: password,
        user_id: userId,
        name: name
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  registerAdmin(accountName: string, password: string, role: UserPlatformRole, userId: string, name: string, createPlaceId?: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/register', {
        account_name: accountName.toLowerCase(),
        password: password,
        role: role,
        user_id: userId,
        name: name,
        create_place_id: createPlaceId || undefined
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  rearm(accountName: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.get<undefined>(environment.baseUri + 'users/rearm/' + encodeURIComponent(accountName.toLowerCase()), this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  reset(accountName: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/reset/' + encodeURIComponent(accountName.toLowerCase()), {}, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  receive(accountName: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/receive/' + encodeURIComponent(accountName.toLowerCase()), {}, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  confirm(userId: number, salt: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.get<undefined>(environment.baseUri + 'users/confirm/' + userId + '/' + salt, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  resetPassword(accountName: string, resetKey: string, password: string, otp?: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/recover/' + encodeURIComponent(accountName.toLowerCase()), {
        reset_key: resetKey,
        password: password,
        otp: otp || undefined
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, reject);
    });
  }

  setPassword(password: string, fingerBytes: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/password', {
        password: password,
        finger_bytes: fingerBytes
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  logout(): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/password', {}, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  places(userId: number, createPlacesIds: string[], consumePlacesIds: string[], controlPlacesIds: string[], adminPlacesIds: string[], seniority: number): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/' + userId + '/places', {
        create_places_ids: createPlacesIds,
        consume_places_ids: consumePlacesIds,
        control_places_ids: controlPlacesIds,
        admin_places_ids: adminPlacesIds,
        seniority: parseInt(seniority as any) > 0 ? seniority : 1
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  lock(userId: number, lock: boolean): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/' + userId + '/lock', {
        lock: lock
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  updateUser(userId: number, externalId: string, accountName: string, name: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/' + userId + '/update', {
        user_id: externalId,
        account_name: accountName,
        name: name
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  adminSearchUser(search: string): Promise<User[]> {
    return new Promise((resolve, reject) => {
      const adminSearchUserCall = (url: string) => this.http.get<Results<User[]>>(url + 'admin/users/search/' + encodeURIComponent(search), this.httpOptions).pipe(timeout(20000), take(1));
      adminSearchUserCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return adminSearchUserCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  sendEmail(subject: string, html: string, email: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/email', {
        subject: subject,
        html: html,
        email: email
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  facialCheck(firstPicture: string, secondPicture: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<number>>(environment.baseUri + 'admin/users/facial', {
        first_picture: firstPicture,
        second_picture: secondPicture
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  place(id: string): Promise<Place> {
    const place: Place = JSON.parse(localStorage.getItem('places' + id) || 'null');
    return new Promise((resolve, reject) => {
      const options = this.httpOptions;
      if(place) options.headers = options.headers.append('If-Modified-Since', place.fetchedAt);
      const placeCall = (url: string) => this.http.get<Results<Place>>(url + 'place/' + id, {...options, observe: 'response'}).pipe(timeout(20000), take(1));
      placeCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return placeCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(res => {
        const newPlace = res.body.result;
        localStorage.setItem('places' + id, JSON.stringify({...newPlace, fetchedAt: new Date(Date.now() - 300000).toUTCString()}));
        resolve(newPlace);
      }, err => {
        if(err.status === 304) {
          resolve(place);
        } else {
          this.makeReject(reject)(err);
        }
      });
    });
  }

  placeSerial(id: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<number>>(environment.baseUri + 'place/' + id + '/serial', {}, this.httpOptions)
        .pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  listPlaces(ids: string[]): Promise<Place[]> {
    return new Promise((resolve, reject) => {
      const listPlacesCall = (url: string) => this.http.get<Results<Place[]>>(url + 'places/list/' + ids.join('|'), this.httpOptions).pipe(timeout(20000), take(1));
      listPlacesCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return listPlacesCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  searchPlace(search?: string, offset?: number, limit?: number): Promise<Results<Place[]>> {
    return new Promise((resolve, reject) => {
      const searchPlaceCall = (url: string) => this.http.get<Results<Place[]>>(url + 'places/search?1=1' + (search ? '&search=' + encodeURIComponent(search) : '')
        + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''), this.httpOptions).pipe(timeout(20000), take(1));
      searchPlaceCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return searchPlaceCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(resolve, this.makeReject(reject));
    });
  }

  createPlace(id: string, name: string, parentId: string, externalCode: string, specifiedNationals: string[]): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'places/create', {
        id: id,
        long_name: name,
        parent_id: parentId || undefined,
        external_code: externalCode || undefined,
        specified_nationals: specifiedNationals || undefined
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  updatePlace(place: Place): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'place/' + place.id + '/update', {
        long_name: place.longName,
        phone_numbers_csv: place.phoneNumbersCsv,
        connect_ua_pattern: place.connectUAPattern,
        connect_ip_range: place.connectIPRange,
        parent_id: place.parent_id,
        external_code: place.externalCode,
        specified_nationals: place.specifiedNationals
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  deletePlace(placeId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'place/' + placeId, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  bundle(id: string): Promise<Bundle> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Bundle>>(environment.baseUri + 'bundle/' + id, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  getRequests(): Promise<Request> {
    return new Promise((resolve, reject) => {
      const getRequestsCall = (url: string) => this.http.get<Results<Request>>(url + 'requests', this.httpOptions).pipe(timeout(20000), take(1));
      getRequestsCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return getRequestsCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe((res: Results<Request>) => resolve(res.result), this.makeReject(reject));
    });
  }

  getRequest(requestId: number): Promise<Request> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Request>>(environment.baseUri + 'requests/' + requestId, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe((res: Results<Request>) => resolve(res.result), this.makeReject(reject));
    });
  }

  searchRequest(metadata: string, createdAtStart?: Date, createdAtEnd?: Date, type?: RequestType, offset?: number, limit?: number): Promise<Results<Request[]>> {
    return new Promise((resolve, reject) => {
      const searchRequestCall = (url: string) => this.http.get<Results<Request[]>>(url + 'requests/search/' + encodeURIComponent(metadata) + '?1=1'
        + (createdAtStart ? '&createAtStart=' + createdAtStart.getTime() : '') + (createdAtEnd ? '&createAtEnd=' + createdAtEnd.getTime() : '') + (type ? '&type=' + type : '')
        + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''), this.httpOptions).pipe(timeout(20000), take(1));
      searchRequestCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return searchRequestCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(resolve, this.makeReject(reject));
    });
  }

  createBundle(paymentIds: string[], placeId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'bundles/create', {
        payment_ids: paymentIds,
        place_id: placeId || undefined
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  deleteBundle(bundleId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'bundle/' + bundleId, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  persons(): Promise<Person[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Person[]>>(environment.baseUri + 'persons', this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  personRequests(personId: number): Promise<Request[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Request[]>>(environment.baseUri + 'person/' + personId + '/requests', this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  createPerson(nationalNumber: string, firstName: string, lastName: string, passportNumber: string, linkType: PersonLinkType, otherLinkType: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<number>>(environment.baseUri + 'persons/create', {
        national_number: nationalNumber,
        first_name: firstName,
        last_name: lastName,
        passport_number: passportNumber,
        link_type: linkType,
        other_link_type: otherLinkType || undefined
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  updatePerson(personId: number, nationalNumber: string, firstName: string, lastName: string, passportNumber: string, linkType: PersonLinkType, otherLinkType?: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'person/' + personId, {
        national_number: nationalNumber,
        first_name: firstName,
        last_name: lastName,
        passport_number: passportNumber,
        link_type: linkType,
        other_link_type: otherLinkType
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  deletePerson(personId: number): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'person/' + personId, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  payment(id: string, history = false, watchlist = false, apipnr = false, inbound = true, storage = false): Promise<RequestWithDuplicata> {
    const payment: RequestWithDuplicata = storage && JSON.parse(localStorage.getItem('payment' + history + watchlist + apipnr + inbound + id) || 'null');
    return new Promise((resolve, reject) => {
      const options = this.httpOptions;
      if(payment && storage) options.headers = options.headers.append('If-Modified-Since', payment.fetchedAt);
      const paymentCall = (url: string) => this.http.get<Results<RequestWithDuplicata>>(url + 'payment/' + id + '?1=1'
        + (history ? '&history=true' : '') + (watchlist ? '&watchlist=true' : '')
        + (apipnr ? '&apipnr=true' : '') + (inbound ? '&inbound=true' : '&inbound=false'), {...options, observe: 'response'}).pipe(timeout(20000), take(1));
      paymentCall(this.flexibleUri).pipe(catchError(error => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return paymentCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(res => {
        const newPayment = res.body.result;
        if(storage) {
          localStorage.setItem('payment' + history + watchlist + apipnr + inbound + id, JSON.stringify({...newPayment, fetchedAt: new Date(Date.now() - 300000).toUTCString()}));
        }
        resolve(newPayment);
      }, err => {
        if(err.status === 304) {
          resolve(payment);
        } else {
          this.makeReject(reject)(err);
        }
      });
    });
  }

  getMetadataPayment(url: string, paymentId?: string): Promise<VisaApplication | ConsumeRequestData> {
    if(!url || url === 'undefined') return;
    return new Promise((resolve, reject) => {
      if(paymentId) url += '?paymentId=' + paymentId;
      const baseUrl = this.flexibleUri === this.env.backupUri ? url.replace(this.env.baseUri, this.env.backupUri) : url;
      this.http.get<VisaApplication>(baseUrl, this.httpOptions)
        .pipe(timeout(20000),
          take(1),
          catchError(error => {
            if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
              this.handleBackup();
              return this.http.get<VisaApplication>(url.replace(this.env.baseUri, this.env.backupUri), this.httpOptions).pipe(timeout(20000), take(1));
            }
            return throwError(() => error);
          })).subscribe(res => resolve(res), this.makeReject(reject));
    });
  }

  lockPayment(paymentId: string, lock: boolean, reason: string, createdPlaceId?: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/lock', {
        lock: lock,
        reason: reason,
        created_place_id: createdPlaceId || undefined
      }, this.httpOptions).pipe(timeout(3000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  myPayments(currency?: string, search?: string): Promise<Request[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Request[]>>(environment.baseUri + 'payments/me?1=1'
        + (currency ? '&currency=' + currency : '') + (search ? '&search=' + encodeURIComponent(search) : ''), this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  searchPayment(currency?: string, search?: string, createAtStart?: Date, createAtEnd?: Date, createdPlaceId?: string, type?: string,
    userId?: number, state?: RequestState, internalReference?: string, subState?: RequestSubState, usageUntilStart?: Date, usageUntilEnd?: Date,
    localUsageUntilStart?: Date, localUsageUntilEnd?: Date, offset?: number, limit?: number, orderAsc?: 'ASC' | 'DESC'): Promise<Results<Request[]>> {
    return new Promise((resolve, reject) => {
      const searchCall = (url: string) => this.http.get<Results<Request[]>>(url + 'payments/search?1=1'
          + (currency ? '&currency=' + currency : '') + (search ? '&search=' + encodeURIComponent(search) : '')
          + (createAtStart ? '&createAtStart=' + createAtStart.getTime() : '') + (createAtEnd ? '&createAtEnd=' + createAtEnd.getTime() : '')
          + (createdPlaceId ? '&createdPlaceId=' + createdPlaceId : '') + (type ? '&type=' + type : '')
          + (userId ? '&userId=' + userId : '') + (state ? '&state=' + state : '')
          + (internalReference ? '&internalReference=' + internalReference : '') + (subState ? '&subState=' + subState : '')
          + (usageUntilStart ? '&usageUntilStart=' + usageUntilStart.getTime() : '') + (usageUntilEnd ? '&usageUntilEnd=' + usageUntilEnd.getTime() : '')
          + (localUsageUntilStart ? '&localUsageUntilStart=' + localUsageUntilStart.getTime() : '') + (localUsageUntilEnd ? '&localUsageUntilEnd=' + localUsageUntilEnd.getTime() : '')
          + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : '') + (orderAsc ? '&orderAsc=' + orderAsc : '&orderAsc=ASC'), this.httpOptions).pipe(timeout(20000), take(1));
      searchCall(this.flexibleUri).pipe(catchError((error) => {
        if(error instanceof TimeoutError || (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 502))) {
          this.handleBackup();
          return searchCall(this.env.backupUri);
        }
        return throwError(() => error);
      })).subscribe(res => resolve(res), this.makeReject(reject));
    });
  }

  registerCashPayment(id: string, requestId: string, metadata: string, type: RequestCashType, createPlaceId: string, customer_email?: string,
    external_id?: string, user_id?: string, confirmed = true, serialized?: string, batchId?: string): Promise<{id: string, signature: string}> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{id: string, signature: string}>>(environment.baseUri + 'payments/create/cash', {
        id: id || undefined,
        request_id: requestId,
        metadata: metadata,
        type: type,
        created_place_id: createPlaceId,
        customer_email: customer_email,
        external_id: external_id || undefined,
        user_id: user_id,
        confirmed: confirmed,
        serialized: serialized,
        batch_id: batchId
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  registerPaypalPayment(requestId: string, personId: number, serialized: string, batchId?: string, online = false): Promise<{id: string, signature: string, link: string}> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{id: string, signature: string, link: string}>>(environment.baseUri + 'payments/create/paypal', {
        request_id: requestId,
        person_id: personId || undefined,
        serialized: serialized,
        batch_id: batchId,
        online: online
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  registerWUPayment(requestId: string, personId?: number, serialized?: string, batchId?: string, online = false): Promise<{id: string, signature: string, link: string}> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{id: string, signature: string, link: string}>>(environment.baseUri + 'payments/create/wu', {
        request_id: requestId,
        person_id: personId || undefined,
        serialized: serialized,
        batch_id: batchId,
        online: online
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  validateWUPayment(requestId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payments/validate/wu', {
        id: requestId
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  registerMNTAPayment(payments: {request_id: string, person_id: number, serialized: string}[], batchId?: string, previousBatchId?: string, online = false): Promise<{ids: string[], body: any}> {
    const body = {
      payments: payments,
      batch_id: batchId,
      previous_batch_id: previousBatchId,
      online: online
    };
    if(this.userInfo.role === UserPlatformRole.CUSTOMER && payments.some(p => p.serialized?.length > 10000)) {
      const key = 'applications/' + environment.appBuild + '-' + Math.random().toString(36).substring(2) + '.json';
      return new Upload({client: this.s3,
        params: {
          Bucket: 'st-public',
          Key: key,
          Body: JSON.stringify(body),
          ACL: 'public-read'
        }
      }).done().then(() => new Promise((resolve, reject) => {
        this.http.post<Results<{ids: string[], body: any}>>(environment.baseUri + 'payments/create/mnta', {
          host: 'st-public.s3.eu-central-1.amazonaws.com',
          key: '/' + key
        }, this.httpOptions).pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
      }), (err: any) => new Promise((_, reject) => this.makeReject(reject, false)(err)));
    }
    return new Promise((resolve, reject) => {
      this.http.post<Results<{ids: string[], body: any}>>(environment.baseUri + 'payments/create/mnta', body, this.httpOptions)
        .pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  validatePayment(paymentId: string, validatedPlaceId: string, refuse: boolean, refusalReason: string, usageUntil?: Date): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/validate', {
        validated_place_id: validatedPlaceId,
        refuse: refuse,
        refusal_reason: refusalReason,
        usage_until: usageUntil ? usageUntil.getTime() : undefined
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  chargeBackPayment(paymentId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/charge_back', {}, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  updatePayment(id: string, metadata: string, externalId: string, serialized: string, remoteState: RequestRemoteState, remoteStateReason: string,
    internalReference?: string, operationId?: DocumentType, usageAfter?: Date, usageUntil?: Date): Promise<undefined> {
    const body = {
      metadata: metadata,
      external_id: externalId,
      serialized: serialized,
      remote_state: remoteState,
      remote_state_reason: remoteStateReason,
      internal_reference: internalReference || undefined,
      operation_id: operationId,
      usage_after: usageAfter ? usageAfter.getTime() : undefined,
      usage_until: usageUntil ? usageUntil.getTime() : undefined
    };
    if(this.userInfo.role === UserPlatformRole.CUSTOMER && serialized?.length > 10000) {
      const key = 'applications/' + environment.appBuild + '-' + Math.random().toString(36).substring(2) + '.json';
      return new Upload({client: this.s3,
        params: {
          Bucket: 'st-public',
          Key: key,
          Body: JSON.stringify(body),
          ACL: 'public-read'
        }
      }).done().then(() => new Promise((resolve, reject) => {
        this.http.post<undefined>(environment.baseUri + 'payment/' + id + '/update', {
          host: 'st-public.s3.eu-central-1.amazonaws.com',
          key: '/' + key
        }, this.httpOptions).pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
      }), (err: any) => new Promise((_, reject) => this.makeReject(reject, false)(err)));
    }
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + id + '/update', body, this.httpOptions)
        .pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  changePayment(id: string, state?: RequestState, subState?: RequestSubState): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + id + '/change', {
        state: state,
        sub_state: subState
      }, this.httpOptions).pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  consumePayment(paymentId: string, consumedPlaceId: string, createdPlaceId?: string, createdAt?: Date, consumeMessage?: string, consumeData?: ComsumePaymentData, localUsageUntil?: Date): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/consume?1=1'
        + (createdPlaceId ? '&createdPlaceId=' + createdPlaceId : '') + (createdAt ? '&createdAt=' + new Date(createdAt).getTime() : ''), {
        consumed_place_id: consumedPlaceId,
        consume_message: consumeMessage || undefined,
        consume_data: consumeData ? JSON.stringify(consumeData) : undefined,
        local_usage_until: localUsageUntil ? new Date(localUsageUntil).getTime() : undefined
      }, this.httpOptions).pipe(takeUntil(this.cancelHttpRequests)).pipe(timeout(this.customTimeout || 20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  voidConsumePayment(paymentId: string, platformEventId: number, reason: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/void/' + platformEventId, {
        reason: reason
      }, this.httpOptions).pipe(takeUntil(this.cancelHttpRequests)).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  suspendPayment(paymentId: string, suspend: boolean, reason: string, refusalRefusedFields: string[], created_place_id: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/suspend', {
        suspend: suspend,
        reason: reason,
        refusal_refused_fields: refusalRefusedFields,
        created_place_id: created_place_id
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  duplicatePayment(paymentId: string, createdPlaceId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/duplicate', {
        created_place_id: createdPlaceId
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  refundPayment(paymentId: string, refundedPlaceId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/refund', {
        refunded_place_id: refundedPlaceId
      }, this.httpOptions).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  selfRefundPayment(paymentId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'payment/' + paymentId + '/self_refund', {}, this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  getZ(placeId: string, start: Date, end: Date): Promise<Request[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Request[]>>(environment.baseUri + 'payments/z/' + placeId + '?1=1'
        + '&start=' + start.getTime() + '&end=' + end.getTime(), this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  getConsilidatedZ(placeId: string, start: Date, end: Date): Promise<Request[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Request[]>>(environment.baseUri + 'payments/consolidatedz/' + placeId + '?1=1'
        + '&start=' + start.getTime() + '&end=' + end.getTime(), this.httpOptions)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  hasPerm(permission: UserPermission): boolean {
    if(!this.userRole?.isCustomer && !this.userRole?.isKiosk && this.userInfo) {
      if(this.isSuperAdmin()) {
        return true;
      } else {
        const current: any = this.userInfo.links.filter(x => x.place_id === this.userPlaceId)[0];
        if(current === undefined) {
          this.signOut().then();
        }
        return !!current[permission];
      }
    }
  }

  checkSeniority(): number | null {
    if(this.userRole.isWorker && this.hasPerm(UserPermission.ALLOW_CONTROL)) {
      return this.userInfo.links.filter(x => x.place_id === this.userPlaceId)[0].seniority;
    } else {
      return null;
    }
  }

  isSuperAdmin():boolean {
    return this.userInfo.role === UserPlatformRole.ADMIN;
  }

  private semverAbove(ver: number[], ref: number[], strict = true) {
    for(let i = 0; i < ver.length && i < ref.length; i++) {
      if(ver[i] > ref[i]) return true;
    }
    return strict ? ver.length > ref.length : ver.length >= ref.length;
  }

  private processMessages(msg: BackendMessage) {
    if(!msg) return;
    if((msg.disableIphoneVersion && this.deviceService.isDevices('cordova') && window.device.platform.toLowerCase() === 'ios' && this.semverAbove(msg.disableIphoneVersion, environment.appVersion, true))
    || (msg.disableAndroidVersion && this.deviceService.isDevices('cordova') && window.device.platform.toLowerCase() !== 'ios' && this.semverAbove(msg.disableAndroidVersion, environment.appVersion, true))
    || (msg.disableWebVersion && !this.deviceService.isDevices('cordova') && this.semverAbove(msg.disableWebVersion, environment.appVersion, true))) {
      document.body.innerHTML = '<p>Epayment Around360 is currently disabled on this medium. Please visit our other platforms.</p>';
      return;
    }
    if(msg.targetAppVersion && this.semverAbove(msg.targetAppVersion, environment.appVersion)) {
      if(this.deviceService.isDevices('cordova')) {
        document.body.innerHTML = '<p>This app needs update in store.</p>';
      } else {
        this.clearStoredItem();
        window.location.reload(true);
      }
      return;
    }
  }

  private makeReject(reject: (error: any) => void, logout = true): (error: any) => void {
    if(this.directReturnError) return reject;
    let stack = 'err';
    try {
      stack = new Error().stack.split('\n')[5].match(/\.([^ ]+) /)[1];
    } catch (e) { /* empty */ }
    return (err: {error?: BackendMessage, status?: number}) => {
      if((err.status === 401 || err.status === 403) && logout && !this.userRole?.isKiosk) {
        this.signOut().then(() => window.electron ? window.location.reload() : location.href = '/', () => window.location.reload(true));
        this.loader.loading(false);
        return;
      }
      let msg = err?.error && err?.error?.error && err?.error?.error?.additionalInfo || err?.error?.message;
      if(err.status === 600 && isEmptyObject(msg)) {
        msg = 'external.down';
      } else if(err instanceof TimeoutError) {
        msg = 'global.connection.timeout';
      } else if(err instanceof HttpErrorResponse && (err.status === 0 || err.status === 502)) {
        msg = err.status === 0? 'global.connection.problem' : 'global.connection.server';
      }
      if(msg) {
        const msg2 = err?.error?.error?.detailedInfo;
        reject(this.lang.transform('err.' + stack) + ': ' + this.lang.transform(msg) + (msg2 ? (' (' + msg2 + ')') : ''));
      } else {
        reject('err.' + stack);
      }
    };
  }

  public clearLocalStorageItemsWithPrefix(prefix: string) {
    const keys = Object.keys(localStorage);
    const keysToClear = keys.filter(key => key.startsWith(prefix));
    keysToClear.forEach(key => localStorage.removeItem(key));
  }

  cancelRequests() {
    this.cancelHttpRequests.next();
  }

  ping(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.get<Results<string>>(url + 'ping')
        .pipe(timeout(2 * 1000), take(1))
        .subscribe(res => resolve(res.result), reject);
    });
  }

  handleBackup() {
    this.flexibleUri = environment.backupUri;
    if(!this.pingInterval) {
      this.pingInterval = setInterval(() => {
        this.ping(environment.baseUri).then(() => {
          clearInterval(this.pingInterval);
          this.pingInterval = null;
          this.flexibleUri = environment.baseUri;
        }, () => {});
      }, 10 * 1000);
    }
  }
}

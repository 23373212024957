import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {ChoiceButton, FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {CartService, FormApplication} from '../../../shared/services/cart.service';
import {Direction, FormField, FormStep, VisaFN, VisaFormConfig, VisaFormName} from '../../../shared/models/forms';
import {RequestService} from '../../../shared/services/request.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CurrencySign, Request} from '../../../shared/models/request';
import {FormError} from 'ngx-satoris/lib/shared/models/forms';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {UserPermission} from '../../../shared/models/user';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';

@Component({
  selector: 'app-5.summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SummaryComponent {

  currentApplication: FormApplication;
  subscription: any;
  form: FormGroup;
  keepScanned = false;
  submitted: boolean;
  disabledConfirm: boolean;
  showPayOnline: boolean;
  choiceButtons: ChoiceButton[] = [
    {text: 'Yes', value: 'true', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
    {text: 'No', value: 'false', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
  ];

  constructor(public api: ApiService,
    public nav: NavigateService,
    public cart: CartService,
    public request: RequestService,
    public forms: FormService,
    private formBuilder: FormBuilder,
    private inactivity: InactivityService,
    private lang: LangService,
    private loader: LoaderService,
    private scanDoc: ScanDocumentService) {
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(!this.cart.currentRequest) {
        if((this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CREATE) && this.api.hasPerm(UserPermission.ALLOW_CONSUME))) {
          this.nav.to('dashboard');
        } else if(this.api.userRole.isKiosk) {
          this.nav.to('standby-kiosk');
        } else {
          this.nav.to('travel-name');
        }
        return;
      } else if(!this.cart.allPersonRequestsAreValid()) {
        if(this.cart.currentRequest?.latestRouteVisited) {
          this.nav.to(this.cart.currentRequest.latestRouteVisited);
        } else {
          this.nav.to('travel-name');
        }
      }
      this.currentApplication = this.cart.currentApplication;
      this.cart.currentRequest = {...this.cart.currentRequest, latestRouteVisited: 'summary'};
      this.cart.saveCurrentApplication(false);
      this.showPayOnline = this.currentApplication.requests.some(request => this.getPrice(request, true) && !this.request.isPaidOnline(request.visaType.id) );
      this.setForm();
    });
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
  }

  ngOnDestroy() {
    if(!this.keepScanned) {
      this.scanDoc.scanned = false;
    }
    this.subscription.unsubscribe();
  }

  setForm() {
    this.form = this.formBuilder.group({});
    this.currentApplication.requests.forEach((_request, index) => {
      this.form.addControl('visaFeesAndTerms_'+index, this.formBuilder.control(this.cart.currentApplication.visaFeesAndTerms || '', [Validators.requiredTrue]));
    });
    if(this.showPayOnline && !this.api.userRole.isKiosk && !this.api.userRole.isWorker) {
      this.form.addControl('payOnline', this.formBuilder.control(this.cart.currentApplication.payOnline || '', [Validators.required]));
    }
  }

  submit() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {

      const action = () => {
        this.disabledConfirm = true;
        if(this.scanDoc?.scanned) {
          this.keepScanned = true;
          this.scanDoc.visaDatas = [];
          sessionStorage.removeItem('visaDatas');
        }
        this.cart.currentApplication.visaFeesAndTerms = true;
        this.cart.saveCurrentApplication(false);
        this.request.pay(undefined, this.cart.currentApplication.payOnline === 'true').catch(() => {
          this.disabledConfirm = false;
        });
      };

      if((this.currentApplication.requests.some(request => this.request.isCountryCat(request.nationality, 'A') || this.request.isCountryCat(request.nationality, 'B'))) && !this.cart.currentApplication?.isExtend) {
        const names = this.currentApplication.requests
          .filter(request => this.request.isCountryCat(request.nationality, 'A') || this.request.isCountryCat(request.nationality, 'B'))
          .map(request => request.person.firstName + ' ' + request.person.lastName)
          .join(', ');
        this.loader.loading(true, {type: 'warn', custom: {innerHtml: this.lang.transform('summary.warning_validity_list', {names})}, btnLabel: 'understand'}).then((done: boolean) => {
          if(done) {
            action();
          }
        });
      } else {
        action();
      }
    } else {
      this.disabledConfirm = false;
    }
  }

  getConfigFields(formConfig: VisaFormConfig): FormField[] {
    const stepsValues = Object.keys(formConfig) as VisaFormName[];
    const steps = stepsValues.map((value: VisaFormName) => formConfig[value]);
    return steps.map((step: FormStep) => step.fields).flat();
  }

  getPrice(request: Request, onlyNum = false, onlyAmount = false) {
    if(!request) return;
    if(this.cart.currentApplication.isRevision) return 0;
    const document = this.api.userInfo.server.paymentRequests.find(p => p.id === request.visaType.id);
    if(onlyNum) return document.amountCents > 0;
    if(this.cart.currentApplication?.isExtend) {
      return (onlyAmount ? '' : document.currency + ' ') + Math.floor((document.amountCentsDyn ?
        eval(document.amountCentsDyn)({
          DaysRequested: this.request.getFieldValue(VisaFormName.PERSONAL_DETAILS, VisaFN.DAYS_REQUESTED, this.cart.currentApplication.requests[0].formConfig)
        }) : document.amountCents) / 100);
    } else {
      return (onlyAmount ? '' : document.currency + ' ') + Math.floor((document.amountCentsDyn ?
        eval(document.amountCentsDyn)({
          Nationality: request.nationality,
          Urgent: request.urgent
        }) : document.amountCents) / 100);
    }
  }

  getError(formControlName: string): FormError[] {
    return this.forms.getFormErrors(this.form, formControlName, false).map((error: FormError) => {
      error.key = error.key.split('_')[0];
      return error;
    });
  }

  checkPayOnline($event: any) {
    this.cart.currentApplication.payOnline= $event;
    this.cart.saveCurrentApplication(false);
  }

  getTotalPrice(onlyAmount = false, toPayOnline: boolean): string | number {
    let totalOnline = 0;
    let totalOffline = 0;
    const document = this.api.userInfo.server.paymentRequests.find(p => p.id === this.currentApplication.requests[0].visaType.id);
    this.currentApplication.requests.forEach(request => {
      const price = this.getPrice(request, false, true);
      if(this.request.isPaidOnline(request.visaType.id)) {
        totalOnline = Number(totalOnline) + Number(price);
      } else {
        totalOffline = Number(totalOffline) + Number(price);
      }

    });
    if(toPayOnline) {
      if(onlyAmount) {
        if(this.cart.currentApplication.payOnline === 'true') return totalOnline + totalOffline;
        return totalOnline;
      } else {
        if(this.cart.currentApplication.payOnline === 'true') return document.currency + ' ' + (totalOnline + totalOffline);
        return totalOnline ? document.currency + ' ' + totalOnline : '';
      }
    } else {
      if(onlyAmount) {
        return totalOffline;
      } else {
        return totalOffline ? document.currency + ' ' + totalOffline : '';
      }
    }
  }

  getTotalPriceNr(b: boolean): number {
    return this.getTotalPrice(true, b) as number;
  }

  previous() {
    if(this.scanDoc?.scanned) {
      this.keepScanned = true;
    }
    this.nav.to('form-visa', undefined, {queryParams: {direction: Direction.PREVIOUS}});
  }

  protected readonly VisaFN = VisaFN;
  protected readonly CurrencySign = CurrencySign;
  protected readonly VisaFormName = VisaFormName;
}

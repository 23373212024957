<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'feedbackForm' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <form id="form" [formGroup]="form" class="pt_20 gap_25 f_col" [class.mb_30]="submitted">
        <app-input class="fullWidth" icon="user" type="number" formCtrlName="numberPeople" [minNumber]="1" [formGrp]="form" label="{{'form.numberPeople' | translate}}"></app-input>
        <div class="flex gap_10 fullWidth">
          <app-input *ngIf="form.contains('fundsSpend')" class="fullWidth" icon="piggy-bank" type="number" formCtrlName="fundsSpend" [minNumber]="1" [formGrp]="form" label="{{'form.totalSpend' | translate}}"></app-input>
          <app-input  *ngIf="form.contains('fundsCurrency')" class="fullWidth" icon="dollar-sign" type="select" formCtrlName="fundsCurrency" [formGrp]="form" label="{{'FundsAvailableCurrency' | translate}}" [selectOptions]="api.env.supportedCurrencies"></app-input>
        </div>
        <app-input *ngIf="form.contains('countryResidence')"  class="fullWidth" icon="location-dot" type="select" formCtrlName="countryResidence" [formGrp]="form" label="{{'countryPermanentResidence' | translate}}" [selectOptions]="request.getNationalities(false)"></app-input>
        <app-input *ngIf="form.contains('feedbackComment')" class="mb_10 fullWidth" type="textarea" formCtrlName="feedbackComment" [formGrp]="form" label="form.feedbackComments" icon="comment" [maxLength]="600" [textareaRows]="10"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
      </form>
      <app-button formId="form" class="fullWidth resp_maxSize_l" (btnClicked)="submitFeedback()">{{'feedback.submit' | translate}}</app-button>
      <app-button size="sm" formId="form" class="fullWidth resp_minSize_l" (btnClicked)="submitFeedback()">{{'feedback.submit' | translate}}</app-button>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth resp_minSize_l mt_4" (btnClicked)="nav.to('dashboard')">{{'dashboard' | translate}}</app-button>
    </div>
  </div>
  <div class="f_align_end f_grow mb_20 resp_maxSize_l">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
  </div>
</div>

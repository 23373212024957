import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ParagraphType} from '../../shared/models/request';

@Component({
  selector: 'app-print-notice-refusal',
  templateUrl: './print-notice-refusal.component.html',
  styleUrl: './print-notice-refusal.component.scss'
})
export class PrintNoticeRefusalComponent {
  @Input() form: FormGroup;
  @Input() qrCode: string;

  protected readonly ParagraphType = ParagraphType;
}

<div id="print">
  <div class="print_content">
    <div class="flex f_between f_align_c mt_40 mb_30">
      <div class="flex f_align_start gap_10">
        <div class="f_col f_align_c">
          <img src="assets-projects/emblem.png" width="80px" alt="">
        </div>
        <div class="f_col f_end mt_10">
          <img [src]="environment.theme.logo" width="50px" alt="">
        </div>
        <div class="f_col f_align_c">
          <img src="assets-projects/zimmigration.png" width="68px" alt="">
        </div>
      </div>
      <div class="f_col f_align_end print_header">
        <span class="text-right" [innerHtml]="title | translate"></span>
      </div>
    </div>
    <div *ngFor="let group of dataList">
      <h3 class="text_center group-subtitle">{{ group.subtitle }}</h3>
      <table class="fullWidth">
        <tbody>
          <tr *ngFor="let item of group.data">
            <td>
              <b>{{ item[0] }}</b>
            </td>
            <td>{{ item[1] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

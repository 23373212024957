import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {CartService} from '../../../shared/services/cart.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DocumentType} from '../../../shared/models/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Person, PersonLinkType} from '../../../shared/models/person';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {enumToArrayObject} from '../../../shared/utils/enumToArrayObject';

@Component({
  selector: 'app-dialog-new-person',
  templateUrl: './dialog-new-person.component.html',
  styleUrl: './dialog-new-person.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DialogNewPersonComponent {

  form: FormGroup;
  submitted = false;
  linkTypeOptions;
  otherOptions = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public cart: CartService,
    public dialogRef: MatDialogRef<DialogNewPersonComponent>,
    public nav: NavigateService,
    public forms: FormService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private lang: LangService) {
    this.linkTypeOptions = enumToArrayObject(PersonLinkType).filter((item) => +item.value !== PersonLinkType.UNKNOWN).map(item => ({
      name: 'personLinkType.DYN.' + item.value,
      value: item.value
    }));
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.pattern('[A-Za-z\' -]+'),
        Validators.maxLength(50)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.pattern('[A-Za-z\' -]+'),
        Validators.maxLength(50)
      ]],
      personalNr: ['', [
        Validators.pattern('[a-zA-Z0-9\-]+'),
        Validators.maxLength(20)
      ]],
      passportNr: ['', [
        Validators.pattern('[a-zA-Z0-9\-]+'),
        Validators.maxLength(20)
      ]],
      linkType: ['', []],
      otherLinkType: ['', [
        Validators.pattern('[A-Za-z\' -]+'),
        Validators.maxLength(50)
      ]]
    });

    this.form.get('linkType').valueChanges.subscribe(value => {
      const otherLinkTypeControl = this.form.get('otherLinkType');
      if(+value === PersonLinkType.OTHER) {
        this.otherOptions = true;
        otherLinkTypeControl.setValidators([Validators.required, Validators.pattern('[A-Za-zàáâãäåçèéêëìíîïðòóôõöùúûüýÿñÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝŸÑ\'-\\s]+'), Validators.maxLength(50)]);
      } else {
        this.form.get('otherLinkType').setValue('');
        this.otherOptions = false;
        otherLinkTypeControl.setValidators([Validators.pattern('[A-Za-zàáâãäåçèéêëìíîïðòóôõöùúûüýÿñÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝŸÑ\'-\\s]+'), Validators.maxLength(50)]);
      }
      otherLinkTypeControl.updateValueAndValidity();
    });
  }

  submit() {
    const isDuplicate = this.api.listPersons.find((person) => (person.firstName.toLowerCase() === this.form.get('firstName').value.toLowerCase() && person.lastName.toLowerCase() === this.form.get('lastName').value.toLowerCase()));
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true, {
        type: isDuplicate? 'warn' : 'question',
        message: this.lang.transform((isDuplicate && this.api.isZwevisa ? 'createPeopleDuplicate.ask.DYN.' : 'createPeople.ask.DYN.') + this.api.env.type),
        btnLabel: 'global.confirm'
      }).then((res: boolean) => {
        if(res) {
          this.loader.loading(true);
          this.api.createPerson(this.form.get('personalNr').value,
            this.form.get('firstName').value,
            this.form.get('lastName').value,
            this.form.get('passportNr').value,
            +this.form.get('linkType').value, this.form.get('otherLinkType').value).then((_personId: number) => {
            this.api.persons().then((res: Person[]) => {
              this.loader.loading(false);
              this.loader.loading(true, {
                type: 'valid',
                message: this.lang.transform('createPeople.success.DYN.' + this.api.env.type)
              }).then(() => {
                this.api.listPersons = res;
                this.closeDialog();
              });
            });
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: this.lang.transform(err || 'createPeople.error')});
          });
        }
      });
    } else {
      this.submitted = true;
    }
  }

  closeDialog(done?: boolean) {
    this.dialogRef?.close(done);
  }

  protected readonly DocumentType = DocumentType;
}

<mat-dialog-content class="dialog_content">
  <app-paragraph class="mb_30" textAlign="center">{{'peopleNew.title.DYN.' + api.env.type | translate}}</app-paragraph>
  <form class="mb_20 f_col gap_20 newPeople-form-container" id="form" [formGroup]="form" (ngSubmit)="submit()">
    <app-input formCtrlName="firstName" [formGrp]="form" class="mb_5" disabled="true" label="form.firstName"
               icon="user" [matTooltip]="api.isZwevisa ? ('tooltip.docFirstName' | translate) : ''"></app-input>
    <app-input formCtrlName="lastName" [formGrp]="form" class="mb_5" disabled="true" label="form.lastName"
               icon="user" [matTooltip]="api.isZwevisa ? ('tooltip.docLastName' | translate) : ''"></app-input>
    <app-input *ngIf="api.env.type === DocumentType.PASS" formCtrlName="personalNr" [formGrp]="form"
               class="mb_5" disabled="true" label="global.personalNum" icon="hashtag"></app-input>
    <app-input class="mb_5" formCtrlName="passportNr" [formGrp]="form" disabled="true" label="global.passportNum"
               icon="hashtag" [matTooltip]="api.isZwevisa ? ('tooltip.docPassportNumber' | translate) : ''"></app-input>
    <app-input class="mb_5" [formGrp]="form" type="select" formCtrlName="linkType"
               label="{{'form.linkType' | translate}}" icon="list" [selectOptions]="linkTypeOptions" [matTooltip]="api.isZwevisa ? ('tooltip.docLinkType' | translate) : ''"></app-input>
    <app-input *ngIf="otherOptions" formCtrlName="otherLinkType" [formGrp]="form" class="mb_5" disabled="true"
               label="form.otherLinkType" icon="link" [matTooltip]="api.isZwevisa ? ('tooltip.docLinkTypeOther' | translate) : ''"></app-input>
    <app-errors-list [dark]="true" *ngIf="submitted"
                     [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
  </form>
  <div class="mt_20 flex gap_10 f_center">
    <app-button size="sm" class="dialog_close"
                (btnClicked)="closeDialog()">
      {{ 'global.close' | translate }}
    </app-button>
    <app-button size="sm" type="submit" formId="form">
      {{ 'global.confirm' | translate }}
    </app-button>
  </div>
</mat-dialog-content>

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {DocumentType} from '../../../shared/models/user';
import {formatDate, convertUpperCase} from '../../../shared/utils/string';
import {CurrencySign} from 'src/app/shared/models/request';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  selector: 'app-payment-commandHistory',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentHistoryComponent {

  CurrencySign = CurrencySign;
  DocumentType= DocumentType;
  convertUpperCase = convertUpperCase;
  formatDate = formatDate;

  constructor(public nav: NavigateService, public api: ApiService, public request: RequestService) {}

}

import {Injectable} from '@angular/core';
import {FormGroup, ValidationErrors} from '@angular/forms';
import {FormError} from '../models/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() {}

  getFormErrors(formGroup: FormGroup): FormError[] {
    this.resetFormInputs(formGroup);
    this.setValidInputs(formGroup);
    const errors: FormError[] = [];

    Object.keys(formGroup.controls).forEach(key => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if(controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {

          if(keyError) {
            document.getElementById(key)?.classList.add('input_error');
            errors.push({key, keyError});
          }
        });
      }
    });
    return errors;
  }

  setValidInputs(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      if(key && formGroup.controls[key].dirty && formGroup.controls[key].valid) {
        document.getElementById(key)?.classList.add('input_valid');
      }
    });
  }

  resetFormInputs(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      if(key) {
        document.getElementById(key)?.classList.remove('input_error', 'input_valid');
      }
    });
  }

  // custom validator to check that two fields match
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if(matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if(control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(undefined);
      }
    };
  }
}

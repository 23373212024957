import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CartService} from '../../../shared/services/cart.service';
import {Request} from '../../../shared/models/request';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  selector: 'app-dialog-participants',
  templateUrl: './dialog-participants.component.html',
  styleUrl: './dialog-participants.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DialogParticipantsComponent {
  constructor(public cart: CartService,
    public dialogRef: MatDialogRef<DialogParticipantsComponent>,
    public request: RequestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public nav: NavigateService,
    private lang: LangService,
    private loader: LoaderService) {
    this.cart.checkMissingParticipants();
  }

  select(request: Request) {
    this.cart.currentApplication.currentPerson = request.person;
    this.cart.currentRequest = request;
    this.cart.saveCurrentApplication(true);
    if(request.latestRouteVisited) this.nav.to(request.latestRouteVisited);
    this.closeDialog();
    const msg = this.lang.transform('application.current_person') + ' <b>' + this.cart.currentApplication.currentPerson.firstName + ' ' + this.cart.currentApplication.currentPerson.lastName[0]+'.</b>';
    this.loader.loading(true, {type: 'info', custom: {innerHtml: msg}});
  }

  closeDialog(action?: boolean) {
    this.dialogRef?.close(action);
  }
}

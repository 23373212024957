import {I18nLang} from 'ngx-satoris';
import {DocumentType} from 'src/app/shared/models/user';

export const environment = {
  production: false,
  appVersion: [5, 4, 2],
  appBuild: 'build-localhost',

  baseUri: 'https://ep70-app-dev.satoris.lu/api/v1/',
  backupUri: 'https://ep70-app-dev-backup.satoris.lu/api/v1/',
  authUri: 'http://10.3.0.97:30301/api/v1/',
  referenceUri: 'http://10.3.0.97:30303/registry/read-only-view/person?referenceNumber=',

  name: 'Visa service',
  supportSource: 'https://glpi-app.satoris.lu/install.js?project=bmszimtest&lang=en&position=bottomRight&btnVisible=true',
  faqSource: 'https://www.evisa.gov.zw/faq.html',
  travizorySource: 'http://apipnr.testzimmigration.local',

  smsIntent: 'INTENT',

  theme: {
    logo: 'assets-projects/logo.svg',
    logoFullAlt: 'assets-projects/logo-full-alt.png',
    header_background: ''
  },
  type: DocumentType.ZWEVISA,
  supportedLanguages: [I18nLang.EN],
  nationalityNoDeclaration: ['ZWE'],
  supportedCurrencies: ['USD', 'EUR', 'ZAR', 'GBP', 'BWP'],
  noConfirm: true,

  domain: 'zimmigration.local',
  kioskUsername: 'info@satoris.lu',
  password: 'wwwXXX0000'
};

import {ChoiceButton, IconProp} from 'ngx-satoris';
import {ValidatorFn} from '@angular/forms';
import {UserPlatformRole} from './user';

//Model to represent each error in the form
export interface FormError {
    key: string;
    keyError: string;
    icon?: IconProp;
}

//Model to represent the form
export type VisaFormConfig = {
  [key: string]: FormStep;
};

//Model to represent the form names
export enum VisaFormName {
  VISITOR_DETAILS = 'visitor_details',
  REQUIREMENTS = 'requirements',
  PERSONAL_DETAILS = 'personal_details',
  CONTACT_DETAILS = 'contact_details',
  EMERGENCY_CONTACT = 'emergency_contact',
  TRAVEL_INFORMATION = 'travel_information',
  MISCELLANEOUS_DETAILS = 'miscellaneous_details',
  SPOUSE_DETAILS = 'spouse_details',
  DEPENDANTS = 'dependants',
  DOCUMENT = 'document'
}

export enum VisaFN {
  PASSPORT_COUNTRY = 'PassportCountry',
  PASSPORT_NUMBER = 'PassportNumber',
  NATIONALITY = 'Nationality',
  PURPOSE_OF_VISIT = 'PurposeOfVisit',
  VISA_TYPE = 'VisaType',
  CHECKBOX_VISA_FEES = 'CheckboxVisaFees',
  CHECKBOX_TERMS_AND_CONDITIONS = 'CheckboxTermsAndConditions',
  LAST_NAME = 'LastName',
  OTHER_NAME = 'OtherName',
  FIRST_NAME = 'FirstName',
  BIRTH_DAY = 'BirthDay',
  PLACE_OF_BIRTH_COUNTRY = 'PlaceOfBirthCountry',
  PLACE_OF_BIRTH_CITY = 'PlaceOfBirthCity',
  GENDER = 'Gender',
  MARITAL_STATUS = 'MaritalStatus',
  EMAIL = 'Email',
  PHONE = 'Phone',
  OCCUPATION = 'Occupation',
  HOME_ADDRESS = 'HomeAddress',
  HOST_NAME = 'HostName',
  ADDRESS_ZIMBABWE = 'AddressZimbabwe',
  DATE_OF_ISSUE = 'DateOfIssue',
  DATE_OF_EXPIRY = 'DateOfExpiry',
  PLACE_OF_ISSUE = 'PlaceofIssue',
  ARRIVAL_DATE = 'ArrivalDate',
  DEPARTURE_DATE = 'DepartureDate',
  REFERENCE_PREVIOUS_VISA = 'ReferencePreviousVisa',
  PREVIOUS_CONVICTIONS_ZIM = 'PreviousConvictionsZim',
  PREVIOUS_CONVICTIONS_ZIM_ASK = 'PreviousConvictionsZimAsk',
  PREVIOUS_CONVICTIONS_HOME = 'PreviousConvictionsHome',
  PREVIOUS_CONVICTIONS_HOME_ASK = 'PreviousConvictionsHomeAsk',
  SPOUSE = 'Spouse',
  SPOUSE_NAME = 'SpouseFullName',
  SPOUSE_PASSPORT_NUMBER = 'SpousePassportNumber',
  SPOUSE_DATE_OF_BIRTH = 'SpouseBirthDay',
  SPOUSE_PLACE_OF_BIRTH = 'SpousePlaceOfBirth',
  DEPENDANTS = 'Dependants',
  PASSPORT_PHOTO = 'PassportPhoto',
  PASSPORT_SCAN = 'PassportScan',
  PROOF_OF_RESIDENCE_HOST = 'ProofOfResidenceHost',
  ADDRESS_AT_DESTINATION = 'AddressAtDestination',
  PREVIOUS_ZIMBABWEAN_VISA = 'PreviousZimbabweanVisa',
  HOST_INVITATION_LETTER = 'HostInvitationLetter',
  PROOF_OF_RESIDENCE = 'ProofOfResidence',
  APPLICATION_LETTER = 'ApplicationLetter',
  RESIDENTIAL_STATUS_OF_HOST = 'ResidentialStatusOfHost',
  REASON_OF_VISIT_PROOF = 'ReasonOfVisitProof',
  BUSINESS_LETTER = 'BusinessLetter',
  BUSINESS_PROFILE = 'BusinessProfile',
  INVITATION_LETTER_AND_BUSINESS_PROFILE = 'InvitationLetterAndBusinessProfile',
  LETTER_OF_ACCEPTANCE_FROM_SCHOOL = 'LetterOfAcceptanceFromSchool',
  NON_ORDINARY_PASS_MISSION = 'NonOrdinaryPassMission',
  BATCH_ID = 'BatchId',
  NEXT_OF_KIN = 'NextOfKin',
  INVITATION_LETTER = 'InvitationLetter',
  FUNDS_AVAILABLE = 'FundsAvailable',
  FUNDS_AVAILABLE_CURRENCY = 'FundsAvailableCurrency',
  URGENT = 'Urgent',
  PASSPORT_TYPE = 'PassportType',
  EMERGENCY_LAST_NAME = 'EmergencyLastName',
  EMERGENCY_FIRST_NAME = 'EmergencyFirstName',
  EMERGENCY_EMAIL = 'EmergencyEmail',
  EMERGENCY_PHONE = 'EmergencyPhone',
  VISA_REFERENCE = 'VisaReference',
  DAYS_REQUESTED = 'DaysRequested',
  REASON_FOR_EXTENSION = 'ReasonForExtension',
  ATTESTANT_ID = 'AttestantID',
  ATTESTANT_NAME = 'AttestantName',
  ATTESTANT_PHONE = 'AttestantPhone',
  ATTESTANT_EMAIL = 'AttestantEmail',
  ATTESTANT_ADDRESS = 'AttestantAddress',
  ATTESTANT_RELATION = 'AttestantRelation',
  ATTESTANT_DOCUMENT = 'AttestantDocument',
  AFFIDAVIT_CUSTODY = 'AffidavitCustody',
  COPY_OF_ENTRY = 'CopyOfEntry',
  DEPARTURE_TICKET = 'DepartureTicket',
  CHECKBOX_VISA_EXTEND_FEES = 'CheckboxVisaExtendFees',
  RESIDENCE_PERMIT_NUMBER = 'ResidencePermitNumber',
  COMPANIONS_SIZE = 'CompanionsSize',
  EXTRA_DOCUMENT_1 = 'ExtraDocument1',
  EXTRA_DOCUMENT_2 = 'ExtraDocument2',
  EXTRA_DOCUMENT_3 = 'ExtraDocument3',
  EXTRA_DOCUMENT_4 = 'ExtraDocument4',
  EXTRA_DOCUMENT_5 = 'ExtraDocument5',
  USAGE_AFTER = 'UsageAfter',
  USAGE_UNTIL = 'UsageUntil'
}

//Model to represent each field in the form
export interface FormField {
  name: string;
  type: 'text' | 'number' | 'password' | 'email' | 'textarea' | 'select' | 'tel' | 'date' | 'pin' | 'datetime-local' | 'file' | 'checkbox' | 'choice';
  validators?: (ValidatorFn | string)[];
  onlyCustomValidators?: boolean;
  mustBeDifferentThan?: {array?: any[], value?: any};
  enum?: any[];
  disabled?: boolean;
  icon?: IconProp;
  customExplain?: string;
  hidden?: boolean;
  value?: any;
  customClass?: string;
  buttonField?: FormStep['buttons'][0];
  ifFormControl?: string;
  ifFormControlHasValue?: {control: VisaFN, value?: any};
  ifFormControlHasNotValue?: {control: VisaFN, value?: any};
  alwaysVisible?: boolean;
  requiredIfFormControl?: boolean;
  label?: string;
  dependants?: FormField[][];
  updateField?: boolean;
  fileName?: string;
  acceptType?: string;
  tooltip?: string;
  dateValidators?: {
    minDate?: string,
    maxDate?: string,
    maxDateMilliFrom?: {form: VisaFormName, control: VisaFN, milliseconds: number},
    maxDateMilliTo?: {form: VisaFormName, control: VisaFN, milliseconds: number},
    maxDateMilliFromToday? : number,
    mustBeBefore?: {form: VisaFormName, control: VisaFN},
    mustBeAfter?: {form: VisaFormName, control: VisaFN},
    mustBeEqualOrBefore?: {form: VisaFormName, control: VisaFN},
    mustBeEqualOrAfter?: {form: VisaFormName, control: VisaFN}
  };
  action?: FormAction;
  topDivider?: boolean;
  bottomDivider?: boolean;
  showInResume?: boolean;
  maxFileSize?: number;
  maxLength?: number;
  noAutocomplete?: boolean;
  choiceButtons?: ChoiceButton[],
  sameRow?: string;
}

//Model to represent each step of the form
export interface FormStep {
  version: number;
  fields?: FormField[];
  requiredList?: {icon: IconProp, text: string}[];
  visaPrice?: boolean;
  visaPriceText?: string;
  textBeforeForm?: {title?: string, text?: string};
  buttons?: {icon?: IconProp, text: string, action: FormAction, needAllFields : boolean, customClass?: string, size?: string}[];
  errors?: FormError[];
  hasRevisionErrors?: boolean;
  visited?: boolean;
  submitted?: boolean;
  classNames?: string;
  imagePay ?: boolean;
  disabledForRoles?: UserPlatformRole[];
}

export enum FormAction {
  NEWBATCHID = 1,
  UPDATE = 2,
  PAYMENT = 3,
  SUBMIT = 4,
  FOCUS_BLUR = 5,
  SAVE = 6,
  UPLOAD_FILE = 7,
}

export enum Direction {
  NEXT = 'next',
  PREVIOUS = 'previous'
}

<div id="layout" class="f_col places-page">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'places.title' | translate}}</app-view-title>
  <div class="scroll_wrapper" (scroll)="onScroll($event)">
    <div class="places-container" [style.visibility]="!firstCall? 'hidden' : 'visible'">
      <div class="leftSide-container">
        <p class="title-leftSide">{{'form.search' | translate}}</p>
        <div class="input_wrapper mb_20">
          <app-input icon="magnifying-glass" type="text" formCtrlName="text" [formGrp]="form" label="{{'form.name' | translate}}, #id" class="fullWidth" (input)="query.inputQuery('search', $event)"></app-input>
        </div>
        <app-button size="sm" formId="form" class="fullWidth mt_4 resp_minSize_l" (btnClicked)="nav.to('place-create')">{{'create_place.title' | translate}}</app-button>
      </div>
      <div class="table-container">
        <div class="rightSide-container">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="id">
              <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by id"> {{'form.id' | translate}} </th>
              <td class="truncate" mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="longName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by name"> {{'form.name' | translate}} </th>
              <td mat-cell *matCellDef="let element" > {{element.longName}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="nav.to('place/' + row.id)"></tr>
          </table>

        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                        [pageSize]="defaultPaginatorSize"
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
    <div class="btns-container resp_maxSize_l mb_20">
      <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <div class="ml_10 fullWidth" *ngIf="api.hasPerm(api.perm.ALLOW_ADMIN)">
        <app-button formId="form" class="fullWidth" (btnClicked)="nav.to('place-create')">{{'create_place.title' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>

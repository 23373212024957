import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlacesService} from '../../../shared/services/places.service';
import {environment} from 'src/environments/environment';
import {DocumentType} from '../../../shared/models/user';

@Component({
  selector: 'app-place-permissions',
  templateUrl: './place-permissions.component.html',
  styleUrls: ['./place-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlacePermissionsComponent implements OnInit {

  form: FormGroup;
  userId: number;
  submitted = false;
  placeId: string;
  showSeniority = false;

  constructor(private route: ActivatedRoute, 
    private api: ApiService, 
    private loader: LoaderService, 
    public nav: NavigateService, 
    private formBuilder: FormBuilder, 
    private lang: LangService, 
    public place: PlacesService, 
    private changeDetectorRef: ChangeDetectorRef,
    public forms: FormService) {
    this.userId = +this.route.snapshot.paramMap.get('userId');
    this.placeId = this.route.snapshot.paramMap.get('placeId');
  }

  ngOnInit() {
    this.place.getPermissionPlace(this.userId, this.placeId).then(() => {
      this.setForm();
      this.changeDetectorRef.detectChanges();
    });
  }

  setForm() {
    if(this.place?.userPlace?.seniority && this.place?.userPlace?.allowControl) {
      this.showSeniority = true;
    }
    this.form = this.formBuilder.group({
      perm1: [this.place?.userPlace?.allowCreate, []],
      perm2: [this.place?.userPlace?.allowConsume, []],
      perm3: [this.place?.userPlace?.allowControl, []],
      perm4: [this.place?.userPlace?.allowAdmin, []],
      seniority: [this.place?.userPlace?.seniority, [
        Validators.pattern('^[1-3]$'),
        ...(this.place?.userPlace?.seniority ? [Validators.required] : [])
      ]]
    });

    if(this.api.env.type === DocumentType.ZWEVISA) {
      this.form.get('perm3').valueChanges.subscribe(value => {
        const seniority = this.form.get('seniority');
        if(value === true) {
          this.showSeniority = true;
          seniority.setValue(this.place?.userPlace?.seniority);
          seniority.setValidators([Validators.pattern('^[1-3]$'), Validators.required]);
        } else {
          this.showSeniority = false;
          seniority.clearValidators();
        }
        seniority.updateValueAndValidity();
      });
    }
  }

  updatePermissions() {
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('ask.place.updatePermissions'), btnLabel: this.lang.transform('global.confirm')}).then((done: boolean) => {
        if(done) {
          this.loader.loading(true);
          let createPlaceIds: string[] = [];
          let consumePlaceIds: string[] = [];
          let controlPlaceIds: string[] = [];
          let adminPlaceIds: string[] = [];
  
          this.place?.user?.links?.forEach(item => {
            item.allowCreate ? createPlaceIds.push(item.place_id) : undefined;
            item.allowConsume ? consumePlaceIds.push(item.place_id) : undefined;
            item.allowControl ? controlPlaceIds.push(item.place_id) : undefined;
            item.allowAdmin ? adminPlaceIds.push(item.place_id) : undefined;
          });
  
          this.form.get('perm1')?.value ? createPlaceIds.push(this.placeId) : createPlaceIds = createPlaceIds.filter(x => x !== this.placeId);
          this.form.get('perm2')?.value ? consumePlaceIds.push(this.placeId) : consumePlaceIds = consumePlaceIds.filter(x => x !== this.placeId);
          this.form.get('perm3')?.value ? controlPlaceIds.push(this.placeId) : controlPlaceIds = controlPlaceIds.filter(x => x !== this.placeId);
          this.form.get('perm4')?.value ? adminPlaceIds.push(this.placeId) : adminPlaceIds = adminPlaceIds.filter(x => x !== this.placeId);
  
          return this.api.places(this.userId, createPlaceIds, consumePlaceIds, controlPlaceIds, adminPlaceIds, parseInt(this.form.get('seniority')?.value, 10)).then(() => this.place.getPermissionPlace(this.userId, this.placeId).then(() =>{
            this.loader.loading(true, {type: 'info', message: this.lang.transform('place.updatePermissions.success')});
          })).catch(err => {
            this.loader.loading(true, {type: 'error', message: err || this.lang.transform('err.place.updatePermissions')});
          });
        }
      });
    } else {
      this.submitted = true;
    }
  }

  removePlace() {
    this.loader.loading(true);
    const createPlaceIds: string[] = [];
    const consumePlaceIds: string[] = [];
    const controlPlaceIds: string[] = [];
    const adminPlaceIds: string[] = [];
    let maxSeniority = 1;
    this.place?.user?.links?.forEach(item => {
      if(item.place_id !== this.place?.userPlace?.place_id) {
        item.allowCreate ? createPlaceIds.push(item.place_id) : undefined;
        item.allowConsume ? consumePlaceIds.push(item.place_id) : undefined;
        item.allowControl ? controlPlaceIds.push(item.place_id) : undefined;
        item.allowAdmin ? adminPlaceIds.push(item.place_id) : undefined;
        if(item.seniority > maxSeniority) maxSeniority = item.seniority;
      }
    });
    if(this.place?.user?.links.length > 1) {
      this.loader.loading(true, {type: 'info', message: this.lang.transform('place.delete.link.ask'), btnLabel: this.lang.transform('global.confirm')}).then((done: boolean) => {
        if(done) {
          this.api.places(this.place?.user?.id, createPlaceIds, consumePlaceIds, controlPlaceIds, adminPlaceIds, maxSeniority).then(() => {
            this.loader.loading(true, {type: 'info', message: this.lang.transform('place.delete.link.done')}).then(() => {
              this.nav.to('user/'+this.userId+'/places-choice');
            });
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    } else {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('lastPlace.delete'), btnLabel: this.lang.transform('global.confirm')}).then((done: boolean) => {
        if(done) {
          this.api.places(this.place?.user?.id, createPlaceIds, consumePlaceIds, controlPlaceIds, adminPlaceIds, maxSeniority).then(() => {
            this.loader.loading(true, {type: 'info', message: this.lang.transform('place.delete.link.done')}).then(() => {
              this.nav.to('user/'+this.userId+'/places-choice');
            });
          }).catch((err) => {
            this.loader.loading(true, {type: 'error', message: err});
          });
        }
      });
    }
  }
  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
}

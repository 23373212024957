<div id="layout" class="sign_in f_col" *ngIf="finishCallPayment">
  <div class="scroll_wrapper resp_wrapper requestSelect-container resp_row">
    <div class="leftSide-container">
      <div *ngIf="currentPayment?.paymentId || paymentId && queue.requestInQueue(currentPayment.paymentId || paymentId)" class="flex gap_10 synchronising">
        <app-paragraph>{{'global.syncing' | translate}}</app-paragraph>
        <div class="loading">
          <svg viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" style="stroke: black; stroke-opacity: 0.5;"/>
          </svg>
        </div>
        <app-divider class="mb_10 mt_10"></app-divider>
      </div>

      <ng-container *ngIf="getWarningMessage() as warningMessage">
        <div class='warning-container mb_10'>
          <div class="flex f_align_c gap_10">
            <fa-icon size="xl" [icon]="'triangle-exclamation'"></fa-icon>
            <app-paragraph class="text" [innerHtml]="warningMessage"></app-paragraph>
          </div>
        </div>
      </ng-container>

      <div *ngIf="environment.type === DocumentType.PASS">
        <app-button class="mt_4" size="xsm" *ngIf="currentPayment?.state === status.PAID && api.hasPerm(api.perm.ALLOW_CONSUME)" leftIcon="check"
                    (btnClicked)="consumePayment()">{{'global.consume' | translate}}</app-button>
        <app-button class="mt_4" size="xsm" *ngIf="currentPayment?.state === status.PAID && api.hasPerm(api.perm.ALLOW_CONTROL)" leftIcon="copy"
                    (btnClicked)="duplicatePayment()">{{'global.duplicate' | translate}}</app-button>
        <app-button class="mt_4" size="xsm" *ngIf="currentPayment?.state === status.PAID && api.hasPerm(api.perm.ALLOW_CONTROL)" leftIcon="money-bill"
                    (btnClicked)="refundPayment()">{{'global.refund' | translate}}</app-button>
        <app-button class="mt_4" size="xsm" *ngIf="api.hasPerm(api.perm.ALLOW_CREATE)" leftIcon="qrcode"
                    (btnClicked)="nav.to('qr/' + paymentId)">{{'qr.see' | translate}}
        </app-button>
        <app-button class="mt_4" size="xsm" leftIcon="clone" *ngIf="currentPayment?.duplicatas?.length > 0"
                    (btnClicked)="goToDuplicata()">{{'payment.seeDuplicata' | translate}}
        </app-button>
      </div>
      <div *ngIf="environment.type === DocumentType.PASS">
        <app-button *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && api.hasPerm(UserPermission.ALLOW_CONTROL))) && currentPayment?.state === status.PAID_NOT_CONFIRMED"
                    (btnClicked)="validateOrRefusePayment()"  class="mt_4" size="xsm">{{'global.approve' | translate}}</app-button>
        <app-button color="red" *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && api.hasPerm(UserPermission.ALLOW_CONTROL))) && currentPayment?.state === status.PAID_NOT_CONFIRMED"
                    (btnClicked)="validateOrRefusePayment(true)"  class="mt_4" size="xsm">{{'global.refuse' | translate}}</app-button>
      </div>
      <div *ngIf="environment.type === DocumentType.ZWEVISA">
        <div class="f_col" *ngIf="canBeDeported && (this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECK || this.request.getStatusTheme(this.currentPayment).actionState === ActionState.READY_CHECKIN) && currentPayment.operationId !== DocumentType.ZWEEXIT && !isSyncing">
          <app-checkbox-element
          class="fullWidth"
          [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.deportedMode' | translate) : ''"
                          (onChange)="changeDeportedMode($event.checked)"
                          [formGrp]="form"
                          [formCtrlName]="'deportedMode'"
                          [id]="'deportedMode'"
                          class="mb_10"
                          [text]="'deportedMode'"></app-checkbox-element>
          <app-divider class="mb_5"></app-divider>
        </div>
        <app-button *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeReviewed() && !selectMode))"
                    (click)="reviewPayment()"  class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.review' | translate) : ''">{{'global.review' | translate}}<fa-icon class="ml_5" [icon]="'question-circle'"></fa-icon></app-button>

        <app-button *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeAcceptedRefused()))"
                    (click)="validateOrRefusePayment()" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.approve' | translate) : ''">{{'global.approve' | translate}}<fa-icon class="ml_5" [icon]="'thumbs-up'"></fa-icon></app-button>
        <app-button color="red" *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeAcceptedRefused(true)))"
                    (click)="validateOrRefusePayment(true)" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.refuse' | translate) : ''">{{'global.refuse' | translate}}<fa-icon class="ml_5" [icon]="'thumbs-down'"></fa-icon></app-button>
        <app-button color="red" class="mt_4" size="xsm" *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && api.hasPerm(UserPermission.ALLOW_CONTROL) && seniorityLevel === 3)) && (currentPayment.operationId === DocumentType.ZWEVISA_DOUBLE || currentPayment.operationId === DocumentType.ZWEVISA_MULTIPLE) && currentPayment.state === RequestState.PAID_NOT_CONFIRMED"
                    (click)="downgrade()">{{'request.downgrade' | translate}}<fa-icon class="ml_5" [icon]="'arrow-down'"></fa-icon>
        </app-button>
        <app-button *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeSuspended()) && selectMode)" (click)="toggleSelectMode(false);" size="xsm" class="mt_4" >{{'X'}}</app-button>
        <app-button color="red" *ngIf="(api.userRole.isAdmin || api.userRole.isWorker && canBeSuspended())"
                    (click)="suspendPayment()"  class="fullWidth mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.revision' | translate) : ''">{{(selectMode ? 'end.suspend' :'global.suspend') | translate}}<fa-icon class="ml_5" [icon]="'edit'"></fa-icon></app-button>
        <app-button color="green" [disabled]="isZweentryOrdinaryCatC()" *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed()) && !isNextCheckOut && currentPayment.operationId !== DocumentType.ZWEEXIT)" (click)="consumePayment(undefined, undefined, true)" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.checkIn.notice' | translate) : ''"><div class="f_align_c gap_5 "><span>{{'global.checkIn.notice' | translate}}</span> <fa-icon [icon]="'file'"></fa-icon></div></app-button>

        <app-button color="green" shortcut [shortcut]="ShortcutAction.ENTER" [shortcutStyle]="{right: '8px', bottom: '8px', left: 'unset'}" [disabled]="isZweentryOrdinaryCatC()" *ngIf="api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed()) && !isNextCheckOut && currentPayment.operationId !== DocumentType.ZWEEXIT && !isDiplomatic" (click)="consumePayment()" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.checkIn' | translate) : ''"><div class="f_align_c gap_5 "><span>{{'global.checkIn' | translate}}</span> <fa-icon [icon]="'camera'"></fa-icon></div></app-button>

        <app-button shortcut [shortcut]="ShortcutAction.ENTER" [shortcutStyle]="{right: '8px', bottom: '8px', left: 'unset'}" color="green" [disabled]="isZweentryOrdinaryCatC()" *ngIf="api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed()) && !isNextCheckOut && currentPayment.operationId !== DocumentType.ZWEEXIT && isDiplomatic" (click)="consumePayment(false, false, false, undefined, false, true)" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.checkInWithoutCamera' | translate) : ''"><div class="f_align_c gap_5 "><span>{{'global.checkIn.withoutCamera' | translate}}</span> <fa-icon [icon]="'play'"></fa-icon></div></app-button>

        <app-button color="green" [disabled]="isZweentryOrdinaryCatC()" *ngIf="api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed()) && (isNextCheckOut || currentPayment.operationId === DocumentType.ZWEEXIT)" (click)="consumePayment()" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.checkOut' | translate) : ''"><div class="f_align_c gap_5 "><span>{{'global.checkOut.withCamera' | translate}}</span> <fa-icon [icon]="'camera'"></fa-icon></div></app-button>

        <app-button color="green" shortcut [shortcut]="ShortcutAction.ENTER" [shortcutStyle]="{right: '8px', bottom: '8px', left: 'unset'}" [disabled]="isZweentryOrdinaryCatC()" *ngIf="api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed()) && (isNextCheckOut || currentPayment.operationId === DocumentType.ZWEEXIT)" (click)="consumePayment(false, false, false, undefined, false, true)" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ((isNextCheckOut || currentPayment.operationId === DocumentType.ZWEEXIT ?  'tooltip.checkOut' : 'tooltip.checkIn') | translate) : ''"><div class="f_align_c gap_5 "><span>{{'global.checkOut.withoutCamera' | translate}}</span> <fa-icon [icon]="'play'"></fa-icon></div></app-button>

        <app-button color="green" [disabled]="isZweentryOrdinaryCatC()" *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed())) && api.isElectronApp" (click)="consumePayment(undefined, true)" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ((isNextCheckOut || currentPayment.operationId === DocumentType.ZWEEXIT ?  'tooltip.checkOut.fingerprint' : 'tooltip.checkIn.fingerprint') | translate) : ''"><div class="f_align_c gap_5 "><span>{{(isNextCheckOut ? 'global.checkOut.fingerprint' : currentPayment.operationId === DocumentType.ZWEEXIT ? 'global.checkOut.fingerprint' : 'global.checkIn.fingerprint') | translate}}</span> <fa-icon [icon]="'fingerprint'"></fa-icon></div></app-button>
        <app-button color="red" *ngIf="(api.userRole.isAdmin || (api.userRole.isWorker && canBeConsumed(true)))" (click)="consumePayment(true)" class="mt_4" size="xsm" [matTooltip]="api.isZwevisa ? ('tooltip.consumeRefuse' | translate) : ''">{{('global.refuse') | translate}}<fa-icon class="ml_5" [icon]="'thumbs-down'"></fa-icon></app-button>

        <app-button *ngIf="(api.userRole.isAdmin || api.userRole.isWorker) && !usageUntilValid && api.hasPerm(UserPermission.ALLOW_CONSUME)" (click)="closeApplication()" class="mt_4" color="red" size="xsm" [matTooltip]="'tooltip.closeApplication' | translate">{{'closeApplication' | translate}}<fa-icon class="ml_5" [icon]="'close'"></fa-icon></app-button>

        <app-button class="mt_4" size="xsm" *ngIf="api.hasPerm(api.perm.ALLOW_CREATE)" leftIcon="qrcode"
                    (click)="nav.to('qr/' + paymentId, undefined, {queryParams: {from: fromRoute}})" [matTooltip]="api.isZwevisa ? ('tooltip.qrSee' | translate) : ''">{{'qr.see' | translate}}<fa-icon class="ml_5" icon="qrcode"></fa-icon>
        </app-button>

        <app-button *ngIf="scanDocument?.scanDatas" class="mt_4" size="xsm" (click)="toggleShowComparison()" [matTooltip]="api.isZwevisa ? ('tooltip.forceComparison' | translate) : ''" icon="passport">{{(showComparison ? 'forceComparison.hide' : 'forceComparison.show') | translate}}</app-button>

        <app-button class="mt_4" size="xsm" (click)="printA4()" [matTooltip]="api.isZwevisa ? ('tooltip.printApplication' | translate) : ''">{{'print.request' | translate}}<fa-icon class="ml_5" icon="print"></fa-icon></app-button>
        <app-button *ngIf="currentPayment.state === RequestState.PAID || currentPayment.state === RequestState.DELAY_PAY || currentPayment.state === RequestState.USED" size="xsm" class="mt_4" (click)="printApprovalLetter()">{{'printApprovalLetter' | translate}}<fa-icon class="ml_5" icon="envelope-open-text"></fa-icon></app-button>
        <app-button *ngIf="api.userRole.isAdmin && (currentPayment.state === RequestState.PAID || currentPayment.state === RequestState.DELAY_PAY || currentPayment.state === RequestState.USED)" size="xsm" class="mt_4" (click)="printSticker()">{{'printSticker' | translate}}<fa-icon class="ml_5" icon="note-sticky"></fa-icon></app-button>
        <app-button *ngIf="api.isZwevisa && printReceiptData?.length" class="mt_4" size="xsm" (click)="printReceipt()" [matTooltip]="api.isZwevisa ? ('tooltip.printReceipt' | translate) : ''">{{'print.receipt' | translate}}<fa-icon class="ml_5" icon="receipt"></fa-icon></app-button>
        <app-button *ngIf="api.isZwevisa && existingNotices?.length" class="mt_4" size="xsm" (click)="printNotices()" [matTooltip]="api.isZwevisa ? ('tooltip.printNotices' | translate) : ''" icon="print">{{'print.notices' | translate}}</app-button>
        <app-button *ngIf="api.isZwevisa && (currentPayment.state === RequestState.USED && currentPayment.subState === RequestSubState.CHECKED_IN) && request.isSubjectToUntil(currentPayment) && request.hasNoAwaitingExtension(currentPayment)" class="mt_4" size="xsm" (click)="request.startExtend(currentPayment, metadata)" [matTooltip]="'tooltip.extendStay' | translate">{{'request.extendStay' | translate}}<fa-icon class="ml_5" [icon]="'calendar-plus'"></fa-icon></app-button>
        <app-button *ngIf="canPrintBorderPass()" class="mt_4" size="xsm" (click)="printBorderPass()" [matTooltip]="api.isZwevisa ? ('tooltip.printBorderPass' | translate) : ''">{{'print.borderPass' | translate}}</app-button>

        <div class="pointerCursor" *ngIf="canInternalReference()" [matTooltip]="api.isZwevisa ? ((currentPayment.internalReference ? 'tooltip.internalEdit' : 'tooltip.internalAdd') | translate) : ''">
          <app-button class="mt_4" size="xsm" leftIcon="qrcode"
          (btnClicked)="internalReference(currentPayment.internalReference)">{{(currentPayment.internalReference ? 'internalReference.edit' : 'internalReference.create') | translate}}</app-button>
          </div>

      </div>
      <app-divider class="mb_10 mt_10"></app-divider>

      <app-button shortcut [shortcut]="ShortcutAction.ESCAPE" [shortcutStyle]="{right: '8px', bottom: '8px', left: 'unset'}" class="mt_4" (click)="api.env.type === DocumentType.PASS ? goToPreviousUrl() : goBack()" color="transparent" border="true" size="xsm">{{'global.back' | translate}}</app-button>
      <app-button *ngIf="api.isElectronApp" class="mt_4" color="transparent" border="true" size="xsm" icon="arrow-right" (click)="openGate()">{{'helps.showProceed' | translate}}</app-button>
      <app-button *ngIf="api.isElectronApp" class="mt_4" color="transparent" border="true" size="xsm" icon="arrow-left" (click)="closeGate()">{{'helps.showScan' | translate}}</app-button>

      <app-divider class="mb_10 mt_10"></app-divider>

      <p *ngIf="currentPayment?.externalId" class="title-leftSide">{{('adminRequestResult.title.DYN.' + api.env.type) | translate : {paymentID: currentPayment?.externalId} }}</p>

      <app-snackbar customColor="#FF4136" customColorIcon="black" class="urgentVisa" *ngIf="metadata?.Urgent === 'Yes'" Icon="triangle-exclamation" [matTooltip]="api.isZwevisa ? ('tooltip.urgent' | translate) : ''">{{'global.urgent' | translate}}</app-snackbar>

      <div class="f_col gap_5 mb_10" *ngIf="(currentPayment?.state || currentPayment?.state === 0) && api.env.type === DocumentType.ZWEVISA">
        <span class="ml_10">{{('request.state.DYN.' + api.env.type) | translate}}</span>
        <div class="request-state">
          <div class="flex gap_5 f_align_c">
            <app-snackbar
              [customColor]="request.getStatusTheme(currentPayment).color"
              [icon]="request.getStatusTheme(currentPayment).icon"
              [customColorIcon]="request.getStatusTheme(currentPayment).iconColor"
              [matTooltip]="api.isZwevisa ? ('tooltip.tableState' | translate) : ''">{{request.getStatusTheme(currentPayment).state}}</app-snackbar>
            <app-snackbar customColor="#a6ffa6a0"
                          icon="spell-check"
                          customColorIcon="black"
                          *ngIf="request.requestHasBeenCorrected(currentPayment)"
                          [matTooltip]="'tooltip.hasBeenCorrected' | translate">{{'recentlyCorrected' | translate}}
            </app-snackbar>
          </div>
          <app-paragraph>{{request.getStatusTheme(currentPayment).descState}}</app-paragraph>
        </div>
      </div>

      <div *ngIf="metadata?.buyingOption && metadata?.buyingOption !== '{}' && metadata?.buyingOption !== 'Embassy fee'" class="f_col gap_5 mb_10">
        <span class="ml_20">{{'document' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="folder">{{('document.' + metadata?.buyingOption) | translate}}
        </app-slide-element>
      </div>

      <div class="f_col gap_5 mb_10" *ngIf="currentPayment?.operationId" [matTooltip]="api.isZwevisa ? ('tooltip.operationId' | translate) : ''">
        <span class="ml_10">{{'document.type.DYN.' + api.env.type | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="file">{{(currentPayment.operationId) | translate}}
        </app-slide-element>
      </div>

       <div class="f_col gap_5 mb_10" *ngIf="metadata?.Nationality">
        <span class="ml_10">{{'form.nationality' | translate}}</span>
        <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="globe">
          {{('DYN.' + metadata.Nationality) | translate}}
        </app-slide-element>
      </div>

      <div *ngIf="api.env.type === DocumentType.ZWEVISA" class="f_col gap_5 mb_10 pointerCursor" (click)="currentPayment?.internalReference && window.open(api.env.referenceUri + currentPayment.internalReference, '_new')" [matTooltip]="api.isZwevisa ? ('tooltip.internalReferenceNoFilter' | translate) : ''">
        <span class="ml_10">{{'internalReference' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="hashtag">{{currentPayment.internalReference}}
        </app-slide-element>
      </div>

      <div class="f_col gap_5 mb_10" *ngIf="metadata?.Documents?.PurposeOfVisit" [matTooltip]="api.isZwevisa ? ('tooltip.purposeOfVisit' | translate) : ''">
        <span class="ml_10">{{'PurposeOfVisit' | translate}}</span>
        <app-slide-element [class.no-pointer]="!selectMode" [noWrap]="true" bgColor="#FFFFFF" rightIcon="question-circle">
          {{('DYN.' + metadata?.Documents.PurposeOfVisit) | translate}}
          <input class="docCheck" *ngIf="selectMode" type="checkbox"
                 (click)="checkSelect($event, VisaFN.PURPOSE_OF_VISIT)">
        </app-slide-element>
      </div>


      <div class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.tableLocalUsageUntil' | translate) : ''" [class.usage-error]="isLocalUsageUntilError()" *ngIf="api.env.type === DocumentType.ZWEVISA">
        <span class="ml_10">{{'form.localUsageUntil' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="calendar-day">{{currentPayment.localUsageUntil ? (currentPayment.localUsageUntil | date: 'dd/MM/yyyy') : ''}}
        </app-slide-element>
      </div>

      <div class="flex gap_10 fullWidth" *ngIf="api.env.type === DocumentType.ZWEVISA">
        <div class="f_col gap_5 mb_10 fullWidth" *ngIf="currentPayment?.usageAfter" [class.usage-error]="!usageAfterValid">
          <span class="ml_10">{{'form.usageAfter' | translate}}</span>
          <app-slide-element [noPointerEvent]="!canUpdateField()" (click)="canUpdateField() && updateField(VisaFN.USAGE_AFTER, 'date', currentPayment.usageAfter)" [noWrap]="true" bgColor="#FFFFFF"
                             rightIcon="calendar-days" [matTooltip]="api.isZwevisa ? ('tooltip.tableUsageAfter' | translate) : ''">
            <div class="flex f_align_c gap_5">
              {{currentPayment.usageAfter | date: 'dd/MM/yyyy'}}
              <fa-icon *ngIf="canUpdateField()" icon="pen-to-square" size="xs"></fa-icon>
            </div>
          </app-slide-element>
        </div>
        <div class="f_col gap_5 mb_10 fullWidth" [class.usage-error]="!usageUntilValid && currentPayment?.usageUntil">
          <span class="ml_10">{{'form.usageUntil' | translate}}</span>
          <app-slide-element [noPointerEvent]="!canUpdateField()" (click)="canUpdateField() && updateField(VisaFN.USAGE_UNTIL, 'date', currentPayment.usageUntil)" [noWrap]="true" bgColor="#FFFFFF"
                             rightIcon="calendar-days" [matTooltip]="api.isZwevisa ? ('tooltip.tableUsageUntil' | translate) : ''">
            <div class="flex f_align_c gap_5">
              {{currentPayment.usageUntil | date: 'dd/MM/yyyy'}}
              <fa-icon *ngIf="canUpdateField()" icon="pen-to-square" size="xs"></fa-icon>
            </div>
          </app-slide-element>
        </div>
      </div>

      <div class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.amount' | translate) : ''">
        <span class="ml_10">{{'form.amount' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="wallet">{{getAmount(currentPayment?.currency, (currentPayment.amountCents))}}
        </app-slide-element>
      </div>

      <div class="f_col gap_5 mb_10" *ngIf="referencePaymentOnarrival" [matTooltip]="api.isZwevisa ? ('tooltip.paymentReference' | translate) : ''">
        <span class="ml_10">{{'paymentReference.ask' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="hashtag">{{referencePaymentOnarrival}}
        </app-slide-element>
      </div>

      <div class="f_col gap_5  mb_10" *ngIf="currentPayment?.amountCents && (this.currentPayment.operationId !== DocumentType.ZWEENTRY && this.currentPayment.operationId !== DocumentType.ZWEEXIT)" [matTooltip]="api.isZwevisa ? ('tooltip.paymentType' | translate) : ''">
        <span class="ml_10">{{'form.paymentType' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" class="no-pointer"
                           rightIcon="file-invoice">{{(currentPayment?.externalId ? 'payment.type.online' : 'payment.type.offline') | translate}}
        </app-slide-element>
      </div>
      <div class="f_col gap_5 mb_10" *ngIf="(currentPayment?.state || currentPayment?.state === 0) && api.env.type === DocumentType.PASS">
        <span class="ml_10">{{('request.state.DYN.' + api.env.type) | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" class="no-pointer"
                           rightIcon="money-check-dollar">{{('payment_status.DYN.' + api.env.type  + '.' + currentPayment.state) | translate}}
        </app-slide-element>
      </div>

      <div *ngIf="currentPayment?.batchId" class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.tableBatchId' | translate) : ''">
        <span class="ml_10">{{'trip.name' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="hashtag">{{currentPayment.batchId}}
        </app-slide-element>
      </div>

      <div class="f_col gap_5 mb_10" *ngIf="currentPayment?.externalId">
        <span class="ml_10">{{'form.externalId' | translate}}</span>
        <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="true"
                           rightIcon="file-invoice-dollar">{{currentPayment.externalId}}
        </app-slide-element>
      </div>

      <div *ngIf="metadata.BorderPass && api.env.nationalityNoDeclaration.includes(metadata.Nationality)" class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.borderPass' | translate) : ''">
        <span class="ml_10">{{'borderPass.emit' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="hashtag">{{metadata.BorderPass}}
        </app-slide-element>
      </div>

      <div *ngIf="currentPayment?.id" class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.applicationId' | translate) : ''">
        <span class="ml_10">{{'application.id' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                           rightIcon="hashtag">{{currentPayment.id}}
        </app-slide-element>
      </div>

      <div class="f_col gap_5  mb_10 pointerCursor" *ngIf="metadata?.VisaReference" [matTooltip]="api.isZwevisa ? ('tooltip.visaApplicationReference' | translate) : ''" (click)="nav.to('admin-request-select/' + metadata.VisaReference,  undefined, {queryParams: {from : 'admin-request-select/' + currentPayment.id }})">
        <span class="ml_10">{{'extend.visaReference' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" class="no-pointer"
                           rightIcon="file-invoice">{{metadata.VisaReference}}
        </app-slide-element>
      </div>

      <div *ngIf="currentPayment?.externalIndexedData" class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ('tooltip.externalIndexedData' | translate) : ''">
        <span class="ml_10">{{'externalIndexedData' | translate}}</span>
        <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF"
                   rightIcon="hashtag">{{getFormattedExternalIndexedData()}}
        </app-slide-element>
      </div>
    </div>
    <div class="right-container">
      <div [ngClass]="scanDocument?.documentWarning ? 'warning-container' : scanDocument?.documentError?.indexOf('err') === 0 ? 'refusal-container' : 'validate-container'" *ngIf="(scanDocument?.documentError || scanDocument?.documentWarning) && showComparison">
        <div class="flex">
          <fa-icon *ngIf="scanDocument?.documentError?.indexOf('err') === 0" class="mr_5" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
          <app-paragraph class="refusal-desc">{{(scanDocument?.documentWarning || scanDocument?.documentError) | translate}}</app-paragraph>
        </div>
      </div>

      <app-collapse title="{{'watchlistCollapse' | translate: {categories: getCategoriesElement()} }}" [expanded]="true" *ngIf="currentPayment?.watchlistsResponse?.matches?.length && environment.type === DocumentType.ZWEVISA" [matTooltip]="api.isZwevisa ? ('tooltip.watchlist' | translate) : ''" class="apiPnr-Collapse">
        <div class="f_col gap_20">
          @for (watchlist of currentPayment.watchlistsResponse.matches; let index = $index; track watchlist.watchlist.uuid;){
            <div class="f_col gap_20 apiPnr_block" (click)="watchlist?.item?.link && window.open(watchlist?.item?.link, '_new')" *ngIf="watchlist?.watchlist?.category">
              <app-paragraph class="fw_bold" [innerHTML]="'watchlist.match' | translate: {numberMatch: index + 1, matchCategory: watchlist.watchlist.category}"></app-paragraph>
              <div class="apiPnr-container">
                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.type">
                  <b>{{'match.type' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist.type}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.score">
                  <b>{{'global.score' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.score}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.level">
                  <b>{{'watchlist.level' | translate}}</b>
                  <span class="apiPnr-datas">{{watchlist?.level}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.watchlist?.name">
                  <b>{{'watchlist.name' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.watchlist?.name}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.watchlist?.alertLevel">
                  <b>{{'watchlist.alertLevel' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.watchlist?.alertLevel}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.watchlist?.category">
                  <b>{{'watchList.category' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.watchlist?.category}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.name">
                  <b>{{'completeName' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.name}}</span>
                </div>

                <ng-container *ngIf="watchlist?.item?.aliases?.length">
                  @for(aliases of watchlist?.item?.aliases; let index = $index; track aliases;){
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="aliases">
                      <b>{{'alias' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{aliases}}</span>
                    </div>
                  }
                </ng-container>

                <ng-container *ngIf="watchlist?.item?.datesOfBirth?.length">
                  @for(dateOfBirth of watchlist?.item?.datesOfBirth; let index = $index; track dateOfBirth;){
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="dateOfBirth">
                      <b>{{'dateOfBirth' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{dateOfBirth | date}}</span>
                    </div>
                  }
                </ng-container>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.placeOfBirth">
                  <b>{{'PlaceOfBirth' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.placeOfBirth}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.hair">
                  <b>{{'match.hair' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.hair}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.eyes">
                  <b>{{'match.eyes' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.eyes}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.height">
                  <b>{{'match.height' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.height}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.weight">
                  <b>{{'match.weight' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.weight}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.gender">
                  <b>{{'Gender' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.gender}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.race">
                  <b>{{'match.race' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.race}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.occupation">
                  <b>{{'Occupation' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.occupation}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="watchlist?.item?.marks">
                  <b>{{'watchlist.marks' | translate}}: </b>
                  <span class="apiPnr-datas">{{watchlist?.item?.marks}}</span>
                </div>

                <ng-container *ngIf="watchlist?.item?.nationalities?.length">
                  @for(nationalities of watchlist?.item?.nationalities; let index = $index; track nationalities;){
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="nationalities">
                      <b>{{'Nationalities' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{'DYN.' + nationalities | translate}}</span>
                    </div>
                  }
                </ng-container>

                <ng-container *ngIf="watchlist?.item?.documents?.length">
                  @for(document of watchlist?.item?.documents; let index = $index; track document;){
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="document?.number">
                      <b>{{'document.number' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{document?.number}}</span>
                    </div>
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="document?.type">
                      <b>{{'document.type' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{document?.type}}</span>
                    </div>
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="document?.issuer">
                      <b>{{'document.issuer' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{'DYN.' + document?.issuer | translate}}</span>
                    </div>
                  }
                </ng-container>

                <ng-container *ngIf="watchlist?.item?.images?.length">
                  @for(image of watchlist?.item?.images; let index = $index; track image;){
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="image?.url">
                      <b class="linkApiPnr" (click)="handleClickLink($event, image?.url)">{{'watchlist.image.link' | translate: {index: index + 1} }}</b>
                    </div>
                    <div class="apiPnr_line flex f_between gap_5" *ngIf="image?.matched !== undefined">
                      <b>{{'image.match' | translate: {index: index + 1} }}</b>
                      <span class="apiPnr-datas">{{image?.matched}}</span>
                    </div>
                  }
                </ng-container>
              </div>
            </div>
          }
        </div>
      </app-collapse>

      <app-collapse title="{{'apiPnrCollapse' | translate}}" [expanded]="true" *ngIf="currentPayment?.apipnrResponse?.matches?.length && environment.type === DocumentType.ZWEVISA" [matTooltip]="api.isZwevisa ? ('tooltip.apiPnr' | translate) : ''" class="apiPnr-Collapse apiPnr-main">
        <div class="f_col gap_20">
          @for (apiPnr of currentPayment.apipnrResponse.matches; let index = $index; track apiPnr.uuid;){
            <div class="f_col gap_20 apiPnr_block">
              <app-paragraph class="fw_bold" [innerHTML]="'apipnrResponse.match' | translate: {numberMatch: index + 1}"></app-paragraph>
              <div class="apiPnr-container">
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.name?.name">
                  <b>{{'match.name' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.name.name}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.name?.score">
                  <b>{{'match.score.name' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.name.score}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.carrier">
                  <b>{{'match.flightCarrier' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.carrier}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.flightNumber">
                  <b>{{'match.flightNumber' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.flightNumber}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.domestic">
                  <b>{{'match.flightOriginDomestic' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.domestic}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.icao">
                  <b>{{'match.flightOriginIcao' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.icao}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.lat">
                  <b>{{'match.flightOriginLat' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.lat}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.lng">
                  <b>{{'match.flightOriginLng' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.lng}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.iata">
                  <b>{{'match.flightOriginIata' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.iata}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.city">
                  <b>{{'match.flightOriginCity' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.city}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.state">
                  <b>{{'match.flightOriginState' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.state}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.country">
                  <b>{{'match.flightOriginCountry' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.country}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.continent">
                  <b>{{'match.flightOriginContinent' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.continent}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.name">
                  <b>{{'match.flightOriginName' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.name}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.origin?.timezone">
                  <b>{{'match.flightOriginTimezone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.origin?.timezone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.domestic">
                  <b>{{'match.flightDestinationDomestic' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.domestic}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.icao">
                  <b>{{'match.flightDestinationIcao' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.icao}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.lat">
                  <b>{{'match.flightDestinationLat' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.lat}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.lng">
                  <b>{{'match.flightDestinationLng' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.lng}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.iata">
                  <b>{{'match.flightDestinationIata' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.iata}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.city">
                  <b>{{'match.flightDestinationCity' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.city}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.state">
                  <b>{{'match.flightDestinationState' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.state}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.country">
                  <b>{{'match.flightDestinationCountry' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.country}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.continent">
                  <b>{{'match.flightDestinationContinent' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.continent}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.name">
                  <b>{{'match.flightDestinationName' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.name}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.destination?.timezone">
                  <b>{{'match.flightDestinationTimezone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.destination?.timezone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.utcstr">
                  <b>{{'match.flightDepartureUtcstr' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.departure.utcstr) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.local?.date">
                  <b>{{'match.localDepartureDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.departure.local.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.local?.time">
                  <b>{{'match.localDepartureTime' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.local.time}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.local?.totalMinutes">
                  <b>{{'match.localDepartureTotalMinutes' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.local.totalMinutes}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.local?.day">
                  <b>{{'match.localDepartureDay' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.local.day}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.local?.zone">
                  <b>{{'match.localDepartureZone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.local.zone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.airport?.date">
                  <b>{{'match.airportDepartureDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.departure.airport.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.airport?.time">
                  <b>{{'match.airportDepartureTime' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.airport.time}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.airport?.totalMinutes">
                  <b>{{'match.airportDepartureTotalMinutes' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.airport.totalMinutes}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.airport?.day">
                  <b>{{'match.airportDepartureDay' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.airport.day}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.airport?.zone">
                  <b>{{'match.airportDepartureZone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.airport.zone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.utc?.date">
                  <b>{{'match.utcDepartureDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.departure.utc.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.utc?.time">
                  <b>{{'match.utcDepartureTime' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.utc.time}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.utc?.totalMinutes">
                  <b>{{'match.utcDepartureTotalMinutes' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.utc.totalMinutes}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.utc?.day">
                  <b>{{'match.utcDepartureDay' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.utc.day}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.utc?.zone">
                  <b>{{'match.utcDepartureZone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.departure.utc.zone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.departure?.original">
                  <b>{{'match.originalDeparture' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.departure.original) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.utcstr">
                  <b>{{'match.flightArrivalUtcstr' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.arrival.utcstr) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.local?.date">
                  <b>{{'match.localArrivalDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.arrival.local.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.local?.time">
                  <b>{{'match.localArrivalTime' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.local.time}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.local?.totalMinutes">
                  <b>{{'match.localArrivalTotalMinutes' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.local.totalMinutes}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.local?.day">
                  <b>{{'match.localArrivalDay' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.local.day}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.local?.zone">
                  <b>{{'match.localArrivalZone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.local.zone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.airport?.date">
                  <b>{{'match.airportArrivalDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.arrival.airport.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.airport?.time">
                  <b>{{'match.airportArrivalTime' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.airport.time}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.airport?.totalMinutes">
                  <b>{{'match.airportArrivalTotalMinutes' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.airport.totalMinutes}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.airport?.day">
                  <b>{{'match.airportArrivalDay' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.airport.day}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.airport?.zone">
                  <b>{{'match.airportArrivalZone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.airport.zone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.utc?.date">
                  <b>{{'match.utcArrivalDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.arrival.utc.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.utc?.time">
                  <b>{{'match.utcArrivalTime' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.utc.time}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.utc?.totalMinutes">
                  <b>{{'match.utcArrivalTotalMinutes' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.utc.totalMinutes}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.utc?.day">
                  <b>{{'match.utcArrivalDay' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.utc.day}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.utc?.zone">
                  <b>{{'match.utcArrivalZone' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.arrival.utc.zone}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.arrival?.original">
                  <b>{{'match.originalArrival' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.flight.arrival.original) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.flight?.bound">
                  <b>{{'match.flightBound' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.flight.bound}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.document?.type">
                  <b>{{'match.documentType' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.document.type}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.document?.number">
                  <b>{{'match.documentNumber' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.document.number}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.document?.issuer">
                  <b>{{'match.documentIssuer' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.document.issuer}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.document?.issued">
                  <b>{{'match.documentIssued' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.document.issued) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.document?.expiration">
                  <b>{{'match.documentExpiration' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.document.expiration) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.document?.score">
                  <b>{{'match.documentScore' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.document.score}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.time?.type">
                  <b>{{'match.timeType' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.time.type}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.time?.date">
                  <b>{{'match.timeDate' | translate}}: </b>
                  <span class="apiPnr-datas">{{cleanDate(apiPnr.time.date) | date}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.time?.score">
                  <b>{{'match.timeScore' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.time.score}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.time?.scope">
                  <b>{{'match.timeScope' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.time.scope}}</span>
                </div>

                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.nationality">
                  <b>{{'match.nationality' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.nationality}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.gender">
                  <b>{{'match.gender' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.gender}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.uuid">
                  <b>{{'match.uuid' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.uuid}}</span>
                </div>
                <div class="apiPnr_line flex f_between gap_5" *ngIf="apiPnr?.travellerUUID">
                  <b>{{'match.travelerUUID' | translate}}: </b>
                  <span class="apiPnr-datas">{{apiPnr.travellerUUID}}</span>
                </div>
              </div>
            </div>
          }
        </div>
      </app-collapse>

      <div class="comparison-container" *ngIf="scanDocument?.scanDatas && showComparison">
        <div class="comparison comparison-passport" [class.documentError]="scanDocument?.documentError && scanDocument?.documentError?.indexOf('err') === 0" [class.documentWarning]="scanDocument?.documentWarning && scanDocument?.documentWarning?.indexOf('err') === 0" [matTooltip]="api.isZwevisa ? ('tooltip.passportComparison' | translate) : ''">
          <div [class.passport-documentError]="scanDocument?.documentError && scanDocument?.documentError?.indexOf('err') === 0" [class.passport-documentWarning]="scanDocument?.documentWarning && scanDocument?.documentWarning?.indexOf('err') === 0" *ngIf="(scanDocument?.documentError && scanDocument?.documentError?.indexOf('err') === 0) || (scanDocument?.documentWarning && scanDocument?.documentWarning?.indexOf('err') === 0)">
            <fa-icon class="mr_5" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
          </div>
          <app-paragraph class="fw_bold fs-h5 text-center">{{'scanned.passport' | translate}}</app-paragraph>
          <fa-icon class="comparison-logo" icon="passport"></fa-icon>
          <div class="passport-img">
            <img class="img" [src]="scanDocument?.documentPhoto" *ngIf="scanDocument?.documentPhoto">
            <div class="img img-noDoc" *ngIf="!scanDocument?.documentPhoto">
            {{'no.passportPhoto' | translate}}</div>
          </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.firstName">
              <span class="fw_bold">{{'form.firstName.scanned' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                {{scanDocument.scanDatas.fields.firstName}}
              </app-slide-element>
            </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.lastName">
              <span class="fw_bold">{{'form.lastName.scanned' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                {{scanDocument.scanDatas.fields.lastName}}
              </app-slide-element>
            </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.birthDate">
              <span class="fw_bold">{{'form.birthDate.scanned' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="cake-candles">
                {{convertToEpoch(scanDocument.scanDatas.fields.birthDate, false, true) | date}}
              </app-slide-element>
            </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.documentNumber">
              <span class="fw_bold">{{'form.documentNumber.scanned' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
                {{scanDocument.scanDatas.fields.documentNumber}}
              </app-slide-element>
            </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.sex">
              <span class="fw_bold">{{'form.gender.scanned' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="venus-mars">
                {{scanDocument.scanDatas.fields.sex}}
              </app-slide-element>
            </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.nationality">
              <span class="fw_bold">{{'form.nationality.scanned' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                {{'DYN.' + scanDocument.scanDatas.fields.nationality | translate}}
              </app-slide-element>
            </div>
            <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.expirationDate">
              <span class="fw_bold">{{'form.expireDate' | translate}}</span>
              <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                {{convertToEpoch(scanDocument.scanDatas.fields.expirationDate) | date}}
              </app-slide-element>
            </div>
        </div>
        <div class="between">
          <fa-icon size="2xl" icon="arrows-left-right"></fa-icon>
        </div>
         <div class="comparison comparison-classic" [matTooltip]="api.isZwevisa ? ('tooltip.classicComparison' | translate) : ''">
        <app-paragraph class="fw_bold fs-h5 text-center">{{'application.data' | translate}}</app-paragraph>
        <fa-icon class="comparison-logo" icon="file"></fa-icon>
        <div class="passport-img">
          <img class="img" [src]="metadata.Documents.PassportPhoto" *ngIf="metadata?.Documents?.PassportPhoto">
          <div class="img img-noDoc" *ngIf="!metadata?.Documents?.PassportPhoto">
          {{'no.passportPhoto' | translate}}</div>
        </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.FirstName || metadata?.firstName || metadata?.first_name">
            <span>{{'form.firstName' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(scanDocument?.scanDatas?.fields?.firstName, metadata?.FirstName || metadata?.firstName || metadata?.first_name) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode" rightIcon="user">
              {{metadata.FirstName || metadata.firstName}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.FIRST_NAME)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.LastName|| metadata?.lastName">
            <span>{{'form.lastName' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(scanDocument?.scanDatas?.fields?.lastName, metadata?.LastName|| metadata?.lastName) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode" rightIcon="user">
              {{metadata.LastName || metadata.lastName}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.FIRST_NAME)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.BirthDay">
            <span>{{'form.birthDate' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(convertToEpoch(scanDocument?.scanDatas?.fields?.birthDate) | date, metadata?.BirthDay | date) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode"
                               rightIcon="cake-candles">
              {{metadata.BirthDay | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.BIRTH_DAY)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.PassportNumber">
            <span>{{'form.passportNumber' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(scanDocument?.scanDatas?.fields?.documentNumber, metadata?.PassportNumber) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode" rightIcon="hashtag">
              {{metadata.PassportNumber}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PASSPORT_NUMBER)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.Gender">
            <span>{{'form.gender' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(scanDocument?.scanDatas?.fields?.sex, metadata?.Gender) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode"
                               rightIcon="venus-mars">
              {{('applicant.gender.' + metadata.Gender) | translate}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.GENDER)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.Nationality">
            <span>{{'form.nationality' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(scanDocument?.scanDatas?.fields?.nationality, metadata?.Nationality) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode" rightIcon="globe">
              {{('DYN.' + metadata.Nationality) | translate}}
            </app-slide-element>
          </div>

          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.DateOfExpiry">
            <span>{{'form.expireDate' | translate}}</span>
            <app-slide-element [noWrap]="true" [bgColor]="!isSameData(convertToEpoch(scanDocument?.scanDatas?.fields?.expirationDate), metadata?.DateOfExpiry) ? '#FFCCB6' : '#FFFFFF'" [class.no-pointer]="!selectMode" rightIcon="globe">
              {{metadata?.DateOfExpiry | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.DATE_OF_EXPIRY)">
            </app-slide-element>
          </div>
          <app-paragraph *ngIf="!metadata?.DateOfExpiry && !metadata?.Nationality && !metadata?.Gender && !metadata?.PassportNumber && !metadata?.BirthDay && !metadata?.LastName && !metadata?.lastName && !metadata?.FirstName && !metadata?.firstName && !metadata?.first_name" class="fw_bold fs-h5 text-center mt_10" textAlign="center">{{'noScannedComparison' | translate}}</app-paragraph>
        </div>
      </div>
      <app-button *ngIf="scanDocument?.scanDatas && (this.currentPayment?.operationId === DocumentType.ZWEVISAARR || this.currentPayment?.operationId === DocumentType.ZWEENTRY || this.currentPayment?.operationId === DocumentType.ZWEVISA) && hasDifferences() && showComparison && (this.request.getStatusTheme(this.currentPayment, true).state === 'readyCheckIn' || this.request.getStatusTheme(this.currentPayment, true).state === 'readyCheck')" (btnClicked)="updateScannedPassport()"  size="xsm" class="transfert-data" [matTooltip]="api.isZwevisa ? ('tooltip.transfertFromPassport' | translate) : ''">{{'transfertFromPassport' | translate}}</app-button>

      <div *ngIf="request.getStatusTheme(currentPayment, true).state === 'readyCheckOut' && environment.type === DocumentType.ZWEVISA">
        <div *ngFor="let document of currentPayment?.consumeData?.Documents; let i = index">
          <div *ngIf="document?.Type === ConsumePaymentNoticeType.REPORTING">
            <app-collapse [title]="'noticePrint.title' | translate" [expanded]="true" [matTooltip]="api.isZwevisa ? ('noticePrint.matTooltip' | translate) : ''">
              <div class="f_col gap_20">
                  <div class="f_col gap_20 apiPnr_block">
                    <div class="apiPnr-container">
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Address" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.address' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Address}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.AddressZimbabwe" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.addressZimbabwe' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.AddressZimbabwe}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Email" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.email' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Email}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ExpectedDeparture" class="apiPnr_line flex f_between gap_5">
                          <b>{{'DepartureDate' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ExpectedDeparture | date}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Host" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.hostName' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Host}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.HostPhone" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.hostPhone' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.HostPhone}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Phone" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.phone' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Phone}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Purpose" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.purpose' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.Purpose}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ReportOn" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.reportOn' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ReportOn | date}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ReportingPost" class="apiPnr_line flex f_between gap_5">
                          <b>{{'reportingPost' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ReportingPost}}</span>
                        </div>
                        <div *ngIf="currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ReportAtIntervalDays" class="apiPnr_line flex f_between gap_5">
                          <b>{{'form.reportInterval' | translate}}: </b>
                          <span class="apiPnr-datas">{{currentPayment?.consumeData?.Documents[i]?.ReportingFields?.ReportAtIntervalDays}}</span>
                        </div>
                    </div>
                  </div>
              </div>
            </app-collapse>
          </div>
        </div>
      </div>

      <div *ngIf="currentPayment?.batch.views.length > 1 && api.env.type === DocumentType.ZWEVISA" class="f_col gap_5">
        <app-paragraph class="batchIds-desc">{{'batchIds.desc' | translate: {trip: currentPayment?.batchId} }}</app-paragraph>
        <div class="batchIds-container">
          @for (batch of currentPayment.batch.views; track batch.passportNumber; ) {
            <div class="batchId-button" >
              <app-button [color]="batch.id === paymentId ? 'primary' :  'secondary'"   size="xs" (btnClicked)="toggleSelectMode(false); printVisaApplication = []; nav.to('admin-request-select/' + batch.id)">{{batch.firstName + ' ' + batch.lastName + ' | ' + batch.passportNumber}}</app-button>
            </div>
          }
        </div>
        <app-divider class="mt_10 mb_10"></app-divider>
      </div>


      <div *ngIf="api.isZwevisa && currentPayment?.refusalReason && (currentPayment?.state === status.EXPIRED || currentPayment?.state === status.PENDING)" class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ? ((currentPayment?.state === status.EXPIRED ? (request.isBlacklisted(currentPayment) ? 'tooltip.finalRefuseBlacklist' : 'tooltip.finalRefuse') : 'tooltip.finalPending') | translate) : ''">
        <div class="refusal-container" [class.refusal-container-pending]="currentPayment?.state === status.PENDING">
          <fa-icon class="mr_5" size="xl" [icon]="request.isBlacklisted(currentPayment) ? 'ban' : 'triangle-exclamation'"></fa-icon>
          <div class="f_col">
            <app-paragraph class="refusal-desc">{{(currentPayment?.state === status.EXPIRED ? ((isExtension ? 'refusal.desc.extension.DYN.' : 'refusal.desc.DYN.') + api.env.type + (request.isBlacklisted(currentPayment) ? '.blacklisted': '')) : 'pending.desc') | translate}}</app-paragraph>
            <app-paragraph class="ml_10 refusal-text">{{"\"" + currentPayment.refusalReason + "\""}}</app-paragraph>
            <app-paragraph *ngIf="currentPayment.refusalRefusedFields" class="ml_10">
              {{ 'refusal.desc2' | translate }}
              <ng-container *ngFor="let field of currentPayment.refusalRefusedFields; let i = index">
                {{ field | translate }}<ng-container *ngIf="i < currentPayment.refusalRefusedFields.length - 1">,</ng-container>
              </ng-container>
            </app-paragraph>
          </div>
        </div>
      </div>

      <div *ngIf="currentPayment?.state === status.PAID && environment.type === DocumentType.ZWEVISA && !isZweentryOrdinaryCatC()" class="f_col gap_5 mb_10" [matTooltip]="api.isZwevisa ?  ('tooltip.finalApprove'  | translate) : ''">
        <div class="validate-container">
          <div class="f_col">
            <app-paragraph>{{'approve.text' | translate}}</app-paragraph>
          </div>
        </div>
      </div>

      <div *ngIf="currentPayment?.state === status.USED && request.detectCheckRefusal(currentPayment) && environment.type === DocumentType.ZWEVISA" class="f_col gap_5 mb_10">
        <div class="refusal-container">
          <div class="f_col">
            <app-paragraph [innerHTML]="(request.detectCheckRefusal(currentPayment) === OperationType.REFUSEIN ? ('payment_status.desc.DYN.zwevisa.5.checkIn' + (currentPayment.consumeData.OperationComments ? '.reason' : '')) : 'payment_status.desc.DYN.zwevisa.5.checkOut') | translate : {reason: currentPayment.consumeData.OperationComments}"></app-paragraph>
          </div>
        </div>
      </div>


      <div *ngIf="currentPayment?.state === status.USED && environment.type === DocumentType.ZWEVISA && request.getDeportedStatus(currentPayment) as deportedStatus" class="f_col gap_5 mb_10">
        <div class="warning-container">
          <div class="f_col">
            <app-paragraph [innerHTML]="(deportedStatus.consumeData.ReturnedPassengerReason ?
            ('payment.inReturned' | translate) + ('reasonAdd' | translate : { reason: deportedStatus.consumeData.ReturnedPassengerReason }) :
            ('payment.inReturned' | translate))">
          </app-paragraph>
          </div>
        </div>
      </div>

      <app-collapse title="{{(isExtension ? 'all.reviews.extension' :  'all.reviews') | translate}}" [expanded]="currentPayment?.state === status.PAID_NOT_CONFIRMED" *ngIf="currentPayment?.events.length > 1 && environment.type === DocumentType.ZWEVISA && isEvent()" [matTooltip]="api.isZwevisa ? ('tooltip.allReviews' | translate) : ''">
        <div class="f_col gap_5">
          @for (event of currentPayment?.events; track event.id;) {
            <div *ngIf="event.type === PlatformEventType.PAYMENT_VALIDATED">
              <div class="validate-container gap_15">
                <fa-icon size="xl" icon="check-circle"></fa-icon>
                <div class="f_col">
                  <app-paragraph class="validate-desc"><b><u>{{getReviewNr(event)}}</u></b>{{(isExtension ? 'validate.extension.desc' : 'validate.desc') | translate: {date: (event.updatedOn | date: 'dd/MM/yyyy HH:mm')} }}<b><a [routerLink]="['/user', event.actor_id]"> {{sync.userDisplay(event.actor_id)}} #{{event.actor_id}}</a></b></app-paragraph>
                  <div *ngIf="JSONparse(event?.context)?.refusalReason">
                    <app-paragraph class="refusal-desc">{{('global.reason' | translate) + ': '}}</app-paragraph>
                    <app-paragraph class="refusal-text">{{JSONparse(event?.context)?.refusalReason}}</app-paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="event.type === PlatformEventType.PAYMENT_REFUSED">
              <div class="refusal-container gap_15">
                <fa-icon size="xl" [icon]="request.isBlacklisted(JSONparse(event?.context)) ? 'ban' : 'triangle-exclamation'"></fa-icon>
                <div class="f_col">
                  <app-paragraph class="validate-desc"><b><u>{{getReviewNr(event)}}</u></b>{{(isExtension ? 'refusal.review.extension.desc' :'refusal.review.desc' + (request.isBlacklisted(JSONparse(event?.context)) ? '.blacklisted' : '')) | translate: {date: (event.updatedOn | date: 'dd/MM/yyyy HH:mm')} }}<b><a [routerLink]="['/user', event.actor_id]"> {{sync.userDisplay(event.actor_id)}} #{{event.actor_id}}</a></b></app-paragraph>
                  <app-paragraph class="refusal-desc">{{('global.reason' | translate) + ': '}}</app-paragraph>
                  <app-paragraph class="refusal-text">{{JSONparse(event?.context)?.refusalReason}}</app-paragraph>
                </div>
              </div>
            </div>
            <ng-container *ngIf="event.type === PlatformEventType.PAYMENT_UPDATED && isJsonParsable(event.context) as parsedContext">
              <ng-container *ngIf="parsedContext.newOperation">
                <div class="refusal-container gap_15">
                  <fa-icon size="xl" icon='circle-down'></fa-icon>
                  <div class="f_col">
                    <app-paragraph class="validate-desc"
                      [innerHTML]="'event.downgrade' | translate: {
                        previousPayment: parsedContext.operation | translate,
                        newPayment: parsedContext.newOperation | translate,
                        date: (event.updatedOn | date: 'dd/MM/yyyy HH:mm'),
                        userId: sync.userDisplay(event.actor_id) +' #'+ event.actor_id
                      }">
                    </app-paragraph>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="event.type === PlatformEventType.PAYMENT_UPDATED && !event.place_id">
              <div class="validate-container gap_15">
                <fa-icon size="xl" icon="spell-check"></fa-icon>
                <div class="f_col">
                  <app-paragraph class="validate-desc">{{'corrected.desc' | translate: {date: (event.updatedOn | date: 'dd/MM/yyyy HH:mm')} }}</app-paragraph>
                  <app-paragraph *ngIf="currentPayment.refusalRefusedFields && isLatestUpdatedEvent(event)">
                    {{'correctionFields' | translate}}
                    <ng-container *ngFor="let field of currentPayment.refusalRefusedFields; let i = index">
                      <b>{{field | translate}}</b>
                      <ng-container *ngIf="i < currentPayment.refusalRefusedFields.length - 1">,</ng-container>
                    </ng-container>
                  </app-paragraph>
                </div>
              </div>
            </ng-container>
            <div *ngIf="event.type === PlatformEventType.PAYMENT_SUSPEND_CHANGE">
              <div class="refusal-container refusal-container-pending gap_15">
                <fa-icon size="xl" icon="triangle-exclamation"></fa-icon>
                <div class="f_col">
                  <app-paragraph class="validate-desc">{{'pending.review.desc' | translate: {date: (event.updatedOn | date: 'dd/MM/yyyy HH:mm')} }}<b><a [routerLink]="['/user', event.actor_id]"> {{sync.userDisplay(event.actor_id)}} #{{event.actor_id}}</a></b></app-paragraph>
                  <app-paragraph class="refusal-desc">{{('global.reason' | translate) + ': '}}</app-paragraph>
                  <app-paragraph class="refusal-text">{{JSONparse(event?.context)?.reason}}</app-paragraph>
                </div>
              </div>
            </div>
          }
        </div>
      </app-collapse>

      <div class="flex_col gap_20 f_align_c" *ngIf="environment.type === DocumentType.ZWEVISA && currentPayment?.state !== status.DELAY_PAY && currentPayment?.state !== status.PROPOSED">
        <app-paragraph *ngIf="currentPayment?.usageCount === 0" class="fw_bold operation-paragraph">{{'payment.noEvents' | translate}}</app-paragraph>
        <div *ngIf="currentPayment?.usageCount > 0" class="flex gap_10">
          <div class="flex gap_10 f_col" *ngFor="let event of moveEvents(); let i = index">
            <div class="flex f_col">
              <div class="flex gap_10">
                <app-paragraph
                  (click)="selectedOperation = i"
                  class="selectoperation"
                  [class.selectoperation_selected]="selectedOperation === i">
                    {{(event.consumeData?.Operation === OperationType.IN ? 'global.entry' :
                      (event.consumeData?.Operation === OperationType.INRETURNED) ? 'global.deported' :
                      'global.exit') | translate}}
                </app-paragraph>
                <button class="revert-event"
                        (click)="revertConsume(event)"
                        *ngIf="(api.hasPerm(api.perm.ALLOW_ADMIN) || (api.userRole.isAdmin && api.isZwevisa)) && !getCancelledEvent(event.id)"
                        [matTooltip]="api.isZwevisa ? ('tooltip.revertConsume' | translate) : ''">
                  <fa-icon class="doc-icon-dk" size="xl" icon="clock-rotate-left"></fa-icon>
                </button>
              </div>
            </div>
            <div *ngIf="selectedOperation === i && event.consumeData" class="f_col gap_10">
              <ng-container *ngIf="getCancelledEvent(event.id) as cancelledEvent">
                <div class="cancelled-event f_col">
                  <div class="flex gap_5">
                    <fa-icon size="lg" [icon]="'triangle-exclamation'"></fa-icon>
                    <app-paragraph [innerHTML]="'cancelled' | translate"></app-paragraph>
                  </div>
                  <app-paragraph class="cancelled-reason" *ngIf="cancelledEvent?.cancellationReason" [innerHTML]="'reasonAdd' | translate : { reason: cancelledEvent.cancellationReason }"></app-paragraph>
                </div>
              </ng-container>
              <div class="doc-container" *ngIf="selectedOperation === i && event.consumeData">
                <div class="doc doc-dk" *ngIf="scanDocument?.documentPhoto && showComparison" (click)="visualFile(scanDocument?.documentPhoto, 'photo.passport.scanned')">
                  <fa-icon class="doc-icon-dk" size="xl" icon="image-portrait"></fa-icon>
                  <app-paragraph textAlign="center">{{'photo.passport.scanned' | translate}}</app-paragraph>
                </div>
                <div class="doc" *ngIf="event.consumeData.ProfilePicture" (click)="visualFile(event.consumeData.ProfilePicture, 'photo.entryCountry.' + event.consumeData.Operation)" [matTooltip]="api.isZwevisa ? ('tooltip.consumeProfile.' + event.consumeData.Operation | translate) : ''">
                  <fa-icon class="doc-icon" size="xl" icon="camera"></fa-icon>
                  <app-paragraph textAlign="center">{{'photo.entryCountry' | translate}}</app-paragraph>
                </div>
                <div class="doc grey" *ngIf="!event.consumeData.ProfilePicture" [matTooltip]="api.isZwevisa ? ('tooltip.consumeNoProfile.' + event.consumeData.Operation | translate) : ''">
                  <fa-icon class="doc-icon" size="xl" icon="camera"></fa-icon>
                  <app-paragraph textAlign="center">{{'photo.unavailable' | translate}}</app-paragraph>
                </div>
                <div class="doc" [matTooltip]="api.isZwevisa ? ('tooltip.consumeTime.' + event.consumeData.Operation | translate) : ''">
                  <fa-icon class="doc-icon" size="xl" icon="location-dot"></fa-icon>
                  <app-paragraph textAlign="center">{{'time' | translate}}: {{event.updatedOn | date:'long'}}</app-paragraph>
                </div>
                <div class="doc" *ngIf="event.place" [matTooltip]="api.isZwevisa ? ('tooltip.consumePlace.' + event.consumeData.Operation | translate) : ''">
                  <fa-icon class="doc-icon" size="xl" icon="location-dot"></fa-icon>
                  <app-paragraph textAlign="center">{{'place' | translate}}: {{event.place.longName}}</app-paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-paragraph *ngIf="hasDocumentsToShow()" class="fw_bold">{{'documents' | translate}}</app-paragraph>
      <div class="doc-container">
        <app-divider *ngIf="scanDocument?.documentPhoto && ((scanDocument?.scanDatas?.fields?.documentNumber === metadata?.PassportNumber) && (scanDocument?.scanDatas?.fields?.nationality === metadata?.Nationality))" orientation="vertical"></app-divider>
        @for (document of metadataDocuments; track $index) {
          @if((document.checkComparison && showComparison) || !document.checkComparison){
            <div class="doc" (click)="visualFile(undefined, document.nameDocument)" [ngClass]="document.customClass">
              @if(document.previewDirect){
                <p class="title-previewDirect">{{document.previewDirect | translate}}</p>
              }
              @if(document.icon) {
                <fa-icon class="doc-icon" size="xl" [icon]="document.icon"></fa-icon>
              }
              @if(!document.previewDirect) {
                <app-paragraph textAlign="center">{{document.title | translate}}</app-paragraph>
              }
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, document.nameDocument)">
              @if(document.previewDirect && !isPDF(document.document)) {
                <img [src]="document.document" class="preview-direct"/>
              }
              @if(!isPDF(document.document)) {
                <div class="hover-overlay">
                  <button class="doc-actionBtn" (click)="$event.stopPropagation(); actionPrint(document.title, document.document)">
                    <fa-icon size="xl" icon="print"></fa-icon>
                  </button>
                </div>
              }
            </div>
          }
        }
      </div>
      <div class="categories-container">
        <div class="category-container"
             *ngIf="metadata?.FirstName || metadata?.firstName  || metadata?.first_name || metadata?.LastName || metadata?.lastName || metadata?.last_name || metadata?.BirthDay || metadata?.PassportNumber || metadata?.PlaceOfBirth || metadata?.Gender || metadata?.MaritalStatus || metadata?.Occupation || metadata?.Nationality || metadata?.idCard || metadata?.CompanionsSize">
          <app-paragraph class="fw_bold">{{'personal.data' | translate}}</app-paragraph>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.Nationality">
            <span class="ml_10">{{'form.nationality' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="globe">
              {{('DYN.' + metadata.Nationality) | translate}}
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.FirstName || metadata?.firstName || metadata?.first_name">
            <span class="ml_10">{{'form.firstName' | translate}}</span>
            <app-slide-element (click)="canUpdateField() && updateField(VisaFN.FIRST_NAME, 'text', metadata.FirstName || metadata.firstName || metadata.first_name)" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode && !canUpdateField()" rightIcon="user">
              <div class="flex f_align_c gap_5">
                {{metadata.FirstName || metadata.firstName || metadata.first_name}}
                <fa-icon *ngIf="canUpdateField()" icon="pen-to-square" size="xs"></fa-icon>
              </div>
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                      (click)="checkSelect($event, VisaFN.FIRST_NAME)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.LastName || metadata?.lastName || metadata?.last_name">
            <span class="ml_10">{{'form.lastName' | translate}}</span>
            <app-slide-element (click)="canUpdateField() && updateField(VisaFN.LAST_NAME, 'text', metadata.LastName || metadata?.lastName || metadata?.last_name)"
                               [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode && !canUpdateField()" rightIcon="user">
              <div class="flex f_align_c gap_5">
                {{metadata.LastName || metadata?.lastName || metadata?.last_name}}
                <fa-icon *ngIf="canUpdateField()" icon="pen-to-square" size="xs"></fa-icon>
              </div>
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                      (click)="checkSelect($event, VisaFN.LAST_NAME)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.BirthDay">
            <span class="ml_10">{{'form.birthDate' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                                rightIcon="cake-candles">
              {{metadata.BirthDay | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                      (click)="checkSelect($event, VisaFN.BIRTH_DAY)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.PassportType">
            <span class="ml_10">{{'form.passportType' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
              {{('DYN.' + metadata.PassportType) | translate}}
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.PassportCountry">
            <span class="ml_10">{{'form.passportCountry' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="globe">
              {{('DYN.' + metadata.PassportCountry) | translate}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PASSPORT_COUNTRY)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5 fullWidth" *ngIf="metadata?.PassportNumber">
            <span class="ml_10">{{'form.passportNumber' | translate}}</span>
            <app-slide-element (click)="canUpdateField() && updateField(VisaFN.PASSPORT_NUMBER, 'text', metadata.PassportNumber)"
                               [class.no-pointer]="!selectMode && !canUpdateField()"
                               [noWrap]="true" bgColor="#FFFFFF" rightIcon="hashtag">
              <div class="flex f_align_c gap_5">
                {{metadata.PassportNumber}}
                <fa-icon *ngIf="canUpdateField()" icon="pen-to-square" size="xs"></fa-icon>
              </div>
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                      (click)="checkSelect($event, VisaFN.PASSPORT_NUMBER)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.DateOfIssue">
            <span class="ml_10">{{'form.passportNumber.dateOfIssue' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="calendar">
              {{metadata.DateOfIssue | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.DATE_OF_ISSUE)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.DateOfExpiry">
            <span class="ml_10">{{'form.passportNumber.dateOfExpiry' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="calendar">
              {{metadata.DateOfExpiry | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.DATE_OF_EXPIRY)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.PlaceofIssue">
            <span class="ml_10">{{'form.passportNumber.placeOfIssue' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="location-dot">
              {{metadata.PlaceofIssue}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PLACE_OF_ISSUE)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.PlaceOfBirth">
            <span class="ml_10">{{'form.placeOfBirth' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="city">
              {{metadata.PlaceOfBirth}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PLACE_OF_BIRTH_CITY)">
            </app-slide-element>
          </div>

            <div class="f_col gap_5 fullWidth" *ngIf="metadata?.Gender">
              <span class="ml_10">{{'form.gender' | translate}}</span>
              <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                                 rightIcon="venus-mars">
                {{('applicant.gender.' + metadata.Gender) | translate}}
                <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.GENDER)">
              </app-slide-element>
            </div>

          <div class="f_col gap_5" *ngIf="metadata?.MaritalStatus">
            <span class="ml_10">{{'form.marialStatus' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="people-arrows">
              {{('marital.status.' + metadata.MaritalStatus) | translate}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.MARITAL_STATUS)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.Occupation">
            <span class="ml_10">{{'form.profession' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="briefcase">
              {{metadata.Occupation}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.OCCUPATION)">
            </app-slide-element>
          </div>

          <div *ngIf="metadata?.idCard" class="f_col gap_5">
            <span class="ml_10">{{'idcard.number' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="id-card">
              {{metadata?.idCard}}
            </app-slide-element>
          </div>
        </div>

        <div class="category-container"
             *ngIf="metadata?.Email || metadata?.email || metadata?.Phone || metadata?.phone || metadata?.AddressZimbabwe || metadata?.HomeAddress || metadata?.HostName">
          <app-paragraph class="fw_bold">{{'contact.data' | translate}}</app-paragraph>
          <div class="f_col gap_5" *ngIf="metadata?.Email || metadata?.email">
            <span class="ml_10">{{'form.email' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="envelope">
              {{metadata.Email || metadata.email}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.EMAIL)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.Phone || metadata?.phone">
            <span class="ml_10">{{'form.phone' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="phone">
              {{metadata.Phone || metadata?.phone}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.PHONE)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.AddressZimbabwe">
            <span class="ml_10">{{'form.addressZimbabwe' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                               rightIcon="location-pin">
              {{metadata.AddressZimbabwe}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.ADDRESS_ZIMBABWE)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.HomeAddress">
            <span class="ml_10">{{'form.residenceAdress' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                               rightIcon="location-pin">
              {{metadata.HomeAddress}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.HOME_ADDRESS)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.HostName">
            <span class="ml_10">{{'form.hostName' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
              {{metadata.HostName}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.HOST_NAME)">
            </app-slide-element>
          </div>
        </div>

        <div class="category-container"
             *ngIf="metadata?.ArrivalDate || metadata?.DepartureDate || metadata?.ReferencePreviousVisa || metadata?.PreviousConvictionsZim || metadata?.PreviousConvictionsHome || metadata?.PurposeOfVisit || currentPayment?.createdAt || currentPayment?.externalId">
          <app-paragraph class="fw_bold">{{'divers.data.DYN.' + api.env.type | translate}}</app-paragraph>
          <div class="f_col gap_5" *ngIf="metadata?.ArrivalDate">
            <span class="ml_10">{{'form.arrivalDate' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="calendar">
              {{metadata.ArrivalDate | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.ARRIVAL_DATE)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.DepartureDate">
            <span class="ml_10">{{'form.departureDate' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="calendar">
              {{metadata.DepartureDate | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.DEPARTURE_DATE)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.ReferencePreviousVisa">
            <span class="ml_10">{{'form.referencePreviousVisa' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
              {{metadata.ReferencePreviousVisa}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.REFERENCE_PREVIOUS_VISA)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.PreviousConvictionsZim">
            <span class="ml_10">{{'form.previousConvictionsZim' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="handcuffs">
              {{(metadata.PreviousConvictionsZim) | translate}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PREVIOUS_CONVICTIONS_ZIM)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.PreviousConvictionsHome">
            <span class="ml_10">{{'form.previousConvictionsHome' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="handcuffs">
              {{(metadata.PreviousConvictionsHome) | translate}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PREVIOUS_CONVICTIONS_HOME)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.PurposeOfVisit">
            <span class="ml_10">{{'form.purposeOfVisit' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="question-circle">
              {{('visa.type.' + metadata.PurposeOfVisit) | translate}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.PURPOSE_OF_VISIT)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="currentPayment?.createdAt">
            <span class="ml_10">{{'date.creation' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                               rightIcon="calendar">{{currentPayment.createdAt | date}}
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="placeActual">
            <span class="ml_10">{{'form.created_place' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                               rightIcon="location-dot"
                               (btnClicked)="nav.to('place/' + placeActual.id)">{{placeActual?.longName}}
              [{{placeActual?.id}}]
            </app-slide-element>
          </div>
          <div *ngIf="placeConsumed" class="f_col gap_5">
            <span class="ml_10">{{'form.consumed_place' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                               rightIcon="globe"
                               (btnClicked)="nav.to('place/' + placeConsumed.id)">{{placeConsumed?.longName}}
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.worker">
            <span class="ml_10">{{'form.userCreator' | translate}}</span>
            <app-slide-element [noWrap]="true" [class.no-pointer]="!selectMode" bgColor="#FFFFFF" rightIcon="hashtag">
              {{sync.userDisplay(currentPayment?.used_by_user__id)}} #{{metadata?.worker}}
            </app-slide-element>
          </div>
          <div *ngIf="currentPayment?.refunded_by_user__id" class="f_col gap_5">
            <span class="ml_10">{{'form.userRefund' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" rightIcon="hashtag"
                               [class.no-pointer]="!api.hasPerm(UserPermission.ALLOW_ADMIN)"
                               (btnClicked)="api.hasPerm(UserPermission.ALLOW_ADMIN) && nav.to('user/' + currentPayment?.refunded_by_user__id)">
              {{sync.userDisplay(currentPayment?.used_by_user__id)}} #{{currentPayment?.refunded_by_user__id}}
            </app-slide-element>
          </div>
          <div *ngIf="currentPayment?.used_by_user__id" class="f_col gap_5">
            <span class="ml_10">{{'form.userConsumed' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" rightIcon="hashtag"
                               [class.no-pointer]="!api.hasPerm(UserPermission.ALLOW_ADMIN)"
                               (btnClicked)="api.hasPerm(UserPermission.ALLOW_ADMIN) && nav.to('user/' + currentPayment?.used_by_user__id)">
              {{sync.userDisplay(currentPayment?.used_by_user__id)}} #{{currentPayment?.used_by_user__id}}
            </app-slide-element>
          </div>

          <div *ngIf="metadata?.FundsAvailable && metadata?.FundsAvailableCurrency && api.userInfo.rates.rates" class="f_col gap_5">
            <span class="ml_10">{{'FundsAvailable' | translate}}</span>
            <div class="flex gap_5 fullWidth">
              <app-slide-element class="fullWidth" [noWrap]="true" [class.no-pointer]="!selectMode" bgColor="#FFFFFF" rightIcon="money-bill">
                {{metadata.FundsAvailable + ' ' + metadata.FundsAvailableCurrency + (metadata.FundsAvailableCurrency !== 'USD' && request?.convertCurrency?.(metadata?.FundsAvailableCurrency, metadata?.FundsAvailable, 'USD') ? (' ' + '(' + request.convertCurrency(metadata.FundsAvailableCurrency, metadata.FundsAvailable, 'USD').amount + ' ' + request.convertCurrency(metadata.FundsAvailableCurrency, metadata.FundsAvailable, 'USD').currency +')') : '')}}
              </app-slide-element>
            </div>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.CompanionsSize">
            <span class="ml_10">{{'CompanionsSize' | translate}}</span>
            <app-slide-element [noWrap]="true" [class.no-pointer]="!selectMode" bgColor="#FFFFFF" rightIcon="users">{{metadata?.CompanionsSize}}
            </app-slide-element>
          </div>
        </div>

        <div class="category-container" *ngIf="metadata?.NextOfKin && metadata?.NextOfKin !== '{}'">
          <app-paragraph class="fw_bold">{{'nextOfKin.data' | translate}}</app-paragraph>
          <div class="f_col gap_5">
            <div *ngIf="JSONparse(metadata.NextOfKin)?.firstname">
              <span class="ml_10">{{'form.firstName' | translate}}</span>
              <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" rightIcon="user">
                {{JSONparse(metadata.NextOfKin).firstname}}
              </app-slide-element>
            </div>

            <div *ngIf="JSONparse(metadata.NextOfKin)?.lastname">
              <span class="ml_10">{{'form.lastName' | translate}}</span>
              <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" rightIcon="user">
                {{JSONparse(metadata.NextOfKin).lastname}}
              </app-slide-element>
            </div>

            <div *ngIf="JSONparse(metadata.NextOfKin)?.phone">
              <span class="ml_10">{{'form.phone' | translate}}</span>
              <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" rightIcon="phone">
                {{JSONparse(metadata.NextOfKin).phone}}
              </app-slide-element>
            </div>

            <div *ngIf="JSONparse(metadata.NextOfKin)?.email">
              <span class="ml_10">{{'form.email' | translate}}</span>
              <app-slide-element [noPointerEvent]="true" [noWrap]="true" bgColor="#FFFFFF" rightIcon="at">
                {{JSONparse(metadata.NextOfKin).email}}
              </app-slide-element>
            </div>
          </div>
        </div>

        <div class="category-container" *ngIf="metadata?.Spouse === 'Yes'">
          <app-paragraph class="fw_bold">{{'spouse.data' | translate}}</app-paragraph>
          <div class="f_col gap_5" *ngIf="metadata?.SpouseFullName">
            <span class="ml_10">{{'form.spouseFullName' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
              {{metadata.SpouseFullName}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.SPOUSE_NAME)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.SpousePassportNumber">
            <span class="ml_10">{{'form.spousePassportNumber' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
              {{metadata.SpousePassportNumber}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.SPOUSE_PASSPORT_NUMBER)">

            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.BirthDay">
            <span class="ml_10">{{'form.spouse.birthDay' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="calendar">
              {{metadata.SpouseBirthDay | date}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.SPOUSE_DATE_OF_BIRTH)">
            </app-slide-element>
          </div>
          <div class="f_col gap_5" *ngIf="metadata?.SpousePlaceOfBirth">
            <span class="ml_10">{{'form.spousePlaceOfBirth' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode"
                               rightIcon="location-dot">
              {{metadata.SpousePlaceOfBirth}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox"
                     (click)="checkSelect($event, VisaFN.SPOUSE_PLACE_OF_BIRTH)">
            </app-slide-element>
          </div>
        </div>
        <div class="category-container" *ngIf="metadata?.Dependants === 'Yes'">
          <app-paragraph class="fw_bold">{{'dependant.data' | translate}}</app-paragraph>
          @for (dependant of metadata.DependantsDetails; track dependant.DependantPassportNumber; ) {
            <app-paragraph style="text-decoration: underline;" *ngIf="metadata.DependantsDetails.length > 1">{{
                ("dependantIndex" | translate : {index: $index + 1}) + ' :' }}
            </app-paragraph>
            <div class="f_col gap_5" *ngIf="dependant?.DependantFullName">
              <span class="ml_10">{{'form.dependantFullName' | translate}}</span>
              <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                {{dependant.DependantFullName}}
                <input class="docCheck" *ngIf="selectMode" type="checkbox"
                       (click)="checkSelect($event, VisaFN.DEPENDANTS)">
              </app-slide-element>
            </div>
            <div class="f_col gap_5" *ngIf="dependant?.DependantPassportNumber">
              <span class="ml_10">{{'form.dependantPassportNumber' | translate}}</span>
              <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
                {{dependant.DependantPassportNumber}}
                <input class="docCheck" *ngIf="selectMode" type="checkbox"
                       (click)="checkSelect($event, VisaFN.DEPENDANTS)">
              </app-slide-element>
            </div>
            <div class="f_col gap_5" *ngIf="dependant?.DependantBirthDay">
              <span class="ml_10">{{'form.dependantBirthDay' | translate}}</span>
              <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="birthday-cake">
                {{dependant.DependantBirthDay | date}}
                <input class="docCheck" *ngIf="selectMode" type="checkbox"
                       (click)="checkSelect($event, VisaFN.DEPENDANTS)">
              </app-slide-element>
            </div>
            <div class="f_col gap_5" *ngIf="dependant?.DependantPlaceOfBirth">
              <span class="ml_10">{{'form.dependantPlaceOfBirth' | translate}}</span>
              <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="location-dot">
                {{dependant.DependantPlaceOfBirth}}
                <input class="docCheck" *ngIf="selectMode" type="checkbox"
                       (click)="checkSelect($event, VisaFN.DEPENDANTS)">
              </app-slide-element>
            </div>
            @if(!$last) {
              <app-divider></app-divider>
            }
          }
        </div>
      </div>
      <div class="category-container"
             *ngIf="metadata?.AttestantAddress || metadata?.AttestantEmail || metadata?.AttestantID || metadata?.AttestantName || metadata?.AttestantPhone || metadata?.AttestantRelation || metadata?.DaysRequested">
          <app-paragraph class="fw_bold">{{'extension.data' | translate}}</app-paragraph>
          <div class="f_col gap_5" *ngIf="metadata?.AttestantAddress">
            <span class="ml_10">{{'attestantAddress' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="location-dot">
              {{metadata.AttestantAddress}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.ATTESTANT_EMAIL)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.ReasonForExtension">
            <span class="ml_10">{{'reasonForExtention' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="folder">
              {{metadata.ReasonForExtension}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.REASON_FOR_EXTENSION)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.AttestantEmail">
            <span class="ml_10">{{'attestantEmail' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="envelope">
              {{metadata.AttestantEmail}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.ATTESTANT_EMAIL)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.AttestantID">
            <span class="ml_10">{{'attestantID' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
              {{metadata.AttestantID}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.ATTESTANT_ID)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.AttestantName">
            <span class="ml_10">{{'attestantName' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
              {{metadata.AttestantName}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.ATTESTANT_NAME)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.AttestantPhone">
            <span class="ml_10">{{'attestantPhone' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="phone">
              {{metadata.AttestantPhone}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.ATTESTANT_PHONE)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.AttestantRelation">
            <span class="ml_10">{{'attestantRelation' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="link">
              {{metadata.AttestantRelation}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.ATTESTANT_RELATION)">
            </app-slide-element>
          </div>

          <div class="f_col gap_5" *ngIf="metadata?.DaysRequested">
            <span class="ml_10">{{'daysRequested' | translate}}</span>
            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
              {{metadata.DaysRequested}}
              <input class="docCheck" *ngIf="selectMode" type="checkbox" (click)="checkSelect($event, VisaFN.DAYS_REQUESTED)">
            </app-slide-element>
          </div>
        </div>
    </div>
  </div>
  <div class="btns-container resp_maxSize_l">
    <app-back-btn icon="house" (btnClicked)="nav.to('dashboard')"></app-back-btn>
  </div>
</div>

<div *ngIf="printDocument.img && printDocument.title" class="print-container">
  <h1 class="print-title">{{printDocument.title}}</h1>
  <img [src]="printDocument.img" class="print-image" alt="Document">
  <table class="print-details-table">
    <thead>
      <tr>
        <th>{{'print.date' | translate}}</th>
        <th>{{'print.id' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{printDocument.date}}</td>
        <td>{{printDocument.id}}</td>
      </tr>
    </tbody>
  </table>
</div>

<app-print-data *ngIf="!printDocument.img && !printDocument.title && printCurrent === 'application'"  [title]="((currentPayment.operationId === DocumentType.ZWEVISAEXTAB || currentPayment.operationId === DocumentType.ZWEVISAEXTC) ? 'titlePrint.extension' :'titlePrint') | translate : {paymentID: currentPayment.id}" [dataList]="printVisaApplication"></app-print-data>
<app-print *ngIf="qr.currentQrData && printCurrent === 'approvalLetter'"></app-print>
<app-print-receipt-a5 *ngIf="currentPrintReceiptDatas && printCurrent === 'receipt'" [receiptData]="currentPrintReceiptDatas"></app-print-receipt-a5>
<app-print-sticker *ngIf="stickerData && printCurrent === 'sticker'" [qrCode]="qr.qrCode" [data]="stickerData"></app-print-sticker>
<app-print-notice-border-pass *ngIf="printBorderPassData && printCurrent === 'borderPass'" [printBorderPassData]="printBorderPassData"></app-print-notice-border-pass>
<app-print-notice-restriction [form]="formNotice" [qrCode]="qr.qrCode" *ngIf="printCurrent === 'notice-RESTRICTION' && formNotice" [documents]="docFormNotice"></app-print-notice-restriction>
<app-print-notice-reporting [form]="formNotice" [qrCode]="qr.qrCode" *ngIf="printCurrent === 'notice-REPORTING' && formNotice"></app-print-notice-reporting>
<app-print-notice-refusal [form]="formNotice" [qrCode]="qr.qrCode" *ngIf="printCurrent === 'notice-REFUSAL' && formNotice"></app-print-notice-refusal>

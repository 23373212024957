export function compressFile(file: File, maxSizeInMB: number): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      if((file.size / 1024 / 1024) <= maxSizeInMB) {
        resolve(reader.result);
        return;
      }
      const img = new Image();
      img.src = reader.result as string;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let newImageData;
        let newSize;
        let quality = 0.9;
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        while(true) {
          newImageData = canvas.toDataURL('image/webp', quality);
          newSize = newImageData.length * 3 / 4 / 1024 / 1024;
          if(newSize <= maxSizeInMB || quality <= 0.1) {
            break;
          }
          quality -= 0.2;
        }
        if(newSize > maxSizeInMB) {
          const maxResolution = 400;
          let width = img.width;
          let height = img.height;
          if(width > maxResolution || height > maxResolution) {
            if(width > height) {
              height *= maxResolution / width;
              width = maxResolution;
            } else {
              width *= maxResolution / height;
              height = maxResolution;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          newImageData = canvas.toDataURL('image/webp', quality);
          newSize = newImageData.length * 3 / 4 / 1024 / 1024;
        }
        if(newSize > maxSizeInMB) {
          reject('Unable to compress the image sufficiently.');
          return;
        }
        resolve(newImageData);
      };
    };
    reader.readAsDataURL(file);
  });
}

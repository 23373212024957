import {Component, ViewEncapsulation} from '@angular/core';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {DocumentType} from '../../shared/models/user';

declare const window: Window & {initialQueryString: string[][]};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {

  constructor(public nav: NavigateService, public api: ApiService, private loader: LoaderService, private lang: LangService) {
    const firstHolder = window.initialQueryString.find((q: string[]) => q[0] === 'first');
    if(firstHolder && firstHolder[1] === 'true') {
      this.loader.loading(true, {type: 'valid', message: this.lang.transform('home.first')});
    }
  }

  protected readonly DocumentType = DocumentType;
}

<mat-dialog-content id="dialogRejectValidate" class="dialog_content text_center" *ngIf="data">
  <div class="dialog-header" [style.background-color]="color">
    <fa-icon icon="circle-check" *ngIf="data?.type === 'valid'"></fa-icon>
    <fa-icon icon="triangle-exclamation" *ngIf="data?.type === 'warn'"></fa-icon>
  </div>
  <span class="dialog-message">{{(data?.message ? data?.message : '')  | translate}}</span>
  <form class="mt_30" id="form" [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="data?.refuse">
    <app-input type="text" formCtrlName="reason" [formGrp]="form" label="form.reason"></app-input>
    <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
  </form>
  <div class="mt_40" [class.f_between]="!!data?.btnLabel" [class.f_center]="!data.btnLabel" [class.pl_30]="!!data?.btnLabel" [class.pr_30]="!!data?.btnLabel">
    <app-button class="dialog-close" color="red" size="sm" (btnClicked)="closeDialog()">{{'global.close' | translate}}</app-button>
    <div class="ml_10">
      <app-button  *ngIf="data?.btnLabel" size="sm" (btnClicked)="data?.refuse ? submitForm() : closeDialog(true)" [style.background-color]="color">{{data?.btnLabel | translate}}</app-button>
    </div>
  </div>
</mat-dialog-content>

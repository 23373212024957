<div id="layout" class="f_col shortcuts">
  <app-view-title fromRoute="my-account" [chooseLangBtn]="false">{{'dashboard.keyboardShortcuts' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <app-paragraph class="mb_10" textAlign="center">{{'shortcuts.desc' | translate}}</app-paragraph>
      <div class="f_center mb_20 gap_10">
        <app-button [border]="true" color="transparent" size="xsm" (btnClicked)="nav.to('my-account')" formId="form">{{'global.back' | translate}}</app-button>
        <app-button color="red" size="xsm" (btnClicked)="resetDefault()" formId="form">{{'global.resetDefaults' | translate}}</app-button>
      </div>
      <form *ngIf="form" id="form" [formGroup]="form">
        <div *ngFor="let s of shortcuts.shortcuts" class="flex f_between f_align_c mb_5 shortcut_element">
          <span class="ml_20">{{s.action}}</span>
          <app-input type="text" formCtrlName="{{s.action}}" [formGrp]="form" (click)="openShortcut(s)"></app-input>
        </div>
      </form>
    </div>
  </div>
  <div class="mb_20 resp_maxSize_l flex gap_10">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
  </div>
</div>

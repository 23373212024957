import {NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  faAddressBook,
  faArrowLeft,
  faArrowRightArrowLeft,
  faBan,
  faBomb,
  faBox,
  faCalendar,
  faCameraRotate,
  faCashRegister,
  faChartSimple,
  faCircle,
  faCircleCheck,
  faCircleDown,
  faClockRotateLeft,
  faClone,
  faCopy,
  faCreditCard,
  faDatabase,
  faDownload,
  faExclamation,
  faFilter,
  faFolder,
  faGear,
  faGlobe,
  faHashtag,
  faHourglassHalf,
  faHouse,
  faIdCard,
  faLink,
  faLinkSlash,
  faList,
  faLocationDot,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMoneyBill,
  faPassport,
  faPenToSquare,
  faPeopleRoof,
  faPerson,
  faPhone,
  faPlus,
  faPrint,
  faQrcode,
  faRotateRight,
  faShoppingCart,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faUser,
  faWallet,
  faWifi,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import {NgxScannerQrcodeModule, LOAD_WASM} from 'ngx-scanner-qrcode';
import {QRCodeModule} from 'angularx-qrcode';
import {
  DevicesService,
  DialogsService,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowRotateLeft,
  faArrowsLeftRight, faArrowsLeftRightToLine, faArrowsTurnToDots,
  faBackwardStep,
  faBars,
  faBookOpenReader,
  faBriefcase,
  faBuildingColumns,
  faCakeCandles,
  faCalendarDay,
  faCalendarPlus,
  faCamera,
  faChevronDown,
  faCircleXmark,
  faCity,
  faClock, faCloudArrowDown, faCloudArrowUp,
  faCode,
  faDollarSign,
  faDoorOpen,
  faEnvelopeOpenText,
  faEthernet,
  faFile,
  faFileCircleQuestion, faFileImage,
  faFileInvoice,
  faFileLines,
  faFilePdf,
  faFingerprint,
  faHandcuffs, faHeart, faHourglassStart,
  faImagePortrait,
  faKey, faKeyboard,
  faLocationPin, faMagnifyingGlassMinus,
  faMoneyBillWave,
  faMoneyCheckDollar,
  faNetworkWired,
  faPen,
  faPeopleArrows,
  faPersonMilitaryPointing,
  faPiggyBank,
  faPlay, faReceipt,
  faRightFromBracket, faRoadBarrier,
  faRocket, faShieldDog,
  faStopwatch,
  faStopwatch20,
  faTableList,
  faTag,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUserLargeSlash,
  faUsers,
  faVenusMars,
  faX,
  FontAwesomeModule,
  FormService,
  LangService,
  LoaderService,
  NavigateService,
  NgxSatorisModule, ShortcutService,
  TranslatePipe
} from 'ngx-satoris';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DialogBluetoothComponent} from './components/dialogs/dialog-bluetooth/dialog-bluetooth.component';
import {DialogDailyOtpComponent} from './components/dialogs/dialog-daily-otp/dialog-daily-otp.component';
import {NavComponent} from './components/nav/nav.component';
import {PrintComponent} from './components/print/print.component';
import {en_strings} from './shared/i18n/en';
import {fr_strings} from './shared/i18n/fr';
import {de_strings} from './shared/i18n/de';
import {cn_strings} from './shared/i18n/cn';
import {ru_strings} from './shared/i18n/ru';
import {en_overrides_strings} from './shared/i18n/en_overrides';
import {fr_overrides_strings} from './shared/i18n/fr_overrides';
import {de_overrides_strings} from './shared/i18n/de_overrides';
import {cn_overrides_strings} from './shared/i18n/cn_overrides';
import {ru_overrides_strings} from './shared/i18n/ru_overrides';
import {SharedModule} from './shared/shared.module';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {ForgotPasswordComponent} from './views/forgot-password/forgot-password.component';
import {ModifiedPasswordComponent} from './views/forgot-password/modified-password/modified-password.component';
import {SendPasswordComponent} from './views/forgot-password/send-password/send-password.component';
import {HomeComponent} from './views/home/home.component';
import {PeopleComponent} from './views/people/people.component';
import {PlacesComponent} from './views/places/places.component';
import {SignInComponent} from './views/sign-in/sign-in.component';
import {EmailSentComponent} from './views/sign-up/email-sent/email-sent.component';
import {EmailValidComponent} from './views/sign-up/email-valid/email-valid.component';
import {SignUpComponent} from './views/sign-up/sign-up.component';
import {UsersComponent} from './views/users/users.component';
import {WelcomeHelloComponent} from './views/welcome/welcome-hello/welcome-hello.component';
import {WelcomeExplainComponent} from './views/welcome/welcome-explain/welcome-explain.component';
import {WelcomeEmployeeComponent} from './views/welcome/welcome-employee/welcome-employee.component';
import {WelcomeOfflineComponent} from './views/welcome/welcome-offline/welcome-offline.component';
import {WelcomeThanksComponent} from './views/welcome/welcome-thanks/welcome-thanks.component';
import {SuccessPaymentComponent} from './views/payment/success-payment/success-payment.component';
import {TrackingControlComponent} from './views/tracking-control/tracking-control.component';
import {PeopleDataComponent} from './views/people/people-data/people-data.component';
import {
  PeopleDataModificationComponent
} from './views/people/people-data-modification/people-data-modification.component';
import {PeopleFoundComponent} from './views/people/people-found/people-found.component';
import {MyAccountComponent} from './views/account/my-account/my-account.component';
import {ChangePasswordComponent} from './views/account/change-password/change-password.component';
import {PaymentHistoryComponent} from './views/payment/payment-history/payment-history.component';
import {ReceiptComponent} from './views/payment/receipt/receipt.component';
import {RequestAccountComponent} from './views/tracking-control/request-account/request-account.component';
import {ChooseLangComponent} from './views/choose-lang/choose-lang.component';
import {UserComponent} from './views/users/user/user.component';
import {PlacesChoiceComponent} from './views/places/places-choice/places-choice.component';
import {PlacePermissionsComponent} from './views/places/place-permissions/place-permissions.component';
import {PlaceAddComponent} from './views/places/place-add/place-add.component';
import {PlaceCreateComponent} from './views/places/place-create/place-create.component';
import {PlaceSelectComponent} from './views/places/place-select/place-select.component';
import {PlaceCreatedComponent} from './views/places/place-create/place-created/place-created.component';
import {UserCreateComponent} from './views/users/user-create/user-create.component';
import {UserCreatedComponent} from './views/users/user/user-created/user-created.component';
import {ScanQRComponent} from './views/qr/scan-qr/scan-qr.component';
import {PageQrComponent} from './views/qr/page-qr/page-qr.component';
import {PagePermissionComponent} from './views/page-permission/page-permission.component';
import {
  DialogRejectValidatePaymentComponent
} from './components/dialogs/dialog-reject-validate-payment/dialog-reject-validate-payment.component';
import {WebcamModule} from 'ngx-webcam';
import {AdminRequestComponent} from './views/admin-request/admin-request.component';
import {AdminRequestCreatedComponent} from './views/admin-request/admin-request-created/admin-request-created.component';
import {AdminRequestSelectComponent} from './views/admin-request/admin-request-select/admin-request-select.component';
import {AdminRequestQrListComponent} from './views/admin-request/admin-request-qr-list/admin-request-qr-list.component';
import {WebcamComponent} from './components/webcam/webcam.component';
import {AdminRequestFaceCheckComponent} from './views/admin-request/admin-request-face-check/admin-request-face-check.component';
import {AdminRequestCreateComponent} from './views/admin-request/admin-request-create/admin-request-create.component';
import {FormComponent} from './components/form/form.component';
import {TripComponent} from './views/trip/trip.component';
import {FormVisaComponent} from './views/form-visa/6.form-visa/form-visa.component';
import {RedirectTravelNameComponent, TravelNameComponent} from './views/form-visa/1.travel-name/travel-name.component';
import {ParticipantsComponent} from './views/form-visa/2.participants/participants.component';
import {PassportTypeComponent} from './views/form-visa/3.passport-type/passport-type.component';
import {VisaTypeComponent} from './views/form-visa/4.visa-type/visa-type.component';
import {UrgentComponent} from './views/form-visa/5.urgent/urgent.component';
import {DialogParticipantsComponent} from './components/dialogs/dialog-participants/dialog-participants.component';
import {PrintDataComponent} from './components/print-data/print-data.component';
import {SummaryComponent} from './views/form-visa/7.summary/summary.component';
import {DialogNewPersonComponent} from './components/dialogs/dialog-new-person/dialog-new-person.component';
import {ScannedListComponent} from './views/scanned-list/scanned-list.component';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule} from '@angular/material/tooltip';
import {environment} from '../environments/environment';
import {AdminScanFingerprintComponent} from './views/admin-request/admin-scan-fingerprint/admin-scan-fingerprint.component';
import {PrintReceiptComponent} from './components/print-receipt/print-receipt.component';
import {DialogPdfViewerComponent} from './components/dialogs/dialog-pdf-viewer/dialog-pdf-viewer.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {PrintNoticeBorderPassComponent} from './components/print-notice-border-pass/print-notice-border-pass.component';
import {FeedbackFormComponent} from './components/feedback-form/feedback-form.component';
import {ShortcutsComponent} from './views/account/shortcuts/shortcuts.component';
import {DialogDocViewerComponent} from './components/dialogs/dialog-doc-viewer/dialog-doc-viewer.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ProgressInterceptor} from './shared/interceptors/progress.interceptor';
import {DocumentType} from './shared/models/user';
import {PassModule} from './pass.module';
import {ZwevisaModule} from './zwevisa.module';

LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    UsersComponent,
    PlacesComponent,
    UsersComponent,
    PrintComponent,
    DialogDailyOtpComponent,
    DialogBluetoothComponent,
    HomeComponent,
    EmailSentComponent,
    EmailValidComponent,
    PrintReceiptComponent,
    PrintNoticeBorderPassComponent,
    WelcomeHelloComponent,
    WelcomeExplainComponent,
    WelcomeEmployeeComponent,
    WelcomeThanksComponent,
    WelcomeOfflineComponent,
    PeopleComponent,
    SendPasswordComponent,
    ModifiedPasswordComponent,
    UserComponent,
    SuccessPaymentComponent,
    TrackingControlComponent,
    PeopleDataComponent,
    PeopleDataModificationComponent,
    PeopleFoundComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    PaymentHistoryComponent,
    AdminRequestCreateComponent,
    ReceiptComponent,
    RequestAccountComponent,
    ChooseLangComponent,
    PlacesChoiceComponent,
    PlacePermissionsComponent,
    PlaceAddComponent,
    PlaceCreateComponent,
    PlaceSelectComponent,
    PlaceCreatedComponent,
    AdminRequestComponent,
    UserCreateComponent,
    ScannedListComponent,
    PrintDataComponent,
    UserCreatedComponent,
    AdminRequestCreatedComponent,
    AdminRequestCreatedComponent,
    AdminRequestSelectComponent,
    ScanQRComponent,
    PageQrComponent,
    PagePermissionComponent,
    DialogRejectValidatePaymentComponent,
    AdminRequestQrListComponent,
    WebcamComponent,
    NavComponent,
    AdminRequestFaceCheckComponent,
    FormVisaComponent,
    TripComponent,
    TravelNameComponent,
    RedirectTravelNameComponent,
    ParticipantsComponent,
    PassportTypeComponent,
    VisaTypeComponent,
    UrgentComponent,
    DialogParticipantsComponent,
    SummaryComponent,
    DialogNewPersonComponent,
    AdminScanFingerprintComponent,
    DialogPdfViewerComponent,
    FeedbackFormComponent,
    ShortcutsComponent,
    DialogDocViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSortModule,
    MatTableModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatIconModule,
    QRCodeModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxScannerQrcodeModule,
    FormsModule,
    NgxSatorisModule,
    FontAwesomeModule,
    NgOptimizedImage,
    WebcamModule,
    FormComponent,
    MatTooltipModule,
    NgxExtendedPdfViewerModule,
    ...(environment.type === DocumentType.ZWEVISA ? [ZwevisaModule] : 
      environment.type === DocumentType.PASS ? [PassModule] : [])
  ],
  providers: [
    MatDatepickerModule,
    TranslatePipe,
    LangService,
    DialogsService,
    FormService,
    LoaderService,
    NavigateService,
    DevicesService,
    ShortcutService,
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {showDelay: 1000}},
    {provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true}
  ],
  exports: [MatIconModule, MatButtonModule, PageQrComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(lang: LangService,
    private ngxSatoris: NgxSatorisModule,
    private deviceService: DevicesService,
    private loader: LoaderService,
    private shortcuts: ShortcutService) {
    this.shortcuts.dialogLoadingDefaultShow = true;
    this.loader.customLoadingElement = `
      <div class="element_loader">
        <img class="spinner" width="120px" height="120px" src="assets-projects/logo-square.png" alt="loader" style="border-radius: 100%">
      </div>`;
    this.deviceService.checkDevices();
    lang.setTranslations({
      en: {...en_strings, ...en_overrides_strings},
      fr: {...fr_strings, ...fr_overrides_strings},
      de: {...de_strings, ...de_overrides_strings},
      cn: {...cn_strings, ...cn_overrides_strings},
      ru: {...ru_strings, ...ru_overrides_strings}
    });

    this.ngxSatoris.addIcons([
      faPassport,
      faArrowRightArrowLeft,
      faPeopleRoof,
      faGear,
      faHouse,
      faXmark,
      faUser,
      faAddressBook,
      faCreditCard,
      faQrcode,
      faArrowLeft,
      faShoppingCart,
      faTimes,
      faHourglassHalf,
      faExclamation,
      faRotateRight,
      faHashtag,
      faCameraRotate,
      faClockRotateLeft,
      faBomb,
      faMagnifyingGlass,
      faBan,
      faLocationDot,
      faCashRegister,
      faPlus,
      faGlobe,
      faCircleCheck,
      faCircle,
      faLink,
      faLinkSlash,
      faMagnifyingGlassPlus,
      faCopy,
      faMoneyBill,
      faWallet,
      faFolder,
      faPhone,
      faIdCard,
      faCalendar,
      faDatabase,
      faPenToSquare,
      faWifi,
      faDownload,
      faBox,
      faChartSimple,
      faThumbsDown,
      faThumbsUp,
      faChartSimple,
      faPrint,
      faList,
      faFilter,
      faClone,
      faPerson,
      faCamera,
      faCircleXmark,
      faBuildingColumns,
      faCreditCard,
      faX,
      faClone,
      faPerson,
      faVenusMars,
      faCakeCandles,
      faBuildingColumns,
      faMoneyCheckDollar,
      faFileInvoice,
      faBriefcase,
      faFingerprint,
      faEnvelopeOpenText,
      faFileLines,
      faCity,
      faLocationPin,
      faHandcuffs,
      faTriangleExclamation,
      faPerson,
      faFileLines,
      faUpload,
      faFileCircleQuestion,
      faVenusMars,
      faPeopleArrows,
      faCakeCandles,
      faBriefcase,
      faHandcuffs,
      faTrash,
      faEthernet,
      faPiggyBank,
      faDollarSign,
      faPlay,
      faClock,
      faPen,
      faRightFromBracket,
      faMoneyBillWave,
      faImagePortrait,
      faDoorOpen,
      faBars,
      faFileCircleQuestion,
      faDoorOpen,
      faUserLargeSlash,
      faUsers,
      faArrowRightToBracket,
      faArrowRightFromBracket,
      faFile,
      faArrowsLeftRight,
      faCashRegister,
      faTag,
      faCreditCard,
      faBan,
      faCalendarDay,
      faStopwatch,
      faPersonMilitaryPointing,
      faBackwardStep,
      faStopwatch20,
      faArrowRotateLeft,
      faCalendarPlus,
      faCode,
      faNetworkWired,
      faTableList,
      faKey,
      faRocket,
      faFilePdf,
      faHeart,
      faBookOpenReader,
      faCircleDown,
      faRoadBarrier,
      faHourglassStart,
      faShieldDog,
      faCamera,
      faKeyboard,
      faArrowsTurnToDots,
      faChevronDown,
      faArrowsLeftRightToLine,
      faReceipt,
      faChevronDown,
      faCloudArrowDown,
      faCloudArrowUp,
      faFileImage,
      faMagnifyingGlassPlus,
      faMagnifyingGlassMinus
    ]);
  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  FormService,
  LoaderService,
  NavigateService,
  LangService
} from 'ngx-satoris';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../shared/services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {

  constructor(public nav: NavigateService,
    public forms: FormService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    public api: ApiService,
    private lang: LangService) {}
  form: FormGroup;
  submitted: boolean;
  pswdStrength: number;

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      old: ['',
        Validators.required
      ],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20)
      ]],
      password2: ['',
        Validators.required
      ]
    }, {
      validator: this.forms.mustMatch('password', 'password2')
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.api.consumeLoginWeak(this.api.userInfo.accountName, this.form.get('old').value, this.api.userPlaceId).then(() => {
        this.api.setPassword(this.form.get('password').value, undefined).then(() => {
          this.submitted = false;
          this.form.reset();
          this.pswdStrength = 0;
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('modifiedPassword.description')}).then(() => {
            this.api.signOut();
          });
        }).catch((err) => {
          this.loader.loading(true, {type: 'error', message: err});
          this.submitted = false;
        });
      }).catch(() => {
        this.submitted = false;
        this.loader.loading(true, {type: 'error', message: this.lang.transform('wrong.current')});
      });
    }
  }

  checkPswdStrength($event: any) {
    this.pswdStrength = this.forms.checkPswdStrength($event);
  }
}

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-people-found',
  templateUrl: './people-found.component.html',
  styleUrls: ['./people-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PeopleFoundComponent  {

  userId = this.route.snapshot.paramMap.get('userId');

  constructor(public nav: NavigateService,
              private route: ActivatedRoute,
              public api: ApiService) { }

  mockRedirect() {
    this.nav.to('people'+ '/' + this.userId);
  }
}

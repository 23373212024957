<div class="document-viewer-container">
  <div class="flex f_col gap_10 doc_contain fullWidth">
    <div class="flex gap_10 f_align_c f_grow f_col f_center fullWidth">
      @if(data.documentsInput[currentDocumentIndex].title){
        <h1 class="document-viewer-title">{{data.documentsInput[currentDocumentIndex].title | translate}}</h1>
      }
      <div class="flex gap_10 f_align_c fullWidth f_between px_40 f_center">
        <div class="btn_nav_visualizer resp_minSize_l">
          @if(currentDocumentIndex !== 0){
            <app-button size="sm" [shortcut]="ShortcutAction.ARROW_LEFT" [shortcutDialog]="true" (click)="previousDocument()" class="nav-btn flex f_align_c">
              <fa-icon icon="arrow-left" class="mr_5"></fa-icon>{{'global.previous' | translate}}
            </app-button>
          }
        </div>

        @if(!isPDF(data.documentsInput[currentDocumentIndex].document)){
          <div class="document-display-container">
            <div class="image-container">
              <img [src]="data.documentsInput[currentDocumentIndex].document" [class.defaultDocImage]="zoomValue === 100" [style.min-width]="zoomValue+'%'"/>
            </div>
          </div>
        } @else {
          <mat-dialog-content class="dialog_content">
            <ngx-extended-pdf-viewer height="70vh" rotation="0.0" [src]="data.documentsInput[currentDocumentIndex].document"></ngx-extended-pdf-viewer>
          </mat-dialog-content>
        }

        <div class="btn_nav_visualizer resp_minSize_l">
          @if(currentDocumentIndex !== data.documentsInput.length - 1){
            <app-button size="sm" [shortcut]="ShortcutAction.ARROW_RIGHT" [shortcutDialog]="true" (click)="nextDocument()" [disabled]="currentDocumentIndex === data.documentsInput.length - 1" class="nav-btn flex f_align_c f_end">
              {{'global.next' | translate}} <fa-icon icon="arrow-right" class="ml_5"></fa-icon>
            </app-button>
          }
        </div>
      </div>
    </div>
    <div class="resp_maxSize_l flex gap_10 f_center">
      @if(currentDocumentIndex !== 0){
        <app-button size="sm" (click)="previousDocument()" class="nav-btn flex f_align_c">
          <fa-icon icon="arrow-left" class="mr_5"></fa-icon>{{'global.previous' | translate}}
        </app-button>
      }
      @if(currentDocumentIndex !== data.documentsInput.length - 1){
        <app-button size="sm" (click)="nextDocument()" [disabled]="currentDocumentIndex === data.documentsInput.length - 1" class="nav-btn flex f_align_c f_end">
          {{'global.next' | translate}} <fa-icon icon="arrow-right" class="ml_5"></fa-icon>
        </app-button>
      }
    </div>
    <div class="flex f_center gap_5">
      <app-button size="sm" [shortcut]="ShortcutAction.ESCAPE" [shortcutDialog]="true" (click)="closeDialog()" class="nav-btn dialog_close">{{'global.close' | translate}}</app-button>
      <app-button *ngIf="!isPDF(data.documentsInput[currentDocumentIndex].document)" (click)="download(data.documentsInput[currentDocumentIndex].document)" size="sm" icon="download">{{'global.download' | translate}}</app-button>
      <app-button *ngIf="!isPDF(data.documentsInput[currentDocumentIndex].document)" [debounceTimeInMilliseconds]="0" size="sm" color="secondary" [disabled]="zoomValue <= 100" (click)="zoom(false)"><fa-icon icon="magnifying-glass-minus"></fa-icon></app-button>
      <app-button *ngIf="!isPDF(data.documentsInput[currentDocumentIndex].document)" [debounceTimeInMilliseconds]="0" size="sm" color="secondary" (click)="zoom(true)"><fa-icon icon="magnifying-glass-plus"></fa-icon></app-button>
    </div>
  </div>
</div>

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-email-valid',
  templateUrl: './email-valid.component.html',
  styleUrls: ['./email-valid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailValidComponent {
  env = environment;

  constructor(public nav: NavigateService) {}

}

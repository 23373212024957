<div id="layout" class="f_col">
    <app-view-title fromRoute="sign-in" [chooseLangBtn]="true">{{'welcome.title_1.DYN.' + api.env.type | translate}}</app-view-title>
      <div class="scroll_wrapper">
        <div class="parent-container">
          <app-view-deco-image class="mt_20 mb_20" imageUrl="assets-projects/images/image_joyride.svg"></app-view-deco-image>
          <div class="welcome-text">
            <p>{{'welcome.text_1.DYN.'+api.env.type | translate : {appName: api.env.name} }}</p>
            <p>{{'welcome.text_1.1.DYN.'+api.env.type | translate}}</p>
          </div>
        </div>
        <div class="btns-container">
          <div class="fullWidth flex f_center">
            <div class="menuButton-container flex f_center">
              <div class="ml_10 fullWidth">
                <app-button formId="form" class=" resp_maxSize_l fullWidth" (btnClicked)="nav.to('welcome-explain')">{{'global.next' | translate}}</app-button>
                <app-button size="xsm" formId="form" class="resp_minSize_l fullWidth" (btnClicked)="nav.to('welcome-explain')">{{'global.next' | translate}}</app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>

import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SyncService} from '../../shared/services/sync.service';

@Component({
  selector: 'app-print-notice-reporting',
  templateUrl: './print-notice-reporting.component.html',
  styleUrl: './print-notice-reporting.component.scss'
})
export class PrintNoticeReportingComponent {
  @Input() form: FormGroup;
  @Input() qrCode: string;

  constructor(public sync: SyncService) {}
}

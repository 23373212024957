import {Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService, LoaderService, NavigateService, Shortcut, ShortcutAction, ShortcutService} from 'ngx-satoris';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrl: './shortcuts.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ShortcutsComponent {

  form: FormGroup;

  constructor(public shortcuts: ShortcutService,
              public forms: FormService,
              public nav: NavigateService,
              private loader: LoaderService,
              private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.setForm();
  }

  openShortcut(s: Shortcut) {
    let shortcutDialog = true;
    this.loader.loading(true, {type: 'info', message: 'press.key', shortcut: {show: false}, custom: {icon: 'keyboard'}}).then(() => {
      shortcutDialog = false;
    });

    document.addEventListener('keydown', (event) => {
      if(!shortcutDialog) return;
      let key = event.key;
      if(key === ' ') {
        key = 'Space';
      }
      this.shortcuts.setShortcut(s.action, key);
      this.form.controls[s.action].patchValue(key);
      this.loader.loading(false);
    }, {once: true});
  }

  setForm() {
    this.form = this.formBuilder.group({});
    this.shortcuts.shortcuts.forEach((s: Shortcut) => {
      this.form.addControl(s.action, this.formBuilder.control({value: s.key, disabled: true}, [Validators.required]));
    });
  }

  updateShortcut(action: ShortcutAction, key: string) {
    this.shortcuts.setShortcut(action, key);
  }

  resetDefault() {
    this.shortcuts.resetDefault();
    this.setForm();
  }
}

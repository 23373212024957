import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigateService, LoaderService, LangService, FormService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';

declare const window: Window & {initialQueryString: string[][]};

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  formRecover: FormGroup;
  submitted: boolean;
  secondStep: boolean;
  email: string;
  recoverMode: boolean;
  pswdStrength: number;
  internal: boolean;

  constructor(public nav: NavigateService,
    public forms: FormService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private loader: LoaderService, private lang: LangService) {
    const recoverHolder = window.initialQueryString.find((q: string[]) => q[0] === 'recover');
    if(recoverHolder) {
      this.email = decodeURIComponent(recoverHolder[1]);
      this.recoverMode = true;
    }
    const internalHolder = window.initialQueryString.find((q: string[]) => q[0] === 'internal');
    if(this.api.isElectronApp || internalHolder) {
      this.internal = true;
    }
  }

  ngOnInit(): void {
    this.setForm();
    this.setFormRecover();
  }

  setForm() {
    this.form = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]]
    });
  }

  setFormRecover() {
    this.formRecover = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20)
      ]],
      password2: ['',
        Validators.required
      ],
      code: ['',
        Validators.required
      ]
    }, {
      validator: this.forms.mustMatch('password', 'password2')
    });
  }

  sendCodeEmail() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      if(this.internal) {
        this.loader.loading(true, {type: 'warn', message: 'change.password.ad'});
        this.form.reset();
        this.submitted = false;
        return;
      }
      this.loader.loading(true);
      this.api.reset(this.form.get('email').value).then(() => {
        this.form.reset();
        this.submitted = false;
        this.secondStep = true;
        this.loader.loading(false);
        this.nav.to('send-password');
      }).catch((err) => {
        this.submitted = false;
        if(err.includes(this.lang.transform('client.extended.missingResource'))) {
          this.loader.loading(true, {type: 'error', message: 'email.notexist'});
        } else {
          this.loader.loading(true, {type: 'error', message: err});
        }
      });
    }
  }

  changePassword() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.formRecover).length < 1) {
      this.loader.loading(true);
      this.api.resetPassword(this.email,
        this.formRecover.get('code').value,
        this.formRecover.get('password').value)
        .then(() => {
          this.secondStep = true;
          this.loader.loading(false);
          this.nav.to('modified-password');
        }).catch((err => {
          this.submitted = false;
          this.loader.loading(true, {type: 'error', message: 'submit recover error : ' + err});
        }));
    }
  }

  checkPswdStrength($event: any) {
    this.pswdStrength = this.forms.checkPswdStrength($event);
  }
}

import {Component, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {DevicesService, LangService, LoaderService, NavigateService, ShortcutAction} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {DocumentType, UserPermission, UserPlatformRole} from '../../shared/models/user';
import {Place} from '../../shared/models/place';
import {CartService} from '../../shared/services/cart.service';
import {environment} from 'src/environments/environment';
import {RequestService} from '../../shared/services/request.service';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';
import {InactivityService} from '../../shared/services/inactivity.service';
import {ConsumeMode} from 'src/app/shared/models/request';
import {FormGroup, FormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {useReturnUrl} from 'src/app/shared/services/application.service';
import {Router} from '@angular/router';
import {isJsonParsable} from 'src/app/shared/utils/parseMetadata';

declare const window: any;
const permissions = window.cordova?.plugins?.permissions;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  userRoles = UserPlatformRole;
  form: FormGroup;
  previousIdApplication = '';
  changeConsumeSubscription : Subscription;

  constructor(public nav: NavigateService,
              public api: ApiService,
              public cart: CartService,
              public scanDocument: ScanDocumentService,
              public request: RequestService,
              private formBuilder: FormBuilder,
              private loader: LoaderService,
              private lang: LangService,
              private deviceService: DevicesService,
              private inactivity: InactivityService,
              private zone: NgZone,
              private router: Router) {
    if(this.inactivity.isWatching) this.inactivity.stopWatching();
  }

  ngOnInit() {
    const returnUrl = isJsonParsable(sessionStorage.getItem('return_url'));
    if(returnUrl) {
      return useReturnUrl(this.router, returnUrl);
    }
    if(this.api.userRole.isKiosk) this.nav.to('standby-kiosk');
    if(this.api.hasPerm(UserPermission.ALLOW_CONTROL) && !this.api.userRole?.isCustomer) {
      this.api.place(this.api.userPlaceId).then((res: Place) => {
        this.api.connectDailyOtp = res.connectDailyOtp ? res.connectDailyOtp : undefined;
      });
    }
    if(this.api.hasPerm(UserPermission.ALLOW_CONSUME) && !this.api.userRole?.isCustomer && this.api.env.type === DocumentType.ZWEVISA) {
      this.setForm();
      this.changeConsumeSubscription = this.scanDocument.consumeMode$.subscribe((mode) => {
        this.form.get('entryConsume').setValue(mode === ConsumeMode.ENTRY);
        this.form.get('exitConsume').setValue(mode === ConsumeMode.EXIT);
        this.form.get('transitConsume').setValue(mode === ConsumeMode.TRANSIT);
      });
    }
    if(this.api.isElectronApp) {
      electron.step('scan', false, []);
    }

    this.request.checkIfPendingWaiting();
    this.previousIdApplication = window.sessionStorage.getItem('previousIdApplication');
  }

  ngOnDestroy() {
    if(this.changeConsumeSubscription){
      this.changeConsumeSubscription.unsubscribe();
    }
  }


  setForm() {
    this.form = this.formBuilder.group({
      entryConsume: ['', []],
      exitConsume: ['', []],
      transitConsume: ['', []]
    });
    const consumeMode = localStorage.getItem('consumeMode');
    if(consumeMode && consumeMode === ConsumeMode.ENTRY || consumeMode === ConsumeMode.EXIT || consumeMode === ConsumeMode.TRANSIT) {
      this.scanDocument.changeConsume(consumeMode, false);
    }
  }

  offlineLoader() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('no.connection')});
  }

  clickScan() {
    if(this.deviceService.isDevices('cordova')) {
      permissions.checkPermission(permissions.CAMERA, (status: any) =>{
        if(status.hasPermission) {
          this.zone.run(() => {
            this.nav.to('scan-qr');
          });
        } else {
          this.zone.run(() => {
            this.nav.to('page-permission');
          });
        }
      });
    } else {
      this.nav.to('scan-qr');
    }
  }

  disconnect() {
    this.loader.loading(true, {type: 'warn', message: 'ask.disconnect', btnLabel: 'global.yes'}).then((done) => {
      if(done) {
        this.api.signOut();
      }
    });
  }

  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;
  protected readonly ConsumeMode = ConsumeMode;
  protected readonly ShortcutAction = ShortcutAction;
}

import {Injectable} from '@angular/core';
import {generateQrOffline, generateQrOnline} from '../utils/generateQr';
import {FormGroup} from '@angular/forms';
import {format} from 'date-fns';
import {ApiService} from './api.service';
import {parseMetadata} from '../utils/parseMetadata';
import {Request} from '../models/request';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  qrCode: string;
  currentQrData: Request | any;

  constructor(private api: ApiService) {}

  qrOnlineGenerate(placeId: string, id: string, externalId: string, signature64: string) {
    this.qrCode = generateQrOnline(id, signature64, placeId, externalId);
  }

  qrOfflineGenerate(placeId: string, id: string, externalId: string) {
    this.qrCode = generateQrOffline(id, placeId, externalId);
  }

  setCurrentQrData(form?: FormGroup, paymentData?: Request) {
    let data: Request = null;
    if(form) {
      data = {
        amountCents: form.get('buyingOption') ? (form.get('buyingOption').value)?.amountCents : '',
        currency: form.get('buyingOption') ? (form.get('buyingOption').value)?.currency : '',
        firstName: form.get('firstName') ? form.get('firstName').value : '',
        lastName: form.get('lastName') ? form.get('lastName').value : '',
        phone: form.get('phone') ? form.get('phone').value : '',
        idCard: form.get('idCard') ? form.get('idCard').value : '',
        buyingOption: form.get('buyingOption') ? form.get('buyingOption').value : '',
        type: form.get('type') ? form.get('type').value : '',
        created_place_id: form.get('place') ? form.get('place').value : '',
        createdAt: format(Date.now(), 'dd-MM-yyyy @ HH:mm:ss'),
        id: form.get('id') ? form.get('id').value : '',
        signature: form.get('signature') ? form.get('signature').value : '',
        metadata: {'worker': this.api.userInfo.id ? this.api.userInfo.id : this.api.getStoredItem('cache.infos').id},
        operationId: form.get('operationId') ? form.get('operationId').value : '',
        serialized: form.get('serialized') ? form.get('serialized').value : ''
      };
    }
    const key = Object.keys(this.api.getStoredItem('cache.infos'))[0];
    const item = this.api.getStoredItem('cache.infos')[key];
    this.currentQrData = paymentData ? JSON.parse(JSON.stringify(paymentData)) : data;
    this.currentQrData.metadata = this.currentQrData.metadata ? parseMetadata(this.currentQrData.metadata) : item?.id;
  }

  deleteCurrentQrData() {
    this.currentQrData = undefined;
  }

}



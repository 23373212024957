import {Component, NgZone, ViewEncapsulation} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {ApiService} from './shared/services/api.service';
import * as Connection from 'cordova-plugin-network-information/www/Connection';
import './shared/services/sync.service';
import {I18nLang, LangService, LoaderService} from 'ngx-satoris';
import {parse} from 'mrz';
import {environment} from 'src/environments/environment';

declare const window: any;
declare const navigator: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  electron = window.electron;
  progressUploadValues: number[] = [];
  progressDownloadValues: number[] = [];

  constructor(public api: ApiService,
              public router: Router,
              private loader: LoaderService,
              private lang: LangService,
              private zone: NgZone) {

    this.api.progressUploadMap$.subscribe(() => {
      this.progressUploadValues = this.api.getProgressValues(true, 1);
    });
    this.api.progressDownloadMap$.subscribe(() => {
      this.progressDownloadValues = this.api.getProgressValues(false, 1);
    });

    document.title = this.api.env.name;
    this.loader.loading(true);

    if(window.cordova) {
      navigator.connection.ontypechange = () => {
        this.checkOnline(navigator.connection.type === Connection.WIFI || /^[0-9]g$/.test(navigator.connection.type));
      };
    } else {
      window.addEventListener('online', () => this.checkOnline(navigator.onLine));
      window.addEventListener('offline', () => this.checkOnline(navigator.onLine));
      electronLocal.parse = parse;
    }
  }

  ngAfterViewInit() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.api.env.supportSource;
    document.body.appendChild(script);
    this.loader.loading(false);
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    const storedLanguage = localStorage.getItem('lang');
    let defaultLanguage = storedLanguage ? +storedLanguage : I18nLang[(navigatorLanguage || 'en').split('-')[0].toUpperCase() as keyof typeof I18nLang];
    if(!this.api.env.supportedLanguages.includes(defaultLanguage)) {
      defaultLanguage = this.api.env.supportedLanguages[0];
    }
    try {
      setTimeout(() => {
        window.updateGlpi(String(I18nLang[defaultLanguage]).toLowerCase(), undefined, environment.faqSource, environment.appVersion);
      }, 1000);
    } catch {/* empty */}
    this.lang.setLang(defaultLanguage);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.animationState;
  }

  checkOnline(isOnline: boolean) {
    // No sync here! sync has to happen after a proper login...
    if(isOnline && !this.api.isOnline) {
      window.location.reload();
    }
    this.zone.run(() => {
      this.api.isOnline = isOnline;
    });
  }
}

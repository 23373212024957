import {Component, Input, ViewEncapsulation} from '@angular/core';
import {format} from 'date-fns';
import {PrintReceiptData} from 'src/app/shared/models/request';
import {QrService} from 'src/app/shared/services/qr.service';
import {RequestService} from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrl: './print-receipt.component.scss',
  encapsulation: ViewEncapsulation.None

})
export class PrintReceiptComponent {
  format = format;

  @Input() receiptData : PrintReceiptData;
  constructor(public qr: QrService, public request: RequestService) {}
}

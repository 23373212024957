<div id="layout" class="sign_in f_col">
  <app-view-title fromRoute="pre-enroll"
                  [chooseLangBtn]="false">{{ ('trackingControl.title.DYN.' + api.env.type) | translate }}
  </app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_c-50 resp_scroll-wrapper_l">
      <app-view-deco-image imageUrl="assets-projects/images/image_documents.svg"></app-view-deco-image>

      <app-snackbar [matTooltip]="api.isZwevisa ? ('tooltip.trackingApplicationReference' | translate) : ''"
                    *ngIf="batchId" class="f_center mb_10">
        <span class="font_bold">{{'trip.name' | translate }}:</span>
        {{batchId}}
      </app-snackbar>

      <div class="f_col gap_10">
        <app-desc-card
          *ngFor="let req of getSortedRequests()"
          type="1"
          [smSizeL]="true"
          [leftIcon]="request.getHighestSeverityTheme(undefined, req).icon"
          [leftIconColor]="request.getHighestSeverityTheme(undefined, req).iconColor"
          descL="<div class='f_col gap_10'><b>{{request.getHighestSeverityTheme(undefined, req).state}}</b><span>{{req.operationId | translate}}</span></div>"
          [descR1]="req.metadata.firstName + ' ' + req.metadata.lastName"
          [descR2]="('last_update' | translate) +' '+ (req.updatedOn | date: 'dd/MM/yyyy HH:mm')"
          (btnClicked)="nav.to('request-account/'+req.id)"></app-desc-card>
      </div>

      <div class="btns-container">
        <div class="fullWidth flex f_center">
          <div class="menuButton-container flex f_center">
            <div class="ml_10 fullWidth">
              <app-button size="xsm" color="transparent" border="true" formId="form" class="resp_minSize_l fullWidth"
                          (btnClicked)="nav.to('trips')">{{'global.back' | translate}}</app-button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap_5 f_align_end mb_20">
        <app-back-btn class="resp_maxSize_l" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
        <app-button color="transparent" border="true" formId="form" class=" resp_maxSize_l fullWidth"
                    (btnClicked)="nav.to('trips')">{{ 'global.back' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</div>

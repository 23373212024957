<div id="nav">
  <button class="flex f_align_c gap_10 nav-logo-container" (click)="nav.to('/')">
    @if((api.userRole.isWorker || api.userRole.isAdmin) && api.env.type === DocumentType.ZWEVISA) {
      <img class="nav-logo" src="assets-projects/bms-full.svg"/>
      <div [matTooltip]="api.isZwevisa ? ('tooltip.syncing' | translate) : ''"
           class="sync-global cursor-pointer"
           (click)="queue.openExportDialog($event)"
           *ngIf="queue.queue.length && api.hasPerm(UserPermission.ALLOW_CONSUME) && api.userRole.isWorker">
        <app-paragraph class="fullWidth">{{('global.syncing' | translate) + ' (' + queue.queue.length + ')'}}</app-paragraph>
        <svg viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" style="stroke: black; stroke-opacity: 0.5;"/>
        </svg>
      </div>
    } @else {
      <img class="nav-logo" src="assets-projects/logo-full.png"/>
    }
  </button>
  <div class="progress-container" *ngIf="!api.userRole.isKiosk">
    <div class="progress-wrap f_col f_align_c f_center" [class.progress-wrap-hidden]="!progressDownloadValues.length">
      <fa-icon icon="download" class="download-icon"></fa-icon>
      <div class="progress-bar download-progress-bar" [class.show-pulse-progress]="progressDownloadValues.length && progressDownloadValues[0] < 1" [class.has-progress]="progressDownloadValues.length">
        <ng-container *ngFor="let download of progressDownloadValues" >
          <div class="progress download-progress" [style.width.%]="download"></div>
        </ng-container>
      </div>
    </div>
    <div class="progress-wrap f_col f_align_c f_center" [class.progress-wrap-hidden]="!progressUploadValues.length">
      <fa-icon icon="upload" class="upload-icon"></fa-icon>
      <div class="progress-bar upload-progress-bar" [class.show-pulse-progress]="progressUploadValues.length && progressUploadValues[0] < 1" [class.has-progress]="progressUploadValues.length">
        <ng-container *ngFor="let upload of progressUploadValues" >
          <div class="progress upload-progress" [style.width.%]="upload"></div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="flex gap_5">
    <div class="flex gap_5" *ngIf="api.isElectronApp && api.userRole.isWorker && api.hasPerm(api.perm.ALLOW_CONSUME)">
      <app-menu [menuItems]="menuConsumeMode" [placeholder]="'scan.choice' | translate"  (selectionChange)="scanDocument.changeConsume($event)" [value]="scanDocument.consumeMode" rightIcon="chevron-down"></app-menu>
      <app-button *ngIf="!api.userRole?.isCustomer && scanDocument.consumeMode" color="secondary" size="xs" icon="passport" (btnClicked)="scanDocument.scanPassport()" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.scanPassport' | translate) : ''">{{'dropdown.pass' | translate}}</app-button>
      <app-divider *ngIf="!api.userRole?.isCustomer" orientation="vertical"></app-divider>
    </div>
    <app-button *ngIf="!api.userRole?.isCustomer"
                color="secondary"
                size="xs"
                icon="qrcode"
                shortcut [shortcutFnList]="true" [shortcutStyle]="{bottom: '-13px', scale: 0.8}"
                (click)="clickScan()"
                [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.Qr' | translate) : ''">{{'payments.scan' | translate}}</app-button>
    <ng-container *ngFor="let item of data">
      <div class="nav_item"
           *ngIf="item.guard && (item.perm ? api.hasPerm(item.perm) : true)"
           [matTooltip]="(api.env.type === DocumentType.ZWEVISA && item?.tip) ? (item.tip | translate) : ''">
          <app-button shortcut [shortcutFnList]="true" [shortcutStyle]="{bottom: '-13px', scale: 0.8}" [borderXs]="true" color="secondary" [class.bold]="item.route === router.url" size="xs" shortcut [shortcutFnList]="true" [shortcutStyle]="{bottom: '-13px', scale: 0.8}" (click)="item.route ? nav.to(item.route) : item.action ? item.action() : undefined">{{item.label | translate}}</app-button>
      </div>
    </ng-container>
    <app-snackbar *ngIf="api.userInfo" class="cursor-pointer" (click)="nav.to('my-account')" [matTooltip]="api.env.type === DocumentType.ZWEVISA ? ('tooltip.role' | translate) : ''">{{api.userInfo.name + ' | ' + (('form.role.'+userRoles[api.userInfo.role] | lowercase) | translate)}}</app-snackbar>
  </div>
</div>

<div id="layout" class="f_col">
  <app-view-title [bgImage]="api.env.theme.header_background">
    {{'travelName.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image imageUrl="assets-projects/images/image_travel.svg"></app-view-deco-image>
      <form id="form" [formGroup]="form" (ngSubmit)="submit()">
        <app-paragraph class="mb_30" innerhtml="{{(cart.currentApplication?.submitted ? 'form.travelName.already' : 'form.travelName.desc') | translate}}"></app-paragraph>
        <app-input type="text" formCtrlName="travelName" [formGrp]="form" label="form.travelName" [matTooltip]="api.isZwevisa ? ('tooltip.applicationReferenceRequest' | translate) : ''"></app-input>
        <app-paragraph class="mb_30 mt_20" innerhtml="{{'form.plannedEntry.desc' | translate}}"></app-paragraph>
        <app-input type="select" formCtrlName="plannedEntry" [selectOptions]="request.entryPoints" [formGrp]="form" label="form.plannedEntry" class="mt_24" [matTooltip]="api.isZwevisa ? ('tooltip.portEntryRequest' | translate) : ''"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted"
                         [formErrors]="forms.getFormErrors(form)"></app-errors-list>
      </form>
      <div class="resp_minSize_l mt_20 flex gap_10">
        <app-button size="xsm" color="transparent" border="true" class="fullWidth" (btnClicked)="nav.to('dashboard')">
          {{'global.back' | translate}}
        </app-button>
        <app-button size="xsm" type="submit" formId="form" class="fullWidth">
          {{(cart.currentApplication?.submitted ? 'global.continue' : 'global.confirm') | translate}}
        </app-button>
      </div>
    </div>
  </div>
  <div class="f_align_end mb_20 resp_maxSize_l">
    <app-back-btn class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-back-btn [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button type="submit" formId="form" class="fullWidth">
        {{'global.continue' | translate}}
      </app-button>
    </div>
  </div>
</div>

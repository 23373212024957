import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {
  constructor(private dialog: MatDialog) {}

  openDialog(component: any, size: 'sm' | 'md' | 'lg', data?: any) {
    let dialogRef: any;
    switch(size) {
    case 'sm':
      dialogRef = this.dialog.open(component, {
        width: '300px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        autoFocus: false,
        data: data ? data : undefined
      });
      break;
    case 'md':
      dialogRef = this.dialog.open(component, {
        width: '400px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        autoFocus: false,
        data: data ? data : undefined
      });
      break;
    case 'lg':
      dialogRef = this.dialog.open(component, {
        width: '600px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        autoFocus: false,
        data: data ? data : undefined
      });
      break;
    default:
      break;
    }

    return new Promise((resolve => {
      dialogRef.afterClosed().subscribe((result: any) => {
        resolve(result);
      });
    }));
  }
}

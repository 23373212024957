<mat-dialog-content class="dialog_content">
  <p class="py-4">{{'participants.title' | translate}} - {{cart.currentApplication.travelName}}</p>
  <div class="f_col gap_10">
    <ng-container *ngFor="let request of cart.currentApplication.requests">
      <app-slide-element border="{{cart.currentApplication.currentPerson.id === request.person.id ? '4px solid var(--clr-primary) !important' : 'none'}}"
                         [rightIcon]="cart.personRequestIsValid(request.person) ? 'check-circle' : 'edit'"
                         [colorRight]="cart.personRequestIsValid(request.person) ? '#03B000' : '#FF823C'"
                         (btnClicked)="select(request)">
        {{request.person.firstName}} {{request.person.lastName}}
      </app-slide-element>
    </ng-container>
    <app-divider color="rgba(0,0,0,0.1)" [classList]="'mt_10 mb_10'"></app-divider>
    <app-slide-element leftIcon="address-book" (btnClicked)="nav.to('participants'); closeDialog()">
      {{'participants.edit' | translate}}
    </app-slide-element>
  </div>
  <div class="flex f_center mt_20 gap_10">
    <app-button class="dialog_close" size="sm" (btnClicked)="closeDialog()">{{'global.close' | translate}}</app-button>
    <app-button *ngIf="cart.allPersonRequestsAreValid()" size="sm" (btnClicked)="this.nav.to('summary');closeDialog()">{{'onlinePayment.pay' | translate}}</app-button>
  </div>
</mat-dialog-content>

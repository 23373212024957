import {Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {FormService, LoaderService, NavigateService} from 'ngx-satoris';
import {RequestWithDuplicata} from 'src/app/shared/models/request';
import {DocumentType} from 'src/app/shared/models/user';
import {ApiService} from 'src/app/shared/services/api.service';
import {RequestService} from 'src/app/shared/services/request.service';
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrl: './feedback-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FeedbackFormComponent {

  requestId = this.route.snapshot.paramMap.get('requestId');
  currentApplication: {batchId: string; requests: RequestWithDuplicata[] | RequestWithDuplicata[]} = null;
  currentRequest: RequestWithDuplicata = null;
  form: FormGroup;
  submitted = false;
  numberPeople = 1;
  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder, 
    public request: RequestService,
    public api: ApiService,
    public nav: NavigateService,
    public forms: FormService,
    private loader: LoaderService) {
    this.currentApplication = this.request.allMyApplications ? this.request.allMyApplications.find(app => app.requests.some((req: any) => req.id === this.requestId)) as {batchId: string; requests: RequestWithDuplicata[] | RequestWithDuplicata[]} : null;
    this.numberPeople =  (this.currentApplication && this.currentApplication.requests) ? (this.currentApplication.requests.length - 1) : 0;
    if(this.currentApplication) {
      this.currentRequest = this.currentApplication.requests.find((request) => request.id === this.requestId);
    }
    
    if(!this.requestId) {
      this.nav.to('dashboard');
    }
  }

  ngOnInit() {
    this.setForm();
  }
  
  setForm() {
    this.form = this.formBuilder.group({
      numberPeople: [this.numberPeople, [Validators.required]],
      fundsSpend: ['', [Validators.required]],
      fundsCurrency: ['', [Validators.required]],
      countryResidence: ['', [Validators.required]],
      feedbackComment: ['', [Validators.required]]
    });
  }

  submitFeedback() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      this.loader.loading(true);
      const dataFeedback: { request_id: string, person_id: number, serialized: string}[] = [{
        request_id: DocumentType.ZWEFEEDBACK,
        person_id: undefined,
        serialized: JSON.stringify({
          NumberPeople: +this.form.value.numberPeople,
          VisaReference: this.requestId,
          AmountSpent: +this.form.value.fundsSpend,
          AmountSpentCurrency: this.form.value.fundsCurrency,
          Remarks: this.form.value.feedbackComment
        })
      }];
      return this.api.registerMNTAPayment(dataFeedback).then(() => {
        this.loader.loading(true, {type: 'valid', message: 'feedback.submitted'}).then(() => {
          this.nav.to('dashboard');
        });
      }).catch((error) => {
        this.loader.loading(true, {type: 'error', message: error});
      });
    } 
  }
}

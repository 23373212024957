import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {CartService} from './cart.service';

@Injectable()
export class UserUnknownGuardService implements CanActivate {
  constructor(public api: ApiService, 
    public router: Router, 
    private nav: NavigateService,
    private loader: LoaderService,
    private cart: CartService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const getJwt = this.api.getStoredItem('jwt');
    if(getJwt) {
      this.api.jwt = getJwt;
      if(this.api.userInfo) {
        this.nav.to('dashboard');
        return true;
      } else {
        try {
          if(this.api.jwt && JSON.parse(atob(this.api.jwt.split('.')[1])).exp > Date.now() / 1000) {
            this.api.info().then(() => {
              if(this.api.userInfo) {
                this.router.navigate(['/dashboard']);
                return false;
              }
            }, err => this.loader.loading(true, {type: 'error', message: err}));
          }
        } catch (_) {
          this.api.jwt = undefined;
          this.api.removeStoredItem('jwt');
        }
      }
    }
    this.cart.removeStorage();
    return true;
  }
}

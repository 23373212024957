import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-send-password',
  templateUrl: './send-password.component.html',
  styleUrls: ['./send-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendPasswordComponent {

  constructor(public nav: NavigateService) {}

}

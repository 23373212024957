import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, debounceTime, distinctUntilChanged} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  query: string;
  addParams$ = new Subject();
  constructor(private route: ActivatedRoute, private router: Router) {
    this.addParams$.pipe(debounceTime(800), distinctUntilChanged())
      .subscribe(v => this.addParams(v));
  }

  /**
   * Get query of the url (need to be launch at the beginning)
   */
  getQuery() {
    return new Promise<void>((resolve) => {
      this.route.queryParams.subscribe((params: any) => {
        this.query = params;
        resolve();
      });
    });
  }

  /**
   * Filter data add to parameter with the target and the query. startWith for an order on the query
   * @param data {[{[key: string]: any}]}
   * @param target {string}
   * @param query {string}
   * @param startWith {boolean}
   */
  filterData(data: any[], target: string, query: string, startWith = false) {
    let filterArray: any;
    if(data) {
      filterArray = data.filter((data: any) => {
        startWith ?  data[target].trim().toLowerCase().startsWith(query.toLowerCase())  : data[target].trim().toLowerCase().includes(query.toLowerCase());
      });
    } else {
      filterArray =  startWith ?  target.trim().toLowerCase().startsWith(query.toLowerCase())  : target.trim().toLowerCase().includes(query.toLowerCase());
    }
    return filterArray;
  }

  /**
   * Add query to url
   * @param params {{[key: string]: any}}
   */
  addParams(params: {[key: string]: any}) {
    this.router.navigate([],
      {queryParams: params, queryParamsHandling: 'merge'});
  }

  inputQuery(nameQuery: string, event?: Event, value?: {[key: string]: any}, cleanState = true) {
    let paramsToUpdate: {[key: string]: any} = {};
    if(event) {
      const queryValue = (event.target as HTMLInputElement).value;
      if(queryValue) {
        paramsToUpdate[nameQuery] = queryValue;
      } else {
        paramsToUpdate[nameQuery] = null;
      }
    } else {
      paramsToUpdate = value;
    }
    if(cleanState) {
      paramsToUpdate.cleanState = true;
    }
    this.addParams$.next(paramsToUpdate);
  }
}
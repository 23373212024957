<div id="layout" class="sign_in f_col">
  <app-view-title class="mb_30" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'payment.created.register' | translate}}</app-view-title>
  <div class="scroll_wrapper resp_wrapper">
    <div class="resp_scroll-wrapper_l resp_c-50">
      <app-view-deco-image class="mb_20" imageUrl="assets-projects/images/image_done.svg"></app-view-deco-image>
      <span class="text_center mb_30">{{'payment.created' | translate}}</span>
      <span class="text_center"> {{'payment.created.qr' | translate}}</span>
      <app-slide-element class="mt_20" leftIcon="location-dot" (btnClicked)="nav.to('admin-request-select/'+ paymentId)">{{'payment.sheet.open' | translate}}</app-slide-element>
      <app-button size="sm" formId="form" class="fullWidth resp_minSize_l mt_4" (btnClicked)="nav.to('qr/' + paymentId)">{{'qr.see' | translate}}</app-button>
      <app-button size="sm" color="transparent" border="true" formId="form" class="fullWidth resp_minSize_l mt_4" (btnClicked)="nav.to('admin-request')">{{'global.close' | translate}}</app-button>
    </div>
  </div>
  <div class="f_align_end mb_20 f_col gap_10 resp_maxSize_l">
    <div class="ml_10 fullWidth">
      <app-button formId="form" class="fullWidth" (btnClicked)="nav.to('qr/' + paymentId)">{{'qr.see' | translate}}</app-button>
    </div>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.to('admin-request')">{{'global.close' | translate}}</app-button>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Place, UserPlace} from '../models/place';
import {LoaderService} from 'ngx-satoris';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  user: User;
  allPlaces: Place[];
  allPlacesByPerson: Place[];
  currentPlace: Place;
  userPlace: UserPlace;
  maxPlace = 0;

  constructor(private api: ApiService, private loader: LoaderService) {}


  /**
   * Get all existing places
   */
  getAllPlaces(offset = 0, limit = 0): Promise<Place[]> {
    this.loader.loading(true);
    return this.api.searchPlace('', offset, limit).then((res: any) => {
      this.allPlaces = res.result;
      this.maxPlace = res.count;
      this.loader.loading(false);
      return res;
    });
  }

  /**
   * Allow to get one place based on her id
   * @param id {string}
   */
  getPlace(id: string): Promise<Place> {
    this.loader.loading(true);
    return this.api.place(id).then((res: any) => {
      this.currentPlace = res;
      this.loader.loading(false);
      return res;
    });
  }

  /**
   * Get all places by the person (id)
   * @param id {number}
   */
  getAllPlacesByPerson(user: User): Promise<Place[]>{
    this.loader.loading(true);
    if(user.links.length > 0) {
      return this.api.listPlaces(user.links?.map((l:any) => l.place_id)).then((res) => {
        this.loader.loading(false);
        this.allPlacesByPerson = res;
        return res;
      }).catch((error: any) => {
        this.loader.loading(false);
        throw error;
      });
    } else {
      this.loader.loading(false);
      this.allPlacesByPerson = [];
      return Promise.resolve([]);
    }
  }

  /**
   * Get the place with her permissions
   * @param id {number} id of the user
   * @param placeId {string} id of the desired place
   */
  getPermissionPlace(id: number, placeId: string): Promise<void>{
    return this.api.user(id).then((res: User) => {
      this.user = res;
      return this.getPlace(placeId).then(() => {
        this.userPlace = this.user.links.find((place: any) => place.place_id === this.currentPlace.id);
      });
    });
  }
}

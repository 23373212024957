<div class="printFrames">

  <div class="printFrame frame_tl">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">1</span>
    <img class="" [src]="environment.theme.logo" alt="logo" width="100" height="100">
    <div *ngIf="environment.type === DocumentType.ZWEVISA" class="flex f_center">
      <div class="f_col f_align_c">
        <img src="./assets-projects/zimmigration.png" width="80px" alt="">
      </div>
      <div class="f_col f_align_c">
        <img src="./assets-projects/emblem.png" width="80px" alt="">
      </div>
    </div>
    <img *ngIf="api.userInfo?.server?.country && environment.type === DocumentType.PASS" class="w-20 mx-auto" [ngSrc]="'assets/images/flags/'+api.userInfo.server.country+'.png'" alt="flag" width="200" height="200">
  </div>

  <div class="printFrame frame_tr">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">4</span>
    <div class="flex f_align_c mb_10">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/error.png" alt="">
      <span class="frame_title">{{'print.notice.title' | translate}}</span>
    </div>
    <p>{{'print.notice.desc1' | translate}}</p>
    <p>{{'print.notice.desc2' | translate}}</p>
    <div class="flex justify-between flex-grow">
      <span *ngIf="api.userInfo?.server.emailConfig.phone.trim()">TEL: {{api.userInfo?.server.emailConfig.phone}}</span>
      <span>EMAIL: {{api.userInfo?.server.emailConfig.email}}</span>
    </div>
    <div class="flex f_align_c mt_20 mb_20">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/origami.png">
      <span class="frame_title">{{'print.notice.folding' | translate}}</span>
    </div>
    <img class="mb-14" ngSrc="assets/images/folding.png" alt="folding" width="320" height="100">
  </div>

  <div class="printFrame frame_bl">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">2</span>
    <div class="flex f_align_c mb_10">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/about.png">
      <span class="frame_title">{{'print.informations' | translate}}</span>
    </div>
    <ng-container *ngIf="api.env.type === DocumentType.PASS">
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.firstName">
        <span>{{'form.name' | translate}}:</span><span>{{qr.currentQrData?.metadata.firstName}} {{qr.currentQrData?.metadata.lastName}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.passportNumber">
        <span>{{'form.passportNumber' | translate}}:</span><span>{{qr.currentQrData?.metadata?.passportNumber}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.phone">
        <span>{{'form.phone' | translate}}:</span><span>{{qr.currentQrData?.metadata.phone}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.idCard">
        <span>{{'form.idCard' | translate}}:</span><span>{{qr.currentQrData?.metadata?.idCard}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.amountCents">
        <span>{{'form.amount' | translate}}:</span><span>{{qr.currentQrData?.amountCents / 100}} {{qr.currentQrData?.currency}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.buyingOption">
        <span>{{'form.buyingOption' | translate}}: </span><span>{{qr.currentQrData?.metadata?.buyingOption?.type ? qr.currentQrData?.metadata?.buyingOption?.type : qr.currentQrData?.metadata?.buyingOption}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.type && !api.userRole.isCustomer">
        <span>{{'form.type' | translate}}:</span><span>{{qr.currentQrData?.type}}</span>
      </div>
      <div class="printFrame_data" *ngIf="api.userRole.isCustomer">
        <span>{{'form.type' | translate}}: </span><span>{{qr.currentQrData?.paymentType}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.created_place_id">
        <span>{{'form.place' | translate}}:</span><span>{{qr.currentQrData?.created_place_id}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.createdAt">
        <span>{{'form.created_date' | translate}}:</span><span>{{qr.currentQrData?.createdAt | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.id">
        <span>{{'form.id' | translate}}:</span><span>{{qr.currentQrData?.id}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.worker">
        <span>{{'form.worker' | translate}}:</span><span>{{sync.userDisplay(qr.currentQrData?.metadata?.worker)}} #{{qr.currentQrData?.metadata?.worker}}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="api.env.type !== DocumentType.PASS">
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.firstName || qr.currentQrData?.serialized?.FirstName">
        <span>{{'form.name' | translate}}:</span><span>{{qr.currentQrData?.metadata.firstName ||  qr.currentQrData?.serialized?.FirstName}} {{qr.currentQrData?.metadata.lastName ||  qr.currentQrData?.serialized?.LastName}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.serialized?.BirthDay">
        <span>{{'form.birthDate' | translate}}:</span><span>{{qr.currentQrData?.serialized?.BirthDay | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.serialized?.Nationality">
        <span>{{'form.nationality' | translate}}:</span><span>{{('DYN.' + qr.currentQrData?.serialized?.Nationality) | translate}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.metadata?.passportNumber || qr.currentQrData?.serialized?.PassportNumber">
        <span>{{'form.passportNumber' | translate}}:</span><span>{{qr.currentQrData?.metadata?.passportNumber || qr.currentQrData?.serialized?.PassportNumber}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.operationId">
        <span>{{('document.type.DYN.' + api.env.type) | translate}}: </span><span>{{qr.currentQrData?.operationId | translate}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.usageAfter">
        <span>{{'visaIntro.validFrom' | translate}}: </span><span>{{qr.currentQrData?.usageAfter | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.usageUntil">
        <span>{{'visaIntro.validUntil' | translate}}: </span><span>{{qr.currentQrData?.usageUntil | date}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.operationId">
        <span>{{'visaIntro.usageCount' | translate}}: </span><span>{{getUsageCount(qr.currentQrData?.operationId)}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.externalId">
        <span>{{'form.externalId' | translate}}: </span><span>{{qr.currentQrData?.externalId | lowercase}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.id">
        <span>{{'trip.name' | translate}}:</span><span>{{qr.currentQrData?.batchId | lowercase}}</span>
      </div>
      <div class="printFrame_data" *ngIf="qr.currentQrData?.id">
        <span>{{'application.id' | translate}}:</span><span>{{qr.currentQrData?.id | lowercase}}</span>
      </div>
    </ng-container>
  </div>

  <div class="printFrame frame_br">
    <img class="printFrame_bg" src="./assets/images/print_bg.png" alt="">
    <span class="frame_number">3</span>
    <div class="flex f_align_c mb_10">
      <img class="printFrame_header_icon" src="assets/images/icons_clr/qr.png">
      <span class="frame_title">{{'print.qr.title' | translate}}</span>
    </div>
    <div *ngIf="api.env.type === DocumentType.ZWEVISA">
      <div class="flex f_col gap_10">
        <app-paragraph innerhtml="{{'print.desc.part1' | translate}}" textAlign="justify"></app-paragraph>
      </div>
    </div>
    <div *ngIf="api.env.type === DocumentType.PASS">
      <p>{{'print.qr.desc' | translate}}</p>
      <p class="small" *ngIf="environment.type !== DocumentType.PASS">{{'qr.request_desc' | translate}}</p>
      <p *ngIf="environment.type !== DocumentType.PASS" class="fw_bold">{{(isValid ? 'qrDesc.valid' : 'qrDesc.invalid') | translate}}</p>
    </div>
    <qrcode *ngIf="isValid || api.env.type === DocumentType.PASS" class="qr-print" elementType="svg" errorCorrectionLevel="M" [qrdata]="qr.qrCode" [width]="250"></qrcode>
  </div>
</div>

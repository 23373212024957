import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {

  navDelay = {
    md: 300,
    l: 500
  };

  devToolShowOTP: boolean;

  constructor(private router: Router, private location: Location) {}

  animatedNavWithDelay(route: string, delay: number, animName: string, idToAnim: string) {
    this.animateElement(animName, idToAnim, delay);
    setTimeout(() => {
      this.router.navigate([route]);
    }, delay);
  }

  animateElement(animName: string, idToAnim: string, delay: number) {
    document.getElementById(idToAnim).style.animation = animName + ' ' + delay + 'ms forwards';
  }

  redirect(route: string, delay?: number) {
    setTimeout(() => {
      this.router.navigate([route]);
    }, delay ? delay : 0);
  }

  scrollToElementId(id: string) {
    document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  back() {
    this.location.back();
  }
}

<div id="layout" class="sign_in f_col text_center">
  <app-view-title class="" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'permission.title' | translate}}</app-view-title>
  <app-view-deco-image class="mb_20" imageUrl="assets-projects/images/image_permission.svg"></app-view-deco-image>
  <div class="scroll_wrapper">
  <span class="mb_40">{{'permission.desc' | translate}}</span>
  <app-button (btnClicked)="checkPermission()" >{{'global.accept' | translate}}</app-button>
  </div>
  <div class="f_align_end f_grow mb_20">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button color="transparent" border="true" formId="form" class="fullWidth" (btnClicked)="nav.to('dashboard')">{{'global.close' | translate}}</app-button>
    </div>
  </div>
</div>

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import * as uuid from 'uuid';
import {UserPlatformRole, UserRoles, DocumentType} from '../../../shared/models/user';
import {ApiService} from '../../../shared/services/api.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserCreateComponent implements OnInit {

  form: FormGroup;
  userPlatformRole = UserPlatformRole;
  submitted = false;
  roles = UserRoles;

  constructor(public nav: NavigateService,
              private formBuilder: FormBuilder,
              public forms: FormService,
              private loader: LoaderService,
              private api: ApiService,
              private lang: LangService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.roles.map(x => x.name = this.lang.transform('form.role.' + this.userPlatformRole[x.value].toLowerCase()));
    if(environment.type === DocumentType.ZWEVISA) {
      this.roles = this.roles.filter(x => x.name !== this.lang.transform('form.role.orbis_consumer') && x.name !== this.lang.transform('form.role.partner_registrer'));
    }
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(100)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20)
      ]],
      password2: ['',
        [Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20)
        ]],
      name: ['', [
        Validators.required,
        Validators.maxLength(50)
      ]],
      role: ['', [
        Validators.required
      ]],
      id: [uuid.v1(), [
        Validators.required,
        Validators.maxLength(50)
      ]]
    },
    {
      validator: this.forms.mustMatch('password', 'password2')
    });
  }

  createUser() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      this.loader.loading(true);
      this.api.registerAdmin(this.form.get('email').value,
        this.form.get('password').value,
        Number(this.form.get('role').value),
        this.form.get('id').value,
        this.form.get('name').value,
        this.api.userPlaceId).then((res: any) => {
        this.loader.loading(false);
        this.nav.to('user-created/' + res.result);
        this.form.reset();
        this.form.controls.id.setValue(uuid.v1());
      }).catch((err) => {
        this.loader.loading(true, {type: 'error', message: err});
      });
    }
  }

  goToPreviousUrl() {
    this.router.navigateByUrl(localStorage.getItem('previous_url'));
  }
}

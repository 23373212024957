import {Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {ConsumePaymentNoticeType, OperationType} from 'src/app/shared/models/request';
import {ApiService} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-admin-scan-fingerprint',
  templateUrl: './admin-scan-fingerprint.component.html',
  styleUrl: './admin-scan-fingerprint.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AdminScanFingerprintComponent {

  constructor(public api: ApiService,
    public nav: NavigateService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe((params: any) => {
      const {createdAt, paymentId, placeID, userPlaceID, operation, from, paymentReference, provisionalPermit, occupationMedia, localUsageUntil, fromScanPassport, fromScanFingerprint, fromNoticePrint, cashType, referencePrint, datePrint, nameClient, amount, currency, agentId, typePayment, placeId, buyDocument, idRequest, paymentIsNotDocument, borderPass, purposeOfVisit, ...rest} = params;
      this.query = {createdAt, paymentId, placeID, userPlaceID, operation, from, occupationMedia, localUsageUntil, provisionalPermit, paymentReference, fromScanPassport, fromNoticePrint, cashType, fromScanFingerprint, referencePrint, datePrint, nameClient, amount, currency, agentId, typePayment, placeId, buyDocument, idRequest, paymentIsNotDocument, borderPass, purposeOfVisit, ...rest};
      this.backParams = {from: this.query.from, ...rest};
    });
  }

  query: any;
  backParams: any;
  minimalTolerance = 40;
  qualityFingerPrint: number | undefined = undefined;
  arrayPointFingerPrint: number[];
  badQuality: boolean;
  form: FormGroup;
  OperationType = OperationType;

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      checkNotice: ['']
    });
  }
  scanFingerprint() {
    const fingerprintScan = electron.addon.scanFingerprint();
    const arayScanned = fingerprintScan[0];
    const qualityScanned = fingerprintScan[1];
    if(qualityScanned > 100 || qualityScanned <= 0 || !arayScanned.length) {
      this.loader.loading(true, {type: 'error', message: 'scan.fingerprint.noResponse'});
    } else {
      this.arrayPointFingerPrint = arayScanned;
      this.qualityFingerPrint = qualityScanned;
      this.badQuality = this.qualityFingerPrint < this.minimalTolerance;
    }
  }

  confirmFingerprint() {
    window.sessionStorage.setItem('fingerprint', JSON.stringify(this.arrayPointFingerPrint));
    if(this.query.operation === OperationType.IN || this.query.operation === OperationType.INRETURNED) {
      const isNoticeChecked = this.form.get('checkNotice').value;
      if(isNoticeChecked) {
        this.nav.to('admin-request-notice/' + this.query.paymentId, undefined, {queryParams: {from: 'admin-scan-fingerprint', ...this.query}});
      } else {
        this.nav.to('admin-request-face-check', undefined, {queryParams: this.query});
      }
    } else {
      this.nav.to('admin-request-face-check', undefined, {queryParams: this.query});
    }
  }

  retryFingerPrint() {
    this.badQuality = false;
    this.arrayPointFingerPrint = [];
    this.qualityFingerPrint = 0;
    this.scanFingerprint();
  }

  skipFingerPrint() {
    window.sessionStorage.removeItem('fingerprint');
    this.arrayPointFingerPrint = [];
    this.qualityFingerPrint = 0;
    if(this.query.operation === OperationType.IN || this.query.operation === OperationType.INRETURNED) {
      const isNoticeChecked = this.form.get('checkNotice').value;
      if(isNoticeChecked) {
        this.nav.to('admin-request-notice/' + this.query.paymentId, undefined, {queryParams: {from: 'admin-scan-fingerprint', ...this.query, notice: this.query.occupationMedia ? ConsumePaymentNoticeType.REPORTING : this.query.provisionalPermit === 'true' ? ConsumePaymentNoticeType.RESTRICTION : undefined}});
      } else {
        this.nav.to('admin-request-face-check', undefined, {queryParams: {fromScanFingerprint: 'true', ...this.query}});
      }
    } else {
      this.nav.to('admin-request-face-check', undefined, {queryParams: {fromScanFingerprint: 'true', ...this.query}});
    }
  }
}

import {Injectable, OnDestroy} from '@angular/core';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {Observable, Subject, fromEvent, merge, timer} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InactivityService implements OnDestroy {
  private stop$ = new Subject<void>();
  private inactivityTimer$: Observable<number>;
  private timeoutRef: number;
  isWatching = false;

  constructor(private loader: LoaderService, private nav: NavigateService) {}

  startWatching(inactivityDelay = 30000, popupDelay = 20000, message = 'stillHere'): void {
    const events$ = merge(fromEvent(window, 'mousemove'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'click'),
      fromEvent(window, 'touchstart'));
    this.inactivityTimer$ = events$.pipe(switchMap(() => timer(inactivityDelay)),
      takeUntil(this.stop$));
    this.inactivityTimer$.subscribe(() => {
      this.handlePopup(popupDelay, inactivityDelay, message);
    });
    this.isWatching = true;
  }

  private handlePopup(popupDelay: number, inactivityDelay: number, message: string) {
    const reset = () => {
      this.nav.to('standby-kiosk');
      this.stopWatching();
      this.startWatching(inactivityDelay, popupDelay, message);
    };

    this.loader.loading(true, {type: 'info', message: message, btnLabel: 'yes', custom: {icon: 'stopwatch-20', closeBtnLabel: 'no'}})
      .then((done: boolean) => {
        if(done) {
          clearTimeout(this.timeoutRef);
          this.timeoutRef = undefined;
          this.stopWatching();
          this.startWatching(inactivityDelay, popupDelay, message);
        } else {
          reset();
        }
      });
    this.timeoutRef = window.setTimeout(() => {
      this.loader.loading(false);
      reset();
    }, popupDelay);
  }

  stopWatching() {
    this.isWatching = false;
    this.stop$.next();
  }

  ngOnDestroy() {
    this.stopWatching();
  }
}

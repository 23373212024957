<div id="layout" class="f_col">
    <app-view-title class="mb_20" fromRoute="document-management" [chooseLangBtn]="false">{{'scanned.list' | translate}}</app-view-title>
    <div class="scroll_wrapper resp_wrapper">
      <div class="scanned-container">
        <div class="comparison-container" *ngIf="scanDocument?.scanDatas">
            <div class="flex f_col gap_5">
              <app-button class="resp_minSize_l" size="xsm" (click)="goBack()">{{'global.back' | translate}}</app-button>
                <div class="comparison comparison-passport" [class.documentError]="scanDocument?.documentError && scanDocument?.documentError?.indexOf('err') === 0"  [matTooltip]="api.isZwevisa ? ('tooltip.passportScanned' | translate) : ''" [class.documentWarning]="scanDocument?.documentWarning && scanDocument?.documentWarning?.indexOf('err') === 0">
                  <div [class.passport-documentError]="scanDocument?.documentError && scanDocument?.documentError?.indexOf('err') === 0" [class.passport-documentWarning]="scanDocument?.documentWarning && scanDocument?.documentWarning?.indexOf('err') === 0" *ngIf="(scanDocument?.documentError && scanDocument?.documentError?.indexOf('err') === 0) || (scanDocument?.documentWarning && scanDocument?.documentWarning?.indexOf('err') === 0)">
                    <fa-icon class="mr_5" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
                  </div>
                  <div class="passport-documentError" *ngIf="scanDocument?.documentError && scanDocument?.documentError.indexOf('err') === 0">
                    <fa-icon class="mr_5" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
                  </div>
                  <app-paragraph class="fw_bold fs-h5 text-center">{{'scanned.passport' | translate}}</app-paragraph>
                  <fa-icon class="comparison-logo" icon="passport"></fa-icon>
                   <div class="passport-img">
                    <img class="img" [src]="scanDocument?.documentPhoto" *ngIf="scanDocument?.documentPhoto">
                    <div class="img img-noDoc" *ngIf="!scanDocument?.documentPhoto">
                    {{'no.passportPhoto' | translate}}</div>
                  </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.firstName">
                      <span class="fw_bold">{{'form.firstName.scanned' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                        {{scanDocument.scanDatas.fields.firstName}}
                      </app-slide-element>
                    </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.lastName">
                      <span class="fw_bold">{{'form.lastName.scanned' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                        {{scanDocument.scanDatas.fields.lastName}}
                      </app-slide-element>
                    </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.birthDate">
                      <span class="fw_bold">{{'form.birthDate.scanned' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="cake-candles">
                        {{convertToEpoch(scanDocument.scanDatas.fields.birthDate, false, true) | date}}
                      </app-slide-element>
                    </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.documentNumber">
                      <span class="fw_bold">{{'form.documentNumber.scanned' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="hashtag">
                        {{scanDocument.scanDatas.fields.documentNumber}}
                      </app-slide-element>
                    </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.sex">
                      <span class="fw_bold">{{'form.gender.scanned' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="venus-mars">
                        {{scanDocument.scanDatas.fields.sex}}
                      </app-slide-element>
                    </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.nationality">
                      <span class="fw_bold">{{'form.nationality.scanned' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                        {{'DYN.' + scanDocument.scanDatas.fields.nationality | translate}}
                      </app-slide-element>
                    </div>
                    <div class="f_col gap_5 fullWidth" *ngIf="scanDocument?.scanDatas?.fields?.expirationDate">
                      <span class="fw_bold">{{'form.expireDate' | translate}}</span>
                      <app-slide-element class="fw_bold" [noWrap]="true" bgColor="#FFFFFF" [class.no-pointer]="!selectMode" rightIcon="user">
                        {{convertToEpoch(scanDocument.scanDatas.fields.expirationDate) | date}}
                      </app-slide-element>
                    </div>
                </div>
            </div>
          </div>
          <div class="fullWidth">
            <div class="mb_20" [ngClass]="scanDocument?.documentWarning ? 'warning-container' : scanDocument?.documentError?.indexOf('err') === 0 ? 'refusal-container' : 'validate-container'" *ngIf="scanDocument?.documentError || scanDocument?.documentWarning ">
              <div class="flex">
                <fa-icon *ngIf="scanDocument?.documentError?.indexOf('err') === 0" class="mr_5" size="xl" [icon]="'triangle-exclamation'"></fa-icon>
                <app-paragraph class="refusal-desc">{{(scanDocument?.documentWarning || scanDocument?.documentError) | translate}}</app-paragraph>
              </div>
            </div>
            <div class="scanned-list-container mb_20">
                @for (visa of scanDocument.visaDatas; track visa.id;) {
                    <button class="scanned-card" (click)="navTo(visa)"  [matTooltip]="api.isZwevisa ? ('tooltip.scannedCard' | translate) : ''">
                        <app-paragraph class="fw_bold fs-h5 text-center">{{visa.operationId | translate}}</app-paragraph>
                        <div class="request-state">
                            <app-snackbar [customColor]="request.getStatusTheme(visa).color" [icon]="request.getStatusTheme(visa).icon" [customColorIcon]="request.getStatusTheme(visa).iconColor">{{request.getStatusTheme(visa).state}}</app-snackbar>
                          </div>
                        <div class="flex gap_5 fullWidth">
                            <div *ngIf="visa?.usageAfter" class="fullWidth">
                                <span class="ml_10" [class.usage-error]="!request.isUsageDateValid(visa.usageAfter,'usageAfter')" >{{'form.usageAfter' | translate}}</span>
                                <app-slide-element [noWrap]="true" bgColor="#FFFFFF" rightIcon="clock">
                                  {{visa.usageAfter | date: 'dd/MM/yyyy'}}
                                </app-slide-element>
                              </div>
                              <div class="fullWidth">
                                <span class="ml_10" [class.usage-error]="visa.usageUntil && !request.isUsageDateValid(visa.usageUntil,'usageUntil')">{{'form.usageUntil' | translate}}</span>
                                <app-slide-element [noWrap]="true" bgColor="#FFFFFF" rightIcon="clock">
                                  {{visa.usageUntil | date: 'dd/MM/yyyy'}}
                                </app-slide-element>
                              </div>
                        </div>
                        <div class="fullWidth">
                            <span class="ml_10">{{'form.firstName' | translate}}</span>
                            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" rightIcon="user">
                              {{parseIndexedData(visa.internalIndexedData, visa.operationId, api.userInfo.server).firstName}}
                            </app-slide-element>
                          </div>
                          <div class="fullWidth">
                            <span class="ml_10">{{'form.lastName' | translate}}</span>
                            <app-slide-element [noWrap]="true" bgColor="#FFFFFF" rightIcon="user">
                              {{parseIndexedData(visa.internalIndexedData, visa.operationId, api.userInfo.server).lastName}}
                            </app-slide-element>
                          </div>
                    </button>
                }
            </div>
          </div>
      </div>
    </div>
    <div class="mb_20 resp_maxSize_l flex gap_10">
        <app-button formId="form" class="fullWidth" (btnClicked)="goBack()">{{'global.back' | translate}}</app-button>
    </div>
  </div>

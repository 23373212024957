const StringIsNumber = (enumChange: any) => isNaN(Number(enumChange)) === false;


/**
 * Change an enum from "name = 'value'" to an array of objects [{value= 'value', name='name'}]
 * Optionally adds a null option at the start.
 * @param enumChange The enum to convert.
 * @param addNull Whether to add a null option at the start.
 */
const enumToArrayObject = (enumChange: any, addNull = false): {name: string, value: string | null}[] => {
  const initialArray: {name: string, value: string | null}[] = addNull ? [{name: '', value: null}] : [];
  const enumArray: {name: string, value: string | null}[] = Object.keys(enumChange)
    .filter(StringIsNumber)
    .map((key) => ({name: enumChange[key], value: key}));
  return initialArray.concat(enumArray);
};

/**
 * Change an enum from "name = 'value'" to an array of objects [{name= 'name', value= 'value'}]
 * Optionally adds a null option at the start.
 * @param enumChange The enum to convert.
 * @param addNull  Whether to add a null option at the start.
 */
const enumStringToArrayObject = (enumChange: { [s: string]: string }, addNull: boolean = false): {name: string, value: string | null}[] => {
  const initialArray: {name: string, value: string | null}[] = addNull ? [{name: '', value: null}] : [];
  const enumArray: {name: string, value: string | null}[] = Object.keys(enumChange).map(key => ({name: key, value: enumChange[key]}));
  return initialArray.concat(enumArray);
};

const enumToArrayName = (enumChange: any, filterNumbers = true) => {
  const isNumber = (value: string) => !isNaN(Number(value));
  return Object.keys(enumChange).filter(key => filterNumbers ? !isNumber(key) : true).map(key => enumChange[key]);
};


/**
 * Change an enum from "name = 'value'" to an array of objects [{value= 'value', name='name'}] with a suffix for select
 * @param enumChange {{ [s: number]: string }}
 * @param suffix {string}
 */
const enumToArrayObjectSelect = (enumChange: { [s: number]: string }, suffix: string) => Object.entries(enumChange).map(([value, name]) => ({value, name: suffix + name}));

export {enumToArrayObject, enumToArrayObjectSelect, enumToArrayName, enumStringToArrayObject};

import {Component, OnInit} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin-request-created',
  templateUrl: './admin-request-created.component.html',
  styleUrls: ['./admin-request-created.component.scss']
})
export class AdminRequestCreatedComponent implements OnInit {

  constructor(public nav: NavigateService, private route: ActivatedRoute) {}

  paymentId : string;

  ngOnInit(): void {
    this.paymentId = this.route.snapshot.paramMap.get('paymentId');
  }
}

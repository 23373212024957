export const rotateBase64Image = (base64Image: string, degrees: number): Promise<string> => new Promise((resolve, reject) => {
  const img = new Image();
  img.src = base64Image;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d'); 
    if(!ctx) {
      return;
    }
    const width = img.width;
    const height = img.height;
    if(degrees === 90 || degrees === 270) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(degrees * Math.PI / 180);
    ctx.drawImage(img, -width / 2, -height / 2);
    const format = base64Image.substring('data:image/'.length, base64Image.indexOf(';base64'));
    resolve(canvas.toDataURL(`image/${format}`));
  };
  img.onerror = (err) => {
    reject(err);
  };
});
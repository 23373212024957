import {Component, NgZone, ViewEncapsulation} from '@angular/core';
import {DevicesService, NavigateService} from 'ngx-satoris';

declare const window: any;
const permissions = window.cordova?.plugins?.permissions;

@Component({
  selector: 'app-page-permission',
  templateUrl: './page-permission.component.html',
  styleUrls: ['./page-permission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagePermissionComponent  {

  constructor(public nav: NavigateService, private device: DevicesService, private zone: NgZone) {}

  checkPermission() {
    if(this.device.isDevices('cordova')) {
      permissions.checkPermission(permissions.CAMERA, (status: any) => {
        if(status.hasPermission) {
          this.zone.run(() => {
            this.nav.to('scan-qr');
          });
        } else {
          this.askPermission();
        }
      });
    } else {
      this.nav.to('dashboard');
    }
  }
  askPermission() {
    permissions.requestPermission(permissions.CAMERA, (status: any)=>{
      if(status.hasPermission) {
        this.zone.run(()=>{
          this.nav.to('scan-qr');
        });
      }
    });
  }
}

<div class="standby_layout">
  <div class="standby_top">
    <h1>{{'kiosk.standbyDesc.title' | translate}}</h1>
    <div class="standby_desc">
      <p>{{'kiosk.standbyDesc.text1' | translate}}</p>
      <ol>
        <li>
          <img src="assets-projects/images/contact-form.png" alt="icon">
          {{'kiosk.standbyDesc.li1' | translate}}</li>
        <li>
          <img src="assets-projects/images/swear.png" alt="icon">
          {{'kiosk.standbyDesc.li2' | translate}}</li>
        <li>
          <img src="assets-projects/images/cop.png" alt="icon">
          {{'kiosk.standbyDesc.li3' | translate}}</li>
        <li>
          <img src="assets-projects/images/accept.png" alt="icon">
          {{'kiosk.standbyDesc.li4' | translate}}</li>
        <li>
          <img src="assets-projects/images/ask.png" alt="icon">
          {{'kiosk.standbyDesc.li5' | translate}}</li>
      </ol>
      <p>{{'kiosk.standbyDesc.text2' | translate}}</p>
    </div>
    <p><i>{{'kiosk.standbyDesc.text3' | translate}}</i></p>
  </div>
  <div class="standby_bottom">
    <qrcode colorLight="none" elementType="svg" margin="0" qrdata="{{'kiosk.qr.url' | translate}}" [width]="120" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
    <button (click)="cart.startApplication(true)">{{'start' | translate}}</button>
    <img class="standby_logo" src="assets-projects/logo-kiosk.svg" alt="Kiosk Logo">
  </div>
</div>

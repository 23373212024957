import {Validators} from '@angular/forms';
import {FormAction, VisaFN, VisaFormConfig, VisaFormName} from 'src/app/shared/models/forms';
import {endOfDay, format, startOfDay, milliseconds, subYears, addYears} from 'date-fns';

export const canMultipleCountries: string[] = ['CAN', 'USA', 'GBR'];

export interface Visa {
  AddressZimbabwe: string;
  ArrivalDate: number;
  BirthDay: number;
  DateOfExpiry: number;
  DateOfIssue: number;
  DepartureDate: number;
  Dependants: string;
  DependantsDetails: any[];
  CompanionsSize: number;
  Documents: {
    AddressAtDestination?: string;
    ApplicationLetter?: string;
    BusinessLetter?: string;
    BusinessProfile?: string;
    HostInvitationLetter?: string;
    InvitationLetterAndBusinessProfile?: string;
    LetterOfAcceptanceFromSchool?: string;
    PassportPhoto: string;
    PassportScan: string;
    PreviousZimbabweanVisa?: string;
    ProofOfResidence?: string;
    ProofOfResidenceHost: string;
    PurposeOfVisit: string;
    ReasonOfVisitProof?: string;
    ResidentialStatusOfHost?: string;
    InvitationLetter?: string;
    AttestantDocument?: string,
    AffidavitCustody?: string,
    CopyOfEntry?: string,
    DepartureTicket?: string,
    ExtraDocument1?: string,
    ExtraDocument2?: string,
    ExtraDocument3?: string,
    ExtraDocument4?: string,
    ExtraDocument5?: string,
  };
  Email: string;
  FirstName: string;
  OtherName: string;
  Gender: 'male' | 'female' | 'other';
  HomeAddress: string;
  HostName: string;
  LastName: string;
  MaritalStatus: 'single' | 'married' | 'separated' | 'divorced' | 'widowed';
  Nationality: string;
  Occupation: string;
  PassportNumber: string;
  Phone: string;
  PlaceOfBirth: string;
  PlaceofIssue: string;
  PreviousConvictionsZim: string;
  PreviousConvictionsHome: string;
  Spouse: string;
  SpouseBirthDay?: number;
  SpouseFullName?: string;
  SpousePassportNumber?: number;
  SpousePlaceOfBirth?: string;
  NextOfKin?: string;
  FundsAvailableCurrency?: string,
  FundsAvailable?: number,
  Urgent?: string;
  PassportType?: string;
  ProvisionnalEntryPort?: string;
  NonOrdinaryPassMission?: string;
  PassportCountry?: string;
}

//Model to represent the form config
export const VISA_FORM_CONFIG: VisaFormConfig = {
  [VisaFormName.PERSONAL_DETAILS]: {
    version: 0.1,
    classNames: 'gap_25',
    fields: [
      {name: VisaFN.PURPOSE_OF_VISIT, type: 'select', icon: 'question', validators: [Validators.required], action: FormAction.SAVE, showInResume: true},
      {name: VisaFN.LAST_NAME, type: 'text', icon: 'person', validators: [Validators.required, Validators.pattern('^[A-Za-z \\-]+$')], showInResume: true},
      {name: VisaFN.FIRST_NAME, type: 'text', icon: 'person', validators: [Validators.required, Validators.pattern('^[A-Za-z \\-]+$')], showInResume: true},
      {name: VisaFN.OTHER_NAME, type: 'text', icon: 'person', validators: [Validators.pattern('^[A-Za-z \\-]+$')], showInResume: true, tooltip: 'tooltip.otherName'},
      {name: VisaFN.BIRTH_DAY, type: 'date', icon: 'cake-candles', validators: [Validators.required], dateValidators: {maxDate: format(endOfDay(new Date()), 'yyyy-MM-dd'), mustBeEqualOrBefore: {form: VisaFormName.TRAVEL_INFORMATION, control: VisaFN.DATE_OF_ISSUE}, minDate: format(subYears(new Date(), 150), 'yyyy-MM-dd')}, showInResume: true},
      {name: VisaFN.PLACE_OF_BIRTH_COUNTRY, type: 'select', icon: 'location-dot', validators: [Validators.required], showInResume: true},
      {name: VisaFN.PLACE_OF_BIRTH_CITY, type: 'text', icon: 'location-dot', validators: [Validators.required], showInResume: true},
      {name: VisaFN.GENDER, type: 'select', icon: 'venus-mars', enum: ['male', 'female'], validators: [Validators.required], showInResume: true},
      {name: VisaFN.MARITAL_STATUS, type: 'select', icon: 'people-arrows', enum: ['single', 'married', 'separated', 'divorced', 'widowed'], validators: [Validators.required], showInResume: true},
      {name: VisaFN.EMAIL, type: 'text', icon: 'at', validators: [Validators.required, Validators.email], showInResume: true},
      {name: VisaFN.PHONE, type: 'text', icon: 'phone', validators: [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')], customExplain: 'phone.pattern', showInResume: true},
      {name: VisaFN.OCCUPATION, type: 'text', icon: 'briefcase', validators: [Validators.maxLength(50)], showInResume: true},
      {name: VisaFN.HOME_ADDRESS, type: 'text', icon: 'house', validators: [], showInResume: true, noAutocomplete: true},
      {name: VisaFN.HOST_NAME, type: 'text', icon: 'person', validators: [], showInResume: true, tooltip: 'tooltip.hostName'},
      {name: VisaFN.ADDRESS_ZIMBABWE, type: 'text', icon: 'location-dot', validators: [], showInResume: true, noAutocomplete: true, tooltip: 'tooltip.addressZimbabwe'},
      {name: VisaFN.SPOUSE, type: 'checkbox', tooltip: 'tooltip.spouseCheckbox'},
      {name: VisaFN.SPOUSE_NAME, type: 'text', icon: 'person', ifFormControl: VisaFN.SPOUSE, requiredIfFormControl: true, validators: [], showInResume: true},
      {name: VisaFN.SPOUSE_PASSPORT_NUMBER, type: 'text', icon: 'hashtag', ifFormControl: VisaFN.SPOUSE, validators: [], showInResume: true},
      {name: VisaFN.SPOUSE_DATE_OF_BIRTH, type: 'date', icon: 'cake-candles', ifFormControl: VisaFN.SPOUSE, requiredIfFormControl: true, dateValidators: {maxDate: format(endOfDay(new Date()), 'yyyy-MM-dd'), minDate: format(subYears(new Date(), 150), 'yyyy-MM-dd')}, showInResume: true},
      {name: VisaFN.SPOUSE_PLACE_OF_BIRTH, type: 'text', icon: 'location-dot', ifFormControl: VisaFN.SPOUSE, requiredIfFormControl: true, validators: [], showInResume: true},
      {name: VisaFN.FUNDS_AVAILABLE, type: 'number', icon: 'piggy-bank', validators: [Validators.required], showInResume: true, sameRow: 'currency'},
      {name: VisaFN.FUNDS_AVAILABLE_CURRENCY, type: 'select', icon: 'dollar-sign', enum: ['USD', 'EUR', 'GBP'], validators: [Validators.required], showInResume: true, sameRow: 'currency'}
    ]
  },
  [VisaFormName.EMERGENCY_CONTACT]: {
    version: 0,
    classNames: 'gap_25',
    textBeforeForm: {
      text: 'emergency_contact_text'
    },
    fields: [
      {name: VisaFN.EMERGENCY_LAST_NAME, type: 'text', icon: 'person', validators: [Validators.maxLength(50)], showInResume: true},
      {name: VisaFN.EMERGENCY_FIRST_NAME, type: 'text', icon: 'person', validators: [Validators.maxLength(50)], showInResume: true},
      {name: VisaFN.EMERGENCY_EMAIL, type: 'text', icon: 'at', validators: [Validators.email, Validators.maxLength(100)], showInResume: true},
      {name: VisaFN.EMERGENCY_PHONE, type: 'text', icon: 'phone', validators: [Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'), Validators.maxLength(20)], customExplain: 'phone.pattern', showInResume: true}
    ]
  },
  [VisaFormName.TRAVEL_INFORMATION]: {
    version: 0.1,
    classNames: 'gap_25',
    textBeforeForm: {
      title: 'travel_information_title',
      text: 'travel_information_text'
    },
    fields: [
      {name: VisaFN.PASSPORT_COUNTRY, type: 'select', icon: 'location-dot', showInResume: true, validators: [Validators.required]},
      {name: VisaFN.PASSPORT_NUMBER, type: 'text', validators: [Validators.required, Validators.pattern('^[A-Za-z0-9 \\-]+$')], icon: 'passport', showInResume: true},
      {name: VisaFN.DATE_OF_ISSUE, type: 'date', validators: [Validators.required], dateValidators: {maxDate: format(endOfDay(new Date()), 'yyyy-MM-dd'), mustBeEqualOrAfter: {form: VisaFormName.PERSONAL_DETAILS, control: VisaFN.BIRTH_DAY}}, showInResume: true},
      {name: VisaFN.DATE_OF_EXPIRY, type: 'date', validators: [Validators.required], dateValidators: {minDate: format(startOfDay(new Date()), 'yyyy-MM-dd'), maxDate: format(addYears(new Date(), 10), 'yyyy-MM-dd'), maxDateMilliTo: {form: VisaFormName.TRAVEL_INFORMATION, control: VisaFN.ARRIVAL_DATE, milliseconds: milliseconds({months: 6})}}, showInResume: true},
      {name: VisaFN.PLACE_OF_ISSUE, type: 'text', icon: 'location-dot', showInResume: true, validators: []},
      {name: VisaFN.ARRIVAL_DATE, type: 'date', validators: [Validators.required], dateValidators: {maxDate: format(addYears(new Date(), 150), 'yyyy-MM-dd'), minDate: format(startOfDay(new Date()), 'yyyy-MM-dd'), mustBeEqualOrBefore: {form: VisaFormName.TRAVEL_INFORMATION, control: VisaFN.DEPARTURE_DATE}, maxDateMilliTo: {form: VisaFormName.TRAVEL_INFORMATION, control: VisaFN.DATE_OF_EXPIRY, milliseconds: milliseconds({months: 6})}}, showInResume: true},
      {name: VisaFN.COMPANIONS_SIZE, type: 'number', icon: 'users', showInResume: true},
      {name: VisaFN.DEPARTURE_DATE, type: 'date', validators: [Validators.required], dateValidators: {maxDate: format(addYears(new Date(), 150), 'yyyy-MM-dd'), minDate: format(startOfDay(new Date()), 'yyyy-MM-dd')}, showInResume: true},
      {name: VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, type: 'choice', choiceButtons: [
        {text: 'Yes', value: 'true', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
        {text: 'No', value: 'false', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
      ], validators: [Validators.required]},
      {name: VisaFN.PREVIOUS_CONVICTIONS_ZIM, type: 'text', icon: 'handcuffs', ifFormControl: VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, requiredIfFormControl: true, showInResume: true, validators: []},
      {name: VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, type: 'choice', choiceButtons: [
        {text: 'Yes', value: 'true', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: '#6fb76b', textColor: 'var(--clr-lt)', opacity: '1'}},
        {text: 'No', value: 'false', backgroundColor: 'var(--clr-lt)', textColor: 'var(--clr-dk)', opacity: '0.7', selected: {backgroundColor: 'var(--clr-btn-red)', textColor: 'var(--clr-lt)', opacity: '1'}}
      ], validators: [Validators.required]},
      {name: VisaFN.PREVIOUS_CONVICTIONS_HOME, type: 'text', icon: 'handcuffs', ifFormControl: VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, requiredIfFormControl: true, showInResume: true, validators: []}
    ]
  },
  [VisaFormName.DOCUMENT]: {
    version: 0,
    classNames: 'gap_8',
    textBeforeForm: {
      title: 'document',
      text: 'document_text'
    },
    fields: [
      {name: VisaFN.PASSPORT_PHOTO, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.PASSPORT_SCAN, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.PROOF_OF_RESIDENCE_HOST, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.ADDRESS_AT_DESTINATION, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, noAutocomplete: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.PREVIOUS_ZIMBABWEAN_VISA, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.HOST_INVITATION_LETTER, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.PROOF_OF_RESIDENCE, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.APPLICATION_LETTER, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.RESIDENTIAL_STATUS_OF_HOST, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.REASON_OF_VISIT_PROOF, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.BUSINESS_LETTER, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.BUSINESS_PROFILE, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.INVITATION_LETTER_AND_BUSINESS_PROFILE, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.LETTER_OF_ACCEPTANCE_FROM_SCHOOL, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.INVITATION_LETTER, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE},
      {name: VisaFN.EXTRA_DOCUMENT_1, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE, alwaysVisible: true},
      {name: VisaFN.EXTRA_DOCUMENT_2, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE, ifFormControlHasValue: {control: VisaFN.EXTRA_DOCUMENT_1}, alwaysVisible: true},
      {name: VisaFN.EXTRA_DOCUMENT_3, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE, ifFormControlHasValue: {control: VisaFN.EXTRA_DOCUMENT_2}, alwaysVisible: true},
      {name: VisaFN.EXTRA_DOCUMENT_4, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE, ifFormControlHasValue: {control: VisaFN.EXTRA_DOCUMENT_3}, alwaysVisible: true},
      {name: VisaFN.EXTRA_DOCUMENT_5, type: 'file', icon: 'upload', acceptType: '.png,.jpg,.jpeg,.pdf', showInResume: true, maxFileSize: 1000 * 500, action: FormAction.UPLOAD_FILE, ifFormControlHasValue: {control: VisaFN.EXTRA_DOCUMENT_4}, alwaysVisible: true}
    ]
  }
};

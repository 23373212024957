import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '../../../shared/services/api.service';
import {UserPlatformRole} from '../../../shared/models/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../../shared/services/form.service';
import {useReturnUrl} from '../../../shared/services/application.service';
import {Router} from '@angular/router';
import {NavigateService} from '../../../shared/services/navigate.service';
import {LangService, LoaderService} from 'ngx-satoris';

@Component({
  selector: 'app-dialog-daily-otp',
  templateUrl: './dialog-daily-otp.component.html',
  styleUrls: ['./dialog-daily-otp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogDailyOtpComponent {
  submitted: boolean;
  form: FormGroup;

  constructor(public api: ApiService,
    public forms: FormService,
    private router: Router,
    private nav: NavigateService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private lang: LangService,
    @Inject(MAT_DIALOG_DATA) public data: { email: string, password: string, placeId: string }) {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      char1: ['', [
        Validators.required,
        Validators.maxLength(1)
      ]],
      char2: ['', [
        Validators.required,
        Validators.maxLength(1)
      ]],
      char3: ['', [
        Validators.required,
        Validators.maxLength(1)
      ]],
      char4: ['', [
        Validators.required,
        Validators.maxLength(1)
      ]],
      char5: ['', [
        Validators.required,
        Validators.maxLength(1)
      ]],
      char6: ['', [
        Validators.required,
        Validators.maxLength(1)
      ]]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      const otp: string = this.form.get('char1').value +
                  this.form.get('char2').value +
                  this.form.get('char3').value +
                  this.form.get('char4').value +
                  this.form.get('char5').value +
                  this.form.get('char6').value;
      this.api.consumeLoginWeak(this.data.email, this.data.password, this.data.placeId, otp).then(() => {
        this.leaveToSearch();
      }, err => {
        this.submitted = false;
        this.form.reset();
        this.loader.loading(true, {type: 'error', message: err});
      });
    }
  }

  closeDialog(): void {
  }

  protected leaveToSearch() {
    return this.api.info().then(() => {
      if(this.api.userInfo.role === UserPlatformRole.ORBIS_CONSUMER) {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('no.accessPermission')});
      } else {
        this.closeDialog();
        this.loader.loading(false);
        if(this.api.getStoredItem('welcome')) {
          useReturnUrl(this.router, ['/dashboard']);
        } else {
          this.nav.redirect('welcome');
        }
      }
    }, err => {
      this.loader.loading(true, {type: 'error', message: err});
    });
  }

  getCodeBoxElement(index: any): any {
    return document.getElementById('char' + index);
  }

  onKeyUpEvent(index: any, event: any) {
    const eventCode = event?.which || event?.keyCode;
    if(this.getCodeBoxElement(index).value.length === 1) {
      if(index !== 6) {
        this.getCodeBoxElement(index+ 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
      }
    }
    if(eventCode === 8 && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  onFocusEvent(index: any) {
    for(let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);
      if(!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }
}

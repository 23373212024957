import {Router} from '@angular/router';
import {App as CapacitorApp} from '@capacitor/app';

declare const window: any;

export function useReturnUrl(router: Router, routerCommands: string[]) {
  let returnUrl: any = sessionStorage.getItem('return_url');
  sessionStorage.removeItem('return_url');
  if(returnUrl) {
    try {
      returnUrl = JSON.parse(returnUrl);
      const p = returnUrl[returnUrl.length - 1].split('?')[1];
      returnUrl[returnUrl.length - 1] = returnUrl[returnUrl.length - 1].split('?')[0];
      const querys: any = {};
      if(p) {
        const queryParts = p.split('&');
        queryParts.forEach((part: string) => {
          const parts = part.split('=');
          querys[parts[0]] = parts[1];
        });
      }
      router.navigate(returnUrl, {queryParams: querys}).then();
    } catch (e) { /* empty */ }
  } else {
    router.navigate(routerCommands).then();
  }
}

export class Application {
  constructor(protected router: Router) {
    document.addEventListener('deviceready', () => {
      CapacitorApp.addListener('backButton', ({canGoBack}) => {
        if(canGoBack) {
          window.history.back();
        } 
      });
    });
  }

  back() {
    history.back();
  }

  storeItem(key: string, value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) { /* empty */ }
  }

  getStoredItem(key: string): any {
    if(!localStorage.getItem(key) || localStorage.getItem(key) === 'undefined') return;
    return JSON.parse(localStorage.getItem(key) || 'null');
  }

  removeStoredItem(key: string) {
    localStorage.removeItem(key);
  }

  clearStoredItem() {
    localStorage.clear();
  }

  openSupport() {
    window.toggleGlpi();
  }
}

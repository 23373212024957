<div class="standby_layout">
  <div class="standby_top">
    <h1>{{'kiosk.success.title' | translate}}</h1>
    <img src="assets-projects/images/accept.png" width="200px" alt="">
    <div class="standby_desc">
      <p>{{'kiosk.success.text1' | translate}}</p>
      <p>{{'kiosk.success.text2' | translate}}</p>
    </div>
    <p><i>{{'kiosk.success.text3' | translate}}</i></p>
  </div>
  <div class="standby_bottom">
    <qrcode colorLight="none" elementType="svg" margin="0" qrdata="{{'kiosk.qr.url' | translate}}" [width]="120" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
    <button (click)="nav.to('standby-kiosk')">{{'global.close' | translate}}</button>
    <img class="standby_logo" src="assets-projects/logo-kiosk.svg" alt="Kiosk Logo">
  </div>
</div>

<div id="layout" class="f_col notice_view">
  <app-view-title *ngIf="!isLoading" [bgImage]="api.env.theme.header_background">
    <div class="flex gap_10 f_align_c">
      {{(currentNotice ? 'notice.'+currentNotice : 'notice.title') | translate}}
    </div>
  </app-view-title>
  <div class="scroll_wrapper">
    <div class="resp_wrapper">
      <div class="resp_c-50 mt_10">
        <app-paragraph *ngIf="!isLoading" textAlign="center" class="mt_10">{{(currentNotice ? 'notice.desc.'+currentNotice : 'notice.desc') | translate}}</app-paragraph>
      </div>
    </div>
    <div class="resp_wrapper mb_40">
      <div *ngIf="currentNotice === ConsumePaymentNoticeType.RESTRICTION" class="fullWidth mt_10">
        <div class="col_2">
          <form *ngIf="form" id="form" [formGroup]="form" (ngSubmit)="submit()" class="flex f_center fullWidth gap_10 mt_20">
            <div class="f_col fullWidth gap_25 mb_20">
              <app-input type="text" formCtrlName="hqReference" [formGrp]="form" label="form.hqReference" icon="hashtag"></app-input>
              <app-input type="text" formCtrlName="stationReference" [formGrp]="form" label="form.stationReference" icon="hashtag"></app-input>
              <app-input type="text" formCtrlName="fullNames" [formGrp]="form" label="form.fullNames" icon="person"></app-input>
              <app-input type="select" formCtrlName="reportLocation" [selectOptions]="restrictionReportingPostEnum" [formGrp]="form" label="form.reportLocation" icon="location-dot"></app-input>
              <app-input type="number" formCtrlName="daysUntilReport" [formGrp]="form" label="form.daysUntilReport" icon="calendar-day"></app-input>
              <app-input type="text" formCtrlName="residentialAddress" [formGrp]="form" label="form.residentialAddress" icon="location-dot"></app-input>
              <app-input type="text" formCtrlName="businessAddress" [formGrp]="form" label="form.businessAddress" icon="location-dot"></app-input>
              <app-input type="text" formCtrlName="phone" [formGrp]="form" label="form.phone" icon="phone"></app-input>
              <app-input type="text" formCtrlName="email" [formGrp]="form" label="form.email" icon="at"></app-input>
              <app-input type="text" formCtrlName="officer" [formGrp]="form" label="form.officer" icon="person-military-pointing"></app-input>
              <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(form)"></app-errors-list>
            </div>
            <div class="f_col fullWidth gap_10">
              <app-checkbox-element [formGrp]="form"
                                    *ngFor="let doc of restrictionDocumentToProduceEnum"
                                    [formCtrlName]="doc"
                                    [text]="doc | translate"
                                    (onChange)="checkIfFormControl($event, doc, 'otherMissingDocument')"></app-checkbox-element>
              <div *ngIf="showOtherDocument" class="mt_20">
                <app-input type="text" formCtrlName="otherMissingDocument" [formGrp]="form" label="form.otherMissingDocument" icon="file-circle-question"></app-input>
              </div>
            </div>
          </form>
        </div>
        <div class="resp_c-50 mt_20 mb_40 flex gap_10">
          <app-button size="xsm" color="transparent" border="true" class="fullWidth"
                      (btnClicked)="goBack()">
            {{ 'global.back' | translate }}
          </app-button>
          <app-button size="xsm" type="submit" formId="form" class="fullWidth">
            {{ (currentNotice !== ConsumePaymentNoticeType.REFUSAL ? 'global.nextPrint' : 'global.refusePrint') | translate }}
          </app-button>
        </div>
      </div>
      <div *ngIf="currentNotice === ConsumePaymentNoticeType.REPORTING" class="fullWidth mt_10">
        <div class="col_2">
          <form *ngIf="form" id="form" [formGroup]="form" (ngSubmit)="submit()" class="flex f_center fullWidth gap_10 mt_20 mb_10">
            <div class="f_col fullWidth gap_25">
              <app-input type="text" formCtrlName="hqReference" [formGrp]="form" label="form.hqReference" icon="hashtag"></app-input>
              <app-input type="text" formCtrlName="stationReference" [formGrp]="form" label="form.stationReference" icon="hashtag"></app-input>
              <app-input type="select" formCtrlName="purpose" [selectOptions]="purposes" [formGrp]="form" label="form.purpose" icon="question-circle"></app-input>
              <app-input type="text" formCtrlName="fullNames" [formGrp]="form" label="form.fullNames" icon="person"></app-input>
              <app-input type="text" formCtrlName="host" [formGrp]="form" label="form.host" icon="person"></app-input>
              <app-input type="text" formCtrlName="hostPhone" [formGrp]="form" label="form.hostPhone" icon="phone"></app-input>
              <app-input type="text" formCtrlName="addressZimbabwe" [formGrp]="form" label="form.addressZimbabwe" icon="location-dot"></app-input>
            </div>
            <div class="f_col fullWidth gap_25">
              <app-input type="text" formCtrlName="phone" [formGrp]="form" label="form.phone" icon="phone"></app-input>
              <app-input type="text" formCtrlName="email" [formGrp]="form" label="form.email" icon="at"></app-input>
              <app-input type="text" formCtrlName="address" [formGrp]="form" label="form.address" icon="location-dot"></app-input>
              <app-date-range formCtrlNameFrom="periodFrom" formCtrlNameTo="periodTo" type="date" [formGrp]="form" [minFrom]="validatorDates.minDate" [minTo]="validatorDates.minDate" [maxFrom]="validatorDates.maxDate" [maxTo]="validatorDates.maxDate" [autoMinMax]="true"></app-date-range>
              <app-input type="select" formCtrlName="reportAt" [selectOptions]="reportingReportingPostEnum" [formGrp]="form" label="form.reportAt" icon="location-dot"></app-input>
              <app-input type="date" formCtrlName="reportOn" [formGrp]="form" label="form.reportOn" [autoMinMaxDate]="true" [minDate]="validatorDates.minDate" [maxDate]="validatorDates.maxDate"></app-input>
              <app-input type="number" formCtrlName="reportInterval" [formGrp]="form" label="form.reportInterval" icon="stopwatch"></app-input>
              <app-input type="text" formCtrlName="officer" [formGrp]="form" label="form.officer" icon="person-military-pointing"></app-input>
            </div>
          </form>
          <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(form)"></app-errors-list>
          <div class="resp_wrapper">
            <div class="resp_c-50 mt_10">
              <div class="mb_40 mt_20 flex gap_10">
                <app-button size="xsm" color="transparent" border="true" class="fullWidth"
                            (btnClicked)="goBack()">
                  {{ 'global.back' | translate }}
                </app-button>
                <app-button size="xsm" type="submit" formId="form" class="fullWidth">
                  {{ 'global.nextPrint' | translate }}
                </app-button>
              </div>
          </div>
        </div>
      </div>
      </div>
      <div *ngIf="currentNotice === ConsumePaymentNoticeType.REFUSAL" class="resp_c-50 mt_30">
        <form *ngIf="form" id="form" [formGroup]="form" (ngSubmit)="submit()" class="f_col gap_25 mb_20">
          <app-input type="text" formCtrlName="fullNames" [formGrp]="form" label="form.fullNames" icon="person"></app-input>
          <app-input type="text" formCtrlName="reportLocation" [formGrp]="form" label="form.reportLocation" icon="location-dot"></app-input>
          <app-input type="select" (output)="checkIfReasonHasParagraph($event)" formCtrlName="reason" [selectOptions]="refusalReasonEnum" [formGrp]="form" label="form.reason" icon="hashtag"></app-input>
          <app-input type="text" *ngIf="showParagraph" formCtrlName="paragraph" [formGrp]="form" label="form.paragraph" icon="hashtag"></app-input>
          <app-input type="date" formCtrlName="dateOfRefusal" [formGrp]="form" label="form.dateOfRefusal" icon="calendar-day"></app-input>
          <app-input type="text" formCtrlName="place" [formGrp]="form" label="form.place" icon="location-dot"></app-input>
          <app-input type="text" formCtrlName="officer" [formGrp]="form" label="form.officer" icon="person-military-pointing"></app-input>
          <app-input type="textarea" formCtrlName="operationComments" [formGrp]="form" label="form.operationComments" icon="comment" [maxLength]="500" [textareaRows]="10"></app-input>
          <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
        </form>
        <div class="mb_40 flex gap_10">
          <app-button size="xsm" color="transparent" border="true" class="fullWidth"
                      (btnClicked)="goBack()">
            {{ 'global.back' | translate }}
          </app-button>
          <app-button size="xsm" type="submit" formId="form" class="fullWidth">
            {{ 'global.nextPrint' | translate }}
          </app-button>
        </div>
      </div>
    </div>
    <div class="parent-container mt_20 mb_40" *ngIf="!currentNotice && !isLoading">
      <div class="flex flex_col f_center gap_10 f_wrap">
        <div class="big_btn big_btn-resp" (click)="selectNotice(ConsumePaymentNoticeType.RESTRICTION)">
          <img src="assets-projects/images/error.png" alt="">
          <span>{{'notice.RESTRICTION' | translate}}</span>
          <p>{{'notice.RESTRICTION.desc' | translate}}</p>
        </div>
        <div class="big_btn big_btn-resp" (click)="selectNotice(ConsumePaymentNoticeType.REPORTING)">
          <img src="assets-projects/images/time.png" alt="">
          <span>{{'notice.REPORTING' | translate}}</span>
          <p>{{'notice.REPORTING.desc' | translate}}</p>
        </div>
      </div>
      <div class="resp_wrapper">
        <div class="resp_c-50">
          <div class="resp_minSize_l mt_40 mb_40 flex gap_10 resp_c-50">
            <app-button size="xsm" color="transparent" border="true" class="fullWidth"
                        (btnClicked)="goBack()">
              {{'global.back' | translate}}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" mb_20 resp_maxSize_l">
    <div class="f_align_end">
      <app-back-btn class="mr_10" icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
      <app-back-btn [navTo]="{route: 'admin-request-select/'+requestId}"></app-back-btn>
    </div>
  </div>
</div>
<app-print-notice-reporting [form]="form" [qrCode]="qrCode" *ngIf="currentNotice === ConsumePaymentNoticeType.REPORTING && form"></app-print-notice-reporting>
<app-print-notice-restriction [form]="form"  [qrCode]="qrCode" *ngIf="currentNotice === ConsumePaymentNoticeType.RESTRICTION && form" [documents]="docsToProducePrint()"></app-print-notice-restriction>
<app-print-notice-refusal [form]="form" [qrCode]="qrCode" *ngIf="currentNotice === ConsumePaymentNoticeType.REFUSAL && form"></app-print-notice-refusal>

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {CartService} from 'src/app/shared/services/cart.service';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';
import {RequestService} from '../../shared/services/request.service';

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TripComponent {

  constructor(public nav: NavigateService,
    public api: ApiService,
    public cart: CartService,
    public request: RequestService) {}

  protected readonly faLocationDot = faLocationDot;
}

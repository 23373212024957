import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DataGroup} from 'src/app/shared/models/request';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-print-data',
  templateUrl: './print-data.component.html',
  styleUrl: './print-data.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PrintDataComponent {
    @Input() title: string;
    @Input() dataList: DataGroup[] = [];
  protected readonly environment = environment;
}

import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';

@Component({
  selector: 'app-modified-password',
  templateUrl: './modified-password.component.html',
  styleUrls: ['./modified-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModifiedPasswordComponent {

  constructor(public nav: NavigateService, public api: ApiService) {}

}

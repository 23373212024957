import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import * as uuid from 'uuid';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../shared/services/api.service';
import {PlacesService} from '../../../shared/services/places.service';
import {Place} from '../../../shared/models/place';
import {Router} from '@angular/router';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  selector: 'app-place-create',
  templateUrl: './place-create.component.html',
  styleUrls: ['./place-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceCreateComponent implements OnInit {

  constructor(public nav: NavigateService,
    public forms: FormService,
    public request: RequestService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private api: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private lang: LangService,
    private place: PlacesService,
    private router: Router) {}

  form: FormGroup;
  submitted = false;
  allPlaces: {name: string, value: string}[] = [];
  countriesSelected: string[] = [];

  ngOnInit(): void {
    this.setForm();
    this.changeDetectorRef.detectChanges();
    this.place.getAllPlaces().then(() => {
      for(const place in this.place.allPlaces) {
        const {longName, id} = this.place.allPlaces[place] as Place;
        this.allPlaces.push({name: longName, value: id});
      }
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      id: [uuid.v1(), [
        Validators.required,
        Validators.maxLength(50)
      ]],
      parentId: [''],
      connectUAPattern: ['.*'],
      places: [''],
      connectIPRange: ['0.0.0.0/0'],
      externalId: [],
      externalCode: ['', [Validators.pattern('^[A-Z]{3,4}$')]]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.api.createPlace(this.form.get('id').value,
        this.form.get('name').value,
        this.form.get('places').value,
        this.form.get('externalCode').value, this.countriesSelected.length ? this.countriesSelected.filter((country) => country !== '') : undefined).then(() => {
        this.nav.to('place-created/' + this.form.get('id').value);
        this.loader.loading(false);
      }).catch(() => {
        this.submitted = false;
        this.loader.loading(true, {type: 'error', message: this.lang.transform('err.createplace')});
      });
    }
  }

  goToPreviousUrl() {
    this.router.navigateByUrl(localStorage.getItem('previous_url'));
  }

  toggleCountry(value: string, event: any) {
    if(event.checked) {
      this.countriesSelected.push(value);
    } else {
      this.countriesSelected = this.countriesSelected.filter(country => country !== value);
    }
  }
}

import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {Place, PlacesTable} from 'src/app/shared/models/place';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../../shared/services/api.service';
import {PlacesService} from '../../shared/services/places.service';
import {ActivatedRoute} from '@angular/router';
import {QueryService} from 'src/app/shared/services/query.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlacesComponent implements OnInit {
  data: Place[];
  filteredData: Place[];
  form: FormGroup;
  scrollState = false;
  defaultPaginatorSize = 10;
  scrollNumber = 30;
  firstCall = false;
  displayedColumns: string[] = ['id', 'longName'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<PlacesTable>([]);

  constructor(public nav: NavigateService,
    public api: ApiService,
    public query: QueryService,
    public places: PlacesService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.loader.loading(true);
    this.setForm();
    this.places.getAllPlaces(this.scrollNumber - this.scrollNumber, this.scrollNumber).then(() => {
      this.loader.loading(false);
      this.firstCall = true;
      this.filteredData = this.places.allPlaces;
      this.updateDataSource();
      this.route.queryParams.subscribe((params: any) => {
        this.applyFilter(params.search);
        this.form.controls.text.setValue(params.search);
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      text: ['', []]
    });
  }

  applyFilter(filterValue: string) {
    if(filterValue) {
      const filterArray = this.places.allPlaces.filter((data: any) => this.query.filterData(undefined, data.longName, filterValue) || this.query.filterData(undefined, data.id, filterValue, true));
      this.filteredData = filterArray;
      this.updateDataSource();
    } else {
      this.filteredData = this.places.allPlaces;
      this.updateDataSource();
    }
  }

  onScroll(event: any) {
    this.scrollState = true;
    if(this.filteredData?.length !== this.places.maxPlace) {
      if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this.scrollNumber += this.scrollNumber;
        this.loader.loadingElement('bottom', true, 'xs');
        setTimeout(() =>{
          this.places.getAllPlaces(this.scrollNumber - this.scrollNumber, this.scrollNumber).then(() => {
            this.scrollState = false;
            this.applyFilter(this.route.snapshot.params.search);
            this.loader.loadingElement('bottom', false);
          });
        }, 1000);
      }
    }
  }

  updateDataSource() {
    const transformedData = this.filteredData.map(request => ({
      id: request.id,
      longName: request.longName
    }));
    this.dataSource = new MatTableDataSource<PlacesTable>(transformedData as any);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

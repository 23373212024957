<div id="layout" class="admin_payments f_col">
  <app-view-title class="" fromRoute="pre-enroll" [chooseLangBtn]="false">{{'menu.requests.DYN.'+api.env.type | translate}}</app-view-title>
  <div class="scroll_wrapper" (scroll)="onScroll($event)">
    <div class="request-container" [style.visibility]="!firstCall? 'hidden' : 'visible'">
      <form class="leftSide-container resp_minSize_l" [class.dropdown-open]="dropDown" [formGroup]="form">
        <p class="title-leftSide">{{'form.search' | translate}}</p>
        <app-input [formGrp]="form" icon="magnifying-glass" type="text" formCtrlName="search" class="fullWidth mb_15" [label]="onlyVisa ? ('form.name' | translate)  + ', ' + ('form.passportNumber' | translate) + ', ' + ('travelName' | translate) : ('form.name' | translate) + ', #id, email@mail.com'"  (input)="query.inputQuery('search', $event)" [matTooltip]="api.isZwevisa ? ('tooltip.search' | translate) : ''"></app-input>
        <app-input class="mt_20" *ngIf="onlyVisa" icon="hashtag" type="text" [formGrp]="form" formCtrlName="internalReference" label="{{'internalReference' | translate}}" (input)="query.inputQuery('internalReference', $event)" [matTooltip]="api.isZwevisa ? ('tooltip.internalReference' | translate) : ''"></app-input>
        <div class="fullWidth">
          <div class="mt_24 flex fullWidth gap_10">
            <app-input *ngIf="!onlyVisa" type="number" [formGrp]="form" formCtrlName="userId" label="{{'form.userId' | translate}}"  (input)="query.inputQuery('userId', $event)"></app-input>
            <app-input [formGrp]="form" type="select" formCtrlName="state" label="{{'dropdown.state' | translate}}" icon="list" [selectOptions]="stateFilterOption" (change)="stateChange($event)" class="fullWidth" [matTooltip]="api.isZwevisa ? ('tooltip.state' | translate) : ''"></app-input>
          </div>
        </div>
        <app-input class="mt_24" [formGrp]="form" type="select" formCtrlName="nationality" label="{{'nationality' | translate}}" icon="list" [selectOptions]="request.getNationalities(true)" (change)="nationalityChange($event)" [matTooltip]="api.isZwevisa ? ('tooltip.nationality' | translate) : ''"></app-input>

        <div class="mt_24" [matTooltip]="api.isZwevisa ? ('tooltip.createdDate' | translate) : ''">
          <app-date-range (onChange)="changeDate($event, true)" [formGrp]="form" formCtrlNameFrom="start" formCtrlNameTo="end"></app-date-range>
        </div>
        <div class="mt_24 mb_20 f_between">
          <app-input class="fullWidth" [formGrp]="form"  type="select" formCtrlName="since" label="{{'dropdown.since' | translate}}" icon="clock-rotate-left" [selectOptions]="requestSinceOption" (change)="changeSince($event)" [matTooltip]="api.isZwevisa ? ('tooltip.createdDateSince' | translate) : ''"></app-input>
        </div>
        <div class="mb_20" *ngIf="onlyVisa" [matTooltip]="api.isZwevisa ? ('tooltip.overstay' | translate) : ''">
          <app-checkbox-element class="fullWidth"
                          (onChange)="!overstayCliked && changeOverStay()"
                          [disableClick]="overstayCliked"
                          [formGrp]="form"
                          [formCtrlName]="'overstay'"
                          [id]="'overstay'"
                          [text]="'overstay'"></app-checkbox-element>
        </div>
        <app-button class="mb_5" size="xsm" (btnClicked)="clearAllFilters()" [matTooltip]="api.isZwevisa ? ('tooltip.clearAllFilters' | translate) : ''">{{'payments.clearFilters' | translate}}</app-button>
        <app-paragraph class="fullWidth resp_minSize_l" textAlign="center" *ngIf="filteredData?.length > 0" [matTooltip]="api.isZwevisa ? ('tooltip.filterLength' | translate) : ''">{{('payments.count.DYN.' + api.env.type) | translate}} {{totalCount}}</app-paragraph>
        <app-divider class="mt_4 mb_10"></app-divider>
        <app-button class="mb_10" size="xsm" (btnClicked)="downloadCSV()" [matTooltip]="api.isZwevisa ? ('tooltip.downloadCsv' | translate) : ''">{{'payments.download_csv' | translate}}</app-button>
        <app-slide-element bgColor="#FFFFFF" class="mb_10" leftIcon="qrcode" (btnClicked)="nav.to('scan-qr')" [matTooltip]="api.isZwevisa ? ('tooltip.Qr' | translate) : ''">{{'dropdown.qr' | translate}}</app-slide-element>
        <app-slide-element bgColor="#FFFFFF" *ngIf="api.hasPerm(api.perm.ALLOW_CONTROL)" leftIcon="table-list" [disabled]="!api.isOnline" [colorRight]="!api.isOnline && '#f51d2a'" [rightIcon]="!api.isOnline ? 'wifi' : 'arrow-right'" class="mb_10" (btnClicked)="goToAllQr()" [matTooltip]="api.isZwevisa ? ('tooltip.listQr' | translate) : ''">{{'see.qr_list' | translate}}</app-slide-element>
      </form>

      <div class="table-container">
        <div class="rightSide-container">
          <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortDirection.active" [matSortDirection]="sortDirection.direction" matSortDisableClear (matSortChange)="onSortChanged($event)">

            <ng-container matColumnDef="passportNumber" *ngIf="onlyVisa">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tablePassportTitle' | translate) : ''">{{'global.passportNum' | translate}}</th>
              <td [matTooltip]="api.isZwevisa ? ('tooltip.tablePassport' | translate) : ''" mat-cell *matCellDef="let element">{{element.passportNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="userId">
              <th mat-header-cell *matHeaderCellDef> {{'form.userId' | translate}} </th>
              <td mat-cell *matCellDef="let element">{{element.userId}}</td>
            </ng-container>

            <ng-container matColumnDef="usageAfter">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableUsageAfterTitle' | translate) : ''"> {{'form.usageAfter' | translate}} </th>
              <td mat-cell *matCellDef="let element" [class.expiring-date]="!request.isUsageDateValid(element.usageAfter, 'usageAfter')" [matTooltip]="api.isZwevisa ? ('tooltip.tableUsageAfter' | translate) : ''">{{element.usageAfter | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <ng-container matColumnDef="usageUntil">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableUsageUntilTitle' | translate) : ''"> {{'form.usageUntil' | translate}} </th>
              <td mat-cell *matCellDef="let element" [class.expiring-date]="!request.isUsageDateValid(element.usageUntil, 'usageUntil')" [matTooltip]="api.isZwevisa ? ('tooltip.tableUsageUntil' | translate) : ''">{{element.usageUntil | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>

            <ng-container matColumnDef="nationality">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableNationalityTitle' | translate) : ''"> {{'form.nationality' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tableNationalityTitle' | translate) : ''">{{element.nationality ? ('DYN.'+ element.nationality | translate) : ''}}</td>
            </ng-container>

            <ng-container matColumnDef="idCard" *ngIf="!onlyVisa">
              <th mat-header-cell *matHeaderCellDef> {{'idcard.number' | translate}} </th>
              <td mat-cell *matCellDef="let element">{{element.idCard}}</td>
            </ng-container>

            <ng-container matColumnDef="email" *ngIf="!onlyVisa">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>

            <ng-container matColumnDef="idPlace" *ngIf="!onlyVisa">
              <th mat-header-cell *matHeaderCellDef> {{'form.createPlaceId' | translate}} </th>
              <td mat-cell *matCellDef="let element">{{element.created_place_id}}</td>
            </ng-container>

            <ng-container matColumnDef="completeName">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableCompleteNameTitle' | translate) : ''"> {{'completeName' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tableCompleteName' | translate) : ''">{{element.completeName}}</td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableStateTitle' | translate) : ''"> {{'text.status' | translate}} </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isUrgent" class="state-color" [style.backgroundColor]="element.iconColor"></div>
                <fa-icon class="state-color" *ngIf="element.isUrgent" icon="triangle-exclamation" [style.color]="element.iconColor"></fa-icon>
                <span [matTooltip]="api.isZwevisa ? ('tooltip.tableState' | translate) : ''">
                  {{element.state}}
                </span>
                <fa-icon *ngIf="request.requestHasBeenCorrected(element)" [icon]="faSpellCheck" class="ml_4" [matTooltip]="'tooltip.hasBeenCorrected' | translate"></fa-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tablePriceTitle' | translate) : ''"> {{'price' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tablePrice' | translate) : ''"> {{element.currency +  element.price}} </td>
            </ng-container>

            <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear [matTooltip]="api.isZwevisa ? ('tooltip.tableCreationDateTitle' | translate) : ''">{{'date.creation' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tableCreationDate' | translate) : ''"> {{element.creationDate | date: 'dd/MM/yyyy HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="internalReference">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableInternalReferenceTitle' | translate) : ''">{{'internalReference' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tableInternalReference' | translate) : ''"> {{element.internalReference}} </td>
            </ng-container>

            <ng-container matColumnDef="batchId">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableBatchIdTitle' | translate) : ''">{{'travelName' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tableBatchId' | translate) : ''"> {{element.batchId}} </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableIdTitle' | translate) : ''">{{'id.payment' | translate}} </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? ('tooltip.tableId' | translate) : ''"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="lock">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="api.isZwevisa ? ('tooltip.tableLockTitle' | translate) : ''">
                <fa-icon
                icon="book-open-reader">
              </fa-icon>
              </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="api.isZwevisa ? (('tooltip.tableLock' + (element.assignedUserId === api.userInfo.id ? '.me' : '.other')) | translate: {officer: (sync.userDisplay(element.assignedUserId) +' #'+ element.assignedUserId)}) : ''">
                <ng-container *ngIf="element.lock">
                  <fa-icon
                    icon="book-open-reader"
                    [style.color]="element.assignedUserId === api.userInfo.id ? '#489F46' : '#FF823C'">
                  </fa-icon>
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="navigateWithParams(row)"></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                       [pageSize]="defaultPaginatorSize"
                       (page)="onPageChange($event)"
                       aria-label="Select page of periodic elements"
                       [matTooltip]="api.isZwevisa ? ('tooltip.tableNumberOfPage' | translate) : ''">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="btns-container resp_maxSize_l search-btn">
    <app-back-btn icon="house" [navTo]="{route: 'dashboard'}"></app-back-btn>
    <app-back-btn class="ml_10" icon="{{dropDown ? 'xmark' : 'magnifying-glass'}}"  (btnClicked)="dropDown = !dropDown"></app-back-btn>
  </div>
  <form class="dropdown f_col fullWidth resp_maxSize_l" [class.dropdown-open]="dropDown" [formGroup]="form">
    <p class="title-leftSide-mobile">{{'form.search' | translate}}</p>
      <app-input [formGrp]="form" type="text" formCtrlName="search" class="fullWidth" [label]="onlyVisa ? ('form.name' | translate)  + ', ' + ('form.passportNumber' | translate) + ', ' + ('travelName' | translate) : ('form.name' | translate) + ', #id, email@mail.com'" (input)="query.inputQuery('search', $event)"></app-input>
      <div class="ml_10 fullWidth">
    </div>
    <app-input class="mt_10" *ngIf="onlyVisa" type="text" [formGrp]="form" formCtrlName="internalReference" label="{{'internalReference' | translate}}"  (input)="query.inputQuery('internalReference', $event)"></app-input>
    <div class="mt_24 flex gap_10">
      <app-input *ngIf="!onlyVisa"  type="number" [formGrp]="form" formCtrlName="userId" label="{{'form.userId' | translate}}"  (input)="query.inputQuery('userId', $event)"></app-input>
      <div class=" fullWidth">
        <app-input [formGrp]="form"  type="select" formCtrlName="paymentState" label="{{'dropdown.state' | translate}}" icon="list" [selectOptions]="stateFilterOption" (change)="stateChange($event)"></app-input>
      </div>
    </div>
    <div class="mt_24">
      <app-date-range (onChange)="changeDate($event, true)" [formGrp]="form" formCtrlNameFrom="start" formCtrlNameTo="end"></app-date-range>
    </div>
    <div class="mt_24 f_between mb_20">
      <app-input class="fullWidth" [formGrp]="form" type="select" formCtrlName="since" label="{{'dropdown.since' | translate}}" icon="clock-rotate-left" [selectOptions]="requestSinceOption" (change)="changeSince($event)"></app-input>
    </div>
    <div class="mb_20" *ngIf="onlyVisa" [matTooltip]="api.isZwevisa ? ('tooltip.overstay' | translate) : ''">
      <app-checkbox-element class="fullWidth"
                      (onChange)="!overstayCliked && changeOverStay()"
                      [disableClick]="overstayCliked"
                      [formGrp]="form"
                      [formCtrlName]="'overstay'"
                      [id]="'overstay'"
                      [text]="'overstay'"></app-checkbox-element>
    </div>
    <app-paragraph class="fullWidth resp_maxSize_l" textAlign="center" *ngIf="filteredData?.length > 0" [matTooltip]="api.isZwevisa ? ('tooltip.filterLength' | translate) : ''">{{('payments.count.DYN.' + api.env.type) | translate}} {{totalCount}}</app-paragraph>
    <app-divider class="mt_10 mb_10"></app-divider>
    <div class="mt_4 mb_10">
      <app-button size="sm" (btnClicked)="clearAllFilters()">{{'payments.clearFilters' | translate}}</app-button>
    </div>
    <app-button class="mb_5" size="sm" (btnClicked)="downloadCSV()">{{'payments.download_csv' | translate}}</app-button>
    <app-slide-element bgColor="#FFFFFF" class="mb_10" leftIcon="qrcode" (btnClicked)="nav.to('scan-qr')" [matTooltip]="api.isZwevisa ? ('tooltip.Qr' | translate) : ''">{{'dropdown.qr' | translate}}</app-slide-element>
  </form>
</div>

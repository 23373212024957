import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSatorisModule} from 'ngx-satoris';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSatorisModule
  ],
  providers: [

  ]
})
export class PassModule { }

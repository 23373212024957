import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {Place} from '../../shared/models/place';
import {ApiService} from '../../shared/services/api.service';
import {DocumentType, UserPermission, UserPlatformRole} from '../../shared/models/user';
import {SyncService} from 'src/app/shared/services/sync.service';
import {DialogDailyOtpComponent} from '../../components/dialogs/dialog-daily-otp/dialog-daily-otp.component';
import {DialogsService} from '../../shared/services/dialog.service';
import {PlacesService} from '../../shared/services/places.service';
import {ActivatedRoute} from '@angular/router';

declare const window: Window & {initialQueryString: string[][]};

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  connectionAd = false;
  secondStep: 'local' | 'ad';
  selectedPlace = '';
  params: {
    email: string;
    password: string;
  };
  places: any;
  placesSO:  {name: string, value: string}[] = [];
  internal: boolean;

  constructor(public nav: NavigateService,
              public forms: FormService,
              public api: ApiService,
              private sync: SyncService,
              private formBuilder: FormBuilder,
              private lang: LangService,
              private loader: LoaderService,
              private dialog: DialogsService,
              public place: PlacesService,
              private route: ActivatedRoute) {
    const internalHolder = window.initialQueryString.find((q: string[]) => q[0] === 'internal');
    if(this.api.isElectronApp || internalHolder) {
      this.internal = true;
    }
    this.connectionAd = this.internal && this.api.isZwevisa;
  }

  ngOnInit(): void {
    this.setForm();
    this.route.queryParams.subscribe((params: any) => {
      const {email, password} = params;
      this.params = {email, password};
      if(email && password) {
        this.loader.loading(true);
        this.loginWeak(email, password);
      }
    });
  }

  setForm() {
    this.form = this.formBuilder.group({
      email: ['', [
        Validators.required,
        ...(this.connectionAd ? [] : [Validators.email])
      ]],
      password: ['', [
        Validators.required
      ]],
      place: ['', []]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.loginWeak(undefined, undefined, this.connectionAd);
    }
  }

  loginWeak(email?: string, password?: string, adConnection = false): boolean {
    const accountName = email || this.form.get('email').value;
    this.api.consumeLoginWeak(accountName, password || this.form.get('password').value, this.api.userPlaceId || this.form.get('place').value,
      undefined, adConnection && this.secondStep !== 'local').then(([local, places]) => {
      if(this.api.userPlaceId) {
        if(this.api.isOnline) {
          this.sync.syncAll();
        }
        this.leaveToSearch();
      } else {
        if(!this.secondStep) {
          if(!local) {
            this.loader.loading(false);
            this.placesSO = (places as string[]).map(place => ({name: place, value: place}));
            this.secondStep = 'ad';
            this.toggleFormControls();
          } else {
            this.api.info().then(() => {
              this.loader.loading(false);
              if(this.api.userInfo?.links?.length > 0 && !this.api.userRole.isCustomer) {
                this.place.getAllPlacesByPerson(this.api.userInfo).then(() => {
                  for(const place in this.place.allPlacesByPerson) {
                    const {longName, id} = this.place.allPlacesByPerson[place] as Place;
                    this.placesSO.push({name: longName, value: id});
                  }
                }).catch(err => {
                  if(err === 'err.user: client.extended.wrongAuth') {
                    this.secondStep = undefined;
                    this.loader.loading(true, {type: 'error', message: 'This account has no access permission'});
                  }
                });
                this.secondStep = 'local';
                this.toggleFormControls();
              } else {
                this.loader.loading(false);
                if(this.api.getStoredItem('welcome') || this.api.env.type === DocumentType.ZWEVISA || this.api.userInfo.role === UserPlatformRole.KIOSK) {
                  this.nav.to('dashboard');
                } else if(this.api.userRole.isCustomer) {
                  this.nav.to('welcome-hello');
                }
              }
            }, error => {
              if(this.api.userRole.isOrbis || this.api.userRole.isPartnerRegister) {
                this.submitted = false;
                this.loader.loading(true, {type: 'error', message: 'This account has no access permission'});
              } else {
                this.submitted = false;
                this.loader.loading(true, {type: 'error', message: error});
              }
            });
          }
        }
      }
    }, err => {
      if(err?.includes(this.lang.transform('client.extended.clientBadOtp'))) {
        this.submitted = false;
        this.loader.loading(false);
        this.openOtp();
      } else {
        if(err?.includes(this.lang.transform('client.extended.placeNotAllowed'))) {
          if(this.api.isOnline) this.api.removeStoredItem('placeId');
          this.api.userPlaceId = '';
          this.loader.loading(false);
          this.loginWeak(undefined, undefined, this.connectionAd);
        } else {
          this.loader.loading(true, {type: 'error', message: err});
        }
        this.submitted = false;
      }
    });
    return true;
  }

  openOtp() {
    const accountName = this.form.get('email').value;
    const data: { email: string, password: string, placeId: string } = {
      email: accountName,
      password: this.form.get('password').value,
      placeId: this.api.userPlaceId || this.form.get('place').value
    };
    this.dialog.openDialog(DialogDailyOtpComponent, 'sm', data);
  }

  protected leaveToSearch() {
    return this.api.info().then(() => {
      this.sync.cacheUsers();
      if(this.api.userRole.isOrbis || this.api.userRole.isPartnerRegister) {
        this.loader.loading(true, {type: 'error', message: 'This account has no access permission'});
      } else {
        this.loader.loading(false);
        if(this.api.getStoredItem('welcome') || this.api.userInfo.role !== UserPlatformRole.CUSTOMER) {
          if(this.api.userRole.isWorker && (this.api.hasPerm(UserPermission.ALLOW_CONTROL) && !this.api.hasPerm(UserPermission.ALLOW_ADMIN) && !this.api.hasPerm(UserPermission.ALLOW_CONSUME))) {
            this.nav.to('admin-request');
          } else {
            this.nav.to('dashboard');
          }
        } else {
          this.nav.to('welcome');
        }
      }
    }, err => {
      this.loader.loading(true, {type: 'error', message: err});
    });
  }

  selectChange(event: any) {
    this.selectedPlace = event.target.value;
  }

  cancel() {
    this.secondStep = undefined;
    this.selectedPlace = '';
    this.submitted = false;
    this.placesSO = [];
    this.api.flexibleUri = this.api.env.baseUri;
    this.toggleFormControls();
    this.setForm();
    clearInterval(this.api.pingInterval);
    this.api.pingInterval = undefined;
  }

  toggleFormControls() {
    if(this.secondStep) {
      this.form.get('email').disable();
      this.form.get('password').disable();
    } else {
      this.form.get('email').enable();
      this.form.get('password').enable();
    }
  }

  changeConnection(event: any) {
    this.connectionAd = event;
    if(this.connectionAd) {
      this.form.controls.email.removeValidators([Validators.email]);
    } else {
      this.form.controls.email.addValidators([Validators.email]);
    }
    this.form.controls.email.updateValueAndValidity();
  }
}

/**
 * truncate string if more than parameter
 * @param {string} string
 * @param {number} n
 * @param {boolean} reverse
 * @returns
 */
export const truncateString = (string: string, n: number, reverse = false) => {
  if(string) {
    if(!reverse) {
      return string.length > n ? string.substring(0, n)  : string;
    } else {
      return string.length > n ? string.substring(string.length  - n, string.length)  : string;
    }
  }
};


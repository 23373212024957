import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlacesService} from '../../../shared/services/places.service';
import {Place} from '../../../shared/models/place';
import {ApiService} from '../../../shared/services/api.service';
import {DocumentType, UserPermission, UserPlatformRole} from '../../../shared/models/user';
import {environment} from 'src/environments/environment';
import {RequestService} from '../../../shared/services/request.service';

@Component({
  selector: 'app-place-select',
  templateUrl: './place-select.component.html',
  styleUrls: ['./place-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceSelectComponent implements OnInit {

  placeId: string;
  canEdit = false;
  form: FormGroup;
  submitted = false;
  UserPlatformRole = UserPlatformRole;
  disabled = true;
  placesSO: {name: string, value: string}[] = [];
  countriesSelected: string[] = [];

  constructor(private route: ActivatedRoute,
    public api: ApiService,
    public request: RequestService,
    public nav: NavigateService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    public forms: FormService,
    private lang: LangService,
    public place: PlacesService,
    private changeDetectorRef: ChangeDetectorRef) {
    this.placeId= this.route.snapshot.paramMap.get('placeId');
  }

  ngOnInit() {
    this.place.getAllPlaces().then(() => {
      this.placesSO = this.transformSelect(this.place.allPlaces);
      this.place.getPlace(this.placeId).then(() => {
        this.setForm();
        this.changeDetectorRef.detectChanges();
      });
    });

    this.canEdit = (this.api.userPlaceId === this.placeId && this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_ADMIN)) || this.api.userRole.isAdmin;
  }

  setForm() {
    this.form = this.formBuilder.group({
      name: [{value: this.place?.currentPlace?.longName, disabled: this.disabled}, [Validators.required, Validators.maxLength(50)]],
      phoneNumbersCsv: [{value: this.place?.currentPlace?.phoneNumbersCsv, disabled: this.disabled}, [Validators.maxLength(20)]],
      connectUAPattern: [{value: this.place?.currentPlace?.connectUAPattern, disabled: this.disabled}, [Validators.required]],
      connectIPRange: [{value: this.place?.currentPlace?.connectIPRange, disabled: this.disabled}, [Validators.required]],
      id: [{value: this.place?.currentPlace?.id, disabled: this.disabled}, [Validators.required]],
      idParent: [{value: this.place?.currentPlace?.parent_id, disabled: this.disabled}, []],
      dailyOtp: ['', []],
      externalCode: [{value: this.place?.currentPlace?.externalCode, disabled: this.disabled}, [Validators.pattern('^[A-Z]{3,4}$')]]
    });

    this.place.currentPlace?.specifiedNationals?.forEach((national) => {
      this.toggleCountry(national, {checked: true});
    });
  }

  toggleChange() {
    for(const key in this.form.controls) {
      if(key !== 'id') {
        this.disabled ? this.form.controls[key].enable() : this.form.controls[key].disable();
      }
    }
    this.disabled = !this.disabled;
  }

  updatePlace() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      this.loader.loading(true);
      const place: Place = {
        longName: this.form.get('name').value,
        phoneNumbersCsv: this.form.get('phoneNumbersCsv')?.value ? this.form.get('phoneNumbersCsv').value : '',
        connectUAPattern: this.form.get('connectUAPattern')?.value ? this.form.get('connectUAPattern').value : '.*',
        connectIPRange: this.form.get('connectIPRange')?.value ? this.form.get('connectIPRange').value : '0.0.0.0/0',
        parent_id: this.form.get('idParent')?.value ? this.form.get('idParent').value : '',
        id: this.place?.currentPlace?.id,
        externalCode: this.form.get('externalCode')?.value ? this.form.get('externalCode').value : '',
        specifiedNationals: this.countriesSelected.length ? this.countriesSelected.filter((country) => country !== '') : undefined
      };
      this.api.updatePlace(place).then(() => {
        this.loader.loading(true, {type: 'info', message: this.lang.transform('place.update.success')}).then(() => {
          this.toggleChange();
        });
      }).catch(() => {
        this.loader.loading(true, {type: 'error', message: 'place.update.error'});
      });
    }
  }

  deletePlace() {
    this.loader.loading(true, {type: 'info', message: this.lang.transform('place.delete'), btnLabel: this.lang.transform('global.confirm')}).then((done: boolean) => {
      if(done) {
        this.api.deletePlace(this.placeId).then(() => {
          this.loader.loading(true, {type: 'info', message: this.lang.transform('place.delete.success')});
          if(this.placeId === this.api.userPlaceId) {
            this.api.signOut();
          } else {
            this.nav.to('places');
          }
        }).catch((err) => {
          this.loader.loading(true, {type: 'error', message: err});
        });
      }
    });
  }

  navBack() {
    this.nav.to(localStorage.getItem('previous_url'));
  }

  transformSelect(arrayObj: any) {
    const arrayNew : any[]= [];
    arrayObj.forEach((obj: Place) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arrayNew.push({name: obj.longName, value: obj.id});
    });
    return arrayNew;
  }

  toggleCountry(value: string, event: any) {
    if(event.checked) {
      this.countriesSelected.push(value);
    } else {
      this.countriesSelected = this.countriesSelected.filter(country => country !== value);
    }
  }
  isSelected(value: string) {
    return this.countriesSelected.includes(value);
  }

  protected readonly environment = environment;
  protected readonly DocumentType = DocumentType;

}

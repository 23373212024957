import {ModelInterface} from './entity';

export enum PersonLinkType {
    UNKNOWN = 0,
    CHILD = 1,
    PARENT = 2,
    SPOUSE = 3,
    OTHER = 4,
    SELF = 5
}

export interface Person extends ModelInterface<number> {
    firstName: string;
    lastName: string;
    nationalNumber: string;
    batchIds: string[];
    passportNumber: string;
    id: number;
    linkType: PersonLinkType;
    otherLinkType: string;
}

import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {endOfDay, endOfToday, format, parseISO, startOfDay, startOfToday, sub, parse, differenceInMinutes} from 'date-fns';
import {ngxCsv} from 'ngx-csv';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {QueryService} from 'src/app/shared/services/query.service';
import {CurrencySign, RequestFilter, RequestState, RequestStatus, Request, RequestTable, SinceTime, RequestStep, RequestWithDuplicata, RequestSubState} from '../../shared/models/request';
import {ApiService} from '../../shared/services/api.service';
import {enumToArrayObject, enumStringToArrayObject} from '../../shared/utils/enumToArrayObject';
import * as uuid from 'uuid';
import {DocumentType, UserPermission} from '../../shared/models/user';
import {parseIndexedData, parseJson, parseMetadata} from 'src/app/shared/utils/parseMetadata';
import {environment} from 'src/environments/environment';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {RequestService} from 'src/app/shared/services/request.service';
import {ScanDocumentService} from 'src/app/shared/services/scan-document.service';

@Component({
  selector: 'app-admin-payment',
  templateUrl: './admin-request.component.html',
  styleUrls: ['./admin-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminRequestComponent implements OnInit {

  constructor(public loader: LoaderService,
    public nav: NavigateService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    public query: QueryService,
    private lang: LangService,
    public device: DevicesService,
    public request: RequestService,
    public scanDocument: ScanDocumentService) {
    this.userId = +this.router.getCurrentNavigation().extras.state;

    const visaColumns = ['lock', 'internalReference', 'batchId', 'completeName', 'passportNumber', 'state', 'creationDate', 'price', 'usageAfter', 'usageUntil', 'nationality', 'id'];
    const passportColumns = ['userId', 'idCard', 'completeName', 'state', 'email', 'price', 'idPlace', 'creationDate'];
    if(this.onlyVisa) {
      this.displayedColumns = visaColumns;
      if(!this.api.hasPerm(UserPermission.ALLOW_CONTROL)) {
        this.displayedColumns = this.displayedColumns.filter(x => x !== 'lock');
      }
    } else {
      this.displayedColumns = passportColumns;
    }
  }

  form: FormGroup;
  scrollNumber = 10;
  count: number;
  processDate = true;
  filteredData: RequestWithDuplicata[] = [];
  firstCall = false;
  newFilter = true;
  currentIndex = 0;
  data: Request[] = [];
  defaultPaginatorSize = 10;
  overstayCliked = false;
  scanPassportMode = false;
  timer: NodeJS.Timeout;
  dataFromDates: any;
  dropDown = false;
  userId: number;
  totalCount: number;
  resScan: any;
  state = RequestState;
  requestFilterOption = enumStringToArrayObject(RequestFilter);
  requestSinceOption = enumStringToArrayObject(SinceTime, true);
  stateFilterOption = enumToArrayObject(RequestStatus, true).filter(item => {
    if(this.api.env.type === DocumentType.PASS) {
      return Number(item.value) <= 6;
    } else {
      return true;
    }
  }).flatMap((originalState: any) => {
    if(this.api.env.type === DocumentType.PASS) {
      return originalState;
    }
    if(+originalState.value === RequestState.PAID_NOT_CONFIRMED) {
      const newStates = [
        {name: RequestState.PAID_NOT_CONFIRMED + RequestSubState.REVISIONS_0, value: (originalState.value + '.' + RequestSubState.REVISIONS_0)},
        {name: RequestState.PAID_NOT_CONFIRMED + RequestSubState.REVISIONS_1, value: (originalState.value + '.' + RequestSubState.REVISIONS_1)},
        {name: RequestState.PAID_NOT_CONFIRMED + RequestSubState.REVISIONS_2, value: (originalState.value + '.' + RequestSubState.REVISIONS_2)}
      ];
      return newStates;
    }
    if(+originalState.value === RequestState.USED) {
      const newStates = [
        {name: RequestState.USED, value: (originalState.value + '.' + 0)},
        {name: RequestState.USED + RequestSubState.CHECKED_IN, value: (originalState.value + '.' + RequestSubState.CHECKED_IN)},
        {name: RequestState.USED + RequestSubState.CHECKED_OUT, value: (originalState.value + '.' + RequestSubState.CHECKED_OUT)},
        {name: RequestState.USED + RequestSubState.REFUSED_IN, value: (originalState.value + '.' + RequestSubState.REFUSED_IN)},
        {name: RequestState.USED + RequestSubState.REFUSED_OUT, value: (originalState.value + '.' + RequestSubState.REFUSED_OUT)},
        {name: RequestState.USED + RequestSubState.CLOSED, value: (originalState.value + '.' + RequestSubState.CLOSED)}
      ];
      return newStates;
    }
    return originalState;
  });
  hasFilter: boolean;
  onlyVisa = environment.type === DocumentType.ZWEVISA;
  params: any;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<RequestTable>([]);
  CurrencySign = CurrencySign;
  parseJson = parseJson;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.setForm();
    this.route.queryParams.pipe().subscribe((params: any) => {
      this.hasFilter = Object.keys(params).length > 0;
      if(this.api.isOnline) {
        this.params = params;
        this.filteredData = [];
        this.loader.loading(true);
        return this.launchFilter().then(()=>{
          this.loader.loading(false);
          this.overstayCliked = false;
          if(this.scanPassportMode) {
            this.scanPassportMode = false;
            return this.scanDocument.scanPassportAction(this.filteredData, 'admin-request', this.params);
          }
        }).catch(() => {
          this.loader.loading(false);
        });
      }
    });
    if(Object.keys(this.params).length === 0 && this.api.env.type === DocumentType.ZWEVISA && this.api.userRole?.isWorker) {
      const seniority = this.api.checkSeniority();
      if(this.api.hasPerm(this.api.perm.ALLOW_CONTROL)) {
        this.query.addParams({state: RequestState.PAID_NOT_CONFIRMED, subState: seniority > 0 ? seniority - 1 : 0});
      } else if(this.api.hasPerm(this.api.perm.ALLOW_CONSUME)) {
        this.query.addParams({since: SinceTime.TWO_MONTHS, dateFrom: sub(startOfToday(), {months: 2}).toISOString(), dateTo: endOfToday().toISOString()});
      }
    }
    this.stateFilterOption.map(filter => filter.name = !filter.value ? this.lang.transform('global.noFilter') : this.lang.transform('payment_status.DYN.' + this.api.env.type + '.' + filter.value));
    this.requestSinceOption.map(x => x.name = !x.value ? this.lang.transform('global.noFilter') : this.lang.transform(x.value));
    if(environment.type === DocumentType.ZWEVISA) {
      this.stateFilterOption = this.stateFilterOption.filter(x => x.name !== this.lang.transform('request_state.' + RequestStatus[RequestState.DUPLICATED]) && x.name !== this.lang.transform('request_state.' + RequestStatus[RequestState.REFUNDED]));
    }
  }

  setForm() {
    this.form = this.formBuilder.group({
      search: ['', []],
      start: [endOfToday(), []],
      end: [Date, []],
      overstay: [false, []],
      userId: [this.userId ? this.userId : '', []],
      internalReference: ['', []],
      createPlaceId: ['', []],
      state: ['', []],
      paymentState: ['', []],
      since: ['', []]
    });
  }

  onScroll(event: any) {
    if(event.target.offsetHeight + event.target.scrollTop + 50 >= event.target.scrollHeight) {
      if(this.filteredData.length < this.count) {
        this.loader.loadingElement('bottom', true, 'sm');
        setTimeout(() => {
          this.scrollNumber += 10;
          this.loader.loading(false);
          this.filterAll(this.params);
        }, 1000);
      }
    }
  }

  filterAll(params: any, offset?: number) {
    //if search is UUID
    if(params.search && uuid.validate(params.search)) {
      if(!this.onlyVisa) {
        return this.api.getRequest(params.search).then((payment: any) => {
          this.loader.loading(false);
          if(!payment || payment?.result.length === 0) {
            this.filteredData = [];
            return this.updateDataSource();
          }
          params.search && this.form.controls.search.setValue(params.search);
          this.filteredData = payment.result;
          this.updateDataSource();
          return this.filterByOperation(this.filteredData);
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: this.firstCall ? 'filterPayment.error' : 'filterPaymentNoFirst.error', btnLabel: 'global.retry', custom: {closeBtnLabel: 'global.close'}}).then(done => {
            if(done) {
              return this.launchFilter();
            } else {
              this.nav.to('dashboard');
            }
          });
        });
      } else {
        return this.api.payment(params.search).then((payment: any) => {
          this.loader.loading(false);
          if(!payment) {
            this.filteredData = [];
            return this.updateDataSource();
          }
          params.search && this.form.controls.search.setValue(params.search);
          this.filteredData = [payment];
          this.updateDataSource();
          return this.filterByOperation(this.filteredData);
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: this.firstCall ? 'filterPayment.error' : 'filterPaymentNoFirst.error', btnLabel: 'global.retry', custom: {closeBtnLabel: 'global.close'}}).then(done => {
            if(done) {
              return this.launchFilter();
            } else {
              this.nav.to('dashboard');
            }
          });
        });
      }
    } else if(this.api.userRole?.isAdmin || this.api.userRole?.isWorker) {
      return this.getRequests(params.dateFrom && new Date(params.dateFrom), params.dateTo && new Date(params.dateTo), params, params.untilStart && new Date(params.untilStart), params.untilEnd && new Date(params.untilEnd),
        params.localUsageUntilStart && new Date(params.localUsageUntilStart), params.localUsageUntilEnd && new Date(params.localUsageUntilEnd), offset).then((requests: RequestWithDuplicata[]) => {
        this.loader.loading(false);
        if(!requests || requests?.length === 0) {
          this.filteredData = [];
          this.updateDataSource();
          return this.searchAll(params, false);
        }
        this.filteredData = this.filterDuplicatePayment(requests);
        this.updateDataSource();
        params.search && this.form.controls.search.setValue(params.search);
        params.localUsageUntilEnd && this.form.controls.overstay.setValue(true);
        if(this.processDate) {
          params.dateFrom && this.form.controls.start.setValue(format(new Date(params.dateFrom), "yyyy-MM-dd'T'HH:mm"));
          params.dateTo && this.form.controls.end.setValue(format(new Date(params.dateTo), "yyyy-MM-dd'T'HH:mm"));
          this.processDate = false;
        }
        return this.searchAll(params);
      });
    } else if(this.processDate) {
      return this.getRequests(params.dateFrom && new Date(params.dateFrom), params.dateTo && new Date(params.dateTo), undefined, params.untilStart && new Date(params.untilStart), params.untilEnd && new Date(params.untilEnd),
        params.localUsageUntilStart && new Date(params.localUsageUntilStart), params.localUsageUntilEnd && new Date(params.localUsageUntilEnd), offset).then(() => {
        params.dateFrom && this.form.controls.start.setValue(format(new Date(params.dateFrom), "yyyy-MM-dd'T'HH:mm"));
        params.dateTo && this.form.controls.end.setValue(format(new Date(params.dateTo), "yyyy-MM-dd'T'HH:mm"));
        this.processDate = false;
        return this.searchAll(params);
      });
    } else {
      this.searchAll(params);
    }
    this.changeDetectorRef.detectChanges();
  }

  getRequests(fromDate?: Date, toDate?: Date, params?: any, untilStart?: Date, untilEnd?: Date, localUsageUntilStart?: Date, localUsageUntilEnd?: Date, offset = 0) {
    return this.api.searchPayment(undefined, params.search ? params.search.split(' ').filter((t: string) => t.length >= 2).join(' ') : undefined,
      fromDate ? fromDate : undefined, toDate ? toDate : undefined, params.createPlaceId ? params.createPlaceId : undefined,
      undefined, params.userId ? params.userId : undefined, params.state ? params.state : undefined, params.internalReference ? params.internalReference : undefined,
      params.subState ? params.subState : undefined, untilStart ? untilStart : undefined, untilEnd ? untilEnd : undefined,
      localUsageUntilStart ? localUsageUntilStart : undefined, localUsageUntilEnd ? localUsageUntilEnd : undefined, offset, this.paginator.pageSize).then((res: any) => {
      this.dataFromDates = [...this.filteredData, ...res.result];
      this.totalCount = res.count;
      setTimeout(() => {
        this.paginator.length = this.totalCount;
      });
      this.filteredData = this.filterDuplicatePayment(this.dataFromDates);
      this.updateDataSource();
      this.loader.loading(false);
      return this.filteredData;
    }).catch((err) => this.loader.loading(true, {type: 'error', message: err, btnLabel: 'global.retry', custom: {closeBtnLabel: 'global.close'}}).then(done => {
      if(done) {
        this.launchFilter();
      } else {
        this.nav.to('dashboard');
      }
    }));
  }

  searchAll(params: { [key: string]: any }, filterData = true) {
    if(params.search) {
      this.form.controls.search.setValue(params.search);
    }
    if(params.userId) {
      this.form.controls.userId.setValue(params.userId);
      if(filterData) {
        this.filteredData = this.filteredData.filter((data: Request) => +data.user__id === +Number(params.userId.trim().toLowerCase()));
      }
    }
    if(params.state) {
      this.form.controls.state.setValue(params.state + (params.subState ? '.' + params.subState : ''));
      if(filterData) {
        this.filteredData = this.filteredData.filter((data: Request) => +data.state === +params.state);
      }
    }
    if(params.internalReference) {
      this.form.controls.userId.setValue(params.internalReference);
      if(filterData) {
        this.filteredData = this.filteredData.filter((data: Request) => data.internalReference === params.internalReference.trim().toLowerCase());
      }
    }

    if(params.paymentState) {
      this.form.controls.state.setValue(params.state);
      if(filterData) {
        this.filteredData = this.filteredData.filter((data: Request) => +data.paymentType === +params.paymentState);
      }
    }

    if(params?.since) {
      this.form.controls.since.setValue(params.since);
      if(filterData) {
        this.filterSince(params.since);
      }
    } else {
      this.filterSince(null);
    }

    if(filterData) {
      this.updateDataSource();
      this.filterByOperation(this.filteredData);
    }
  }

  filterSince(since: SinceTime | null) {
    switch(since) {
    case null:
      if(this.params.resetFromSince === 'true') {
        this.changeDate({dateFrom: null, dateTo: null}, true);
      }
      break;
    case SinceTime.ONE_DAY:
      this.changeDate({dateFrom: sub(startOfToday(), {days: 1}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.THREE_DAYS:
      this.changeDate({dateFrom: sub(startOfToday(), {days: 3}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.ONE_WEEK:
      this.changeDate({dateFrom: sub(startOfToday(), {weeks: 1}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.TWO_WEEKS:
      this.changeDate({dateFrom: sub(startOfToday(), {weeks: 2}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.ONE_MONTH:
      this.changeDate({dateFrom: sub(startOfToday(), {months: 1}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.TWO_MONTHS:
      this.changeDate({dateFrom: sub(startOfToday(), {months: 2}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.SIX_MONTHS:
      this.changeDate({dateFrom: sub(startOfToday(), {months: 6}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    case SinceTime.ONE_YEAR:
      this.changeDate({dateFrom: sub(startOfToday(), {years: 1}).toISOString(), dateTo: endOfToday().toISOString()});
      break;
    default:
      break;
    }
  }

  filterByOperation(payments: any) {
    const finalPayment: any = [];
    payments.forEach((payment: RequestStep) => {
      payment.step =  [{status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.PAID]), value: format(parseISO(payment.createdAt), 'HH:mm dd/MM/yyyy')}];
      if(payment.state === RequestState.PAID_NOT_CONFIRMED) {
        payment.step =  [{status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.PAID_NOT_CONFIRMED]), value: format(parseISO(payment.createdAt), 'HH:mm dd/MM/yyyy')}];
      }
      if(payment.state === RequestState.EXPIRED) {
        payment.step  = [...payment.step, {status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.EXPIRED]), value: format(parseISO(payment.validatedAt), 'HH:mm dd/MM/yyyy')}];
      }
      if(payment.state === RequestState.USED) {
        payment.step  = [...payment.step, {status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.USED]), value: format(parseISO(payment.usedAt), 'HH:mm dd/MM/yyyy')}];
      }
      if(payment.state === RequestState.DUPLICATED) {
        payment.step  = [...payment.step, {status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.DUPLICATED]), value: format(parseISO(payment.updatedOn), 'HH:mm dd/MM/yyyy')}];
      }
      if(payment.state === RequestState.PROPOSED) {
        payment.step  = [...payment.step, {status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.PROPOSED]), value: format(parseISO(payment.updatedOn), 'HH:mm dd/MM/yyyy')}];
      }
      if(payment.state === RequestState.REFUNDED) {
        payment.step  = [...payment.step, {status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.REFUNDED]), value: format(parseISO(payment.refundedAt), 'HH:mm dd/MM/yyyy')}];
      }
      if(payment.state === RequestState.DELAY_PAY) {
        payment.step  = [...payment.step, {status: this.lang.transform('payment_status.DYN.' + RequestStatus[RequestState.DELAY_PAY]), value: format(parseISO(payment.updatedOn), 'HH:mm dd/MM/yyyy')}];
      }
      finalPayment.push(payment);
    });
    this.filteredData = finalPayment;
    this.updateDataSource();
    this.loader.loading(false);
  }

  offlineLoader() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('no.connection')});
  }

  changeDate(event: {dateFrom: any, dateTo: any}, clearSince?: boolean) {
    if(clearSince) {
      this.form.controls.since.setValue('');
    }
    const {dateFrom, dateTo} = event;
    if(dateFrom === null && dateTo === null) {
      this.form.controls.start.setValue(null);
      this.form.controls.end.setValue(null);
    }
    this.addParams({'dateFrom': dateFrom ? startOfDay(new Date(dateFrom)).toISOString() : null, 'dateTo': dateTo ? endOfDay(new Date(dateTo)).toISOString() : null, resetFromSince: null}, clearSince);
    this.processDate = true;
  }

  formatDate(date: string) {
    return format(parseISO(date), 'HH:mm dd/MM/yyyy');
  }

  addParams(params: { [key: string]: any }, clearSince?: boolean) {
    if(clearSince) {
      params.since = null;
    }
    this.router.navigate([],
      {queryParams: params, queryParamsHandling: 'merge'});
  }

  clearAllFilters() {
    this.form.reset();
    this.router.navigate([], {
      queryParams: {
        'userId': null,
        'search': null,
        'dateFrom': null,
        'untilStart': null,
        'untilEnd': null,
        'localUsageUntilStart': null,
        'localUsageUntilEnd': null,
        'dateTo': null,
        'internalReference': null,
        'state': null,
        'subState': null,
        'paymentState': null,
        'createPlaceId': null,
        'sortBy': null,
        'since': null
      },
      queryParamsHandling: 'merge'
    });
    this.customTable();
  }

  downloadCSV() {
    if(this.filteredData.length > 0) {
      const options = {
        showTitle: true,
        title: 'sales_report',
        headers: [
          'ID',
          'Update on',
          'Amount cents',
          'Payment Type',
          'Used at',
          'Closed at',
          'State',
          'Currency',
          'Metadata',
          'Created at',
          'Offline',
          'Parent ID',
          'User ID',
          'Used by user ID',
          'Created place ID',
          'Consumed place ID',
          'Refunded at',
          'Refunded place ID',
          'Payment cash type',
          'External ID',
          'Consume message',
          'Operation',
          'Time'
        ]
      };
      new ngxCsv(this.filteredData, 'sales_report', options);
    } else {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('one.payment')});
    }
  }

  goToAllQr() {
    if(this.filteredData?.length === 0 || !this.filteredData) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('one.payment')});
    } else {
      this.nav.to('admin-request-qr-list', undefined, {state: this.filteredData});
    }
  }

  updateDataSource() {
    const transformedData = this.filteredData.map(request => {
      if(this.onlyVisa) {
        const parsedData = parseIndexedData(request.internalIndexedData, request.operationId, this.api.userInfo.server);
        return {
          id: request.id,
          passportNumber: parsedData.passportNumber,
          currency: CurrencySign[request.currency as keyof typeof CurrencySign],
          completeName: (parsedData.firstName || '') + ' ' + (parsedData.lastName || ''),
          events: request.events,
          creationDate: request.createdAt ||  '',
          internalReference: request.internalReference || '',
          lock: (request.assigned_user__id && request.assignedAt) && differenceInMinutes(new Date(), new Date(request.assignedAt)) < 30,
          assignedAt: request.assignedAt || '',
          assignedUserId: request.assigned_user__id || '',
          realState: request.state,
          price: request.amountCents / 100,
          operationId: request.operationId,
          state: this.request.getStatusTheme(request).state,
          usageAfter: request.usageAfter || '',
          nationality: parsedData.nationality || '',
          usageUntil: request.usageUntil || '',
          iconColor: this.request.getStatusTheme(request).iconColor,
          isUrgent: this.isUrgent(request),
          batchId: request.batchId || ''
        };
      } else {
        const metadata = parseMetadata(request.metadata);
        const serializedData = request.operationId === DocumentType.PREENROLL ? parseIndexedData(request.internalIndexedData, request.operationId, this.api.userInfo.server) : {};
        return {
          id: request.id,
          userId: request.user__id,
          idCard: metadata?.idCard,
          email: metadata?.email,
          currency: CurrencySign[request.currency as keyof typeof CurrencySign],
          passportNumber: metadata?.passportNumber ? metadata.passportNumber : '',
          completeName: (metadata?.firstName || metadata?.first_name || serializedData?.first_name) + ' ' + (metadata?.lastName || metadata?.last_name || serializedData?.last_name),
          state: this.request.getStatusTheme(request).state,
          iconColor: this.request.getStatusTheme(request).iconColor,
          creationDate: request.createdAt || '',
          created_place_id: request.created_place_id,
          price: request?.amountCents / 100
        };
      }
    });
    this.dataSource = new MatTableDataSource<RequestTable>(transformedData as any);
    this.dataSource.paginator = this.paginator;
    if(this.newFilter) {
      this.customTable();
    }
    if(!this.firstCall) this.firstCall = true;
  }

  launchFilter() {
    clearTimeout(this.timer);
    return new Promise((resolve, reject) => {
      this.timer = setTimeout(() => {
        this.filterAll(this.params, 0)
          .then(resolve)
          .catch(reject);
      }, this.firstCall ? 500 : 0);
    });
  }

  onPageChange(event: any) {
    this.currentIndex = event.pageIndex;
    return this.filterAll(this.params, this.currentIndex * this.paginator.pageSize);
  }

  filterDuplicatePayment(payment: RequestWithDuplicata[]) {
    const uniqueIds = new Set();
    return payment.filter(item => {
      if(!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });
  }

  customTable() {
    if(this.newFilter) {
      this.newFilter = false;
    }
  }

  stateChange(state: any) {
    const queryValue = (state.target as HTMLInputElement).value;
    const splitValue = queryValue.split('.');
    if(splitValue[1]) {
      this.query.addParams({state: splitValue[0], subState: splitValue[1]});
    } else {
      this.query.addParams({state: splitValue[0], subState: null});
    }
  }

  isUrgent(payment: RequestWithDuplicata) {
    const indexedDatas = parseIndexedData(payment.internalIndexedData, payment.operationId, this.api.userInfo.server);
    return indexedDatas?.urgent === 'Yes';
  }

  navigateWithParams(row: Request) {
    this.loader.loading(true);
    return this.checkAndLockPayment(row).then((response) => {
      this.loader.loading(false);
      if(response) {
        this.nav.to('admin-request-select/' + row.id, undefined, {
          queryParams: {...this.params, from: 'admin-request'}});
      }
    }).catch((err) => {
      this.loader.loading(true, {type: 'error', message: err});
    });
  }

  changeOverStay() {
    if(this.form.controls.overstay.value) {
      this.clearAllFilters();
      setTimeout(() => {
        this.overstayCliked = true;
        this.addParams({localUsageUntilEnd: format(endOfToday(), "yyyy-MM-dd'T'HH:mm"), state: RequestState.USED, subState: RequestSubState.CHECKED_IN});
        this.form.controls.overstay.setValue(true);
      });
    } else {
      this.addParams({localUsageUntilEnd: null, state: null, subState: null});
      this.form.controls.overstay.setValue(false);
      this.form.controls.state.setValue('');
    }
  }


  isDate(date: any) {
    const parsedDate = parse(date, 'dd/MM/yyyy HH:mm', new Date());
    const isValidDate = !isNaN(parsedDate.getTime());
    return isValidDate;
  }

  checkAndLockPayment(payment: Request): Promise<boolean> {
    if(!this.api.hasPerm(UserPermission.ALLOW_CONTROL) || !this.onlyVisa) {
      return Promise.resolve(true);
    }
    const lockPayment = () => this.api.lockPayment(payment.id, true, '', this.api.userPlaceId).then(() => true).catch(() => true);
    if(!payment.assignedAt && !payment.assignedUserId) {
      return lockPayment();
    } else if(+payment.assignedUserId !== +this.api.userInfo.id) {
      const assignedTime = new Date(payment.assignedAt);
      const now = new Date();
      if(differenceInMinutes(now, assignedTime) < 30 && this.api.checkSeniority() < 3) {
        return this.loader.loading(true, {type: 'warn',
          message: this.lang.transform('payment.assigned.warning', {officerId: payment.assignedUserId}),
          btnLabel: 'global.confirm',
          custom: {closeBtnLabel: 'global.no'}
        }).then(response => {
          if(response) {
            this.loader.loading(true);
            return lockPayment();
          } else {
            return false;
          }
        });
      } else {
        return lockPayment();
      }
    } else {
      return lockPayment();
    }
  }

  changeSince(event: any) {
    this.addParams({since: event.target.value, resetFromSince: event.target.value ? null : true});
  }
}

import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {LoaderService, NavigateService, ShortcutAction} from 'ngx-satoris';
import {FormGroup, Validators} from '@angular/forms';
import {CurrencySign, PassportType, PurposeOfVisit, RequestType} from 'src/app/shared/models/request';
import {Document, Gender, UserPermission} from 'src/app/shared/models/user';
import {RequestService} from 'src/app/shared/services/request.service';
import {ApiService} from '../../../shared/services/api.service';
import {CartService} from '../../../shared/services/cart.service';
import {Direction, FormField, VisaFN, VisaFormConfig, VisaFormName} from '../../../shared/models/forms';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {ScanDocumentService} from '../../../shared/services/scan-document.service';
import {format} from 'date-fns';
import {convertToEpoch} from 'src/app/shared/utils/date';
import {ActivatedRoute} from '@angular/router';
import {FormComponent} from '../../../components/form/form.component';

@Component({
  selector: 'app-form-visa',
  templateUrl: './form-visa.component.html',
  styleUrl: './form-visa.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FormVisaComponent {
  currentStep: { key: VisaFormName, value: VisaFormName };
  steps: { key: VisaFormName, value: VisaFormName }[];
  VisaFormName = VisaFormName;
  availableBatchIds: string[];
  schemaLoaded = false;
  VisaFN = VisaFN;
  keepScanned = false;
  RequestType = RequestType;
  schema: any;
  submitted: boolean;
  successOnArrival: boolean;
  document: Document;
  subscription: any;

  config: VisaFormConfig;
  personId: number;
  paramDirection: Direction;

  @ViewChild(FormComponent) formComponent: FormComponent;

  constructor(public nav: NavigateService,
    public api: ApiService,
    public cart: CartService,
    public request: RequestService,
    public scanDoc: ScanDocumentService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private inactivity: InactivityService) {
    this.route.queryParams.subscribe(params => {
      if(params && params.direction) this.paramDirection = params.direction;
    });
    this.subscription = this.cart.currentApplication$.subscribe(() => {
      if(!(this.cart.currentApplication?.isExtend || (this.cart.currentApplication?.isRevision && this.cart.currentApplication?.isExtend))) {
        if(!this.cart.currentRequest) {
          if(this.cart.currentApplication?.isRevision || (this.api.userRole.isWorker && this.api.hasPerm(UserPermission.ALLOW_CREATE) && this.api.hasPerm(UserPermission.ALLOW_CONSUME))) {
            this.nav.to('dashboard');
          } else if(this.api.userRole.isKiosk) {
            this.nav.to('standby-kiosk');
          } else {
            this.nav.to('travel-name');
          }
          return;
        } else if(!this.cart.currentRequest.nationality || !this.cart.currentRequest.passportType) {
          this.nav.to('passport-type');
          return;
        } else if(!this.cart.currentRequest.visaType) {
          this.nav.to('visa-type');
          return;
        }
      }
      this.cart.currentRequest = {...this.cart.currentRequest, latestRouteVisited: 'form-visa'};
      this.cart.saveCurrentApplication(false);
      this.initConfig().then(() => {
        this.personId = this.cart.currentRequest.person.id;
        const stepsValues = Object.keys(this.config) as VisaFormName[];
        this.steps = stepsValues.map((value: VisaFormName) => ({
          key: this.getKeyByValue(value, VisaFormName) as VisaFormName,
          value
        }))
          .filter(step => this.config[step.value] && !this.config[step.value].disabledForRoles?.includes(this.api.userInfo.role));

        if(this.cart.currentRequest.latestFormStepVisited) {
          this.currentStep = this.cart.currentRequest.latestFormStepVisited;
        } else {
          this.currentStep = this.steps[0];
          this.cart.currentRequest.latestFormStepVisited = this.currentStep;
        }
        this.schema = undefined;
        this.schemaLoaded = false;
        this.setSchema().then(() => {
          this.cart.saveCurrentApplication(false);
          if(this.scanDoc.scanDatas && this.scanDoc.scanned && (this.api.userRole.isWorker || this.api.userRole.isAdmin)) {
            this.preFillFormFromScanDoc();
          } else if(!this.cart.currentApplication?.isExtend) {
            this.preFillForm();
          }

          this.additionnalConditions().then(() => {
            this.schemaLoaded = true;
          });
        });

        if(this.cart.currentApplication.isRevision && this.cart.currentApplication.skipValidRevision) {
          //if param direction is previous and no errors on current step, do previous
          if(this.paramDirection === Direction.PREVIOUS && !this.config[this.currentStep.value].hasRevisionErrors) {
            this.previousStep();
          } else if(this.paramDirection === Direction.NEXT && !this.config[this.currentStep.value].hasRevisionErrors) {
            this.nextStep();
          }
        }
      });
    });
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching();
  }

  ngOnDestroy() {
    if(!this.keepScanned) {
      this.scanDoc.scanned = false;
    }
    this.subscription.unsubscribe();
  }

  initConfig(): Promise<void> {
    return new Promise(resolve => {
      if(this.cart.currentRequest.formConfig) {
        this.config = this.cart.currentRequest.formConfig;
      } else {
        this.config = JSON.parse(JSON.stringify(this.cart.getOriginalForm()));
      }

      //update config with original form if newer
      Object.keys(this.config).forEach(step => {
        if(!this.cart.getOriginalForm()[step]) {
          this.config = JSON.parse(JSON.stringify(this.cart.getOriginalForm()));
        } else if(this.config[step] && this.config[step].version < this.cart.getOriginalForm()[step].version) {
          this.config[step] = JSON.parse(JSON.stringify(this.cart.getOriginalForm()[step]));
        }
      });
      resolve();
    });
  }

  additionnalConditions(): Promise<void> {
    return new Promise(resolve => {
      if(this.api.userRole.isAdmin || this.api.userRole.isWorker) {
        //remove step emergency contact from config
        if(this.config[VisaFormName.EMERGENCY_CONTACT]) this.config[VisaFormName.EMERGENCY_CONTACT] = undefined;
        this.steps = this.steps.filter(step => step.value !== VisaFormName.EMERGENCY_CONTACT);
      }
      if(this.cart.currentRequest.passportType === PassportType.RESIDENT_PERMIT && this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config).label = VisaFN.RESIDENCE_PERMIT_NUMBER;
      } else if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config)){
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config).label = VisaFN.PASSPORT_NUMBER;
      }
      if((this.api.userRole.isWorker || this.api.userRole.isAdmin) && this.cart.currentRequest.passportType === PassportType.REFUGEE && this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).hidden = false;
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).validators = [Validators.required.name];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).onlyCustomValidators = true;
      } else if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).hidden = true;
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).validators = [];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).onlyCustomValidators = true;
      }
      resolve();
    });
  }

  preFillForm() {
    if(this.api.userRole.isAdmin || this.api.userRole.isWorker) {
      if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config).validators = [];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config).onlyCustomValidators = true;
      }
      if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, this.config).validators = [];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, this.config).onlyCustomValidators = true;
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, this.config).validators = [];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, this.config).onlyCustomValidators = true;
      }
      if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, this.config).validators = [];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, this.config).onlyCustomValidators = true;
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, this.config).validators = [];
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, this.config).onlyCustomValidators = true;
      }
    }

    if(this.api.userRole.isCustomer && !this.api.env.nationalityNoDeclaration.includes(this.cart.currentRequest.nationality) && this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_LAST_NAME, this.config)) {
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_LAST_NAME, this.config).validators = [Validators.required.name];
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_FIRST_NAME, this.config).validators = [Validators.required.name];
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_EMAIL, this.config).validators = [Validators.required.name];
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_PHONE, this.config).validators = [Validators.required.name];
    }
    if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config) && !this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config)?.value) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config).value = this.cart.currentRequest.person.passportNumber;
    } else if(this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, this.config) && !this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, this.config)?.value) {
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, this.config).value = this.cart.currentRequest.person.passportNumber;
    }
    if(!this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, this.config)?.value && !this.cart.currentApplication?.isRevision) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, this.config).value = this.cart.currentRequest.person.firstName;
      if(this.cart?.currentRequest?.person?.firstName) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, this.config).disabled = true;
      }
    }
    if(!this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, this.config)?.value && !this.cart.currentApplication?.isRevision) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, this.config).value = this.cart.currentRequest.person.lastName;
      if(this.cart?.currentRequest?.person?.lastName) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, this.config).disabled = true;
      }
    }
    if(this.cart.currentRequest.passportType !== PassportType.ORDINARY &&
      this.cart.currentRequest.passportType !== PassportType.REFUGEE &&
      this.cart.currentRequest.passportType !== PassportType.IDENTITY_CARD &&
      this.cart.currentRequest.passportType !== PassportType.RESIDENT_PERMIT) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).value = this.cart.currentRequest.passportType === PassportType.PERMANENT_RESIDENT_PERMIT ? 'live_here' : 'business_visa';
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).hidden = true;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.NON_ORDINARY_PASS_MISSION, this.config).hidden = false;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE, this.config).value = '0';
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE_CURRENCY, this.config).value = 'USD';
    }
    if(this.scanDoc.consumeMode === 'transit'){
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).value = PurposeOfVisit.TRANSIT_VISA;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).disabled = true;
    }
  }

  preFillFormFromScanDoc() {
    if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config)) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config).validators = [];
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config).onlyCustomValidators = true;
    }
    if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, this.config)) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, this.config).validators = [];
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM_ASK, this.config).onlyCustomValidators = true;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, this.config).validators = [];
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_ZIM, this.config).onlyCustomValidators = true;
    }
    if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, this.config)) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, this.config).validators = [];
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME_ASK, this.config).onlyCustomValidators = true;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, this.config).validators = [];
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PREVIOUS_CONVICTIONS_HOME, this.config).onlyCustomValidators = true;
    }
    //last name
    if(this.scanDoc.scanDatas?.fields?.lastName && this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, this.config)) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, this.config).value = this.scanDoc.scanDatas.fields.lastName;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.LAST_NAME, this.config).disabled = true;
    }
    //first name
    if(this.scanDoc.scanDatas?.fields?.firstName && this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, this.config)) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, this.config).value = this.scanDoc.scanDatas.fields.firstName;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FIRST_NAME, this.config).disabled = true;
    }
    //birth date
    if(this.scanDoc.scanDatas?.fields?.birthDate && this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.BIRTH_DAY, this.config)) {
      const parsedDate = convertToEpoch(this.scanDoc.scanDatas.fields.birthDate, true, true);
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.BIRTH_DAY, this.config).value = format(parsedDate, 'yyyy-MM-dd');
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.BIRTH_DAY, this.config).disabled = true;
    }
    //gender
    if(this.scanDoc.scanDatas?.fields?.sex && this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.GENDER, this.config)) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.GENDER, this.config).value = this.scanDoc.scanDatas.fields.sex;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.GENDER, this.config).disabled = true;
    }
    //passport number
    if(this.scanDoc.scanDatas?.fields?.documentNumber) {
      if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config)) {
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config).value = this.scanDoc.scanDatas.fields.documentNumber;
        this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_NUMBER, this.config).disabled = true;
      } else if(this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, this.config)) {
        this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, this.config).value = this.scanDoc.scanDatas.fields.documentNumber;
        this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_NUMBER, this.config).disabled = true;
      }
    }
    //passport expiry date
    if(this.scanDoc.scanDatas?.fields?.expirationDate && this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_EXPIRY, this.config)) {
      const parsedDate = convertToEpoch(this.scanDoc.scanDatas.fields.expirationDate, true);
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_EXPIRY, this.config).value = parsedDate;
      this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.DATE_OF_EXPIRY, this.config).disabled = true;
    }

    if(this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_LAST_NAME, this.config)) {
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_LAST_NAME, this.config).validators = [];
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_FIRST_NAME, this.config).validators = [];
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_EMAIL, this.config).validators = [];
      this.request.getField(VisaFormName.EMERGENCY_CONTACT, VisaFN.EMERGENCY_PHONE, this.config).validators = [];
    }
    if(this.cart.currentRequest.passportType !== PassportType.ORDINARY &&
      this.cart.currentRequest.passportType !== PassportType.REFUGEE &&
      this.cart.currentRequest.passportType !== PassportType.IDENTITY_CARD &&
      this.cart.currentRequest.passportType !== PassportType.RESIDENT_PERMIT) {
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).value = this.cart.currentRequest.passportType === PassportType.PERMANENT_RESIDENT_PERMIT ? 'live_here' : 'business_visa';
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).hidden = true;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.NON_ORDINARY_PASS_MISSION, this.config).hidden = false;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE, this.config).value = '0';
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE_CURRENCY, this.config).value = 'USD';
    }
    if(this.scanDoc.consumeMode === 'transit'){
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).value = PurposeOfVisit.TRANSIT_VISA;
      this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).disabled = true;
    }
  }

  getPrice() {
    if(!this.cart.currentRequest) return;
    return this.document.currency + ' ' + Math.floor((this.document.amountCentsDyn ?
      eval(this.document.amountCentsDyn)({Nationality: this.cart.currentRequest.nationality, Urgent: this.cart.currentRequest.urgent}) : this.document.amountCents) / 100);
  }

  submitStep(event: {form?: FormGroup, byPass?: boolean}) {
    if(event?.form?.valid || event?.form?.disabled || event?.byPass) {
      this.nextStep();
    }
  }

  setStep(i: number) {
    //if step is not visited or is current step, do nothing
    if(!this.config[this.steps[i].value].visited || this.currentStep.key === this.steps[i].key) {
      return;
    }
    //this.setSchema();
    this.updateFromSchema().then(() => {
      this.currentStep = this.steps[i];
      this.cart.currentRequest.latestFormStepVisited = this.currentStep;
    });
  }

  nextStep() {
    setTimeout(() => {
      const index = this.steps.findIndex((step: { key: VisaFormName, value: VisaFormName }) => step.key === this.currentStep.key);
      if(index < this.steps.length - 1) {
        if(this.cart.currentApplication.isRevision && this.cart.currentApplication.skipValidRevision) {
          let nextIndex = index + 1;
          while(nextIndex < this.steps.length - 1 && !this.config[this.steps[nextIndex].value].hasRevisionErrors) {
            nextIndex++;
          }
          //if no errors found in next steps, nav to summary
          if(nextIndex === this.steps.length - 1 && !this.config[this.steps[nextIndex].value].hasRevisionErrors) {
            this.nav.to('summary');
          } else {
            this.updateFromSchema().then(() => {
              this.currentStep = this.steps[nextIndex] || this.steps[index + 1];
              this.cart.currentRequest.latestFormStepVisited = this.currentStep;
            });
          }
        } else {
          this.updateFromSchema().then(() => {
            this.currentStep = this.steps[index + 1];
            this.cart.currentRequest.latestFormStepVisited = this.currentStep;
          });
        }
      } else if(this.cart.allPersonRequestsAreValid()) {
        if(this.api.userRole.isKiosk || this.api.userRole.isCustomer) {
          this.nav.to('summary');
        } else {
          this.loader.loading(true, {type: 'info', message: 'entry.confirmation', btnLabel: 'global.confirm', custom: {closeBtnLabel: 'no'}}).then((done: boolean) => {
            if(done) {
              if(this.scanDoc?.scanned) {
                this.keepScanned = true;
                this.scanDoc.visaDatas = [];
                sessionStorage.removeItem('visaDatas');
              }
              this.cart.currentApplication.visaFeesAndTerms = true;
              this.cart.saveCurrentApplication(false);
              this.request.pay(undefined, false);
            }
          });
        }
      } else {
        this.cart.openParticipants();
      }
    });
  }

  previousStep() {
    const index = this.steps.findIndex((step: {
      key: VisaFormName,
      value: VisaFormName
    }) => step.key === this.currentStep.key);
    if(index > 0) {
      if(this.cart.currentApplication.isRevision && this.cart.currentApplication.skipValidRevision) {
        let prevIndex = index - 1;
        while(prevIndex > 0 && this.config[this.steps[prevIndex].value].visited && !this.config[this.steps[prevIndex].value].hasRevisionErrors) {
          prevIndex--;
        }
        //if no errors found in previous steps, nav to summary
        if(prevIndex === 0 && !this.config[this.steps[prevIndex].value].hasRevisionErrors) {
          if(this.request.isPaidOnline(this.cart.currentRequest.visaType.id) && !this.api.userRole.isWorker && !this.api.userRole.isAdmin) {
            this.nav.to('urgent', undefined, {queryParams: {direction: Direction.PREVIOUS}});
          } else {
            this.nav.to('visa-type', undefined, {queryParams: {direction: Direction.PREVIOUS}});
          }
        } else {
          this.updateFromSchema().then(() => {
            this.currentStep = this.steps[prevIndex] || this.steps[index - 1];
            this.cart.currentRequest.latestFormStepVisited = this.currentStep;
          });
        }
      } else {
        this.updateFromSchema().then(() => {
          this.currentStep = this.steps[index - 1];
          this.cart.currentRequest.latestFormStepVisited = this.currentStep;
        });
      }
    } /*else if(this.cart.cartDatas?.fromRoute.includes('tracking-control')) {
      this.nav.goBack(); //TODO check revision
    }*/ else {
      if(this.cart.currentApplication?.isExtend) {
        this.nav.to('visa-extend', undefined, {queryParams: {direction: Direction.PREVIOUS}});
      } else if(this.request.isPaidOnline(this.cart.currentRequest.visaType.id) && !this.api.userRole.isWorker && !this.api.userRole.isAdmin) {
        this.nav.to('urgent', undefined, {queryParams: {direction: Direction.PREVIOUS}});
      } else {
        if(this.scanDoc?.scanned) {
          this.keepScanned = true;
        }
        this.nav.to('visa-type', undefined, {queryParams: {direction: Direction.PREVIOUS}});
      }
    }
  }

  setSchema() {
    return new Promise((resolve, reject) => {
      if(!this.schema) {
        this.document = this.api.userInfo.server.paymentRequests.find(p => p.id === this.cart.currentRequest.visaType.id);
        if(this.request.getField(VisaFormName.MISCELLANEOUS_DETAILS, VisaFN.DEPARTURE_DATE, this.config)) {
          const deltaMillis = eval(this.document.usageUntilFromNowMillis)({
            Nationality: this.cart.currentRequest.nationality,
            PassportType: this.cart.currentRequest.passportType
          });
          if(deltaMillis) this.request.getField(VisaFormName.MISCELLANEOUS_DETAILS, VisaFN.DEPARTURE_DATE, this.config).dateValidators.maxDateMilliFrom = {
            form: VisaFormName.MISCELLANEOUS_DETAILS,
            control: VisaFN.ARRIVAL_DATE,
            milliseconds: deltaMillis
          };
        }
        return this.api.schema(this.document.schema).then((res: any) => {
          this.schema = res;
          this.updateFromSchema().then(() => {
            resolve(true);
          }).catch(() => {
            reject();
          });
        }).catch(() => {
          reject();
        });
      } else {
        return this.updateFromSchema().then(() => {
          resolve(true);
        }).catch(() => {
          reject();
        });
      }
    });
  }

  updateFromSchema() {
    return new Promise(resolve => {
      if(this.schema?.properties) {
        if(this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_COUNTRY, this.config)) {
          this.request.getField(VisaFormName.TRAVEL_INFORMATION, VisaFN.PASSPORT_COUNTRY, this.config).enum = this.request.getNationalities(false);
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config)) {
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PASSPORT_COUNTRY, this.config).enum = this.request.getNationalities(false);
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_RELATION, this.config)) {
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.ATTESTANT_RELATION, this.config).enum = this.schema.properties.AttestantRelation?.enum.map((item: string) => ({
            value: item,
            name: 'DYN.' + item
          }));
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_COUNTRY, this.config)) {
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PLACE_OF_BIRTH_COUNTRY, this.config).enum = this.request.getNationalities(false);
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE_CURRENCY, this.config)) {
          const supportedCurrencies = this.api.env.supportedCurrencies;
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.FUNDS_AVAILABLE_CURRENCY, this.config).enum = Object.keys(this.api.userInfo.rates.rates).map((item: string) => ({
            value: item,
            name: item
          })).sort((a: { name: string }, b: { name: string }) => {
            const aIndex = supportedCurrencies.indexOf(a.name);
            const bIndex = supportedCurrencies.indexOf(b.name);
            if(aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
            if(aIndex !== -1) return -1;
            if(bIndex !== -1) return 1;
            return a.name.localeCompare(b.name);
          }).filter((item: {name: string}) => item.name.length < 4);
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config)) {
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.PURPOSE_OF_VISIT, this.config).enum = this.schema.properties.Documents.properties.PurposeOfVisit?.enum.map((item: string) => ({
            value: item,
            name: 'DYN.' + item
          }));
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config)) {
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.MARITAL_STATUS, this.config).enum = this.schema.properties.MaritalStatus?.enum.map((item: string) => ({
            value: item,
            name: 'DYN.' + item
          }));
        }
        if(this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.GENDER, this.config)) {
          this.request.getField(VisaFormName.PERSONAL_DETAILS, VisaFN.GENDER, this.config).enum = this.schema.properties.Gender?.enum.map((item: string) => ({
            value: item,
            name: 'DYN.' + item
          })).filter((item: { value: string }) => item.value !== Gender.OTHER);
        }
        //set required fields in documents
        if(this.schema.properties.Documents.allOf) {
          this.schema.properties.Documents.allOf.forEach((item: any) => {
            if(item.if && item.if.properties) {
              const ifControlName = Object.keys(item.if.properties)[0];
              const ifControlValue = item.if.properties[ifControlName].const;
              //search field in all steps
              this.steps.forEach((step: { key: VisaFormName, value: VisaFormName }) => {
                if(this.config[step.value].fields) {
                  //search field in all fields of config
                  this.config[step.value].fields.forEach((formField: FormField) => {
                    if(formField.name === ifControlName) {
                      if(formField.value === ifControlValue) {
                        if(item.then.required) {
                          //reset validators of all documents fields
                          this.config[VisaFormName.DOCUMENT]?.fields.forEach((field: FormField) => {
                            if(field.alwaysVisible) return;
                            field.validators = [];
                            field.hidden = true;
                          });
                          //set validators of required fields
                          item.then.required.forEach((field: string) => {
                            //search field in all steps
                            if(this.request.getField(VisaFormName.DOCUMENT, field, this.config)?.name) {
                              //set validators of field
                              this.request.getField(VisaFormName.DOCUMENT, field, this.config).validators = [Validators.required.name];
                              //show field
                              this.request.getField(VisaFormName.DOCUMENT, field, this.config).hidden = false;
                              //add field to required list
                              /*this.cart.currentRequest.formConfig[VisaFormName.REQUIREMENTS].requiredList.push({icon: 'file-lines', text: field});*/
                            }
                          });
                        }
                      }
                    }
                  });
                }
              });
            }
          });
        } else if(this.schema.properties.Documents.required) {
          this.schema.properties.Documents.required.forEach((field: string) => {
            if(this.request.getField(VisaFormName.DOCUMENT, field, this.config)?.name) {
              this.request.getField(VisaFormName.DOCUMENT, field, this.config).validators = [Validators.required.name];
            }
          });
        }
        Object.keys(this.schema.properties).forEach((key) => {
          const fieldSchema = this.schema.properties[key];
          if(fieldSchema.maxLength !== undefined) {
            Object.values(VisaFormName).forEach((formName) => {
              const formField = this.request.getField(formName, key, this.config);
              if(formField) {
                formField.validators = formField.validators || [];
                formField.maxLength = fieldSchema.maxLength;
              }
            });
          }
        });
        resolve(true);
      }
    });
  }

  allStepsValid(): boolean {
    //check if all steps except payment were visited and have no errors
    return this.steps.every((step: {
      key: VisaFormName,
      value: VisaFormName
    }) => this.config[step.value].visited && !this.config[step.value].errors?.length);
  }

  getKeyByValue(value: string, enumCheck: any): string | undefined {
    for(const key in enumCheck) {
      if(enumCheck[key] === value) {
        return key;
      }
    }
    return undefined;
  }

  isLastStep() {
    return this.currentStep && this.steps.length && this.currentStep.key === this.steps[this.steps.length - 1].key;
  }

  submit() {
    if(this.api.userRole.isWorker || this.api.userRole.isAdmin) this.formComponent.submitForm();
  }

  protected readonly CurrencySign = CurrencySign;
  protected readonly ShortcutAction = ShortcutAction;
}

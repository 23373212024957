<div class="standby_layout" (click)="nav.to('standby-desc-kiosk')">
  <app-button class="mt_10 mb_10" icon="language" size="sm" [border]="true" color="transparent" (click)="nav.to('choose-lang', undefined, {state: {fromRoute: 'home'}})">{{'change_lang' | translate}}</app-button>
  <div class="standby_top">
    <h1>{{'kiosk.standby.title' |translate}}</h1>
    <h2>{{'kiosk.standby.text1' |translate}}</h2>
    <div class="resp_maxSize_xl flex f_align_c gap_30 mt_40">
      <img class="standby_logo" width="160px" src="assets-projects/logo-kiosk.svg" alt="Kiosk Logo">
      <qrcode colorLight="none" elementType="svg" margin="0" qrdata="{{'kiosk.qr.url' |translate}}" [width]="120" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
    </div>
  </div>
  <div class="standby_bottom">
    <qrcode [width]="140" class="resp_minSize_xl" colorLight="none" elementType="svg" margin="0" qrdata="{{'kiosk.qr.url' | translate}}" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
    <div class="standby_bigImg">
      <img src="assets-projects/images/big_five.png" alt="Animals of Zimbabwe">
    </div>
    <img class="standby_logo resp_minSize_xl" src="assets-projects/logo-kiosk.svg" alt="Kiosk Logo">
  </div>
  <div id="standby_hand">
    <img src="assets-projects/images/hand.svg" alt="Touch hand">
    <div class="ripples">
      <span class="ripple"></span>
      <span class="ripple"></span>
      <span class="ripple"></span>
    </div>
  </div>
</div>

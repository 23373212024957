/* tslint:disable */
export const en_overrides_strings = {
  'summary.warning_validity_list': '<b>IMPORTANT NOTICE:</b> Please be aware that the validity of the electronic travel authorization for the following person(s) begins on the date of issuance, <b><u>NOT</u></b> on the date of entry into Zimbabwe: <b>{names}</b>.<br><br>The permitted length of stay will be <b> determined by the immigration authorities at the port of entry upon arrival (typically 30 days)</b>. Please plan your trip accordingly.',
  'summary.warning_validity': '<b>IMPORTANT NOTICE:</b> Please be aware that the validity of the electronic travel authorization for this visa begins on the date of issuance, <b><u>NOT</u></b> on the date of entry into Zimbabwe. <b>The permitted length of stay will be determined by the immigration authorities at the port of entry upon arrival (typically 30 days)</b>. Please plan your trip accordingly.',
  'UsageUntil': 'Valid until',
  'updateField.desc': 'Please provide the updated information for the following field: <b>{field}</b>',
  'global.checkOut.withoutCamera': 'Check-out without camera',
  'global.checkIn.withoutCamera': 'Check-in without camera',
  'tooltip.checkInWithoutCamera': 'Check-in the person without using the camera',
  'global.checkOut.withCamera': 'Check-out with camera',
  'supervision.zweentry': 'Entry requiring supervisor\'s approval',
  'DYN.permanent-resident-permit': 'Permanent Resident Permit',
  'DYN.permanent-resident-permit.desc': 'A Permanent Resident Permit allows indefinite residence in Zimbabwe. It is typically issued to individuals who have lived in the country for an extended period and meet the eligibility criteria.',
  'changeFingerprint': 'Change fingerprint',
  'scan.supervisor.timeout': 'Would you like to bypass the supervisor scan?',
  'supervision.manualCatC': 'Manual entry for Category C country',
  'supervision.blacklist': 'Blacklisted applicant',
  'supervision.specifiedNationals': 'Country requiring supervision',
  'ask.downgrade': 'Are you sure you want to downgrade this request to a Single Entry Visa? This action cannot be undone.',
  'checkin': 'Check-in',
  'checkout': 'Check-out',
  'scan.addFingerprint.error': 'An error occurred while adding the fingerprint. Please try again.',
  'scan.supervisor.wrongFingerprint': 'The fingerprint does not match the supervisor\'s fingerprint stored in the system. Please try again.',
  'scan.now': 'Scan now',
  'scan.fingerprintSupervisor.desc': 'When ready, as the supervisor, place your finger on the scanner and click on <b>Scan now</b> to proceed with the following action:</br><b>{actionText}</b></br></br><i>Bypass available after {bypass} seconds</i>',
  'scan.supervisor.noFingerprint': 'No fingerprint data found for this supervisor\'s account name',
  'scan.supervisor.noAccountName': 'No supervisor account name provided',
  'supervisorAccountName': 'Supervisor account name',
  'scan.supervisor.desc': 'Please enter the account name of the supervisor to proceed with the following action:</br><b>{actionText}</b>',
  'addFingerprint': 'Add fingerprint',
  'all.supervisions': 'All supervision reviews',
  'tooltip.allSupervisions': 'A list of all reviews that have been submitted regarding the supervision of the application.',
  'refusalReason.doSupervisor.desc.DYN.zwevisa': 'Please provide a brief description of the <b>REFUSAL</b> reason. This message will be sent to the border officer.',
  'approveOrRefuse': 'Approve or refuse',
  'request.do_supervisor_review': 'Review by supervisor',
  'request.do_supervisor_review.desc': 'A supervisor may review the request to ensure that the information provided is accurate and that the request is compliant with the country\'s regulations.',
  'request.askSupervisorReview': 'Your review has been successfully submitted.',
  'refusalReason.reviewSupervisor.desc.DYN.zwevisa': 'Please provide a brief description of the review as <b>NOT RECOMMENDED</b>. The review will be sent to the supervisor for approval.',
  'request.askSupervisorReview.error': 'An error occurred while reviewing the request. Please try again.',
  'askSupervisorReview': 'What is your recommendation? Your review will be sent to the supervisor for approval.',
  'doSupervisorReview': 'Would you like to approve or refuse this application? Based on your decision, the border officer will be able to check in or not the applicant.',
  'request.ask_supervisor_review': 'Review to supervisor',
  'request.supervisor_review': 'You can request a supervisor review for this application. The supervisor will review the request and you will then be able to proceed with the application process.',
  'place.countriesSelected': 'Select the countries that will require a supervised entry',
  'visa.catC.ordinary': 'This application is for entry without a visa for individuals holding a <b>Category C</b> nationality and an <b>ordinary passport</b>. Entry may only be <b>REFUSED</b> to the applicant.',
  'err.AddressZimbabwe.required': 'Address in Zimbabwe is required',
  'zwevisaextc.title': 'Stay extension for category C',
  'zwevisaextab.title': 'Stay extension for category A or B',
  'DYN.payment_text.zwevisaextc': 'The stay extension for Category C allows you to extend your stay in Zimbabwe.',
  'DYN.payment_text.zwevisaextab': 'The stay extension for Category A or B allows you to extend your stay in Zimbabwe.',
  'extend.start': 'A stay extension request may be submitted for a longer stay in Zimbabwe. During the extension process, you may be required to provide additional information or documents. Please select the number of days you wish to extend your visa for on the next page.',
  'visaExtend.desc': 'You can extend your visa for a longer stay in Zimbabwe. Please select the number of days you wish to extend your visa for.',
  'kiosk.standby.title': 'READY TO DISCOVER ZIMBABWE?',
  'kiosk.standbyDesc.title': 'Welcome to Zimbabwe\'s Entry Declaration Kiosk',
  'kiosk.standbyDesc.li4': 'An immigration officer will then proceed with the final clearance and validate your entry into Zimbabwe.',
  'kiosk.standbyDesc.text3': 'We wish you an enjoyable stay in Zimbabwe!',
  'kiosk.success.text3': 'We wish you an enjoyable stay and wonderful discoveries in Zimbabwe!',
  'kiosk.qr.url': 'https://www.evisa.gov.zw/',
  'PARX_SUB3_SEC12': 'Paragraph X Subsection 3 Section 12',
  'PARB_SEC17': 'Paragraph B Section 17',
  'SUB1_SEC18': 'Subsection 1 Section 18',
  'PARX_SUB1_SEC14': 'Paragraph X Subsection 1 Section 14',
  'SUBPARIII_PARF_SUB1_SEC14': 'Subparagraph III Paragraph F Subsection 1 Section 14',
  'err.addressZimbabwe.required': 'Address in Zimbabwe is required',
  'PreviousConvictionsZimAsk': 'Have you, or any of your dependants, been convicted of any crime in Zimbabwe?',
  'err.PreviousConvictionsZimAsk.required': 'Please answer the question about previous convictions in Zimbabwe',
  'err.PreviousConvictionsZim.required': 'Previous conviction(s) in Zimbabwe is required',
  'form.plannedEntry': 'What is the planned port of entry in Zimbabwe?',
  'form.plannedEntry.desc': 'Please select the port of entry where you plan to enter Zimbabwe.',
  'zwevisa.DYN.desc': 'Allows for one entry into the country within a specified period of one (1) month, requiring a new visa for subsequent entries.',
  'zwevisa.DYN.long_desc': 'This visa permits one entry into the country within a specified period of one (1) month.</br>It is suitable for travelers planning a single visit to Zimbabwe within a short timeframe.</br>After the initial entry, a new visa is required for subsequent visits, making it less convenient for those who need to make multiple visits within a short period.',
  'zwevisa_double.DYN.desc': 'Permits two separate entries into the country within a designated timeframe of three (3) months, ideal for travelers with multiple visits planned.',
  'zwevisa_double.DYN.long_desc': 'The double entry visa allows for two separate entries into the country within a designated timeframe of three (3) months.</br>This type of visa is ideal for travelers with multiple visits planned, such as those conducting business or attending events in Zimbabwe.</br>It offers more flexibility than the single entry visa but still requires a new visa for additional visits beyond the two entries allowed.',
  'zwevisa_multiple.DYN.desc': 'Grants the holder multiple entries into the destination country over a specified period of six (6) months, facilitating frequent travel for business or tourism purposes.',
  'zwevisa_multiple.DYN.long_desc': 'This visa grants the holder multiple entries into the destination country over a specified period of six (6) months.</br>It is suitable for frequent travelers who need to visit Zimbabwe multiple times for business or tourism purposes.</br>The multiple entry visa offers the most flexibility among the visa types mentioned, allowing for unlimited entries within the designated period.',
  'zwevisa_kaza.DYN.desc': 'A special visa allowing entry into multiple countries within the Kavango Zambezi Transfrontier Conservation Area (KAZA TFCA) for a period of one (1) month, promoting regional tourism and conservation efforts.',
  'zwevisa_kaza.DYN.long_desc': 'The KAZA UNIVISA is a special visa that allows entry into multiple countries within the Kavango Zambezi Transfrontier Conservation Area (KAZA TFCA) for a period of one (1) month.</br>It promotes regional tourism and conservation efforts by facilitating travel between participating countries, including <b>Zimbabwe</b>, <b>Zambia</b>, and <b>Botswana</b>.</br>The KAZA UNIVISA is valid for 30 days and allows multiple entries into the KAZA TFCA.',
  'zwevisaarr.DYN.desc': 'Allows eligible travelers to obtain a visa upon arrival at the destination\'s port of entry, simplifying the entry process for short-term visits. This visa has a validity period of one (1) month.',
  'zwevisaarr.DYN.long_desc': 'This visa allows eligible travelers to obtain a visa upon arrival at the destination\'s port of entry, simplifying the entry process for short-term visits.</br>It is suitable for travelers who do not have the time or resources to apply for a visa in advance.</br>The visa on arrival is typically valid for a short period, such as 30 days, and may have restrictions on the number of entries allowed. This visa has a validity period of one (1) month.',
  'zwevisaarr_double.DYN.desc': 'Enables eligible travelers to obtain a visa allowing two entries upon arrival at the destination\'s port of entry, providing flexibility for multiple visits. This visa is valid within a designated timeframe of three (3) months.',
  'zwevisaarr_double.DYN.long_desc': 'The double entry visa on arrival enables eligible travelers to obtain a visa allowing two entries upon arrival at the destination\'s port of entry.</br>It provides flexibility for travelers who need to make multiple visits within the visa\'s validity period.</br>This type of visa is suitable for business travelers or tourists planning to visit Zimbabwe and other neighboring countries. This visa is valid within a designated timeframe of three (3) months.',
  'zwevisaarr_multiple.DYN.desc': 'Grants eligible travelers a visa allowing multiple entries upon arrival at the destination\'s port of entry, catering to frequent visitors. This visa is valid within a designated timeframe of six (6) months.',
  'zwevisaarr_multiple.DYN.long_desc': 'This visa grants eligible travelers a visa allowing multiple entries upon arrival at the destination\'s port of entry.</br>It caters to frequent visitors who need to travel to Zimbabwe multiple times within a short period.</br>The multiple entry visa on arrival offers the most flexibility among the visa types mentioned, allowing for unlimited entries within the designated period. This visa is valid within a designated timeframe of six (6) months.',
  'zwevisaarr_kaza.DYN.desc': 'A variant of the KAZA Univisa obtained upon arrival at the destination\'s port of entry, offering convenience for travelers exploring multiple countries within the KAZA TFCA. This visa has a validity period of one (1) month.',
  'zwevisaarr_kaza.DYN.long_desc': 'This variant of the KAZA UNIVISA is obtained upon arrival at the destination\'s port of entry.</br>It offers convenience for travelers exploring multiple countries within the KAZA TFCA, including <b>Zimbabwe</b>, <b>Zambia</b>, and <b>Botswana</b>.</br>The KAZA UNIVISA on arrival is valid for one (1) month and allows multiple entries into the KAZA TFCA.',
  'zweentry.DYN.desc': 'Zimbabwean citizens, as well as travelers from select countries, can enter without obtaining a visa beforehand.',
  'zweentry.DYN.long_desc': 'Zimbabwean citizens, as well as travelers from select countries, can enter without obtaining a visa beforehand.</br>This type of visa is ideal for short-term visits and tourism purposes.</br>It allows travelers to enter the country without the need for a visa application process, simplifying entry procedures.</br></br>However, it\'s important to note that the entry without visa does not allow for multiple entries or extended stays beyond the specified period of visit.',
  'zwevisa_visitor_details_text': '<div class="mb_40">If you are a national of a country listed in <a href="https://www.evisa.gov.zw/faq.html" target="_blank"><b>Category A (click to open)</b></a>, you are not required to have a visa to travel to Zimbabwe.</div>',
  'zwevisa.description': 'The Single Entry Visa allows for a single entry into Zimbabwe for a period of one (1) month. Once used, re-entry is restricted without obtaining a new Visa.',
  'zwevisa_double.description': 'The Double Entry Visa permits two entries into Zimbabwe within a period of three (3) months from the date of issue.',
  'zwevisa_multiple.description': 'The Multiple Entry Visa permits illimited entries into Zimbabwe within a period of six (6) months from the date of issue.',
  'zwevisaarr_multiple.description': 'The Multiple Entry Visa on arrival permits illimited entries into Zimbabwe within a period of six (6) months from the date of issue. This visa is obtained directly upon arrival at the port of entry, offering a convenient and efficient solution for travelers planning several short-term visits.',
  'zwevisa_kaza.description': 'The KAZA UNIVISA is a unique travel document that enhances your exploration of the Kavango Zambezi Transfrontier Conservation Area (KAZA TFCA). Encompassing Zambia, Zimbabwe, Botswana, Namibia, and Angola, this visa simplifies travel across these diverse regions, allowing you to seamlessly experience the rich cultural and natural wonders they offer.',
  'zwevisaarr_multiple': 'Multiple entry visa on arrival',
  'zwevisaarr.description': 'A Single Entry Visa on arrival is a straightforward travel document allowing eligible visitors to apply for and receive a visa upon arrival at their destination\'s port of entry. Characterized by quick processing, direct fee payment, and broad eligibility, it offers a convenient and flexible option for short-term stays. Travelers should check specific requirements for their destination, as eligibility may vary between countries.',
  'zwevisaarr.title': 'Single entry visa on arrival',
  'zwevisaarr_double.title': 'Double entry visa on arrival',
  'zwevisaarr_kaza.title': 'KAZA Visa on arrival',
  'zwevisaarr_multiple.title': 'Multiple entry visa on arrival',
  'zwevisa': 'Single Entry Visa',
  'zwevisa_double': 'Double Entry Visa',
  'zwevisa_multiple': 'Multiple Entry Visa',
  'zwevisa_kaza': 'KAZA Univisa',
  'zwevisa_double.title': 'Double Entry Visa',
  'zwevisa_multiple.title': 'Multiple Entry Visa',
  'zwevisa_kaza.title': 'KAZA Univisa',
  'zwevisaarr': 'Single Entry Visa on arrival',
  'zwevisaarr_double': 'Double entry visa on arrival',
  'zwevisaarr_kaza': 'KAZA Univisa on arrival',
  'PreviousZimbabweanVisa': 'Previous Zimbabwean visa',
  'err.PreviousZimbabweanVisa.required': 'Previous Zimbabwean visa is required',
  'visaPriceArrival.desc': 'This is the price you will have to pay upon arrival in Zimbabwe. This payment is non-refundable.',
  'document_text': 'Please provide the following information truthfully and as it appears on your passport or other legal documents. The Department of Immigration (Zimbabwe) will not be held responsible for any falsified information or inconvenience resulting from it. The accepted document formats are JPEG, PNG, or PDF, and should be less than 500KB in size.',
  'PreviousConvictionsZim': 'Nature of the conviction(s) in Zimbabwe',
  'travel_information_text': 'Please provide the following information truthfully and as it appears on your passport or other legal documents. The Department of Immigration (Zimbabwe) will not be held responsible for any falsified information or inconvenience resulting from it.',
  'AddressZimbabwe': 'Address in Zimbabwe',
  'form.addressZimbabwe': 'Host address',
  'form.previousConvictionsZim': 'Previous convictions in Zimbabwe',
  'proof.residence.zim': 'Proof of residence in Zimbabwe',
  'err.PreviousConvictionsZim.pattern': 'Previous convictions in Zimbabwe must have a correct format',
  'DYN.payment_text.zwevisa': '<p>This application is for a <b>Single Entry Visa</b>.</p> <p>The Single Entry Visa allows for a single entry into Zimbabwe for a period of one (1) month. Once used, re-entry is restricted without obtaining a new Visa.</p> <div><p class="mb_2"><b>Please read this carefully:</b></p> <p>You cannot travel until you have received the approved visa application first. The application you are submitting and paying for now still has to go through the administration control process. You will receive notifications by email upon approval, revision request, or refusal of this application.</p></div> <b>The Zimbabwe Department of Immigration reserves the right to change your visa type during processing at their discretion. This may affect the cost of your visa. The actual cost of the visa will be stated in the approval response. No change allowed after the payment is done.</b></p>',
  'DYN.payment_text.zwevisa_double': '<p>This application is for a <b>Double Entry Visa</b>.</p> <p>The Double Entry Visa permits two entries into Zimbabwe within a period of three (3) months from the date of issue.</p> <div><p class="mb_2"><b>Please read this carefully:</b></p> <p>You cannot travel until you have received the approved visa application first. The application you are submitting and paying for now still has to go through the administration control process. You will receive notifications by email upon approval, revision request, or refusal of this application.</p></div> <b>The Zimbabwe Department of Immigration reserves the right to change your visa type during processing at their discretion. This may affect the cost of your visa. The actual cost of the visa will be stated in the approval response. No change allowed after the payment is done.</b></p>',
  'DYN.payment_text.zwevisa_kaza': '<p>This application is for a <b>KAZA Univisa</b>.</p> <p>The KAZA UNIVISA is a unique travel document that enhances your exploration of the Kavango Zambezi Transfrontier Conservation Area (KAZA TFCA) for a period of one (1) month. Encompassing Zambia, Zimbabwe, Botswana, Namibia, and Angola, this visa simplifies travel across these diverse regions, allowing you to seamlessly experience the rich cultural and natural wonders they offer.</p> <div><p class="mb_2"><b>Please read this carefully:</b></p> <p>You cannot travel until you have received the approved visa application first. The application you are submitting and paying for now still has to go through the administration control process. You will receive notifications by email upon approval, revision request, or refusal of this application.</p></div> <b>The Zimbabwe Department of Immigration reserves the right to change your visa type during processing at their discretion. This may affect the cost of your visa. The actual cost of the visa will be stated in your approval response. No change allowed after the payment is done.</b></p>',
  'DYN.payment_text.zwevisa_multiple': '<p>This application is for a <b>Multiple Entry Visa</b>.</p> <p>The Multiple Entry Visa permits illimited entries into Zimbabwe within a period of six (6) months from the date of issue.</p> <div><p class="mb_2"><b>Please read this carefully:</b></p> <p>You cannot travel until you have received the approved visa application first. The application you are submitting and paying for now still has to go through the administration control process. You will receive notifications by email upon approval, revision request, or refusal of this application.</p></div> <b>The Zimbabwe Department of Immigration reserves the right to change your visa type during processing at their discretion. This may affect the cost of your visa. The actual cost of the visa will be stated in your approval response. No change allowed after the payment is done.</b></p>',
  'DYN.payment_text.zwevisaarr': '<p>This application is for a <b>Single Entry Visa on arrival</b>.</p> <p>The Single Entry Visa on arrival is a straightforward travel document allowing eligible visitors to apply for and receive a visa upon arrival at their destination\'s port of entry with a validity of one (1) month. Characterized by quick processing, direct fee payment, and broad eligibility, it offers a convenient and flexible option for short-term stays. Travelers should check specific requirements for their destination, as eligibility may vary between countries.</p><b>The Zimbabwe Department of Immigration reserves the right to change your visa type during processing at their discretion. This may affect the cost of your visa. The actual cost of the visa will be stated in your approval response. No change allowed after the payment is done.</b></p>',
  'DYN.payment_text.zwevisaarr_double': '<p>This application is for a <b>Double Entry Visa</b>.</p> <p>The Double Entry Visa permits two entries into the country within a specified period, usually three (3) months from the date of issue. It is designed for those who plan to enter the country twice for short-term stays. This visa offers the convenience of applying upon arrival at your destination’s port of entry, with rapid processing and direct fee payment. Eligibility and requirements may vary between countries, so travelers should verify specific details for their destination.</p><b>The Immigration Department reserves the right to modify your visa type during processing. This may impact the visa cost. The final cost will be indicated in your approval response. No modifications are allowed after payment completion.</b></p>',
  'DYN.payment_text.zwevisaarr_kaza': '<p>This application is for a <b>KAZA Visa on Arrival</b>.</p> <p>The KAZA Visa on Arrival facilitates entry into the Kavango Zambezi Transfrontier Conservation Area, encompassing Zambia, Zimbabwe, Botswana, Namibia, and Angola for a period of one (1) month. It allows eligible travelers to apply for and receive a visa directly upon arrival at their destination\'s port of entry. This visa is known for its quick processing, direct fee payment, and broad eligibility, making it a flexible option for those looking to explore the rich cultural and natural landscapes of the KAZA region for short-term stays. Travelers should ensure they meet the specific requirements for each country within the KAZA TFCA.</p><b>The KAZA Immigration Authorities reserve the right to alter your visa type during processing, which could affect the visa fee. The actual visa cost will be specified in your approval notice. Changes are not permitted after payment has been made.</b></p>',
  'DYN.payment_text.zwevisaarr_multiple': '<p>This application is for a <b>Multiple Entry Visa on Arrival</b>.</p> <p>The Multiple Entry Visa on Arrival  permits illimited entries into the destination country within a period of six (6) months from the date of issue. Characterized by quick processing and direct fee payment, it provides a flexible option for travelers requiring frequent access for short-term stays.</p><b>The Department of Immigration reserves the right to change your visa type during processing at their discretion. This may affect the cost of your visa. The actual cost of the visa will be stated in your approval response. No change allowed after the payment is done.</b></p>',
  'DYN.payment_text.zweentry': '<p>This application is for an <b>Entry without visa</b>.</p> <p>The Entry without visa allow travelers from select countries to enter without obtaining a visa beforehand, typically for short stays.</p> <div><p class="mb_2"></p>',
  'zwevisaarr_double.description': 'The Double Entry Visa on arrival allows travelers two entries into a country, typically within a three-month period from the date of issue. This visa is obtained directly upon arrival at the port of entry, offering a quick and convenient option for those planning to enter the country twice for short-term stays. It\'s essential for travelers to check the specific requirements and eligibility criteria for the destination country.',
  'zwevisaarr_kaza.description': 'The KAZA Visa on Arrival offers direct issuance at the port of entry within the Kavango Zambezi Transfrontier Conservation Area, covering Zambia, Zimbabwe, Botswana, Namibia, and Angola. This streamlined process allows travelers to efficiently obtain their visa upon arrival for short-term visits, facilitating easy exploration of the region\'s diverse attractions. It\'s important for travelers to check specific entry requirements for each KAZA TFCA country.',
  'print.desc.part1': 'The possession of a electronic travel authorization (eTA) is not the final authority to enter the Republic of Zimbabwe. Admissibility will be determined at the point of entry.',
  'print.desc.part1.zweentry': 'Being eligible to enter Zimbabwe without a visa does not guarantee entry. Admissibility will be determined at the point of entry.',
  'print.desc.part3': 'Engaging in any form of business or employment in Zimbabwe without a requisite permit is an offence. Please present your passport and this eTA when requested by airline or immigration officials.',
  'tooltip.addressZimbabwe': 'If the applicant has an address in Zimbabwe, please enter it here.',
  'err.PreviousConvictionsZim.maxlength': 'Previous conviction(s) in Zimbabwe must be less than 50 characters long',
  'zweprovrespermit': 'Provisional residence permit',
  'zwevisaextc': 'Stay extension for category C',
  'zwevisaextab': 'Stay extension for category A or B',
  'zwefeedback': 'Stay feedback form',
  'zwevisa_visitor_details_title': 'Does your nationality fall under the Category A regime?',
  'refusalReason.desc.DYN.zwevisa': '<p><b>Please note that if the term "blacklist" is used, the individual associated with this visa application will be blacklisted.</b></p><p>Please provide a brief description of the refusal reason.</p>',
  'refusalReason.desc.extension.DYN.zwevisa': '<p>Please provide a brief description of the refusal reason for this extension request.</p>',
  'refusalReason.review.desc.DYN.zwevisa': 'Please provide a brief description of the review as <b>NOT RECOMMENDED</b>. This description will be sent to the next reviewer.',
  'approveReason.review.desc.DYN.zwevisa': 'Please provide a brief description of the review as <b>RECOMMENDED</b>. This description will be sent to the next reviewer.',
  'deletePerson.error.DYN.zwevisa': 'An error occurred while deleting the person. You cannot delete a person who already has a submitted application.',
  'DYN.zwevisaarr': 'Single Entry Visa on arrival',
  'notice.desc.BORDER_PASS': 'A Border Pass is an official document issued by the Government of Zimbabwe allowing individuals to cross specified border points legally.',
  'scan.visaCreated.exit': 'The visa application has been successfully created. You can now check out the person',
  'scan.visaCreated.entry': 'The visa application has been successfully created. You can now check in the person',
  'forceComparison.show': 'Show comparison with scanned doc',
  'forceComparison.hide': 'Hide comparison with scanned doc',
  'tooltip.forceComparison': 'Compare informations between the document scanned and the application.',
  'noScannedComparison': 'No matching data found in the application',
  'transfertFromPassport': 'Transfer the informations from the scanned doc.',
  'tooltip.transfertFromPassport': 'The informations between the passport and the scanned doc. are different. You can transfer the informations from the scanned doc. to the application.',
  'CompanionsSize': 'Number of travel companions',
  'transit.consume': 'Transit',
  'tooltip.scanPassport': 'Scan the passport of the person',
  'passport.scan': 'Scan passport',
  'tooltip.transitRegister': 'Register the transit of the person',
  'transit.register': 'Record a transit',
  'global.goLatest': 'Open the latest application visited',
  'tooltip.goLatest': 'You can return to previous application you visited',
  'scan.choice': 'Select mode',
  'form.ad': 'Email/Username',
  'printerOffline': 'The thermal printer is offline. Please check the USB connection with the device and try again. Would you still like to print the document using a regular printer?',
  'printerNotFound': 'The thermal printer is not detected in your device list. Please check the connection and ensure the drivers are installed. Would you still like to print the document using a regular printer?',
  'datePrinted': 'Printed date',
  'printThermal': 'Print with thermal printer',
  'tooltip.printThermal': 'Print the document with a thermal printer, or uncheck to print with a regular printer',
  'application.overStay': 'The application is <b>in overstay</b>',
  'watchlistFound': 'The application <b>matches the watchlist</b>',
  'apipnrFound': 'The application <b>matches the API/PNR list</b>',
  'payAttention': 'I will pay attention',
  'deportedMode': 'Mark as deported',
  'tooltip.deportedMode': 'Mark the person as a deported. If you mark the person as a deported, the person will be considered as a deported in the system when you check in the person.',
  'deportedComment': 'Reason for deported status',
  'payment.inReturned': 'This application was checked in with <b>deported status</b> at the officer\'s desk.',
  'reasonAdd': '<p><b>Reason</b>: <br/> {reason}</p>',
  'global.deported': 'Deported',
  'photo.entryCountry.INRETURNED': 'Photo at check-in with deported status',
  'print.notices': 'Print Notice(s)',
  'ask.noticePrint': 'Would you like to print the notice dated {date} ?',
  'select.notice': 'Select the notice to print',
  'ask.selectNotice': 'Please select the notice you want to print.',
  'noChoice.selectedNotice': 'No notice selected',
  'tooltip.printNotices': 'Print the notice(s) printed by the officer during the check-in or refusal process',
  'ask.revertConsume': 'Are you sur you want to cancel this event?',
  'cancelled': 'Cancelled',
  'tooltip.revertConsume': 'You can cancel this event of this application',
  'done.revertConsume': 'The event has been successfully cancelled',
  'revert.consume': 'Reason of cancellation',
  'error.revertConsume': 'An error occurred while cancelling the event. Please try again.'
};

import {Component, ViewEncapsulation} from '@angular/core';
import {InactivityService} from '../../../shared/services/inactivity.service';
import {ApiService} from '../../../shared/services/api.service';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-success-kiosk',
  templateUrl: './success-kiosk.component.html',
  styleUrl: './success-kiosk.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SuccessKioskComponent {
  constructor(public nav: NavigateService, private inactivity: InactivityService, private api: ApiService) {
    if(!this.inactivity.isWatching && this.api.userRole.isKiosk) this.inactivity.startWatching(20000);
  }
}
